"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getGrade = getGrade;
exports.getRoles = getRoles;
exports.getToken = getToken;
exports.removeToken = removeToken;
exports.setToken = setToken;
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var _store = _interopRequireDefault(require("../store"));
var TokenKey = 'Admin-Token';
function getToken() {
  return _jsCookie.default.get(TokenKey);
}
function setToken(token) {
  return _jsCookie.default.set(TokenKey, token);
}
function removeToken() {
  return _jsCookie.default.remove(TokenKey);
}
function getRoles() {
  return _store.default.getters.roles;
}
function getGrade() {
  return _store.default.getters.grade;
}