"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addHotel = addHotel;
exports.batchUpdateHotelEmployee = batchUpdateHotelEmployee;
exports.deleteHotel = deleteHotel;
exports.deleteHotelEmployee = deleteHotelEmployee;
exports.fetchList = fetchList;
exports.getArea = getArea;
exports.getBusinessArea = getBusinessArea;
exports.getHotel = getHotel;
exports.getHotelCommissionConfig = getHotelCommissionConfig;
exports.getHotelDepositDeductConfig = getHotelDepositDeductConfig;
exports.getHotelDepositDeductSummary = getHotelDepositDeductSummary;
exports.getHotelEmployeeList = getHotelEmployeeList;
exports.getHotelSimpleList = getHotelSimpleList;
exports.getManagementModeList = getManagementModeList;
exports.getModeList = getModeList;
exports.getPermissionList = getPermissionList;
exports.getSimpleHotel = getSimpleHotel;
exports.gethotelEmployeeRoles = gethotelEmployeeRoles;
exports.updateHotel = updateHotel;
exports.updateHotelCommissionConfig = updateHotelCommissionConfig;
exports.updateHotelDepositDeductConfig = updateHotelDepositDeductConfig;
exports.updateHotelInfo = updateHotelInfo;
var _request = _interopRequireDefault(require("@/utils/request"));
function fetchList(data) {
  return (0, _request.default)({
    url: 'hotel',
    method: 'get',
    params: data
  });
}
function getHotel(id, data) {
  return (0, _request.default)({
    url: "/hotel/".concat(id),
    method: 'get',
    params: data
  });
}
function getSimpleHotel(query) {
  return (0, _request.default)({
    url: '/simpleHotel',
    method: 'get',
    params: query
  });
}
function getHotelSimpleList(params) {
  params = params || {};
  return (0, _request.default)({
    url: "/hotelSimpleList",
    method: 'get',
    params: params
  });
}
function getArea() {
  return (0, _request.default)({
    url: "/areaList",
    method: 'get'
  });
}
function getModeList() {
  return (0, _request.default)({
    url: "/modeList",
    method: 'get'
  });
}
function getManagementModeList() {
  return (0, _request.default)({
    url: "/managementModeList",
    method: 'get'
  });
}
function getBusinessArea(data) {
  return (0, _request.default)({
    url: "/businessArea",
    method: 'get',
    params: data
  });
}
function getPermissionList() {
  return (0, _request.default)({
    url: "/permissionList",
    method: 'get'
  });
}
function addHotel(data) {
  return (0, _request.default)({
    url: 'hotel',
    method: 'post',
    data: data
  });
}
function updateHotel(id, data) {
  return (0, _request.default)({
    url: "/hotel/".concat(id),
    method: 'put',
    data: data
  });
}
function deleteHotel(id) {
  return (0, _request.default)({
    url: "/hotel/".concat(id),
    method: 'delete'
  });
}
function updateHotelInfo(id, data) {
  return (0, _request.default)({
    url: "/hotelInfo/".concat(id),
    method: 'post',
    data: data
  });
}
function getHotelDepositDeductConfig(id) {
  return (0, _request.default)({
    url: "/hotel_deposit_deduct_config/".concat(id),
    method: 'get'
  });
}
function updateHotelDepositDeductConfig(id, data) {
  return (0, _request.default)({
    url: "/hotel_deposit_deduct_config/".concat(id),
    method: 'put',
    data: data
  });
}
function getHotelDepositDeductSummary(id) {
  return (0, _request.default)({
    url: "/hotel_deposit_deduct_summary/".concat(id),
    method: 'get'
  });
}
function getHotelCommissionConfig(id) {
  return (0, _request.default)({
    url: "/hotel_commissions/".concat(id),
    method: 'get'
  });
}
function updateHotelCommissionConfig(id, data) {
  return (0, _request.default)({
    url: "/hotel_commissions/".concat(id),
    method: 'put',
    data: data
  });
}
function gethotelEmployeeRoles(query) {
  return (0, _request.default)({
    url: '/hotel_employee_roles',
    method: 'get',
    params: query
  });
}
function getHotelEmployeeList(hotelId) {
  return (0, _request.default)({
    url: "/hotel/".concat(hotelId, "/hotel_employees"),
    method: 'get'
  });
}
function batchUpdateHotelEmployee(hotelId, data) {
  return (0, _request.default)({
    url: "/hotel/".concat(hotelId, "/batch_hotel_employees"),
    method: 'put',
    data: data
  });
}
function deleteHotelEmployee(hotelId, empId) {
  return (0, _request.default)({
    url: "/hotel/".concat(hotelId, "/hotel_employees/").concat(empId),
    method: 'delete'
  });
}