var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
            size: "mini",
            title: "订单换机记录",
            width: "720px",
          },
          on: { close: _vm.closeHandler },
        },
        [
          _c(
            "el-row",
            {},
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.listLoading,
                          expression: "listLoading",
                        },
                      ],
                      staticStyle: { width: "100%" },
                      attrs: { data: _vm.list, border: "" },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "detail.started_at",
                          align: "center",
                          label: "起始时间",
                          width: "190px",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "detail.ended_at",
                          align: "center",
                          label: "结束时间",
                          width: "190px",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { align: "center", label: "使用设备" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(scope.row.detail.device_name) +
                                    "（" +
                                    _vm._s(scope.row.detail.device_code) +
                                    "）\n          "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.closeHandler } },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }