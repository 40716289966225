"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _activity = require("@/api/activity");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "ActivityPointLottery",
  data: function data() {
    return {
      activityId: undefined,
      list: [],
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 20,
        keyword: ""
      },
      pagination: ""
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      (0, _activity.fetchPointLotteryList)(this.listQuery).then(function (response) {
        var data = response.data;
        _this.list = data.data;
        _this.pagination = data;
        _this.listQuery.page = data.current_page;
        _this.listLoading = false;
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    resetForm: function resetForm() {
      this.listQuery.keyword = "";
    },
    addHandler: function addHandler() {
      this.$router.push({
        path: "/activity/point_lottery_form"
      });
    },
    closeHandler: function closeHandler(activityId) {
      var _this2 = this;
      console.log(activityId);
      (0, _activity.closePointLottery)(activityId).then(function () {
        _this2.$message.success("已结束活动！");
        _this2.getList();
      });
    },
    toDetail: function toDetail(activityId) {
      this.$router.push({
        path: "/activity/point_lottery/".concat(activityId, "/log")
      });
    },
    copyHandler: function copyHandler(activityId) {
      this.$router.push({
        path: "/activity/point_lottery_form",
        query: {
          copy: activityId
        }
      });
    }
  }
};
exports.default = _default;