"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _weekBase = _interopRequireDefault(require("./weekBase.vue"));
var _weekPermutation = _interopRequireDefault(require("./weekPermutation.vue"));
var _weekSoft = _interopRequireDefault(require("./weekSoft.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import weekPermutationOld from './weekPermutationOld.vue'
// import WeekLease from './weekLease.vue'
var _default = {
  name: "BillWeek",
  components: {
    WeekBase: _weekBase.default,
    // WeekLease, 
    WeekPermutation: _weekPermutation.default,
    // weekPermutationOld, 
    WeekSoft: _weekSoft.default
  },
  data: function data() {
    return {
      billType: "base"
    };
  },
  created: function created() {
    this.billType = this.$route.query.bill_type || "base";
  },
  methods: {
    billTypeHandler: function billTypeHandler() {}
  }
};
exports.default = _default;