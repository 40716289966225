"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.cancelOrder = cancelOrder;
exports.confirmOrder = confirmOrder;
exports.createCatetory = createCatetory;
exports.createGoods = createGoods;
exports.fetchCatetoryGoods = fetchCatetoryGoods;
exports.fetchCatetoryList = fetchCatetoryList;
exports.fetchCatetorySimple = fetchCatetorySimple;
exports.fetchGoods = fetchGoods;
exports.fetchGoodsList = fetchGoodsList;
exports.fetchGoodsSimple = fetchGoodsSimple;
exports.fetchOrderList = fetchOrderList;
exports.updateCatetory = updateCatetory;
exports.updateCatetoryGoodsSort = updateCatetoryGoodsSort;
exports.updateGoods = updateGoods;
exports.updateGoodsShow = updateGoodsShow;
var _request = _interopRequireDefault(require("@/utils/request"));
/*********** 商品类别 *************/
function fetchCatetoryList(query) {
  return (0, _request.default)({
    url: '/point_shop/categories',
    method: 'get',
    params: query
  });
}
function fetchCatetorySimple(query) {
  return (0, _request.default)({
    url: '/point_shop/category_simple',
    method: 'get',
    params: query
  });
}
function fetchCatetoryGoods(id) {
  return (0, _request.default)({
    url: "/point_shop/categories/".concat(id, "/goods"),
    method: 'get'
  });
}
function createCatetory(data) {
  return (0, _request.default)({
    url: '/point_shop/categories',
    method: 'post',
    data: data
  });
}
function updateCatetory(id, data) {
  return (0, _request.default)({
    url: "/point_shop/categories/".concat(id),
    method: 'put',
    data: data
  });
}
function updateCatetoryGoodsSort(id, goods) {
  return (0, _request.default)({
    url: "/point_shop/categories/".concat(id, "/sort_goods"),
    method: 'put',
    data: {
      goods: goods
    }
  });
}

/*********** 积分商品 *************/
function fetchGoodsList(query) {
  return (0, _request.default)({
    url: '/point_shop/goods',
    method: 'get',
    params: query
  });
}
function fetchGoodsSimple(query) {
  return (0, _request.default)({
    url: '/point_shop/goods_simple',
    method: 'get',
    params: query
  });
}
function fetchGoods(id) {
  return (0, _request.default)({
    url: "/point_shop/goods/".concat(id),
    method: 'get'
  });
}
function createGoods(data) {
  return (0, _request.default)({
    url: '/point_shop/goods',
    method: 'post',
    data: data
  });
}
function updateGoods(id, data) {
  return (0, _request.default)({
    url: "/point_shop/goods/".concat(id),
    method: 'put',
    data: data
  });
}
function updateGoodsShow(id) {
  return (0, _request.default)({
    url: "/point_shop/goods/".concat(id, "/show_switch"),
    method: 'put'
  });
}

/*********** 积分兑换订单 *************/
function fetchOrderList(query) {
  return (0, _request.default)({
    url: '/point_shop/orders',
    method: 'get',
    params: query
  });
}
function confirmOrder(id) {
  return (0, _request.default)({
    url: "/point_shop/orders/".concat(id, "/confirm"),
    method: 'put'
  });
}
function cancelOrder(id) {
  return (0, _request.default)({
    url: "/point_shop/orders/".concat(id, "/cancel"),
    method: 'put'
  });
}