"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.number.constructor");
var _net_bar = require("@/api/net_bar");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: "NetBarDeviceAddForm",
  components: {},
  props: {
    storeId: {
      type: [String, Number]
    },
    visible: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    }
  },
  watch: {
    visible: function visible() {
      var _this = this;
      this.dialogVisible = this.visible;
      if (this.visible) {
        // fetchStoreDevicePriceSimpleList(this.storeId).then(response => {
        //   this.devicePrices = response.data
        // });
        (0, _net_bar.getDeviceConfigurationList)().then(function (response) {
          _this.deviceConfigurations = response.data;
        });
      }
    }
  },
  data: function data() {
    var _this2 = this;
    var checkDeviceSet = function checkDeviceSet(rule, value, callback) {
      for (var i = 0; i < _this2.deviceForm.devices.length; i++) {
        if (!_this2.deviceForm.devices[i].name || !_this2.deviceForm.devices[i].code) {
          callback(new Error("\u8BF7\u5B8C\u5584\u8BBE\u5907\u4FE1\u606F"));
          return;
        }
      }
      callback();
    };
    return {
      devicePrices: [],
      deviceConfigurations: [],
      dialogVisible: false,
      formLoading: false,
      submitLoading: false,
      deviceForm: {
        devices: [{
          name: undefined,
          code: undefined
        }],
        graphic_card: undefined,
        display: undefined,
        cpu: undefined,
        device_price_id: undefined
      },
      deviceFormRules: {
        // devices: [{ validator: checkDeviceSet}],
        graphic_card: [{
          required: true,
          message: '请选择显卡',
          trigger: 'change'
        }],
        display: [{
          required: true,
          message: '请选择显示器',
          trigger: 'change'
        }],
        cpu: [{
          required: true,
          message: '请选择CPU',
          trigger: 'change'
        }],
        ram: [{
          required: true,
          message: '请选择内存',
          trigger: 'change'
        }]
        // device_price_id: [{ required: true, message: '请选择计费规则', trigger: 'change' }],
      },

      deviceFormDeviceError: false
    };
  },
  mounted: function mounted() {},
  methods: {
    addDeviceItem: function addDeviceItem() {
      this.deviceForm.devices.push({
        name: undefined,
        code: undefined
      });
    },
    removeDeviceItem: function removeDeviceItem(index) {
      this.deviceForm.devices.splice(index, 1);
    },
    closeHanlder: function closeHanlder() {
      this.deviceForm.devices = [{
        name: undefined,
        code: undefined
      }];
      this.$refs['deviceForm'].resetFields();
      this.$emit('update:id', "");
      this.$emit('update:visible', false);
    },
    submitHandler: function submitHandler() {
      var _this3 = this;
      console.log(this.deviceForm);
      this.$refs['deviceForm'].validate(function (valid) {
        _this3.deviceFormDeviceError = false;
        for (var i = 0; i < _this3.deviceForm.devices.length; i++) {
          if (!_this3.deviceForm.devices[i].name || !_this3.deviceForm.devices[i].code) {
            _this3.deviceFormDeviceError = true;
            return;
          }
        }
        if (valid && !_this3.deviceFormDeviceError) {
          _this3.doCreate();
        }
      });
    },
    doCreate: function doCreate() {
      var _this4 = this;
      this.submitLoading = true;
      (0, _net_bar.createStoreDevice)(this.storeId, this.deviceForm).then(function () {
        _this4.$message.success("操作成功");
        _this4.$emit('success');
        _this4.dialogVisible = false;
      }).finally(function () {
        _this4.submitLoading = false;
      });
    }
  }
};
exports.default = _default2;