"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _banner = require("@/api/banner");
var _hotel = require("@/api/hotel");
var _auth = require("@/utils/auth");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "Banner",
  data: function data() {
    return {
      list: null,
      imageUrl: "",
      dialogFormVisible: false,
      temp: {
        thumbnail: "",
        desc: "",
        path: "",
        order: 0,
        redirect_type: 0,
        is_show: 1
      },
      areas: [],
      dialogTitle: "添加banner",
      header: {
        Authorization: "Bearer " + (0, _auth.getToken)()
      }
    };
  },
  created: function created() {
    var _this = this;
    this.getList();
    if ((0, _auth.getGrade)() < 1) {
      (0, _hotel.getArea)().then(function (response) {
        _this.areas = response.data;
      });
    }
  },
  methods: {
    doAdd: function doAdd() {
      this.dialogTitle = "添加banner";
      this.temp = {
        thumbnail: "",
        desc: "",
        path: "",
        order: 0,
        redirect_type: 0,
        is_show: 1,
        area_id: ""
      };
      this.dialogFormVisible = true;
    },
    getList: function getList() {
      var _this2 = this;
      this.listLoading = true;
      (0, _banner.BannerList)().then(function (response) {
        _this2.list = response.data;
      });
      this.listLoading = false;
    },
    handleImageSuccess: function handleImageSuccess(res) {
      if (res.code == 0) {
        this.temp.thumbnail = res.data.filepath;
        this.$forceUpdate();
        this.$message.success("上传成功！");
      } else {
        this.$message.error("上传失败");
      }
    },
    createBanner: function createBanner() {
      var _this3 = this;
      if (this.dialogTitle == "添加banner") {
        (0, _banner.addBanner)(this.temp).then(function (res) {
          if (res.code == 0) {
            _this3.$message.success("添加成功！");
            _this3.getList();
            _this3.temp = {
              thumbnail: "",
              desc: "",
              path: "",
              order: 0,
              redirect_type: 0,
              is_show: 1
            };
            _this3.dialogFormVisible = !_this3.dialogFormVisible;
          } else {
            _this3.$message.error(res.msg);
          }
        });
      } else {
        (0, _banner.updateBanner)(this.temp).then(function (res) {
          if (res.code == 0) {
            _this3.$message.success("编辑成功！");
            _this3.getList();
            _this3.temp = {
              thumbnail: "",
              full_pic: "",
              desc: "",
              goods_id: "",
              type: 0,
              order: 0,
              height: 0
            };
            _this3.dialogFormVisible = !_this3.dialogFormVisible;
          } else {
            _this3.$message.error(res.msg);
          }
          _this3.dialogTitle = "添加banner";
        });
      }
    },
    handleShow: function handleShow(id) {
      var _this4 = this;
      (0, _banner.showBanner)({
        id: id
      }).then(function (res) {
        if (res.data.code == 0) {
          _this4.$message.success(res.data.msg);
          _this4.getList();
        } else {
          _this4.$message.error(res.data.msg);
        }
      });
    },
    deleteBanner: function deleteBanner(id) {
      var _this5 = this;
      (0, _banner.deleteBanner)({
        id: id
      }).then(function (res) {
        if (res.code == 0) {
          _this5.$message.success("删除成功！");
          _this5.getList();
        } else {
          _this5.$message.error(res.msg);
        }
      });
    },
    updateAction: function updateAction(row) {
      this.temp = row;
      this.dialogTitle = "编辑banner";
      this.dialogFormVisible = !this.dialogFormVisible;
    }
  }
};
exports.default = _default;