"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.to-string");
var _moment = _interopRequireDefault(require("moment"));
var _mixin = _interopRequireDefault(require("@/utils/mixin"));
var _net_bar = require("@/api/net_bar");
var _fitter_select_mult = _interopRequireDefault(require("@/views/components/_fitter_select_mult"));
var _image_preview = _interopRequireDefault(require("@/views/components/_image_preview"));
var _cost_add_form = _interopRequireDefault(require("./cost_add_form.vue"));
var _cost_edit_form = _interopRequireDefault(require("./cost_edit_form.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "NetBarFinanceBillCost",
  mixins: [_mixin.default],
  components: {
    FitterSelectMult: _fitter_select_mult.default,
    ImagePreview: _image_preview.default,
    NetBarFinanceBillCostAddForm: _cost_add_form.default,
    NetBarFinanceBillCostEditForm: _cost_edit_form.default
  },
  data: function data() {
    return {
      billId: "",
      bill: {},
      feeTypes: [],
      billDateRange: "",
      list: [],
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 20,
        fee_types: [],
        voucher_desc: ""
      },
      pagination: "",
      costAddFormVisible: false,
      costId: "",
      costEditFormVisible: false,
      previewImages: [],
      imagePreviewVisible: false
    };
  },
  created: function created() {
    this.billId = this.$route.params.id;
    this.getBill();
    this.getFeeTypes();
    this.getList();
  },
  methods: {
    goBack: function goBack() {
      this.$router.back();
    },
    fmtBillDate: function fmtBillDate(date) {
      date = date.toString();
      return (0, _moment.default)(date.slice(0, 4) + '-' + date.slice(4, 6) + '-' + date.slice(6, 8)).format("YYYY-MM-DD");
    },
    enableBusinessEntry: function enableBusinessEntry() {
      // 业务录入
      // 账单初始状态 操作时间运行 非财务
      if (this.bill.status == 10 && this.bill.enable_business_entry && !this.isRoleBill()) {
        return true;
      }
      return false;
    },
    getBill: function getBill() {
      var _this = this;
      (0, _net_bar.fetchFinanceBill)(this.billId).then(function (result) {
        var bill = result.data;
        _this.bill = bill;
        _this.billDateRange = [_this.fmtBillDate(bill.start_date), _this.fmtBillDate(bill.end_date)];
      });
    },
    getFeeTypes: function getFeeTypes() {
      var _this2 = this;
      (0, _net_bar.getFinanceBillCostFeeTypeList)().then(function (res) {
        _this2.feeTypes = res.data;
      });
    },
    onFeeTypeChange: function onFeeTypeChange(check) {
      this.listQuery.fee_types = check.is_all ? [] : check.checks;
    },
    getList: function getList() {
      var _this3 = this;
      this.listLoading = true;
      (0, _net_bar.fetchFinanceBillCostList)(this.billId, this.listQuery).then(function (response) {
        var data = response.data;
        _this3.list = data.data;
        _this3.pagination = {
          total: data.total,
          fee_total: data.fee_total
        };
        _this3.listQuery.page = data.current_page;
        _this3.listLoading = false;
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    addHandler: function addHandler() {
      this.costAddFormVisible = true;
    },
    editHandler: function editHandler(record) {
      this.costId = record.id;
      this.costEditFormVisible = true;
    },
    deleleHandler: function deleleHandler(record) {
      var _this4 = this;
      this.$confirm('<span style="color: #F56C6C;">删除后如需恢复，请重新添加</span>', '确定要删除当前费用吗？', {
        dangerouslyUseHTMLString: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _net_bar.deleteFinanceBillCost)(_this4.billId, record.id).then(function () {
          _this4.getList();
          _this4.$message.success("操作成功");
        });
      }).catch(function () {});
    },
    showVoucherHandler: function showVoucherHandler(record) {
      this.previewImages = JSON.parse(record.voucher_desc);
      this.imagePreviewVisible = true;
    },
    exportHandler: function exportHandler() {
      window.open((0, _net_bar.getFinanceBillCostDownloadUrl)(this.billId, this.listQuery));
    }
  }
};
exports.default = _default;