var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "thrid-order-form-dialog",
      attrs: {
        visible: _vm.dialogVisible,
        title: "编辑订单",
        "close-on-click-modal": false,
        width: "540px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.orderFormCloseHanlder,
      },
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.formLoading,
              expression: "formLoading",
            },
          ],
          ref: "orderForm",
          attrs: {
            model: _vm.orderForm,
            rules: _vm.orderFormRules,
            "label-suffix": ":",
            "label-width": "85px",
            "label-position": "right",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "订单编号", prop: "order_no", required: "" } },
            [
              _c("el-input", {
                staticStyle: { width: "255px" },
                attrs: {
                  placeholder: "请输入",
                  disabled: _vm.orderFormIsEdit(),
                },
                model: {
                  value: _vm.orderForm.order_no,
                  callback: function ($$v) {
                    _vm.$set(_vm.orderForm, "order_no", $$v)
                  },
                  expression: "orderForm.order_no",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "酒店名称", prop: "hotel_id", required: "" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "255px" },
                  attrs: {
                    filterable: "",
                    placeholder: "请选择",
                    disabled: _vm.orderFormIsEdit(),
                  },
                  model: {
                    value: _vm.orderForm.hotel_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.orderForm, "hotel_id", $$v)
                    },
                    expression: "orderForm.hotel_id",
                  },
                },
                _vm._l(_vm.hotelList, function (item) {
                  return _c("el-option", {
                    key: item.hotel_id,
                    attrs: { label: item.name, value: item.hotel_id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "调整金额", prop: "amount", required: "" } },
            [
              _c("el-input", {
                staticStyle: { width: "255px" },
                attrs: { placeholder: "请输入" },
                model: {
                  value: _vm.orderForm.amount,
                  callback: function ($$v) {
                    _vm.$set(_vm.orderForm, "amount", $$v)
                  },
                  expression: "orderForm.amount",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "承担方",
                prop: "adjust_undertaker",
                required: "",
              },
            },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "255px" },
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.orderForm.adjust_undertaker,
                    callback: function ($$v) {
                      _vm.$set(_vm.orderForm, "adjust_undertaker", $$v)
                    },
                    expression: "orderForm.adjust_undertaker",
                  },
                },
                _vm._l(_vm.adjustUndertakers, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.title, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "原因", prop: "reason", required: "" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  autosize: { minRows: 2, maxRows: 5 },
                  placeholder: "请输入",
                  disabled: _vm.orderFormIsEdit(),
                },
                model: {
                  value: _vm.orderForm.reason,
                  callback: function ($$v) {
                    _vm.$set(_vm.orderForm, "reason", $$v)
                  },
                  expression: "orderForm.reason",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.orderFormSubmitLoading },
              on: { click: _vm.orderFormSubmitHandler },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }