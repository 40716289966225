var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "build-bill-container" },
    [
      _c(
        "el-button",
        { staticClass: "filter-item", on: { click: _vm.buildBillHandler } },
        [_vm._v("\n    生成账单\n  ")]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "build-bill-form-dialog",
          attrs: {
            visible: _vm.dialogVisible,
            title: "生成账单",
            "close-on-click-modal": false,
            width: "540px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            close: _vm.billFormCloseHanlder,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "billForm",
              attrs: {
                model: _vm.billForm,
                rules: _vm.billFormRules,
                "label-suffix": ":",
                "label-width": "125px",
                "label-position": "right",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "账单周期", prop: "bill_week" } },
                [
                  _c("el-date-picker", {
                    staticClass: "filter-item",
                    staticStyle: { "margin-bottom": "0" },
                    attrs: {
                      "picker-options": { firstDayOfWeek: 1 },
                      type: "week",
                      "value-format": "yyyyMMdd",
                      format: "yyyy 年 第 WW 周",
                      placeholder: "账单周期",
                    },
                    on: { change: _vm.onBillWeekChange },
                    model: {
                      value: _vm.billForm.bill_week,
                      callback: function ($$v) {
                        _vm.$set(_vm.billForm, "bill_week", $$v)
                      },
                      expression: "billForm.bill_week",
                    },
                  }),
                  _vm._v(" "),
                  _vm.billWeekRange
                    ? _c(
                        "div",
                        {
                          staticClass: "bill-range-tips",
                          class: { error: _vm.billExists },
                        },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.billWeekRange[0]) +
                                " 至 " +
                                _vm._s(_vm.billWeekRange[1])
                            ),
                          ]),
                          _vm._v(" "),
                          _vm.billExists
                            ? _c("span", [_vm._v("账单已生成")])
                            : _vm._e(),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "第三方订单复核", required: "" } },
                [
                  _vm.billComparisonStatus == _vm.billCmpStart
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            size: "small",
                            disabled: !_vm.billForm.bill_week,
                          },
                          on: { click: _vm.billComparisonHandler },
                        },
                        [_vm._v("\n          开始复核\n        ")]
                      )
                    : _vm.billComparisonStatus == _vm.billCmping
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                size: "small",
                                loading: true,
                              },
                            },
                            [_vm._v("\n            订单复核中\n          ")]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "bill-tips" }, [
                            _vm._v("订单复核需要一定时间，请耐心等待"),
                          ]),
                        ],
                        1
                      )
                    : _vm.billComparisonStatus == _vm.billCmpCompleted ||
                      _vm.billComparisonStatus == _vm.billCmpChecked
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                size: "small",
                                disabled: !_vm.billForm.bill_week,
                              },
                              on: { click: _vm.billComparisonHandler },
                            },
                            [_vm._v("\n            再次复核订单\n          ")]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "bill-tips" }, [
                            _c("span", [_vm._v("复核完成！")]),
                            _c("span", [
                              _vm._v("发现【异常订单】"),
                              _c("span", { staticClass: "error-num" }, [
                                _vm._v(
                                  _vm._s(_vm.billComparisonInfo.error || 0)
                                ),
                              ]),
                              _vm._v("单"),
                            ]),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _vm.billComparisonStatus == _vm.billCmpCompleted
                ? [
                    _vm.billComparisonInfo.error > 0
                      ? _c(
                          "el-button",
                          { on: { click: _vm.billCmpCheckHandler } },
                          [_vm._v("\n          确认复核结果\n        ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        on: {
                          click: function ($event) {
                            _vm.dialogVisible = false
                          },
                        },
                      },
                      [_vm._v("取 消")]
                    ),
                    _vm._v(" "),
                    [
                      _vm.billComparisonInfo.error > 0
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.toAbnormal },
                            },
                            [_vm._v("\n            查看异常订单\n          ")]
                          )
                        : _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.billCmpCheckHandler },
                            },
                            [_vm._v("\n            确认复核结果\n          ")]
                          ),
                    ],
                  ]
                : _vm.billComparisonStatus == _vm.billCmpChecked
                ? [
                    _c(
                      "el-button",
                      {
                        on: {
                          click: function ($event) {
                            _vm.dialogVisible = false
                          },
                        },
                      },
                      [_vm._v("取 消")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          loading: _vm.billFormSubmitLoading,
                          disabled: _vm.billExists,
                          type: "primary",
                        },
                        on: { click: _vm.billFormSubmitHandler },
                      },
                      [_vm._v("\n          生成账单\n        ")]
                    ),
                  ]
                : [
                    _c(
                      "el-button",
                      {
                        on: {
                          click: function ($event) {
                            _vm.dialogVisible = false
                          },
                        },
                      },
                      [_vm._v("取 消")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      { attrs: { type: "primary", disabled: "" } },
                      [_vm._v("\n          确认复核结果\n        ")]
                    ),
                  ],
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }