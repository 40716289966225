"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addWOAccount = addWOAccount;
exports.addWORole = addWORole;
exports.addWOTemplate = addWOTemplate;
exports.editWOAccount = editWOAccount;
exports.editWOAccountStatus = editWOAccountStatus;
exports.editWORole = editWORole;
exports.editWORoleStatus = editWORoleStatus;
exports.editWOTemplate = editWOTemplate;
exports.editWOTemplateStatus = editWOTemplateStatus;
exports.getAccount = getAccount;
exports.getAccountList = getAccountList;
exports.getFeedbackStatusList = getFeedbackStatusList;
exports.getRoleList = getRoleList;
exports.getSimlpeRoleList = getSimlpeRoleList;
exports.getTemplate = getTemplate;
exports.getTemplateLevelList = getTemplateLevelList;
exports.getTemplateList = getTemplateList;
exports.getTemplateTypeList = getTemplateTypeList;
exports.getUsableHotelList = getUsableHotelList;
exports.getUsablePlatformAccountsList = getUsablePlatformAccountsList;
exports.getWorkOrder = getWorkOrder;
exports.getWorkOrderDownloadUrl = getWorkOrderDownloadUrl;
exports.getWorkOrderList = getWorkOrderList;
exports.processWorkOrderClose = processWorkOrderClose;
exports.processWorkOrderReport = processWorkOrderReport;
var _request = _interopRequireDefault(require("@/utils/request"));
var _auth = require("@/utils/auth");
var _helper = require("@/utils/helper");
function getAccountList(data) {
  return (0, _request.default)({
    url: 'work_order/config/accounts',
    method: 'get',
    params: data || {}
  });
}
function getAccount(id) {
  return (0, _request.default)({
    url: "/work_order/config/accounts/".concat(id),
    method: 'get'
  });
}
function getUsablePlatformAccountsList(data) {
  return (0, _request.default)({
    url: 'work_order/config/usable_platform_accounts',
    method: 'get',
    params: data || {}
  });
}
function addWOAccount(data) {
  return (0, _request.default)({
    url: '/work_order/config/accounts',
    method: 'post',
    data: data
  });
}
function editWOAccount(id, data) {
  return (0, _request.default)({
    url: "/work_order/config/accounts/".concat(id),
    method: 'put',
    data: data
  });
}
function editWOAccountStatus(id, status) {
  return (0, _request.default)({
    url: "/work_order/config/accounts/".concat(id, "/status"),
    method: 'put',
    data: {
      status: status
    }
  });
}
function getUsableHotelList() {
  return (0, _request.default)({
    url: 'work_order/config/usable_hotels',
    method: 'get'
  });
}
function getRoleList(data) {
  return (0, _request.default)({
    url: 'work_order/config/roles',
    method: 'get',
    params: data || {}
  });
}
function addWORole(data) {
  return (0, _request.default)({
    url: '/work_order/config/roles',
    method: 'post',
    data: data
  });
}
function editWORole(id, data) {
  return (0, _request.default)({
    url: "/work_order/config/roles/".concat(id),
    method: 'put',
    data: data
  });
}
function editWORoleStatus(id, status) {
  return (0, _request.default)({
    url: "/work_order/config/roles/".concat(id, "/status"),
    method: 'put',
    data: {
      status: status
    }
  });
}
function getSimlpeRoleList(data) {
  return (0, _request.default)({
    url: 'work_order/config/simple_roles',
    method: 'get',
    params: data || {}
  });
}
function getTemplateList(data) {
  return (0, _request.default)({
    url: 'work_order/templates',
    method: 'get',
    params: data || {}
  });
}
function getTemplate(id) {
  return (0, _request.default)({
    url: "work_order/templates/".concat(id),
    method: 'get'
  });
}
function getTemplateTypeList(data) {
  return (0, _request.default)({
    url: 'work_order/template_types',
    method: 'get',
    params: data || {}
  });
}
function getTemplateLevelList(data) {
  return (0, _request.default)({
    url: 'work_order/template_levels',
    method: 'get',
    params: data || {}
  });
}
function addWOTemplate(data) {
  return (0, _request.default)({
    url: '/work_order/templates',
    method: 'post',
    data: data
  });
}
function editWOTemplate(id, data) {
  return (0, _request.default)({
    url: "/work_order/templates/".concat(id),
    method: 'put',
    data: data
  });
}
function editWOTemplateStatus(id, status) {
  return (0, _request.default)({
    url: "/work_order/templates/".concat(id, "/status"),
    method: 'put',
    data: {
      status: status
    }
  });
}
function getFeedbackStatusList(data) {
  return (0, _request.default)({
    url: 'work_order/feedback_status',
    method: 'get',
    params: data || {}
  });
}
function getWorkOrderList(data) {
  return (0, _request.default)({
    url: 'work_order/work_orders',
    method: 'get',
    params: data || {}
  });
}
function getWorkOrderDownloadUrl(query) {
  query.api_token = (0, _auth.getToken)();
  var queryStr = (0, _helper.parseUrl)(query);
  return "".concat(process.env.VUE_APP_BASE_API, "/work_order/work_order_export?").concat(queryStr);
}
function getWorkOrder(id) {
  return (0, _request.default)({
    url: "/work_order/work_orders/".concat(id),
    method: 'get'
  });
}
function processWorkOrderClose(id, data) {
  return (0, _request.default)({
    url: "/work_order/work_orders/".concat(id, "/close"),
    method: 'put',
    data: data
  });
}
function processWorkOrderReport(id, data) {
  return (0, _request.default)({
    url: "/work_order/work_orders/".concat(id, "/report"),
    method: 'put',
    data: data
  });
}