var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container point-lottery-container" },
    [
      _c(
        "el-descriptions",
        { attrs: { title: "基础信息" } },
        [
          _c("el-descriptions-item", { attrs: { label: "活动名称" } }, [
            _vm._v(_vm._s(_vm.activity.title)),
          ]),
          _vm._v(" "),
          _c("el-descriptions-item", { attrs: { label: "状态" } }, [
            _vm.activity.status == -1
              ? _c("span", [_vm._v("已结束")])
              : _vm.activity.status == 0
              ? _c("span", [_vm._v("未开始")])
              : _vm.activity.status == 1
              ? _c("span", { staticStyle: { color: "#67C23A" } }, [
                  _vm._v("进行中"),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("el-descriptions-item", { attrs: { label: "活动时间" } }, [
            _vm._v(
              _vm._s(_vm.activity.start_time) +
                " ~ " +
                _vm._s(_vm.activity.end_time)
            ),
          ]),
          _vm._v(" "),
          _c("el-descriptions-item", { attrs: { label: "创建人" } }, [
            _vm._v(_vm._s(_vm.activity.create_uname)),
          ]),
          _vm._v(" "),
          _c("el-descriptions-item", { attrs: { label: "活动规则" } }, [
            _c(
              "div",
              {
                staticClass: "activity-rules",
                attrs: { title: _vm.activity.rules },
              },
              [_vm._v(_vm._s(_vm.activity.rules))]
            ),
          ]),
          _vm._v(" "),
          _c("el-descriptions-item", { attrs: { label: "分享文案" } }, [
            _c(
              "div",
              {
                staticClass: "share-text",
                attrs: { title: _vm.activity.share_text },
              },
              [_vm._v(_vm._s(_vm.activity.share_text))]
            ),
          ]),
          _vm._v(" "),
          _c("el-descriptions-item", { attrs: { label: "分享图" } }, [
            _vm.activity.share_image
              ? _c("img", {
                  staticStyle: { "max-width": "80px" },
                  attrs: { src: _vm.activity.share_image },
                })
              : _vm._e(),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "div",
        [
          _c("h4", { staticStyle: { "font-size": "16px" } }, [
            _vm._v("奖品列表"),
          ]),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.prizeLoading,
                  expression: "prizeLoading",
                },
              ],
              key: "prizeList",
              staticClass: "prize-table",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.activity.prizes,
                "element-loading-text": "给我一点时间",
                border: "",
                fit: "",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "商品类型", "class-name": "overflow-on" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.prizeTypeMap[scope.row.type] || "未知") +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "商品名称", "class-name": "overflow-on" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(scope.row.title) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "图片", "class-name": "overflow-on" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticStyle: { "text-align": "center" } }, [
                          _c("img", {
                            staticStyle: { width: "50px" },
                            attrs: { src: scope.row.thumbnail },
                          }),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "剩余库存 / 初始库存",
                  "class-name": "overflow-on",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(scope.row.remain_stock) +
                            " / " +
                            _vm._s(scope.row.stock) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "div",
        [
          _c("h4", { staticStyle: { "font-size": "16px" } }, [
            _vm._v("抽奖记录"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "filter-container filter-container-flex" }, [
            _c(
              "div",
              { staticStyle: { "text-align": "right", width: "100%" } },
              [
                _c(
                  "el-select",
                  {
                    staticClass: "filter-item",
                    attrs: {
                      "default-first-option": "",
                      placeholder: "奖品类型",
                      clearable: "",
                    },
                    on: { change: _vm.onPrizeTypeChange },
                    model: {
                      value: _vm.listQuery.prize_type,
                      callback: function ($$v) {
                        _vm.$set(_vm.listQuery, "prize_type", $$v)
                      },
                      expression: "listQuery.prize_type",
                    },
                  },
                  _vm._l(_vm.prizeTypes, function (item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.title, value: item.id },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              key: "tableList",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.list,
                "element-loading-text": "给我一点时间",
                border: "",
                fit: "",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "奖品名称",
                  "class-name": "overflow-on",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(scope.row.prize_title) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "奖品类型",
                  "class-name": "overflow-on",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.prizeTypeMap[scope.row.prize_type] || "未知"
                            ) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "用户",
                  "class-name": "overflow-on",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(scope.row.user_name) +
                            "（ID:" +
                            _vm._s(scope.row.user_id) +
                            "）\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "抽取时间",
                  "class-name": "overflow-on",
                  width: "180",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(scope.row.created_at) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "消耗积分值",
                  "class-name": "overflow-on",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(scope.row.use_point) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "发放状态",
                  "class-name": "overflow-on",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.status == 1
                          ? _c("span", [_vm._v("已发放")])
                          : _c("span", { staticStyle: { color: "#E6A23C" } }, [
                              _vm._v("待发放"),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  fixed: "right",
                  label: "操作",
                  width: "140",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.status == 0
                          ? _c(
                              "el-link",
                              {
                                attrs: { type: "primary", underline: false },
                                on: {
                                  click: function ($event) {
                                    return _vm.writeOffHandler(scope.row)
                                  },
                                },
                              },
                              [_vm._v("\n            确认发放\n          ")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "pagination-container text-right" },
            [
              _c("el-pagination", {
                attrs: {
                  background: "",
                  total: _vm.pagination.total,
                  "current-page": _vm.listQuery.page,
                  "page-sizes": [10, 20, 50],
                  "page-size": _vm.listQuery.limit,
                  layout: "total, sizes, prev, pager, next, jumper",
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }