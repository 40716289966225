"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _mixin = _interopRequireDefault(require("@/utils/mixin"));
var _work_order = require("@/api/work_order");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "WorkOrderConfigRole",
  mixins: [_mixin.default],
  data: function data() {
    return {
      // 列表及查询
      listQuery: {
        page: 1,
        limit: 20
      },
      pagination: {},
      listLoading: false,
      roles: [],
      dialogFormVisible: false,
      roleFormTitle: "新建工单角色",
      roleForm: {
        id: "",
        name: ""
      },
      roleFormRules: {
        name: [{
          required: true,
          message: '请输入角色名称',
          trigger: 'blur'
        }]
      },
      roleFormConfirmLoading: false
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      (0, _work_order.getRoleList)(this.listQuery).then(function (response) {
        var data = response.data;
        _this.pagination = {
          total: data.total,
          currentPage: data.current_page,
          pageSize: data.per_page
        };
        _this.roles = data.data;
        _this.listQuery.page = data.current_page;
        _this.listLoading = false;
      });
    },
    addHandler: function addHandler() {
      this.roleFormTitle = '新建工单角色';
      this.roleForm.id = '';
      this.dialogFormVisible = true;
    },
    editHandler: function editHandler(record) {
      this.roleFormTitle = '编辑工单角色';
      this.roleForm.id = record.id;
      this.roleForm.name = record.name;
      this.dialogFormVisible = true;
    },
    statusChangeHandler: function statusChangeHandler(id, status) {
      var _this2 = this;
      (0, _work_order.editWORoleStatus)(id, status).then(function (res) {
        _this2.$message.success("操作成功");
        _this2.handleFilter();
      });
    },
    roleFormCloseHanlder: function roleFormCloseHanlder() {
      this.$refs['roleForm'].resetFields();
      this.roleForm.id = '';
    },
    roleFormSubmitHandler: function roleFormSubmitHandler() {
      var _this3 = this;
      this.$refs['roleForm'].validate(function (valid) {
        if (valid) {
          var form = JSON.parse(JSON.stringify(_this3.roleForm));
          _this3.roleFormConfirmLoading = true;
          if (form.id) {
            (0, _work_order.editWORole)(form.id, form).then(function (res) {
              _this3.roleForm.id = '';
              _this3.$message.success("编辑成功");
              _this3.dialogFormVisible = false;
              _this3.handleFilter();
            }).finally(function () {
              _this3.roleFormConfirmLoading = false;
            });
          } else {
            // 添加分类
            (0, _work_order.addWORole)(form).then(function (res) {
              _this3.$message.success("添加成功");
              _this3.dialogFormVisible = false;
              _this3.handleFilter();
            }).finally(function () {
              _this3.roleFormConfirmLoading = false;
            });
          }
        }
      });
    },
    toTemplatePage: function toTemplatePage(record) {
      this.$router.push("/work_order/template?&wo_role_id=".concat(record.id));
      this.reload();
    }
  }
};
exports.default = _default;