var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container calendar-list-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              staticStyle: { "margin-left": "10px" },
              attrs: { type: "primary", icon: "edit" },
              on: {
                click: function ($event) {
                  return _vm.addHandler()
                },
              },
            },
            [_vm._v("\n      添加\n    ")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          key: "tableList",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            "element-loading-text": "给我一点时间",
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "序号", width: "65" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.id))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "图片" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("img", {
                      staticStyle: { "max-width": "120px" },
                      attrs: { src: scope.row.image },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "展示日期" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(scope.row.start_date) +
                          " ~ " +
                          _vm._s(scope.row.end_date)
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "通知形式" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.type == 1
                      ? _c("span", [_vm._v("弹框")])
                      : scope.row.type == 2
                      ? _c("span", [_vm._v("Banner")])
                      : _c("span", [_vm._v("未知")]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "弹出频率" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.type == 1
                      ? _c("span", [
                          _vm._v(
                            _vm._s(scope.row.frequency == 1 ? "每日一次" : "--")
                          ),
                        ])
                      : _c("span", [_vm._v("--")]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "状态", width: "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.status_name))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "操作", width: "180" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.updateHandler(scope.row)
                          },
                        },
                      },
                      [_vm._v("\n          编辑\n        ")]
                    ),
                    _vm._v(" "),
                    scope.row.status == 0
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.updateStatusHandler(scope.row.id, 1)
                              },
                            },
                          },
                          [_vm._v("\n          显示\n        ")]
                        )
                      : _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.updateStatusHandler(scope.row.id, 0)
                              },
                            },
                          },
                          [_vm._v("\n          隐藏\n        ")]
                        ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "pagination-container text-right" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              total: _vm.pagination.total,
              "current-page": _vm.listQuery.page,
              "page-sizes": [10, 20, 50],
              "page-size": _vm.listQuery.limit,
              layout: "total, sizes, prev, pager, next, jumper",
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.dialogTitle,
            visible: _vm.dialogFormVisible,
            top: "50px",
            width: "580px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "popupForm",
              staticClass: "small-space",
              attrs: {
                model: _vm.popupForm,
                rules: _vm.popupFormRules,
                "label-suffix": ":",
                "label-width": "100px",
                "label-position": "right",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "展示日期", prop: "date" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "daterange",
                      "range-separator": "-",
                      "value-format": "yyyyMMdd",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                    },
                    model: {
                      value: _vm.popupForm.date,
                      callback: function ($$v) {
                        _vm.$set(_vm.popupForm, "date", $$v)
                      },
                      expression: "popupForm.date",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "弹窗描述", prop: "desc" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.popupForm.desc,
                      callback: function ($$v) {
                        _vm.$set(_vm.popupForm, "desc", $$v)
                      },
                      expression: "popupForm.desc",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "通知形式", prop: "type" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.popupForm.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.popupForm, "type", $$v)
                        },
                        expression: "popupForm.type",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("弹窗")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 2 } }, [
                        _vm._v("首页固定banner位"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "PC后台", prop: "image" } },
                [
                  _c(
                    "el-upload",
                    {
                      staticClass: "avatar-uploader",
                      attrs: {
                        action: _vm.uploadUrl,
                        headers: _vm.header,
                        "show-file-list": false,
                        "on-success": _vm.handleImageSuccess,
                      },
                    },
                    [
                      _vm.popupForm.image
                        ? _c("img", {
                            staticClass: "avatar",
                            staticStyle: { "max-width": "385px" },
                            attrs: { src: _vm.popupForm.image },
                          })
                        : _c("i", {
                            staticClass: "el-icon-plus avatar-uploader-icon",
                          }),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "img-tips" }, [
                    _vm.popupForm.type == 1
                      ? _c("span", [
                          _vm._v("弹框建议：600*600，支持png,gif，100kb左右"),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.popupForm.type == 2
                      ? _c("span", [
                          _vm._v("Banner建议：480*60，支持png,gif，100kb左右"),
                        ])
                      : _vm._e(),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "商户小程序", prop: "mp_image" } },
                [
                  _c(
                    "el-upload",
                    {
                      staticClass: "avatar-uploader",
                      attrs: {
                        action: _vm.uploadUrl,
                        headers: _vm.header,
                        "show-file-list": false,
                        "on-success": _vm.handleMpImageSuccess,
                      },
                    },
                    [
                      _vm.popupForm.mp_image
                        ? _c("img", {
                            staticClass: "avatar",
                            staticStyle: { "max-width": "385px" },
                            attrs: { src: _vm.popupForm.mp_image },
                          })
                        : _c("i", {
                            staticClass: "el-icon-plus avatar-uploader-icon",
                          }),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "img-tips" }, [
                    _vm.popupForm.type == 1
                      ? _c("span", [
                          _vm._v(
                            "弹框建议：600*830，支持png,gif，100kb左右，不上传不显示在商户小程序"
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.popupForm.type == 2
                      ? _c("span", [
                          _vm._v(
                            "Banner建议：690*220，支持png,gif，100kb左右，不上传不显示在商户小程序"
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _vm.popupForm.type == 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "弹出频率", prop: "frequency" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.popupForm.frequency,
                            callback: function ($$v) {
                              _vm.$set(_vm.popupForm, "frequency", $$v)
                            },
                            expression: "popupForm.frequency",
                          },
                        },
                        _vm._l(_vm.frequencyTypes, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "状态", prop: "status" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.popupForm.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.popupForm, "status", $$v)
                        },
                        expression: "popupForm.status",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("显示")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("隐藏")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "弹窗序号", prop: "sort" } },
                [
                  _c("el-input-number", {
                    model: {
                      value: _vm.popupForm.sort,
                      callback: function ($$v) {
                        _vm.$set(_vm.popupForm, "sort", $$v)
                      },
                      expression: "popupForm.sort",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    loading: _vm.popupFormSubmitLoading,
                  },
                  on: { click: _vm.createPopup },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }