"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.number.constructor");
var _net_bar = require("@/api/net_bar");
var _auth = require("@/utils/auth");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: "NetBarFinanceBillCostEditForm",
  components: {},
  props: {
    id: [Number, String],
    billId: {
      type: [String, Number]
    },
    visible: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    }
  },
  watch: {
    visible: function visible() {
      var _this = this;
      this.dialogVisible = this.visible;
      if (this.visible) {
        (0, _net_bar.getFinanceBillCostFeeTypeList)().then(function (res) {
          _this.feeTypes = res.data;
        });
      }
    },
    id: function id() {
      if (this.id) {
        this.getDetail();
      }
    }
  },
  data: function data() {
    return {
      feeTypes: [],
      dialogVisible: false,
      formLoading: false,
      submitLoading: false,
      submitDisabled: false,
      costItemForm: {
        id: undefined,
        fee_type: undefined,
        fee: undefined,
        fee_desc: undefined,
        voucher_type: undefined,
        voucher_i8: undefined,
        voucher_file: []
      },
      costItemFormRules: {
        fee_type: [{
          required: true,
          message: '请选择费用类型',
          trigger: 'change'
        }],
        fee: [{
          required: true,
          message: '请输入费用金额',
          trigger: 'blur'
        }],
        fee_desc: [{
          required: true,
          message: '请输入费用说明',
          trigger: 'blur'
        }],
        voucher_type: [{
          required: true,
          message: '请选择费用依据',
          trigger: 'change'
        }],
        voucher_i8: [{
          required: true,
          message: '请输入i8流程号',
          trigger: 'blur'
        }],
        voucher_file: [{
          required: true,
          message: '请上传凭证',
          trigger: 'blur'
        }]
      },
      uploadUrl: "",
      header: {
        Authorization: "Bearer " + (0, _auth.getToken)()
      },
      voucherImages: []
    };
  },
  mounted: function mounted() {
    this.uploadUrl = (0, _net_bar.getNetBarUploadUrl)();
  },
  methods: {
    getDetail: function getDetail() {
      var _this2 = this;
      this.formLoading = true;
      (0, _net_bar.fetchFinanceBillCost)(this.billId, this.id).then(function (result) {
        var cost = result.data;
        _this2.costItemForm = {
          fee_type: cost.fee_type,
          fee: cost.fee,
          fee_desc: cost.fee_desc,
          voucher_type: cost.voucher_type,
          voucher_i8: cost.voucher_type == 1 ? cost.voucher_desc : undefined,
          voucher_file: cost.voucher_type == 2 ? cost.voucher_desc : []
        };
        _this2.voucherImages = [];
        if (cost.voucher_type == 2 && cost.voucher_desc) {
          cost.voucher_desc.forEach(function (image, k) {
            _this2.voucherImages.push({
              name: "image_".concat(k, ".jpg"),
              url: image
            });
          });
        }
        _this2.formLoading = false;
      });
    },
    getCostItem: function getCostItem() {
      var voucherDesc = "";
      if (this.costItemForm.voucher_type == 1) {
        voucherDesc = this.costItemForm.voucher_i8;
      } else if (this.costItemForm.voucher_type == 2) {
        voucherDesc = this.costItemForm.voucher_file;
      }
      return {
        fee_type: this.costItemForm.fee_type,
        fee: this.costItemForm.fee,
        fee_desc: this.costItemForm.fee_desc,
        voucher_type: this.costItemForm.voucher_type,
        voucher_desc: voucherDesc
      };
    },
    getFeeTypeName: function getFeeTypeName(feeType) {
      for (var i = 0; i < this.feeTypes.length; i++) {
        if (this.feeTypes[i].value == feeType) {
          return this.feeTypes[i].label;
        }
      }
    },
    beforeUpload: function beforeUpload(file) {
      var isJPG = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
      if (!isJPG) {
        this.$message.error('请上传jpg/png/jpeg格式的图片!');
      }
      var isLt10M = file.size / 1024 / 1024 < 10;
      if (!isLt10M) {
        this.$message.error('上传图片大小不能超过 10MB!');
      }
      return isJPG && isLt10M;
    },
    onUploadProgress: function onUploadProgress() {
      this.submitDisabled = true;
    },
    onUploadSuccess: function onUploadSuccess() {
      this.submitDisabled = false;
    },
    onUploadChange: function onUploadChange(file, fileList) {
      this.voucherImages = fileList;
    },
    closeHanlder: function closeHanlder() {
      this.$refs['costItemForm'].resetFields();
      this.$emit('update:id', "");
      this.$emit('update:visible', false);
    },
    submitHandler: function submitHandler() {
      var _this3 = this;
      this.costItemForm.voucher_file = [];
      this.voucherImages.forEach(function (image) {
        _this3.costItemForm.voucher_file.push(image.response ? image.response.data.filepath : image.url);
      });
      this.$refs['costItemForm'].validate(function (valid) {
        if (valid) {
          _this3.submitLoading = true;
          (0, _net_bar.updateFinanceBillCost)(_this3.billId, _this3.id, _this3.getCostItem()).then(function () {
            _this3.$message.success("操作成功");
            _this3.$emit('success');
            _this3.dialogVisible = false;
          }).finally(function () {
            _this3.submitLoading = false;
          });
        }
      });
    }
  }
};
exports.default = _default2;