"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _order = require("@/api/order");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "order_alipay",
  data: function data() {
    return {
      areas: [],
      list: [],
      sourceList: [],
      dialogFormVisible: false,
      listQuery: {
        page: 1,
        limit: 20,
        name: ""
      },
      pagination: ""
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    resetForm: function resetForm() {
      this.listQuery.poiName = "";
      this.listQuery.date = "";
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      (0, _order.getAliPayList)(this.listQuery).then(function (response) {
        var data = response.data;
        _this.list = data.data;
        _this.pagination = data;
        _this.listQuery.page = data.current_page;
        _this.listLoading = false;
        _this.sourceList = data.source;
      });
    },
    confirm: function confirm(id) {
      var _this2 = this;
      this.$prompt('请输入收款金额，单位：元', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function (value) {
        (0, _order.aliPayConfirm)(id, {
          "amount": value.value
        }).then(function (response) {
          if (response.code === 0) {
            _this2.getList();
            _this2.$message.success(response.msg);
          } else {
            _this2.$message.error(response.msg);
          }
        });
      }).catch(function () {});
    }
  }
};
exports.default = _default;