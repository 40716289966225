"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.object.keys");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.to-string");
var _order = require("@/api/order");
var _hotel = require("@/api/hotel");
var _room = require("@/api/room");
var _auth = require("@/utils/auth");
var _orderDetail = _interopRequireDefault(require("./orderDetail.vue"));
var _OrderRefundDialog = _interopRequireDefault(require("./components/OrderRefundDialog.vue"));
var _moment = _interopRequireDefault(require("moment"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "Self",
  components: {
    OrderDetail: _orderDetail.default,
    OrderRefundDialog: _OrderRefundDialog.default
  },
  filters: {
    dateFormat: function dateFormat(value) {
      if (!value) return '';
      var date = value.toString();
      return date.slice(4, 6) + '月' + date.slice(6, 8) + '日';
    },
    previceDateFormat: function previceDateFormat(value) {
      if (!value) return '';
      var date = value.toString();
      return "".concat(date.slice(0, 4), "-").concat(date.slice(4, 6), "-").concat(date.slice(6, 8));
    }
  },
  data: function data() {
    return {
      orderId: '',
      areas: [],
      todayDate: parseInt((0, _moment.default)().format("YYYYMMDD")),
      list: null,
      lastWeekBillEndDate: 99999999,
      statusOptions: [{
        value: 25,
        label: '付款成功'
      }, {
        value: 26,
        label: '已关闭,买家取消订单'
      }, {
        value: 27,
        label: '已关闭,酒店取消订单'
      }, {
        value: 28,
        label: '已退款'
      }, {
        value: 29,
        label: '部分退款'
      }, {
        value: 30,
        label: '已确认'
      }, {
        value: 40,
        label: '已完成'
      }],
      statusMap: {
        25: "付款成功",
        26: "已关闭,买家取消订单",
        31: "已关闭,买家取消订单",
        27: "已关闭,酒店取消订单",
        28: "已退款",
        29: "部分退款",
        30: "已确认",
        40: "已完成"
      },
      hotelList: [],
      selfHotelList: [],
      listQuery: {},
      listLoading: false,
      pagination: "",
      sellers: [],
      otas: [],
      importOta: 1,
      importTime: 0,
      previewList: [],
      previewListLoading: false,
      previewPagination: {
        page: 1,
        limit: 10,
        total: 0
      },
      dialogImportVisible: false,
      dialogAddByTextVisible: false,
      dialogOrderByFormVisible: false,
      dialogOrderByFormTitle: '添加订单',
      orderFormConfirmLoading: false,
      orderForm: {
        id: "",
        third_id: 1,
        seller_type: "",
        hotel_id: "",
        room_id: "",
        dates: [],
        start_date: "",
        end_date: "",
        order_no: "",
        amount: "",
        contacts: "",
        mobile: "",
        room_quantity: 1
      },
      orderFormRules: {
        third_id: [{
          required: true,
          message: '请选择来源渠道',
          trigger: 'blur'
        }],
        seller_type: [{
          required: true,
          message: '请选择售卖方',
          trigger: 'blur'
        }],
        hotel_id: [{
          required: true,
          message: '请选择入住酒店',
          trigger: 'blur'
        }],
        room_id: [{
          required: true,
          message: '请选择预订房型',
          trigger: 'blur'
        }],
        dates: [{
          required: true,
          message: '请选择入离日期',
          trigger: 'blur'
        }],
        order_no: [{
          required: true,
          message: '请输入订单编号',
          trigger: 'blur'
        }],
        amount: [{
          required: true,
          message: '请输入订单金额',
          trigger: 'blur'
        }],
        contacts: [{
          required: true,
          message: '请输入入住人',
          trigger: 'blur'
        }],
        room_quantity: [{
          required: true,
          message: '请输入房间数量',
          trigger: 'blur'
        }]
      },
      orderFormHotelRooms: {},
      orderFormRooms: [],
      orderFormPickerOptions: {
        disabledDate: function disabledDate() {
          false;
        }
      },
      dialogDetailVisible: false,
      dialogfilterVisible: false,
      filterForm: {
        area_id: "",
        seller_type: "",
        contacts: "",
        status: "",
        start_date: "",
        end_date: "",
        pay_at: ""
      },
      hasFilter: false,
      refundOrderVisible: false,
      refundOrderId: ''
    };
  },
  created: function created() {
    var _this = this;
    this.initQuery();
    // 取消模式过滤 {mode: 7}
    (0, _hotel.getHotelSimpleList)().then(function (response) {
      _this.hotelList = response.data;
    });
    // 比例分成酒店
    (0, _hotel.getHotelSimpleList)({
      mode: 7
    }).then(function (response) {
      _this.selfHotelList = response.data;
    });
    (0, _order.getSelfOtaList)().then(function (response) {
      _this.otas = response.data;
    });
    (0, _order.getSelfSellerList)().then(function (response) {
      _this.sellers = response.data;
    });
    if ((0, _auth.getGrade)() < 1) {
      (0, _hotel.getArea)().then(function (response) {
        _this.areas = response.data;
      });
    }
    this.getList();
  },
  methods: {
    initQuery: function initQuery() {
      this.setListQuery();
      if (this.$route.query.order_no) {
        this.listQuery.order_no = this.$route.query.order_no;
      }
      if (this.$route.query.start_date) {
        this.listQuery.more.end_date = [this.$route.query.start_date, this.$route.query.end_date];
        this.hasFilter = true;
      }
      if (this.$route.query.pay_start_date) {
        this.listQuery.more.pay_at = [this.$route.query.pay_start_date, this.$route.query.pay_end_date];
        this.hasFilter = true;
      }
      if (this.$route.query.hotel_id) {
        this.listQuery.hotel_id = parseInt(this.$route.query.hotel_id);
      }
    },
    setListQuery: function setListQuery() {
      this.listQuery = {
        page: 1,
        limit: 20,
        order_no: "",
        hotel_id: "",
        more: {
          area_id: "",
          seller_type: "",
          contacts: "",
          status: "",
          start_date: "",
          end_date: "",
          pay_at: ""
        }
      };
    },
    getListQuery: function getListQuery() {
      var query = JSON.parse(JSON.stringify(this.listQuery));
      var more = query.more;
      delete query.more;
      return Object.assign(query, more);
    },
    getList: function getList() {
      var _this2 = this;
      this.listLoading = true;
      (0, _order.getSelfList)(this.getListQuery()).then(function (response) {
        var data = response.data;
        _this2.list = data.data;
        _this2.lastWeekBillEndDate = data.last_week_bill_end_date;
        _this2.pagination = data;
        _this2.listQuery.page = data.current_page;
        _this2.listLoading = false;
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    filterFormHandler: function filterFormHandler() {
      this.filterForm = Object.assign(this.filterForm, this.listQuery.more);
      this.dialogfilterVisible = true;
    },
    onFilterSubmit: function onFilterSubmit() {
      var _this3 = this;
      Object.keys(this.filterForm).forEach(function (k) {
        if (_this3.filterForm[k] != "") {
          _this3.hasFilter = true;
        }
      });
      // 更新查询条件
      this.listQuery.more = Object.assign(this.listQuery.more, this.filterForm);
      this.handleFilter();
      this.dialogfilterVisible = false;
    },
    resetFilter: function resetFilter() {
      this.hasFilter = false;
      this.$refs["filterForm"].resetFields();
      this.setListQuery();
    },
    closeFilter: function closeFilter() {
      this.dialogfilterVisible = false;
    },
    resetForm: function resetForm() {
      this.hasFilter = false;
      this.setListQuery();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    handleCommand: function handleCommand(command) {
      this[command](); // 调用对应的方法
    },
    addHandlerByForm: function addHandlerByForm() {
      this.dialogOrderByFormTitle = '添加订单';
      // 入离时间可选周期不做限制
      this.orderFormPickerOptions.disabledDate = function () {
        false;
      };
      this.orderForm.dates = [(0, _moment.default)().format("YYYY-MM-DD"), (0, _moment.default)().add(1, "days").format("YYYY-MM-DD")];
      this.dialogOrderByFormVisible = true;
    },
    editHandlerByForm: function editHandlerByForm(order) {
      this.dialogOrderByFormTitle = '编辑订单';
      var startDate = this.$options.filters['previceDateFormat'](order.start_date);
      var endDate = this.$options.filters['previceDateFormat'](order.end_date);
      this.orderFormHotelChangeHandler(order.hotel_id);
      var startDateTs = (0, _moment.default)(startDate).valueOf();
      var endDateTs = (0, _moment.default)(endDate).valueOf();
      // 入离时间可选择周期限制
      this.orderFormPickerOptions.disabledDate = function (time) {
        var ts = time.getTime();
        return ts < startDateTs || ts > endDateTs;
      };
      this.orderForm = {
        id: order.id,
        third_id: order.third_id,
        seller_type: order.seller_type,
        hotel_id: order.hotel_id,
        room_id: order.room_id,
        dates: [startDate, endDate],
        start_date: startDate,
        end_date: endDate,
        order_no: order.order_no,
        amount: order.amount,
        contacts: order.contacts,
        mobile: order.mobile,
        room_quantity: order.room_quantity
      };
      this.dialogOrderByFormVisible = true;
    },
    orderFormIsEdit: function orderFormIsEdit() {
      return this.orderForm.id != undefined && this.orderForm.id != '';
    },
    orderFormCloseHanlder: function orderFormCloseHanlder() {
      this.$refs.orderForm.resetFields();
      this.orderForm.id = '';
    },
    orderFormHotelChangeHandler: function orderFormHotelChangeHandler(v) {
      var _this4 = this;
      if (this.orderFormHotelRooms[v] != undefined) {
        this.orderFormRooms = this.orderFormHotelRooms[v];
        return;
      }
      (0, _room.simpleList)(v).then(function (res) {
        _this4.orderFormHotelRooms[v] = res.data;
        _this4.orderFormRooms = res.data;
      });
    },
    orderFormSubmitHandler: function orderFormSubmitHandler() {
      var _this5 = this;
      this.$refs['orderForm'].validate(function (valid) {
        if (valid) {
          var form = JSON.parse(JSON.stringify(_this5.orderForm));
          form.start_date = form.dates[0];
          form.end_date = form.dates[1];
          form.amount = parseFloat(form.amount);
          delete form.dates;
          _this5.orderFormConfirmLoading = true;
          var successCallback = function successCallback(res) {
            _this5.listQuery.page = 1;
            _this5.getList();
            _this5.$message.success(res.msg);
            _this5.dialogOrderByFormVisible = false;
            _this5.orderFormConfirmLoading = false;
          };
          if (_this5.orderFormIsEdit()) {
            (0, _order.editSelfOrderByForm)(form).then(function (res) {
              _this5.orderForm.id = '';
              successCallback(res);
            }).catch(function () {
              _this5.orderFormConfirmLoading = false;
            });
          } else {
            // 添加订单
            (0, _order.addSelfOrderByForm)(form).then(function (res) {
              successCallback(res);
            }).catch(function () {
              _this5.orderFormConfirmLoading = false;
            });
          }
        }
      });
    },
    handleDelete: function handleDelete(id) {
      var _this6 = this;
      this.$confirm('确认删除订单吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _order.delSelfOrder)(id).then(function () {
          _this6.getList();
          _this6.$message.success("操作成功");
        });
      }).catch(function () {
        return;
      });
    },
    // 订单操作
    //订单详情
    handleDetail: function handleDetail(id) {
      this.orderId = id;
      this.dialogDetailVisible = true;
    },
    //订单状态修改
    handleOrderChangeStatus: function handleOrderChangeStatus(id, status) {
      var _this7 = this;
      if (status == 27) {
        this.$confirm('确认拒单?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          (0, _order.orderChangeStatus)({
            order_id: id,
            status: status
          }).then(function () {
            _this7.getList();
            _this7.$message.success("操作成功");
          });
        }).catch(function () {
          return;
        });
      } else {
        (0, _order.orderChangeStatus)({
          order_id: id,
          status: status
        }).then(function () {
          _this7.getList();
          _this7.$message.success("操作成功！");
        });
      }
    },
    handleOtaOrderChangeStatus: function handleOtaOrderChangeStatus(order, status) {
      var _this8 = this;
      (0, _order.otaOrderChangeStatus)({
        id: order.id,
        order_type: order.order_type,
        status: status
      }).then(function () {
        _this8.getList();
        _this8.$message.success("操作成功");
      });
    },
    handleCancel: function handleCancel(id) {
      var _this9 = this;
      this.$confirm('订单已确认，确认取消吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _order.cancel)(id).then(function () {
          _this9.getList();
          _this9.$message.success("操作成功");
        });
      }).catch(function () {
        return;
      });
    },
    handleRefund: function handleRefund(id) {
      this.refundOrderVisible = true;
      this.refundOrderId = id;
    },
    download: function download() {
      var query = this.getListQuery();
      var isArray = function isArray(obj) {
        return Object.prototype.toString.call(obj) === '[object Array]';
      };
      var url = Object.keys(this.getListQuery()).map(function (key) {
        if (isArray(query[key])) {
          var iUrl = '';
          query[key].forEach(function (item) {
            iUrl = iUrl + key + '[]=' + item + '&';
          });
          return iUrl.trim('&');
        } else {
          return encodeURIComponent(key) + "=" + encodeURIComponent(query[key]);
        }
      }).join("&");
      url += '&api_token=' + (0, _auth.getToken)();
      window.open((0, _order.getSeftOrderDownloadUrl)(url));
    },
    canEdit: function canEdit(order) {
      // 离店日期大于上个订单结算周期的 未退款的refunded_at
      if (order['end_date'] > this.lastWeekBillEndDate && order['refunded_at'] == null) {
        return true;
      }
      return false;
    },
    canDelete: function canDelete(order) {
      // 入住日期大于当前日期的可删除
      if (order['start_date'] >= this.todayDate) {
        return true;
      }
      // 离店日期大于上个订单结算周期的可删除
      if (order['end_date'] > this.lastWeekBillEndDate) {
        return true;
      }
      return false;
    },
    getRealAmount: function getRealAmount(record, key) {
      // 部分退款
      if (record.status == 29) {
        return record.real_info && record.real_info[key] || record[key];
      }
      return record[key];
    }
  }
};
exports.default = _default;