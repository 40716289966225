"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  data: function data() {
    return {
      title: '飞火电竞',
      logo: 'https://zlfz-hotel.oss-cn-hangzhou.aliyuncs.com/imgs/logo1.png',
      logo2: 'https://zlfz-hotel.oss-cn-hangzhou.aliyuncs.com/imgs/logo2.png'
    };
  }
};
exports.default = _default;