var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    { staticClass: "nb-used-time-chart" },
    [
      _c("el-col", { staticClass: "operation-item", attrs: { span: 24 } }, [
        _c(
          "div",
          { staticClass: "title" },
          [
            _c(
              "el-radio-group",
              {
                staticStyle: { "margin-bottom": "1px" },
                attrs: { size: "small" },
                on: { change: _vm.categoryChangeHandler },
                model: {
                  value: _vm.chartCategory,
                  callback: function ($$v) {
                    _vm.chartCategory = $$v
                  },
                  expression: "chartCategory",
                },
              },
              [
                _c("el-radio-button", { attrs: { label: "used_time" } }, [
                  _vm._v("上机时间"),
                ]),
                _vm._v(" "),
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: { effect: "dark", placement: "top" },
                  },
                  [
                    _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                      _vm._v("【单机时长】在次日凌晨进行计算，"),
                      _c("br"),
                      _vm._v("算法为：当日的订单总时长/当日上架的设备数。"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-radio-button",
                      { attrs: { label: "device_per_used_time" } },
                      [_vm._v("单机时长")]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: { effect: "dark", placement: "top" },
                  },
                  [
                    _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                      _vm._v(
                        "【人均时长】为当日订单的总时长/当日结束上机的总人次，"
                      ),
                      _c("br"),
                      _vm._v(
                        "可能存在订单跨天导致的数据误差，可以通过扩大数据范围降低误差。"
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-radio-button",
                      { attrs: { label: "user_per_used_time" } },
                      [_vm._v("人均时长")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c("span", { staticStyle: { "font-size": "14px" } }, [
              _vm._v("选择日期："),
            ]),
            _vm._v(" "),
            _c("el-date-picker", {
              staticStyle: { width: "280px" },
              attrs: {
                clearable: false,
                type: "daterange",
                size: "small",
                "range-separator": "~",
                "start-placeholder": "开始日期",
                "end-placeholder": "结束日期",
                "value-format": "yyyy-MM-dd",
                "picker-options": _vm.datePickerOptions,
              },
              on: { change: _vm.dateChangeHandler },
              model: {
                value: _vm.chartQuery.date,
                callback: function ($$v) {
                  _vm.$set(_vm.chartQuery, "date", $$v)
                },
                expression: "chartQuery.date",
              },
            }),
            _vm._v(" "),
            _c(
              "el-radio-group",
              {
                staticStyle: { "margin-bottom": "1px" },
                attrs: { size: "small" },
                on: { change: _vm.dateTypeChangeHandler },
                model: {
                  value: _vm.chartQuery.date_type,
                  callback: function ($$v) {
                    _vm.$set(_vm.chartQuery, "date_type", $$v)
                  },
                  expression: "chartQuery.date_type",
                },
              },
              [
                _c("el-radio-button", { attrs: { label: "day" } }, [
                  _vm._v("日数据"),
                ]),
                _vm._v(" "),
                _c("el-radio-button", { attrs: { label: "week" } }, [
                  _vm._v("周数据"),
                ]),
                _vm._v(" "),
                _c("el-radio-button", { attrs: { label: "month" } }, [
                  _vm._v("月数据"),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-col",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { span: 24 },
        },
        [
          _c("el-col", { attrs: { span: 24 } }, [
            _c("div", {
              staticStyle: { width: "100%", height: "350px" },
              attrs: { id: "used_time_chart" },
            }),
          ]),
          _vm._v(" "),
          _c("el-col", { staticClass: "stats-line", attrs: { span: 24 } }, [
            _vm.chartCategory == "used_time"
              ? _c("div", [
                  _vm._v(
                    "当前结果总时长：" + _vm._s(_vm.chartData.total) + " 小时"
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", [
              _vm._v(
                "当前结果平均时长：" + _vm._s(_vm.chartData.avg) + " 小时"
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }