"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _activity = require("@/api/activity");
var _form = _interopRequireDefault(require("./form.vue"));
var _auth = require("@/utils/auth");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "ActivityScanCode",
  components: {
    ActivityForm: _form.default
  },
  data: function data() {
    return {
      activityId: undefined,
      list: [],
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 20,
        keyword: ""
      },
      pagination: ""
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      (0, _activity.fetchScanList)(this.listQuery).then(function (response) {
        var data = response.data;
        _this.list = data.data;
        _this.pagination = data;
        _this.listQuery.page = data.current_page;
        _this.listLoading = false;
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    addHandler: function addHandler() {
      this.activityId = undefined;
      this.$refs['activityForm'].open();
    },
    closeHandler: function closeHandler(activityId) {
      var _this2 = this;
      (0, _activity.closeScan)(activityId).then(function () {
        _this2.$message.success("下架成功！");
        _this2.getList();
      });
    },
    qrCodeHandler: function qrCodeHandler(activityId) {
      window.open((0, _activity.getScanQRCodeUrl)(activityId, (0, _auth.getToken)()));
    }
  }
};
exports.default = _default;