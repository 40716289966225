"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.regexp.replace");
var _typeof2 = _interopRequireDefault(require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/typeof.js"));
var _mixin = _interopRequireDefault(require("@/utils/mixin"));
var _hotel = require("@/api/hotel");
var _work_order = require("@/api/work_order");
var _moment = _interopRequireDefault(require("moment"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "WorkOrderTempate",
  mixins: [_mixin.default],
  data: function data() {
    var _this = this;
    var validateTemplateFlow = function validateTemplateFlow(rule, value, callback) {
      var flowLen = value.length;
      if (flowLen == 1 && !value[0].wo_role_id) {
        return callback(new Error('请设置反馈流程'));
      }
      var _loop = function _loop() {
        var item = value[i];
        if (!item.wo_role_id) {
          return {
            v: callback(new Error('请选择反馈操作角色'))
          };
        }
        var timeLimitErr;
        if (item.time_limit && isNaN(parseInt(item.time_limit))) {
          timeLimitErr = "操作角色【%s】反馈处理时间格式错误，请填写数字";
        }
        if (i != flowLen - 1) {
          if (item.time_limit == "") {
            timeLimitErr = "请设置操作角色【%s】反馈处理时间";
          } else if (parseInt(item.time_limit) <= 0) {
            timeLimitErr = "操作角色【%s】反馈处理时间不能小于0";
          }
        }
        if (timeLimitErr) {
          var role = _this.WORoleList.filter(function (role) {
            return role.id == item.wo_role_id;
          });
          return {
            v: callback(new Error(timeLimitErr.replace("%s", role[0].name)))
          };
        }
      };
      for (var i = 0; i < flowLen; i++) {
        var _ret = _loop();
        if ((0, _typeof2.default)(_ret) === "object") return _ret.v;
      }
      callback();
    };
    return {
      templateTypes: [],
      templateLevels: [],
      // 列表及查询
      templates: [],
      listQuery: {
        page: 1,
        limit: 20,
        title: "",
        type: "",
        level: "",
        wo_role_id: ""
      },
      pagination: {},
      listLoading: false,
      dialogVisible: false,
      formLoading: false,
      hotelList: [],
      hotelEmployeeList: [],
      templateFormSubmitLoading: false,
      templateFormShowDetail: false,
      templateForm: {
        id: "",
        title: "",
        level: "",
        type: 1,
        cycle: 1,
        cycle_time_start: "",
        cycle_time_end: "",
        target_hotel_ids: [],
        target_role: "",
        flows: []
      },
      templateFormRules: {
        title: [{
          required: true,
          message: '请输入工单内容',
          trigger: 'blur'
        }, {
          type: 'string',
          max: 32,
          message: '工单内容不能超过32个字符',
          trigger: 'blur'
        }],
        level: [{
          required: true,
          message: '请选择工单级别',
          trigger: 'change'
        }],
        type: [{
          required: true,
          message: '请选择工单类型',
          trigger: 'change'
        }],
        cycle: [{
          required: true,
          message: '请选择循环设置',
          trigger: 'change'
        }],
        cycle_time_start: [{
          required: true,
          message: '请选择工单开始时间',
          trigger: 'blur'
        }],
        cycle_time_end: [{
          required: true,
          message: '请选择工单结束时间',
          trigger: 'blur'
        }],
        target_hotel_ids: [{
          required: true,
          message: '请选择下发酒店',
          trigger: 'change'
        }],
        target_role: [{
          required: true,
          message: '请选择通知对象',
          trigger: 'change'
        }],
        flows: [{
          required: true,
          message: '请设置反馈流程',
          trigger: 'change'
        }, {
          validator: validateTemplateFlow,
          trigger: 'blur'
        }]
      },
      WORoleList: []
    };
  },
  created: function created() {
    var _this2 = this;
    if (this.$route.query.wo_role_id) {
      this.listQuery.wo_role_id = this.$route.query.wo_role_id;
    }
    this.getList();
    (0, _work_order.getTemplateTypeList)().then(function (response) {
      _this2.templateTypes = response.data;
    });
    (0, _work_order.getTemplateLevelList)().then(function (response) {
      _this2.templateLevels = response.data;
    });
    (0, _work_order.getSimlpeRoleList)().then(function (response) {
      _this2.WORoleList = response.data;
    });
    (0, _work_order.getUsableHotelList)().then(function (response) {
      _this2.hotelList = response.data;
    });
    (0, _hotel.gethotelEmployeeRoles)().then(function (response) {
      _this2.hotelEmployeeList = response.data;
    });
  },
  methods: {
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    getList: function getList() {
      var _this3 = this;
      this.listLoading = true;
      (0, _work_order.getTemplateList)(this.listQuery).then(function (response) {
        var data = response.data;
        _this3.pagination = {
          total: data.total,
          currentPage: data.current_page,
          pageSize: data.per_page
        };
        _this3.templates = data.data;
        _this3.listQuery.page = data.current_page;
        _this3.listLoading = false;
      });
    },
    addHandler: function addHandler() {
      this.templateForm.id = "";
      this.templateFormShowDetail = false;
      this.addFlowItemHandler();
      this.dialogVisible = true;
    },
    editHandler: function editHandler(record) {
      this.showHandler(record);
      // 即时超过工单开始时间不允许编辑
      if (record.cycle == 2 && record.time_start <= (0, _moment.default)().format("YYYY-MM-DD HH:mm:00")) {
        this.templateFormShowDetail = true;
      } else {
        this.templateFormShowDetail = false;
      }
    },
    showHandler: function showHandler(record) {
      var _this4 = this;
      this.templateFormShowDetail = true;
      this.formLoading = true;
      (0, _work_order.getTemplate)(record.id).then(function (response) {
        var data = response.data;
        var cycleTimeStart = data.time_start;
        var cycleTimeEnd = data.time_end;
        if (data.cycle == 1) {
          cycleTimeStart = data.daily_start;
          cycleTimeEnd = data.daily_end;
        }
        var flows = [];
        data.flows.forEach(function (item) {
          if (item.opr_type == "wo_role") {
            var timeLimit = item.time_limit || "";
            if (timeLimit) {
              timeLimit = timeLimit / 60;
            }
            flows.push({
              wo_role_id: item.opr_id,
              time_limit: timeLimit
            });
          }
        });
        _this4.templateForm = {
          id: data.id,
          title: data.title,
          level: data.level,
          type: data.type,
          cycle: data.cycle,
          cycle_time_start: cycleTimeStart,
          cycle_time_end: cycleTimeEnd,
          target_hotel_ids: data.target_hotel_ids,
          target_role: data.target_role,
          flows: flows
        };
        _this4.formLoading = false;
      });
      this.dialogVisible = true;
    },
    addFlowItemHandler: function addFlowItemHandler() {
      this.templateForm.flows.push({
        wo_role_id: "",
        time_limit: ""
      });
    },
    removeFlowItemHandler: function removeFlowItemHandler(idx) {
      this.templateForm.flows.splice(idx, 1);
    },
    onCycleChange: function onCycleChange() {
      this.templateForm.cycle_time_start = "";
      this.templateForm.cycle_time_end = "";
    },
    onCycleTimeStartChange: function onCycleTimeStartChange(value) {
      this.templateForm.cycle_time_start = value.slice(0, -2) + '00';
    },
    onCycleTimeEndChange: function onCycleTimeEndChange(value) {
      this.templateForm.cycle_time_end = value.slice(0, -2) + '00';
    },
    onCycleDatetimeStartChange: function onCycleDatetimeStartChange(value) {
      this.templateForm.cycle_time_start = value.slice(0, -2) + '00';
    },
    onCycleDatetimeEndChange: function onCycleDatetimeEndChange(value) {
      this.templateForm.cycle_time_end = value.slice(0, -2) + '00';
    },
    templateFormCloseHanlder: function templateFormCloseHanlder() {
      this.$refs['templateForm'].resetFields();
      this.templateForm.flows = [];
    },
    templateFormSubmitHandler: function templateFormSubmitHandler() {
      var _this5 = this;
      this.$refs['templateForm'].validate(function (valid) {
        if (valid) {
          var form = JSON.parse(JSON.stringify(_this5.templateForm));
          _this5.templateFormSubmitLoading = true;
          if (form.id) {
            (0, _work_order.editWOTemplate)(form.id, form).then(function () {
              _this5.getList();
              _this5.$message.success("编辑成功");
              _this5.dialogVisible = false;
            }).finally(function () {
              _this5.templateFormSubmitLoading = false;
            });
          } else {
            (0, _work_order.addWOTemplate)(form).then(function () {
              _this5.getList();
              _this5.$message.success("新建成功");
              _this5.dialogVisible = false;
            }).finally(function () {
              _this5.templateFormSubmitLoading = false;
            });
          }
        }
      });
    },
    statusChangeHandler: function statusChangeHandler(id, status) {
      var _this6 = this;
      (0, _work_order.editWOTemplateStatus)(id, status).then(function (res) {
        _this6.$message.success("操作成功");
        _this6.handleFilter();
      });
    }
  }
};
exports.default = _default;