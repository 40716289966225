"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _account = _interopRequireDefault(require("./account.vue"));
var _role = _interopRequireDefault(require("./role.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "WorkOrderConfig",
  components: {
    Account: _account.default,
    Role: _role.default
  },
  watch: {
    '$route.query.tab_name': {
      handler: function handler(tab_name) {
        this.tabName = tab_name;
      }
    }
  },
  data: function data() {
    return {
      tabName: "account"
    };
  },
  created: function created() {
    if (this.$route.query.tab_name) {
      this.tabName = this.$route.query.tab_name;
    }
  },
  methods: {
    typeHandler: function typeHandler() {
      this.$router.push("/work_order/config?tab_name=".concat(this.tabName));
    }
  }
};
exports.default = _default;