"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addPermutationOldOrderByForm = addPermutationOldOrderByForm;
exports.addPermutationOldOrderByText = addPermutationOldOrderByText;
exports.addPermutationOrderByForm = addPermutationOrderByForm;
exports.addPermutationOrderByText = addPermutationOrderByText;
exports.addSelfOrderByForm = addSelfOrderByForm;
exports.addSoftOrderByForm = addSoftOrderByForm;
exports.addSoftOrderByText = addSoftOrderByText;
exports.aliPayConfirm = aliPayConfirm;
exports.cancel = cancel;
exports.delPermutation = delPermutation;
exports.delPermutationOld = delPermutationOld;
exports.delPermutationOldPreview = delPermutationOldPreview;
exports.delPermutationPreview = delPermutationPreview;
exports.delSelfOrder = delSelfOrder;
exports.delSoft = delSoft;
exports.delSoftPreview = delSoftPreview;
exports.delTongZhou = delTongZhou;
exports.editPermutationOldOrderByForm = editPermutationOldOrderByForm;
exports.editPermutationOrderByForm = editPermutationOrderByForm;
exports.editSelfOrderByForm = editSelfOrderByForm;
exports.editSoftOrderByForm = editSoftOrderByForm;
exports.fetchList = fetchList;
exports.getAdminOrderDownloadUrl = getAdminOrderDownloadUrl;
exports.getAliPayList = getAliPayList;
exports.getOtaList = getOtaList;
exports.getPermutationList = getPermutationList;
exports.getPermutationOldList = getPermutationOldList;
exports.getPermutationOldOrderDownloadUrl = getPermutationOldOrderDownloadUrl;
exports.getPermutationOldOrderTemplate = getPermutationOldOrderTemplate;
exports.getPermutationOldOtaList = getPermutationOldOtaList;
exports.getPermutationOldPreviewList = getPermutationOldPreviewList;
exports.getPermutationOrderDownloadUrl = getPermutationOrderDownloadUrl;
exports.getPermutationOrderTemplate = getPermutationOrderTemplate;
exports.getPermutationOtaList = getPermutationOtaList;
exports.getPermutationPreviewList = getPermutationPreviewList;
exports.getPermutationSellerList = getPermutationSellerList;
exports.getSecKillOrderDownloadUrl = getSecKillOrderDownloadUrl;
exports.getSeftOrderDownloadUrl = getSeftOrderDownloadUrl;
exports.getSelfList = getSelfList;
exports.getSelfOtaList = getSelfOtaList;
exports.getSelfSellerList = getSelfSellerList;
exports.getSoftList = getSoftList;
exports.getSoftOrderDownloadUrl = getSoftOrderDownloadUrl;
exports.getSoftOrderTemplate = getSoftOrderTemplate;
exports.getSoftOtaList = getSoftOtaList;
exports.getSoftPreviewList = getSoftPreviewList;
exports.importPermutationOldOrders = importPermutationOldOrders;
exports.importPermutationOrders = importPermutationOrders;
exports.importSoftOrders = importSoftOrders;
exports.memberOrder = memberOrder;
exports.orderByCouponId = orderByCouponId;
exports.orderChangeStatus = orderChangeStatus;
exports.orderDetail = orderDetail;
exports.otaOrderChangeStatus = otaOrderChangeStatus;
exports.putCookie = putCookie;
exports.refund = refund;
exports.refundAmountPreview = refundAmountPreview;
exports.secKillOrder = secKillOrder;
exports.secKillRefund = secKillRefund;
exports.tongZhouList = tongZhouList;
exports.tongZhouOrderCreate = tongZhouOrderCreate;
exports.uploadPermutationOldOrders = uploadPermutationOldOrders;
exports.uploadPermutationOrders = uploadPermutationOrders;
exports.uploadSoftOrders = uploadSoftOrders;
var _request = _interopRequireDefault(require("@/utils/request"));
function fetchList(data) {
  return (0, _request.default)({
    url: 'adminOrders',
    method: 'get',
    params: data
  });
}
function getAdminOrderDownloadUrl(query) {
  return process.env.VUE_APP_BASE_API + '/orderDownload?' + query;
}
function secKillOrder(data) {
  return (0, _request.default)({
    url: 'secKillOrder',
    method: 'get',
    params: data
  });
}
function getSecKillOrderDownloadUrl(query) {
  return process.env.VUE_APP_BASE_API + '/secKillOrderDownload?' + query;
}
function orderChangeStatus(data) {
  return (0, _request.default)({
    url: 'order/status',
    method: 'put',
    params: data
  });
}
function otaOrderChangeStatus(data) {
  return (0, _request.default)({
    url: "ota_orders/".concat(data.id, "/status"),
    method: 'put',
    data: data
  });
}
function cancel(id) {
  return (0, _request.default)({
    url: 'order/unConfirm/' + id,
    method: 'put'
  });
}
function orderDetail(data) {
  return (0, _request.default)({
    url: 'orders/' + data.id,
    method: 'get',
    params: data
  });
}
function orderByCouponId(userId, couponId) {
  return (0, _request.default)({
    url: 'orderByCouponId/' + userId + '/' + couponId,
    method: 'get'
  });
}
function memberOrder(data, id) {
  return (0, _request.default)({
    url: 'memberOrders/' + id,
    method: 'get',
    params: data
  });
}
function getOtaList() {
  return (0, _request.default)({
    url: 'orderOtas',
    method: 'get'
  });
}
function getAliPayList(query) {
  return (0, _request.default)({
    url: 'aliPayOrder',
    method: 'get',
    params: query
  });
}
function aliPayConfirm(id, data) {
  return (0, _request.default)({
    url: 'aliPayOrder/confirm/' + id,
    method: 'post',
    data: data
  });
}
function tongZhouList(data) {
  return (0, _request.default)({
    url: 'tongZhouOrder',
    method: 'get',
    params: data
  });
}
function putCookie(data) {
  return (0, _request.default)({
    url: 'tongZhou/cookie',
    method: 'put',
    data: data
  });
}
function tongZhouOrderCreate(data) {
  return (0, _request.default)({
    url: 'tongZhouOrder/create',
    method: 'post',
    data: data
  });
}
function delTongZhou(id) {
  return (0, _request.default)({
    url: 'delTongZhou/' + id,
    method: 'delete'
  });
}
function secKillRefund(id) {
  return (0, _request.default)({
    url: 'secKillRefund/' + id,
    method: 'post'
  });
}
function refund(id, data) {
  return (0, _request.default)({
    url: 'order/refund/' + id,
    method: 'post',
    data: data
  });
}
function refundAmountPreview(id, data) {
  return (0, _request.default)({
    url: 'order/refund_amount_preview/' + id,
    method: 'post',
    data: data
  });
}

/******** 比例分成订单 ********/

function getPermutationOrderDownloadUrl(query) {
  return process.env.VUE_APP_BASE_API + '/permutationOrderDownload?' + query;
}
function getPermutationList(query) {
  return (0, _request.default)({
    url: 'permutationOrders',
    method: 'get',
    params: query
  });
}
function addPermutationOrderByForm(data) {
  return (0, _request.default)({
    url: 'permutationOrderForm',
    method: 'post',
    data: data
  });
}
function editPermutationOrderByForm(data) {
  return (0, _request.default)({
    url: "permutationOrderForm/".concat(data.id),
    method: 'put',
    data: data
  });
}
function addPermutationOrderByText(data) {
  return (0, _request.default)({
    url: 'permutationOrderText',
    method: 'post',
    data: data
  });
}
function delPermutation(id) {
  return (0, _request.default)({
    url: 'permutationOrders/' + id,
    method: 'delete'
  });
}
function getPermutationOtaList() {
  return (0, _request.default)({
    url: 'permutationOtas',
    method: 'get'
  });
}
function getPermutationSellerList() {
  return (0, _request.default)({
    url: 'permutationSellers',
    method: 'get'
  });
}
function uploadPermutationOrders() {
  return process.env.VUE_APP_BASE_API + '/permutationOrderUpload';
}
function getPermutationPreviewList(query) {
  return (0, _request.default)({
    url: 'permutationOrderUpload/preview',
    method: 'get',
    params: query
  });
}
function delPermutationPreview(id, time) {
  return (0, _request.default)({
    url: 'permutationOrderUpload/preview/' + id,
    method: 'delete',
    params: {
      time: time
    }
  });
}
function getPermutationOrderTemplate(token) {
  return process.env.VUE_APP_BASE_API + '/permutationOrderUpload/template?api_token=' + token;
}
function importPermutationOrders(time) {
  return (0, _request.default)({
    url: 'permutationOrderImport',
    method: 'post',
    params: {
      time: time
    }
  });
}

/******** 置换（旧）订单 ********/

function getPermutationOldOrderDownloadUrl(query) {
  return process.env.VUE_APP_BASE_API + '/permutationOldOrderDownload?' + query;
}
function getPermutationOldList(query) {
  return (0, _request.default)({
    url: 'permutationOldOrders',
    method: 'get',
    params: query
  });
}
function addPermutationOldOrderByForm(data) {
  return (0, _request.default)({
    url: 'permutationOldOrderForm',
    method: 'post',
    data: data
  });
}
function editPermutationOldOrderByForm(data) {
  return (0, _request.default)({
    url: "permutationOldOrderForm/".concat(data.id),
    method: 'put',
    data: data
  });
}
function addPermutationOldOrderByText(data) {
  return (0, _request.default)({
    url: 'permutationOldOrderText',
    method: 'post',
    data: data
  });
}
function delPermutationOld(id) {
  return (0, _request.default)({
    url: 'permutationOldOrders/' + id,
    method: 'delete'
  });
}
function getPermutationOldOtaList() {
  return (0, _request.default)({
    url: 'permutationOldOtas',
    method: 'get'
  });
}
function uploadPermutationOldOrders() {
  return process.env.VUE_APP_BASE_API + '/permutationOldOrderUpload';
}
function getPermutationOldPreviewList(query) {
  return (0, _request.default)({
    url: 'permutationOldOrderUpload/preview',
    method: 'get',
    params: query
  });
}
function delPermutationOldPreview(id, time) {
  return (0, _request.default)({
    url: 'permutationOldOrderUpload/preview/' + id,
    method: 'delete',
    params: {
      time: time
    }
  });
}
function getPermutationOldOrderTemplate(token) {
  return process.env.VUE_APP_BASE_API + '/permutationOldOrderUpload/template?api_token=' + token;
}
function importPermutationOldOrders(time) {
  return (0, _request.default)({
    url: 'permutationOldOrderImport',
    method: 'post',
    params: {
      time: time
    }
  });
}

/******** 软包订单 ********/

function getSoftOrderDownloadUrl(query) {
  return process.env.VUE_APP_BASE_API + '/softOrderDownload?' + query;
}
function getSoftList(query) {
  return (0, _request.default)({
    url: 'softOrders',
    method: 'get',
    params: query
  });
}
function addSoftOrderByForm(data) {
  return (0, _request.default)({
    url: 'softOrderForm',
    method: 'post',
    data: data
  });
}
function editSoftOrderByForm(data) {
  return (0, _request.default)({
    url: "softOrderForm/".concat(data.id),
    method: 'put',
    data: data
  });
}
function addSoftOrderByText(data) {
  return (0, _request.default)({
    url: 'softOrderText',
    method: 'post',
    data: data
  });
}
function delSoft(id) {
  return (0, _request.default)({
    url: 'softOrders/' + id,
    method: 'delete'
  });
}
function getSoftOtaList() {
  return (0, _request.default)({
    url: 'softOtas',
    method: 'get'
  });
}
function uploadSoftOrders() {
  return process.env.VUE_APP_BASE_API + '/softOrderUpload';
}
function getSoftPreviewList(query) {
  return (0, _request.default)({
    url: 'softOrderUpload/preview',
    method: 'get',
    params: query
  });
}
function delSoftPreview(id, time) {
  return (0, _request.default)({
    url: 'softOrderUpload/preview/' + id,
    method: 'delete',
    params: {
      time: time
    }
  });
}
function getSoftOrderTemplate(token) {
  return process.env.VUE_APP_BASE_API + '/softOrderUpload/template?api_token=' + token;
}
function importSoftOrders(time) {
  return (0, _request.default)({
    url: 'softOrderImport',
    method: 'post',
    params: {
      time: time
    }
  });
}

/******** 直营订单 ********/

function getSeftOrderDownloadUrl(query) {
  return process.env.VUE_APP_BASE_API + '/selfOrderDownload?' + query;
}
function getSelfList(query) {
  return (0, _request.default)({
    url: 'selfOrders',
    method: 'get',
    params: query
  });
}
function addSelfOrderByForm(data) {
  return (0, _request.default)({
    url: 'selfOrderForm',
    method: 'post',
    data: data
  });
}
function editSelfOrderByForm(data) {
  return (0, _request.default)({
    url: "selfOrderForm/".concat(data.id),
    method: 'put',
    data: data
  });
}
function delSelfOrder(id) {
  return (0, _request.default)({
    url: 'selfOrders/' + id,
    method: 'delete'
  });
}
function getSelfOtaList() {
  return (0, _request.default)({
    url: 'selfOtas',
    method: 'get'
  });
}
function getSelfSellerList() {
  return (0, _request.default)({
    url: 'selfSellers',
    method: 'get'
  });
}