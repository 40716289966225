"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getBankList = getBankList;
exports.getCityList = getCityList;
exports.getOpenBankList = getOpenBankList;
exports.getProvinceList = getProvinceList;
var _request = _interopRequireDefault(require("@/utils/request"));
function getProvinceList() {
  return (0, _request.default)({
    url: "/cbs/bank/provinces",
    method: 'get'
  });
}
function getCityList(province) {
  return (0, _request.default)({
    url: "/cbs/bank/cities",
    method: 'get',
    params: {
      province: province
    }
  });
}
function getBankList() {
  return (0, _request.default)({
    url: "/cbs/bank/banks",
    method: 'get'
  });
}
function getOpenBankList(params) {
  return (0, _request.default)({
    url: "/cbs/bank/open_banks",
    method: 'get',
    params: params
  });
}