"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _finance_reco = require("@/api/finance_reco");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: "FinanceBillAppeal",
  props: {
    billId: {
      type: Number,
      default: function _default() {
        return undefined;
      }
    },
    visible: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    }
  },
  watch: {
    visible: function visible() {
      this.dialogVisible = this.visible;
      if (this.visible) {
        this.getBillAppeal();
      }
    }
  },
  computed: {
    formDisabled: function formDisabled() {
      return this.appealBill.audit_status != 0;
    }
  },
  data: function data() {
    return {
      appealBill: {},
      loading: false,
      dialogVisible: false,
      previewVisible: false,
      previewImage: '',
      auditForm: {
        audit_status: undefined,
        audit_amount: undefined
      },
      auditFormRules: {
        audit_status: [{
          required: true,
          message: '请选择处理意见',
          trigger: 'change'
        }],
        audit_amount: []
      },
      auditFormSubmitLoading: false
    };
  },
  created: function created() {},
  methods: {
    getBillAppeal: function getBillAppeal() {
      var _this = this;
      this.loading = true;
      (0, _finance_reco.getFinanceBillAppeal)(this.billId).then(function (res) {
        _this.appealBill = res.data;
        if (_this.appealBill.audit_status != 0) {
          _this.auditForm.audit_status = _this.appealBill.audit_status;
          _this.auditForm.audit_amount = _this.appealBill.audit_amount;
        }
        _this.loading = false;
      });
    },
    onAuditStatusChange: function onAuditStatusChange(v) {
      if (v == 1) {
        this.auditFormRules.audit_amount = [{
          required: true,
          message: '请输入调整金额',
          trigger: 'blur'
        }];
      } else {
        this.auditFormRules.audit_amount = [];
        this.$refs['auditForm'].clearValidate('audit_amount');
      }
    },
    confirmHandler: function confirmHandler() {
      var _this2 = this;
      this.$refs['auditForm'].validate(function (valid) {
        if (valid) {
          _this2.$confirm('<span style="color: #F56C6C;">确认后无法撤回</span>，你还要继续吗？', '同意后，申诉金额会在下一账期自动结算', {
            dangerouslyUseHTMLString: true,
            confirmButtonText: '继续',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(function () {
            _this2.auditFormSubmitLoading = true;
            (0, _finance_reco.auditFinanceBillAppeal)(_this2.billId, _this2.auditForm).then(function () {
              _this2.$message.success("调整成功");
              _this2.$emit('success');
              _this2.closeHandler();
            }).finally(function () {
              _this2.auditFormSubmitLoading = false;
            });
          }).catch(function () {});
        }
      });
    },
    handlePreview: function handlePreview(src) {
      this.previewVisible = true;
      this.previewImage = src;
    },
    previewCancelHandler: function previewCancelHandler() {
      this.previewVisible = false;
    },
    closeHandler: function closeHandler() {
      this.dialogVisible = false;
      this.appealBill = {};
      this.$refs["auditForm"].resetFields();
      this.$emit('update:visible', false);
    }
  }
};
exports.default = _default2;