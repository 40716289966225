"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _vuex = require("vuex");
var _admin = _interopRequireDefault(require("./admin"));
var _editor = _interopRequireDefault(require("./editor"));
//
//
//
//
//
//
var _default = {
  name: 'Dashboard',
  components: {
    adminDashboard: _admin.default,
    editorDashboard: _editor.default
  },
  data: function data() {
    return {
      currentRole: 'editorDashboard'
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['roles'])),
  created: function created() {
    // if (!this.roles.includes('admin')) {
    // this.currentRole = 'adminDashboard'
    // }
  }
};
exports.default = _default;