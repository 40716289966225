"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.regexp.to-string");
var _order = require("@/api/order");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "OrderDetail",
  filters: {
    dateFormat: function dateFormat(value) {
      if (!value) return '';
      var date = value.toString();
      return date.slice(4, 6) + '月' + date.slice(6, 8) + '日';
    }
  },
  props: {
    id: [Number, String]
  },
  data: function data() {
    return {
      detail: {},
      loading: false,
      statusMap: {
        25: "付款成功",
        26: "已关闭,买家取消订单",
        31: "已关闭,买家取消订单",
        27: "已关闭,酒店取消订单",
        28: "已退款",
        29: "部分退款",
        30: "已确认",
        40: "已完成"
      }
    };
  },
  watch: {
    id: function id(val, oldVal) {
      this.id = val;
      this.getDetail();
    }
  },
  created: function created() {
    this.getDetail();
  },
  methods: {
    getDetail: function getDetail() {
      var _this = this;
      this.loading = true;
      (0, _order.orderDetail)({
        id: this.id
      }).then(function (response) {
        _this.loading = false;
        _this.detail = response.data;
      });
    }
  }
};
exports.default = _default;