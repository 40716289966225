"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es6.number.constructor");
var _hotel = require("@/api/hotel");
var _room = require("@/api/room");
var _finance_reco = require("@/api/finance_reco");
var _moment = _interopRequireDefault(require("moment"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: "ThirOrderForm",
  components: {},
  props: {
    id: [Number, String],
    visible: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    },
    allowModify: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    },
    dateRange: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  watch: {
    visible: function visible() {
      this.dialogVisible = this.visible;
    },
    id: function id() {
      if (this.id) {
        this.getOrder();
      }
    }
  },
  data: function data() {
    return {
      dialogVisible: false,
      formLoading: false,
      hotelList: [],
      orderFormPickerOptions: {
        disabledDate: function disabledDate() {
          false;
        }
      },
      orderFormSubmitLoading: false,
      orderForm: {
        id: "",
        order_no: "",
        hotel_id: "",
        room_id: "",
        date_range: [],
        start_date: "",
        end_date: "",
        contacts: "",
        amount: "",
        hotel_amount: "",
        hotel_real_amount: "",
        hotel_agent_amount: "",
        hotel_agent_real_amount: "",
        ota_service_fee: "",
        ota_service_refund_fee: ""
      },
      orderFormRules: {
        order_no: [{
          required: true,
          message: '请输入订单编号',
          trigger: 'blur'
        }],
        hotel_id: [{
          required: true,
          message: '请选择入住酒店',
          trigger: 'blur'
        }],
        room_id: [{
          required: true,
          message: '请选择预订房型',
          trigger: 'blur'
        }],
        date_range: [{
          required: true,
          message: '请选择入离日期',
          trigger: 'blur'
        }],
        contacts: [{
          required: true,
          message: '请输入入住人',
          trigger: 'blur'
        }],
        amount: [{
          required: true,
          message: '请输入结算金额',
          trigger: 'blur'
        }],
        hotel_amount: [{
          required: true,
          message: '请输入商家应收',
          trigger: 'blur'
        }],
        hotel_real_amount: [{
          required: true,
          message: '请输入商家实收',
          trigger: 'blur'
        }],
        hotel_agent_amount: [{
          required: true,
          message: '请输入代理商应收',
          trigger: 'blur'
        }],
        hotel_agent_real_amount: [{
          required: true,
          message: '请输入代理商实收',
          trigger: 'blur'
        }],
        ota_service_fee: [{
          required: true,
          message: '请输入技术费',
          trigger: 'blur'
        }],
        ota_service_refund_fee: [{
          required: true,
          message: '请输入退技术费',
          trigger: 'blur'
        }]
      },
      orderFormRooms: [],
      orderFormHotelRooms: [],
      inFinanceRange: false
    };
  },
  filters: {
    previceDateFormat: function previceDateFormat(value) {
      if (!value) return '';
      var date = value.toString();
      return "".concat(date.slice(0, 4), "-").concat(date.slice(4, 6), "-").concat(date.slice(6, 8));
    }
  },
  mounted: function mounted() {
    var _this = this;
    (0, _hotel.getHotelSimpleList)().then(function (response) {
      _this.hotelList = response.data;
    });
  },
  methods: {
    getOrder: function getOrder() {
      var _this2 = this;
      this.formLoading = true;
      (0, _finance_reco.getFinanceRecoOrder)(this.id).then(function (result) {
        var orderForm = result.data;
        _this2.formLoading = false;
        var startDate = _this2.$options.filters['previceDateFormat'](orderForm.start_date);
        var endDate = _this2.$options.filters['previceDateFormat'](orderForm.end_date);
        _this2.orderFormHotelChangeHandler(orderForm.hotel_id);
        var endDateTs = (0, _moment.default)(endDate).day(7).valueOf(); // 订单对应周期的最后一天
        // 入离时间可选择周期限制
        _this2.orderFormPickerOptions.disabledDate = function (time) {
          var ts = time.getTime();
          return ts > endDateTs;
        };
        _this2.orderForm = {
          id: orderForm.id,
          order_no: orderForm.order_no,
          hotel_id: orderForm.hotel_id,
          room_id: orderForm.room_id,
          date_range: [startDate, endDate],
          start_date: orderForm.start_date,
          end_date: orderForm.end_date,
          contacts: orderForm.contacts,
          amount: orderForm.amount,
          hotel_amount: orderForm.hotel_amount,
          hotel_real_amount: orderForm.hotel_real_amount,
          hotel_agent_amount: orderForm.hotel_agent_amount,
          hotel_agent_real_amount: orderForm.hotel_agent_real_amount,
          ota_service_fee: orderForm.ota_service_fee,
          ota_service_refund_fee: orderForm.ota_service_refund_fee
        };
        _this2.inFinanceRange = _this2.inDateRange(orderForm.bill_end_date);
        _this2.formLoading = false;
      });
    },
    orderFormCloseHanlder: function orderFormCloseHanlder() {
      this.$refs['orderForm'].resetFields();
      this.$emit('update:id', "");
      this.$emit('update:visible', false);
    },
    orderFormSubmitHandler: function orderFormSubmitHandler() {
      var _this3 = this;
      this.$refs['orderForm'].validate(function (valid) {
        if (valid) {
          var form = JSON.parse(JSON.stringify(_this3.orderForm));
          form.start_date = form.date_range[0];
          form.end_date = form.date_range[1];
          form.amount = parseFloat(form.amount);
          form.hotel_amount = parseFloat(form.hotel_amount);
          form.hotel_real_amount = parseFloat(form.hotel_real_amount);
          form.hotel_agent_amount = parseFloat(form.hotel_agent_amount);
          form.hotel_agent_real_amount = parseFloat(form.hotel_agent_real_amount);
          form.ota_service_fee = parseFloat(form.ota_service_fee);
          form.ota_service_refund_fee = parseFloat(form.ota_service_refund_fee);
          delete form.date_range;
          _this3.editHandler(form);
        }
      });
    },
    editHandler: function editHandler(form) {
      var _this4 = this;
      this.orderFormSubmitLoading = true;
      (0, _finance_reco.updateFinanceRecoOrder)(form.id, form).then(function () {
        _this4.$message.success("编辑成功");
        _this4.$emit('success');
        _this4.dialogVisible = false;
      }).finally(function () {
        _this4.orderFormSubmitLoading = false;
      });
    },
    orderFormEditDisabled: function orderFormEditDisabled() {
      return !this.allowModify || !this.inFinanceRange;
    },
    orderFormHotelChangeHandler: function orderFormHotelChangeHandler(v) {
      var _this5 = this;
      this.$refs["roomSelect"].resetField();
      if (this.orderFormHotelRooms[v] != undefined) {
        this.orderFormRooms = this.orderFormHotelRooms[v];
        return;
      }
      (0, _room.simpleList)(v).then(function (res) {
        _this5.orderFormHotelRooms[v] = res.data;
        _this5.orderFormRooms = res.data;
      });
    },
    inDateRange: function inDateRange(billEndDate) {
      if (this.dateRange.length == 0) {
        return false;
      }
      var endDateStr = billEndDate.toString();
      endDateStr = endDateStr.slice(0, 4) + '-' + endDateStr.slice(4, 6) + '-' + endDateStr.slice(6, 8);
      return endDateStr >= this.dateRange[0] && endDateStr <= this.dateRange[1];
    }
  }
};
exports.default = _default2;