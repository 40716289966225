"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _manager = require("@/api/manager");
var _hotel = require("@/api/hotel");
var _net_bar = require("@/api/net_bar");
var _auth = require("@/utils/auth");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Admin',
  data: function data() {
    return {
      pagination: "",
      areas: [],
      netbarList: [],
      adminList: null,
      dialogFormVisible: false,
      adminForm: {
        role: '',
        grade: ''
      },
      listQuery: {
        area_id: "",
        username: "",
        page: 1,
        limit: 20
      },
      dialogStatus: null,
      dataRole: [],
      roleList: [],
      areaList: [],
      props: {
        multiple: true
      },
      formRules: {
        role: [{
          required: true,
          message: '请选择角色',
          trigger: 'change'
        }],
        grade: [{
          required: true,
          message: '请选择组织',
          trigger: 'change'
        }],
        data: [{
          required: true,
          message: '请选择',
          trigger: 'blur'
        }],
        username: [{
          required: true,
          message: '请输入账号',
          trigger: 'blur'
        }],
        name: [{
          required: true,
          message: '请输入员工名称',
          trigger: 'blur'
        }]
      }
    };
  },
  created: function created() {
    var _this = this;
    this.getAdminList();
    (0, _hotel.getArea)().then(function (response) {
      _this.areas = response.data;
    });
    (0, _net_bar.getSimpleList)().then(function (response) {
      _this.netbarList = response.data;
    });
  },
  methods: {
    getAdminList: function getAdminList() {
      var _this2 = this;
      this.listLoading = true;
      (0, _manager.fetchAdminList)(this.listQuery).then(function (res) {
        _this2.roleList = res.data.role;
        _this2.pagination = res.data.list;
        _this2.dataRole = res.data.data_role;
        _this2.areaList = res.data.areaList;
        _this2.adminList = res.data.list.data;
        _this2.listLoading = false;
      });
    },
    onRoleChange: function onRoleChange(v) {
      if (v == 'net_bar_manager') {
        this.adminForm.grade = 6;
        this.adminForm.data = [];
        return;
      }
      if (this.adminForm.grade == 6) {
        this.adminForm.grade = '';
        this.adminForm.data = [];
        return;
      }
    },
    handleEdit: function handleEdit(row, status) {
      var _this3 = this;
      (0, _manager.fetchAdmin)(row.id).then(function (res) {
        _this3.adminForm = res.data;
        _this3.adminForm.grade = res.data.data_role.grade ? res.data.data_role.grade : 0;
        console.log(_this3.adminForm);
      });
      this.dialogFormVisible = true;
      row.password = '';
      this.dialogStatus = status;
    },
    handleCreate: function handleCreate(status) {
      this.dialogStatus = status;
      this.dialogFormVisible = true;
      this.adminForm = {
        role: '',
        grade: '',
        data: []
      };
    },
    create: function create() {
      var _this4 = this;
      this.$refs['adminForm'].validate(function (valid) {
        console.log(valid);
        if (!valid) {
          return false;
        } else {
          console.warn(_this4.adminForm);
          (0, _manager.addAdmin)(_this4.adminForm).then(function (response) {
            if (response.code == 0) {
              _this4.$message({
                message: response.msg,
                type: 'success'
              });
              _this4.dialogFormVisible = false;
              _this4.getAdminList();
            } else {
              _this4.$message({
                message: response.msg,
                type: 'error'
              });
            }
          });
        }
      });
    },
    update: function update() {
      var _this5 = this;
      (0, _manager.updateAdmin)(this.adminForm.id, this.adminForm).then(function (response) {
        if (response.code == 0) {
          _this5.$message({
            message: response.msg,
            type: 'success'
          });
          _this5.dialogFormVisible = false;
          _this5.getAdminList();
        } else {
          _this5.$message({
            message: response.msg,
            type: 'error'
          });
        }
      });
    },
    deleteAdmin: function deleteAdmin(id) {
      var _this6 = this;
      this.$confirm('删除用户后无法恢复，是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _manager.deleteAdmin)({
          id: id
        }).then(function (response) {
          if (response.code == 0) {
            _this6.$message({
              message: response.msg,
              type: 'success'
            });
            _this6.getAdminList();
          } else {
            _this6.$message({
              message: response.msg,
              type: 'error'
            });
          }
        });
      }).catch(function () {
        return;
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getAdminList();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getAdminList();
    }
  }
};
exports.default = _default;