var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "order-resync-container" },
    [
      _c(
        "el-button",
        { staticClass: "filter-item", on: { click: _vm.resyncHandler } },
        [_vm._v("\n    更新订单\n  ")]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "order-resync-form-dialog",
          attrs: {
            visible: _vm.dialogVisible,
            title: "更新订单",
            "close-on-click-modal": false,
            width: "540px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            close: _vm.resyncFormCloseHanlder,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "resyncForm",
              attrs: {
                model: _vm.resyncForm,
                rules: _vm.resyncFormRules,
                "label-suffix": ":",
                "label-width": "100px",
                "label-position": "right",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "账单周期", prop: "bill_week" } },
                [
                  _c("el-date-picker", {
                    staticClass: "filter-item",
                    staticStyle: { "margin-bottom": "0" },
                    attrs: {
                      "picker-options": { firstDayOfWeek: 1 },
                      type: "week",
                      "value-format": "yyyyMMdd",
                      format: "yyyy 年 第 WW 周",
                      placeholder: "账单周期",
                    },
                    on: { change: _vm.onBillWeekChange },
                    model: {
                      value: _vm.resyncForm.bill_week,
                      callback: function ($$v) {
                        _vm.$set(_vm.resyncForm, "bill_week", $$v)
                      },
                      expression: "resyncForm.bill_week",
                    },
                  }),
                  _vm._v(" "),
                  _vm.billWeekRange
                    ? _c(
                        "div",
                        {
                          staticClass: "bill-range-tips",
                          class: { error: _vm.billExists },
                        },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.billWeekRange[0]) +
                                " 至 " +
                                _vm._s(_vm.billWeekRange[1])
                            ),
                          ]),
                          _vm._v(" "),
                          _vm.billExists
                            ? _c("span", [_vm._v("账单已生成")])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.billComparisonStatus == _vm.billCmpCompleted
                            ? _c("div", { staticClass: "bill-tips" }, [
                                _c("span", [_vm._v("复核完成！")]),
                                _c("span", [
                                  _vm._v("发现【异常订单】"),
                                  _c("span", { staticClass: "error-num" }, [
                                    _vm._v(
                                      _vm._s(_vm.billComparisonInfo.error || 0)
                                    ),
                                  ]),
                                  _vm._v("单"),
                                ]),
                              ])
                            : _vm._e(),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              [
                _vm.billComparisonStatus == _vm.billCmpCompleted
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            _vm.dialogVisible = false
                          },
                        },
                      },
                      [_vm._v("\n          确认\n        ")]
                    )
                  : _c(
                      "el-button",
                      {
                        attrs: {
                          loading: _vm.resyncFormSubmitLoading,
                          disabled: _vm.billExists,
                          type: "primary",
                        },
                        on: { click: _vm.resyncFormSubmitHandler },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.resyncFormSubmitLoading
                                ? "更新订单中"
                                : "更新订单"
                            ) +
                            "\n        "
                        ),
                      ]
                    ),
              ],
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }