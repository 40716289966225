var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container calendar-list-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              attrs: { filterable: "", multiple: "", placeholder: "券" },
              model: {
                value: _vm.listQuery.id,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "id", $$v)
                },
                expression: "listQuery.id",
              },
            },
            _vm._l(_vm.couponArr, function (item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.name, value: item.id },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "150px" },
              attrs: { placeholder: "状态" },
              model: {
                value: _vm.listQuery.status,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "status", $$v)
                },
                expression: "listQuery.status",
              },
            },
            _vm._l(_vm.statusOptions, function (item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.label, value: item.value },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c("el-date-picker", {
            staticClass: "filter-item",
            staticStyle: { width: "280px" },
            attrs: {
              type: "daterange",
              "start-placeholder": "日期筛选",
              "end-placeholder": "",
              "value-format": "yyyy-MM-dd",
              align: "right",
            },
            model: {
              value: _vm.listQuery.date,
              callback: function ($$v) {
                _vm.$set(_vm.listQuery, "date", $$v)
              },
              expression: "listQuery.date",
            },
          }),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.handleFilter },
            },
            [_vm._v("\n      查询\n    ")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "success" },
              on: { click: _vm.resetForm },
            },
            [_vm._v("\n      重置\n    ")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              staticStyle: { "margin-left": "10px" },
              attrs: { type: "primary", icon: "edit" },
              on: { click: _vm.showCreateCoupon },
            },
            [_vm._v("\n      添加优惠券\n    ")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          key: "tableList",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            "element-loading-text": "给我一点时间",
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "序号", width: "65" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.id))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "名称",
              "class-name": "overflow-on",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v("\n        " + _vm._s(scope.row.name) + "\n      "),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "类别" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.typeList[scope.row.type - 1].val)),
                    ]),
                    _vm._v(" "),
                    scope.row.is_multi == 1
                      ? _c("el-tag", { attrs: { type: "success" } }, [
                          _vm._v("可叠加"),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "抵扣金额/折扣", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          scope.row.type == 3
                            ? scope.row.discount_per + "折"
                            : scope.row.amount + "元"
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "适用酒店", width: "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.category == 1
                      ? _c("span", [_vm._v("全部酒店")])
                      : scope.row.category == 2
                      ? _c("span", [_vm._v("指定酒店")])
                      : scope.row.category == 3
                      ? _c("span", [
                          _vm._v(_vm._s(_vm.areas[scope.row.area - 2].name)),
                        ])
                      : scope.row.category == 4
                      ? _c("span", [_vm._v("指定不可用")])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "总数" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(scope.row.receive_num + scope.row.num)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "领取数" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.get_num))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "核销数" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.consume_num))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "有效时间" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.expire_type == 2
                      ? [_vm._v("\n          --\n        ")]
                      : [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.getLocalTime(scope.row.start_time))
                            ),
                          ]),
                          _c("br"),
                          _vm._v("至\n          "),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.getLocalTime(scope.row.over_time))
                            ),
                          ]),
                        ],
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "操作",
              fixed: "right",
              width: "160px",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.updateAction(scope.row)
                          },
                        },
                      },
                      [_vm._v("\n          编辑\n        ")]
                    ),
                    _vm._v(" "),
                    scope.row.status == 2
                      ? _c(
                          "el-button",
                          {
                            staticStyle: { color: "#67C23A" },
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.recoverCoupon(scope.row.id)
                              },
                            },
                          },
                          [_vm._v("\n          恢复\n        ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status == 1
                      ? _c(
                          "el-button",
                          {
                            staticStyle: { color: "#F56C6C" },
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.deleteCoupon(scope.row.id)
                              },
                            },
                          },
                          [_vm._v("\n          禁用\n        ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.generateCode(scope.row.id)
                          },
                        },
                      },
                      [_vm._v("\n          生成码\n        ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "pagination-container text-right" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              total: _vm.pagination.total,
              "current-page": _vm.listQuery.page,
              "page-sizes": [20],
              "page-size": _vm.listQuery.limit,
              layout: "total, sizes, prev, pager, next, jumper",
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.dialogTitle,
            visible: _vm.dialogFormVisible,
            width: "800px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "couponForm",
              staticClass: "small-space",
              staticStyle: { padding: "0 20px" },
              attrs: {
                model: _vm.temp,
                rules: _vm.couponFormRules,
                "label-position": "right",
                "label-width": "150px",
                "label-suffix": ":",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "名称", prop: "name", required: "" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.temp.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp, "name", $$v)
                      },
                      expression: "temp.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "说明", prop: "description", required: "" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea" },
                    model: {
                      value: _vm.temp.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp, "description", $$v)
                      },
                      expression: "temp.description",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "类别", required: "", prop: "type" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.temp.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.temp, "type", $$v)
                        },
                        expression: "temp.type",
                      },
                    },
                    _vm._l(_vm.typeList, function (item) {
                      return _c(
                        "el-radio",
                        {
                          key: item.label,
                          attrs: {
                            label: item.label,
                            disabled: _vm.dialogTitle == "编辑优惠券",
                          },
                        },
                        [
                          _vm._v(
                            "\n            " + _vm._s(item.val) + "\n          "
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "剩余数量", prop: "num", required: "" } },
                [
                  _c("el-input-number", {
                    attrs: { controls: false, min: 0 },
                    model: {
                      value: _vm.temp.num,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp, "num", $$v)
                      },
                      expression: "temp.num",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: { label: "有效期", prop: "expire_type", required: "" },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      on: { change: _vm.onExpireTypeChange },
                      model: {
                        value: _vm.temp.expire_type,
                        callback: function ($$v) {
                          _vm.$set(_vm.temp, "expire_type", $$v)
                        },
                        expression: "temp.expire_type",
                      },
                    },
                    _vm._l(_vm.expireTypes, function (item) {
                      return _c(
                        "el-radio",
                        { key: item.label, attrs: { label: item.label } },
                        [
                          _vm._v(
                            "\n            " + _vm._s(item.val) + "\n          "
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.temp.expire_type == 1
                ? [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "开始时间",
                          prop: "start_time",
                          required: "",
                        },
                      },
                      [
                        _c("el-date-picker", {
                          staticClass: "filter-item",
                          attrs: { type: "datetime" },
                          model: {
                            value: _vm.temp.start_time,
                            callback: function ($$v) {
                              _vm.$set(_vm.temp, "start_time", $$v)
                            },
                            expression: "temp.start_time",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "结束时间",
                          prop: "over_time",
                          required: "",
                        },
                      },
                      [
                        _c("el-date-picker", {
                          staticClass: "filter-item",
                          attrs: { type: "datetime" },
                          model: {
                            value: _vm.temp.over_time,
                            callback: function ($$v) {
                              _vm.$set(_vm.temp, "over_time", $$v)
                            },
                            expression: "temp.over_time",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                : _vm.temp.expire_type == 2
                ? [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "领取后",
                          prop: "expire_days",
                          required: "",
                        },
                      },
                      [
                        _c("el-input-number", {
                          attrs: { controls: false, min: 0 },
                          model: {
                            value: _vm.temp.expire_days,
                            callback: function ($$v) {
                              _vm.$set(_vm.temp, "expire_days", $$v)
                            },
                            expression: "temp.expire_days",
                          },
                        }),
                        _vm._v("\n          天有效\n        "),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "适用范围", prop: "category" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      on: { change: _vm.onCategoryChange },
                      model: {
                        value: _vm.temp.category,
                        callback: function ($$v) {
                          _vm.$set(_vm.temp, "category", $$v)
                        },
                        expression: "temp.category",
                      },
                    },
                    _vm._l(_vm.categoryList, function (item) {
                      return _c(
                        "el-radio",
                        { key: item.label, attrs: { label: item.label } },
                        [
                          _vm._v(
                            "\n            " + _vm._s(item.val) + "\n          "
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _vm.temp.category == 2 || _vm.temp.category == 4
                        ? _c(
                            "el-select",
                            {
                              ref: "categoryHotel",
                              staticStyle: { "min-width": "400px" },
                              attrs: {
                                filterable: "",
                                multiple: "",
                                placeholder: "请选择酒店",
                              },
                              model: {
                                value: _vm.temp.hotel_id,
                                callback: function ($$v) {
                                  _vm.$set(_vm.temp, "hotel_id", $$v)
                                },
                                expression: "temp.hotel_id",
                              },
                            },
                            _vm._l(_vm.hotelList, function (item) {
                              return _c("el-option", {
                                key: item.hotel_id,
                                attrs: {
                                  label: item.name,
                                  value: item.hotel_id,
                                },
                              })
                            }),
                            1
                          )
                        : _vm.temp.category == 3
                        ? _c(
                            "el-select",
                            {
                              ref: "categoryArea",
                              attrs: {
                                placeholder: "请选择区域",
                                clearable: "",
                              },
                              model: {
                                value: _vm.temp.area,
                                callback: function ($$v) {
                                  _vm.$set(_vm.temp, "area", $$v)
                                },
                                expression: "temp.area",
                              },
                            },
                            _vm._l(_vm.areas, function (item) {
                              return _c("el-option", {
                                key: item.role_id,
                                attrs: {
                                  label: item.name,
                                  value: item.role_id,
                                },
                              })
                            }),
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.temp.type && _vm.temp.type != 3
                ? _c("el-form-item", { attrs: { label: "使用门槛" } }, [
                    _c(
                      "div",
                      [
                        _vm._v("\n          订单满 "),
                        _c("el-input-number", {
                          staticStyle: { width: "60px" },
                          attrs: { size: "mini", controls: false, min: 0 },
                          model: {
                            value: _vm.temp.need_amount,
                            callback: function ($$v) {
                              _vm.$set(_vm.temp, "need_amount", $$v)
                            },
                            expression: "temp.need_amount",
                          },
                        }),
                        _vm._v(" 可用\n        "),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.temp.type && _vm.temp.type != 3
                ? _c(
                    "el-form-item",
                    { attrs: { label: "抵扣金额" } },
                    [
                      _c("el-input-number", {
                        attrs: { controls: false, min: 0 },
                        model: {
                          value: _vm.temp.amount,
                          callback: function ($$v) {
                            _vm.$set(_vm.temp, "amount", $$v)
                          },
                          expression: "temp.amount",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.temp.type && _vm.temp.type == 3
                ? _c(
                    "el-form-item",
                    { attrs: { label: "折扣" } },
                    [
                      _c("el-input-number", {
                        attrs: { controls: false, min: 0 },
                        model: {
                          value: _vm.temp.discount_per,
                          callback: function ($$v) {
                            _vm.$set(_vm.temp, "discount_per", $$v)
                          },
                          expression: "temp.discount_per",
                        },
                      }),
                      _vm._v(" 折\n      "),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "适用时段" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.temp.is_week,
                        callback: function ($$v) {
                          _vm.$set(_vm.temp, "is_week", $$v)
                        },
                        expression: "temp.is_week",
                      },
                    },
                    _vm._l(_vm.weekList, function (item) {
                      return _c(
                        "el-radio",
                        { key: item.label, attrs: { label: item.label } },
                        [
                          _vm._v(
                            "\n            " + _vm._s(item.val) + "\n          "
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "是否叠加" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.temp.is_multi,
                        callback: function ($$v) {
                          _vm.$set(_vm.temp, "is_multi", $$v)
                        },
                        expression: "temp.is_multi",
                      },
                    },
                    _vm._l(_vm.multiType, function (item) {
                      return _c(
                        "el-radio",
                        { key: item.label, attrs: { label: item.label } },
                        [
                          _vm._v(
                            "\n            " + _vm._s(item.val) + "\n          "
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "机位" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { multiple: "" },
                      model: {
                        value: _vm.temp.seat,
                        callback: function ($$v) {
                          _vm.$set(_vm.temp, "seat", $$v)
                        },
                        expression: "temp.seat",
                      },
                    },
                    _vm._l(_vm.seatList, function (item) {
                      return _c("el-option", {
                        key: item.val,
                        attrs: { label: item.label, value: item.val },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: { label: "每人限制领取数量", prop: "receive_per_num" },
                },
                [
                  _c("el-input-number", {
                    attrs: {
                      placeholder: "0表示不限制",
                      controls: false,
                      min: 0,
                    },
                    model: {
                      value: _vm.temp.receive_per_num,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp, "receive_per_num", $$v)
                      },
                      expression: "temp.receive_per_num",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "是否启用" } },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-text": "启用",
                      "inactive-text": "禁用",
                      "active-color": "#13ce66",
                      "inactive-color": "#ff4949",
                      "active-value": "1",
                      "inactive-value": "2",
                    },
                    model: {
                      value: _vm.temp.status,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp, "status", $$v)
                      },
                      expression: "temp.status",
                    },
                  }),
                ],
                1
              ),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = false
                    },
                  },
                },
                [_vm._v("\n        取 消\n      ")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: _vm.btn },
                  on: { click: _vm.createCoupon },
                },
                [_vm._v("\n        确 定\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }