"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
require("core-js/modules/es6.regexp.to-string");
var _order = require("@/api/order");
var _hotel = require("@/api/hotel");
var _auth = require("@/utils/auth");
var _coupon = require("@/api/coupon");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "Coupon",
  data: function data() {
    return {
      couponList: [],
      areas: [],
      data: [],
      list: null,
      hotelList: [],
      dialogFormVisible: false,
      listQuery: {
        hotel_id: "",
        coupon_id: "",
        page: 1,
        limit: 20,
        contacts: "",
        order_no: "",
        city: "",
        status: "",
        created_at: "",
        end_date: "",
        pay_at: ""
      },
      detail: {
        order_no: "",
        coupon: [],
        shop_coupon: []
      },
      rooms: [],
      pagination: "",
      disabledText: false,
      listLoading: false
    };
  },
  created: function created() {
    var _this = this;
    var iTime = new Date().getTime() - 180 * 24 * 3600 * 1000;
    this.listQuery.pay_at = [this.formatTime(new Date(iTime)), this.formatTime(new Date())];
    (0, _coupon.getManualList)().then(function (response) {
      _this.couponList = response.data;
    });
    this.getList();
    if ((0, _auth.getGrade)() < 1) {
      (0, _hotel.getArea)().then(function (response) {
        _this.areas = response.data;
      });
    }
  },
  methods: {
    formatTime: function formatTime(date) {
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? '0' + m : m;
      var d = date.getDate();
      d = d < 10 ? '0' + d : d;
      var h = date.getHours();
      h = h < 10 ? '0' + h : h;
      var minute = date.getMinutes();
      var second = date.getSeconds();
      minute = minute < 10 ? '0' + minute : minute;
      second = second < 10 ? '0' + second : second;
      return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second;
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    resetForm: function resetForm() {
      this.listQuery.city = "";
      this.listQuery.status = "";
      this.listQuery.contacts = "";
      this.listQuery.order_no = "";
      this.listQuery.hotel_id = "";
      this.listQuery.coupon_id = "";
      this.listQuery.status = "";
      this.listQuery.pay_at = "";
      this.listQuery.end_date = "";
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    getList: function getList() {
      var _this2 = this;
      this.listLoading = true;
      (0, _order.secKillOrder)(this.listQuery).then(function (response) {
        var data = response.data;
        _this2.list = data.data;
        _this2.pagination = data;
        _this2.listQuery.page = data.current_page;
        _this2.listLoading = false;
      });
    },
    download: function download() {
      var query = this.listQuery;
      var isArray = function isArray(obj) {
        return Object.prototype.toString.call(obj) === '[object Array]';
      };
      var url = Object.keys(this.listQuery).map(function (key) {
        if (isArray(query[key])) {
          var iUrl = '';
          query[key].forEach(function (item) {
            iUrl = iUrl + key + '[]=' + item + '&';
          });
          return iUrl.trim('&');
        } else {
          return encodeURIComponent(key) + "=" + encodeURIComponent(query[key]);
        }
      }).join("&");
      url += '&api_token=' + (0, _auth.getToken)();
      window.open((0, _order.getSecKillOrderDownloadUrl)(url));
    },
    handleRefund: function handleRefund(id) {
      var _this3 = this;
      this.$confirm('退单会给用户退款，且删除优惠券，确认操作吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _order.secKillRefund)(id).then(function () {
          _this3.getList();
          _this3.$message.success("退款申请成功");
        });
      }).catch(function () {
        return;
      });
    },
    handleDrag: function handleDrag() {
      this.$refs.select.blur();
    }
  }
};
exports.default = _default;