"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _agent = require("@/api/agent");
var _form = _interopRequireDefault(require("./form.vue"));
var _moment = _interopRequireDefault(require("moment"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "AgentDetail",
  components: {
    AgentForm: _form.default
  },
  data: function data() {
    return {
      activeName: "hotel",
      agentId: undefined,
      agent: {},
      list: [],
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 20,
        date: "",
        hotel_name: ""
      },
      pagination: ""
    };
  },
  created: function created() {
    this.agentId = this.$route.params.id;
    this.getAgent();
    this.getList();
    this.setDefaultDate();
  },
  methods: {
    setDefaultDate: function setDefaultDate() {
      var end = new Date();
      var start = new Date();
      end.setTime(end.getTime() - 3600 * 1000 * 24 * new Date().getDay());
      start.setTime(end.getTime() - 3600 * 1000 * 24 * 6);
      this.listQuery.date = [(0, _moment.default)(start).format('YYYY-MM-DD'), (0, _moment.default)(end).format('YYYY-MM-DD')];
    },
    getAgent: function getAgent() {
      var _this = this;
      (0, _agent.fetchAgent)(this.agentId).then(function (result) {
        _this.agent = result.data;
      });
    },
    getList: function getList() {
      var _this2 = this;
      this.listLoading = true;
      (0, _agent.fetchHotelList)(this.agentId, this.listQuery).then(function (response) {
        var data = response.data;
        _this2.list = data.data;
        _this2.pagination = data;
        _this2.listQuery.page = data.current_page;
        _this2.listLoading = false;
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    editHandler: function editHandler() {
      this.$refs['agentForm'].open();
    },
    toHotelBill: function toHotelBill(hotelId) {
      this.$router.push({
        path: "/bill/week",
        query: {
          bill_type: "permutation",
          hotel_id: hotelId
        }
      });
    }
  }
};
exports.default = _default;