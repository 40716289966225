"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.object.keys");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.to-string");
var _order = require("@/api/order");
var _hotel = require("@/api/hotel");
var _auth = require("@/utils/auth");
var _orderDetail = _interopRequireDefault(require("./orderDetail.vue"));
var _OrderRefundDialog = _interopRequireDefault(require("./components/OrderRefundDialog.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "Order",
  components: {
    OrderDetail: _orderDetail.default,
    OrderRefundDialog: _OrderRefundDialog.default
  },
  filters: {
    dateFormat: function dateFormat(value) {
      if (!value) return '';
      var date = value.toString();
      return date.slice(4, 6) + '月' + date.slice(6, 8) + '日';
    }
  },
  data: function data() {
    return {
      orderId: '',
      areas: [],
      data: [],
      list: null,
      hotelList: [],
      dialogFormVisible: false,
      listQuery: {},
      modeList: [],
      modeNameList: [],
      detail: {
        order_no: "",
        coupon: [],
        shop_coupon: []
      },
      rooms: [],
      pagination: "",
      disabledText: false,
      statusMap: {
        25: "付款成功",
        26: "已关闭,买家取消订单",
        31: "已关闭,买家取消订单",
        27: "已关闭,酒店取消订单",
        28: "已退款",
        29: "部分退款",
        30: "已确认",
        40: "已完成"
      },
      statusOptions: [{
        value: 25,
        label: '付款成功'
      }, {
        value: 26,
        label: '已关闭,买家取消订单'
      }, {
        value: 27,
        label: '已关闭,酒店取消订单'
      }, {
        value: 28,
        label: '已退款'
      }, {
        value: 29,
        label: '部分退款'
      }, {
        value: 30,
        label: '已确认'
      }, {
        value: 40,
        label: '已完成'
      }],
      dialogDetailVisible: false,
      listLoading: false,
      dialogfilterVisible: false,
      filterForm: {
        hotel_id: "",
        area_id: "",
        mode: "",
        contacts: "",
        status: "",
        start_date: "",
        end_date: "",
        pay_at: ""
      },
      hasFilter: false,
      refundOrderVisible: false,
      refundOrderId: ''
    };
  },
  created: function created() {
    var _this = this;
    this.initQuery();
    this.getList();
    (0, _hotel.getHotelSimpleList)().then(function (response) {
      _this.hotelList = response.data;
    });
    if ((0, _auth.getGrade)() < 1) {
      (0, _hotel.getArea)().then(function (response) {
        _this.areas = response.data;
      });
    }
    (0, _hotel.getModeList)().then(function (res) {
      // 指定可操作模式
      var allowModes = [1, 2, 4];
      _this.modeList = [];
      res.data.forEach(function (item) {
        if (allowModes.indexOf(item.value) >= 0) {
          _this.modeList.push(item);
        }
      });
      var modeNameList = [];
      _this.modeList.forEach(function (v) {
        modeNameList[v.value] = v.label;
      });
      _this.modeNameList = modeNameList;
    });
  },
  methods: {
    initQuery: function initQuery() {
      this.setListQuery();
      var iTime = new Date().getTime() - 180 * 24 * 3600 * 1000;
      this.listQuery.more.pay_at = [this.formatTime(new Date(iTime)), this.formatTime(new Date())];
      if (this.$route.query.start_date) {
        this.listQuery.more.end_date = [this.$route.query.start_date, this.$route.query.end_date];
        this.hasFilter = true;
      }
      if (this.$route.query.pay_start_date) {
        this.listQuery.more.pay_at = [this.$route.query.pay_start_date, this.$route.query.pay_end_date];
        this.hasFilter = true;
      }
      if (this.$route.query.hotel_id) {
        this.listQuery.more.hotel_id = parseInt(this.$route.query.hotel_id);
        this.hasFilter = true;
      }
    },
    formatTime: function formatTime(date) {
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? '0' + m : m;
      var d = date.getDate();
      d = d < 10 ? '0' + d : d;
      var h = date.getHours();
      h = h < 10 ? '0' + h : h;
      var minute = date.getMinutes();
      var second = date.getSeconds();
      minute = minute < 10 ? '0' + minute : minute;
      second = second < 10 ? '0' + second : second;
      return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second;
    },
    setListQuery: function setListQuery() {
      this.listQuery = {
        page: 1,
        limit: 20,
        order_no: "",
        more: {
          hotel_id: "",
          area_id: "",
          mode: "",
          contacts: "",
          status: "",
          start_date: "",
          end_date: "",
          pay_at: ""
        }
      };
    },
    getListQuery: function getListQuery() {
      var query = JSON.parse(JSON.stringify(this.listQuery));
      var more = query.more;
      delete query.more;
      return Object.assign(query, more);
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    filterFormHandler: function filterFormHandler() {
      this.filterForm = Object.assign(this.filterForm, this.listQuery.more);
      this.dialogfilterVisible = true;
    },
    onFilterSubmit: function onFilterSubmit() {
      var _this2 = this;
      Object.keys(this.filterForm).forEach(function (k) {
        if (_this2.filterForm[k] != "") {
          _this2.hasFilter = true;
        }
      });
      // 更新查询条件
      this.listQuery.more = Object.assign(this.listQuery.more, this.filterForm);
      this.handleFilter();
      this.dialogfilterVisible = false;
    },
    resetFilter: function resetFilter() {
      this.hasFilter = false;
      this.$refs["filterForm"].resetFields();
      this.setListQuery();
    },
    closeFilter: function closeFilter() {
      this.dialogfilterVisible = false;
    },
    resetForm: function resetForm() {
      this.hasFilter = false;
      this.setListQuery();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    //订单详情
    handleDetail: function handleDetail(id) {
      this.orderId = id;
      this.dialogDetailVisible = true;
      // orderDetail({id:id}).then(response =>{
      //   this.dialogDetailVisible = true;
      //   this.detail = response.data;
      //   // const that = this
      //   // if (that.detail.discount_amount > 0 ) {
      //   //   that.detail.coupon.push({id: 0, amount: that.detail.discount_amount, name: "连住优惠", type: -1})
      //   // }
      // });
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    getList: function getList() {
      var _this3 = this;
      this.listLoading = true;
      (0, _order.fetchList)(this.getListQuery()).then(function (response) {
        var data = response.data;
        _this3.list = data.data;
        _this3.pagination = data;
        _this3.listQuery.page = data.current_page;
        _this3.listLoading = false;
      });
    },
    download: function download() {
      var query = this.getListQuery();
      var isArray = function isArray(obj) {
        return Object.prototype.toString.call(obj) === '[object Array]';
      };
      var url = Object.keys(this.getListQuery()).map(function (key) {
        if (isArray(query[key])) {
          var iUrl = '';
          query[key].forEach(function (item) {
            iUrl = iUrl + key + '[]=' + item + '&';
          });
          return iUrl.trim('&');
        } else {
          return encodeURIComponent(key) + "=" + encodeURIComponent(query[key]);
        }
      }).join("&");
      url += '&api_token=' + (0, _auth.getToken)();
      window.open((0, _order.getAdminOrderDownloadUrl)(url));
    },
    //订单状态修改
    handleOrderChangeStatus: function handleOrderChangeStatus(id, status) {
      var _this4 = this;
      if (status == 27) {
        this.$confirm('确认拒单?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          (0, _order.orderChangeStatus)({
            order_id: id,
            status: status
          }).then(function () {
            _this4.getList();
            _this4.$message.success("操作成功");
          });
        }).catch(function () {
          // console.log(this.userId, { coupon_id: couponId })
          return;
        });
      } else {
        (0, _order.orderChangeStatus)({
          order_id: id,
          status: status
        }).then(function () {
          _this4.getList();
          _this4.$message.success("操作成功！");
        });
      }
    },
    handleCancel: function handleCancel(id) {
      var _this5 = this;
      this.$confirm('订单已确认，确认取消吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _order.cancel)(id).then(function () {
          _this5.getList();
          _this5.$message.success("操作成功");
        });
      }).catch(function () {
        return;
      });
    },
    handleRefund: function handleRefund(id) {
      this.refundOrderVisible = true;
      this.refundOrderId = id;
    },
    handleDrag: function handleDrag() {
      this.$refs.select.blur();
    },
    getRealAmount: function getRealAmount(record, key) {
      // 部分退款
      if (record.status == 29) {
        return record.real_info && record.real_info[key] || record[key];
      }
      return record[key];
    }
  }
};
exports.default = _default;