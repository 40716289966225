"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
require("core-js/modules/es6.regexp.to-string");
var _shared_store = require("@/api/shared_store");
var _auth = require("@/utils/auth");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "SharedStore",
  components: {},
  filters: {
    dateFormat: function dateFormat(value) {
      if (!value) return '';
      var date = value.toString();
      return date.slice(4, 6) + '月' + date.slice(6, 8) + '日';
    },
    previceDateFormat: function previceDateFormat(value) {
      if (!value) return '';
      var date = value.toString();
      return "".concat(date.slice(0, 4), "-").concat(date.slice(4, 6), "-").concat(date.slice(6, 8));
    }
  },
  data: function data() {
    return {
      storeList: [],
      list: null,
      listLoading: false,
      pagination: "",
      listQuery: {
        page: 1,
        limit: 20,
        order_no: "",
        store_id: "",
        status: "",
        pay_at: ""
      },
      statusOptions: [{
        value: 10,
        label: '待支付'
      }, {
        value: 15,
        label: '已退款'
      }, {
        value: 30,
        label: '进行中'
      }, {
        value: 40,
        label: '已完成'
      }],
      statusMap: {
        10: "待支付",
        15: "已退款",
        30: "进行中",
        40: "已完成"
      },
      dialogDetailVisible: false
    };
  },
  created: function created() {
    var _this = this;
    if (this.$route.query.store_id) {
      this.listQuery.store_id = parseInt(this.$route.query.store_id);
    }
    (0, _shared_store.getSimpleList)().then(function (response) {
      _this.storeList = response.data;
    });
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this2 = this;
      this.listLoading = true;
      (0, _shared_store.fetchOrderList)(this.listQuery).then(function (response) {
        var data = response.data;
        _this2.list = data.data;
        _this2.pagination = data;
        _this2.listQuery.page = data.current_page;
        _this2.listLoading = false;
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    resetForm: function resetForm() {
      this.listQuery.page = 1;
      this.listQuery.store_id = "";
      this.listQuery.order_no = "";
      this.listQuery.pay_at = "";
      this.listQuery.status = "";
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    download: function download() {
      var query = this.listQuery;
      var isArray = function isArray(obj) {
        return Object.prototype.toString.call(obj) === '[object Array]';
      };
      var url = Object.keys(this.listQuery).map(function (key) {
        if (isArray(query[key])) {
          var iUrl = '';
          query[key].forEach(function (item) {
            iUrl = iUrl + key + '[]=' + item + '&';
          });
          return iUrl.trim('&');
        } else {
          return encodeURIComponent(key) + "=" + encodeURIComponent(query[key]);
        }
      }).join("&");
      url += '&api_token=' + (0, _auth.getToken)();
      window.open((0, _shared_store.getOrderDownloadUrl)(url));
    },
    // handleDetail(id) {
    //   this.dialogDetailVisible = true;
    // },
    handleRefund: function handleRefund(id) {
      var _this3 = this;
      this.$confirm('订单已完成，确认退款吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _shared_store.orderRefund)(id).then(function () {
          _this3.getList();
          _this3.$message.success("操作成功");
        });
      }).catch(function () {
        return;
      });
    }
  }
};
exports.default = _default;