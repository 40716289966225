"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _finance_reco = require("@/api/finance_reco");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: "FinanceBillAdjustDetail",
  components: {},
  props: {
    id: {
      type: [String, Number]
    },
    visible: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    }
  },
  watch: {
    visible: function visible() {
      this.dialogVisible = this.visible;
    },
    id: function id() {
      if (this.id) {
        this.getList();
      }
    }
  },
  data: function data() {
    return {
      dialogVisible: false,
      loading: false,
      list: []
    };
  },
  created: function created() {},
  methods: {
    getList: function getList() {
      var _this = this;
      this.loading = true;
      (0, _finance_reco.getFinanceBill)(this.id).then(function (response) {
        _this.list = response.data.adjust_records || [];
        _this.loading = false;
      });
    },
    closeHanlder: function closeHanlder() {
      this.$emit('update:id', "");
      this.$emit('update:visible', false);
    }
  }
};
exports.default = _default2;