"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getPermutationDayBillDownloadUrl = getPermutationDayBillDownloadUrl;
exports.getPermutationFinancialSettlementDownloadUrl = getPermutationFinancialSettlementDownloadUrl;
exports.getPermutationWeekBillDownloadUrl = getPermutationWeekBillDownloadUrl;
exports.permutationDayList = permutationDayList;
exports.permutationFinancialSettlement = permutationFinancialSettlement;
exports.permutationHotelPaymentFlow = permutationHotelPaymentFlow;
exports.permutationTransfer = permutationTransfer;
exports.permutationTransferAll = permutationTransferAll;
exports.permutationTransferPreview = permutationTransferPreview;
exports.permutationWeekList = permutationWeekList;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
require("core-js/modules/es6.regexp.to-string");
var _request = _interopRequireDefault(require("@/utils/request"));
function permutationDayList(data) {
  return (0, _request.default)({
    url: 'permutationBills/day',
    method: 'get',
    params: data
  });
}
function getPermutationDayBillDownloadUrl(day, token) {
  return process.env.VUE_APP_BASE_API + "/permutationBills/dayDownload?date=".concat(day, "&api_token=").concat(token);
}
function permutationWeekList(data) {
  return (0, _request.default)({
    url: "/permutationBills/week",
    method: 'get',
    params: data
  });
}
function permutationTransferPreview(data) {
  return (0, _request.default)({
    url: "/permutationBills/transferPreview",
    method: 'post',
    params: data
  });
}
function permutationHotelPaymentFlow(data) {
  return (0, _request.default)({
    url: "/permutationBills/hotelPaymentFlow",
    method: 'post',
    params: data
  });
}
function permutationTransfer(data) {
  return (0, _request.default)({
    url: "/permutationBills/transfer",
    method: 'post',
    params: data
  });
}
function permutationTransferAll(data) {
  return (0, _request.default)({
    url: "/permutationBills/transferAll",
    method: 'post',
    params: data
  });
}
function getPermutationWeekBillDownloadUrl(params, token) {
  var isArray = function isArray(obj) {
    return Object.prototype.toString.call(obj) === '[object Array]';
  };
  var url = Object.keys(params).map(function (key) {
    if (isArray(params[key])) {
      var iUrl = '';
      params[key].forEach(function (item) {
        iUrl = iUrl + key + '[]=' + item + '&';
      });
      return iUrl.trim('&');
    } else {
      return encodeURIComponent(key) + "=" + encodeURIComponent(params[key]);
    }
  }).join("&");
  return process.env.VUE_APP_BASE_API + "/permutationBills/weekDownload?".concat(url, "&api_token=").concat(token);
}
function permutationFinancialSettlement(data) {
  return (0, _request.default)({
    url: 'permutationBills/financialSettlement',
    method: 'get',
    params: data
  });
}
function getPermutationFinancialSettlementDownloadUrl(url, token) {
  return process.env.VUE_APP_BASE_API + "/permutationBills/financialSettlementDownload?".concat(url, "&api_token=").concat(token);
}