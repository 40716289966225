"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _manager = require("@/api/manager");
var _agent = require("@/api/agent");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Agent',
  data: function data() {
    return {
      pagination: "",
      adminList: null,
      dialogFormVisible: false,
      agent: {},
      agentList: [],
      listQuery: {
        agent_id: "",
        page: 1,
        limit: 20
      }
    };
  },
  created: function created() {
    var _this = this;
    this.getAdminList();
    (0, _agent.getSimpleList)().then(function (response) {
      _this.agentList = response.data;
    });
  },
  methods: {
    getAdminList: function getAdminList() {
      var _this2 = this;
      this.listLoading = true;
      (0, _manager.fetchAgentList)(this.listQuery).then(function (res) {
        _this2.adminList = res.data.data;
        _this2.pagination = res.data;
        _this2.listLoading = false;
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getAdminList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getAdminList();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getAdminList();
    },
    addHandler: function addHandler() {
      this.agent = {
        id: undefined,
        role: 'agent'
      };
      this.dialogFormVisible = true;
    },
    editHandler: function editHandler(agentId) {
      var _this3 = this;
      this.agent = {
        id: agentId,
        role: 'agent'
      };
      (0, _manager.fetchAdmin)(agentId).then(function (res) {
        _this3.agent = res.data;
      });
      this.dialogFormVisible = true;
    },
    accountFormCloseHanlder: function accountFormCloseHanlder() {
      this.$refs['accountForm'].resetFields();
    },
    submitHandler: function submitHandler() {
      var _this4 = this;
      this.$refs['accountForm'].validate(function (valid) {
        if (!valid) {
          return false;
        }
        if (_this4.agent.id) {
          (0, _manager.updateAdmin)(_this4.agent.id, _this4.agent).then(function () {
            _this4.getAdminList();
            _this4.$message.success("编辑成功");
            _this4.dialogFormVisible = false;
          });
        } else {
          (0, _manager.addAdmin)(_this4.agent).then(function () {
            _this4.getAdminList();
            _this4.$message.success("添加成功");
            _this4.dialogFormVisible = false;
          });
        }
      });
    },
    deleteAdmin: function deleteAdmin(id) {
      var _this5 = this;
      (0, _manager.deleteAdmin)({
        id: id
      }).then(function (response) {
        if (response.code == 0) {
          _this5.$message({
            message: response.msg,
            type: 'success'
          });
          _this5.getAdminList();
        } else {
          _this5.$message({
            message: response.msg,
            type: 'error'
          });
        }
      });
    }
  }
};
exports.default = _default;