var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          key: "id",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            "element-loading-text": "给我一点时间",
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "ID" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.id))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "名称",
              "class-name": "overflow-on",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v("\n        " + _vm._s(scope.row.name) + "\n      "),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "状态" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.statusList[scope.row.status - 1].label)
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "领取时间" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.created_at))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.status == 1
                      ? _c(
                          "el-popconfirm",
                          {
                            attrs: { title: "确定核销该优惠券吗？" },
                            on: {
                              confirm: function ($event) {
                                return _vm.wirteOffHandler(scope.row)
                              },
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { slot: "reference", type: "text" },
                                slot: "reference",
                              },
                              [_vm._v("\n            手动核销\n          ")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status == 2
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleOrderDetail(
                                  scope.row.user_id,
                                  scope.row.id
                                )
                              },
                            },
                          },
                          [_vm._v("\n          查看订单\n        ")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("MemberOrderDetail", {
        attrs: { orderId: _vm.orderId, visible: _vm.orderDetailVisible },
        on: {
          "update:orderId": function ($event) {
            _vm.orderId = $event
          },
          "update:order-id": function ($event) {
            _vm.orderId = $event
          },
          "update:visible": function ($event) {
            _vm.orderDetailVisible = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }