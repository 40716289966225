"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es6.number.constructor");
var _order = require("@/api/order");
var _order_detail = _interopRequireDefault(require("./order_detail"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "MemberHotelOrder",
  components: {
    MemberOrderDetail: _order_detail.default
  },
  props: {
    userId: {
      type: [String, Number]
    }
  },
  filters: {
    dateFormat: function dateFormat(value) {
      if (!value) return '';
      var date = value.toString();
      return date.slice(4, 6) + '月' + date.slice(6, 8) + '日';
    }
  },
  data: function data() {
    return {
      list: [],
      pagination: {},
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 20
      },
      statusMap: {
        25: "付款成功",
        26: "已关闭,买家取消订单",
        31: "已关闭,买家取消订单",
        27: "已关闭,酒店取消订单",
        28: "已退款",
        29: "部分退款",
        30: "已确认",
        40: "已完成"
      },
      orderId: "",
      orderDetailVisible: false
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      (0, _order.memberOrder)(this.listQuery, this.userId).then(function (response) {
        var data = response.data;
        _this.list = data.data;
        _this.pagination = data;
        _this.listQuery.page = data.current_page;
        _this.listLoading = false;
      });
      this.listLoading = false;
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    getRealAmount: function getRealAmount(record, key) {
      // 部分退款
      if (record.status == 29) {
        return record.real_info && record.real_info[key] || record[key];
      }
      return record[key];
    },
    handleDetail: function handleDetail(id) {
      this.orderId = id;
      this.orderDetailVisible = true;
    }
  }
};
exports.default = _default;