var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container calendar-list-container week-base-page" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              attrs: { filterable: "", clearable: "", placeholder: "酒店" },
              model: {
                value: _vm.listQuery.hotel_id,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "hotel_id", $$v)
                },
                expression: "listQuery.hotel_id",
              },
            },
            _vm._l(_vm.hotelList, function (item) {
              return _c("el-option", {
                key: item.hotel_id,
                attrs: { label: item.name, value: item.hotel_id },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "160px" },
              attrs: { filterable: "", clearable: "", placeholder: "酒店模式" },
              model: {
                value: _vm.listQuery.mode,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "mode", $$v)
                },
                expression: "listQuery.mode",
              },
            },
            _vm._l(_vm.modeList, function (item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.label, value: item.value },
              })
            }),
            1
          ),
          _vm._v(" "),
          _vm.areas.length > 0
            ? _c(
                "el-select",
                {
                  staticClass: "filter-item",
                  staticStyle: { width: "160px" },
                  attrs: {
                    "default-first-option": "",
                    placeholder: "请选择区域",
                    clearable: "",
                  },
                  model: {
                    value: _vm.listQuery.area_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery, "area_id", $$v)
                    },
                    expression: "listQuery.area_id",
                  },
                },
                _vm._l(_vm.areas, function (item) {
                  return _c("el-option", {
                    key: item.role_id,
                    attrs: { label: item.name, value: item.role_id },
                  })
                }),
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "160px" },
              attrs: { clearable: "", placeholder: "状态" },
              model: {
                value: _vm.listQuery.status,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "status", $$v)
                },
                expression: "listQuery.status",
              },
            },
            _vm._l(_vm.statusOptions, function (item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.label, value: item.value },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "160px" },
              attrs: { clearable: "", placeholder: "充值状态" },
              model: {
                value: _vm.listQuery.recharge_status,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "recharge_status", $$v)
                },
                expression: "listQuery.recharge_status",
              },
            },
            _vm._l(_vm.rechargeStatusOptions, function (item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.label, value: item.value },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c("el-date-picker", {
            staticClass: "filter-item",
            staticStyle: { width: "210px" },
            attrs: {
              "picker-options": { firstDayOfWeek: 1 },
              type: "week",
              "value-format": "yyyyMMdd",
              format: "yyyy 年 第 WW 周",
              placeholder: "账单周期",
            },
            model: {
              value: _vm.listQuery.bill_week,
              callback: function ($$v) {
                _vm.$set(_vm.listQuery, "bill_week", $$v)
              },
              expression: "listQuery.bill_week",
            },
          }),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              staticStyle: { "margin-left": "10px" },
              attrs: { type: "primary" },
              on: { click: _vm.handleFilter },
            },
            [_vm._v("\n      查询\n    ")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            { staticClass: "filter-item", on: { click: _vm.resetForm } },
            [_vm._v("\n      重置\n    ")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.multipleSelection.length > 0
        ? _c("el-alert", {
            attrs: {
              title: _vm.alert_title,
              type: "success",
              "close-text": "清空",
              "show-icon": "",
              closable: true,
            },
            on: { close: _vm.resetBlank },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          ref: "multipleTable",
          attrs: {
            data: _vm.list,
            "element-loading-text": "给我一点时间",
            border: "",
            "highlight-current-row": "",
          },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("el-table-column", { attrs: { type: "selection" } }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "对账周期", width: "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm._f("dateFormat")(scope.row.start_date))
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", [_vm._v("至")]),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(_vm._s(_vm._f("dateFormat")(scope.row.end_date))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "酒店名称", "min-width": "180" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.hotel_name))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c(
            "el-table-column",
            {
              attrs: { align: "center", width: "100" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        "\n        " + _vm._s(scope.row.pay_amount) + "\n      "
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _c("span", [_vm._v("售房金额")]),
                  _vm._v(" "),
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        effect: "dark",
                        content: "所有订单中用户实付金额总和，含已退款",
                        placement: "top",
                      },
                    },
                    [_c("i", { staticClass: "el-icon-question color-success" })]
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "退款金额", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.refund_amount) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c(
            "el-table-column",
            {
              attrs: { align: "center", width: "100" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        "\n        " +
                          _vm._s(scope.row.consume_amount) +
                          "\n      "
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _c("span", [_vm._v("消费金额")]),
                  _vm._v(" "),
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        effect: "dark",
                        content: "已入住订单中用户实付金额总和",
                        placement: "top",
                      },
                    },
                    [_c("i", { staticClass: "el-icon-question color-success" })]
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "佣金", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " + _vm._s(scope.row.commission) + "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c(
            "el-table-column",
            {
              attrs: { align: "center", label: "未消费金额", width: "110" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        "\n        " +
                          _vm._s(scope.row.unconsume_amount) +
                          "\n      "
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _c("span", [_vm._v("未消费金额")]),
                  _vm._v(" "),
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        effect: "dark",
                        content: "本账期内用户已支付但是尚未入住的金额",
                        placement: "top",
                      },
                    },
                    [_c("i", { staticClass: "el-icon-question color-success" })]
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "el-table-column",
            {
              attrs: { align: "center", width: "140" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        "\n        " +
                          _vm._s(scope.row.pay_discount_amount) +
                          "\n      "
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _c("template", { slot: "header" }, [
                _c(
                  "div",
                  [
                    _c("span", [_vm._v("已付款平台优惠")]),
                    _vm._v(" "),
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: "所有订单中平台补贴金额总和，含已退款",
                          placement: "top",
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "el-icon-question color-success",
                        }),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("span", [_vm._v("（电竞服务费费）")]),
              ]),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "el-table-column",
            {
              attrs: { align: "center", width: "140" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        "\n        " +
                          _vm._s(scope.row.refund_discount_amount) +
                          "\n      "
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _c("span", [_vm._v("已退款平台优惠")]),
                  _vm._v(" "),
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        effect: "dark",
                        content: "已退款订单中平台补贴金额总和",
                        placement: "top",
                      },
                    },
                    [_c("i", { staticClass: "el-icon-question color-success" })]
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "已消耗平台优惠", width: "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.consume_discount_amount) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "未消费平台优惠", width: "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.unconsume_discount_amount) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "商家优惠", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.shop_discount_amount) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c(
            "el-table-column",
            {
              attrs: { align: "center", label: "商家应收", width: "100" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        "\n        " +
                          _vm._s(scope.row.hotel_amount) +
                          "\n      "
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _c("span", [_vm._v("商家应收")]),
                  _vm._v(" "),
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        effect: "dark",
                        content: "商家应收 = 消费金额 + 已消耗平台优惠 - 佣金",
                        placement: "top",
                      },
                    },
                    [_c("i", { staticClass: "el-icon-question color-success" })]
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "el-table-column",
            {
              attrs: { align: "center", label: "转账金额", width: "100" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            scope.row.hotel_amount -
                              scope.row.consume_discount_amount
                          ) +
                          "\n      "
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _c("span", [_vm._v("转账金额")]),
                  _vm._v(" "),
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        effect: "dark",
                        content: "转账金额 = 商家应收 - 已消耗平台优惠",
                        placement: "top",
                      },
                    },
                    [_c("i", { staticClass: "el-icon-question color-success" })]
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "状态", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", [
                      _vm._v(_vm._s(_vm.statusNameMap[scope.row.status])),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v(
                        _vm._s(
                          _vm.rechargeStatusNameMap[scope.row.recharge_status]
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "操作",
              fixed: "right",
              width: "120",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-link",
                      {
                        attrs: { type: "primary", underline: false },
                        on: {
                          click: function ($event) {
                            return _vm.toOrder(scope.row)
                          },
                        },
                      },
                      [_vm._v("\n          查看订单\n        ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { "margin-top": "20px" } },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.exportBill } },
            [_vm._v("\n      导出账单\n    ")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.download } },
            [_vm._v("\n      全部导出\n    ")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            { attrs: { type: "success" }, on: { click: _vm.transfer } },
            [_vm._v("\n      确认转账\n    ")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            { attrs: { type: "success" }, on: { click: _vm.transferAll } },
            [_vm._v("\n      全部转账\n    ")]
          ),
          _vm._v(" "),
          _c("el-button", { on: { click: _vm.recharge } }, [
            _vm._v("\n      确认充值\n    "),
          ]),
          _vm._v(" "),
          _c("el-button", { on: { click: _vm.rechargeAll } }, [
            _vm._v("\n      全部充值\n    "),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "pagination-container text-right" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              total: _vm.pagination.total,
              "current-page": _vm.listQuery.page,
              "page-size": _vm.listQuery.limit,
              layout: "total, sizes, prev, pager, next, jumper",
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }