var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "finance-order-table" },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.data,
            "element-loading-text": "给我一点时间",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "订单类型", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [_vm._v(_vm._s(scope.row.third_order_type))]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              "show-overflow-tooltip": true,
              label: "酒店名称",
              "min-width": "180",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.hotel_name))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "调整金额", width: "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.amount) + "元")])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "原因", "min-width": "180" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.reason))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "订单号", width: "180" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.order_no))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c(
            "el-table-column",
            {
              attrs: { align: "center", width: "100" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.adjust_undertaker > 0
                        ? _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.adjustUndertakerNameMap[
                                  scope.row.adjust_undertaker
                                ]
                              )
                            ),
                          ])
                        : _c("span", { staticStyle: { color: "#909399" } }, [
                            _vm._v("未设置"),
                          ]),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _c("span", [_vm._v("承担方")]),
                  _vm._v(" "),
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        effect: "dark",
                        content:
                          "若承担方为商家，则将从本周期的商家应收中扣除调整金额",
                        placement: "top",
                      },
                    },
                    [_c("i", { staticClass: "el-icon-question color-success" })]
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "更新时间", width: "180" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.updated_at))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              fixed: "right",
              align: "center",
              label: "操作",
              width: "120",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    !scope.row.bill_exists
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.editHandler(scope.row)
                              },
                            },
                          },
                          [_vm._v("\n          编辑\n        ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.allowAction.allowDelete && _vm.inDateRange(scope.row)
                      ? _c(
                          "el-button",
                          {
                            staticStyle: { color: "#F56C6C" },
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.deleteHandler(scope.row.id)
                              },
                            },
                          },
                          [_vm._v("\n          删除\n        ")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "pagination-container text-right" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              total: _vm.pagination.total,
              "current-page": _vm.query.page,
              "page-sizes": [10, 20, 50],
              "page-size": _vm.query.limit,
              layout: "total, sizes, prev, pager, next, jumper",
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("adjust-order-form", {
        ref: "orderForm",
        attrs: {
          id: _vm.orderId,
          visible: _vm.orderFormVisible,
          "allow-modify": _vm.allowAction.allowModify,
          "date-range": _vm.dateRange,
        },
        on: {
          "update:id": function ($event) {
            _vm.orderId = $event
          },
          "update:visible": function ($event) {
            _vm.orderFormVisible = $event
          },
          success: _vm.getList,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }