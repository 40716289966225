"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.array.find");
require("core-js/modules/es6.number.constructor");
var _drag_select = _interopRequireDefault(require("@/views/components/_drag_select.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: "PriceCalendar",
  components: {
    "drag-select-container": _drag_select.default
  },
  props: {
    value: {
      type: Object
    },
    title: {
      type: [Number, String],
      default: function _default() {
        return "";
      }
    }
  },
  data: function data() {
    return {
      weeks: [{
        label: "周一",
        value: 1
      }, {
        label: "周二",
        value: 2
      }, {
        label: "周三",
        value: 3
      }, {
        label: "周四",
        value: 4
      }, {
        label: "周五",
        value: 5
      }, {
        label: "周六",
        value: 6
      }, {
        label: "周日",
        value: 7
      }],
      hours: [{
        label: "00",
        value: 0
      }, {
        label: "01",
        value: 1
      }, {
        label: "02",
        value: 2
      }, {
        label: "03",
        value: 3
      }, {
        label: "04",
        value: 4
      }, {
        label: "05",
        value: 5
      }, {
        label: "06",
        value: 6
      }, {
        label: "07",
        value: 7
      }, {
        label: "08",
        value: 8
      }, {
        label: "09",
        value: 9
      }, {
        label: "10",
        value: 10
      }, {
        label: "11",
        value: 11
      }, {
        label: "12",
        value: 12
      }, {
        label: "13",
        value: 13
      }, {
        label: "14",
        value: 14
      }, {
        label: "15",
        value: 15
      }, {
        label: "16",
        value: 16
      }, {
        label: "17",
        value: 17
      }, {
        label: "18",
        value: 18
      }, {
        label: "19",
        value: 19
      }, {
        label: "20",
        value: 20
      }, {
        label: "21",
        value: 21
      }, {
        label: "22",
        value: 22
      }, {
        label: "23",
        value: 23
      }],
      prices: {},
      selectIds: [],
      priceDialogVisible: false,
      priceForm: {
        price: undefined
      },
      priceFormRules: {
        price: [{
          required: true,
          message: '请输入计费价格金额',
          trigger: 'change'
        }, {
          type: "number",
          min: 0,
          max: 1000000,
          message: '计费价格不能小于0',
          trigger: 'change'
        }]
      }
    };
  },
  watch: {
    value: function value(newVal) {
      this.prices = JSON.parse(JSON.stringify(newVal));
    }
  },
  created: function created() {},
  methods: {
    // init() {
    //   this.weeks.forEach(week => {
    //     this.hours.forEach(hour => {
    //       this.prices[`${week.value}_${hour.value}`] = undefined
    //     })
    //   })
    // },
    getClasses: function getClasses(weekId, hourId, selectedItems) {
      var itemId = "".concat(weekId, "_").concat(hourId);
      var isActive = !!selectedItems.find(function (selectedItem) {
        return selectedItem.dataset.item === itemId;
      });
      return {
        block: true,
        'hour-item': true,
        'hour-item-seted': this.prices[itemId] != undefined,
        'hour-item-last': hourId == 23,
        // 最后一个小时
        active: isActive
      };
    },
    getPrice: function getPrice(weekId, hourId) {
      return this.prices["".concat(weekId, "_").concat(hourId)];
    },
    onSelectConfirm: function onSelectConfirm(selectedItems) {
      var _this = this;
      var selectIds = selectedItems.map(function (selectedItem) {
        return selectedItem.dataset.item;
      });
      this.selectIds = selectIds;
      if (selectIds.length) {
        // 价格弹窗
        this.priceForm.price = undefined;
        this.priceDialogVisible = true;
        this.$nextTick(function () {
          _this.$refs.priceFormPriceInput.focus();
        });
      }
    },
    priceCloseHanlder: function priceCloseHanlder() {
      this.$refs['priceForm'].resetFields();
      this.priceDialogVisible = false;
      // 清空选中状态及信息
      this.selectIds = [];
      this.$refs.HourSelection.reset();
    },
    priceSubmitHandler: function priceSubmitHandler() {
      var _this2 = this;
      this.$refs['priceForm'].validate(function (valid) {
        if (valid) {
          _this2.selectIds.forEach(function (selectId) {
            _this2.prices[selectId] = _this2.priceForm.price;
          });
          _this2.priceCloseHanlder();
          _this2.$emit("input", JSON.parse(JSON.stringify(_this2.prices)));
        }
      });
    }
  }
};
exports.default = _default2;