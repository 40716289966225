"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es6.number.constructor");
var _order = require("@/api/order");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: "MemberOrderDetail",
  components: {},
  props: {
    orderId: {
      type: [String, Number]
    },
    visible: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    }
  },
  watch: {
    visible: function visible() {
      this.dialogVisible = this.visible;
    },
    orderId: function orderId() {
      if (this.orderId) {
        this.getDetail();
      }
    }
  },
  filters: {
    dateFormat: function dateFormat(value) {
      if (!value) return '';
      var date = value.toString();
      return date.slice(4, 6) + '月' + date.slice(6, 8) + '日';
    }
  },
  data: function data() {
    return {
      dialogVisible: false,
      loading: false,
      detail: {
        order_no: "",
        coupon: [],
        shop_coupon: []
      },
      statusMap: {
        25: "付款成功",
        26: "已关闭,买家取消订单",
        31: "已关闭,买家取消订单",
        27: "已关闭,酒店取消订单",
        28: "已退款",
        29: "部分退款",
        30: "已确认",
        40: "已完成"
      }
    };
  },
  created: function created() {},
  methods: {
    getDetail: function getDetail() {
      var _this = this;
      this.loading = true;
      (0, _order.orderDetail)({
        id: this.orderId
      }).then(function (response) {
        _this.detail = response.data;
        var that = _this;
        if (that.detail.discount_amount > 0) {
          that.detail.coupon.push({
            id: 0,
            amount: that.detail.discount_amount,
            name: "连住优惠",
            type: -1
          });
        }
        _this.loading = false;
      });
    },
    closeHanlder: function closeHanlder() {
      this.$emit('update:orderId', "");
      this.$emit('update:visible', false);
    }
  }
};
exports.default = _default2;