"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.to-string");
var _finance_reco = require("@/api/finance_reco");
var _AdjustOrderForm = _interopRequireDefault(require("./AdjustOrderForm.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "FinanceAdjustOrderTable",
  components: {
    AdjustOrderForm: _AdjustOrderForm.default
  },
  filters: {
    dateFormat: function dateFormat(value) {
      if (!value) return '';
      var date = value.toString();
      return date.slice(0, 4) + '-' + date.slice(4, 6) + '-' + date.slice(6, 8);
    }
  },
  props: {
    loading: [Boolean],
    data: [Array],
    query: [Object],
    pagination: [Object],
    allowAction: [Object],
    dateRange: [Array]
  },
  data: function data() {
    return {
      orderFormVisible: false,
      orderId: '',
      adjustUndertakerNameMap: {
        // 0: "未设置",
        1: "商家",
        2: "平台"
      }
    };
  },
  created: function created() {},
  methods: {
    handleSizeChange: function handleSizeChange(val) {
      var query = this.query;
      query.limit = val;
      this.$emit('update:query', query);
      this.getList();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      var query = this.query;
      query.page = val;
      this.$emit('update:query', query);
      this.getList();
    },
    getList: function getList() {
      this.$emit("getData", this.query);
    },
    editHandler: function editHandler(record) {
      this.orderId = record.id;
      this.orderFormVisible = true;
    },
    inDateRange: function inDateRange(record) {
      if (this.dateRange.length == 0) {
        return false;
      }
      var endDateStr = record.bill_end_date.toString();
      endDateStr = endDateStr.slice(0, 4) + '-' + endDateStr.slice(4, 6) + '-' + endDateStr.slice(6, 8);
      return endDateStr >= this.dateRange[0] && endDateStr <= this.dateRange[1];
    },
    deleteHandler: function deleteHandler(id) {
      var _this = this;
      this.$confirm('确认删除订单吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _finance_reco.deleteFinanceRecoOrder)(id).then(function () {
          _this.getList();
          _this.$message.success("操作成功");
        });
      });
    }
  }
};
exports.default = _default;