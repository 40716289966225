"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.download = download;
exports.getData = getData;
exports.getSelectedList = getSelectedList;
var _request = _interopRequireDefault(require("@/utils/request"));
function getData(type, data) {
  return (0, _request.default)({
    url: "/statistics/".concat(type),
    method: 'get',
    params: data
  });
}
function download(type, data) {
  return (0, _request.default)({
    url: "/statistics/download/".concat(type),
    method: 'get',
    params: data
  });
}
function getSelectedList(type, data) {
  return (0, _request.default)({
    url: "/getSelectedList/".concat(type),
    method: 'get',
    params: data
  });
}