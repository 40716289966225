"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _net_bar = require("@/api/net_bar");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: "NetBarStoreRechargeFrom",
  components: {},
  props: {
    id: [Number, String],
    storeId: {
      type: [String, Number]
    },
    visible: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    }
  },
  watch: {
    visible: function visible() {
      this.dialogVisible = this.visible;
    },
    id: function id() {
      if (this.id) {
        this.getDetail();
      }
    }
  },
  data: function data() {
    return {
      dialogVisible: false,
      formLoading: false,
      submitLoading: false,
      dataForm: {
        id: undefined,
        recharge_card: 1,
        recharge_type: 1,
        recharge_amount: undefined,
        gift_amount: undefined,
        is_recommend: 0,
        eff_type: 1,
        eff_date: undefined
      },
      dataFormRules: {
        recharge_card: [{
          required: true,
          message: '请选择充值卡片',
          trigger: 'blur'
        }],
        recharge_type: [{
          required: true,
          message: '请选择充值类型',
          trigger: 'blur'
        }],
        recharge_amount: [{
          required: true,
          message: '请输入金额',
          trigger: 'blur'
        }, {
          type: "number",
          min: 0.01,
          max: 1000000,
          message: '金额不能小于0',
          trigger: 'blur'
        }],
        gift_amount: [{
          required: true,
          message: '请输入赠送金额',
          trigger: 'blur'
        }, {
          type: "number",
          min: 0,
          max: 1000000,
          message: '赠送金额不能小于0',
          trigger: 'blur'
        }],
        is_recommend: [{
          required: true,
          message: '请选择是否推荐',
          trigger: 'blur'
        }],
        eff_type: [{
          required: true,
          message: '请选择有效期',
          trigger: 'blur'
        }],
        eff_date: [{
          required: true,
          message: '请选择生效时间',
          trigger: 'blur'
        }]
      },
      effDatePickerOptions: {
        disabledDate: function disabledDate(time) {
          return time.getTime() < Date.now() - 86400000;
        }
      }
    };
  },
  mounted: function mounted() {},
  methods: {
    onRechargeCardChange: function onRechargeCardChange(v) {
      this.dataForm.gift_amount = undefined;
      this.dataForm.is_recommend = 0;
      this.dataForm.eff_type = 1;
      this.dataForm.eff_date = undefined;
      if (v == 2) {
        // 临时卡只支持自定义
        this.dataForm.recharge_type = 2;
      }
    },
    onRechargeTypeChange: function onRechargeTypeChange(v) {
      if (v == 2) {
        // 临时卡不需要设置下列设置
        this.dataForm.gift_amount = undefined;
        this.dataForm.is_recommend = 0;
        this.dataForm.eff_type = 1;
        this.dataForm.eff_date = undefined;
      }
    },
    onEffTypeChange: function onEffTypeChange(v) {
      if (v == 1) {
        this.dataForm.eff_date = undefined;
      }
    },
    getDetail: function getDetail() {
      var _this = this;
      this.formLoading = true;
      (0, _net_bar.fetchStoreRecharge)(this.storeId, this.id).then(function (result) {
        var data = result.data;
        var effDate = undefined;
        if (data.start_time && data.end_time) {
          effDate = [data.start_time, data.end_time];
        }
        _this.dataForm = {
          id: data.id,
          recharge_card: data.recharge_card,
          recharge_type: data.recharge_type,
          recharge_amount: data.recharge_amount,
          gift_amount: data.gift_amount,
          is_recommend: data.is_recommend,
          eff_type: data.eff_type,
          eff_date: effDate
        };
        _this.formLoading = false;
      });
    },
    closeHanlder: function closeHanlder() {
      this.dataForm.id = undefined;
      this.$refs['dataForm'].resetFields();
      this.$emit('update:id', "");
      this.$emit('update:visible', false);
    },
    submitHandler: function submitHandler() {
      var _this2 = this;
      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          if (_this2.dataForm.id) {
            _this2.doUpadte();
          } else {
            _this2.doCreate();
          }
        }
      });
    },
    doCreate: function doCreate() {
      var _this3 = this;
      this.submitLoading = true;
      (0, _net_bar.createStoreRecharge)(this.storeId, this.dataForm).then(function () {
        _this3.$message.success("操作成功");
        _this3.$emit('success');
        _this3.dialogVisible = false;
      }).finally(function () {
        _this3.submitLoading = false;
      });
    },
    doUpadte: function doUpadte() {
      var _this4 = this;
      this.submitLoading = true;
      (0, _net_bar.updateStoreRecharge)(this.storeId, this.dataForm.id, this.dataForm).then(function () {
        _this4.$message.success("操作成功");
        _this4.$emit('success');
        _this4.dialogVisible = false;
      }).finally(function () {
        _this4.submitLoading = false;
      });
    }
  }
};
exports.default = _default2;