"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addRoom = addRoom;
exports.batchSetRoomPrice = batchSetRoomPrice;
exports.closeRoom = closeRoom;
exports.deleteRoom = deleteRoom;
exports.fetchList = fetchList;
exports.getChangePriceRecord = getChangePriceRecord;
exports.getChangeStockRecord = getChangeStockRecord;
exports.getRoom = getRoom;
exports.getRoomPrice = getRoomPrice;
exports.getRoomStat = getRoomStat;
exports.getRoomStatHotels = getRoomStatHotels;
exports.getRoomStock = getRoomStock;
exports.getRoomTodayPrices = getRoomTodayPrices;
exports.setRoomPrice = setRoomPrice;
exports.setRoomStat = setRoomStat;
exports.setRoomStock = setRoomStock;
exports.simpleList = simpleList;
exports.updateRoom = updateRoom;
var _request = _interopRequireDefault(require("@/utils/request"));
function fetchList(id) {
  return (0, _request.default)({
    url: 'roomList/' + id,
    method: 'get'
  });
}
function simpleList(id) {
  return (0, _request.default)({
    url: "room/simpleList/".concat(id),
    method: 'get'
  });
}
function getRoom(id, data) {
  return (0, _request.default)({
    url: "/room/".concat(id),
    method: 'get',
    params: data
  });
}
function addRoom(data) {
  return (0, _request.default)({
    url: 'room',
    method: 'post',
    data: data
  });
}
function updateRoom(id, data) {
  return (0, _request.default)({
    url: "/room/".concat(id),
    method: 'put',
    data: data
  });
}
function closeRoom(id) {
  return (0, _request.default)({
    url: "/closeRoom/".concat(id),
    method: 'post'
  });
}
function setRoomPrice(id, data) {
  return (0, _request.default)({
    url: "/setRoomPrice/".concat(id),
    method: 'put',
    data: data
  });
}
function getRoomPrice(id, data) {
  return (0, _request.default)({
    url: "/getRoomPrice/".concat(id),
    method: 'get',
    params: data
  });
}
function getRoomTodayPrices(params) {
  return (0, _request.default)({
    url: "/getRoomTodayPrices",
    method: 'get',
    params: params
  });
}
function batchSetRoomPrice(data) {
  return (0, _request.default)({
    url: "/batchSetRoomPrice",
    method: 'put',
    data: data
  });
}
function deleteRoom(id) {
  return (0, _request.default)({
    url: "/room/".concat(id),
    method: 'delete'
  });
}
function getChangePriceRecord(id, params) {
  params = params || {};
  return (0, _request.default)({
    url: "/getChangePriceRecord/".concat(id),
    method: 'get',
    params: params
  });
}
function getRoomStock(id, data) {
  return (0, _request.default)({
    url: "/getRoomStock/".concat(id),
    method: 'get',
    params: data
  });
}
function setRoomStock(id, data) {
  return (0, _request.default)({
    url: "/setRoomStock/".concat(id),
    method: 'put',
    data: data
  });
}
function setRoomStat(id, data) {
  return (0, _request.default)({
    url: "/setRoomStat/".concat(id),
    method: 'put',
    data: data
  });
}
function getChangeStockRecord(id) {
  return (0, _request.default)({
    url: "/getChangeStockRecord/".concat(id),
    method: 'get'
  });
}

/************ 房情表 **************/

function getRoomStatHotels() {
  return (0, _request.default)({
    url: "/enable_room_stat_hotels",
    method: 'get'
  });
}
function getRoomStat(hotelId, params) {
  params = params || {};
  return (0, _request.default)({
    url: "/hotels/".concat(hotelId, "/room_stat"),
    method: 'get',
    params: params
  });
}