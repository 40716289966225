"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.array.sort");
var _point_shop = require("@/api/point_shop");
var _vuedraggable = _interopRequireDefault(require("vuedraggable"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "PointShopCategory",
  components: {
    draggable: _vuedraggable.default
  },
  data: function data() {
    return {
      list: [],
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 20,
        title: "",
        is_show: ""
      },
      pagination: "",
      showOptions: [{
        value: '',
        label: '全部'
      }, {
        value: 1,
        label: '显示'
      }, {
        value: 0,
        label: '隐藏'
      }],
      goods: [],
      dialogFormVisible: false,
      dialogFormTitle: "新增分类",
      typeForm: {
        id: "",
        title: "",
        sort: 0,
        is_show: 1,
        goods: []
      },
      typeFormRules: {
        title: [{
          required: true,
          message: '请输入分类名称',
          trigger: 'blur'
        }],
        sort: [{
          required: true,
          message: '请输入排序值',
          trigger: 'blur'
        }],
        is_show: [{
          required: true,
          message: '请选择状态',
          trigger: 'blur'
        }]
      },
      typeFormConfirmLoading: false,
      dialogSortVisible: false,
      sortFormConfirmLoading: false,
      sortGoodsCategoryId: "",
      sortGoods: [],
      sortGoodsLength: 0
    };
  },
  created: function created() {
    this.getList();
    this.getGoods();
  },
  methods: {
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      (0, _point_shop.fetchCatetoryList)(this.listQuery).then(function (response) {
        var data = response.data;
        _this.list = data.data;
        _this.pagination = data;
        _this.listQuery.page = data.current_page;
        _this.listLoading = false;
      });
    },
    getGoods: function getGoods() {
      var _this2 = this;
      (0, _point_shop.fetchGoodsSimple)().then(function (response) {
        _this2.goods = response.data;
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleReset: function handleReset() {
      this.listQuery.title = "";
      this.listQuery.is_show = "";
      this.handleFilter();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    addTypeHandler: function addTypeHandler() {
      this.dialogFormTitle = '添加分类';
      this.dialogFormVisible = true;
    },
    editTypeHandler: function editTypeHandler(record) {
      this.dialogFormTitle = '编辑分类';
      this.typeForm = {
        id: record.id,
        title: record.title,
        sort: record.sort,
        is_show: record.is_show,
        goods: record.goods
      };
      this.dialogFormVisible = true;
    },
    typeFormCloseHanlder: function typeFormCloseHanlder() {
      this.$refs.typeForm.resetFields();
      this.typeForm.id = '';
    },
    typeFormSubmitHandler: function typeFormSubmitHandler() {
      var _this3 = this;
      this.$refs['typeForm'].validate(function (valid) {
        if (valid) {
          var form = JSON.parse(JSON.stringify(_this3.typeForm));
          _this3.typeFormConfirmLoading = true;
          var successCallback = function successCallback(res) {
            _this3.listQuery.page = 1;
            _this3.getList();
            _this3.$message.success(res.msg);
            _this3.dialogFormVisible = false;
            _this3.typeFormConfirmLoading = false;
          };
          if (form.id) {
            (0, _point_shop.updateCatetory)(form.id, form).then(function (res) {
              _this3.typeForm.id = '';
              successCallback(res);
            }).catch(function () {
              _this3.typeFormConfirmLoading = false;
            });
          } else {
            // 添加分类
            (0, _point_shop.createCatetory)(form).then(function (res) {
              successCallback(res);
            }).catch(function () {
              _this3.typeFormConfirmLoading = false;
            });
          }
        }
      });
    },
    showGoodsDialog: function showGoodsDialog(goodsCount, categoryId) {
      var _this4 = this;
      this.sortGoodsCategoryId = categoryId;
      if (goodsCount > 0) {
        (0, _point_shop.fetchCatetoryGoods)(categoryId).then(function (response) {
          _this4.sortGoods = response.data;
          _this4.sortGoodsLength = _this4.sortGoods.length;
          _this4.dialogSortVisible = true;
        });
      }
    },
    sortGoodsHandler: function sortGoodsHandler(dataTransfer) {
      console.log('sortGoodsHandler');
    },
    moveHandler: function moveHandler(idx, action) {
      if (idx == 0 && action == -1) {
        return;
      }
      if (idx == this.sortGoodsLength - 1 && action == 1) {
        return;
      }
      var toIdx = idx + action;
      var goods = this.sortGoods[idx];
      var toGoods = this.sortGoods[toIdx];
      this.sortGoods.splice(toIdx, 1, goods);
      this.sortGoods.splice(idx, 1, toGoods);
    },
    sortDialogCloseHanlder: function sortDialogCloseHanlder() {
      this.sortGoodsCategoryId = '';
      this.sortGoods = [];
      this.sortGoodsLength = 0;
    },
    sortSubmitHandler: function sortSubmitHandler() {
      var _this5 = this;
      var sortGoodsIds = [];
      this.sortGoods.forEach(function (item) {
        sortGoodsIds.push(item.id);
      });
      this.sortFormConfirmLoading = true;
      (0, _point_shop.updateCatetoryGoodsSort)(this.sortGoodsCategoryId, sortGoodsIds).then(function (res) {
        _this5.getList();
        _this5.$message.success(res.msg);
        _this5.dialogSortVisible = false;
        _this5.sortFormConfirmLoading = false;
      }).catch(function () {
        _this5.sortFormConfirmLoading = false;
      });
    }
  }
};
exports.default = _default;