var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "form-dialog",
      attrs: {
        visible: _vm.dialogVisible,
        title: "补充账单",
        "close-on-click-modal": false,
        top: "10vh",
        width: "580px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.closeHanlder,
      },
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.formLoading,
              expression: "formLoading",
            },
          ],
          ref: "extraForm",
          attrs: {
            model: _vm.extraForm,
            rules: _vm.extraFormRules,
            "label-suffix": ":",
            "label-width": "105px",
            "label-position": "right",
          },
        },
        [
          _c("el-form-item", { attrs: { label: "对账周期" } }, [
            _c("span", [
              _vm._v(
                _vm._s(_vm._f("dateFormat")(_vm.bill.start_date)) +
                  " 至 " +
                  _vm._s(_vm._f("dateFormat")(_vm.bill.end_date))
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "商户名称" } }, [
            _c("span", [_vm._v(_vm._s(_vm.bill.nb_name))]),
          ]),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "抖音卡券", prop: "o2o_douyin.extra_amount" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入金额" },
                model: {
                  value: _vm.extraForm.o2o_douyin.extra_amount,
                  callback: function ($$v) {
                    _vm.$set(_vm.extraForm.o2o_douyin, "extra_amount", $$v)
                  },
                  expression: "extraForm.o2o_douyin.extra_amount",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "美团卡券", prop: "o2o_meituan.extra_amount" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入金额" },
                model: {
                  value: _vm.extraForm.o2o_meituan.extra_amount,
                  callback: function ($$v) {
                    _vm.$set(_vm.extraForm.o2o_meituan, "extra_amount", $$v)
                  },
                  expression: "extraForm.o2o_meituan.extra_amount",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "水吧收入" } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "water_bar.extra_amount" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入金额" },
                            model: {
                              value: _vm.extraForm.water_bar.extra_amount,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.extraForm.water_bar,
                                  "extra_amount",
                                  $$v
                                )
                              },
                              expression: "extraForm.water_bar.extra_amount",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 1 } }, [_vm._v("计")]),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "water_bar.extra_desc" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "销售次数" },
                            model: {
                              value: _vm.extraForm.water_bar.extra_desc,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.extraForm.water_bar,
                                  "extra_desc",
                                  $$v
                                )
                              },
                              expression: "extraForm.water_bar.extra_desc",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 1 } }, [_vm._v("杯")]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "洗浴间收入" } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "shower.extra_amount" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入金额" },
                            model: {
                              value: _vm.extraForm.shower.extra_amount,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.extraForm.shower,
                                  "extra_amount",
                                  $$v
                                )
                              },
                              expression: "extraForm.shower.extra_amount",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 1 } }, [_vm._v("计")]),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "shower.extra_desc" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "销售次数" },
                            model: {
                              value: _vm.extraForm.shower.extra_desc,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.extraForm.shower,
                                  "extra_desc",
                                  $$v
                                )
                              },
                              expression: "extraForm.shower.extra_desc",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 1 } }, [_vm._v("次")]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "经营费用调整", prop: "adjust.extra_amount" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入调整金额" },
                model: {
                  value: _vm.extraForm.adjust.extra_amount,
                  callback: function ($$v) {
                    _vm.$set(_vm.extraForm.adjust, "extra_amount", $$v)
                  },
                  expression: "extraForm.adjust.extra_amount",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "费用调整原因", prop: "adjust.extra_desc" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  autosize: { minRows: 2, maxRows: 5 },
                  placeholder: "请输入调整原因",
                },
                model: {
                  value: _vm.extraForm.adjust.extra_desc,
                  callback: function ($$v) {
                    _vm.$set(_vm.extraForm.adjust, "extra_desc", $$v)
                  },
                  expression: "extraForm.adjust.extra_desc",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: { label: "收入调整", prop: "income_adjust.extra_amount" },
            },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入调整金额" },
                model: {
                  value: _vm.extraForm.income_adjust.extra_amount,
                  callback: function ($$v) {
                    _vm.$set(_vm.extraForm.income_adjust, "extra_amount", $$v)
                  },
                  expression: "extraForm.income_adjust.extra_amount",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "收入调整原因",
                prop: "income_adjust.extra_desc",
              },
            },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  autosize: { minRows: 2, maxRows: 5 },
                  placeholder: "请输入调整原因",
                },
                model: {
                  value: _vm.extraForm.income_adjust.extra_desc,
                  callback: function ($$v) {
                    _vm.$set(_vm.extraForm.income_adjust, "extra_desc", $$v)
                  },
                  expression: "extraForm.income_adjust.extra_desc",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitLoading },
              on: { click: _vm.submitHandler },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }