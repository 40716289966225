var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "image-preview-dialog",
      attrs: { visible: _vm.dialogVisible, title: _vm.title, width: "620px" },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.closeHanlder,
      },
    },
    [
      _c(
        "div",
        { staticClass: "image-container" },
        [
          _c(
            "el-carousel",
            { staticClass: "carousel-container", attrs: { autoplay: false } },
            _vm._l(_vm.previewImages, function (image, k) {
              return _c("el-carousel-item", { key: k }, [
                _c("div", { staticClass: "image-block" }, [
                  _c("img", { attrs: { src: image } }),
                ]),
              ])
            }),
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }