var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container point-goods-container" },
    [
      _c("div", { staticClass: "filter-container filter-container-flex" }, [
        _c(
          "div",
          [
            _c("el-input", {
              staticClass: "filter-item",
              staticStyle: { width: "200px" },
              attrs: { placeholder: "订单编号" },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.handleFilter($event)
                },
              },
              model: {
                value: _vm.listQuery.order_no,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "order_no", $$v)
                },
                expression: "listQuery.order_no",
              },
            }),
            _vm._v(" "),
            _c(
              "el-select",
              {
                staticClass: "filter-item",
                attrs: {
                  "default-first-option": "",
                  placeholder: "提货酒店",
                  filterable: "",
                },
                model: {
                  value: _vm.listQuery.pickup_hotel_id,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery, "pickup_hotel_id", $$v)
                  },
                  expression: "listQuery.pickup_hotel_id",
                },
              },
              [
                _c("el-option", { attrs: { value: "", label: "全部" } }),
                _vm._v(" "),
                _vm._l(_vm.hotels, function (item) {
                  return _c("el-option", {
                    key: item.hotel_id,
                    attrs: { label: item.name, value: item.hotel_id },
                  })
                }),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "el-select",
              {
                staticClass: "filter-item",
                attrs: {
                  "default-first-option": "",
                  placeholder: "状态",
                  clearable: "",
                },
                model: {
                  value: _vm.listQuery.status,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery, "status", $$v)
                  },
                  expression: "listQuery.status",
                },
              },
              _vm._l(_vm.statusOptions, function (item) {
                return _c("el-option", {
                  key: item.value,
                  attrs: { label: item.label, value: item.value },
                })
              }),
              1
            ),
            _vm._v(" "),
            _c("el-date-picker", {
              staticClass: "filter-item",
              attrs: {
                type: "daterange",
                "start-placeholder": "下单开始日期",
                "end-placeholder": "结束日期",
                "value-format": "yyyy-MM-dd",
                align: "right",
              },
              model: {
                value: _vm.listQuery.created_at,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "created_at", $$v)
                },
                expression: "listQuery.created_at",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c(
              "el-button",
              {
                staticClass: "filter-item",
                attrs: { type: "primary" },
                on: { click: _vm.handleFilter },
              },
              [_vm._v("\n        搜索\n      ")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              { staticClass: "filter-item", on: { click: _vm.handleReset } },
              [_vm._v("\n        重置\n      ")]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          key: "tableList",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            "element-loading-text": "给我一点时间",
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "订单编号",
              "class-name": "overflow-on",
              width: "180",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " + _vm._s(scope.row.order_no) + "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "商品名称",
              "class-name": "overflow-on",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " + _vm._s(scope.row.goods_title) + "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "数量",
              "class-name": "overflow-on",
              width: "90",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " + _vm._s(scope.row.quantity) + "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "积分值",
              "class-name": "overflow-on",
              width: "90",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v("\n        " + _vm._s(scope.row.point) + "\n      "),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "提货酒店",
              "class-name": "overflow-on",
              width: "210",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          scope.row.pickup_hotel_id
                            ? scope.row.pickup_hotel_name
                            : "/"
                        ) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "用户信息",
              "class-name": "overflow-on",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.user_nickname) +
                        "/" +
                        _vm._s(scope.row.user_mobile) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "状态",
              "class-name": "overflow-on",
              width: "90",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.statusName[scope.row.status])),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "下单时间",
              "class-name": "overflow-on",
              width: "160",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " + _vm._s(scope.row.created_at) + "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              fixed: "right",
              label: "操作",
              width: "140",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.status == 0
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.confirmOrderHandler(scope.row.id)
                              },
                            },
                          },
                          [_vm._v("\n          确认提货\n        ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status == 0
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.cancelOrderHandler(scope.row.id)
                              },
                            },
                          },
                          [_vm._v("\n          取消\n        ")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "pagination-container text-right" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              total: _vm.pagination.total,
              "current-page": _vm.listQuery.page,
              "page-sizes": [10, 20, 50],
              "page-size": _vm.listQuery.limit,
              layout: "total, sizes, prev, pager, next, jumper",
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }