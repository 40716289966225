"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.number.constructor");
var _coupon = require("@/api/coupon");
var _auth = require("@/utils/auth");
var _helper = require("@/utils/helper");
var _hotel = require("@/api/hotel");
var _moment = _interopRequireDefault(require("moment"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "Coupon",
  props: {
    platform: Number
  },
  data: function data() {
    var _this = this;
    var checkCategory = function checkCategory(rule, value, callback) {
      if ((value == 2 || value == 4) && _this.temp.hotel_id.length == 0) {
        callback(new Error('请输入选择酒店'));
      } else if (value == 3 && _this.temp.area == '') {
        callback(new Error('请输入区域'));
      } else {
        callback();
      }
    };
    return {
      couponArr: [],
      areas: [],
      btn: false,
      list: null,
      imageUrl: "",
      typeList: [],
      pagination: "",
      listQuery: {
        page: 1,
        limit: 20,
        status: 0,
        id: '',
        name: '',
        date: ""
      },
      sendType: [{
        "label": 1,
        "val": "手动"
      }, {
        "label": 0,
        "val": "自动"
      }],
      multiType: [{
        "label": 0,
        "val": "不可叠加"
      }, {
        "label": 1,
        "val": "可叠加"
      }],
      categoryList: [{
        "label": 1,
        "val": "所有商家"
      }, {
        "label": 3,
        "val": "指定区域"
      }, {
        "label": 2,
        "val": "指定商家"
      }, {
        "label": 4,
        "val": "不可用商家"
      }],
      expireTypes: [{
        "label": 1,
        "val": "指定日期"
      }, {
        "label": 2,
        "val": "领取后生效"
      }],
      weekList: [{
        "label": 1,
        "val": "全部可用"
      }, {
        "label": 2,
        "val": "周中可用"
      }, {
        "label": 3,
        "val": "周末可用"
      }],
      seatList: [],
      statusOptions: [{
        value: 0,
        label: '全部'
      }, {
        value: 1,
        label: '有效'
      }, {
        value: 2,
        label: '不可用'
      }],
      dialogFormVisible: false,
      temp: {
        name: '',
        description: '',
        type: '',
        expire_days: 0,
        expire_type: 1,
        category: 1,
        amount: 0,
        num: 0,
        start_time: 0,
        over_time: 0,
        need_amount: 0,
        is_multi: 0,
        status: '1',
        hotel_id: [],
        is_manaul: 1,
        is_week: 1,
        area: '',
        seat: [1, 2, 4, 8, 16]
      },
      dialogTitle: '添加优惠券',
      header: {
        Authorization: "Bearer " + (0, _auth.getToken)()
      },
      couponFormRules: {
        name: [{
          required: true,
          message: '请输入名称',
          trigger: 'blur'
        }, {
          max: 30,
          message: '长度在30 个字符以内',
          trigger: 'blur'
        }],
        description: [{
          required: true,
          message: '请输入说明',
          trigger: 'blur'
        }],
        type: [{
          required: true,
          message: '请选择类别',
          trigger: 'change'
        }],
        num: [{
          required: true,
          message: '请输入数量',
          trigger: 'blur'
        }],
        category: [{
          validator: checkCategory,
          trigger: 'blur'
        }],
        start_time: [{
          type: 'date',
          required: true,
          message: '请选择开始时间',
          trigger: 'change'
        }],
        over_time: [{
          type: 'date',
          required: true,
          message: '请选择结束时间',
          trigger: 'change'
        }],
        expire_days: [{
          type: 'number',
          required: true,
          message: '请输入有效天数',
          trigger: 'blur'
        }]
      },
      hotelList: []
    };
  },
  created: function created() {
    var _this2 = this;
    this.temp.platform = this.platform;
    this.getList();
    (0, _coupon.couponAll)().then(function (response) {
      _this2.couponArr = response.data;
    });
    (0, _hotel.getHotelSimpleList)().then(function (response) {
      _this2.hotelList = response.data;
    });
    (0, _hotel.getArea)().then(function (response) {
      _this2.areas = response.data;
    });
  },
  methods: {
    getLocalTime: function getLocalTime(nS) {
      return (0, _moment.default)(nS * 1000).format("YYYY-MM-DD HH:mm:ss");
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    generateCode: function generateCode(id) {
      var that = this;
      that.listLoading = true;
      (0, _coupon.generateCode)(id).then(function (res) {
        that.listLoading = false;
        if (res.code == 0) {
          that.$confirm('优惠券码已生成, 是否下载?', '提示', {
            confirmButtonText: '立即下载',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(function () {
            (0, _helper.download)('/admin/downloadCode/' + id, {
              "api_token": (0, _auth.getToken)()
            });
          }).catch(function () {});
        } else {
          that.$message.error(res.msg);
        }
      });
    },
    resetForm: function resetForm() {
      this.listQuery.id = '';
      this.listQuery.name = '';
      this.listQuery.status = '';
      this.listQuery.date = '';
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    getList: function getList() {
      var _this3 = this;
      this.listLoading = true;
      (0, _coupon.CouponList)(this.platform, this.listQuery).then(function (res) {
        var data = res.data.couponList;
        _this3.list = data.data;
        _this3.pagination = data;
        _this3.listQuery.page = data.current_page;
        _this3.typeList = res.data.typeList;
        _this3.seatList = res.data.seatList;
      });
      this.listLoading = false;
    },
    showCreateCoupon: function showCreateCoupon() {
      this.temp = {
        name: '',
        description: '',
        type: '',
        expire_type: 1,
        expire_days: 0,
        category: 1,
        amount: 0,
        num: 0,
        start_time: new Date().getTime(),
        over_time: new Date().getTime(),
        need_amount: 0,
        status: '1',
        hotel_id: [],
        is_manaul: 1,
        receive_time: '',
        area: '',
        is_week: 1,
        is_multi: 0,
        platform: this.platform,
        seat: [1, 2, 4, 8, 16]
      };
      this.dialogTitle = '添加优惠券', this.dialogFormVisible = true;
    },
    onCategoryChange: function onCategoryChange(val) {
      var _this4 = this;
      if (val == 2 || val == 4) {
        this.$refs['categoryHotel'].focus();
      } else if (val == 3) {
        this.$refs['categoryArea'].focus();
      }
      this.$nextTick(function () {
        _this4.temp.hotel_id = [];
        _this4.temp.area = '';
        _this4.$refs.couponForm.clearValidate('category');
      });
    },
    onExpireTypeChange: function onExpireTypeChange() {
      var _this5 = this;
      this.$nextTick(function () {
        _this5.$refs.couponForm.clearValidate('start_time');
        _this5.$refs.couponForm.clearValidate('expire_days');
      });
    },
    createCoupon: function createCoupon() {
      var _this6 = this;
      this.$refs.couponForm.validate(function (valid) {
        if (valid) {
          // this.btn = true
          var startTime = parseInt((0, _moment.default)(_this6.temp.start_time).valueOf() / 1000);
          var overTime = parseInt((0, _moment.default)(_this6.temp.over_time).valueOf() / 1000);
          var data = JSON.parse(JSON.stringify(_this6.temp));
          data.rule = JSON.stringify(data.rule);
          data.start_time = startTime;
          data.over_time = overTime;
          if (_this6.dialogTitle == '添加优惠券') {
            (0, _coupon.addCoupon)(data).then(function (res) {
              _this6.btn = false;
              if (res.code == 0) {
                _this6.$message.success("添加成功！");
                _this6.getList();
                _this6.dialogFormVisible = !_this6.dialogFormVisible;
              } else {
                _this6.$message.error(res.msg);
              }
            });
          } else {
            (0, _coupon.updateCoupon)(data).then(function (res) {
              _this6.btn = false;
              if (res.code == 0) {
                _this6.$message.success("编辑成功！");
                _this6.getList();
                _this6.dialogFormVisible = !_this6.dialogFormVisible;
              } else {
                _this6.$message.error(res.msg);
              }
            });
          }
        } else {
          // this.$notify.error({
          //   title: '错误',
          //   message: '请检查输入是否正确',
          //   offset: 100
          // });
          return false;
        }
      });
    },
    deleteCoupon: function deleteCoupon(id) {
      var _this7 = this;
      (0, _coupon.deleteCoupon)({
        id: id
      }).then(function (res) {
        if (res.code == 0) {
          _this7.$message.success("禁用成功！");
          _this7.getList();
        } else {
          _this7.$message.error(res.data.msg);
        }
      });
    },
    recoverCoupon: function recoverCoupon(id) {
      var _this8 = this;
      (0, _coupon.recoverCoupon)({
        id: id
      }).then(function (res) {
        if (res.code == 0) {
          _this8.$message.success("已恢复");
          _this8.getList();
        } else {
          _this8.$message.error(res.data.msg);
        }
      });
    },
    updateAction: function updateAction(row) {
      this.temp = JSON.parse(JSON.stringify(row));
      var hotelIds = [];
      if (this.temp.hotel_id) {
        hotelIds = row.hotel_id.split('|');
      } else if (this.temp.un_hotel_id) {
        hotelIds = row.un_hotel_id.split('|');
      }
      hotelIds = hotelIds.map(function (item) {
        return parseInt(item);
      });
      this.temp.hotel_id = hotelIds;
      this.temp.status = this.temp.status == 1 ? '1' : '2';
      this.temp.start_time *= 1000;
      this.temp.over_time *= 1000;
      this.dialogTitle = '编辑优惠券';
      this.dialogFormVisible = !this.dialogFormVisible;
    }
  }
};
exports.default = _default;