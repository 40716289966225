"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _orderNormal = _interopRequireDefault(require("./orderNormal.vue"));
var _meituanAdjust = _interopRequireDefault(require("./meituanAdjust.vue"));
var _comparisonAbnormal = _interopRequireDefault(require("./comparisonAbnormal.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "FinanceOrder",
  components: {
    OrderNormal: _orderNormal.default,
    MeituanAdjust: _meituanAdjust.default,
    ComparisonAbnormal: _comparisonAbnormal.default
  },
  watch: {
    '$route.query.tab_name': {
      handler: function handler(tab_name) {
        this.tabName = tab_name;
      }
    }
  },
  data: function data() {
    return {
      tabName: "normal"
    };
  },
  created: function created() {
    if (this.$route.query.tab_name) {
      this.tabName = this.$route.query.tab_name;
    }
  },
  methods: {
    typeHandler: function typeHandler() {
      this.$router.push("/bill/finance_order?tab_name=".concat(this.tabName));
    }
  }
};
exports.default = _default;