var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container room-stat" },
    [
      _c("div", { staticClass: "filter-container filter-container-flex" }, [
        _c(
          "div",
          [
            _c("el-date-picker", {
              staticClass: "filter-item",
              staticStyle: { width: "180px" },
              attrs: {
                "value-format": "yyyy-MM-dd",
                placeholder: "选择日期",
                clearable: false,
              },
              on: { change: _vm.getList },
              model: {
                value: _vm.listQuery.day,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "day", $$v)
                },
                expression: "listQuery.day",
              },
            }),
            _vm._v(" "),
            _c(
              "el-select",
              {
                staticClass: "filter-item",
                staticStyle: { width: "280px" },
                attrs: {
                  filterable: "",
                  "default-first-option": "",
                  placeholder: "请选择酒店",
                },
                on: { change: _vm.getList },
                model: {
                  value: _vm.listQuery.hotel_id,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery, "hotel_id", $$v)
                  },
                  expression: "listQuery.hotel_id",
                },
              },
              _vm._l(_vm.hotelList, function (item) {
                return _c("el-option", {
                  key: item.id,
                  attrs: { label: item.name, value: item.id },
                })
              }),
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c(
              "el-button",
              {
                staticClass: "filter-item",
                attrs: { type: "primary" },
                on: { click: _vm.getList },
              },
              [_vm._v("\n        刷新数据\n      ")]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            "element-loading-text": "给我一点时间",
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        _vm._l(_vm.columns, function (column) {
          return _c("el-table-column", {
            key: column.keyword,
            attrs: {
              align: "center",
              label: column.label,
              width: column.width,
              fixed: column.fixed || false,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      column.keyword == "name"
                        ? [_c("span", [_vm._v(_vm._s(scope.row.name))])]
                        : column.keyword == "sale_status"
                        ? [
                            _c(
                              "el-popconfirm",
                              {
                                attrs: {
                                  title:
                                    "是否" +
                                    (scope.row.close ? "开启" : "关闭") +
                                    "该房型？",
                                },
                                on: {
                                  confirm: function ($event) {
                                    return _vm.closeHandler(scope.row.id)
                                  },
                                },
                              },
                              [
                                _c("div", {
                                  staticClass: "room-status-column",
                                  attrs: { slot: "reference" },
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.paserStatus(scope.row)
                                    ),
                                  },
                                  slot: "reference",
                                }),
                              ]
                            ),
                          ]
                        : [
                            _c("div", {
                              staticClass: "room-stock-column",
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.paserStock(
                                    scope.row.stocks[column.keyword]
                                  )
                                ),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.stockChangeHandler(
                                    scope.row,
                                    column.keyword
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "room-stat-button" },
                              [
                                _c(
                                  "el-popconfirm",
                                  {
                                    attrs: {
                                      title:
                                        "是否" +
                                        (scope.row.stocks[column.keyword].close
                                          ? "开启"
                                          : "关闭") +
                                        "该房型？",
                                    },
                                    on: {
                                      confirm: function ($event) {
                                        return _vm.setRoomStatHandler(
                                          scope.row.id,
                                          scope.row.stocks[column.keyword]
                                            .close,
                                          column.keyword
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "template",
                                      { slot: "reference" },
                                      [
                                        scope.row.stocks[column.keyword].close
                                          ? _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  size: "mini",
                                                  type: "danger",
                                                },
                                              },
                                              [_vm._v("已关房")]
                                            )
                                          : _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  size: "mini",
                                                  type: "primary",
                                                },
                                              },
                                              [_vm._v("有房")]
                                            ),
                                      ],
                                      1
                                    ),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                          ],
                    ]
                  },
                },
              ],
              null,
              true
            ),
          })
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogStockFormVisible,
            "close-on-click-modal": false,
            title: "编辑库存",
            width: "520px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogStockFormVisible = $event
            },
            close: _vm.stockFormCloseHanlder,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "stockForm",
              attrs: {
                model: _vm.stockForm,
                rules: _vm.stockFormRules,
                "label-suffix": ":",
                "label-width": "105px",
                "label-position": "right",
              },
            },
            [
              _c("el-form-item", { attrs: { label: "日期" } }, [
                _c("span", { staticStyle: { "font-size": "16px" } }, [
                  _vm._v(_vm._s(_vm.stockForm.day)),
                ]),
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "当日库存" } }, [
                _vm.chooseRoomDayStock == -1
                  ? _c("span", [_vm._v("未设置")])
                  : _c(
                      "span",
                      {
                        staticStyle: {
                          color: "#409EFF",
                          "font-size": "16px",
                          "font-weight": "bold",
                        },
                      },
                      [_vm._v(_vm._s(_vm.chooseRoomDayStock))]
                    ),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "变更后库存", prop: "stock" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "180px" },
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.stockForm.stock,
                      callback: function ($$v) {
                        _vm.$set(_vm.stockForm, "stock", $$v)
                      },
                      expression: "stockForm.stock",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticStyle: { "text-align": "center" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogStockFormVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    loading: _vm.stockFormConfirmLoading,
                  },
                  on: { click: _vm.stockFormSubmitHandler },
                },
                [_vm._v("\n        确 定\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }