"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _vuex = require("vuex");
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'DashboardEditor',
  data: function data() {
    return {
      welcome_img: 'http://zlfz-hotel.oss-cn-hangzhou.aliyuncs.com/front/welcome.png'
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['name', 'avatar', 'roles']))
};
exports.default = _default;