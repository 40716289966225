"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _manager = require("@/api/manager");
var _hotel = require("@/api/hotel");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Admin',
  filters: {
    statusFilter: function statusFilter(status) {
      var statusMap = {
        published: 'success',
        draft: 'gray',
        deleted: 'danger'
      };
      return statusMap[status];
    }
  },
  data: function data() {
    return {
      pagination: "",
      adminList: null,
      dialogFormVisible: false,
      accountForm: {
        username: "",
        hotel_id: []
      },
      accountFormRules: {
        username: [{
          required: true,
          message: '请输入账号',
          trigger: 'blur'
        }],
        hotel_id: [{
          required: true,
          message: '请选择酒店',
          trigger: 'blur'
        }],
        password: [{
          required: true,
          message: '请输入密码',
          trigger: 'blur'
        }]
      },
      dialogStatus: null,
      hotelList: [],
      listQuery: {
        hotel_id: "",
        page: 1,
        limit: 20
      }
    };
  },
  created: function created() {
    var _this = this;
    this.getAdminList();
    (0, _hotel.getHotelSimpleList)().then(function (response) {
      _this.hotelList = response.data;
    });
  },
  methods: {
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getAdminList();
    },
    getAdminList: function getAdminList() {
      var _this2 = this;
      this.listLoading = true;
      (0, _manager.fetchHotelList)(this.listQuery).then(function (res) {
        _this2.adminList = res.data.data;
        _this2.pagination = res.data;
        _this2.listLoading = false;
      });
    },
    handleEdit: function handleEdit(row, status) {
      var _this3 = this;
      (0, _manager.fetchAdmin)(row.id).then(function (res) {
        _this3.accountForm = {
          id: res.data.id,
          role: res.data.role,
          username: res.data.username,
          hotel_id: res.data.hotel_id,
          password: ""
        };
      });
      row.password = '';
      this.dialogStatus = status;
      this.dialogFormVisible = true;
    },
    handleCreate: function handleCreate(status) {
      this.accountForm = {
        role: 'hotel',
        username: "",
        hotel_id: []
      };
      this.dialogStatus = status;
      this.dialogFormVisible = true;
    },
    accountFormCloseHanlder: function accountFormCloseHanlder() {
      this.$refs['accountForm'].resetFields();
      this.accountForm.id = '';
    },
    create: function create() {
      var _this4 = this;
      this.$refs['accountForm'].validate(function (valid) {
        if (!valid) {
          return false;
        }
        (0, _manager.addAdmin)(_this4.accountForm).then(function (response) {
          if (response.code == 0) {
            _this4.$message({
              message: response.msg,
              type: 'success'
            });
            _this4.dialogFormVisible = false;
            _this4.getAdminList();
          } else {
            _this4.$message({
              message: response.msg,
              type: 'error'
            });
          }
        });
      });
    },
    update: function update() {
      var _this5 = this;
      this.$refs['accountForm'].validate(function (valid) {
        if (!valid) {
          return false;
        }
        (0, _manager.updateAdmin)(_this5.accountForm.id, _this5.accountForm).then(function (response) {
          if (response.code == 0) {
            _this5.$message({
              message: response.msg,
              type: 'success'
            });
            _this5.dialogFormVisible = false;
            _this5.getAdminList();
          } else {
            _this5.$message({
              message: response.msg,
              type: 'error'
            });
          }
        });
      });
    },
    deleteAdmin: function deleteAdmin(id) {
      var _this6 = this;
      (0, _manager.deleteAdmin)({
        id: id
      }).then(function (response) {
        if (response.code == 0) {
          _this6.$message({
            message: response.msg,
            type: 'success'
          });
          _this6.getAdminList();
        } else {
          _this6.$message({
            message: response.msg,
            type: 'error'
          });
        }
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getAdminList();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getAdminList();
    },
    parseToString: function parseToString(data, key) {
      var vals = [];
      data.forEach(function (item) {
        vals.push(item[key]);
      });
      return vals.join(",");
    }
  }
};
exports.default = _default;