"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.to-string");
var _hotel = require("@/api/hotel");
var _room = require("@/api/room");
var _activity = require("@/api/activity");
var _auth = require("@/utils/auth");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "ActivityNew",
  data: function data() {
    return {
      areas: [],
      list: [],
      btn: false,
      dialogFormVisible: false,
      listQuery: {
        page: 1,
        limit: 20,
        area_id: "",
        hotel_id: ""
      },
      temp: {
        hotel_id: '',
        detail: [{
          room_id: '',
          start_date: '',
          end_date: '',
          price: 0,
          today_price: 0
        }]
      },
      pagination: "",
      dialogTitle: "添加",
      disabledText: false,
      hotelList: [],
      roomList: []
    };
  },
  created: function created() {
    var _this = this;
    this.getList();
    // var roles = getRoles();
    if ((0, _auth.getGrade)() < 1) {
      (0, _hotel.getArea)().then(function (response) {
        _this.areas = response.data;
      });
    }
    (0, _hotel.getHotelSimpleList)().then(function (response) {
      _this.hotelList = response.data;
    });
  },
  methods: {
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleDetailRedirect: function handleDetailRedirect(id) {
      this.$router.push({
        path: "/hotel/detail/" + id
      });
    },
    handleRoomRedirect: function handleRoomRedirect(id) {
      this.$router.push({
        path: "/hotel/room/" + id
      });
    },
    handleFormRedirect: function handleFormRedirect(id, action) {
      if (action == "create") {
        this.$router.push({
          path: "/hotel/add/"
        });
      } else {
        this.$router.push({
          path: "/hotel/edit/" + id
        });
      }
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    getList: function getList() {
      var _this2 = this;
      this.listLoading = true;
      (0, _activity.getNewDiscountList)(this.listQuery).then(function (response) {
        var data = response.data;
        _this2.list = data.data;
        _this2.pagination = data;
        _this2.listQuery.page = data.current_page;
        _this2.listLoading = false;
      });
    },
    addBtn: function addBtn() {
      this.roomList = [];
      this.dialogTitle = '添加', this.dialogFormVisible = true;
    },
    removeDomain: function removeDomain(item) {
      var index = this.temp.detail.indexOf(item);
      if (index !== -1) {
        this.temp.detail.splice(index, 1);
      }
    },
    addTime: function addTime(index) {
      this.roomList[index].list.push({
        start_date: '',
        end_date: '',
        weekday_price: 0,
        weekend_price: 0
      });
    },
    addNewDiscount: function addNewDiscount() {
      var _this3 = this;
      if (this.dialogTitle == '编辑') {
        (0, _activity.editNewDiscount)(this.temp.id, this.roomList).then(function (res) {
          if (res.code == 0) {
            _this3.getList();
            _this3.dialogFormVisible = false;
            _this3.$message.success(res.msg);
          } else {
            _this3.$message.error(res.msg);
          }
        });
        return false;
      }
      (0, _activity.addNewDiscount)(this.roomList).then(function (res) {
        if (res.code == 0) {
          _this3.getList();
          _this3.dialogFormVisible = false;
          _this3.$message.success(res.msg);
        } else {
          _this3.$message.error(res.msg);
        }
      });
    },
    downShelf: function downShelf(row) {
      var _this4 = this;
      this.$confirm('确认下架操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _activity.downNewDiscount)(row.id).then(function (res) {
          if (res.code == 0) {
            row.status = 2;
            _this4.$message.success(res.msg);
          } else {
            _this4.$message.error(res.msg);
          }
        });
      }).catch(function () {
        // console.log(this.userId, { coupon_id: couponId })
        return;
      });
    },
    changeHotel: function changeHotel() {
      var _this5 = this;
      (0, _room.simpleList)(this.temp.hotel_id).then(function (res) {
        res.data.map(function (item) {
          item.list = [{
            start_date: '',
            end_date: '',
            weekday_price: 0,
            weekend_price: 0
          }];
        });
        _this5.roomList = res.data;
      });
    },
    editBtn: function editBtn(row) {
      var _this6 = this;
      this.dialogTitle = '编辑', this.dialogFormVisible = true;
      this.temp = row;
      row.roomList.map(function (item, index) {
        item.list.map(function (time) {
          time.start_date = time.start_date.toString();
          time.end_date = time.end_date.toString();

          // console.warn(time.start_date.toString(), time.end_date.toString());
        });
      });

      (0, _room.simpleList)(this.temp.hotel_id).then(function (res) {
        res.data.map(function (item, index) {
          // item.list = amaze[item.room_id]
          item.list = [{
            start_date: '',
            end_date: '',
            weekday_price: 0,
            weekend_price: 0
          }];
          row.roomList.some(function (room, index) {
            if (room.room_id == item.room_id) {
              item.list = room.list;
            }
          });
        });
        _this6.roomList = res.data;
        console.log(_this6.roomList);
      });
    }
  }
};
exports.default = _default;