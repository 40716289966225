var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "app-container calendar-list-container finance-bill-cost-page",
    },
    [
      _c("div", { staticClass: "back-line" }, [
        _c("i", { staticClass: "el-icon-back", on: { click: _vm.goBack } }),
        _vm._v(" "),
        _c("span", [_vm._v(_vm._s(_vm.bill.nb_name || "--"))]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "filter-container filter-container-flex" }, [
        _c(
          "div",
          [
            _c("el-date-picker", {
              staticClass: "filter-item el-range-separator-w",
              attrs: {
                type: "daterange",
                "range-separator": "至",
                "start-placeholder": "账单开始日期",
                "end-placeholder": "账单结束日期",
                "value-format": "yyyy-MM-dd",
                align: "right",
                disabled: "",
              },
              model: {
                value: _vm.billDateRange,
                callback: function ($$v) {
                  _vm.billDateRange = $$v
                },
                expression: "billDateRange",
              },
            }),
            _vm._v(" "),
            _c("FitterSelectMult", {
              ref: "FeeTypeSelection",
              staticClass: "filter-item fee-type-selection",
              staticStyle: { width: "170px" },
              attrs: {
                title: "费用类型",
                options: _vm.feeTypes,
                col: 3,
                height: 220,
                "default-value": { checkAll: true },
              },
              on: { confirm: _vm.onFeeTypeChange },
            }),
            _vm._v(" "),
            _c("el-input", {
              staticClass: "filter-item",
              staticStyle: { width: "170px" },
              attrs: { placeholder: "流程编号" },
              model: {
                value: _vm.listQuery.voucher_desc,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "voucher_desc", $$v)
                },
                expression: "listQuery.voucher_desc",
              },
            }),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "filter-item",
                attrs: { type: "primary" },
                on: { click: _vm.handleFilter },
              },
              [_vm._v("\n        搜索\n      ")]
            ),
            _vm._v(" "),
            _vm.enableBusinessEntry()
              ? _c(
                  "el-button",
                  {
                    staticClass: "filter-item",
                    attrs: { type: "primary" },
                    on: { click: _vm.addHandler },
                  },
                  [_vm._v("\n        费用录入\n      ")]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "el-button",
              { staticClass: "filter-item", on: { click: _vm.exportHandler } },
              [_vm._v("\n        导出表格\n      ")]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          key: "tableList",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            "element-loading-text": "给我一点时间",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "费用类型",
              "class-name": "overflow-on",
              prop: "fee_type_name",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "费用说明",
              "class-name": "overflow-on",
              prop: "fee_desc",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "费用金额",
              "class-name": "overflow-on",
              prop: "fee",
              width: "125",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "费用依据",
              "class-name": "overflow-on",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.voucher_type == 1
                      ? [
                          _c("span", [_vm._v("i8流程")]),
                          _c("div", [_vm._v(_vm._s(scope.row.voucher_desc))]),
                        ]
                      : scope.row.voucher_type == 2
                      ? [_vm._v("\n          凭证上传\n        ")]
                      : _c("span", [_vm._v("--")]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "录入人",
              "class-name": "overflow-on",
              prop: "create_uname",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "所属账期",
              "class-name": "overflow-on",
              prop: "nb_real_amount",
              width: "185",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.billDateRange[0] + " ~ " + _vm.billDateRange[1]
                        ) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "录入时间",
              "class-name": "overflow-on",
              width: "160",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.updated_at || scope.row.created_at) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              fixed: "right",
              label: "操作",
              width: "180",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.voucher_type == 2
                      ? _c(
                          "el-link",
                          {
                            staticClass: "link-right",
                            attrs: { type: "primary", underline: false },
                            on: {
                              click: function ($event) {
                                return _vm.showVoucherHandler(scope.row)
                              },
                            },
                          },
                          [_vm._v("\n          查看凭证\n        ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.enableBusinessEntry()
                      ? [
                          _c(
                            "el-link",
                            {
                              staticClass: "link-right",
                              attrs: { type: "primary", underline: false },
                              on: {
                                click: function ($event) {
                                  _vm.ed = _vm.editHandler(scope.row)
                                },
                              },
                            },
                            [_vm._v("\n            编辑\n          ")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-link",
                            {
                              staticClass: "link-right",
                              attrs: { type: "primary", underline: false },
                              on: {
                                click: function ($event) {
                                  return _vm.deleleHandler(scope.row)
                                },
                              },
                            },
                            [_vm._v("\n            删除\n          ")]
                          ),
                        ]
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "pagination-container pagination-stat" }, [
        _c("div", { staticClass: "stat" }, [
          _vm._v("费用合计：" + _vm._s(_vm.pagination.fee_total || 0) + " 元"),
        ]),
        _vm._v(" "),
        _c(
          "div",
          [
            _c("el-pagination", {
              attrs: {
                background: "",
                total: _vm.pagination.total,
                "current-page": _vm.listQuery.page,
                "page-sizes": [10, 20, 50],
                "page-size": _vm.listQuery.limit,
                layout: "sizes, prev, pager, next, jumper",
              },
              on: {
                "size-change": _vm.handleSizeChange,
                "current-change": _vm.handleCurrentChange,
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("NetBarFinanceBillCostAddForm", {
        ref: "FinanceBillCostAddForm",
        attrs: { billId: _vm.billId, visible: _vm.costAddFormVisible },
        on: {
          "update:visible": function ($event) {
            _vm.costAddFormVisible = $event
          },
          success: _vm.getList,
        },
      }),
      _vm._v(" "),
      _c("NetBarFinanceBillCostEditForm", {
        ref: "FinanceBillCostEditForm",
        attrs: {
          id: _vm.costId,
          billId: _vm.billId,
          visible: _vm.costEditFormVisible,
        },
        on: {
          "update:id": function ($event) {
            _vm.costId = $event
          },
          "update:visible": function ($event) {
            _vm.costEditFormVisible = $event
          },
          success: _vm.getList,
        },
      }),
      _vm._v(" "),
      _c("ImagePreview", {
        ref: "ImagePreview",
        attrs: {
          title: "凭证预览",
          images: _vm.previewImages,
          visible: _vm.imagePreviewVisible,
        },
        on: {
          "update:visible": function ($event) {
            _vm.imagePreviewVisible = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }