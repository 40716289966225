"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.object.keys");
require("core-js/modules/web.dom.iterable");
var _mixin = _interopRequireDefault(require("@/utils/mixin"));
var _moment = _interopRequireDefault(require("moment"));
var _FeeOrderTable = _interopRequireDefault(require("./FeeOrderTable.vue"));
var _ThirdOrderImport = _interopRequireDefault(require("./ThirdOrderImport.vue"));
var _hotel = require("@/api/hotel");
var _order = require("@/api/order");
var _finance_reco = require("@/api/finance_reco");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "FinanceOffline",
  mixins: [_mixin.default],
  components: {
    FeeOrderTable: _FeeOrderTable.default,
    ThirdOrderImport: _ThirdOrderImport.default
  },
  data: function data() {
    return {
      thirdId: 4,
      modeList: [],
      hotelList: [],
      sellers: [],
      thirdChannel: [{
        id: 7,
        title: "线下渠道"
      }],
      thirdChannelOrderTypes: [],
      thirdOrderTypes: [],
      financeRange: [],
      allowAction: {
        allowImport: false,
        allowModify: false,
        allowDelete: false
      },
      // 列表及查询
      listQuery: {
        page: 1,
        limit: 20,
        bill_week: "",
        order_no: "",
        contacts: "",
        // third_channel: "",
        third_order_type: "",
        seller_type: "",
        start_date: "",
        end_date: "",
        import_date: "",
        hotel_mode: "",
        hotel_id: ""
      },
      pagination: {},
      listLoading: false,
      orders: [],
      summary: {},
      // 导入
      importVisible: false
    };
  },
  created: function created() {
    var _this = this;
    var prevWeekStart = this.getWeekRange((0, _moment.default)().subtract(7, 'days').format("YYYY-MM-DD"))[0];
    this.listQuery.bill_week = (0, _moment.default)(prevWeekStart).format("YYYYMMDD");
    (0, _hotel.getHotelSimpleList)().then(function (response) {
      _this.hotelList = response.data;
    });
    (0, _hotel.getModeList)().then(function (response) {
      _this.modeList = response.data;
    });
    (0, _order.getPermutationSellerList)().then(function (response) {
      _this.sellers = response.data;
    });
    (0, _finance_reco.getFinanceRange)().then(function (response) {
      var data = response.data;
      _this.financeRange = [data.date.start, data.date.end];
      _this.allowAction.allowImport = data.allow_import;
      _this.allowAction.allowModify = data.allow_modify;
      _this.allowAction.allowDelete = data.allow_delete;
    });
    (0, _finance_reco.getFinanceRecoThirdOrderTypes)({
      third_channel: "7"
    }).then(function (response) {
      _this.thirdOrderTypes = response.data;
      _this.thirdChannelOrderTypes = _this.getThirdChannelOrderTypes("");
    });
    this.getList();
  },
  methods: {
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    getList: function getList() {
      var _this2 = this;
      this.listLoading = true;
      (0, _finance_reco.getOfflineList)(this.listQuery).then(function (response) {
        var data = response.data;
        _this2.pagination = {
          total: data.total,
          currentPage: data.current_page,
          pageSize: data.per_page
        };
        _this2.orders = data.data;
        _this2.summary = data.summary;
        _this2.listQuery.page = data.current_page;
        _this2.listLoading = false;
      });
    },
    importHandler: function importHandler() {
      this.importVisible = true;
    },
    exportHandler: function exportHandler() {
      window.open((0, _finance_reco.getOfflineDownloadUrl)(this.listQuery));
    },
    onStartDateChange: function onStartDateChange() {
      this.listQuery.bill_week = "";
    },
    onEndDateChange: function onEndDateChange() {
      this.listQuery.bill_week = "";
    },
    onThirdChannelChange: function onThirdChannelChange(thirdChannel) {
      this.listQuery.third_order_type = "";
      this.thirdChannelOrderTypes = this.getThirdChannelOrderTypes(thirdChannel);
    },
    getThirdChannelOrderTypes: function getThirdChannelOrderTypes(thirdChannel) {
      var _this3 = this;
      if (thirdChannel == "") {
        var orderTypes = [];
        Object.keys(this.thirdOrderTypes).forEach(function (idx) {
          _this3.thirdOrderTypes[idx].forEach(function (type) {
            if (orderTypes.indexOf(type) < 0) {
              orderTypes.push(type);
            }
          });
        });
        return orderTypes;
      }
      return this.thirdOrderTypes[thirdChannel];
    }
  }
};
exports.default = _default;