var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "form-dialog",
          attrs: {
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
            title: "费用录入",
            width: "720px",
            top: "10vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            close: _vm.closeHanlder,
          },
        },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%", "margin-bottom": "30px" },
              attrs: {
                data: _vm.costForm.costs,
                stripe: "",
                border: "",
                size: "mini",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "费用类目",
                  "class-name": "overflow-on",
                  width: "120",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.getFeeTypeName(scope.row.fee_type)) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "费用金额",
                  "class-name": "overflow-on",
                  width: "120",
                  prop: "fee",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "费用说明",
                  "class-name": "overflow-on",
                  prop: "fee_desc",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "费用依据",
                  "class-name": "overflow-on",
                  width: "140",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.voucher_type == 1
                          ? _c(
                              "span",
                              [
                                _vm._v("i8: "),
                                _c(
                                  "el-link",
                                  {
                                    attrs: {
                                      type: "primary",
                                      underline: false,
                                    },
                                  },
                                  [_vm._v(_vm._s(scope.row.voucher_desc))]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.voucher_type == 2
                          ? _c(
                              "span",
                              [
                                _c(
                                  "el-link",
                                  {
                                    attrs: {
                                      type: "primary",
                                      underline: false,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.showVoucherFiles(
                                          scope.row.voucher_desc
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("查看图片")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  "class-name": "overflow-on",
                  width: "110",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-link",
                          {
                            staticClass: "link-right",
                            attrs: { type: "primary", underline: false },
                            on: {
                              click: function ($event) {
                                return _vm.editCostItem(scope.$index)
                              },
                            },
                          },
                          [_vm._v("\n            编辑\n          ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-link",
                          {
                            staticClass: "link-right",
                            attrs: { type: "primary", underline: false },
                            on: {
                              click: function ($event) {
                                return _vm.removeCostItem(scope.$index)
                              },
                            },
                          },
                          [_vm._v("\n            删除\n          ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.formLoading,
                  expression: "formLoading",
                },
              ],
              ref: "costItemForm",
              attrs: {
                model: _vm.costItemForm,
                rules: _vm.costItemFormRules,
                "label-suffix": ":",
                "label-width": "125px",
                "label-position": "right",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "费用类目及金额", required: "" } },
                [
                  _c(
                    "el-row",
                    { attrs: { type: "flex", gutter: 5 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "fee_type" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择费用类型" },
                                  model: {
                                    value: _vm.costItemForm.fee_type,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.costItemForm,
                                        "fee_type",
                                        $$v
                                      )
                                    },
                                    expression: "costItemForm.fee_type",
                                  },
                                },
                                _vm._l(_vm.feeTypes, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 10 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "fee" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入金额" },
                                model: {
                                  value: _vm.costItemForm.fee,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.costItemForm, "fee", $$v)
                                  },
                                  expression: "costItemForm.fee",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 1 } }, [_vm._v("元")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "费用说明", prop: "fee_desc" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "450px" },
                    attrs: { placeholder: "请输入费用说明" },
                    model: {
                      value: _vm.costItemForm.fee_desc,
                      callback: function ($$v) {
                        _vm.$set(_vm.costItemForm, "fee_desc", $$v)
                      },
                      expression: "costItemForm.fee_desc",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "费用依据", prop: "voucher_type" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.costItemForm.voucher_type,
                        callback: function ($$v) {
                          _vm.$set(_vm.costItemForm, "voucher_type", $$v)
                        },
                        expression: "costItemForm.voucher_type",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("i8流程"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 2 } }, [
                        _vm._v("凭证上传"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.costItemForm.voucher_type == 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "i8流程", prop: "voucher_i8" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "450px" },
                        attrs: { placeholder: "请输入i8流程号" },
                        model: {
                          value: _vm.costItemForm.voucher_i8,
                          callback: function ($$v) {
                            _vm.$set(_vm.costItemForm, "voucher_i8", $$v)
                          },
                          expression: "costItemForm.voucher_i8",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.costItemForm.voucher_type == 2
                ? _c(
                    "el-form-item",
                    { attrs: { label: "上传凭证", prop: "voucher_file" } },
                    [
                      _c(
                        "el-upload",
                        {
                          staticClass: "upload-voucher-image",
                          attrs: {
                            "list-type": "picture-card",
                            "file-list": _vm.voucherImages,
                            action: _vm.uploadUrl,
                            headers: _vm.header,
                            "before-upload": _vm.beforeUpload,
                            "on-progress": _vm.onUploadProgress,
                            "on-success": _vm.onUploadSuccess,
                            "on-change": _vm.onUploadChange,
                            "on-remove": _vm.onUploadChange,
                          },
                        },
                        [_c("i", { staticClass: "el-icon-plus" })]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "operation-btn" }, [
            this.costItemEditIndex != undefined
              ? _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          icon: "el-icon-edit",
                          size: "small",
                          disabled: _vm.submitDisabled,
                        },
                        on: { click: _vm.doEditCostItem },
                      },
                      [_vm._v("确认编辑")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small", disabled: _vm.submitDisabled },
                        on: { click: _vm.resetCostItemForm },
                      },
                      [_vm._v("取消")]
                    ),
                  ],
                  1
                )
              : _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          icon: "el-icon-plus",
                          size: "small",
                          disabled: _vm.submitDisabled,
                        },
                        on: { click: _vm.addCostItem },
                      },
                      [_vm._v("确认添加")]
                    ),
                  ],
                  1
                ),
          ]),
          _vm._v(" "),
          _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
            _c("div", { staticClass: "footer-container" }, [
              _c("div", { staticClass: "total" }, [
                _vm._v("共计：" + _vm._s(_vm.feeTotal) + " 元"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.dialogVisible = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        disabled: _vm.submitDisabled,
                        loading: _vm.submitLoading,
                      },
                      on: { click: _vm.submitHandler },
                    },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("ImagePreview", {
        ref: "ImagePreview",
        attrs: {
          title: "凭证预览",
          images: _vm.previewImages,
          visible: _vm.imagePreviewVisible,
        },
        on: {
          "update:visible": function ($event) {
            _vm.imagePreviewVisible = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }