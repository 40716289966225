"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _manager = require("@/api/manager");
var _net_bar = require("@/api/net_bar");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'SharedStoreAdmin',
  filters: {
    statusFilter: function statusFilter(status) {
      var statusMap = {
        published: 'success',
        draft: 'gray',
        deleted: 'danger'
      };
      return statusMap[status];
    }
  },
  data: function data() {
    return {
      pagination: "",
      adminList: null,
      dialogFormVisible: false,
      temp: {},
      dialogStatus: null,
      storeList: [],
      listQuery: {
        nb_id: "",
        page: 1,
        limit: 20
      }
    };
  },
  created: function created() {
    var _this = this;
    this.getAdminList();
    (0, _net_bar.getSimpleList)().then(function (response) {
      _this.storeList = response.data;
    });
  },
  methods: {
    getAdminList: function getAdminList() {
      var _this2 = this;
      this.listLoading = true;
      (0, _manager.fetchNetBarList)(this.listQuery).then(function (res) {
        _this2.adminList = res.data.data;
        _this2.pagination = res.data;
        _this2.listLoading = false;
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getAdminList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getAdminList();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getAdminList();
    },
    handleEdit: function handleEdit(row, status) {
      var _this3 = this;
      (0, _manager.fetchAdmin)(row.id).then(function (res) {
        _this3.temp = res.data;
      });
      this.dialogFormVisible = true;
      row.password = '';
      this.dialogStatus = status;
    },
    handleCreate: function handleCreate(status) {
      this.dialogStatus = status;
      this.dialogFormVisible = true;
      this.temp = {
        role: 'net_bar'
      };
    },
    create: function create() {
      var _this4 = this;
      this.$refs['accountForm'].validate(function (valid) {
        if (!valid) {
          return false;
        }
        (0, _manager.addAdmin)(_this4.temp).then(function (response) {
          if (response.code == 0) {
            _this4.$message({
              message: response.msg,
              type: 'success'
            });
            _this4.dialogFormVisible = false;
            _this4.getAdminList();
          } else {
            _this4.$message({
              message: response.msg,
              type: 'error'
            });
          }
        });
      });
    },
    update: function update() {
      var _this5 = this;
      this.$refs['accountForm'].validate(function (valid) {
        if (!valid) {
          return false;
        }
        (0, _manager.updateAdmin)(_this5.temp.id, _this5.temp).then(function (response) {
          if (response.code == 0) {
            _this5.$message({
              message: response.msg,
              type: 'success'
            });
            _this5.dialogFormVisible = false;
            _this5.getAdminList();
          } else {
            _this5.$message({
              message: response.msg,
              type: 'error'
            });
          }
        });
      });
    },
    deleteAdmin: function deleteAdmin(id) {
      var _this6 = this;
      this.$confirm('确定删除该账号吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      }).then(function () {
        (0, _manager.deleteAdmin)({
          id: id
        }).then(function (response) {
          if (response.code == 0) {
            _this6.$message({
              message: response.msg,
              type: 'success'
            });
            _this6.getAdminList();
          } else {
            _this6.$message({
              message: response.msg,
              type: 'error'
            });
          }
        });
      }).catch(function () {});
    }
  }
};
exports.default = _default;