"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _RightPanel = _interopRequireDefault(require("@/components/RightPanel"));
var _components = require("./components");
var _ResizeHandler = _interopRequireDefault(require("./mixin/ResizeHandler"));
var _vuex = require("vuex");
var _request = _interopRequireDefault(require("@/utils/request"));
var _auth = require("@/utils/auth");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// get token from cookie
var _default = {
  name: 'Layout',
  components: {
    AppMain: _components.AppMain,
    Navbar: _components.Navbar,
    RightPanel: _RightPanel.default,
    Settings: _components.Settings,
    Sidebar: _components.Sidebar,
    TagsView: _components.TagsView
  },
  mixins: [_ResizeHandler.default],
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapState)({
    sidebar: function sidebar(state) {
      return state.app.sidebar;
    },
    device: function device(state) {
      return state.app.device;
    },
    showSettings: function showSettings(state) {
      return state.settings.showSettings;
    },
    needTagsView: function needTagsView(state) {
      return state.settings.tagsView;
    },
    fixedHeader: function fixedHeader(state) {
      return state.settings.fixedHeader;
    }
  })), {}, {
    classObj: function classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === 'mobile'
      };
    }
  }),
  methods: {
    handleClickOutside: function handleClickOutside() {
      this.$store.dispatch('app/closeSideBar', {
        withoutAnimation: false
      });
    }
  }
};
/**
function timest() {
  var tmp = Date.parse( new Date() ).toString();
  tmp = tmp.substr(0,10);
  return tmp;
}
var nowTime = 0;
setInterval(function(){
  const hasToken = getToken();
  if (!hasToken) {
    return false;
  }
  request({
    url: '/order/isNewOrder',
    method: 'get',
    params: {}
  }).then(response => {
    if (response.data.order_num > 0){
      var iTitle = document.title;
      document.title = '您有新的订单待确认，若您30分钟内未处理，系统将自动接单。';
      if ((timest() - nowTime) > 300 && confirm("您有一笔新的订单待确认，若您30分钟内未处理，系统将自动接单。") ) {
        window.location = "/#/order/index";
        document.title = iTitle;
      } else {
        document.title = iTitle;
        nowTime = timest()
      }
    }
  })
},1000*60)
*/
exports.default = _default;