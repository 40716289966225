var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container point-good-type-container" },
    [
      _c("div", { staticClass: "filter-container filter-container-flex" }, [
        _c(
          "div",
          [
            _c("el-input", {
              staticClass: "filter-item",
              staticStyle: { width: "200px" },
              attrs: { placeholder: "分类名称" },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.handleFilter($event)
                },
              },
              model: {
                value: _vm.listQuery.title,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "title", $$v)
                },
                expression: "listQuery.title",
              },
            }),
            _vm._v(" "),
            _c(
              "el-select",
              {
                staticClass: "filter-item",
                attrs: {
                  "default-first-option": "",
                  placeholder: "状态",
                  clearable: "",
                },
                model: {
                  value: _vm.listQuery.is_show,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery, "is_show", $$v)
                  },
                  expression: "listQuery.is_show",
                },
              },
              _vm._l(_vm.showOptions, function (item) {
                return _c("el-option", {
                  key: item.value,
                  attrs: { label: item.label, value: item.value },
                })
              }),
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c(
              "el-button",
              {
                staticClass: "filter-item",
                attrs: { type: "primary" },
                on: { click: _vm.handleFilter },
              },
              [_vm._v("\n        搜索\n      ")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              { staticClass: "filter-item", on: { click: _vm.handleReset } },
              [_vm._v("\n        重置\n      ")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "filter-item",
                staticStyle: { "margin-left": "10px" },
                on: {
                  click: function ($event) {
                    return _vm.addTypeHandler()
                  },
                },
              },
              [_vm._v("\n        新增\n      ")]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          key: "tableList",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            "element-loading-text": "给我一点时间",
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "序号",
              "class-name": "overflow-on",
              width: "120",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v("\n        " + _vm._s(scope.row.id) + "\n      "),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "分类名称",
              "class-name": "overflow-on",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v("\n        " + _vm._s(scope.row.title) + "\n      "),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "关联商品数" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-link",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.showGoodsDialog(
                              scope.row.goods_count,
                              scope.row.id
                            )
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(scope.row.goods_count) +
                            "\n        "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "排序值" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.sort))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "状态" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(scope.row.is_show ? "显示" : "锁定")),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              fixed: "right",
              label: "操作",
              width: "120",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.editTypeHandler(scope.row)
                          },
                        },
                      },
                      [_vm._v("\n          编辑\n        ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "pagination-container text-right" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              total: _vm.pagination.total,
              "current-page": _vm.listQuery.page,
              "page-sizes": [10, 20, 50],
              "page-size": _vm.listQuery.limit,
              layout: "total, sizes, prev, pager, next, jumper",
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogFormVisible,
            "close-on-click-modal": false,
            title: _vm.dialogFormTitle,
            width: "640px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
            close: _vm.typeFormCloseHanlder,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "typeForm",
              attrs: {
                model: _vm.typeForm,
                rules: _vm.typeFormRules,
                "label-suffix": ":",
                "label-width": "85px",
                "label-position": "right",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "分类名称",
                    prop: "title",
                    maxlength: "20",
                    required: "",
                  },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "450px" },
                    attrs: { placeholder: "请输入酒店的名称，限20汉字" },
                    model: {
                      value: _vm.typeForm.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.typeForm, "title", $$v)
                      },
                      expression: "typeForm.title",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "选择商品", prop: "goods" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "450px" },
                      attrs: { multiple: "", placeholder: "请选择" },
                      model: {
                        value: _vm.typeForm.goods,
                        callback: function ($$v) {
                          _vm.$set(_vm.typeForm, "goods", $$v)
                        },
                        expression: "typeForm.goods",
                      },
                    },
                    _vm._l(_vm.goods, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.title, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "排序值", prop: "sort", required: "" } },
                [
                  _c("el-input-number", {
                    attrs: { min: 0, placeholder: "请输入" },
                    model: {
                      value: _vm.typeForm.sort,
                      callback: function ($$v) {
                        _vm.$set(_vm.typeForm, "sort", $$v)
                      },
                      expression: "typeForm.sort",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "状态", prop: "is_show", required: "" } },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 1 },
                      model: {
                        value: _vm.typeForm.is_show,
                        callback: function ($$v) {
                          _vm.$set(_vm.typeForm, "is_show", $$v)
                        },
                        expression: "typeForm.is_show",
                      },
                    },
                    [_vm._v("显示")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 0 },
                      model: {
                        value: _vm.typeForm.is_show,
                        callback: function ($$v) {
                          _vm.$set(_vm.typeForm, "is_show", $$v)
                        },
                        expression: "typeForm.is_show",
                      },
                    },
                    [_vm._v("隐藏")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticStyle: { "text-align": "center" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    loading: _vm.typeFormConfirmLoading,
                  },
                  on: { click: _vm.typeFormSubmitHandler },
                },
                [_vm._v("\n        确 定\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "sort-form-dialog",
          attrs: {
            visible: _vm.dialogSortVisible,
            "close-on-click-modal": false,
            title: "商品排序",
            width: "520px",
            top: "10vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogSortVisible = $event
            },
            close: _vm.sortDialogCloseHanlder,
          },
        },
        [
          _c("div", { staticClass: "sort-goods-header" }, [
            _c("div", [_vm._v("商品名称")]),
            _vm._v(" "),
            _c("div", [_vm._v("操作")]),
          ]),
          _vm._v(" "),
          _c(
            "draggable",
            {
              staticClass: "sort-goods-container",
              attrs: { draggable: ".sort-goods-item" },
              on: { sort: _vm.sortGoodsHandler },
              model: {
                value: _vm.sortGoods,
                callback: function ($$v) {
                  _vm.sortGoods = $$v
                },
                expression: "sortGoods",
              },
            },
            _vm._l(_vm.sortGoods, function (item, idx) {
              return _c(
                "div",
                { key: item.id, staticClass: "sort-goods-item" },
                [
                  _c("div", [_vm._v(_vm._s(item.title))]),
                  _vm._v(" "),
                  _c("div", [
                    _c("i", {
                      staticClass: "el-icon-arrow-up",
                      class: { disable: idx == 0 },
                      on: {
                        click: function ($event) {
                          return _vm.moveHandler(idx, -1)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("i", {
                      staticClass: "el-icon-arrow-down",
                      class: { disable: idx == _vm.sortGoodsLength - 1 },
                      on: {
                        click: function ($event) {
                          return _vm.moveHandler(idx, 1)
                        },
                      },
                    }),
                  ]),
                ]
              )
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticStyle: { "text-align": "center" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogSortVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    loading: _vm.sortFormConfirmLoading,
                  },
                  on: { click: _vm.sortSubmitHandler },
                },
                [_vm._v("\n        确 定\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }