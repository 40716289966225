var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container calendar-list-container hotel-form-page" },
    [
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "基本信息", name: "base" } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 20, md: 16, lg: 12, xl: 12 } },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "hotelForm",
                          staticClass: "small-space",
                          attrs: {
                            model: _vm.hotelForm,
                            "label-position": "left",
                            "label-width": "150px",
                            "label-suffix": ":",
                            rules: _vm.formRules,
                          },
                        },
                        [
                          _vm.areas.length > 0
                            ? _c(
                                "el-form-item",
                                { attrs: { label: "区域", prop: "area_id" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        "default-first-option": "",
                                        placeholder: "请选择区域",
                                      },
                                      model: {
                                        value: _vm.hotelForm.area_id,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.hotelForm,
                                            "area_id",
                                            $$v
                                          )
                                        },
                                        expression: "hotelForm.area_id",
                                      },
                                    },
                                    _vm._l(_vm.areas, function (item) {
                                      return _c("el-option", {
                                        key: item.role_id,
                                        attrs: {
                                          label: item.name,
                                          value: item.role_id,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "名称", prop: "name" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.hotelForm.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.hotelForm, "name", $$v)
                                  },
                                  expression: "hotelForm.name",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              ref: "hotelFormThumbnail",
                              attrs: { label: "缩略图", prop: "thumbnail" },
                            },
                            [
                              _c(
                                "el-upload",
                                {
                                  staticClass: "avatar-uploader",
                                  attrs: {
                                    action: _vm.uploadUrl,
                                    headers: _vm.header,
                                    "show-file-list": false,
                                    "on-success": _vm.handleImageSuccess,
                                  },
                                },
                                [
                                  _vm.hotelForm.thumbnail
                                    ? _c("img", {
                                        staticClass: "avatar",
                                        staticStyle: { "max-width": "500px" },
                                        attrs: { src: _vm.hotelForm.thumbnail },
                                      })
                                    : _c("i", {
                                        staticClass:
                                          "el-icon-plus avatar-uploader-icon",
                                      }),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "客服电话" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder:
                                    "用户可在酒店详情页拨打此客服电话",
                                },
                                model: {
                                  value: _vm.hotelForm.tel,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.hotelForm, "tel", $$v)
                                  },
                                  expression: "hotelForm.tel",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "手机号码" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder:
                                    "用于酒店接收新订单提醒短信，多个手机号中间用@隔开，最多录入5个",
                                },
                                model: {
                                  value: _vm.hotelForm.mobile,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.hotelForm, "mobile", $$v)
                                  },
                                  expression: "hotelForm.mobile",
                                },
                              }),
                              _vm._v(" "),
                              _c("div", { staticStyle: { color: "grey" } }, [
                                _vm._v(
                                  "\n                多个手机号中间用@隔开，最多录入5个。\n              "
                                ),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content: "用于接收新订单电话提醒",
                                placement: "left",
                              },
                            },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "语音通知号码" } },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "" },
                                    model: {
                                      value: _vm.hotelForm.voice_mobile,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.hotelForm,
                                          "voice_mobile",
                                          $$v
                                        )
                                      },
                                      expression: "hotelForm.voice_mobile",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              ref: "hotelFormDistrict",
                              attrs: { label: "省市区", prop: "district" },
                            },
                            [
                              _c("v-distpicker", {
                                attrs: {
                                  province: _vm.hotelForm.province,
                                  city: _vm.hotelForm.city,
                                  area: _vm.hotelForm.district,
                                },
                                on: { selected: _vm.onDistrictSelected },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "具体地址", prop: "address" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请填写详细地址，精确到门牌号",
                                },
                                model: {
                                  value: _vm.hotelForm.address,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.hotelForm, "address", $$v)
                                  },
                                  expression: "hotelForm.address",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "商圈", prop: "business_area" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    "allow-create": "",
                                    filterable: "",
                                    "default-first-option": "",
                                    placeholder: "可填写新建",
                                  },
                                  model: {
                                    value: _vm.hotelForm.business_area,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.hotelForm,
                                        "business_area",
                                        $$v
                                      )
                                    },
                                    expression: "hotelForm.business_area",
                                  },
                                },
                                _vm._l(_vm.businessAreaList, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "经度", prop: "lng" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.hotelForm.lng,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.hotelForm, "lng", $$v)
                                  },
                                  expression: "hotelForm.lng",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "纬度", prop: "lat" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.hotelForm.lat,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.hotelForm, "lat", $$v)
                                  },
                                  expression: "hotelForm.lat",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "飞火臻选", prop: "is_like" } },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.hotelForm.is_like,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.hotelForm, "is_like", $$v)
                                    },
                                    expression: "hotelForm.is_like",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: 0 } }, [
                                    _vm._v("否"),
                                  ]),
                                  _vm._v(" "),
                                  _c("el-radio", { attrs: { label: 1 } }, [
                                    _vm._v("是"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "是否显示", prop: "is_show" } },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.hotelForm.is_show,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.hotelForm, "is_show", $$v)
                                    },
                                    expression: "hotelForm.is_show",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: 0 } }, [
                                    _vm._v("否"),
                                  ]),
                                  _vm._v(" "),
                                  _c("el-radio", { attrs: { label: 1 } }, [
                                    _vm._v("是"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "校验订单号",
                                prop: "is_package",
                              },
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.hotelForm.bill.is_package,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.hotelForm.bill,
                                        "is_package",
                                        $$v
                                      )
                                    },
                                    expression: "hotelForm.bill.is_package",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: 0 } }, [
                                    _vm._v("否"),
                                  ]),
                                  _vm._v(" "),
                                  _c("el-radio", { attrs: { label: 1 } }, [
                                    _vm._v("是"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "校验健康码",
                                prop: "is_health_code",
                              },
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.hotelForm.bill.is_health_code,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.hotelForm.bill,
                                        "is_health_code",
                                        $$v
                                      )
                                    },
                                    expression: "hotelForm.bill.is_health_code",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: 0 } }, [
                                    _vm._v("否"),
                                  ]),
                                  _vm._v(" "),
                                  _c("el-radio", { attrs: { label: 1 } }, [
                                    _vm._v("是"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "美团ID", prop: "mt_id" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.hotelForm.bill.mt_id,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.hotelForm.bill, "mt_id", $$v)
                                  },
                                  expression: "hotelForm.bill.mt_id",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "计费系统ID", prop: "cd_id" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.hotelForm.bill.cd_id,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.hotelForm.bill, "cd_id", $$v)
                                  },
                                  expression: "hotelForm.bill.cd_id",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "第三方平台名称",
                                prop: "ota_names",
                              },
                            },
                            [
                              _vm._l(_vm.otas, function (item) {
                                return [
                                  _c(
                                    "div",
                                    { key: item.id, staticClass: "ota-names" },
                                    [
                                      _c("div", { staticClass: "ota-label" }, [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(item.title) +
                                            "：\n                  "
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("el-input", {
                                        attrs: {
                                          clall: "ota-input",
                                          placeholder:
                                            "多个名称中间用逗号分隔，如：酒店1,酒店2",
                                        },
                                        model: {
                                          value:
                                            _vm.hotelForm.ota_names[item.id],
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.hotelForm.ota_names,
                                              item.id,
                                              $$v
                                            )
                                          },
                                          expression:
                                            "hotelForm.ota_names[item.id]",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              }),
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "第三方平台门店ID",
                                prop: "ota_ids",
                              },
                            },
                            [
                              _vm._l(_vm.otas, function (item) {
                                return [
                                  _c(
                                    "div",
                                    { key: item.id, staticClass: "ota-names" },
                                    [
                                      _c("div", { staticClass: "ota-label" }, [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(item.title) +
                                            "：\n                  "
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("el-input", {
                                        attrs: {
                                          clall: "ota-input",
                                          placeholder:
                                            "多个ID中间用逗号分隔，如：id1,id2",
                                        },
                                        model: {
                                          value: _vm.hotelForm.ota_ids[item.id],
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.hotelForm.ota_ids,
                                              item.id,
                                              $$v
                                            )
                                          },
                                          expression:
                                            "hotelForm.ota_ids[item.id]",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              }),
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "企业微信", prop: "wxwork_info" },
                            },
                            [
                              _vm._l(_vm.wxworkGroups, function (wxworkGroup) {
                                return [
                                  _c("div", { key: wxworkGroup.tag }, [
                                    _c(
                                      "div",
                                      { staticClass: "wxwork-names" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "wxwork-label",
                                            staticStyle: { width: "85px" },
                                          },
                                          [_vm._v("企微群：")]
                                        ),
                                        _vm._v(" "),
                                        _c("el-input", {
                                          attrs: {
                                            clall: "wxwork-input",
                                            placeholder:
                                              "请输入企微群机器人key信息",
                                          },
                                          model: {
                                            value:
                                              _vm.hotelForm.wxwork_groups[
                                                wxworkGroup.tag
                                              ].robot_key,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.hotelForm.wxwork_groups[
                                                  wxworkGroup.tag
                                                ],
                                                "robot_key",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "hotelForm.wxwork_groups[wxworkGroup.tag].robot_key",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "wxwork-names" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "wxwork-label",
                                            staticStyle: { width: "85px" },
                                          },
                                          [_vm._v("企微账号：")]
                                        ),
                                        _vm._v(" "),
                                        _c("el-input", {
                                          attrs: {
                                            clall: "wxwork-input",
                                            placeholder:
                                              "请输入企微账号，若有多个，请以英语 ; 分隔",
                                          },
                                          model: {
                                            value:
                                              _vm.hotelForm.wxwork_groups[
                                                wxworkGroup.tag
                                              ].mention_accounts,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.hotelForm.wxwork_groups[
                                                  wxworkGroup.tag
                                                ],
                                                "mention_accounts",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "hotelForm.wxwork_groups[wxworkGroup.tag].mention_accounts",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                ]
                              }),
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "商家权限配置" } },
                            [
                              _c(
                                "el-checkbox-group",
                                {
                                  model: {
                                    value: _vm.hotelForm.permission,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.hotelForm, "permission", $$v)
                                    },
                                    expression: "hotelForm.permission",
                                  },
                                },
                                _vm._l(
                                  _vm.permissionList,
                                  function (permission) {
                                    return _c(
                                      "el-checkbox",
                                      { attrs: { label: permission.value } },
                                      [_vm._v(_vm._s(permission.label))]
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "标签" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    multiple: "",
                                    "multiple-limit": _vm.tagLimit,
                                    filterable: "",
                                    "allow-create": "",
                                    "default-first-option": "",
                                    placeholder: "请选择标签",
                                  },
                                  model: {
                                    value: _vm.hotelForm.tags,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.hotelForm, "tags", $$v)
                                    },
                                    expression: "hotelForm.tags",
                                  },
                                },
                                _vm._l(_vm.options, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "酒店图片" } },
                            [
                              [
                                _vm._l(_vm.images, function (item, index) {
                                  return _c(
                                    "div",
                                    { key: index },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: { placeholder: "请先选择" },
                                          model: {
                                            value: item.location,
                                            callback: function ($$v) {
                                              _vm.$set(item, "location", $$v)
                                            },
                                            expression: "item.location",
                                          },
                                        },
                                        _vm._l(
                                          _vm.locationList,
                                          function (item2, key2) {
                                            return _c("el-option", {
                                              key: key2,
                                              attrs: {
                                                label: item2,
                                                value: key2,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("imageUplad", {
                                        attrs: {
                                          type: index,
                                          "image-list": item.imageList,
                                        },
                                        on: {
                                          uploadHotelImageSuccess: function (
                                            $event
                                          ) {
                                            return _vm.uploadHotelImageSuccess(
                                              $event
                                            )
                                          },
                                          deleteHotelImageSuccess: function (
                                            $event
                                          ) {
                                            return _vm.deleteHotelImageSuccess(
                                              $event
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                }),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.addImages()
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                  添加位置\n                "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.delImage()
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                  删除位置\n                "
                                    ),
                                  ]
                                ),
                              ],
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "text-center ml" },
                            [
                              _c("el-button", { on: { click: _vm.cancel } }, [
                                _vm._v(
                                  "\n                取 消\n              "
                                ),
                              ]),
                              _vm._v(" "),
                              _vm.hotel_id
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary" },
                                      on: { click: _vm.updateHotel },
                                    },
                                    [
                                      _vm._v(
                                        "\n                更新\n              "
                                      ),
                                    ]
                                  )
                                : _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary" },
                                      on: { click: _vm.createHotel },
                                    },
                                    [
                                      _vm._v(
                                        "\n                创建\n              "
                                      ),
                                    ]
                                  ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-tab-pane",
            { attrs: { label: "财务信息", name: "ext", lazy: "" } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 20, md: 16, lg: 12, xl: 12 } },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "hotelInfoForm",
                          staticClass: "small-space",
                          attrs: {
                            model: _vm.bill,
                            rules: _vm.billRules,
                            "label-position": "left",
                            "label-width": "150px",
                            "label-suffix": ":",
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "经营模式", prop: "mgt_mode" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    filterable: "",
                                    "default-first-option": "",
                                    placeholder: "请选择经营模式",
                                  },
                                  model: {
                                    value: _vm.bill.mgt_mode,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.bill, "mgt_mode", $$v)
                                    },
                                    expression: "bill.mgt_mode",
                                  },
                                },
                                _vm._l(_vm.managementModeList, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "结算模式", prop: "mode" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    filterable: "",
                                    "default-first-option": "",
                                    placeholder: "请选择结算模式",
                                  },
                                  model: {
                                    value: _vm.bill.mode,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.bill, "mode", $$v)
                                    },
                                    expression: "bill.mode",
                                  },
                                },
                                _vm._l(_vm.modeList, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.showShareRatio
                            ? [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "商家分润比例" } },
                                  [
                                    _c("el-slider", {
                                      attrs: { "show-input": "" },
                                      model: {
                                        value: _vm.bill.share_ratio,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.bill, "share_ratio", $$v)
                                        },
                                        expression: "bill.share_ratio",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.isSoftMode
                            ? [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "商家分润" } },
                                  [
                                    _c("span", [
                                      _vm._v("按房型底价结算"),
                                      _c(
                                        "span",
                                        { staticStyle: { color: "red" } },
                                        [_vm._v("（需至房型价格日历设置底价）")]
                                      ),
                                    ]),
                                  ]
                                ),
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.showAgent
                            ? [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "是否有代理商",
                                      prop: "is_agent",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-radio-group",
                                      {
                                        model: {
                                          value: _vm.bill.is_agent,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.bill, "is_agent", $$v)
                                          },
                                          expression: "bill.is_agent",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 1 } },
                                          [_vm._v("是")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 0 } },
                                          [_vm._v("否")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _vm.bill.is_agent == 1
                                  ? [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "代理商名称",
                                            prop: "agent_id",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              attrs: {
                                                filterable: "",
                                                "default-first-option": "",
                                                placeholder: "请选择代理商",
                                              },
                                              model: {
                                                value: _vm.bill.agent_id,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.bill,
                                                    "agent_id",
                                                    $$v
                                                  )
                                                },
                                                expression: "bill.agent_id",
                                              },
                                            },
                                            _vm._l(
                                              _vm.agentList,
                                              function (item) {
                                                return _c("el-option", {
                                                  key: item.id,
                                                  attrs: {
                                                    label: item.name,
                                                    value: item.id,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _vm.showAgentRatio
                                        ? _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "代理商订房金额分润",
                                                prop: "agent_ratio",
                                              },
                                            },
                                            [
                                              _c("el-slider", {
                                                attrs: { "show-input": "" },
                                                model: {
                                                  value: _vm.bill.agent_ratio,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.bill,
                                                      "agent_ratio",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "bill.agent_ratio",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.showCDAgentRatio
                                        ? _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "代理商计费金额分润",
                                                prop: "cd_agent_ratio",
                                              },
                                            },
                                            [
                                              _c("el-slider", {
                                                attrs: { "show-input": "" },
                                                model: {
                                                  value:
                                                    _vm.bill.cd_agent_ratio,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.bill,
                                                      "cd_agent_ratio",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "bill.cd_agent_ratio",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  : _vm._e(),
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "收款银行", prop: "cbs_bank" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    filterable: "",
                                    placeholder: "请选择收款银行",
                                  },
                                  on: { change: _vm.onCbsBankChange },
                                  model: {
                                    value: _vm.bill.cbs_bank,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.bill, "cbs_bank", $$v)
                                    },
                                    expression: "bill.cbs_bank",
                                  },
                                },
                                _vm._l(_vm.cbsBanks, function (item) {
                                  return _c("el-option", {
                                    key: item.code,
                                    attrs: {
                                      label: item.name + "@" + item.code,
                                      value: item.name + "@" + item.code,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              ref: "hotelInfoFormBankCity",
                              attrs: { label: "开户行所在省市", required: "" },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticStyle: { display: "inline-block" },
                                  attrs: { prop: "bank_province" },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        filterable: "",
                                        placeholder: "请选择省",
                                      },
                                      on: { change: _vm.onBankProvinceChange },
                                      model: {
                                        value: _vm.bill.bank_province,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.bill,
                                            "bank_province",
                                            $$v
                                          )
                                        },
                                        expression: "bill.bank_province",
                                      },
                                    },
                                    _vm._l(_vm.cbsProvinces, function (item) {
                                      return _c("el-option", {
                                        key: item,
                                        attrs: { label: item, value: item },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  staticStyle: { display: "inline-block" },
                                  attrs: { prop: "bank_city" },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        filterable: "",
                                        placeholder: "请选择市",
                                        disabled: !_vm.bill.bank_province,
                                      },
                                      on: { change: _vm.onBankCityChange },
                                      model: {
                                        value: _vm.bill.bank_city,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.bill, "bank_city", $$v)
                                        },
                                        expression: "bill.bank_city",
                                      },
                                    },
                                    _vm._l(_vm.cbsCities, function (item) {
                                      return _c("el-option", {
                                        key: item,
                                        attrs: { label: item, value: item },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "开户行", prop: "bank" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "408px" },
                                  attrs: {
                                    filterable: "",
                                    placeholder: "请选择开户行",
                                    disabled: !_vm.showBillBank(),
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.$forceUpdate()
                                    },
                                  },
                                  model: {
                                    value: _vm.bill.bank,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.bill, "bank", $$v)
                                    },
                                    expression: "bill.bank",
                                  },
                                },
                                _vm._l(_vm.cbsOpenBanks, function (item) {
                                  return _c("el-option", {
                                    key: item,
                                    attrs: { label: item, value: item },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "账户性质", prop: "type" } },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.bill.type,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.bill, "type", $$v)
                                    },
                                    expression: "bill.type",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: 1 } }, [
                                    _vm._v("对公"),
                                  ]),
                                  _vm._v(" "),
                                  _c("el-radio", { attrs: { label: 2 } }, [
                                    _vm._v("对私"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "账户名称", prop: "account" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.bill.account,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.bill, "account", $$v)
                                  },
                                  expression: "bill.account",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "收款账户银行账号",
                                prop: "bank_account",
                              },
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.bill.bank_account,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.bill, "bank_account", $$v)
                                  },
                                  expression: "bill.bank_account",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.bill.type == 2
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "收款人身份证号",
                                    prop: "idcard",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder:
                                        "请填写详细地址，精确到门牌号",
                                    },
                                    model: {
                                      value: _vm.bill.idcard,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.bill, "idcard", $$v)
                                      },
                                      expression: "bill.idcard",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "text-center ml" },
                            [
                              _c("el-button", { on: { click: _vm.cancel } }, [
                                _vm._v(
                                  "\n                取 消\n              "
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary" },
                                  on: { click: _vm.updateHotelInfo },
                                },
                                [
                                  _vm._v(
                                    "\n                更新\n              "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-tab-pane",
            {
              attrs: {
                label: "押金抵扣设置",
                name: "deposit_deduct",
                lazy: "",
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 20, md: 16, lg: 12, xl: 12 } },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "depositDeductForm",
                          staticClass: "small-space",
                          attrs: {
                            model: _vm.depositDeductForm,
                            rules: _vm.depositDeductRules,
                            "label-position": "left",
                            "label-width": "150px",
                            "label-suffix": ":",
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "是否押金抵扣",
                                prop: "is_deposit_deduct",
                              },
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  attrs: {
                                    disabled:
                                      !_vm.enableOperateDepositDeductRole ||
                                      !_vm.enbaleDepositDeduct,
                                  },
                                  model: {
                                    value:
                                      _vm.depositDeductForm.is_deposit_deduct,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.depositDeductForm,
                                        "is_deposit_deduct",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "depositDeductForm.is_deposit_deduct",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: 1 } }, [
                                    _vm._v("是"),
                                  ]),
                                  _vm._v(" "),
                                  _c("el-radio", { attrs: { label: 0 } }, [
                                    _vm._v("否"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "押金金额",
                                prop: "deposit_amount",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled:
                                    !_vm.enableOperateDepositDeductRole ||
                                    !_vm.depositDeductForm.is_deposit_deduct ||
                                    _vm.isDepositDeductSetted,
                                  placeholder:
                                    "一旦填写无法修改，请务必按合同金额填写",
                                },
                                model: {
                                  value: _vm.depositDeductForm.deposit_amount,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.depositDeductForm,
                                      "deposit_amount",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "depositDeductForm.deposit_amount",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "text-center ml" },
                            [
                              _c("el-button", { on: { click: _vm.cancel } }, [
                                _vm._v(
                                  "\n                取 消\n              "
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.enableOperateDepositDeductRole,
                                      expression:
                                        "enableOperateDepositDeductRole",
                                    },
                                  ],
                                  attrs: {
                                    type: "primary",
                                    loading: _vm.depositDeductSubmitLoading,
                                  },
                                  on: { click: _vm.updateHotelDepositInfo },
                                },
                                [
                                  _vm._v(
                                    "\n                更新\n              "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-tab-pane",
            { attrs: { label: "飞火佣金设置", name: "commission", lazy: "" } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 20, md: 16, lg: 12, xl: 12 } },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "commissionForm",
                          staticClass: "small-space",
                          attrs: {
                            model: _vm.commissionForm,
                            rules: _vm.commissionRules,
                            "label-position": "left",
                            "label-width": "150px",
                            "label-suffix": ":",
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "飞火渠道佣金",
                                prop: "is_commission",
                              },
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.commissionForm.is_commission,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.commissionForm,
                                        "is_commission",
                                        $$v
                                      )
                                    },
                                    expression: "commissionForm.is_commission",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: 1 } }, [
                                    _vm._v("是"),
                                  ]),
                                  _vm._v(" "),
                                  _c("el-radio", { attrs: { label: 0 } }, [
                                    _vm._v("否"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "飞火佣金比例",
                                prop: "share_rate",
                              },
                            },
                            [
                              _c("el-input-number", {
                                attrs: {
                                  min: 0,
                                  max: 100,
                                  disabled: !_vm.commissionForm.is_commission,
                                },
                                model: {
                                  value: _vm.commissionForm.share_rate,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.commissionForm,
                                      "share_rate",
                                      $$v
                                    )
                                  },
                                  expression: "commissionForm.share_rate",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "text-center ml" },
                            [
                              _c("el-button", { on: { click: _vm.cancel } }, [
                                _vm._v(
                                  "\n                取 消\n              "
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    loading: _vm.commissionSubmitLoading,
                                  },
                                  on: { click: _vm.updateHotelCommissionInfo },
                                },
                                [
                                  _vm._v(
                                    "\n                更新\n              "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }