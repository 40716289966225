"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _activity = require("@/api/activity");
var _coupon = require("@/api/coupon");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "ActivityForm",
  components: {},
  props: {
    id: [Number, String],
    success: Function
  },
  data: function data() {
    return {
      data: {
        title: "",
        start_time: "",
        end_time: "",
        coupon_id: ""
      },
      dialogformVisible: false,
      formSubmitLoading: false,
      dialogFormTitle: "活动详情",
      couponList: [],
      datePickerOptions: {
        disabledDate: function disabledDate(time) {
          return time.getTime() < Date.now() - 86400000;
        }
      },
      rules: {
        title: [{
          required: true,
          message: "请输入名称",
          trigger: 'blur'
        }],
        start_time: [{
          type: 'date',
          required: true,
          message: "请选择开始日期",
          trigger: 'change'
        }],
        end_time: [{
          type: 'date',
          required: true,
          message: "请选择结束日期",
          trigger: 'change'
        }],
        coupon_id: [{
          required: true,
          message: "请选择优惠券",
          trigger: 'change'
        }]
      }
    };
  },
  created: function created() {
    var _this = this;
    (0, _coupon.couponManualList)().then(function (response) {
      _this.couponList = response.data;
    });
  },
  methods: {
    open: function open() {
      if (this.id) {
        this.dialogFormTitle = "编辑活动详情";
        // fetchScan(this.id)
        //   .then(result => {
        //     this.data = result.data
        //   })
      } else {
        this.data = {
          title: "",
          start_time: "",
          end_time: "",
          coupon_id: ""
        };
        this.dialogFormTitle = "设置活动详情";
      }
      this.dialogformVisible = true;
    },
    formCloseHanlder: function formCloseHanlder() {
      this.$refs['form'].resetFields();
    },
    formSubmitHandler: function formSubmitHandler() {
      var _this2 = this;
      this.$refs['form'].validate(function (valid) {
        if (!valid) {
          return false;
        }
        if (_this2.id) {
          // this.editHandler()
        } else {
          _this2.addHandler();
        }
      });
    },
    addHandler: function addHandler() {
      var _this3 = this;
      this.formSubmitLoading = true;
      (0, _activity.createScan)(this.data).then(function () {
        _this3.$message.success("添加成功");
        _this3.$emit('successCallback');
        _this3.dialogformVisible = false;
        _this3.formSubmitLoading = false;
      }).catch(function () {
        _this3.formSubmitLoading = false;
      });
    } // editHandler() {
    //   this.formSubmitLoading = true
    //   updateScan(this.id, this.data)
    //     .then(() => {
    //       this.$message.success("编辑成功")
    //       this.$emit('successCallback');
    //       this.dialogformVisible = false
    //       this.formSubmitLoading = false
    //     })
    //     .catch(() => {
    //       this.formSubmitLoading = false
    //     })
    // },
  }
};
exports.default = _default;