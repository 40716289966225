var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container calendar-list-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _vm.areas.length > 0
            ? _c(
                "el-select",
                {
                  staticClass: "filter-item",
                  attrs: {
                    "default-first-option": "",
                    placeholder: "请选择区域",
                    clearable: "",
                  },
                  model: {
                    value: _vm.listQuery.area_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery, "area_id", $$v)
                    },
                    expression: "listQuery.area_id",
                  },
                },
                _vm._l(_vm.areas, function (item) {
                  return _c("el-option", {
                    key: item.role_id,
                    attrs: { label: item.name, value: item.role_id },
                  })
                }),
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.handleFilter },
            },
            [_vm._v("\n      搜索\n    ")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              staticStyle: { "margin-left": "10px" },
              attrs: { type: "primary", icon: "el-icon-edit" },
              on: { click: _vm.addBtn },
            },
            [_vm._v("\n      添加\n    ")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("el-alert", {
        attrs: {
          title:
            "说明：每个版块不可同时上架多个秒杀活动，否则将自动下架前一个活动。编辑库存第二天生效。",
          type: "warning",
          "show-icon": "",
          closable: false,
        },
      }),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          key: "tableList",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            "element-loading-text": "给我一点时间",
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "ID", width: "65" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.id))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "活动日期",
              "class-name": "overflow-on",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.start_date) +
                        " ~ " +
                        _vm._s(scope.row.end_date) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "状态",
              "class-name": "overflow-on",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " + _vm._s(scope.row.status_name) + "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "最新更新" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.editor))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "更新时间" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.updated_at))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.status == 1
                      ? _c(
                          "span",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "small", type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.editBtn(scope.row)
                                  },
                                },
                              },
                              [_vm._v("\n            编辑\n          ")]
                            ),
                          ],
                          1
                        )
                      : _c(
                          "span",
                          [
                            scope.row.status == 1
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { size: "small", type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.doEdit(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("\n            保存\n          ")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                    _vm._v(" "),
                    scope.row.status == 1
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "danger" },
                            on: {
                              click: function ($event) {
                                return _vm.downShelf(scope.row)
                              },
                            },
                          },
                          [_vm._v("\n          下架\n        ")]
                        )
                      : _c("span", [_vm._v("已下架")]),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.copySeckill(scope.row)
                          },
                        },
                      },
                      [_vm._v("\n          复制\n        ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "pagination-container text-right" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              total: _vm.pagination.total,
              "current-page": _vm.listQuery.page,
              "page-sizes": [20],
              "page-size": _vm.listQuery.limit,
              layout: "total, sizes, prev, pager, next, jumper",
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: _vm.dialogTitle, visible: _vm.dialogFormVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "temp",
              staticClass: "small-space",
              staticStyle: { width: "800px", "margin-left": "20px" },
              attrs: {
                model: _vm.temp,
                "label-position": "right",
                "label-width": "100px",
              },
            },
            [
              _c("el-alert", {
                staticStyle: { "margin-bottom": "20px" },
                attrs: {
                  title:
                    "活动日期内每天可设置多场秒杀，每场秒杀可配置多个商品，注意时段不可重叠",
                  type: "success",
                  closable: false,
                },
              }),
              _vm._v(" "),
              _vm.areas.length > 0
                ? _c(
                    "el-form-item",
                    { attrs: { label: "活动区域" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "filter-item",
                          attrs: {
                            "default-first-option": "",
                            placeholder: "请选择区域",
                            clearable: "",
                          },
                          model: {
                            value: _vm.temp.area_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.temp, "area_id", $$v)
                            },
                            expression: "temp.area_id",
                          },
                        },
                        _vm._l(_vm.areas, function (item) {
                          return _c("el-option", {
                            key: item.role_id,
                            attrs: { label: item.name, value: item.role_id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "活动日期", required: "", prop: "date" } },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "100%" },
                    attrs: { type: "daterange", "value-format": "yyyyMMdd" },
                    model: {
                      value: _vm.temp.date,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp, "date", $$v)
                      },
                      expression: "temp.date",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "活动规则", prop: "rule", required: "" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea" },
                    model: {
                      value: _vm.temp.rule,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp, "rule", $$v)
                      },
                      expression: "temp.rule",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "活动banner", required: "" } },
                [
                  _c(
                    "el-upload",
                    {
                      staticClass: "avatar-uploader",
                      attrs: {
                        action: "/admin/upload",
                        headers: _vm.header,
                        "show-file-list": false,
                        "on-success": _vm.handleImageSuccess,
                      },
                    },
                    [
                      _vm.temp.banner
                        ? _c("img", {
                            staticClass: "avatar",
                            staticStyle: { "max-width": "200px" },
                            attrs: { src: _vm.temp.banner },
                          })
                        : _c("i", {
                            staticClass: "el-icon-plus avatar-uploader-icon",
                          }),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "首页横幅", required: "" } },
                [
                  _c(
                    "el-upload",
                    {
                      staticClass: "avatar-uploader",
                      attrs: {
                        action: "/admin/upload",
                        headers: _vm.header,
                        "show-file-list": false,
                        "on-success": _vm.handleStreamerSuccess,
                      },
                    },
                    [
                      _vm.temp.streamer
                        ? _c("img", {
                            staticClass: "avatar",
                            staticStyle: { "max-width": "200px" },
                            attrs: { src: _vm.temp.streamer },
                          })
                        : _c("i", {
                            staticClass: "el-icon-plus avatar-uploader-icon",
                          }),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm._l(_vm.temp.detail, function (domain, index) {
                return _c(
                  "div",
                  {
                    key: domain.key,
                    attrs: { label: "场次" + index, prop: "detail[]" },
                  },
                  [
                    _c("el-divider", {
                      staticClass: "divider-title-tile",
                      attrs: { "content-position": "left" },
                    }),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "秒杀时段", required: "" } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 5 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { prop: "start_time" } },
                              [
                                _c("el-time-select", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    "value-format": "HH:mm",
                                    "picker-options": {
                                      start: "00:00",
                                      end: "23:45",
                                    },
                                  },
                                  model: {
                                    value: domain.start_time,
                                    callback: function ($$v) {
                                      _vm.$set(domain, "start_time", $$v)
                                    },
                                    expression: "domain.start_time",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          {
                            staticClass: "line text-center",
                            attrs: { span: 2 },
                          },
                          [_vm._v("\n            -\n          ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 5 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { prop: "end_time" } },
                              [
                                _c("el-time-select", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    "value-format": "HH:mm",
                                    "picker-options": {
                                      start: "00:00",
                                      end: "23:45",
                                    },
                                  },
                                  model: {
                                    value: domain.end_time,
                                    callback: function ($$v) {
                                      _vm.$set(domain, "end_time", $$v)
                                    },
                                    expression: "domain.end_time",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-button",
                              {
                                staticStyle: { "margin-left": "50px" },
                                attrs: { type: "primary" },
                                on: { click: _vm.addDomain },
                              },
                              [_vm._v("\n              添加场次\n            ")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.removeDomain(domain)
                                  },
                                },
                              },
                              [_vm._v("\n              删除\n            ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm._l(domain.coupons, function (row, i) {
                      return _c(
                        "div",
                        {
                          key: row.key,
                          staticClass: "room-box",
                          attrs: { label: "优惠券" + i },
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "商品类型", required: "" } },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      on: {
                                        change: function ($event) {
                                          return _vm.changeRow(row)
                                        },
                                      },
                                      model: {
                                        value: row.type,
                                        callback: function ($$v) {
                                          _vm.$set(row, "type", $$v)
                                        },
                                        expression: "row.type",
                                      },
                                    },
                                    _vm._l(_vm.typeList, function (item) {
                                      return _c("el-option", {
                                        key: item.val,
                                        attrs: {
                                          label: item.label,
                                          value: item.val,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "商品名称",
                                        required: "",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: { filterable: "" },
                                          model: {
                                            value: row.coupon_id,
                                            callback: function ($$v) {
                                              _vm.$set(row, "coupon_id", $$v)
                                            },
                                            expression: "row.coupon_id",
                                          },
                                        },
                                        _vm._l(row.list, function (item) {
                                          return _c("el-option", {
                                            key: item.id,
                                            attrs: {
                                              label: item.name,
                                              value: item.id,
                                            },
                                          })
                                        }),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "秒杀价格", required: "" } },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "用户实际支付的价格",
                                    },
                                    model: {
                                      value: row.price,
                                      callback: function ($$v) {
                                        _vm.$set(row, "price", $$v)
                                      },
                                      expression: "row.price",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "origin_price",
                                        label: "划线价",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        model: {
                                          value: row.origin_price,
                                          callback: function ($$v) {
                                            _vm.$set(row, "origin_price", $$v)
                                          },
                                          expression: "row.origin_price",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "本场库存", required: "" } },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder:
                                        "此商品在本时段可被用户购买的数量",
                                    },
                                    model: {
                                      value: row.stock_num,
                                      callback: function ($$v) {
                                        _vm.$set(row, "stock_num", $$v)
                                      },
                                      expression: "row.stock_num",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "desc",
                                        label: "商品描述",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          placeholder:
                                            "示例：限时特价 | 不可退",
                                        },
                                        model: {
                                          value: row.desc,
                                          callback: function ($$v) {
                                            _vm.$set(row, "desc", $$v)
                                          },
                                          expression: "row.desc",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticStyle: { "margin-left": "100px" } },
                            [
                              _c(
                                "el-button",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.addCoupon(domain.coupons)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              添加商品\n            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.removeCoupon(
                                        row,
                                        domain.coupons
                                      )
                                    },
                                  },
                                },
                                [_vm._v("\n              删除\n            ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                  ],
                  2
                )
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = false
                    },
                  },
                },
                [_vm._v("\n        取 消\n      ")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: _vm.btn },
                  on: {
                    click: function ($event) {
                      return _vm.doConfirm()
                    },
                  },
                },
                [_vm._v("\n        确 定\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }