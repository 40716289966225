var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dashboard-editor-container" }, [
    _c("div", { staticClass: " clearfix" }, [
      _c("div", { staticClass: "info-container text-center" }, [
        _c("div", [_c("img", { attrs: { src: _vm.welcome_img } })]),
        _vm._v(" "),
        _c("div", { staticClass: "display_name" }, [_vm._v(_vm._s(_vm.name))]),
        _vm._v(" "),
        _c(
          "span",
          {
            staticStyle: {
              "font-size": "20px",
              "padding-top": "20px",
              display: "inline-block",
            },
          },
          [_vm._v("欢迎登录酒店管理后台")]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }