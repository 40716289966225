"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: "PriceCalendarLog",
  props: {
    value: {
      type: Object
    },
    title: {
      type: [Number, String],
      default: function _default() {
        return "";
      }
    }
  },
  data: function data() {
    return {
      weeks: [{
        label: "周一",
        value: 1
      }, {
        label: "周二",
        value: 2
      }, {
        label: "周三",
        value: 3
      }, {
        label: "周四",
        value: 4
      }, {
        label: "周五",
        value: 5
      }, {
        label: "周六",
        value: 6
      }, {
        label: "周日",
        value: 7
      }],
      hours: [{
        label: "00",
        value: 0
      }, {
        label: "01",
        value: 1
      }, {
        label: "02",
        value: 2
      }, {
        label: "03",
        value: 3
      }, {
        label: "04",
        value: 4
      }, {
        label: "05",
        value: 5
      }, {
        label: "06",
        value: 6
      }, {
        label: "07",
        value: 7
      }, {
        label: "08",
        value: 8
      }, {
        label: "09",
        value: 9
      }, {
        label: "10",
        value: 10
      }, {
        label: "11",
        value: 11
      }, {
        label: "12",
        value: 12
      }, {
        label: "13",
        value: 13
      }, {
        label: "14",
        value: 14
      }, {
        label: "15",
        value: 15
      }, {
        label: "16",
        value: 16
      }, {
        label: "17",
        value: 17
      }, {
        label: "18",
        value: 18
      }, {
        label: "19",
        value: 19
      }, {
        label: "20",
        value: 20
      }, {
        label: "21",
        value: 21
      }, {
        label: "22",
        value: 22
      }, {
        label: "23",
        value: 23
      }],
      prices: {},
      priceDialogVisible: false,
      priceForm: {
        price: undefined
      }
    };
  },
  watch: {
    value: function value(newVal) {
      this.prices = JSON.parse(JSON.stringify(newVal));
    }
  },
  created: function created() {},
  methods: {
    getClasses: function getClasses(weekId, hourId) {
      var itemId = "".concat(weekId, "_").concat(hourId);
      return {
        block: true,
        'hour-item': true,
        'hour-item-seted': this.prices[itemId] != undefined,
        'hour-item-last': hourId == 23 // 最后一个小时
      };
    },
    getPrice: function getPrice(weekId, hourId) {
      if (this.prices["".concat(weekId, "_").concat(hourId)] == undefined) {
        return '';
      }
      return this.prices["".concat(weekId, "_").concat(hourId)].price || '';
    },
    getOldPrice: function getOldPrice(weekId, hourId) {
      if (this.prices["".concat(weekId, "_").concat(hourId)] == undefined) {
        return undefined;
      }
      return this.prices["".concat(weekId, "_").concat(hourId)].old_price || undefined;
    }
  }
};
exports.default = _default2;