var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "form-dialog",
      attrs: {
        visible: _vm.dialogVisible,
        title: "编辑设备",
        "close-on-click-modal": false,
        width: "540px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.closeHanlder,
      },
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.formLoading,
              expression: "formLoading",
            },
          ],
          ref: "deviceForm",
          attrs: {
            model: _vm.deviceForm,
            rules: _vm.deviceFormRules,
            "label-suffix": ":",
            "label-width": "115px",
            "label-position": "right",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "门店设备编号", prop: "name" } },
            [
              _c("el-input", {
                staticStyle: { width: "320px" },
                attrs: {
                  disabled: _vm.basicEditDisabled(),
                  placeholder: "请输入门店设备编号",
                },
                model: {
                  value: _vm.deviceForm.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.deviceForm, "name", $$v)
                  },
                  expression: "deviceForm.name",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "设备编号", prop: "code" } },
            [
              _c("el-input", {
                staticStyle: { width: "320px" },
                attrs: {
                  disabled: _vm.basicEditDisabled(),
                  placeholder: "请保证与无盘系统内设备ID一致",
                },
                model: {
                  value: _vm.deviceForm.code,
                  callback: function ($$v) {
                    _vm.$set(_vm.deviceForm, "code", $$v)
                  },
                  expression: "deviceForm.code",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "显卡", prop: "graphic_card" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "320px" },
                  attrs: {
                    disabled: _vm.basicEditDisabled(),
                    placeholder: "请选择显卡",
                  },
                  model: {
                    value: _vm.deviceForm.graphic_card,
                    callback: function ($$v) {
                      _vm.$set(_vm.deviceForm, "graphic_card", $$v)
                    },
                    expression: "deviceForm.graphic_card",
                  },
                },
                _vm._l(_vm.deviceConfigurations.graphic_card, function (item) {
                  return _c("el-option", {
                    key: item,
                    attrs: { label: item, value: item },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "显示器", prop: "display" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "320px" },
                  attrs: {
                    disabled: _vm.basicEditDisabled(),
                    placeholder: "请选择显示器",
                  },
                  model: {
                    value: _vm.deviceForm.display,
                    callback: function ($$v) {
                      _vm.$set(_vm.deviceForm, "display", $$v)
                    },
                    expression: "deviceForm.display",
                  },
                },
                _vm._l(_vm.deviceConfigurations.display, function (item) {
                  return _c("el-option", {
                    key: item,
                    attrs: { label: item, value: item },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "CPU", prop: "cpu" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "320px" },
                  attrs: {
                    disabled: _vm.basicEditDisabled(),
                    placeholder: "请选择 CPU",
                  },
                  model: {
                    value: _vm.deviceForm.cpu,
                    callback: function ($$v) {
                      _vm.$set(_vm.deviceForm, "cpu", $$v)
                    },
                    expression: "deviceForm.cpu",
                  },
                },
                _vm._l(_vm.deviceConfigurations.cpu, function (item) {
                  return _c("el-option", {
                    key: item,
                    attrs: { label: item, value: item },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "内存", prop: "ram" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "320px" },
                  attrs: {
                    disabled: _vm.basicEditDisabled(),
                    placeholder: "请选择内存",
                  },
                  model: {
                    value: _vm.deviceForm.ram,
                    callback: function ($$v) {
                      _vm.$set(_vm.deviceForm, "ram", $$v)
                    },
                    expression: "deviceForm.ram",
                  },
                },
                _vm._l(_vm.deviceConfigurations.ram, function (item) {
                  return _c("el-option", {
                    key: item,
                    attrs: { label: item, value: item },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "计费规则", prop: "device_price_id" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "320px" },
                  attrs: {
                    disabled: _vm.priceEditDisabled(),
                    placeholder: "请选择计费规则",
                  },
                  model: {
                    value: _vm.deviceForm.device_price_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.deviceForm, "device_price_id", $$v)
                    },
                    expression: "deviceForm.device_price_id",
                  },
                },
                _vm._l(_vm.devicePrices, function (devicePrice) {
                  return _c("el-option", {
                    attrs: { label: devicePrice.title, value: devicePrice.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitLoading },
              on: { click: _vm.submitHandler },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }