"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DateIntToDate = DateIntToDate;
exports.dayIsWeekend = dayIsWeekend;
exports.download = download;
exports.exportExcel = exportExcel;
exports.getMouthDays = getMouthDays;
exports.parseUrl = parseUrl;
require("core-js/modules/es6.object.keys");
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es6.typed.uint8-array");
require("core-js/modules/es7.object.values");
require("core-js/modules/web.dom.iterable");
var _fileSaver = _interopRequireDefault(require("file-saver"));
var _xlsx = _interopRequireDefault(require("xlsx"));
var _moment = _interopRequireDefault(require("moment"));
/**
 * 常用函数
 */

// 导出Excel表格
function exportExcel(json, fields, filename) {
  json.forEach(function (item) {
    for (var i in item) {
      if (fields.hasOwnProperty(i)) {
        item[fields[i]] = item[i];
      }
      delete item[i]; //删除原先的对象属性
    }
  });

  filename = filename + '_' + new Date().getTime();
  var sheetName = filename; //excel的文件名称
  var wb = _xlsx.default.utils.book_new(); //工作簿对象包含一SheetNames数组，以及一个表对象映射表名称到表对象。XLSX.utils.book_new实用函数创建一个新的工作簿对象。
  var ws = _xlsx.default.utils.json_to_sheet(json, {
    header: Object.values(fields)
  }); //将JS对象数组转换为工作表。
  wb.SheetNames.push(sheetName);
  wb.Sheets[sheetName] = ws;
  var defaultCellStyle = {
    font: {
      name: "Verdana",
      sz: 13,
      color: "FF00FF88"
    },
    fill: {
      fgColor: {
        rgb: "FFFFAA00"
      }
    }
  }; //设置表格的样式
  var wopts = {
    bookType: 'xlsx',
    bookSST: false,
    type: 'binary',
    cellStyles: true,
    defaultCellStyle: defaultCellStyle,
    showGridLines: false
  }; //写入的样式
  var wbout = _xlsx.default.write(wb, wopts);
  var blob = new Blob([s2ab(wbout)], {
    type: 'application/octet-stream'
  });
  _fileSaver.default.saveAs(blob, filename + '.xlsx');
}
var s2ab = function s2ab(s) {
  if (typeof ArrayBuffer !== 'undefined') {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  } else {
    var _buf = new Array(s.length);
    for (var _i = 0; _i != s.length; ++_i) _buf[_i] = s.charCodeAt(_i) & 0xFF;
    return _buf;
  }
};
function DateIntToDate(value) {
  if (!value) return '';
  var date = value.toString();
  return date.slice(0, 4) + '-' + date.slice(4, 6) + '-' + date.slice(6, 8);
}
function parseUrl(query) {
  var isArray = function isArray(obj) {
    return Object.prototype.toString.call(obj) === "[object Array]";
  };
  var url = Object.keys(query).map(function (key) {
    if (isArray(query[key])) {
      var iUrl = "";
      query[key].forEach(function (item) {
        iUrl = iUrl + key + "[]=" + item + "&";
      });
      return iUrl.trim("&");
    } else {
      var value = query[key];
      if (query[key] == null || query[key] == undefined) {
        value = "";
      }
      return encodeURIComponent(key) + "=" + encodeURIComponent(value);
    }
  }).join("&");
  return url;
}
function download(iUrl, query) {
  var isArray = function isArray(obj) {
    return Object.prototype.toString.call(obj) === '[object Array]';
  };
  var url = Object.keys(query).map(function (key) {
    if (isArray(query[key])) {
      var iUrl = '';
      query[key].forEach(function (item) {
        iUrl = iUrl + key + '[]=' + item + '&';
      });
      return iUrl.trim('&');
    } else {
      return encodeURIComponent(key) + "=" + encodeURIComponent(query[key]);
    }
  }).join("&");
  window.open(iUrl + '?' + url);
}

// 获取指定日所在月的所有日期
function getMouthDays(day) {
  var monthStart = (0, _moment.default)(day).startOf('month');
  var monthEndDay = (0, _moment.default)(day).endOf('month').format('YYYY-MM-DD');
  var days = [];
  while (monthStart.format('YYYY-MM-DD') <= monthEndDay) {
    days.push(monthStart.format('YYYY-MM-DD'));
    monthStart = monthStart.add(1, 'days');
  }
  return days;
}

// 判断指定日期是否为
function dayIsWeekend(day) {
  var weekday = (0, _moment.default)(day).weekday();
  if (weekday == 5 || weekday == 6) {
    return true;
  }
  return false;
}