"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CouponList = CouponList;
exports.addCoupon = addCoupon;
exports.addGroupCoupon = addGroupCoupon;
exports.couponAll = couponAll;
exports.couponManualList = couponManualList;
exports.deleteCoupon = deleteCoupon;
exports.editGroupCoupon = editGroupCoupon;
exports.generateCode = generateCode;
exports.getManualList = getManualList;
exports.groupList = groupList;
exports.groupSimpleList = groupSimpleList;
exports.recoverCoupon = recoverCoupon;
exports.updateCoupon = updateCoupon;
var _request = _interopRequireDefault(require("@/utils/request"));
function CouponList(id, query) {
  return (0, _request.default)({
    url: "/couponList/".concat(id),
    method: 'get',
    params: query
  });
}
function couponManualList() {
  return (0, _request.default)({
    url: '/couponManualList',
    method: 'get'
  });
}
function couponAll() {
  return (0, _request.default)({
    url: '/couponAll',
    method: 'get'
  });
}
function getManualList() {
  return (0, _request.default)({
    url: '/getManualList',
    method: 'get'
  });
}
function addCoupon(data) {
  return (0, _request.default)({
    url: '/coupon',
    method: 'post',
    data: data
  });
}
function updateCoupon(data) {
  return (0, _request.default)({
    url: '/coupon',
    method: 'put',
    data: data
  });
}
function deleteCoupon(data) {
  return (0, _request.default)({
    url: '/coupon',
    method: 'delete',
    data: data
  });
}
function recoverCoupon(data) {
  return (0, _request.default)({
    url: '/recoverCoupon',
    method: 'put',
    data: data
  });
}
function generateCode(id) {
  return (0, _request.default)({
    url: '/generateCode/' + id,
    method: 'post'
  });
}
function addGroupCoupon(data) {
  return (0, _request.default)({
    url: '/couponGroup',
    method: 'post',
    data: data
  });
}
function editGroupCoupon(id, data) {
  return (0, _request.default)({
    url: '/couponGroup/' + id,
    method: 'put',
    data: data
  });
}
function groupList(query) {
  return (0, _request.default)({
    url: '/couponGroups',
    method: 'get',
    params: query
  });
}
function groupSimpleList() {
  return (0, _request.default)({
    url: '/groupSimpleList',
    method: 'get'
  });
}