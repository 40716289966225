"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _hotel = require("@/api/hotel");
var _moment = _interopRequireDefault(require("moment"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'DepositDeductDetail',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    hotelId: {
      type: [String, Number]
    }
  },
  watch: {
    visible: function visible() {
      this.detailVisible = this.visible;
      if (this.visible) {
        this.getDetail();
      }
    },
    hotelId: function hotelId() {}
  },
  data: function data() {
    return {
      detailVisible: false,
      loading: true,
      detail: {
        deposit_amount: "--",
        // 总额
        to_deposit_amount: "--",
        // 待抵扣
        withholding_deduct_amount: 0,
        // 预扣，未审核
        deducts: [] // 抵扣记录
      },

      moment: _moment.default
    };
  },
  created: function created() {},
  methods: {
    resetDetail: function resetDetail() {
      this.detail = {
        deposit_amount: "--",
        // 总额
        to_deposit_amount: "--",
        // 待抵扣
        withholding_deduct_amount: 0,
        // 预扣，未审核
        deducts: [] // 抵扣记录
      };
    },
    getDetail: function getDetail() {
      var _this = this;
      this.loading = true;
      (0, _hotel.getHotelDepositDeductSummary)(this.hotelId).then(function (res) {
        var data = res.data;
        _this.detail.deposit_amount = data.deposit_amount;
        _this.detail.to_deposit_amount = data.to_deposit_amount;
        _this.detail.withholding_deduct_amount = data.withholding_deduct_amount;
        _this.detail.deducts = data.deducts;
        _this.loading = false;
      });
    },
    closeHandler: function closeHandler() {
      this.detailVisible = false;
      this.resetDetail();
      this.$emit("update:visible", this.detailVisible);
    },
    handleDetailRedirect: function handleDetailRedirect(record) {
      this.closeHandler();
      var billType = '';
      if (record.hotel_mode == 3) {
        billType = 'permutation';
      } else if (record.hotel_mode == 6) {
        billType = 'soft';
      }
      this.$router.push({
        path: "/bill/week",
        query: {
          bill_type: billType,
          hotel_id: record.hotel_id,
          bill_start_date: record.start_date_fmt
        }
      });
    }
  }
};
exports.default = _default;