"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _axios = _interopRequireDefault(require("axios"));
var _elementUi = require("element-ui");
var _store = _interopRequireDefault(require("@/store"));
var _auth = require("@/utils/auth");
// create an axios instance
var service = _axios.default.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // url = base url + request url
  //baseURL: 'http://hotel.com/admin',
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 10000 // request timeout
});

// request interceptor
service.interceptors.request.use(function (config) {
  // do something before request is sent
  config.headers['X-Requested-With'] = 'XMLHttpRequest';
  if (_store.default.getters.token) {
    // let each request carry token
    // ['X-Token'] is a custom headers key
    // please modify it according to the actual situation
    config.headers['Authorization'] = 'Bearer ' + (0, _auth.getToken)(); // 让每个请求携带token--['X-Token']为自定义key 请根据实际情况自行修改
  }

  return config;
}, function (error) {
  // do something with request error
  console.log(error); // for debug
  return Promise.reject(error);
});

// response interceptor
service.interceptors.response.use(
/**
 * If you want to get http information such as headers or status
 * Please return  response => response
*/

/**
 * Determine the request status by custom code
 * Here is just an example
 * You can also judge the status by HTTP Status Code
 */
function (response) {
  var res = response.data;
  // console.log(res)
  // if the custom code is not 20000, it is judged as an error.
  if (res.code !== 0) {
    (0, _elementUi.Message)({
      message: res.msg || 'Error',
      type: 'error',
      duration: 5 * 1000
    });

    // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
    if (res.code === 50008 || res.code === 50012 || res.code === 50014 || res.code === 401) {
      // to re-login
      _elementUi.MessageBox.confirm('登录已失效，您可以点击重新登录', '确认', {
        confirmButtonText: '重新登录',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _store.default.dispatch('user/resetToken').then(function () {
          location.reload();
        });
      });
    }
    return Promise.reject(res.msg);
  } else {
    return res;
  }
}, function (error) {
  console.log('err' + error); // for debug
  (0, _elementUi.Message)({
    message: error,
    type: 'error',
    duration: 5 * 1000
  });
  return Promise.reject(error);
});
var _default = service;
exports.default = _default;