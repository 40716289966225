var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-upload",
        {
          attrs: {
            action: _vm.uploadUrl,
            "list-type": "picture-card",
            headers: _vm.header,
            "on-remove": _vm.handleRemove,
            "file-list": _vm.imageList,
            "on-success": _vm.handleUploadSuccess,
          },
        },
        [_c("i", { staticClass: "el-icon-plus" })]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }