"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "ButtonFile",
  emits: ["input"],
  props: {
    showIcon: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: "el-icon-upload2"
    },
    title: {
      type: String,
      default: "上传文件"
    },
    multiple: {
      type: Boolean,
      default: false
    },
    accept: {
      type: String,
      default: ""
    },
    value: [Array, Object, FileList]
  },
  data: function data() {
    return {
      files: []
    };
  },
  mounted: function mounted() {},
  methods: {
    clickFile: function clickFile() {
      this.$refs.fileInput.dispatchEvent(new MouseEvent('click'));
    },
    fileChangeHandler: function fileChangeHandler(e) {
      this.files = e.target.files || e.dataTransfer.files;
      this.$emit('input', this.files);
    },
    remoceFile: function remoceFile(k) {
      // 目前只支持单文件操作
      this.$refs.fileInput.value = '';
      this.files = [];
      this.$emit('input', this.files);
    }
  }
};
exports.default = _default;