"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _auth = require("@/utils/auth");
var _common = require("@/api/common");
var _net_bar = require("@/api/net_bar");
var _ButtonFile = _interopRequireDefault(require("@/components/ButtonFile"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "NetBarFinanceOrderImportButton",
  components: {
    ButtonFile: _ButtonFile.default
  },
  props: {},
  watch: {
    importFormFile: function importFormFile(val, oldVal) {
      this.importForm.file = val;
      this.$refs['uploadFile'].clearValidate();
    }
  },
  data: function data() {
    return {
      dialogVisible: false,
      channel: [{
        id: 1,
        title: "抖音"
      }, {
        id: 2,
        title: "美团"
      }],
      header: {
        Authorization: "Bearer " + (0, _auth.getToken)()
      },
      uploadUrl: '',
      importForm: {
        order_channel: "",
        file: []
      },
      importFormFile: [],
      importFormRules: {
        order_channel: [{
          required: true,
          message: '请选择渠道',
          trigger: 'blur'
        }],
        file: [{
          required: true,
          message: '请选择文件',
          trigger: 'blur'
        }]
      },
      importFormConfirmLoading: false
    };
  },
  mounted: function mounted() {
    this.uploadUrl = (0, _common.adminUpload)();
  },
  methods: {
    importHandler: function importHandler() {
      this.dialogVisible = true;
      this.importFormConfirmLoading = false;
    },
    importCloseHanlder: function importCloseHanlder() {
      this.dialogVisible = false;
      this.$refs['importForm'].resetFields();
      this.importFormFile = [];
    },
    handleUploadSuccess: function handleUploadSuccess(res) {
      this.importForm.file = res.data.filepath;
      this.$refs['uploadFile'].clearValidate();
    },
    handleUploadRemove: function handleUploadRemove(file, fileList) {
      this.importForm.file = "";
    },
    importFormSubmitHandler: function importFormSubmitHandler() {
      var _this = this;
      this.$refs['importForm'].validate(function (valid) {
        if (valid) {
          var formData = new FormData();
          formData.append("file", _this.importForm.file[0]);
          formData.append("order_channel", _this.importForm.order_channel);
          _this.importFormConfirmLoading = true;
          console.log(_this.importForm);
          (0, _net_bar.financeImportThridOrders)(formData).then(function (response) {
            _this.importCloseHanlder();
            _this.$emit('success');
          }).finally(function () {
            _this.importFormConfirmLoading = false;
          });
        }
      });
    }
  }
};
exports.default = _default;