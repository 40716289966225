var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container calendar-list-container" },
    [
      _c(
        "el-form",
        {
          ref: "storeForm",
          attrs: {
            model: _vm.storeForm,
            "label-position": "right",
            "label-width": "145px",
            "label-suffix": ":",
            rules: _vm.formRules,
          },
        },
        [
          _c(
            "div",
            { staticClass: "form-block" },
            [
              _c("h4", [_vm._v("基础资料")]),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 20, md: 16, lg: 12, xl: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "门店名称",
                            required: "",
                            prop: "base.name",
                            maxlength: "20",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入酒店的名称，限20汉字",
                            },
                            model: {
                              value: _vm.storeForm.base.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.storeForm.base, "name", $$v)
                              },
                              expression: "storeForm.base.name",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "计费系统ID",
                            required: "",
                            prop: "base.cd_id",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入计费系统中同一酒店的ID",
                            },
                            model: {
                              value: _vm.storeForm.base.cd_id,
                              callback: function ($$v) {
                                _vm.$set(_vm.storeForm.base, "cd_id", $$v)
                              },
                              expression: "storeForm.base.cd_id",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "联系人" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入联系人" },
                            model: {
                              value: _vm.storeForm.base.contacts,
                              callback: function ($$v) {
                                _vm.$set(_vm.storeForm.base, "contacts", $$v)
                              },
                              expression: "storeForm.base.contacts",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "联系号码" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入手机号码" },
                            model: {
                              value: _vm.storeForm.base.tel,
                              callback: function ($$v) {
                                _vm.$set(_vm.storeForm.base, "tel", $$v)
                              },
                              expression: "storeForm.base.tel",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          ref: "sotreBaseThumbnail",
                          attrs: {
                            label: "门店图片",
                            required: "",
                            prop: "base.thumbnail",
                          },
                        },
                        [
                          _c(
                            "el-upload",
                            {
                              staticClass: "avatar-uploader",
                              attrs: {
                                action: _vm.uploadUrl,
                                headers: _vm.header,
                                "show-file-list": false,
                                multiple: false,
                                "on-success": _vm.handleImageSuccess,
                              },
                            },
                            [
                              _vm.storeForm.base.thumbnail
                                ? _c("img", {
                                    staticClass: "avatar",
                                    staticStyle: { "max-width": "500px" },
                                    attrs: {
                                      src: _vm.storeForm.base.thumbnail,
                                    },
                                  })
                                : _c("i", {
                                    staticClass:
                                      "el-icon-plus avatar-uploader-icon",
                                  }),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          ref: "sotreBaseDistrict",
                          attrs: {
                            label: "地区",
                            required: "",
                            prop: "base.district",
                          },
                        },
                        [
                          _c("v-distpicker", {
                            attrs: {
                              province: _vm.storeForm.base.province,
                              city: _vm.storeForm.base.city,
                              area: _vm.storeForm.base.district,
                            },
                            on: { selected: _vm.onStoreDistSelected },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "详细地址",
                            required: "",
                            maxlength: "100",
                            prop: "base.address",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              rows: 3,
                              placeholder:
                                "请输入酒店的详细地址信息，精确到街道号,不超过100汉字",
                            },
                            model: {
                              value: _vm.storeForm.base.address,
                              callback: function ($$v) {
                                _vm.$set(_vm.storeForm.base, "address", $$v)
                              },
                              expression: "storeForm.base.address",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "经度",
                            required: "",
                            prop: "base.lng",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入经度" },
                            model: {
                              value: _vm.storeForm.base.lng,
                              callback: function ($$v) {
                                _vm.$set(_vm.storeForm.base, "lng", $$v)
                              },
                              expression: "storeForm.base.lng",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "纬度",
                            required: "",
                            prop: "base.lat",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入纬度" },
                            model: {
                              value: _vm.storeForm.base.lat,
                              callback: function ($$v) {
                                _vm.$set(_vm.storeForm.base, "lat", $$v)
                              },
                              expression: "storeForm.base.lat",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "门店数据来源", required: "" } },
                        [
                          _c(
                            "el-row",
                            { attrs: { type: "flex", gutter: 5 } },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { prop: "base.settlement_type" } },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            filterable: "",
                                            placeholder: "请选择数据来源",
                                          },
                                          model: {
                                            value:
                                              _vm.storeForm.base
                                                .settlement_type,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.storeForm.base,
                                                "settlement_type",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "storeForm.base.settlement_type",
                                          },
                                        },
                                        _vm._l(
                                          _vm.settlementTypes,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.value,
                                              attrs: {
                                                label: item.label,
                                                value: item.value,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.storeForm.base.settlement_type == 1
                                ? _c(
                                    "el-col",
                                    { attrs: { span: 12 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { prop: "base.yjf_account" } },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "请输入账号",
                                            },
                                            model: {
                                              value:
                                                _vm.storeForm.base.yjf_account,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.storeForm.base,
                                                  "yjf_account",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "storeForm.base.yjf_account",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "第三方平台门店ID", prop: "o2o_ids" },
                        },
                        [
                          _vm._l(_vm.o2oTypes, function (item) {
                            return [
                              _c(
                                "div",
                                { key: item.id, staticClass: "o2o-ids" },
                                [
                                  _c("div", { staticClass: "o2o-label" }, [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(item.label) +
                                        "：\n                "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("el-input", {
                                    attrs: {
                                      clall: "o2o-input",
                                      placeholder: "请输入门店ID",
                                    },
                                    model: {
                                      value:
                                        _vm.storeForm.base.o2o_ids[item.value],
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.storeForm.base.o2o_ids,
                                          item.value,
                                          $$v
                                        )
                                      },
                                      expression:
                                        "storeForm.base.o2o_ids[item.value]",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          }),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "门店状态",
                            required: "",
                            prop: "base.status",
                          },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.storeForm.base.status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.storeForm.base, "status", $$v)
                                },
                                expression: "storeForm.base.status",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("正常"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 0 } }, [
                                _vm._v("锁定"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "form-block" },
            [
              _c("h4", [_vm._v("结算信息")]),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 20, md: 16, lg: 12, xl: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "飞火管理费",
                            required: "",
                            prop: "settlement.is_mgt_fee",
                          },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.storeForm.settlement.is_mgt_fee,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.storeForm.settlement,
                                    "is_mgt_fee",
                                    $$v
                                  )
                                },
                                expression: "storeForm.settlement.is_mgt_fee",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("是"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 0 } }, [
                                _vm._v("否"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.storeForm.settlement.is_mgt_fee
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "管理费比例",
                                required: "",
                                prop: "settlement.is_mgt_fee",
                              },
                            },
                            [
                              _c("el-slider", {
                                staticClass: "el-slider-input-percent",
                                attrs: { "show-input": "" },
                                model: {
                                  value: _vm.storeForm.settlement.mgt_fee_ratio,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.storeForm.settlement,
                                      "mgt_fee_ratio",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "storeForm.settlement.mgt_fee_ratio",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "订单分佣",
                            required: "",
                            prop: "settlement.is_order_commission",
                          },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value:
                                  _vm.storeForm.settlement.is_order_commission,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.storeForm.settlement,
                                    "is_order_commission",
                                    $$v
                                  )
                                },
                                expression:
                                  "storeForm.settlement.is_order_commission",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("是"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 0 } }, [
                                _vm._v("否"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.storeForm.settlement.is_order_commission
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "商家分佣比例",
                                required: "",
                                prop: "settlement.is_order_commission",
                              },
                            },
                            [
                              _c("el-slider", {
                                staticClass: "el-slider-input-percent",
                                attrs: { "show-input": "" },
                                model: {
                                  value:
                                    _vm.storeForm.settlement.nb_share_ratio,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.storeForm.settlement,
                                      "nb_share_ratio",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "storeForm.settlement.nb_share_ratio",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "form-block" },
            [
              _c("h4", [_vm._v("财务信息")]),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 20, md: 16, lg: 12, xl: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "账户性质",
                            required: "",
                            prop: "finance.type",
                          },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.storeForm.finance.type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.storeForm.finance, "type", $$v)
                                },
                                expression: "storeForm.finance.type",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("对公"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 2 } }, [
                                _vm._v("对私"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "账户名称",
                            required: "",
                            prop: "finance.account",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入" },
                            model: {
                              value: _vm.storeForm.finance.account,
                              callback: function ($$v) {
                                _vm.$set(_vm.storeForm.finance, "account", $$v)
                              },
                              expression: "storeForm.finance.account",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "银行账号",
                            required: "",
                            prop: "finance.bank_account",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入" },
                            model: {
                              value: _vm.storeForm.finance.bank_account,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.storeForm.finance,
                                  "bank_account",
                                  $$v
                                )
                              },
                              expression: "storeForm.finance.bank_account",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "开户行",
                            required: "",
                            prop: "finance.bank",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入" },
                            model: {
                              value: _vm.storeForm.finance.bank,
                              callback: function ($$v) {
                                _vm.$set(_vm.storeForm.finance, "bank", $$v)
                              },
                              expression: "storeForm.finance.bank",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          ref: "sotreFinanceBankCity",
                          attrs: {
                            label: "开户行所在省市",
                            required: "",
                            prop: "finance.bank_city",
                          },
                        },
                        [
                          _c("v-distpicker", {
                            attrs: {
                              province: _vm.storeForm.finance.bank_province,
                              city: _vm.storeForm.finance.bank_city,
                              "hide-area": "",
                            },
                            on: { selected: _vm.onBankDistSelected },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.storeForm.finance.type == 2
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "收款人身份证号",
                                required: "",
                                prop: "finance.idcard",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入" },
                                model: {
                                  value: _vm.storeForm.finance.idcard,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.storeForm.finance,
                                      "idcard",
                                      $$v
                                    )
                                  },
                                  expression: "storeForm.finance.idcard",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "text-center ml" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.formSubmitLoading },
              on: { click: _vm.submitHandler },
            },
            [_vm._v("\n      提交\n    ")]
          ),
          _vm._v(" "),
          _c("el-button", { on: { click: _vm.cancel } }, [
            _vm._v("\n      取 消\n    "),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }