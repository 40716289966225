"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _net_bar = require("@/api/net_bar");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "NetBarThirdMemberYunjifei",
  props: {
    storeId: {
      type: [String, Number]
    }
  },
  data: function data() {
    return {
      list: [],
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 20,
        card_id: ""
      },
      pagination: "",
      dialogVisible: false,
      redirectForm: {
        card_id: "",
        date: "",
        order_type: "boot"
      }
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      (0, _net_bar.fetchMemberList)(this.storeId, this.listQuery).then(function (response) {
        var data = response.data;
        _this.list = data.data;
        _this.pagination = data;
        _this.listQuery.page = data.current_page;
        _this.listLoading = false;
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    handleReset: function handleReset() {
      this.listQuery.card_id = "";
      this.handleFilter();
    },
    showRedirectHandler: function showRedirectHandler(record) {
      this.redirectForm.card_id = record.id;
      this.dialogVisible = true;
    },
    closeHanlder: function closeHanlder() {
      this.dialogVisible = false;
      this.redirectForm.card_id = "";
      this.redirectForm.date = "";
      this.redirectForm.order_type = "boot";
    },
    redirectHandler: function redirectHandler() {
      var to = "/net_bar/order/index";
      if (this.redirectForm.order_type == "recharge") {
        to = "/net_bar/recharge_order/index";
      }
      var startDate = "";
      var endDate = "";
      if (this.redirectForm.date) {
        startDate = this.redirectForm.date[0];
        endDate = this.redirectForm.date[1];
      }
      this.$router.push({
        path: to,
        query: {
          nb_id: this.storeId,
          card_id: this.redirectForm.card_id,
          start_date: startDate,
          end_date: endDate
        }
      });
    }
  }
};
exports.default = _default;