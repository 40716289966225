var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          key: "tableList",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            "element-loading-text": "给我一点时间",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "订单编号",
              "class-name": "overflow-on",
              width: "180",
              prop: "order_no",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "门店",
              "class-name": "overflow-on",
              prop: "nb_name",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "设备编号",
              "class-name": "overflow-on",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", [_vm._v(_vm._s(scope.row.device_name))]),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v("（" + _vm._s(scope.row.device_code) + "）"),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "上网时长",
              "class-name": "overflow-on",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [_vm._v(_vm._s(_vm.getUseTime(scope.row)))]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "订单金额",
              "class-name": "overflow-on",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.amount) + "元")])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "状态" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.status == 30
                      ? _c("span", [
                          _c("div", { staticClass: "dot-success" }),
                          _vm._v(_vm._s(scope.row.status_name)),
                        ])
                      : _c("span", [_vm._v(_vm._s(scope.row.status_name))]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "开机时间",
              "class-name": "overflow-on",
              width: "160",
              prop: "started_at",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              fixed: "right",
              label: "操作",
              width: "120",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.detailHandler(scope.row)
                          },
                        },
                      },
                      [_vm._v("\n          查看\n        ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "pagination-container text-right" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              total: _vm.pagination.total,
              "current-page": _vm.listQuery.page,
              "page-sizes": [10, 20, 50],
              "page-size": _vm.listQuery.limit,
              layout: "total, sizes, prev, pager, next, jumper",
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("NetBarOrderDetail", {
        attrs: { orderId: _vm.orderId, visible: _vm.orderDetailVisible },
        on: {
          "update:orderId": function ($event) {
            _vm.orderId = $event
          },
          "update:order-id": function ($event) {
            _vm.orderId = $event
          },
          "update:visible": function ($event) {
            _vm.orderDetailVisible = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }