"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.adjustFinanceBill = adjustFinanceBill;
exports.auditFinanceBillAppeal = auditFinanceBillAppeal;
exports.buildFinanceBill = buildFinanceBill;
exports.buildFinanceOrderReview = buildFinanceOrderReview;
exports.checkFinanceBillExists = checkFinanceBillExists;
exports.deleteFinanceRecoOrder = deleteFinanceRecoOrder;
exports.getCtripDownloadUrl = getCtripDownloadUrl;
exports.getCtripList = getCtripList;
exports.getFinanceBill = getFinanceBill;
exports.getFinanceBillAppeal = getFinanceBillAppeal;
exports.getFinanceBillAppealDownloadUrl = getFinanceBillAppealDownloadUrl;
exports.getFinanceBillAppealList = getFinanceBillAppealList;
exports.getFinanceOrderReviewDownloadUrl = getFinanceOrderReviewDownloadUrl;
exports.getFinanceOrderReviewSummary = getFinanceOrderReviewSummary;
exports.getFinanceOrderReviews = getFinanceOrderReviews;
exports.getFinanceRange = getFinanceRange;
exports.getFinanceRecoOrder = getFinanceRecoOrder;
exports.getFinanceRecoOrderDownloadUrl = getFinanceRecoOrderDownloadUrl;
exports.getFinanceRecoOrderList = getFinanceRecoOrderList;
exports.getFinanceRecoThirdOrderTypes = getFinanceRecoThirdOrderTypes;
exports.getImportTemplate = getImportTemplate;
exports.getMeiTtuanAdjustDownloadUrl = getMeiTtuanAdjustDownloadUrl;
exports.getMeiTtuanAdjustList = getMeiTtuanAdjustList;
exports.getMeiTtuanNormalDownloadUrl = getMeiTtuanNormalDownloadUrl;
exports.getMeiTtuanNormalList = getMeiTtuanNormalList;
exports.getMiniappDownloadUrl = getMiniappDownloadUrl;
exports.getMiniappList = getMiniappList;
exports.getOfflineDownloadUrl = getOfflineDownloadUrl;
exports.getOfflineList = getOfflineList;
exports.importThridOrders = importThridOrders;
exports.resyncOtaOrder = resyncOtaOrder;
exports.syncMiniappOrder = syncMiniappOrder;
exports.updateFinanceRecoOrder = updateFinanceRecoOrder;
exports.updateMeiTtuanAdjustOrder = updateMeiTtuanAdjustOrder;
var _request = _interopRequireDefault(require("@/utils/request"));
var _auth = require("@/utils/auth");
var _helper = require("@/utils/helper");
function getFinanceRange() {
  return (0, _request.default)({
    url: 'finance_reco/date_range',
    method: 'get'
  });
}
function getImportTemplate(token, channel) {
  return "".concat(process.env.VUE_APP_BASE_API, "/finance_reco/template?api_token=").concat(token, "&third_channel=").concat(channel);
}
function importThridOrders(data) {
  return (0, _request.default)({
    url: 'finance_reco/import',
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: data
  });
}
function getFinanceRecoOrderList(params) {
  return (0, _request.default)({
    url: 'finance_reco/orders',
    method: 'get',
    params: params
  });
}
function getFinanceRecoOrderDownloadUrl(query) {
  query.api_token = (0, _auth.getToken)();
  var queryStr = (0, _helper.parseUrl)(query);
  return "".concat(process.env.VUE_APP_BASE_API, "/finance_reco/order_export?").concat(queryStr);
}
function getFinanceRecoOrder(id) {
  return (0, _request.default)({
    url: 'finance_reco/orders/' + id,
    method: 'get'
  });
}
function updateFinanceRecoOrder(id, data) {
  return (0, _request.default)({
    url: 'finance_reco/orders/' + id,
    method: 'put',
    data: data
  });
}
function deleteFinanceRecoOrder(id) {
  return (0, _request.default)({
    url: 'finance_reco/orders/' + id,
    method: 'delete'
  });
}
function getMeiTtuanNormalList(params) {
  return (0, _request.default)({
    url: 'finance_reco/meituan/normal',
    method: 'get',
    params: params
  });
}
function getMeiTtuanNormalDownloadUrl(query) {
  query.api_token = (0, _auth.getToken)();
  var queryStr = (0, _helper.parseUrl)(query);
  return "".concat(process.env.VUE_APP_BASE_API, "/finance_reco/meituan/normal_export?").concat(queryStr);
}
function getMeiTtuanAdjustList(params) {
  return (0, _request.default)({
    url: 'finance_reco/meituan/adjust',
    method: 'get',
    params: params
  });
}
function getMeiTtuanAdjustDownloadUrl(query) {
  query.api_token = (0, _auth.getToken)();
  var queryStr = (0, _helper.parseUrl)(query);
  return "".concat(process.env.VUE_APP_BASE_API, "/finance_reco/meituan/adjust_export?").concat(queryStr);
}
function updateMeiTtuanAdjustOrder(id, data) {
  return (0, _request.default)({
    url: 'finance_reco/meituan/adjust/' + id,
    method: 'put',
    data: data
  });
}
function getCtripList(params) {
  return (0, _request.default)({
    url: 'finance_reco/ctrip',
    method: 'get',
    params: params
  });
}
function getCtripDownloadUrl(query) {
  query.api_token = (0, _auth.getToken)();
  var queryStr = (0, _helper.parseUrl)(query);
  return "".concat(process.env.VUE_APP_BASE_API, "/finance_reco/ctrip_export?").concat(queryStr);
}
function getOfflineList(params) {
  return (0, _request.default)({
    url: 'finance_reco/offline',
    method: 'get',
    params: params
  });
}
function getOfflineDownloadUrl(query) {
  query.api_token = (0, _auth.getToken)();
  var queryStr = (0, _helper.parseUrl)(query);
  return "".concat(process.env.VUE_APP_BASE_API, "/finance_reco/offline_export?").concat(queryStr);
}
function getMiniappList(params) {
  return (0, _request.default)({
    url: 'finance_reco/miniapp',
    method: 'get',
    params: params
  });
}
function getMiniappDownloadUrl(query) {
  query.api_token = (0, _auth.getToken)();
  var queryStr = (0, _helper.parseUrl)(query);
  return "".concat(process.env.VUE_APP_BASE_API, "/finance_reco/miniapp_export?").concat(queryStr);
}
function syncMiniappOrder() {
  return (0, _request.default)({
    url: 'finance_reco/miniapp/sync',
    method: 'post'
  });
}
function getFinanceRecoThirdOrderTypes(params) {
  params = params || {};
  return (0, _request.default)({
    url: "finance_reco/third_order_type",
    method: 'get',
    params: params
  });
}
function getFinanceOrderReviews(params) {
  return (0, _request.default)({
    url: 'finance_reco_reviews',
    method: 'get',
    params: params
  });
}
function getFinanceOrderReviewDownloadUrl(query) {
  query.api_token = (0, _auth.getToken)();
  var queryStr = (0, _helper.parseUrl)(query);
  return "".concat(process.env.VUE_APP_BASE_API, "/finance_reco_review_export?").concat(queryStr);
}
function getFinanceOrderReviewSummary(params) {
  return (0, _request.default)({
    url: 'finance_reco_review_summary',
    method: 'get',
    params: params
  });
}
function buildFinanceOrderReview(data) {
  return (0, _request.default)({
    url: 'finance_reco_review_build',
    method: 'post',
    data: data
  });
}
function resyncOtaOrder(data) {
  return (0, _request.default)({
    url: 'finance_reco/ota_order_resync',
    method: 'post',
    data: data
  });
}
function checkFinanceBillExists(params) {
  return (0, _request.default)({
    url: 'finance_reco_bill_check',
    method: 'get',
    params: params
  });
}
function buildFinanceBill(data) {
  return (0, _request.default)({
    url: 'finance_reco_bill_build',
    method: 'post',
    data: data
  });
}
function getFinanceBill(billId) {
  return (0, _request.default)({
    url: "finance_reco_bills/".concat(billId),
    method: 'get'
  });
}
function adjustFinanceBill(billId, data) {
  return (0, _request.default)({
    url: "finance_reco_bills/".concat(billId, "/adjust"),
    method: 'put',
    data: data
  });
}
function getFinanceBillAppealList(params) {
  return (0, _request.default)({
    url: "finance_reco_bill_appeals",
    method: 'get',
    params: params
  });
}
function getFinanceBillAppealDownloadUrl(params) {
  params.api_token = (0, _auth.getToken)();
  var queryStr = (0, _helper.parseUrl)(params);
  return "".concat(process.env.VUE_APP_BASE_API, "/finance_reco_bill_appeal_export?").concat(queryStr);
}
function getFinanceBillAppeal(billId) {
  return (0, _request.default)({
    url: "finance_reco_bill_appeals/".concat(billId),
    method: 'get'
  });
}
function auditFinanceBillAppeal(billId, data) {
  return (0, _request.default)({
    url: "finance_reco_bill_appeals/".concat(billId, "/audit"),
    method: 'put',
    data: data
  });
}