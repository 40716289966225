"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.searchUser = searchUser;
exports.transactionList = transactionList;
var _request = _interopRequireDefault(require("@/utils/request"));
function searchUser(name) {
  return (0, _request.default)({
    url: '/search/user',
    method: 'get',
    params: {
      name: name
    }
  });
}
function transactionList(query) {
  return (0, _request.default)({
    url: '/transaction/list',
    method: 'get',
    params: query
  });
}