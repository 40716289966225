"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.object.keys");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.number.is-integer");
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _hotel = require("@/api/hotel");
var _room = require("@/api/room");
var _auth = require("@/utils/auth");
var _helper = require("@/utils/helper");
var _common = require("@/api/common");
var _moment = _interopRequireDefault(require("moment"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "HotelRoom",
  data: function data() {
    return {
      uploadUrl: '',
      hotel: {},
      formRules: {
        name: [{
          required: true,
          message: '请输入名称',
          trigger: 'blur'
        }, {
          max: 50,
          message: '长度在50个字符以内',
          trigger: 'blur'
        }],
        tags: [{
          required: true,
          message: '请选择标签',
          trigger: 'blur'
        }],
        price: [{
          required: true,
          message: '请输入周中预订价',
          trigger: 'blur'
        }],
        weekend_price: [{
          required: true,
          message: '请输入周末预订价',
          trigger: 'blur'
        }],
        original_price: [{
          required: true,
          message: '请输入划线价',
          trigger: 'blur'
        }],
        area: [{
          required: true,
          message: '请输入面积',
          trigger: 'blur'
        }],
        "info.graphic_card": [{
          required: true,
          message: '请选择显卡',
          trigger: 'blur'
        }],
        "info.display": [{
          required: true,
          message: '请选择显示器',
          trigger: 'blur'
        }],
        "info.ram": [{
          required: true,
          message: '请选择内存',
          trigger: 'blur'
        }]
      },
      priceRules: {
        date: [{
          required: true,
          message: '请选择分时计价',
          trigger: 'blur'
        }],
        is_part: [{
          required: true,
          message: '请选择分时计价',
          trigger: 'blur'
        }],
        normal_price: [{
          required: true,
          message: '请输入预订价',
          trigger: 'blur'
        }]
        // base_normal_price : [
        //   { required: true, message: '请输入底价', trigger: 'blur' },
        // ],
      },

      pickerBeginDateBefore: {
        disabledDate: function disabledDate(time) {
          return time.getTime() < Date.now() - 86400 * 1000;
        }
      },
      recordVisible: false,
      showPriceRoomId: undefined,
      priceRecordLoading: false,
      recordList: [],
      schedule: [],
      rooms: [],
      expandRowId: '',
      dialogPriceVisible: false,
      dialogFormVisible: false,
      onRoomFormConfirmLoading: false,
      dialogStockVisible: false,
      dialogRoomStatVisible: false,
      dialogTitle: "添加",
      header: {
        Authorization: "Bearer " + (0, _auth.getToken)()
      },
      calendarDate: '2020-01-01',
      nowDate: 1,
      nowMonth: 1,
      nowDay: 0,
      nowYear: 2020,
      calendarMonth: 1,
      calendarType: 'price',
      priceList: [],
      basePriceList: [],
      stockList: [],
      priceForm: {
        tags: [],
        images: [],
        imageList: [],
        date: '',
        normal_price: '',
        week_price: ''
      },
      stockForm: {
        date: [],
        stock: '',
        name: ''
      },
      roomStatForm: {
        date: [],
        name: '',
        open: 1
      },
      roomStatFormRules: {
        date: [{
          required: true,
          message: '请选择时间段',
          trigger: 'blur'
        }],
        open: [{
          required: true,
          message: '请设置房态',
          trigger: 'blur'
        }]
      },
      roomForm: {
        id: '',
        tags: [],
        images: [],
        imageList: [],
        date: '',
        price: '',
        weekend_price: '',
        base_price: '',
        weekend_base_price: '',
        stock: '',
        stat_stock: '',
        type: 1,
        ota_names: {},
        info: {
          ram: '',
          display: '',
          graphic_card: '',
          room_no: ''
        }
      },
      otas: [{
        id: 1,
        title: "美团"
      }, {
        id: 2,
        title: "飞猪"
      }, {
        id: 3,
        title: "携程"
      }, {
        id: 4,
        title: "线下"
      }],
      hotel_id: this.$route.params.id,
      hotel_mode: 1,
      params: {
        hotel_id: this.$route.params.id
      },
      imageList: [],
      CardList: [{
        value: "GTX1060",
        label: "GTX1060"
      }, {
        value: "GTX1660",
        label: "GTX1660"
      }, {
        value: "GTX2060",
        label: "GTX2060"
      }, {
        value: "GTX3060",
        label: "GTX3060"
      }, {
        value: "RTX4060",
        label: "RTX4060"
      }, {
        value: "RTX4060Ti",
        label: "RTX4060Ti"
      }, {
        value: "RTX4070",
        label: "RTX4070"
      }, {
        value: "RTX4090",
        label: "RTX4090"
      }, {
        value: "无",
        label: "无"
      }],
      typeList: [{
        value: 1,
        label: "普通房"
      }, {
        value: 2,
        label: "升级房"
      }],
      displayList: [{
        value: "27寸144hz",
        label: "27寸144hz"
      }, {
        value: "32寸144hz",
        label: "32寸144hz"
      }, {
        value: "32寸165hz",
        label: "32寸165hz"
      }, {
        value: "无",
        label: "无"
      }],
      ramList: [{
        value: "16G",
        label: "16G"
      }, {
        value: "32G",
        label: "32G"
      }, {
        value: "无",
        label: "无"
      }],
      options: [{
        value: "低价保障",
        label: "低价保障"
      }, {
        value: "服务热情",
        label: "服务热情"
      }, {
        value: "高配电脑",
        label: "高配电脑"
      }],
      expands: [0],
      //只展开一行放入当前行id
      room: {},
      roomTodayPrice: {},
      listLoading: false,
      calendarLoad: false,
      isPackage: 0,
      calendarTitle: "价格设置",
      dialogCalendarVisible: false,
      datePickerOptions: {
        disabledDate: function disabledDate(time) {
          return time.getTime() < Date.now() - 86400000;
        }
      },
      batchPriceForm: {
        date: '',
        is_part: 0,
        rooms: {}
      },
      batchPriceRules: {
        date: [{
          required: true,
          message: '请选择调价时段',
          trigger: 'change'
        }],
        is_part: [{
          required: true,
          message: '请选择分时计价规则',
          trigger: 'change'
        }]
      },
      dialogBatchPriceVisible: false,
      dialogRoomPriceChangeVisible: false,
      roomPriceChangeEffectType: ""
    };
  },
  created: function created() {
    this.uploadUrl = (0, _common.adminUpload)();
    this.getHotel();
    this.getList();
    this.getRoomTodayPrices();
    this.calendarDate = new Date();
    this.nowDate = this.calendarDate.getDate();
    this.nowMonth = this.calendarDate.getMonth() + 1;
    this.calendarMonth = this.nowMonth;
    this.nowYear = this.calendarDate.getFullYear();
    this.nowDay = this.calendarDate.getFullYear() * 10000 + (1 + this.calendarDate.getMonth()) * 100 + this.calendarDate.getDate();
  },
  methods: {
    qrCode: function qrCode(id) {
      window.open('/admin/roomQrCode/' + id + '?api_token=' + (0, _auth.getToken)());
    },
    getRowKeys: function getRowKeys(row) {
      return row.id; //这里看这一行中需要根据哪个属性值是id
    },
    showPriceRecord: function showPriceRecord(id) {
      this.showPriceRoomId = id;
      this.getPriceRecord();
      this.recordVisible = true;
    },
    getPriceRecord: function getPriceRecord() {
      var _this = this;
      this.priceRecordLoading = true;
      this.recordList = [];
      (0, _room.getChangePriceRecord)(this.showPriceRoomId).then(function (response) {
        _this.recordList = response.data;
        _this.priceRecordLoading = false;
      });
    },
    getPriceAllRecord: function getPriceAllRecord() {
      var _this2 = this;
      this.recordList = [];
      this.priceRecordLoading = true;
      (0, _room.getChangePriceRecord)(this.showPriceRoomId, {
        all: 1
      }).then(function (response) {
        _this2.recordList = response.data;
        _this2.priceRecordLoading = false;
      });
    },
    getHotel: function getHotel() {
      var _this3 = this;
      (0, _hotel.getHotel)(this.hotel_id).then(function (res) {
        _this3.hotel_mode = res.data.bill.mode;
        // 软包模式，追加规则
        if (_this3.isSoftMode()) {
          _this3.formRules.base_price = [{
            required: true,
            message: '请输入周中底价',
            trigger: 'blur'
          }];
          _this3.formRules.weekend_base_price = [{
            required: true,
            message: '请输入周末底价',
            trigger: 'blur'
          }];
        }
      });
    },
    getList: function getList() {
      var _this4 = this;
      this.listLoading = true;
      (0, _room.fetchList)(this.$route.params.id).then(function (response) {
        _this4.rooms = response.data.list;
        _this4.isPackage = response.data.is_package;
      });
      this.listLoading = false;
    },
    addAction: function addAction() {
      this.roomForm.id = '';
      this.dialogFormVisible = true;
      this.dialogTitle = "添加房间";
    },
    updateAction: function updateAction(row) {
      var _this5 = this;
      this.roomForm.id = row.id;
      this.dialogFormVisible = true;
      this.dialogTitle = "编辑房间";
      (0, _room.getRoom)(row.id).then(function (response) {
        if (response.data.stock < 0) {
          response.data.stock = '';
        }
        if (response.data.stat_stock < 0) {
          response.data.stat_stock = '';
        }
        _this5.roomForm = response.data;
      });
    },
    deleteAction: function deleteAction() {
      this.dialogFormVisible = true;
    },
    onRoomFormClosed: function onRoomFormClosed() {
      this.$refs['roomForm'].resetFields();
      this.roomForm.imageList = [];
      this.roomForm.ota_names = {};
      this.roomForm.price_change_delay = undefined;
    },
    onRoomPriceChangeClosed: function onRoomPriceChangeClosed() {
      this.roomPriceChangeEffectType = "";
    },
    onRoomPriceChangeConfirm: function onRoomPriceChangeConfirm() {
      if (!this.roomPriceChangeEffectType) {
        this.$message.error("请选择生效时间");
        return;
      }
      this.dialogRoomPriceChangeVisible = false;
      this.doUpdateRoom({
        price_change_effect_type: this.roomPriceChangeEffectType
      });
    },
    createRoom: function createRoom() {
      var _this6 = this;
      this.roomForm.hotel_id = this.$route.params.id;
      this.$refs['roomForm'].validate(function (valid) {
        if (valid) {
          if (_this6.roomForm.id) {
            /**
             * 需要判断预订价及房价变动
             * 存在变动时，还需选择修改生效时间
             */
            var originalRoom = undefined;
            _this6.rooms.forEach(function (room) {
              if (room.id == _this6.roomForm.id) {
                originalRoom = room;
              }
            });
            if (originalRoom) {
              if (_this6.roomForm.price != originalRoom.price || _this6.roomForm.weekend_price != originalRoom.weekend_price || _this6.roomForm.base_price != originalRoom.base_price || _this6.roomForm.weekend_base_price != originalRoom.weekend_base_price) {
                // 存在价格变动
                _this6.dialogRoomPriceChangeVisible = true;
                return;
              }
            }
            _this6.doUpdateRoom({});
          } else {
            _this6.onRoomFormConfirmLoading = true;
            (0, _room.addRoom)(_this6.roomForm).then(function (res) {
              if (res.code == 0) {
                _this6.$message.success("添加成功！");
                _this6.getList();
                _this6.dialogFormVisible = !_this6.dialogFormVisible;
              } else {
                _this6.$message.error(res.msg);
              }
            }).finally(function () {
              _this6.onRoomFormConfirmLoading = false;
            });
          }
        }
      });
    },
    doUpdateRoom: function doUpdateRoom(extraData) {
      var _this7 = this;
      extraData = extraData || {};
      var formData = Object.assign({}, JSON.parse(JSON.stringify(this.roomForm)), extraData);
      this.onRoomFormConfirmLoading = true;
      (0, _room.updateRoom)(this.roomForm.id, formData).then(function (res) {
        if (res.code == 0) {
          _this7.$message.success("编辑成功！");
          _this7.getList();
          _this7.dialogFormVisible = !_this7.dialogFormVisible;
        } else {
          _this7.$message.error(res.msg);
        }
      }).finally(function () {
        _this7.onRoomFormConfirmLoading = false;
      });
    },
    handleImageSuccess: function handleImageSuccess(res) {
      if (res.code == 0) {
        this.roomForm.imageList.push(res.data.filepath);
        this.$forceUpdate();
        this.$message.success("上传成功！");
      } else {
        this.$message.error("上传失败");
      }
    },
    handleRemove: function handleRemove(file) {
      // console.log(file, filelist);
      if (file.response) {
        var pic = file.response.data.filepath;
        var index = this.roomForm.imageList.indexOf(pic);
        this.roomForm.imageList.splice(index, 1);
      } else {
        var _pic = file.url.substr(30);
        var _index = this.roomForm.imageList.indexOf(_pic);
        this.roomForm.imageList.splice(_index, 1);
      }
    },
    handleCheck: function handleCheck(row, type) {
      this.calendarType = type;
      this.room = row;
      if (type == "price") {
        this.calendarTitle = "价格设置";
      } else if (type == "price") {
        this.calendarTitle = "库存管理";
      } else if (type == "room_stat") {
        this.calendarTitle = "房态管理";
      }
      this.getCalendarData();
      this.dialogCalendarVisible = true;
    },
    setBatchPriceHandler: function setBatchPriceHandler() {
      // 初始化form
      var rooms = {};
      this.rooms.forEach(function (item) {
        rooms[item.id] = {
          normal_price: "",
          week_price: ""
        };
      });
      this.batchPriceForm = {
        date: "",
        is_part: 0,
        rooms: rooms
      };
      this.dialogBatchPriceVisible = true;
    },
    getRoomPrice: function getRoomPrice(date) {
      var _this8 = this;
      date = (0, _moment.default)(date).format("YYYY-MM-DD");
      this.calendarLoad = true;
      (0, _room.getRoomPrice)(this.room.id, {
        'date': date
      }).then(function (response) {
        _this8.calendarLoad = false;
        var arr = [];
        var room = response.data.room;
        // 区分房间周中及周末售价
        var mouthDays = (0, _helper.getMouthDays)(date);
        response.data.priceList.forEach(function (item, index) {
          arr[index] = item ? item : (0, _helper.dayIsWeekend)(mouthDays[index]) ? room.weekend_price : room.price;
        });
        _this8.priceList = arr;
        if (_this8.isSoftMode()) {
          var baseArr = [];
          response.data.basePriceList.forEach(function (item, index) {
            baseArr[index] = item ? item : (0, _helper.dayIsWeekend)(mouthDays[index]) ? room.weekend_base_price : room.base_price;
          });
          _this8.basePriceList = baseArr;
        }
      });
    },
    getRoomTodayPrices: function getRoomTodayPrices() {
      var _this9 = this;
      (0, _room.getRoomTodayPrices)({
        hotel_id: this.hotel_id
      }).then(function (response) {
        var roomTodayPrice = {};
        response.data.forEach(function (item) {
          roomTodayPrice[item.room_id] = item;
        });
        _this9.roomTodayPrice = roomTodayPrice;
      });
    },
    getRoomTodayPrice: function getRoomTodayPrice(rid) {
      var roomId = rid || this.room.id;
      if (this.roomTodayPrice[roomId]) {
        return this.roomTodayPrice[roomId].price;
      }
      return '';
    },
    getRoomStock: function getRoomStock(date) {
      var _this10 = this;
      this.calendarLoad = true;
      (0, _room.getRoomStock)(this.room.id, {
        'date': date
      }).then(function (response) {
        _this10.calendarLoad = false;
        var arr = [];
        var room = response.data.room;
        response.data.stockList.forEach(function (item, index) {
          var close = response.data.closeList[index] || false;
          var sale = response.data.saleNumList[index] || 0;
          var stock = item !== false ? item : room.stock; // 存在单独设置的库存 -1 0 >=1
          if (close) {
            arr[index] = "<span style=\"color: #F56C6C;\">\u5DF2\u5173\u623F</span>";
          } else {
            if (stock >= 0) {
              if (stock - sale > 0) {
                arr[index] = "<span style=\"color: #67C23A;\">\u5269\u4F59".concat(stock - sale, "</span>/<span color=\"\">\u5DF2\u552E").concat(sale, "</span>");
              } else {
                arr[index] = "满房";
              }
            } else {
              arr[index] = "未设置";
            }
          }
        });
        _this10.stockList = arr;
      });
    },
    getCalendarData: function getCalendarData() {
      this.calendarLoad = true;
      if (this.calendarType == 'price') {
        this.getRoomPrice(this.calendarDate);
      } else if (this.calendarType == 'stock' || this.calendarType == 'room_stat') {
        this.getRoomStock(this.calendarDate);
      }
    },
    closeCalendarToUpdateRoom: function closeCalendarToUpdateRoom() {
      var _this11 = this;
      this.dialogCalendarVisible = false;
      setTimeout(function () {
        _this11.updateAction(_this11.room);
      }, 350); // 延迟执行，防止闪屏
    },
    // 日期切换，刷新日历数据
    refreshPrice: function refreshPrice(data) {
      if (this.calendarLoad) {
        return;
      }
      var dayMonth = parseInt(data.day.slice(5, 7));
      if (dayMonth != this.calendarMonth) {
        // 月份变化,更新日期数据
        this.calendarMonth = dayMonth;
        this.getRoomPrice(data.day);
      } else {
        return true;
      }
    },
    // 日期切换，刷新日历数据
    refreshStock: function refreshStock(data) {
      if (this.calendarLoad) {
        return;
      }
      var dayMonth = parseInt(data.day.slice(5, 7));
      if (dayMonth != this.calendarMonth) {
        // 月份变化,更新日期数据
        this.calendarMonth = dayMonth;
        this.getRoomStock(data.day);
      } else {
        return true;
      }
    },
    setPrice: function setPrice(data, date) {
      var chooseDay = new Date(data.day + " 00:00:00").getTime();
      var today = new Date(new Date().toLocaleDateString()).getTime();
      if (chooseDay < today) {
        return;
      }
      var formatDate = (0, _moment.default)(date).format("YYYY-MM-DD");
      this.priceForm = {
        name: this.room.name,
        date: [formatDate, formatDate],
        is_part: 0
      };
      this.dialogPriceVisible = true;
    },
    putPrice: function putPrice() {
      var _this12 = this;
      this.$refs['priceForm'].validate(function (valid) {
        if (valid) {
          _this12.dialogPriceVisible = true;
          (0, _room.setRoomPrice)(_this12.room.id, _this12.priceForm).then(function (res) {
            if (res.code == 0) {
              _this12.$message.success("编辑成功");
              _this12.dialogPriceVisible = !_this12.dialogPriceVisible;
              _this12.$refs['priceForm'].resetFields();
              _this12.getRoomPrice(_this12.priceForm.date[0]);
              _this12.getRoomTodayPrices();
              (0, _room.fetchList)(_this12.params.hotel_id).then(function (response) {
                _this12.rooms = response.data.list;
              });
            } else {
              _this12.$message.error(res.msg);
            }
          });
        }
      });
    },
    batchPutPrice: function batchPutPrice() {
      var _this13 = this;
      this.$refs['batchPriceForm'].validate(function (valid) {
        if (!valid) {
          return false;
        }
        // 价格相关校验
        var priceCheckFunc = function priceCheckFunc(price) {
          if (price == "") {
            return "";
          }
          var normalPrice = parseFloat(price);
          if (!Number.isInteger(normalPrice) || normalPrice <= 0) {
            return "房型价格必须是大于0的整数";
          }
          return "";
        };
        var roomIds = Object.keys(_this13.batchPriceForm.rooms);
        var hasRoomSet = false;
        for (var i = 0; i < roomIds.length; i++) {
          var roomPrice = _this13.batchPriceForm.rooms[roomIds[i]];
          // 平时价
          var normalPriceCheckErr = priceCheckFunc(roomPrice.normal_price);
          if (normalPriceCheckErr != "") {
            _this13.$message.error(normalPriceCheckErr);
            return false;
          }
          if (_this13.batchPriceForm.is_part) {
            // 区分周末
            var weekPriceCheckErr = priceCheckFunc(roomPrice.week_price);
            if (weekPriceCheckErr != "") {
              _this13.$message.error(weekPriceCheckErr);
              return false;
            }
          }
          if (roomPrice.normal_price != "" || _this13.batchPriceForm.is_part && roomPrice.week_price != "") {
            hasRoomSet = true;
          }
        }
        // 房间价格必填确认
        if (!hasRoomSet) {
          _this13.$message.error("请配置房间价格");
          return false;
        }
        (0, _room.batchSetRoomPrice)(_this13.batchPriceForm).then(function (res) {
          if (res.code == 0) {
            _this13.$message.success("改价成功");
            _this13.dialogBatchPriceVisible = !_this13.dialogBatchPriceVisible;
            _this13.$refs['batchPriceForm'].resetFields();
            (0, _room.fetchList)(_this13.params.hotel_id).then(function (response) {
              _this13.rooms = response.data.list;
              _this13.getRoomTodayPrices();
            });
          } else {
            _this13.$message.error(res.msg);
          }
        });
      });
    },
    setStock: function setStock(data, date) {
      var chooseDay = new Date(data.day + " 00:00:00").getTime();
      var today = new Date(new Date().toLocaleDateString()).getTime();
      if (chooseDay < today) {
        return;
      }
      var formatDate = (0, _moment.default)(date).format("YYYY-MM-DD");
      this.stockForm = {
        name: this.room.name,
        date: [formatDate, formatDate]
      };
      this.dialogStockVisible = true;
    },
    putStock: function putStock() {
      var _this14 = this;
      this.dialogStockVisible = true;
      (0, _room.setRoomStock)(this.room.id, this.stockForm).then(function (res) {
        if (res.code == 0) {
          _this14.$message.success("编辑成功");
          _this14.dialogStockVisible = !_this14.dialogStockVisible;
          _this14.getRoomStock(_this14.stockForm.date[0]);
          (0, _room.fetchList)(_this14.params.hotel_id).then(function (response) {
            _this14.rooms = response.data.list;
          });
        } else {
          _this14.$message.error(res.msg);
        }
      });
    },
    setRoomStat: function setRoomStat(data, date) {
      var chooseDay = new Date(data.day + " 00:00:00").getTime();
      var today = new Date(new Date().toLocaleDateString()).getTime();
      if (chooseDay < today) {
        return;
      }
      var formatDate = (0, _moment.default)(date).format("YYYY-MM-DD");
      this.roomStatForm = {
        name: this.room.name,
        date: [formatDate, formatDate],
        open: 1
      };
      this.dialogRoomStatVisible = true;
    },
    putRoomStat: function putRoomStat() {
      var _this15 = this;
      this.dialogRoomStatVisible = true;
      this.$refs['roomStatForm'].validate(function (valid) {
        if (!valid) {
          return false;
        } else {
          (0, _room.setRoomStat)(_this15.room.id, _this15.roomStatForm).then(function (res) {
            if (res.code == 0) {
              _this15.$message.success("编辑成功");
              _this15.dialogRoomStatVisible = !_this15.dialogRoomStatVisible;
              _this15.getRoomStock(_this15.roomStatForm.date[0]);
              (0, _room.fetchList)(_this15.params.hotel_id).then(function (response) {
                _this15.rooms = response.data.list;
              });
            } else {
              _this15.$message.error(res.msg);
            }
          });
        }
      });
    },
    openRoom: function openRoom(id) {
      var _this16 = this;
      (0, _room.closeRoom)(id).then(function (res) {
        if (res.code == 0) {
          _this16.$message.success("恢复成功！");
          _this16.getList();
        } else {
          _this16.$message.error(res.msg);
        }
      });
    },
    close: function close(id) {
      var _this17 = this;
      this.$confirm('关房后用户不可定当天的该房型，确认关房操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _room.closeRoom)(id).then(function (res) {
          if (res.code == 0) {
            _this17.$message.success("关房成功！");
            _this17.getList();
          } else {
            _this17.$message.error(res.msg);
          }
        });
      }).catch(function () {
        return;
      });
    },
    deleteRoom: function deleteRoom(id) {
      var _this18 = this;
      this.$confirm('删除后将不会再小程序端展示，确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _room.deleteRoom)(id).then(function (res) {
          if (res.code == 0) {
            _this18.$message.success("删除成功！");
            _this18.getList();
          } else {
            _this18.$message.error(res.msg);
          }
        });
      }).catch(function () {
        return;
      });
    },
    renderTipsHeader: function renderTipsHeader(h, _ref) {
      var column = _ref.column;
      return h('div', [h('span', column.label), h('el-tooltip', {
        props: {
          effect: 'dark',
          content: '剩余房量/已售房量',
          placement: 'top'
        }
      }, [h('i', {
        class: 'el-icon-question',
        style: 'color:#409EFF;margin-left:5px;'
      })])]);
    },
    isSoftMode: function isSoftMode() {
      return this.hotel_mode == 6;
    },
    enableShareRatio: function enableShareRatio() {
      var enableModes = [3, 5];
      return enableModes.indexOf(this.hotel_mode) !== -1;
    }
  }
};
exports.default = _default;