"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es6.number.constructor");
var _net_bar = require("@/api/net_bar");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: "NetBarFinanceBillExtraForm",
  components: {},
  props: {
    billId: [Number, String],
    visible: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    }
  },
  watch: {
    billId: function billId() {},
    visible: function visible() {
      this.dialogVisible = this.visible;
      if (this.visible && this.billId) {
        this.getDetail();
      }
    }
  },
  filters: {
    dateFormat: function dateFormat(value) {
      if (!value) return '';
      var date = value.toString();
      return date.slice(0, 4) + '-' + date.slice(4, 6) + '-' + date.slice(6, 8);
    }
  },
  data: function data() {
    var _this = this;
    var validateWaterBarAmount = function validateWaterBarAmount(rule, value, callback) {
      if (_this.extraForm.water_bar.extra_desc != "" && value == "") {
        return callback(new Error('金额不能为空'));
      }
      if (value && isNaN(value)) {
        return callback(new Error('金额请填写数字'));
      }
      if (parseFloat(value) < 0) {
        return callback(new Error('金额不能小于0'));
      }
      callback();
    };
    var validateWaterBarDesc = function validateWaterBarDesc(rule, value, callback) {
      if (_this.extraForm.water_bar.extra_amount != "" && value == "") {
        return callback(new Error('销售次数不能为空'));
      }
      if (value && isNaN(value)) {
        return callback(new Error('销售次数请填写数字'));
      }
      if (parseFloat(value) < 0) {
        return callback(new Error('销售次数不能小于0'));
      }
      callback();
    };
    var validateShowerAmount = function validateShowerAmount(rule, value, callback) {
      if (_this.extraForm.shower.extra_desc != "" && value == "") {
        return callback(new Error('金额不能为空'));
      }
      if (value && isNaN(value)) {
        return callback(new Error('金额请填写数字'));
      }
      if (parseFloat(value) < 0) {
        return callback(new Error('金额不能小于0'));
      }
      callback();
    };
    var validateShowerDesc = function validateShowerDesc(rule, value, callback) {
      if (_this.extraForm.shower.extra_amount != "" && value == "") {
        return callback(new Error('销售次数不能为空'));
      }
      if (value && isNaN(value)) {
        return callback(new Error('销售次数请填写数字'));
      }
      if (parseFloat(value) < 0) {
        return callback(new Error('销售次数不能小于0'));
      }
      callback();
    };
    var validateAdjustAmount = function validateAdjustAmount(rule, value, callback) {
      if (_this.extraForm.adjust && _this.extraForm.adjust.extra_desc != "" && value == "") {
        return callback(new Error('调整金额不能为空'));
      }
      if (value && isNaN(value)) {
        return callback(new Error('调整金额请填写数字'));
      }
      callback();
    };
    var validateAdjustDesc = function validateAdjustDesc(rule, value, callback) {
      if (_this.extraForm.adjust && _this.extraForm.adjust.extra_amount != "" && value == "") {
        return callback(new Error('调整原因不能为空'));
      }
      callback();
    };
    var validateIncomeAdjustAmount = function validateIncomeAdjustAmount(rule, value, callback) {
      if (_this.extraForm.income_adjust && _this.extraForm.income_adjust.extra_desc != "" && value == "") {
        return callback(new Error('调整金额不能为空'));
      }
      if (value && isNaN(value)) {
        return callback(new Error('调整金额请填写数字'));
      }
      callback();
    };
    var validateIncomeAdjustDesc = function validateIncomeAdjustDesc(rule, value, callback) {
      if (_this.extraForm.income_adjust && _this.extraForm.income_adjust.extra_amount != "" && value == "") {
        return callback(new Error('调整原因不能为空'));
      }
      callback();
    };
    return {
      dialogVisible: false,
      formLoading: false,
      submitLoading: false,
      bill: {},
      extraTypes: ["o2o_douyin", "o2o_meituan", "water_bar", "shower", "adjust", "income_adjust"],
      extraForm: {
        o2o_douyin: {
          extra_amount: "",
          extra_desc: ""
        },
        o2o_meituan: {
          extra_amount: "",
          extra_desc: ""
        },
        water_bar: {
          extra_amount: "",
          extra_desc: ""
        },
        shower: {
          extra_amount: "",
          extra_desc: ""
        },
        adjust: {
          extra_amount: "",
          extra_desc: ""
        },
        income_adjust: {
          extra_amount: "",
          extra_desc: ""
        }
      },
      extraFormRules: {
        "o2o_douyin.extra_amount": [{
          required: true,
          message: '请输入金额',
          trigger: 'blur'
        }],
        "o2o_meituan.extra_amount": [{
          required: true,
          message: '请输入金额',
          trigger: 'blur'
        }],
        "water_bar.extra_amount": [{
          validator: validateWaterBarAmount,
          trigger: 'change'
        }],
        "water_bar.extra_desc": [{
          validator: validateWaterBarDesc,
          trigger: 'change'
        }],
        "shower.extra_amount": [{
          validator: validateShowerAmount,
          trigger: 'change'
        }],
        "shower.extra_desc": [{
          validator: validateShowerDesc,
          trigger: 'change'
        }],
        "adjust.extra_amount": [{
          validator: validateAdjustAmount,
          trigger: 'change'
        }],
        "adjust.extra_desc": [{
          validator: validateAdjustDesc,
          trigger: 'change'
        }],
        "income_adjust.extra_amount": [{
          validator: validateIncomeAdjustAmount,
          trigger: 'change'
        }],
        "income_adjust.extra_desc": [{
          validator: validateIncomeAdjustDesc,
          trigger: 'change'
        }]
      }
    };
  },
  mounted: function mounted() {},
  methods: {
    getDetail: function getDetail() {
      var _this2 = this;
      this.formLoading = true;
      (0, _net_bar.fetchFinanceBill)(this.billId).then(function (result) {
        var bill = result.data;
        _this2.bill = bill;
        bill.extras.forEach(function (extra) {
          if (_this2.extraTypes.indexOf(extra.extra_type) >= 0) {
            _this2.extraForm[extra.extra_type] = {
              extra_amount: extra.extra_amount,
              extra_desc: extra.extra_desc
            };
          }
        });
        _this2.formLoading = false;
      });
    },
    closeHanlder: function closeHanlder() {
      this.bill = {};
      this.extraForm.billId = undefined;
      this.$refs['extraForm'].resetFields();
      this.$emit('update:billId', "");
      this.$emit('update:visible', false);
    },
    submitHandler: function submitHandler() {
      var _this3 = this;
      this.$refs['extraForm'].validate(function (valid) {
        if (valid) {
          _this3.doSave();
        }
      });
    },
    doSave: function doSave() {
      var _this4 = this;
      this.submitLoading = true;
      (0, _net_bar.saveFinanceBillExtra)(this.billId, this.extraForm).then(function () {
        _this4.$message.success("操作成功");
        _this4.$emit('success');
        _this4.dialogVisible = false;
      }).finally(function () {
        _this4.submitLoading = false;
      });
    }
  }
};
exports.default = _default2;