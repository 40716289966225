"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _meituanNormal = _interopRequireDefault(require("./meituanNormal.vue"));
var _meituanAdjust = _interopRequireDefault(require("./meituanAdjust.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "FinanceMeituan",
  components: {
    MeituanNormal: _meituanNormal.default,
    MeituanAdjust: _meituanAdjust.default
  },
  data: function data() {
    return {
      billType: "normal"
    };
  },
  created: function created() {},
  methods: {
    typeHandler: function typeHandler() {}
  }
};
exports.default = _default;