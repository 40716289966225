"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.split");
var _imageUpload = _interopRequireDefault(require("./components/imageUpload"));
var _vDistpicker = _interopRequireDefault(require("v-distpicker"));
var _hotel = require("@/api/hotel");
var _auth = require("@/utils/auth");
var _agent = require("@/api/agent");
var _common = require("@/api/common");
var _cbs = require("@/api/cbs");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "HotelForm",
  components: {
    imageUplad: _imageUpload.default,
    VDistpicker: _vDistpicker.default
  },
  data: function data() {
    var _this = this;
    var checkDepositAmount = function checkDepositAmount(rule, value, callback) {
      if (!_this.depositDeductForm.is_deposit_deduct) {
        callback();
        return;
      }
      if (!value) {
        callback(new Error('请输入押金金额'));
        return;
      }
      if (isNaN(parseFloat(value)) || !isFinite(value)) {
        callback(new Error('押金金额只能为数值'));
        return;
      }
      if (value < 0) {
        callback(new Error('押金金额必须大于0'));
        return;
      }
      callback();
    };
    return {
      activeName: 'base',
      tagLimit: 3,
      uploadUrl: '',
      hotel_id: this.$route.params.id || 0,
      list: null,
      imageUrl: "",
      dialogVisible: false,
      hotelForm: {
        area_id: 2,
        // 杭州板块
        thumbnail: '',
        district: '',
        business_area: undefined,
        is_like: undefined,
        is_show: undefined,
        tags: [],
        bill: {},
        ota_names: {},
        ota_ids: {},
        permission: [],
        wxwork_groups: {
          default: {
            robot_key: "",
            mention_accounts: ""
          }
        }
      },
      businessAreaList: [],
      bill: {
        is_agent: 0,
        mgt_mode: 1,
        mode: 1,
        share_ratio: 70,
        bank_province: undefined,
        bank_city: undefined,
        bank: undefined,
        cbs_bank: undefined
      },
      images: [{
        location: "客房",
        imageList: []
      }],
      otas: [{
        id: 1,
        title: "美团"
      }, {
        id: 2,
        title: "飞猪"
      }, {
        id: 3,
        title: "携程"
      }, {
        id: 4,
        title: "线下"
      }],
      wxworkGroups: [{
        tag: "default"
      }],
      areas: [],
      rooms: [{}],
      header: {
        Authorization: "Bearer " + (0, _auth.getToken)()
      },
      listLoading: false,
      dialogImageUrl: "",
      curRowIndex: 0,
      locationList: ["客房", "房型", "设备", "卫生间", "外观", "前台", "走廊"],
      location2: {
        0: "外观",
        1: "房型",
        2: "设备",
        3: "卫生间",
        4: "外观",
        5: "前台",
        6: "走廊"
      },
      options: [{
        value: "144hz电竞屏",
        label: "144hz电竞屏"
      }, {
        value: "吃鸡专用耳机",
        label: "吃鸡专用耳机"
      }, {
        value: "i7八核CPU",
        label: "i7八核CPU"
      }, {
        value: "专业电竞椅",
        label: "专业电竞椅"
      }, {
        value: "1660显卡",
        label: "1660显卡"
      }, {
        value: "游戏键鼠",
        label: "游戏键鼠"
      }, {
        value: "低价保障",
        label: "低价保障"
      }, {
        value: "服务热情",
        label: "服务热情"
      }, {
        value: "高配电脑",
        label: "高配电脑"
      }, {
        value: "未成年人不得入住",
        label: "未成年人不得入住"
      }],
      modeList: [],
      managementModeList: [],
      permissionList: [],
      agentList: [],
      tags: [],
      formRules: {
        area_id: [{
          required: true,
          message: '请选择区域',
          trigger: 'change'
        }],
        name: [{
          required: true,
          message: '请输入名称',
          trigger: 'blur'
        }, {
          max: 30,
          message: '长度在30 个字符以内',
          trigger: 'blur'
        }],
        thumbnail: [{
          required: true,
          message: '请上传缩略图',
          trigger: 'blur'
        }],
        district: [{
          required: true,
          message: '请选择省市区',
          trigger: 'change'
        }],
        address: [{
          required: true,
          message: '请输入具体地址',
          trigger: 'blur'
        }],
        business_area: [{
          required: true,
          message: '请选择商圈',
          trigger: 'change'
        }],
        lng: [{
          required: true,
          message: '请输入酒店经度',
          trigger: 'blur'
        }],
        lat: [{
          required: true,
          message: '请输入酒店纬度',
          trigger: 'blur'
        }],
        is_like: [{
          required: true,
          message: '请选择飞火臻选',
          trigger: 'change'
        }],
        is_show: [{
          required: true,
          message: '请选择是否显示',
          trigger: 'change'
        }],
        is_official: [{
          required: true,
          message: '请选择是否官方店',
          trigger: 'change'
        }],
        is_hot: [{
          required: true,
          message: '请选择是否热门',
          trigger: 'change'
        }]
      },
      billRules: {
        mgt_mode: [{
          required: true,
          message: '请选择经营模式',
          trigger: 'change'
        }],
        mode: [{
          required: true,
          message: '请选择结算模式',
          trigger: 'change'
        }],
        is_agent: [{
          required: true,
          message: '请选择是否有代理商',
          trigger: 'change'
        }],
        agent_id: [{
          required: true,
          message: '请选择代理商名称',
          trigger: 'change'
        }],
        type: [{
          required: true,
          message: '请选择账户性质',
          trigger: 'change'
        }],
        account: [{
          required: true,
          message: '请输入账户名称',
          trigger: 'blur'
        }],
        bank_account: [{
          required: true,
          message: '请输入收款账户银行账号',
          trigger: 'blur'
        }],
        bank: [{
          required: true,
          message: '请选择开户行',
          trigger: 'change'
        }],
        cbs_bank: [{
          required: true,
          message: '请输入收款银行',
          trigger: 'change'
        }],
        bank_province: [{
          required: true,
          message: '请选择开户行所在省',
          trigger: 'change'
        }],
        bank_city: [{
          required: true,
          message: '请选择开户行所在市',
          trigger: 'change'
        }],
        idcard: [{
          required: true,
          message: '请输入收款人身份证号',
          trigger: 'blur'
        }]
      },
      cbsProvinces: [],
      cbsCities: [],
      cbsBanks: [],
      cbsOpenBanks: [],
      isDepositDeductSetted: true,
      isDepositDeductDetail: null,
      depositDeductForm: {
        is_deposit_deduct: 0,
        deposit_amount: null
      },
      depositDeductRules: {
        is_deposit_deduct: [{
          required: true,
          message: '请选择押金抵扣状态',
          trigger: 'blur'
        }],
        deposit_amount: [{
          validator: checkDepositAmount,
          trigger: 'blur'
        }]
      },
      depositDeductSubmitLoading: false,
      commissionDetail: null,
      commissionForm: {
        is_commission: 0,
        settlement_type: 1,
        share_rate: null
      },
      commissionRules: {
        is_commission: [{
          required: true,
          message: '请选择飞火渠道佣金',
          trigger: 'blur'
        }],
        settlement_type: [{
          required: true,
          message: '请选择佣金计算方式',
          trigger: 'blur'
        }],
        share_rate: [{
          required: true,
          message: '请输入佣金比例',
          trigger: 'blur'
        }, {
          type: "number",
          min: 0,
          max: 100,
          message: '佣金比例在0-100之间',
          trigger: 'blur'
        }]
      },
      commissionSettlementTypes: [{
        value: 1,
        label: "固定比例"
      }],
      commissionSubmitLoading: false,
      roles: []
    };
  },
  computed: {
    isSoftMode: function isSoftMode() {
      return this.bill.mode == 6;
    },
    isPermutationMode: function isPermutationMode() {
      return this.bill.mode == 3;
    },
    showShareRatio: function showShareRatio() {
      // 存在商家分润  按比例分成 置换（旧）
      var enableModes = [3, 5];
      return enableModes.indexOf(this.bill.mode) !== -1;
    },
    showAgent: function showAgent() {
      // 存在代理商  按比例分成 授权 置换（旧） 软包模式
      var enableModes = [3, 4, 5, 6];
      return enableModes.indexOf(this.bill.mode) !== -1;
    },
    showAgentRatio: function showAgentRatio() {
      // 存在代理商,可设置房费金额分润  按比例分成 置换（旧） 软包模式
      var enableModes = [3, 5, 6];
      return enableModes.indexOf(this.bill.mode) !== -1;
    },
    showCDAgentRatio: function showCDAgentRatio() {
      // 存在代理商,可设置房费金额分润  授权
      var enableModes = [4];
      return enableModes.indexOf(this.bill.mode) !== -1;
    },
    enbaleDepositDeduct: function enbaleDepositDeduct() {
      // 可配置押金抵扣  按比例分成 软包模式
      var enableModes = [3, 6];
      return enableModes.indexOf(this.bill.mode) !== -1;
    },
    enableOperateDepositDeductRole: function enableOperateDepositDeductRole() {
      // 只有 admin 和 bill 允许编辑押金抵扣
      if (this.roles.indexOf('admin') >= 0 || this.roles.indexOf('bill') >= 0) {
        return true;
      }
      return false;
    }
  },
  created: function created() {
    var _this2 = this;
    if (this.hotel_id) {
      this.getHotel();
    }
    if (!this.isDepositDeductDetail) {
      this.getHotelDepositDeductInfo();
    }
    if (!this.commissionDetail) {
      this.getHotelCommissionInfo();
    }
    this.uploadUrl = (0, _common.adminUpload)();
    (0, _hotel.getManagementModeList)().then(function (res) {
      _this2.managementModeList = res.data;
    });
    (0, _hotel.getPermissionList)().then(function (res) {
      _this2.permissionList = res.data;
    });
    (0, _hotel.getModeList)().then(function (res) {
      _this2.modeList = res.data;
    });
    (0, _agent.getSimpleList)().then(function (response) {
      _this2.agentList = response.data;
    });
    if ((0, _auth.getGrade)() < 1) {
      (0, _hotel.getArea)().then(function (response) {
        _this2.areas = response.data;
      });
    }
    this.roles = (0, _auth.getRoles)();
  },
  methods: {
    onDistrictSelected: function onDistrictSelected(data) {
      var _this3 = this;
      if (data.city.value != this.hotelForm.city) {
        (0, _hotel.getBusinessArea)({
          city: data.city.value
        }).then(function (response) {
          _this3.businessAreaList = response.data;
        });
      }
      this.hotelForm.province = data.province.value;
      this.hotelForm.city = data.city.value;
      this.hotelForm.district = data.area.value;
      this.$refs['hotelFormDistrict'].clearValidate();
    },
    getHotel: function getHotel() {
      var _this4 = this;
      (0, _hotel.getHotel)(this.hotel_id).then(function (res) {
        _this4.hotelForm = res.data;
        _this4.images = res.data.imageList;
        _this4.bill = Object.assign({}, _this4.bill, res.data.bill);
        // 代理商信息需要单独处理
        if (!_this4.bill.agent_id || _this4.bill.agent_id == 0) {
          _this4.bill.agent_id = ''; // 重置默认0值
        }
      });
    },
    handleImageSuccess: function handleImageSuccess(res) {
      if (res.code == 0) {
        this.hotelForm.thumbnail = res.data.filepath;
        this.$refs['hotelFormThumbnail'].clearValidate();
        this.$message.success("上传成功！");
      } else {
        this.$message.error("上传失败");
      }
    },
    handleFullPicSuccess: function handleFullPicSuccess(res) {
      if (res.code == 0) {
        this.hotelForm.full_pic = res.data.filepath;
        this.$message.success("上传成功！");
      } else {
        this.$message.error("上传失败");
      }
    },
    createHotel: function createHotel() {
      var _this5 = this;
      var params = this.hotelForm;
      params.images = this.images;
      this.$refs['hotelForm'].validate(function (valid) {
        if (!valid) {
          _this5.$message.error("酒店必填信息缺失");
          return false;
        } else {
          (0, _hotel.addHotel)(params).then(function (res) {
            if (res.code == 0) {
              _this5.hotel_id = res.data.id;
              _this5.$message.success("添加成功,请补充财务信息");
              _this5.activeName = 'ext';
              _this5.getBankProvinces();
            } else {
              _this5.$message.error(res.msg);
            }
          });
        }
      });
    },
    updateHotel: function updateHotel() {
      var _this6 = this;
      var params = this.hotelForm;
      params.images = this.images;
      this.$refs['hotelForm'].validate(function (valid) {
        if (!valid) {
          _this6.$message.error("酒店必填信息缺失");
          return false;
        } else {
          (0, _hotel.updateHotel)(_this6.hotel_id, params).then(function (res) {
            if (res.code == 0) {
              _this6.$message.success("更新成功！");
              _this6.activeName = 'ext';
              _this6.getBankProvinces();
              // this.$router.push('/hotel/index');
            } else {
              _this6.$message.error(res.msg);
            }
          });
        }
      });
    },
    updateHotelInfo: function updateHotelInfo() {
      var _this7 = this;
      if (this.hotel_id < 1) {
        this.$message.error("请先填写基本信息");
        return false;
      }
      var params = this.bill;
      this.$refs['hotelInfoForm'].validate(function (valid) {
        if (!valid) {
          return false;
        }
        // 代理商信息单独校验
        if (params.is_agent) {
          if (params.share_ratio + params.agent_ratio > 100) {
            _this7.$message.error("分润比例和不能超过100%");
            return;
          }
        }
        (0, _hotel.updateHotelInfo)(_this7.hotel_id, params).then(function (res) {
          if (res.code == 0) {
            _this7.$message.success("更新成功！");
            _this7.$router.push('/hotel/index');
          } else {
            _this7.$message.error(res.msg);
          }
        });
      });
    },
    cancel: function cancel() {
      this.$router.push('/hotel/index');
    },
    addContentUrl: function addContentUrl() {
      this.images.push({});
    },
    delContentUrl: function delContentUrl(index) {
      this.images.splice(index, 1);
    },
    addImages: function addImages(key) {
      this.images.push({});
    },
    delImage: function delImage(key) {
      this.images.pop();
    },
    uploadHotelImageSuccess: function uploadHotelImageSuccess(event) {
      console.log("收到子组件");
      console.log(event);
      var fileList = event.fileList;
      var imageList = [];
      for (var i = 0; i < fileList.length; i++) {
        var imagePath = fileList[i].response != undefined ? fileList[i].response.data.filepath : fileList[i].url;
        imageList.push(imagePath);
      }
      this.images[event.type].imageList = imageList;
      console.log("图片列表");
      console.log(this.images);
    },
    deleteHotelImageSuccess: function deleteHotelImageSuccess(event) {
      console.log("收到子组件");
      console.log(event);
      var fileList = event.fileList;
      var imageList = [];
      for (var i = 0; i < fileList.length; i++) {
        var imagePath = fileList[i].response != undefined ? fileList[i].response.data.filepath : fileList[i].url;
        imageList.push(imagePath);
      }
      this.images[event.type].imageList = imageList;
      console.log(this.images);
    },
    onCbsBankChange: function onCbsBankChange() {
      this.bill.bank = undefined;
      if (this.showBillBank()) {
        this.getOpenBanks();
      }
    },
    onBankProvinceChange: function onBankProvinceChange(province) {
      this.cbsCities = [];
      if (this.showBillBank()) {
        this.bill.bank = undefined;
      }
      this.bill.bank_city = undefined;
      this.getBankCities(province);
    },
    onBankCityChange: function onBankCityChange() {
      if (this.showBillBank()) {
        this.bill.bank = undefined;
        this.getOpenBanks();
      }
    },
    getBankProvinces: function getBankProvinces() {
      var _this8 = this;
      if (this.cbsProvinces.length > 0) {
        return;
      }
      (0, _cbs.getProvinceList)().then(function (result) {
        _this8.cbsProvinces = result.data;
      });
    },
    getBankCities: function getBankCities(province) {
      var _this9 = this;
      (0, _cbs.getCityList)(province).then(function (result) {
        _this9.cbsCities = result.data;
      });
    },
    getBanks: function getBanks() {
      var _this10 = this;
      if (this.cbsBanks.length > 0) {
        return;
      }
      (0, _cbs.getBankList)().then(function (result) {
        _this10.cbsBanks = result.data;
      });
    },
    getOpenBanks: function getOpenBanks() {
      var _this11 = this;
      (0, _cbs.getOpenBankList)({
        bank_name: this.bill.cbs_bank.split('@')[0],
        province: this.bill.bank_province,
        city: this.bill.bank_city
      }).then(function (result) {
        _this11.cbsOpenBanks = result.data;
      });
    },
    showBillBank: function showBillBank() {
      return this.bill.cbs_bank && this.bill.bank_province && this.bill.bank_city;
    },
    getHotelDepositDeductInfo: function getHotelDepositDeductInfo() {
      var _this12 = this;
      this.isDepositDeductSetted = true;
      (0, _hotel.getHotelDepositDeductConfig)(this.hotel_id).then(function (res) {
        if (!res.data || !res.data.deposits || res.data.deposits.length == 0) {
          _this12.isDepositDeductSetted = false;
        } else {
          _this12.depositDeductForm.is_deposit_deduct = res.data.is_deposit_deduct;
          var depositAmount = 0;
          res.data.deposits.forEach(function (item) {
            depositAmount += item.deposit_amount;
          });
          _this12.depositDeductForm.deposit_amount = depositAmount / 100;
        }
        _this12.isDepositDeductDetail = res.data;
      });
    },
    updateHotelDepositInfo: function updateHotelDepositInfo() {
      var _this13 = this;
      if (this.hotel_id < 1) {
        this.$message.error("请先填写基本信息");
        return false;
      }
      if (!this.enbaleDepositDeduct) {
        this.$message.error("当前结算模式不支持押金抵扣设置");
        return false;
      }
      this.$refs['depositDeductForm'].validate(function (valid) {
        if (valid) {
          _this13.depositDeductSubmitLoading = true;
          (0, _hotel.updateHotelDepositDeductConfig)(_this13.hotel_id, _this13.depositDeductForm).then(function (res) {
            // this.getHotelDepositDeductInfo()
            _this13.$message.success("更新成功！");
            _this13.$router.push('/hotel/index');
          }).finally(function () {
            _this13.depositDeductSubmitLoading = false;
          });
        }
      });
    },
    getHotelCommissionInfo: function getHotelCommissionInfo() {
      var _this14 = this;
      (0, _hotel.getHotelCommissionConfig)(this.hotel_id).then(function (res) {
        if (res.data) {
          _this14.commissionForm.is_commission = res.data.is_commission;
          if (res.data.settlement_type) {
            _this14.commissionForm.settlement_type = res.data.settlement_type;
          }
          _this14.commissionForm.share_rate = res.data.share_rate;
        }
        _this14.commissionDetail = res.data;
      });
    },
    updateHotelCommissionInfo: function updateHotelCommissionInfo() {
      var _this15 = this;
      console.log('updateHotelCommissionInfo click');
      if (this.hotel_id < 1) {
        this.$message.error("请先填写基本信息");
        return false;
      }
      this.$refs['commissionForm'].validate(function (valid) {
        if (valid) {
          _this15.commissionSubmitLoading = true;
          (0, _hotel.updateHotelCommissionConfig)(_this15.hotel_id, _this15.commissionForm).then(function (res) {
            // this.getHotelCommissionInfo()
            _this15.$message.success("更新成功！");
            _this15.$router.push('/hotel/index');
          }).finally(function () {
            _this15.commissionSubmitLoading = false;
          });
        }
      });
    },
    handleClick: function handleClick(tab) {
      if (tab.name == "ext") {
        this.getBanks();
        this.getBankProvinces();
        if (this.bill.bank_province && this.bill.bank_city) {
          this.getBankCities(this.bill.bank_province);
        }
        if (this.showBillBank()) {
          this.getOpenBanks();
        }
      }
    }
  }
};
exports.default = _default;