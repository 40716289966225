"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.to-string");
var _moment = _interopRequireDefault(require("moment"));
var _net_bar = require("@/api/net_bar");
var _extra_form = _interopRequireDefault(require("./extra_form.vue"));
var _mixin = _interopRequireDefault(require("@/utils/mixin"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "NetBarFinanceBill",
  mixins: [_mixin.default],
  components: {
    NetBarFinanceBillExtraForm: _extra_form.default
  },
  filters: {
    dateFormat: function dateFormat(value) {
      if (!value) return '';
      var date = value.toString();
      return date.slice(0, 4) + '-' + date.slice(4, 6) + '-' + date.slice(6, 8);
    }
  },
  data: function data() {
    return {
      storeList: [],
      statusOptions: [{
        value: 10,
        label: '待发起账单'
      }, {
        value: 20,
        label: '商家待确认'
      }, {
        value: 30,
        label: '待打款'
      }, {
        value: 40,
        label: '已打款'
      }],
      list: [],
      multipleSelection: [],
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 20,
        nb_id: "",
        status: "",
        start_date: this.getDefaultDateRange()
      },
      pagination: "",
      extraBillId: "",
      financeBillExtraFormVisible: false,
      redirectDialogVisible: false,
      redirectForm: {
        nb_id: "",
        date: "",
        order_type: "boot"
      }
    };
  },
  created: function created() {
    var _this = this;
    (0, _net_bar.getSimpleList)().then(function (response) {
      _this.storeList = response.data;
    });
    this.getList();
  },
  methods: {
    getDefaultDateRange: function getDefaultDateRange() {
      // 默认为上个账期
      var day = parseInt((0, _moment.default)().format("DD"));
      var start, end;
      if (day <= 15) {
        start = (0, _moment.default)().startOf('month').subtract(1, 'months').add(15, 'days').format("YYYY-MM-DD");
        end = (0, _moment.default)(start).endOf('month').format("YYYY-MM-DD");
      } else {
        start = (0, _moment.default)().startOf('month').format("YYYY-MM-DD");
        end = (0, _moment.default)(start).add(14, 'days').format("YYYY-MM-DD");
      }
      return [start, end];
    },
    getCurrDateRange: function getCurrDateRange(date) {
      var day = parseInt((0, _moment.default)(date).format("DD"));
      var start, end;
      if (day <= 15) {
        start = (0, _moment.default)(date).startOf('month').format("YYYY-MM-DD");
        end = (0, _moment.default)(start).add(14, 'days').format("YYYY-MM-DD");
      } else {
        start = (0, _moment.default)(date).startOf('month').add(15, 'days').format("YYYY-MM-DD");
        end = (0, _moment.default)(start).endOf('month').format("YYYY-MM-DD");
      }
      return [start, end];
    },
    onBillDateChange: function onBillDateChange(date) {
      var start = this.getCurrDateRange(date[0])[0];
      var end = this.getCurrDateRange(date[1])[1];
      this.listQuery.start_date = [start, end];
    },
    getList: function getList() {
      var _this2 = this;
      this.listLoading = true;
      (0, _net_bar.fetchFinanceBillList)(this.listQuery).then(function (response) {
        var data = response.data;
        // 格式化补充账单信息
        data.data.forEach(function (item) {
          var extras = {};
          item.extras.forEach(function (extra) {
            extras[extra.extra_type] = {
              extra_type: extra.extra_type,
              extra_amount: extra.extra_amount,
              extra_desc: extra.extra_desc
            };
          });
          item.extras = extras;
        });
        _this2.list = data.data;
        _this2.pagination = {
          total: data.total
        };
        _this2.listQuery.page = data.current_page;
        _this2.listLoading = false;
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    handleReset: function handleReset() {
      this.listQuery.nb_id = "";
      this.listQuery.status = "";
      this.listQuery.start_date = this.getDefaultDateRange();
      this.handleFilter();
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    exportHandler: function exportHandler() {
      window.open((0, _net_bar.getFinanceBillDownloadUrl)(this.listQuery));
    },
    enableBillExtraEntry: function enableBillExtraEntry(record) {
      // 财务录入
      // 账单初始状态 操作时间允许 财务或管理员
      if (record.status == 10 && record.enable_business_entry && (this.isRoleAdmin() || this.isRoleBill())) {
        return true;
      }
      return false;
    },
    enableFinanceConfirm: function enableFinanceConfirm(record) {
      // 成本审核
      // 账单初始状态 财务或管理员
      if (record.status == 10 && (this.isRoleAdmin() || this.isRoleBill())) {
        return true;
      }
      return false;
    },
    enableBatchFinanceConfirm: function enableBatchFinanceConfirm() {
      // 账单批量审核
      // 财务或管理员
      return this.isRoleAdmin() || this.isRoleBill();
    },
    enablePaymentComplete: function enablePaymentComplete(record) {
      // 打款完成
      // 账单已确认，待打款 财务或管理员
      if (record.status == 30 && (this.isRoleAdmin() || this.isRoleBill())) {
        return true;
      }
      return false;
    },
    extraFormHandler: function extraFormHandler(record) {
      this.extraBillId = record.id;
      this.financeBillExtraFormVisible = true;
    },
    toCostPage: function toCostPage(record) {
      this.$router.push({
        path: "/net_bar/finance_bill/cost/".concat(record.id)
      });
    },
    fmtBillDate: function fmtBillDate(date) {
      date = date.toString();
      return (0, _moment.default)(date.slice(0, 4) + '-' + date.slice(4, 6) + '-' + date.slice(6, 8)).format("YYYY-MM-DD");
    },
    toOrderPage: function toOrderPage(record) {
      this.$router.push({
        path: "/net_bar/order/index",
        query: {
          nb_id: record.nb_id,
          status: 40,
          start_date: this.fmtBillDate(record.start_date),
          end_date: this.fmtBillDate(record.end_date)
        }
      });
    },
    showRedirectHandler: function showRedirectHandler(record) {
      this.redirectForm.nb_id = record.nb_id;
      this.redirectForm.date = [this.fmtBillDate(record.start_date), this.fmtBillDate(record.end_date)];
      this.redirectDialogVisible = true;
    },
    redirectCloseHanlder: function redirectCloseHanlder() {
      this.redirectDialogVisible = false;
      this.redirectForm.nb_id = "";
      this.redirectForm.date = "";
      this.redirectForm.order_type = "boot";
    },
    redirectHandler: function redirectHandler() {
      var to = "/net_bar/order/index";
      if (this.redirectForm.order_type == "recharge") {
        to = "/net_bar/recharge_order/index";
      }
      var startDate = "";
      var endDate = "";
      if (this.redirectForm.date) {
        startDate = this.redirectForm.date[0];
        endDate = this.redirectForm.date[1];
      }
      this.$router.push({
        path: to,
        query: {
          nb_id: this.redirectForm.nb_id,
          start_date: startDate,
          end_date: endDate
        }
      });
    },
    financeConfirmHandler: function financeConfirmHandler(record) {
      var _this3 = this;
      this.$confirm('<span style="color: #F56C6C;">请确认本期成本已录入完毕！</span>', '成本审核确认后不可更改', {
        dangerouslyUseHTMLString: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _net_bar.financeBillStatusFinanceConfirm)(record.id).then(function () {
          _this3.getList();
          _this3.$message.success("操作成功");
        });
      }).catch(function () {});
    },
    financeBatchConfirmHandler: function financeBatchConfirmHandler() {
      var _this4 = this;
      var ids = [];
      this.multipleSelection.forEach(function (item) {
        ids.push(item.id);
      });
      if (ids.length < 1) {
        this.$message.error("请选择账单");
        return false;
      }
      this.$confirm('<span style="color: #F56C6C;">请确认本期成本已录入完毕！</span>', '成本审核确认后不可更改', {
        dangerouslyUseHTMLString: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _net_bar.financeBillStatusBatchFinanceConfirm)(ids).then(function (response) {
          var data = response.data;
          var content = "所有账单均已成功审核！";
          if (data.errors && data.errors.length) {
            content = "\u6210\u529F\u5BA1\u6838<span style=\"color: #67C23A;\"> ".concat(data.success, " </span>\u6761\uFF0C\u5931\u8D25<span style=\"color: #F56C6C;\"> ").concat(data.errors.length, " </span>\u6761\uFF0C\u8BF7\u590D\u6838\u5931\u8D25\u539F\u56E0\uFF01");
          }
          _this4.$alert(content, '网咖账单批量审核完毕！', {
            dangerouslyUseHTMLString: true,
            confirmButtonText: '确定',
            type: 'success',
            callback: function callback() {
              _this4.getList();
              _this4.$message.success("操作成功");
            }
          });
        });
      }).catch(function () {});
    },
    paymentCompleteHandler: function paymentCompleteHandler(record) {
      var _this5 = this;
      (0, _net_bar.financeBillStatusComplete)(record.id).then(function () {
        _this5.getList();
        _this5.$message.success("操作成功");
      });
    }
  }
};
exports.default = _default;