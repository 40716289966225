"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _auth = require("@/utils/auth");
var _common = require("@/api/common");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: "imageUpload",
  props: {
    type: {
      type: Number,
      default: 1
    },
    imageList: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      uploadUrl: '/admin/upload',
      header: {
        Authorization: "Bearer " + (0, _auth.getToken)()
      },
      dialogImageUrl: "",
      dialogVisible: true
    };
  },
  created: function created() {
    this.uploadUrl = (0, _common.adminUpload)();
  },
  methods: {
    handleUploadSuccess: function handleUploadSuccess(res, file, fileList) {
      var data = {
        type: this.type,
        res: res,
        file: file,
        fileList: fileList
      };
      this.$emit("uploadHotelImageSuccess", data);
    },
    handleRemove: function handleRemove(file, fileList) {
      var data = {
        type: this.type,
        file: file,
        fileList: fileList
      };
      this.$emit("deleteHotelImageSuccess", data);
    },
    handlePictureCardPreview: function handlePictureCardPreview(file) {
      console.log('123');
      console.log(file);
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    }
  }
};
exports.default = _default2;