"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vueCountTo = _interopRequireDefault(require("vue-count-to"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    CountTo: _vueCountTo.default
  },
  methods: {
    handleSetLineChartData: function handleSetLineChartData(type) {
      this.$emit('handleSetLineChartData', type);
    }
  }
};
exports.default = _default;