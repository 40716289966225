"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _member = require("@/api/member");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: "MemberStvBalanceLog",
  components: {},
  props: {
    userId: {
      type: [String, Number]
    },
    storeId: {
      type: [String, Number]
    },
    visible: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    }
  },
  watch: {
    visible: function visible() {
      this.dialogVisible = this.visible;
    },
    storeId: function storeId() {
      if (this.storeId) {
        this.getList();
      }
    }
  },
  data: function data() {
    return {
      dialogVisible: false,
      loading: false,
      list: [],
      listQuery: {
        page: 1,
        limit: 10
      },
      pagination: ""
    };
  },
  created: function created() {},
  methods: {
    getList: function getList() {
      var _this = this;
      this.loading = true;
      (0, _member.fetchStvBalanceLogsList)(this.userId, this.storeId, this.listQuery).then(function (response) {
        var data = response.data;
        _this.list = data.data;
        _this.pagination = data;
        _this.listQuery.page = data.current_page;
        _this.loading = false;
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    closeHanlder: function closeHanlder() {
      this.listQuery.page = 1;
      this.listQuery.limit = 10;
      this.$emit('update:storeId', "");
      this.$emit('update:visible', false);
    }
  }
};
exports.default = _default2;