var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container calendar-list-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _vm.areaList.length > 0
            ? _c(
                "el-select",
                {
                  staticClass: "filter-item",
                  attrs: {
                    "default-first-option": "",
                    placeholder: "请选择区域",
                    clearable: "",
                    "close-on-click-modal": false,
                  },
                  model: {
                    value: _vm.listQuery.area_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery, "area_id", $$v)
                    },
                    expression: "listQuery.area_id",
                  },
                },
                _vm._l(_vm.areaList, function (item) {
                  return _c("el-option", {
                    key: item.role_id,
                    attrs: { label: item.name, value: item.role_id },
                  })
                }),
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: { clearable: "", placeholder: "账号" },
            model: {
              value: _vm.listQuery.username,
              callback: function ($$v) {
                _vm.$set(_vm.listQuery, "username", $$v)
              },
              expression: "listQuery.username",
            },
          }),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.getAdminList },
            },
            [_vm._v("\n      查询\n    ")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              staticStyle: { "margin-left": "10px" },
              attrs: { type: "primary", icon: "edit" },
              on: {
                click: function ($event) {
                  return _vm.handleCreate("create")
                },
              },
            },
            [_vm._v("\n      添加\n    ")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          key: "tableKey",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.adminList,
            "element-loading-text": "给我一点时间",
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "序号", width: "65" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.id))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "账号" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.username))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "角色" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [_vm._v(_vm._s(_vm.roleList[scope.row.role]))]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "版块" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.bankuai))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "使用人" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.name))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "添加时间" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.created_at))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "success" },
                        on: {
                          click: function ($event) {
                            return _vm.handleEdit(scope.row, "edit")
                          },
                        },
                      },
                      [_vm._v("\n          编辑\n        ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "danger" },
                        on: {
                          click: function ($event) {
                            return _vm.deleteAdmin(scope.row.id)
                          },
                        },
                      },
                      [_vm._v("\n          删除\n        ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "pagination-container text-right" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              total: _vm.pagination.total,
              "current-page": _vm.listQuery.page,
              "page-sizes": [20],
              "page-size": _vm.listQuery.limit,
              layout: "total, sizes, prev, pager, next, jumper",
            },
            on: { "current-change": _vm.handleCurrentChange },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "管理员", visible: _vm.dialogFormVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "adminForm",
              staticClass: "small-space",
              attrs: {
                model: _vm.adminForm,
                "label-position": "right",
                "label-width": "100px",
                rules: _vm.formRules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "角色", prop: "role" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      on: { change: _vm.onRoleChange },
                      model: {
                        value: _vm.adminForm.role,
                        callback: function ($$v) {
                          _vm.$set(_vm.adminForm, "role", $$v)
                        },
                        expression: "adminForm.role",
                      },
                    },
                    _vm._l(_vm.roleList, function (item, i) {
                      return _c(
                        "el-radio",
                        { key: item, attrs: { label: i } },
                        [
                          _vm._v(
                            "\n            " + _vm._s(item) + "\n          "
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "组织", prop: "grade" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { disabled: _vm.dialogStatus != "create" },
                      model: {
                        value: _vm.adminForm.grade,
                        callback: function ($$v) {
                          _vm.$set(_vm.adminForm, "grade", $$v)
                        },
                        expression: "adminForm.grade",
                      },
                    },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: {
                            label: 0,
                            disabled: _vm.adminForm.role == "net_bar_manager",
                          },
                        },
                        [_vm._v("全部")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          attrs: {
                            label: 3,
                            disabled: _vm.adminForm.role == "net_bar_manager",
                          },
                        },
                        [_vm._v("区域")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          attrs: {
                            label: 5,
                            disabled: _vm.adminForm.role == "net_bar_manager",
                          },
                        },
                        [_vm._v("酒店")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          attrs: {
                            label: 6,
                            disabled: _vm.adminForm.role != "net_bar_manager",
                          },
                        },
                        [_vm._v("网吧")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              [
                _vm.adminForm.grade == 5
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "组织权限", prop: "data" } },
                      [
                        _c("el-cascader", {
                          attrs: {
                            filterable: "",
                            options: _vm.dataRole,
                            props: _vm.props,
                            "collapse-tags": "",
                            clearable: "",
                          },
                          model: {
                            value: _vm.adminForm.data,
                            callback: function ($$v) {
                              _vm.$set(_vm.adminForm, "data", $$v)
                            },
                            expression: "adminForm.data",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.adminForm.grade == 6
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "组织权限", prop: "data" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "filter-item",
                            attrs: {
                              filterable: "",
                              clearable: "",
                              multiple: "",
                              placeholder: "请选择网咖",
                            },
                            model: {
                              value: _vm.adminForm.data,
                              callback: function ($$v) {
                                _vm.$set(_vm.adminForm, "data", $$v)
                              },
                              expression: "adminForm.data",
                            },
                          },
                          _vm._l(_vm.netbarList, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.name, value: item.id },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    )
                  : _vm.areas.length > 0 && _vm.adminForm.grade == 3
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "组织权限", prop: "data" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "filter-item",
                            attrs: {
                              "default-first-option": "",
                              placeholder: "请选择区域",
                              clearable: "",
                            },
                            model: {
                              value: _vm.adminForm.data,
                              callback: function ($$v) {
                                _vm.$set(_vm.adminForm, "data", $$v)
                              },
                              expression: "adminForm.data",
                            },
                          },
                          _vm._l(_vm.areas, function (item) {
                            return _c("el-option", {
                              key: item.role_id,
                              attrs: { label: item.name, value: item.role_id },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "账号", prop: "username" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.adminForm.username,
                      callback: function ($$v) {
                        _vm.$set(_vm.adminForm, "username", $$v)
                      },
                      expression: "adminForm.username",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "员工名称", prop: "name" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.adminForm.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.adminForm, "name", $$v)
                      },
                      expression: "adminForm.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.dialogStatus == "create"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "密码", required: "" } },
                    [
                      _vm.dialogStatus == "create"
                        ? _c("el-input", {
                            model: {
                              value: _vm.adminForm.password,
                              callback: function ($$v) {
                                _vm.$set(_vm.adminForm, "password", $$v)
                              },
                              expression: "adminForm.password",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _c(
                    "el-form-item",
                    { attrs: { label: "密码" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "不填写密码则不修改密码" },
                        model: {
                          value: _vm.adminForm.password,
                          callback: function ($$v) {
                            _vm.$set(_vm.adminForm, "password", $$v)
                          },
                          expression: "adminForm.password",
                        },
                      }),
                    ],
                    1
                  ),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = false
                    },
                  },
                },
                [_vm._v("\n        取 消\n      ")]
              ),
              _vm._v(" "),
              _vm.dialogStatus == "create"
                ? _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.create } },
                    [_vm._v("\n        确 定\n      ")]
                  )
                : _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.update } },
                    [_vm._v("\n        确 定\n      ")]
                  ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }