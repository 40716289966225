"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.object.keys");
require("core-js/modules/web.dom.iterable");
var _mixin = _interopRequireDefault(require("@/utils/mixin"));
var _moment = _interopRequireDefault(require("moment"));
var _FeeOrderTable = _interopRequireDefault(require("./FeeOrderTable.vue"));
var _BuildBillButton = _interopRequireDefault(require("../week/BuildBillButton.vue"));
var _hotel = require("@/api/hotel");
var _order = require("@/api/order");
var _finance_reco = require("@/api/finance_reco");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "FinanceOrderNormal",
  mixins: [_mixin.default],
  components: {
    FeeOrderTable: _FeeOrderTable.default,
    BuildBillButton: _BuildBillButton.default
  },
  data: function data() {
    return {
      modeList: [],
      hotelList: [],
      sellers: [],
      thirdChannel: [{
        id: -1,
        title: "小程序"
      }, {
        id: 1,
        title: "美团（代理账号）"
      }, {
        id: 2,
        title: "美团（独立账号）"
      }, {
        id: 4,
        title: "携程（代理账号）"
      }, {
        id: 6,
        title: "携程（独立账号）"
      }, {
        id: 7,
        title: "线下渠道"
      }, {
        id: 8,
        title: "抖音渠道"
      }],
      thirdChannelOrderTypes: [],
      thirdOrderTypes: [],
      financeRange: [],
      allowAction: {
        allowImport: false,
        allowModify: false,
        allowDelete: false
      },
      // 列表及查询
      listQuery: {
        page: 1,
        limit: 20,
        bill_week: "",
        order_no: "",
        contacts: "",
        third_channel: "",
        third_order_type: "",
        seller_type: "",
        start_date: "",
        end_date: "",
        import_date: "",
        hotel_mode: "",
        hotel_id: ""
      },
      pagination: {},
      listLoading: false,
      orders: [],
      summary: {}
    };
  },
  created: function created() {
    var _this = this;
    this.loadURLQuerys();
    (0, _hotel.getHotelSimpleList)().then(function (response) {
      _this.hotelList = response.data;
    });
    (0, _hotel.getModeList)().then(function (response) {
      _this.modeList = response.data;
    });
    (0, _order.getPermutationSellerList)().then(function (response) {
      _this.sellers = response.data;
    });
    (0, _finance_reco.getFinanceRange)().then(function (response) {
      var data = response.data;
      _this.financeRange = [data.date.start, data.date.end];
      _this.allowAction.allowImport = data.allow_import;
      _this.allowAction.allowModify = data.allow_modify;
      _this.allowAction.allowDelete = data.allow_delete;
    });
    (0, _finance_reco.getFinanceRecoThirdOrderTypes)().then(function (response) {
      _this.thirdOrderTypes = response.data;
      _this.thirdChannelOrderTypes = _this.getThirdChannelOrderTypes("");
    });
    this.getList();
  },
  methods: {
    loadURLQuerys: function loadURLQuerys() {
      if (this.$route.query.order_no) {
        this.listQuery.order_no = this.$route.query.order_no;
      }
      if (this.$route.query.hotel_id) {
        this.listQuery.hotel_id = parseInt(this.$route.query.hotel_id);
      }
      if (this.$route.query.hotel_mode) {
        this.listQuery.hotel_mode = parseInt(this.$route.query.hotel_mode);
      }
      if (this.$route.query.bill_week) {
        this.listQuery.bill_week = this.$route.query.bill_week;
      } else {
        var prevWeekStart = this.getWeekRange((0, _moment.default)().subtract(7, 'days').format("YYYY-MM-DD"))[0];
        this.listQuery.bill_week = (0, _moment.default)(prevWeekStart).format("YYYYMMDD");
      }
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    getList: function getList() {
      var _this2 = this;
      this.listLoading = true;
      (0, _finance_reco.getFinanceRecoOrderList)(this.listQuery).then(function (response) {
        var data = response.data;
        _this2.pagination = {
          total: data.total,
          currentPage: data.current_page,
          pageSize: data.per_page
        };
        _this2.orders = data.data;
        _this2.summary = data.summary;
        _this2.listQuery.page = data.current_page;
        _this2.listLoading = false;
      });
    },
    syncHandler: function syncHandler() {
      var _this3 = this;
      this.$confirm("\u5F53\u524D\u5BF9\u8D26\u5468\u671F\u3010".concat(this.financeRange[0], " \u81F3 ").concat(this.financeRange[1], "\u3011\uFF0C\u540C\u6B65\u64CD\u4F5C\u4F1A\u5148\u79FB\u9664\u5DF2\u540C\u6B65\u5C0F\u7A0B\u5E8F\u8BA2\u5355\u540E\u91CD\u65B0\u540C\u6B65\uFF0C\u786E\u8BA4\u540C\u6B65\u5C0F\u7A0B\u5E8F\u8BA2\u5355\u5417?"), '提示', {
        title: '同步小程序订单',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this3.syncing = true;
        // 开始同步
        (0, _finance_reco.syncMiniappOrder)().then(function () {
          _this3.getList();
          _this3.$message.success("同步成功");
        }).finally(function () {
          _this3.syncing = false;
        });
      });
    },
    exportHandler: function exportHandler() {
      window.open((0, _finance_reco.getFinanceRecoOrderDownloadUrl)(this.listQuery));
    },
    onStartDateChange: function onStartDateChange() {
      this.listQuery.bill_week = "";
    },
    onEndDateChange: function onEndDateChange() {
      this.listQuery.bill_week = "";
    },
    onThirdChannelChange: function onThirdChannelChange(thirdChannel) {
      this.listQuery.third_order_type = "";
      this.thirdChannelOrderTypes = this.getThirdChannelOrderTypes(thirdChannel);
    },
    getThirdChannelOrderTypes: function getThirdChannelOrderTypes(thirdChannel) {
      var _this4 = this;
      if (thirdChannel == "") {
        var orderTypes = [];
        Object.keys(this.thirdOrderTypes).forEach(function (idx) {
          _this4.thirdOrderTypes[idx].forEach(function (type) {
            if (orderTypes.indexOf(type) < 0) {
              orderTypes.push(type);
            }
          });
        });
        return orderTypes;
      }
      return this.thirdOrderTypes[thirdChannel];
    }
  }
};
exports.default = _default;