var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "thrid-order-form-dialog",
      attrs: {
        visible: _vm.dialogVisible,
        title: "编辑订单",
        "close-on-click-modal": false,
        width: "760px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.orderFormCloseHanlder,
      },
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.formLoading,
              expression: "formLoading",
            },
          ],
          ref: "orderForm",
          attrs: {
            model: _vm.orderForm,
            rules: _vm.orderFormRules,
            "label-suffix": ":",
            "label-width": "105px",
            "label-position": "right",
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "订单编号",
                        prop: "order_no",
                        required: "",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入",
                          disabled: _vm.orderFormEditDisabled(),
                        },
                        model: {
                          value: _vm.orderForm.order_no,
                          callback: function ($$v) {
                            _vm.$set(_vm.orderForm, "order_no", $$v)
                          },
                          expression: "orderForm.order_no",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "入离日期",
                        prop: "date_range",
                        required: "",
                      },
                    },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "245px" },
                        attrs: {
                          clearable: false,
                          type: "daterange",
                          "range-separator": "-",
                          "start-placeholder": "入住日期",
                          "end-placeholder": "离店日期",
                          "picker-options": _vm.orderFormPickerOptions,
                          disabled: _vm.orderFormEditDisabled(),
                          align: "left",
                        },
                        model: {
                          value: _vm.orderForm.date_range,
                          callback: function ($$v) {
                            _vm.$set(_vm.orderForm, "date_range", $$v)
                          },
                          expression: "orderForm.date_range",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "入住酒店",
                        prop: "hotel_id",
                        required: "",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "245px" },
                          attrs: {
                            filterable: "",
                            placeholder: "请选择",
                            disabled: _vm.orderFormEditDisabled(),
                          },
                          on: { change: _vm.orderFormHotelChangeHandler },
                          model: {
                            value: _vm.orderForm.hotel_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.orderForm, "hotel_id", $$v)
                            },
                            expression: "orderForm.hotel_id",
                          },
                        },
                        _vm._l(_vm.hotelList, function (item) {
                          return _c("el-option", {
                            key: item.hotel_id,
                            attrs: { label: item.name, value: item.hotel_id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      ref: "roomSelect",
                      attrs: {
                        label: "预订房型",
                        prop: "room_id",
                        required: "",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "245px" },
                          attrs: {
                            filterable: "",
                            placeholder: "请选择",
                            disabled: _vm.orderFormEditDisabled(),
                          },
                          model: {
                            value: _vm.orderForm.room_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.orderForm, "room_id", $$v)
                            },
                            expression: "orderForm.room_id",
                          },
                        },
                        _vm._l(_vm.orderFormRooms, function (item) {
                          return _c("el-option", {
                            key: item.room_id,
                            attrs: { label: item.name, value: item.room_id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "入住人",
                        prop: "contacts",
                        required: "",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入",
                          disabled: _vm.orderFormEditDisabled(),
                        },
                        model: {
                          value: _vm.orderForm.contacts,
                          callback: function ($$v) {
                            _vm.$set(_vm.orderForm, "contacts", $$v)
                          },
                          expression: "orderForm.contacts",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "结算金额",
                        prop: "amount",
                        required: "",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入",
                          disabled: _vm.orderFormEditDisabled(),
                        },
                        model: {
                          value: _vm.orderForm.amount,
                          callback: function ($$v) {
                            _vm.$set(_vm.orderForm, "amount", $$v)
                          },
                          expression: "orderForm.amount",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "商家应收",
                        prop: "hotel_amount",
                        required: "",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入",
                          disabled: _vm.orderFormEditDisabled(),
                        },
                        model: {
                          value: _vm.orderForm.hotel_amount,
                          callback: function ($$v) {
                            _vm.$set(_vm.orderForm, "hotel_amount", $$v)
                          },
                          expression: "orderForm.hotel_amount",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "商家实收",
                        prop: "hotel_real_amount",
                        required: "",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入" },
                        model: {
                          value: _vm.orderForm.hotel_real_amount,
                          callback: function ($$v) {
                            _vm.$set(_vm.orderForm, "hotel_real_amount", $$v)
                          },
                          expression: "orderForm.hotel_real_amount",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "代理商应收",
                        prop: "hotel_agent_amount",
                        required: "",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入",
                          disabled: _vm.orderFormEditDisabled(),
                        },
                        model: {
                          value: _vm.orderForm.hotel_agent_amount,
                          callback: function ($$v) {
                            _vm.$set(_vm.orderForm, "hotel_agent_amount", $$v)
                          },
                          expression: "orderForm.hotel_agent_amount",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "代理商实收",
                        prop: "hotel_agent_real_amount",
                        required: "",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入" },
                        model: {
                          value: _vm.orderForm.hotel_agent_real_amount,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.orderForm,
                              "hotel_agent_real_amount",
                              $$v
                            )
                          },
                          expression: "orderForm.hotel_agent_real_amount",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "技术费",
                        prop: "ota_service_fee",
                        required: "",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入",
                          disabled: _vm.orderFormEditDisabled(),
                        },
                        model: {
                          value: _vm.orderForm.ota_service_fee,
                          callback: function ($$v) {
                            _vm.$set(_vm.orderForm, "ota_service_fee", $$v)
                          },
                          expression: "orderForm.ota_service_fee",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "退技术费",
                        prop: "ota_service_refund_fee",
                        required: "",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入",
                          disabled: _vm.orderFormEditDisabled(),
                        },
                        model: {
                          value: _vm.orderForm.ota_service_refund_fee,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.orderForm,
                              "ota_service_refund_fee",
                              $$v
                            )
                          },
                          expression: "orderForm.ota_service_refund_fee",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.orderFormSubmitLoading },
              on: { click: _vm.orderFormSubmitHandler },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }