var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container calendar-list-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              staticStyle: { "margin-left": "10px" },
              attrs: { type: "primary", icon: "edit" },
              on: {
                click: function ($event) {
                  return _vm.doAdd()
                },
              },
            },
            [_vm._v("\n      添加\n    ")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          key: "tableList",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            "element-loading-text": "给我一点时间",
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "序号", width: "65" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.id))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _vm.areas.length > 0
            ? _c("el-table-column", {
                attrs: { align: "center", label: "所属板块" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.areas[scope.row.area_id - 2].name)
                            ),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2723405783
                ),
              })
            : _vm._e(),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "图片", width: "250" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("img", {
                      staticStyle: { width: "200px" },
                      attrs: { src: scope.row.image },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "展示日期" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(scope.row.start_date) +
                          " ~ " +
                          _vm._s(scope.row.end_date)
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "跳转链接" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.path))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "弹出页面" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-select",
                      {
                        attrs: { disabled: "", multiple: "" },
                        model: {
                          value: scope.row.pages,
                          callback: function ($$v) {
                            _vm.$set(scope.row, "pages", $$v)
                          },
                          expression: "scope.row.pages",
                        },
                      },
                      _vm._l(_vm.options2, function (item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value },
                        })
                      }),
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "状态" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.status_name))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.updateAction(scope.row)
                          },
                        },
                      },
                      [_vm._v("\n          编辑\n        ")]
                    ),
                    _vm._v(" "),
                    scope.row.status == 0
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.deletePopup(scope.row.id)
                              },
                            },
                          },
                          [_vm._v("\n          显示\n        ")]
                        )
                      : _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "success" },
                            on: {
                              click: function ($event) {
                                return _vm.deletePopup(scope.row.id)
                              },
                            },
                          },
                          [_vm._v("\n          隐藏\n        ")]
                        ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: _vm.dialogTitle, visible: _vm.dialogFormVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "popupForm",
              staticClass: "small-space",
              attrs: {
                model: _vm.popupForm,
                rules: _vm.popupFormRules,
                "label-suffix": ":",
                "label-width": "100px",
                "label-position": "right",
              },
            },
            [
              _vm.areas.length > 0
                ? _c(
                    "el-form-item",
                    { attrs: { label: "板块", prop: "area_id", required: "" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "filter-item",
                          attrs: {
                            "default-first-option": "",
                            placeholder: "请选择区域",
                            clearable: "",
                          },
                          model: {
                            value: _vm.popupForm.area_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.popupForm, "area_id", $$v)
                            },
                            expression: "popupForm.area_id",
                          },
                        },
                        _vm._l(_vm.areas, function (item) {
                          return _c("el-option", {
                            key: item.role_id,
                            attrs: { label: item.name, value: item.role_id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "展示日期", prop: "date", required: "" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "daterange",
                      "range-separator": "-",
                      "value-format": "yyyyMMdd",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                    },
                    model: {
                      value: _vm.popupForm.date,
                      callback: function ($$v) {
                        _vm.$set(_vm.popupForm, "date", $$v)
                      },
                      expression: "popupForm.date",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "弹窗描述" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.popupForm.desc,
                      callback: function ($$v) {
                        _vm.$set(_vm.popupForm, "desc", $$v)
                      },
                      expression: "popupForm.desc",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "template",
                    { slot: "label" },
                    [
                      _c("el-tooltip", { attrs: { placement: "top" } }, [
                        _c(
                          "div",
                          { attrs: { slot: "content" }, slot: "content" },
                          [
                            _vm._v(
                              "\n              跳转链接规则：\n              "
                            ),
                            _c("br"),
                            _vm._v(
                              "\n              1.网页地址，如：https://www.feihuo.net.cn\n              "
                            ),
                            _c("br"),
                            _vm._v(
                              "\n              2.小程序页面，如：/pages/blindBox/blindBox\n            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("i", { staticClass: "el-icon-question" }),
                      ]),
                      _vm._v("\n          跳转链接:\n        "),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-input", {
                    attrs: { placeholder: "留空表示不跳转" },
                    model: {
                      value: _vm.popupForm.path,
                      callback: function ($$v) {
                        _vm.$set(_vm.popupForm, "path", $$v)
                      },
                      expression: "popupForm.path",
                    },
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "弹窗图片", prop: "image", required: "" } },
                [
                  _c(
                    "el-upload",
                    {
                      staticClass: "avatar-uploader",
                      attrs: {
                        action: _vm.uploadUrl,
                        headers: _vm.header,
                        "show-file-list": false,
                        "on-success": _vm.handleImageSuccess,
                      },
                    },
                    [
                      _vm.popupForm.image
                        ? _c("img", {
                            staticClass: "avatar",
                            staticStyle: { "max-width": "100%" },
                            attrs: { src: _vm.popupForm.image },
                          })
                        : _c("i", {
                            staticClass: "el-icon-plus avatar-uploader-icon",
                          }),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: { label: "弹出频率", prop: "frequency", required: "" },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.popupForm.frequency,
                        callback: function ($$v) {
                          _vm.$set(_vm.popupForm, "frequency", $$v)
                        },
                        expression: "popupForm.frequency",
                      },
                    },
                    _vm._l(_vm.options, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "弹出页面", prop: "pages", required: "" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { multiple: "", placeholder: "请选择" },
                      model: {
                        value: _vm.popupForm.pages,
                        callback: function ($$v) {
                          _vm.$set(_vm.popupForm, "pages", $$v)
                        },
                        expression: "popupForm.pages",
                      },
                    },
                    _vm._l(_vm.options2, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "弹窗序号" } },
                [
                  _c("el-input-number", {
                    model: {
                      value: _vm.popupForm.sort,
                      callback: function ($$v) {
                        _vm.$set(_vm.popupForm, "sort", $$v)
                      },
                      expression: "popupForm.sort",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-alert", {
                attrs: {
                  title:
                    "tips:图片宽度不小于600，弹窗序号越大越早弹出，不超过100",
                  type: "warning",
                  closable: false,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.createPopup } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }