"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
require("core-js/modules/es6.regexp.to-string");
var _mixin = _interopRequireDefault(require("@/utils/mixin"));
var _hotel = require("@/api/hotel");
var _auth = require("@/utils/auth");
var _depositDeductDetail = _interopRequireDefault(require("./components/depositDeductDetail"));
var _hotelEmployeeDetail = _interopRequireDefault(require("./components/hotelEmployeeDetail"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "HotelIndex",
  mixins: [_mixin.default],
  components: {
    DepositDeductDetail: _depositDeductDetail.default,
    HotelEmployeeDetail: _hotelEmployeeDetail.default
  },
  data: function data() {
    return {
      areas: [],
      list: [],
      modeList: [],
      dialogFormVisible: false,
      listQuery: {
        page: 1,
        limit: 20,
        name: "",
        city: "",
        area_id: "",
        is_show: "",
        mode: "",
        settlement_type: ""
      },
      pagination: "",
      showOptions: [{
        value: 2,
        label: '显示'
      }, {
        value: 1,
        label: '隐藏'
      }],
      settlementTypes: [{
        value: '',
        label: '全部'
      }, {
        value: 'deposit_deduct_off',
        label: '非押金抵扣'
      }, {
        value: 'deposit_deduct_processing',
        label: '押金抵扣中'
      }, {
        value: 'deposit_deduct_completed',
        label: '押金抵扣完毕'
      }],
      depositDeductDetailVisable: false,
      depositDeductDetailHotelId: '',
      hotelEmployeeDetailVisable: false,
      hotelEmployeeDetailHotelId: ''
    };
  },
  created: function created() {
    var _this = this;
    console.log('env---' + process.env.NODE_ENV);
    (0, _hotel.getModeList)().then(function (res) {
      _this.modeList = res.data;
    });
    // var roles = getRoles();
    if ((0, _auth.getGrade)() < 1) {
      (0, _hotel.getArea)().then(function (response) {
        _this.areas = response.data;
      });
    }
    this.getList();
  },
  methods: {
    qrCode: function qrCode(id) {
      window.open('/admin/qrCode/' + id + '?api_token=' + (0, _auth.getToken)());
    },
    deleteRow: function deleteRow(id) {
      var _this2 = this;
      (0, _hotel.deleteHotel)(id).then(function (response) {
        if (response.code == 0) {
          _this2.getList();
        } else {
          _this2.$message.success(response.msg);
        }
      });
    },
    resetForm: function resetForm() {
      this.listQuery.city = "";
      this.listQuery.name = "";
      this.listQuery.area_id = "";
      this.listQuery.is_show = "";
      this.listQuery.settlement_type = "";
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleDetailRedirect: function handleDetailRedirect(id) {
      this.$router.push({
        path: "/hotel/detail/" + id
      });
    },
    handleRoomRedirect: function handleRoomRedirect(id) {
      this.$router.push({
        path: "/hotel/room/" + id
      });
    },
    handleFormRedirect: function handleFormRedirect(id, action) {
      if (action == "create") {
        this.$router.push({
          path: "/hotel/add/"
        });
      } else {
        this.$router.push({
          path: "/hotel/edit/" + id
        });
      }
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    getList: function getList() {
      var _this3 = this;
      this.listLoading = true;
      (0, _hotel.fetchList)(this.listQuery).then(function (response) {
        var data = response.data;
        _this3.list = data.data;
        _this3.pagination = data;
        _this3.listQuery.page = data.current_page;
        _this3.listLoading = false;
      });
    },
    handleRoom: function handleRoom() {
      this.dialogFormVisible = true;
    },
    download: function download() {
      var query = this.listQuery;
      var isArray = function isArray(obj) {
        return Object.prototype.toString.call(obj) === '[object Array]';
      };
      var url = Object.keys(this.listQuery).map(function (key) {
        if (isArray(query[key])) {
          var iUrl = '';
          query[key].forEach(function (item) {
            iUrl = iUrl + key + '[]=' + item + '&';
          });
          return iUrl.trim('&');
        } else {
          return encodeURIComponent(key) + "=" + encodeURIComponent(query[key]);
        }
      }).join("&");
      window.open('/admin/hotelDownload?' + url + '&api_token=' + (0, _auth.getToken)());
    },
    showDepositDeduct: function showDepositDeduct(hotelId) {
      this.depositDeductDetailHotelId = hotelId;
      this.depositDeductDetailVisable = true;
    },
    showHotelEmployee: function showHotelEmployee(hotelId) {
      this.hotelEmployeeDetailHotelId = hotelId;
      this.hotelEmployeeDetailVisable = true;
    }
  }
};
exports.default = _default;