var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "button-file" },
    [
      _c(
        "el-button",
        {
          attrs: { type: "primary", size: "small" },
          on: { click: _vm.clickFile },
        },
        [
          _vm.showIcon ? _c("i", { class: _vm.icon }) : _vm._e(),
          _vm._v(_vm._s(_vm.title) + "\n  "),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "file-list" },
        _vm._l(_vm.files, function (file, k) {
          return _c("div", { key: file.name, staticClass: "file-list-item" }, [
            _c("div", { staticClass: "name" }, [
              _c("i", { staticClass: "el-icon-document" }),
              _vm._v(_vm._s(file.name)),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "icon" }, [
              _c("i", {
                staticClass: "el-icon-close",
                on: {
                  click: function ($event) {
                    return _vm.remoceFile(k)
                  },
                },
              }),
            ]),
          ])
        }),
        0
      ),
      _vm._v(" "),
      _c("input", {
        ref: "fileInput",
        staticStyle: { display: "none" },
        attrs: {
          id: "file",
          type: "file",
          multiple: _vm.multiple,
          accept: _vm.accept,
        },
        on: { change: _vm.fileChangeHandler },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }