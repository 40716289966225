"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es6.number.constructor");
var _order = require("@/api/order");
var _moment = _interopRequireDefault(require("moment"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'OrderRefundDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    orderId: {
      type: [String, Number]
    }
  },
  filters: {
    previceDateFormat: function previceDateFormat(value) {
      if (!value) return '';
      var date = value.toString();
      return "".concat(date.slice(0, 4), "-").concat(date.slice(4, 6), "-").concat(date.slice(6, 8));
    }
  },
  watch: {
    visible: function visible() {
      this.dialogRefundFormVisible = this.visible;
    },
    orderId: function orderId() {
      // this.getOrder()
      // this.getPreviewRefundAmount()
    }
  },
  data: function data() {
    return {
      order: {},
      dialogRefundFormVisible: false,
      refundForm: {
        action_type: 1,
        refund_date: []
      },
      refundAmount: 0,
      refundFormRules: {
        action_type: [{
          required: true,
          message: '请选择类型',
          trigger: 'blur'
        }],
        refund_date: [{
          required: true,
          message: '请选择退订日期',
          trigger: 'blur'
        }]
      },
      refundFormConfirmLoading: false,
      refundFormPickerOptions: {
        disabledDate: function disabledDate() {
          false;
        }
      }
    };
  },
  created: function created() {},
  methods: {
    getOrder: function getOrder() {
      var _this = this;
      (0, _order.orderDetail)({
        id: this.orderId
      }).then(function (response) {
        _this.order = response.data;
        var startDate = _this.$options.filters['previceDateFormat'](_this.order.start_date);
        var endDate = _this.$options.filters['previceDateFormat'](_this.order.end_date);
        _this.refundForm.refund_date = [startDate, endDate];
        var startDateTs = (0, _moment.default)(startDate).valueOf();
        var endDateTs = (0, _moment.default)(endDate).valueOf();
        // 退款时间可选择周期限制
        _this.refundFormPickerOptions.disabledDate = function (time) {
          var ts = time.getTime();
          return ts < startDateTs || ts > endDateTs;
        };
      });
    },
    getPreviewRefundAmount: function getPreviewRefundAmount() {
      var _this2 = this;
      (0, _order.refundAmountPreview)(this.orderId, this.refundForm).then(function (response) {
        _this2.refundAmount = response.data;
      });
    },
    actionTypeChangeHandler: function actionTypeChangeHandler() {
      this.getPreviewRefundAmount();
    },
    refundDateChangeHandler: function refundDateChangeHandler() {
      this.getPreviewRefundAmount();
    },
    closeHandler: function closeHandler() {
      this.dialogRefundFormVisible = false;
      this.$emit("update:visible", this.dialogRefundFormVisible);
    },
    refundFormSubmitHandler: function refundFormSubmitHandler() {
      var _this3 = this;
      this.refundFormConfirmLoading = true;
      (0, _order.refund)(this.orderId, this.refundForm).then(function () {
        _this3.$emit('succss');
        _this3.closeHandler();
      }).finally(function () {
        _this3.refundFormConfirmLoading = false;
      });
    },
    refundFormOpenHanlder: function refundFormOpenHanlder() {
      this.getOrder();
      this.getPreviewRefundAmount();
    },
    refundFormCloseHanlder: function refundFormCloseHanlder() {
      this.closeHandler();
      this.refundForm = {
        action_type: 1,
        refund_date: []
      };
      this.refundAmount = 0;
    }
  }
};
exports.default = _default;