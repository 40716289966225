"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createStore = createStore;
exports.createStoreDevice = createStoreDevice;
exports.deleteStoreDevice = deleteStoreDevice;
exports.fetchDeviceList = fetchDeviceList;
exports.fetchList = fetchList;
exports.fetchOrderList = fetchOrderList;
exports.fetchStore = fetchStore;
exports.getOrderDownloadUrl = getOrderDownloadUrl;
exports.getSimpleList = getSimpleList;
exports.orderRefund = orderRefund;
exports.updateStore = updateStore;
exports.updateStoreDevice = updateStoreDevice;
var _request = _interopRequireDefault(require("@/utils/request"));
function fetchList(query) {
  return (0, _request.default)({
    url: '/shared_stores',
    method: 'get',
    params: query
  });
}
function getSimpleList(query) {
  return (0, _request.default)({
    url: '/shared_store_simple',
    method: 'get',
    params: query
  });
}
function getOrderDownloadUrl(query) {
  return process.env.VUE_APP_BASE_API + '/shared_store_order_download?' + query;
}
function fetchStore(id) {
  return (0, _request.default)({
    url: 'shared_stores/' + id,
    method: 'get'
  });
}
function createStore(data) {
  return (0, _request.default)({
    url: 'shared_stores',
    method: 'post',
    data: data
  });
}
function updateStore(id, data) {
  return (0, _request.default)({
    url: 'shared_stores/' + id,
    method: 'put',
    data: data
  });
}

// export function deleteStore(id) {
//   return request({
//     url: '/shared_stores/' + id,
//     method: 'delete'
//   })
// }

function fetchDeviceList(id, query) {
  return (0, _request.default)({
    url: '/shared_stores/' + id + '/devices',
    method: 'get',
    params: query
  });
}
function createStoreDevice(storeId, data) {
  return (0, _request.default)({
    url: '/shared_stores/' + storeId + '/devices',
    method: 'post',
    data: data
  });
}
function updateStoreDevice(storeId, deviceId, data) {
  return (0, _request.default)({
    url: '/shared_stores/' + storeId + '/devices/' + deviceId,
    method: 'put',
    data: data
  });
}
function deleteStoreDevice(storeId, deviceId) {
  return (0, _request.default)({
    url: '/shared_stores/' + storeId + '/devices/' + deviceId,
    method: 'delete'
  });
}
function fetchOrderList(query) {
  return (0, _request.default)({
    url: '/shared_store_orders',
    method: 'get',
    params: query
  });
}
function orderRefund(id) {
  return (0, _request.default)({
    url: '/shared_store_orders/' + id + '/refund',
    method: 'put'
  });
}