var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "calendar-list-container" },
    [
      _c("div", { staticClass: "filter-container filter-container-flex" }, [
        _c(
          "div",
          [
            _c("el-input", {
              staticClass: "filter-item",
              staticStyle: { width: "200px" },
              attrs: { placeholder: "请输入订单号", clearable: "" },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.handleFilter($event)
                },
              },
              model: {
                value: _vm.listQuery.order_no,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "order_no", $$v)
                },
                expression: "listQuery.order_no",
              },
            }),
            _vm._v(" "),
            _c("el-input", {
              staticClass: "filter-item",
              staticStyle: { width: "200px" },
              attrs: { placeholder: "请输入卡号", clearable: "" },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.handleFilter($event)
                },
              },
              model: {
                value: _vm.listQuery.card_id,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "card_id", $$v)
                },
                expression: "listQuery.card_id",
              },
            }),
            _vm._v(" "),
            _c(
              "el-select",
              {
                staticClass: "filter-item",
                attrs: {
                  filterable: "",
                  clearable: "",
                  placeholder: "请选择门店",
                },
                model: {
                  value: _vm.listQuery.nb_id,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery, "nb_id", $$v)
                  },
                  expression: "listQuery.nb_id",
                },
              },
              _vm._l(_vm.storeList, function (item) {
                return _c("el-option", {
                  key: item.id,
                  attrs: { label: item.name, value: item.id },
                })
              }),
              1
            ),
            _vm._v(" "),
            _c("el-date-picker", {
              staticClass: "filter-item",
              staticStyle: { width: "260px" },
              attrs: {
                type: "daterange",
                "range-separator": "-",
                "start-placeholder": "日期",
                "end-placeholder": "日期",
                "value-format": "yyyy-MM-dd",
                align: "right",
              },
              model: {
                value: _vm.listQuery.settlement_at,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "settlement_at", $$v)
                },
                expression: "listQuery.settlement_at",
              },
            }),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "filter-item",
                attrs: { type: "primary" },
                on: { click: _vm.handleFilter },
              },
              [_vm._v("\n        搜索\n      ")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              { staticClass: "filter-item", on: { click: _vm.handleReset } },
              [_vm._v("\n        重置\n      ")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              { staticClass: "filter-item", on: { click: _vm.exportHandler } },
              [_vm._v("\n        导出\n      ")]
            ),
            _vm._v(" "),
            _c("order-import-button", { on: { success: _vm.getList } }),
            _vm._v(" "),
            _c("order-review-button", { on: { success: _vm.getList } }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          key: "tableList",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            "summary-method": _vm.getSummaries,
            "show-summary": "",
            fit: "",
            "highlight-current-row": "",
            "element-loading-text": "给我一点时间",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "订单号",
              "class-name": "overflow-on",
              prop: "order_no",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.order_no || "--") +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "卡号",
              "class-name": "overflow-on",
              prop: "card_id",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.card_id || "--") +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "门店",
              "class-name": "overflow-on",
              prop: "nb_name",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "类型",
              "class-name": "overflow-on",
              prop: "order_type_name",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "订单金额",
              "class-name": "overflow-on",
              width: "90",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.amount) + "元")])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "结算金额",
              "class-name": "overflow-on",
              width: "90",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(scope.row.settlement_amount) + "元"),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "本金",
              "class-name": "overflow-on",
              width: "90",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(scope.row.balance_amount) + "元"),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "赠送金额",
              "class-name": "overflow-on",
              width: "90",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [_vm._v(_vm._s(scope.row.gift_amount) + "元")]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "渠道",
              "class-name": "overflow-on",
              prop: "order_channel_name",
              width: "90",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "券码",
              "class-name": "overflow-on",
              prop: "coupon_code",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.coupon_code || "--") +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "商品信息",
              "class-name": "overflow-on",
              prop: "sku_name",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.sku_name || "--") +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "日期",
              "class-name": "overflow-on",
              width: "150",
              prop: "settlement_at",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "复核状态",
              "class-name": "overflow-on",
              prop: "verify_status_name",
              width: "90",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "pagination-container text-right" }, [
        _c(
          "div",
          [
            _c("el-pagination", {
              attrs: {
                background: "",
                total: _vm.pagination.total,
                "current-page": _vm.listQuery.page,
                "page-sizes": [10, 20, 50],
                "page-size": _vm.listQuery.limit,
                layout: "total, sizes, prev, pager, next, jumper",
              },
              on: {
                "size-change": _vm.handleSizeChange,
                "current-change": _vm.handleCurrentChange,
              },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }