"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _hotel = require("@/api/hotel");
var _activity = require("@/api/activity");
var _auth = require("@/utils/auth");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "ActivitySpecial",
  data: function data() {
    return {
      areas: [],
      list: [],
      btn: false,
      dialogFormVisible: false,
      listQuery: {
        page: 1,
        limit: 20,
        area_id: "",
        hotel_id: "",
        city: ""
      },
      temp: {
        id: 0,
        hotel_id: '',
        detail: [{
          day: '',
          discount: ''
        }]
      },
      pagination: "",
      dialogTitle: "添加",
      disabledText: false,
      hotelList: [],
      roomList: [],
      typeList: [{
        value: 1,
        label: '平台承担'
      }, {
        value: 2,
        label: '商家承担'
      }]
    };
  },
  created: function created() {
    var _this = this;
    this.getList();
    // var roles = getRoles();
    if ((0, _auth.getGrade)() < 1) {
      (0, _hotel.getArea)().then(function (response) {
        _this.areas = response.data;
      });
    }
    (0, _hotel.getHotelSimpleList)().then(function (response) {
      _this.hotelList = response.data;
    });
  },
  methods: {
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleDetailRedirect: function handleDetailRedirect(id) {
      this.$router.push({
        path: "/hotel/detail/" + id
      });
    },
    handleRoomRedirect: function handleRoomRedirect(id) {
      this.$router.push({
        path: "/hotel/room/" + id
      });
    },
    handleFormRedirect: function handleFormRedirect(id, action) {
      if (action == "create") {
        this.$router.push({
          path: "/hotel/add/"
        });
      } else {
        this.$router.push({
          path: "/hotel/edit/" + id
        });
      }
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    getList: function getList() {
      var _this2 = this;
      this.listLoading = true;
      (0, _activity.connectedOffers)(this.listQuery).then(function (response) {
        var data = response.data;
        _this2.list = data.data;
        _this2.pagination = data;
        _this2.listQuery.page = data.current_page;
        _this2.listLoading = false;
      });
    },
    addBtn: function addBtn() {
      this.temp = {
        id: 0,
        hotel_id: '',
        detail: [{
          day: '',
          discount: ''
        }]
      };
      this.dialogTitle = '添加', this.dialogFormVisible = true;
    },
    removeDomain: function removeDomain(item) {
      var index = this.temp.detail.indexOf(item);
      if (index !== -1) {
        this.temp.detail.splice(index, 1);
      }
    },
    addDomain: function addDomain() {
      this.temp.detail.push({
        day: '',
        discount: ''
      });
    },
    addConnectedOffer: function addConnectedOffer() {
      var _this3 = this;
      if (this.dialogTitle == '添加') {
        (0, _activity.addConnectedOffer)(this.temp).then(function (res) {
          if (res.code == 0) {
            _this3.getList();
            _this3.dialogFormVisible = false;
            _this3.$message.success(res.msg);
          } else {
            _this3.$message.error(res.msg);
          }
        });
      } else {
        this.doEdit(this.temp);
      }
    },
    doEdit: function doEdit(row) {
      var _this4 = this;
      (0, _activity.updateConnectedOffer)(row.id, row).then(function (res) {
        if (res.code == 0) {
          _this4.dialogFormVisible = false;
          _this4.getList();
          _this4.$message.success(res.msg);
        } else {
          _this4.$message.error(res.msg);
        }
      });
    },
    downShelf: function downShelf(row) {
      var _this5 = this;
      this.$confirm('确认下架操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _activity.downConnectedOffer)(row.id).then(function (res) {
          if (res.code == 0) {
            row.status = 2;
            _this5.$message.success(res.msg);
          } else {
            _this5.$message.error(res.msg);
          }
        });
      }).catch(function () {
        // console.log(this.userId, { coupon_id: couponId })
        return;
      });
    },
    changeHotel: function changeHotel() {
      var _this6 = this;
      (0, _activity.hotelList)(this.temp.hotel_id, {
        id: this.temp.id
      }).then(function (res) {
        _this6.roomList = res.data;
      });
    },
    changeRoom: function changeRoom(e) {
      console.log(e);
    },
    editBtn: function editBtn(row) {
      var _this7 = this;
      row.room_id = row.room_id.map(Number);
      this.temp = row;
      this.dialogTitle = '编辑', (0, _activity.hotelList)(this.temp.hotel_id, {
        id: row.id
      }).then(function (res) {
        _this7.roomList = res.data;
      });
      this.dialogFormVisible = true;
    }
  }
};
exports.default = _default;