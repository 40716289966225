"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _mixin = _interopRequireDefault(require("@/utils/mixin"));
var _moment = _interopRequireDefault(require("moment"));
var _finance_reco = require("@/api/finance_reco");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var billCmpStart = 'start_cmp';
var billCmping = 'in_cmp';
var billCmpCompleted = 'completed_cmp';
var billCmpChecked = 'checked_cmp';
var _default = {
  name: "BuildBillButton",
  mixins: [_mixin.default],
  props: {},
  watch: {},
  data: function data() {
    return {
      dialogVisible: false,
      billWeekRange: undefined,
      billExists: false,
      billForm: {
        bill_week: ""
      },
      billCmpStart: billCmpStart,
      billCmping: billCmping,
      billCmpCompleted: billCmpCompleted,
      billCmpChecked: billCmpChecked,
      billComparisonStatus: billCmpStart,
      billComparisonInfo: {
        error: 0
      },
      billFormRules: {
        bill_week: [{
          required: true,
          message: '请选择账单周期',
          trigger: 'blur'
        }]
        // bill_comparison: [{ required: true, message: '请复核订单', trigger: 'blur' }],
      },

      billFormSubmitLoading: false
    };
  },
  mounted: function mounted() {},
  methods: {
    buildBillHandler: function buildBillHandler() {
      this.dialogVisible = true;
      this.billComparisonStatus = billCmpStart;
    },
    onBillWeekChange: function onBillWeekChange(v) {
      var _this = this;
      if (v) {
        this.billWeekRange = this.getWeekRange((0, _moment.default)(v));
        // 校验是否已生成账单
        (0, _finance_reco.checkFinanceBillExists)(this.billForm).then(function (response) {
          _this.billExists = response.data.exists;
          if (response.data.exists) {
            _this.$message.error("当前账单周期已存在账单，请勿重复操作");
          }
        });
      } else {
        this.billWeekRange = undefined;
        this.billExists = false;
      }
      this.billComparisonStatus = billCmpStart;
    },
    billFormCloseHanlder: function billFormCloseHanlder() {
      this.$refs['billForm'].resetFields();
      this.billWeekRange = undefined;
      this.billExists = false;
    },
    billComparisonHandler: function billComparisonHandler() {
      var _this2 = this;
      if (!this.billForm.bill_week) {
        return this.$message.success("请选择账单周期");
      }
      // 请求复核
      this.billComparisonStatus = billCmping;
      (0, _finance_reco.buildFinanceOrderReview)(this.billForm).then(function () {
        _this2.getBillComparisonStatus(_this2.billForm);
      }).catch(function () {
        _this2.billComparisonStatus = billCmpStart;
        // this.$message.success("账单已生成")
      });
    },
    billCmpCheckHandler: function billCmpCheckHandler() {
      this.billComparisonStatus = billCmpChecked;
    },
    getBillComparisonStatus: function getBillComparisonStatus(queryData) {
      var _this3 = this;
      setTimeout(function () {
        (0, _finance_reco.getFinanceOrderReviewSummary)(queryData).then(function (response) {
          // 弹窗关闭，或账期变更，不处理
          if (!_this3.dialogVisible || _this3.billForm.bill_week != queryData.bill_week) {
            return;
          }
          if (response.data.building) {
            _this3.getBillComparisonStatus(queryData);
            return;
          }
          _this3.billComparisonStatus = billCmpCompleted;
          _this3.billComparisonInfo = response.data.comparison_info;
        });
      }, 3000);
    },
    toAbnormal: function toAbnormal() {
      this.$router.push("/bill/finance_order?tab_name=abnormal&bill_week=".concat(this.billForm.bill_week));
      this.dialogVisible = false;
    },
    billFormSubmitHandler: function billFormSubmitHandler() {
      var _this4 = this;
      this.$refs['billForm'].validate(function (valid) {
        if (valid) {
          _this4.billFormSubmitLoading = true;
          (0, _finance_reco.buildFinanceBill)(_this4.billForm).then(function () {
            _this4.$message.success("账单已生成");
            _this4.$emit('success');
            _this4.dialogVisible = false;
          }).finally(function () {
            _this4.billFormSubmitLoading = false;
          });
        }
      });
    }
  }
};
exports.default = _default;