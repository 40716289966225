"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _agent = require("@/api/agent");
var _vDistpicker = _interopRequireDefault(require("v-distpicker"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "AgentForm",
  components: {
    VDistpicker: _vDistpicker.default
  },
  props: {
    id: [Number, String],
    success: Function
  },
  data: function data() {
    return {
      data: {},
      dialogAgentFormVisible: false,
      agentFormSubmitLoading: false,
      dialogAgentFormTitle: "代理商"
    };
  },
  created: function created() {},
  methods: {
    open: function open() {
      var _this = this;
      if (this.id) {
        this.dialogAgentFormTitle = "编辑代理商";
        (0, _agent.fetchAgent)(this.id).then(function (result) {
          _this.data = result.data;
        });
      } else {
        this.data = {
          finance_type: 1,
          status: 1,
          finance_bank_province: "",
          finance_bank_city: ""
        };
        this.dialogAgentFormTitle = "新增代理商";
      }
      this.dialogAgentFormVisible = true;
    },
    onDistSelected: function onDistSelected(data) {
      this.data.finance_bank_province = data.province.value;
      this.data.finance_bank_city = data.city.value;
    },
    agentFormCloseHanlder: function agentFormCloseHanlder() {
      this.$refs['form'].resetFields();
    },
    agentFormSubmitHandler: function agentFormSubmitHandler() {
      var _this2 = this;
      this.$refs['form'].validate(function (valid) {
        if (!valid) {
          return false;
        }
        if (_this2.id) {
          _this2.editHandler();
        } else {
          _this2.addHandler();
        }
      });
    },
    addHandler: function addHandler() {
      var _this3 = this;
      this.agentFormSubmitLoading = true;
      (0, _agent.createAgent)(this.data).then(function () {
        _this3.$message.success("添加成功");
        _this3.$emit('successCallback');
        _this3.dialogAgentFormVisible = false;
        _this3.agentFormSubmitLoading = false;
      }).catch(function () {
        _this3.agentFormSubmitLoading = false;
      });
    },
    editHandler: function editHandler() {
      var _this4 = this;
      this.agentFormSubmitLoading = true;
      (0, _agent.updateAgent)(this.id, this.data).then(function () {
        _this4.$message.success("编辑成功");
        _this4.$emit('successCallback');
        _this4.dialogAgentFormVisible = false;
        _this4.agentFormSubmitLoading = false;
      }).catch(function () {
        _this4.agentFormSubmitLoading = false;
      });
    }
  }
};
exports.default = _default;