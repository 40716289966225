"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.number.constructor");
var _net_bar = require("@/api/net_bar");
var _auth = require("@/utils/auth");
var _image_preview = _interopRequireDefault(require("@/views/components/_image_preview"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: "NetBarFinanceBillCostAddForm",
  components: {
    ImagePreview: _image_preview.default
  },
  props: {
    billId: {
      type: [String, Number]
    },
    visible: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    }
  },
  watch: {
    visible: function visible() {
      var _this = this;
      this.dialogVisible = this.visible;
      if (this.visible) {
        (0, _net_bar.getFinanceBillCostFeeTypeList)().then(function (res) {
          _this.feeTypes = res.data;
        });
      }
    }
  },
  computed: {
    feeTotal: function feeTotal() {
      var total = 0;
      if (this.costForm.costs.length) {
        this.costForm.costs.forEach(function (cost) {
          total += parseFloat(cost.fee) * 100;
        });
      }
      return parseFloat(total / 100);
    }
  },
  data: function data() {
    return {
      feeTypes: [],
      dialogVisible: false,
      formLoading: false,
      submitLoading: false,
      submitDisabled: false,
      costForm: {
        costs: []
      },
      costItemForm: {
        fee_type: undefined,
        fee: undefined,
        fee_desc: undefined,
        voucher_type: undefined,
        voucher_i8: undefined,
        voucher_file: []
      },
      costItemFormRules: {
        fee_type: [{
          required: true,
          message: '请选择费用类型',
          trigger: 'change'
        }],
        fee: [{
          required: true,
          message: '请输入费用金额',
          trigger: 'blur'
        }],
        fee_desc: [{
          required: true,
          message: '请输入费用说明',
          trigger: 'blur'
        }],
        voucher_type: [{
          required: true,
          message: '请选择费用依据',
          trigger: 'change'
        }],
        voucher_i8: [{
          required: true,
          message: '请输入i8流程号',
          trigger: 'blur'
        }],
        voucher_file: [{
          required: true,
          message: '请上传凭证',
          trigger: 'blur'
        }]
      },
      costItemEditIndex: undefined,
      uploadUrl: "",
      header: {
        Authorization: "Bearer " + (0, _auth.getToken)()
      },
      voucherImages: [],
      previewImages: [],
      imagePreviewVisible: false
    };
  },
  mounted: function mounted() {
    this.uploadUrl = (0, _net_bar.getNetBarUploadUrl)();
  },
  methods: {
    getFeeTypeName: function getFeeTypeName(feeType) {
      for (var i = 0; i < this.feeTypes.length; i++) {
        if (this.feeTypes[i].value == feeType) {
          return this.feeTypes[i].label;
        }
      }
    },
    resetCostItemForm: function resetCostItemForm() {
      this.voucherImages = [];
      this.$refs['costItemForm'].resetFields();
    },
    getCostItem: function getCostItem() {
      var voucherDesc = "";
      if (this.costItemForm.voucher_type == 1) {
        voucherDesc = this.costItemForm.voucher_i8;
      } else if (this.costItemForm.voucher_type == 2) {
        voucherDesc = this.costItemForm.voucher_file;
      }
      return {
        fee_type: this.costItemForm.fee_type,
        fee: this.costItemForm.fee,
        fee_desc: this.costItemForm.fee_desc,
        voucher_type: this.costItemForm.voucher_type,
        voucher_desc: voucherDesc
      };
    },
    addCostItem: function addCostItem() {
      var _this2 = this;
      this.costItemForm.voucher_file = [];
      this.voucherImages.forEach(function (image) {
        _this2.costItemForm.voucher_file.push(image.response ? image.response.data.filepath : image.url);
      });
      this.$refs['costItemForm'].validate(function (valid) {
        if (valid) {
          _this2.costForm.costs.push(_this2.getCostItem());
          _this2.resetCostItemForm();
        }
      });
    },
    editCostItem: function editCostItem(index) {
      var _this3 = this;
      this.costItemEditIndex = index;
      var cost = this.costForm.costs[index];
      this.voucherImages = [];
      if (cost.voucher_type == 2 && cost.voucher_desc) {
        cost.voucher_desc.forEach(function (image, k) {
          _this3.voucherImages.push({
            name: "image_".concat(k, ".jpg"),
            url: image
          });
        });
      }
      this.costItemForm = {
        fee_type: cost.fee_type,
        fee: cost.fee,
        fee_desc: cost.fee_desc,
        voucher_type: cost.voucher_type,
        voucher_i8: cost.voucher_type == 1 ? cost.voucher_desc : undefined,
        voucher_file: cost.voucher_type == 2 ? cost.voucher_desc : []
      };
    },
    doEditCostItem: function doEditCostItem() {
      var _this4 = this;
      if (this.costItemEditIndex == undefined) {
        return;
      }
      if (!this.costForm.costs[this.costItemEditIndex]) {
        this.costItemEditIndex = undefined;
        this.resetCostItemForm();
        return;
      }
      this.costItemForm.voucher_file = [];
      this.voucherImages.forEach(function (image) {
        _this4.costItemForm.voucher_file.push(image.response ? image.response.data.filepath : image.url);
      });
      this.$refs['costItemForm'].validate(function (valid) {
        if (valid) {
          _this4.costForm.costs.splice(_this4.costItemEditIndex, 1, _this4.getCostItem());
          _this4.resetCostItemForm();
        }
      });
    },
    removeCostItem: function removeCostItem(index) {
      if (this.costForm.costs[index]) {
        if (this.costItemEditIndex == index) {
          this.costItemEditIndex = undefined;
          this.resetCostItemForm();
          return;
        }
        this.costForm.costs.splice(index, 1);
      }
    },
    showVoucherFiles: function showVoucherFiles(files) {
      this.previewImages = files;
      this.imagePreviewVisible = true;
    },
    beforeUpload: function beforeUpload(file) {
      var isJPG = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
      if (!isJPG) {
        this.$message.error('请上传jpg/png/jpeg格式的图片!');
      }
      var isLt10M = file.size / 1024 / 1024 < 10;
      if (!isLt10M) {
        this.$message.error('上传图片大小不能超过 10MB!');
      }
      return isJPG && isLt10M;
    },
    onUploadProgress: function onUploadProgress() {
      this.submitDisabled = true;
    },
    onUploadSuccess: function onUploadSuccess() {
      this.submitDisabled = false;
    },
    onUploadChange: function onUploadChange(file, fileList) {
      this.voucherImages = fileList;
    },
    closeHanlder: function closeHanlder() {
      this.costForm.costs = [];
      this.$refs['costItemForm'].resetFields();
      this.$emit('update:visible', false);
    },
    submitHandler: function submitHandler() {
      var _this5 = this;
      if (!this.costForm.costs.length) {
        this.$message.error('费用信息不能为空');
        return;
      }
      this.submitLoading = true;
      (0, _net_bar.createFinanceBillCost)(this.billId, this.costForm).then(function () {
        _this5.$message.success("操作成功");
        _this5.$emit('success');
        _this5.dialogVisible = false;
      }).finally(function () {
        _this5.submitLoading = false;
      });
    }
  }
};
exports.default = _default2;