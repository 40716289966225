"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _net_bar = require("@/api/net_bar");
var _order_detail = _interopRequireDefault(require("@/views/net_bar/order/order_detail"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "MemberNetBarOrder",
  components: {
    NetBarOrderDetail: _order_detail.default
  },
  props: {
    userId: {
      type: [String, Number]
    }
  },
  data: function data() {
    return {
      statusList: [{
        value: 30,
        label: "使用中"
      }, {
        value: 40,
        label: "已完成"
      }],
      list: [],
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 20,
        user_id: ""
      },
      pagination: "",
      orderId: "",
      orderDetailVisible: false
    };
  },
  created: function created() {
    this.listQuery.user_id = this.userId;
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      (0, _net_bar.fetchOrderList)(this.listQuery).then(function (response) {
        var data = response.data;
        _this.list = data.data;
        _this.pagination = data;
        _this.listQuery.page = data.current_page;
        _this.listLoading = false;
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    getUseTime: function getUseTime(order) {
      var startTime = new Date(order.started_at).getTime() / 1000;
      var useTime = 0;
      if (order.status == 40) {
        var endTime = new Date(order.ended_at).getTime() / 1000;
        useTime = endTime - startTime;
      } else if (order.status == 30) {
        var now = new Date().getTime() / 1000;
        useTime = now - startTime;
      }
      // to H时m分
      var format = '';
      var h = parseInt(useTime / 3600);
      if (h > 0) format += "".concat(h, "\u5C0F\u65F6");
      var m = parseInt(useTime % 3600 / 60);
      if (m > 0) format += "".concat(m, "\u5206\u949F");
      return format;
    },
    detailHandler: function detailHandler(record) {
      this.orderId = record.id;
      this.orderDetailVisible = true;
    }
  }
};
exports.default = _default;