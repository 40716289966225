"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _member = require("@/api/member");
var _order = require("@/api/order");
var _order_detail = _interopRequireDefault(require("./order_detail"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "MemberCoupon",
  components: {
    MemberOrderDetail: _order_detail.default
  },
  props: {
    userId: {
      type: [String, Number]
    }
  },
  data: function data() {
    return {
      list: [],
      pagination: {},
      listLoading: false,
      statusList: [],
      orderId: "",
      orderDetailVisible: false
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;
      this.couponLoading = true;
      (0, _member.memberCoupon)(this.userId).then(function (res) {
        _this.list = res.data.data;
        _this.statusList = res.data.statusList;
        _this.listLoading = false;
      });
    },
    wirteOffHandler: function wirteOffHandler(row) {
      var _this2 = this;
      (0, _member.writeOffCoupon)(row.user_id, [row.id]).then(function () {
        _this2.getList();
      });
    },
    handleOrderDetail: function handleOrderDetail(userId, couponId) {
      var _this3 = this;
      (0, _order.orderByCouponId)(userId, couponId).then(function (response) {
        _this3.orderId = response.data.id;
        _this3.orderDetailVisible = true;
      });
    }
  }
};
exports.default = _default;