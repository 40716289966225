var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "agent-form-dialog",
      attrs: {
        visible: _vm.dialogAgentFormVisible,
        title: _vm.dialogAgentFormTitle,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogAgentFormVisible = $event
        },
        close: _vm.agentFormCloseHanlder,
      },
    },
    [
      _c(
        "el-form",
        { ref: "form", attrs: { "label-suffix": ":", model: _vm.data } },
        [
          _c("el-divider", { attrs: { "content-position": "left" } }, [
            _vm._v("基本信息"),
          ]),
          _vm._v(" "),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "名称",
                        "label-width": "85px",
                        required: "",
                        prop: "name",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入" },
                        model: {
                          value: _vm.data.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.data, "name", $$v)
                          },
                          expression: "data.name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "联系人",
                        "label-width": "85px",
                        required: "",
                        prop: "contact",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入" },
                        model: {
                          value: _vm.data.contact,
                          callback: function ($$v) {
                            _vm.$set(_vm.data, "contact", $$v)
                          },
                          expression: "data.contact",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "联系方式",
                        "label-width": "85px",
                        required: "",
                        prop: "phone",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入" },
                        model: {
                          value: _vm.data.phone,
                          callback: function ($$v) {
                            _vm.$set(_vm.data, "phone", $$v)
                          },
                          expression: "data.phone",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "状态",
                        "label-width": "85px",
                        required: "",
                        prop: "status",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.data.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "status", $$v)
                            },
                            expression: "data.status",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "合作中", value: 1 },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "已锁定", value: 0 },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "备注",
                        "label-width": "85px",
                        prop: "remark",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入",
                          type: "textarea",
                          autosize: { minRows: 2, maxRows: 4 },
                        },
                        model: {
                          value: _vm.data.remark,
                          callback: function ($$v) {
                            _vm.$set(_vm.data, "remark", $$v)
                          },
                          expression: "data.remark",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-divider", { attrs: { "content-position": "left" } }, [
            _vm._v("财务信息"),
          ]),
          _vm._v(" "),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "账户性质",
                        "label-width": "85px",
                        required: "",
                        prop: "finance_type",
                      },
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.data.finance_type,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "finance_type", $$v)
                            },
                            expression: "data.finance_type",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("对公"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("对私"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "账户名称",
                        "label-width": "85px",
                        required: "",
                        prop: "finance_account",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入" },
                        model: {
                          value: _vm.data.finance_account,
                          callback: function ($$v) {
                            _vm.$set(_vm.data, "finance_account", $$v)
                          },
                          expression: "data.finance_account",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "银行账号",
                        "label-width": "85px",
                        required: "",
                        prop: "finance_bank_account",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入" },
                        model: {
                          value: _vm.data.finance_bank_account,
                          callback: function ($$v) {
                            _vm.$set(_vm.data, "finance_bank_account", $$v)
                          },
                          expression: "data.finance_bank_account",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "开户行",
                        "label-width": "85px",
                        required: "",
                        prop: "finance_bank",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入" },
                        model: {
                          value: _vm.data.finance_bank,
                          callback: function ($$v) {
                            _vm.$set(_vm.data, "finance_bank", $$v)
                          },
                          expression: "data.finance_bank",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "开户行所在省市",
                        "label-width": "125px",
                        required: "",
                        prop: "finance_bank_city",
                      },
                    },
                    [
                      _c("v-distpicker", {
                        attrs: {
                          province: _vm.data.finance_bank_province,
                          city: _vm.data.finance_bank_city,
                          "hide-area": "",
                        },
                        on: { selected: _vm.onDistSelected },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 18 } },
                [
                  _vm.data.finance_type == 2
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "收款人身份证号",
                            "label-width": "125px",
                            required: "",
                            prop: "finance_idcard",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入" },
                            model: {
                              value: _vm.data.finance_idcard,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "finance_idcard", $$v)
                              },
                              expression: "data.finance_idcard",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogAgentFormVisible = false
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.agentFormSubmitLoading },
              on: { click: _vm.agentFormSubmitHandler },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }