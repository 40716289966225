var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "form-dialog",
      attrs: {
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        title: "新增设备",
        width: "640px",
        top: "10vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.closeHanlder,
      },
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.formLoading,
              expression: "formLoading",
            },
          ],
          ref: "deviceForm",
          attrs: {
            model: _vm.deviceForm,
            rules: _vm.deviceFormRules,
            "label-suffix": ":",
            "label-width": "85px",
            "label-position": "right",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "设备信息", required: "" } },
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.deviceForm.devices,
                    stripe: "",
                    border: "",
                    size: "mini",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "门店设备编号",
                      "class-name": "overflow-on",
                      width: "180",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("el-input", {
                              attrs: { placeholder: "请输入门店设备编号" },
                              model: {
                                value: scope.row.name,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "name", $$v)
                                },
                                expression: "scope.row.name",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "设备编号",
                      "class-name": "overflow-on",
                      "min-width": "240",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("el-input", {
                              attrs: {
                                placeholder: "请保证与无盘系统内设备ID一致",
                              },
                              model: {
                                value: scope.row.code,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "code", $$v)
                                },
                                expression: "scope.row.code",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      "class-name": "overflow-on",
                      "min-width": "60",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var scope = ref.scope
                          var $index = ref.$index
                          return [
                            $index > 0
                              ? _c(
                                  "el-button",
                                  {
                                    staticStyle: { color: "#F56C6C" },
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.removeDeviceItem($index)
                                      },
                                    },
                                  },
                                  [_vm._v("移除")]
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.deviceFormDeviceError
                ? _c("div", { staticClass: "el-form-item__error" }, [
                    _vm._v("\n        请完善设备信息\n      "),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "device-add-btn",
                  on: { click: _vm.addDeviceItem },
                },
                [
                  _c("i", { staticClass: "el-icon-plus device-plus-icon" }),
                  _vm._v("添加\n      "),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "显卡", prop: "graphic_card" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "260px" },
                  attrs: { placeholder: "请选择显卡" },
                  model: {
                    value: _vm.deviceForm.graphic_card,
                    callback: function ($$v) {
                      _vm.$set(_vm.deviceForm, "graphic_card", $$v)
                    },
                    expression: "deviceForm.graphic_card",
                  },
                },
                _vm._l(_vm.deviceConfigurations.graphic_card, function (item) {
                  return _c("el-option", {
                    key: item,
                    attrs: { label: item, value: item },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "显示器", prop: "display" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "260px" },
                  attrs: { placeholder: "请选择显示器" },
                  model: {
                    value: _vm.deviceForm.display,
                    callback: function ($$v) {
                      _vm.$set(_vm.deviceForm, "display", $$v)
                    },
                    expression: "deviceForm.display",
                  },
                },
                _vm._l(_vm.deviceConfigurations.display, function (item) {
                  return _c("el-option", {
                    key: item,
                    attrs: { label: item, value: item },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "CPU", prop: "cpu" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "260px" },
                  attrs: { placeholder: "请选择 CPU" },
                  model: {
                    value: _vm.deviceForm.cpu,
                    callback: function ($$v) {
                      _vm.$set(_vm.deviceForm, "cpu", $$v)
                    },
                    expression: "deviceForm.cpu",
                  },
                },
                _vm._l(_vm.deviceConfigurations.cpu, function (item) {
                  return _c("el-option", {
                    key: item,
                    attrs: { label: item, value: item },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "内存", prop: "ram" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "260px" },
                  attrs: { placeholder: "请选择内存" },
                  model: {
                    value: _vm.deviceForm.ram,
                    callback: function ($$v) {
                      _vm.$set(_vm.deviceForm, "ram", $$v)
                    },
                    expression: "deviceForm.ram",
                  },
                },
                _vm._l(_vm.deviceConfigurations.ram, function (item) {
                  return _c("el-option", {
                    key: item,
                    attrs: { label: item, value: item },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitLoading },
              on: { click: _vm.submitHandler },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }