"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _shared_store = require("@/api/shared_store");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "StoreDetail",
  data: function data() {
    return {
      activeName: "device",
      storeId: undefined,
      store: {
        info: {},
        finance: {}
      },
      list: [],
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 20,
        code: ""
      },
      pagination: "",
      addDeviceForm: {
        devices: [{
          code: ''
        }]
      },
      addDeviceFormDialogVisible: false,
      addDeviceFormSubmitLoading: false,
      editDeviceForm: {
        code: ''
      },
      editDeviceFormDialogVisible: false,
      editDeviceFormSubmitLoading: false,
      // 使用记录
      deviceOrdersDialogVisible: false,
      orderList: [],
      orderListLoading: false,
      orderPagination: {
        page: 1,
        limit: 10,
        total: 0
      },
      deleteDeviceVisible: true
    };
  },
  created: function created() {
    this.storeId = this.$route.params.id;
    this.getStore();
    this.getList();
  },
  methods: {
    getStore: function getStore() {
      var _this = this;
      (0, _shared_store.fetchStore)(this.storeId).then(function (result) {
        _this.store = result.data;
      });
    },
    getList: function getList() {
      var _this2 = this;
      this.listLoading = true;
      (0, _shared_store.fetchDeviceList)(this.storeId, this.listQuery).then(function (response) {
        var data = response.data;
        _this2.list = data.data;
        _this2.pagination = data;
        _this2.listQuery.page = data.current_page;
        _this2.listLoading = false;
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    getUseTime: function getUseTime(order) {
      var startTime = new Date(order.started_at).getTime() / 1000;
      var useTime = 0;
      if (order.status == 40) {
        var endTime = new Date(order.ended_at).getTime() / 1000;
        useTime = endTime - startTime;
      } else if (order.status == 30) {
        var now = new Date().getTime() / 1000;
        useTime = now - startTime;
      }

      // to H时m分
      var format = '';
      var h = parseInt(useTime / 3600);
      if (h > 0) format += "".concat(h, "\u5C0F\u65F6");
      var m = parseInt(useTime % 3600 / 60);
      if (m > 0) format += "".concat(m, "\u5206\u949F");
      return format;
    },
    editStoreHandler: function editStoreHandler() {
      this.$router.push({
        path: "/shared_store/edit/" + this.storeId + '?form=detail'
      });
    },
    addDeviceHandler: function addDeviceHandler() {
      this.addDeviceForm = {
        devices: [{
          code: ''
        }]
      };
      this.addDeviceFormDialogVisible = true;
    },
    addDeviceItem: function addDeviceItem() {
      this.addDeviceForm.devices.push({
        code: ''
      });
    },
    removeDeviceItem: function removeDeviceItem(index) {
      this.addDeviceForm.devices.splice(index, 1);
    },
    addDeviceFormSubmitHandler: function addDeviceFormSubmitHandler() {
      var _this3 = this;
      this.$refs['addDeviceForm'].validate(function (valid) {
        if (valid) {
          var codes = [];
          _this3.addDeviceForm.devices.forEach(function (item) {
            codes.push(item.code);
          });
          _this3.addDeviceFormSubmitLoading = true;
          (0, _shared_store.createStoreDevice)(_this3.storeId, {
            code: codes
          }).then(function () {
            _this3.addDeviceFormDialogVisible = false;
            _this3.addDeviceFormSubmitLoading = false;
            _this3.getList();
            _this3.$message.success("添加成功");
          }).catch(function () {
            _this3.addDeviceFormSubmitLoading = false;
          });
        }
      });
    },
    editDeviceHandler: function editDeviceHandler(device) {
      this.editDeviceForm = {
        id: device.id,
        code: device.code
      };
      this.editDeviceFormDialogVisible = true;
    },
    editDeviceFormSubmitHandler: function editDeviceFormSubmitHandler() {
      var _this4 = this;
      this.$refs['editDeviceForm'].validate(function (valid) {
        if (valid) {
          (0, _shared_store.updateStoreDevice)(_this4.storeId, _this4.editDeviceForm.id, _this4.editDeviceForm).then(function () {
            _this4.editDeviceFormDialogVisible = false;
            _this4.editDeviceFormSubmitLoading = false;
            _this4.getList();
            _this4.$message.success("编辑成功");
          }).catch(function () {
            _this4.editDeviceFormSubmitLoading = false;
          });
        }
      });
    },
    showOrdersHandler: function showOrdersHandler(deviceId) {
      this.getOrderList(deviceId);
      this.deviceOrdersDialogVisible = true;
    },
    getOrderList: function getOrderList(deviceId) {
      var _this5 = this;
      this.orderListLoading = true;
      (0, _shared_store.fetchOrderList)({
        store_id: this.storeId,
        device_id: deviceId,
        status: [30, 40],
        page: this.orderPagination.page,
        limit: this.orderPagination.limit
      }).then(function (response) {
        var data = response.data;
        _this5.orderList = data.data;
        _this5.orderPagination.page = data.current_page;
        _this5.orderPagination.total = data.total;
        _this5.orderListLoading = false;
      });
    },
    handleOrderSizeChange: function handleOrderSizeChange(val) {
      this.orderPagination.limit = val;
      this.getOrderList();
    },
    handleOrderCurrentChange: function handleOrderCurrentChange(val) {
      this.orderPagination.page = val;
      this.getOrderList();
    },
    deleteDeviceHandler: function deleteDeviceHandler(deviceId) {
      var _this6 = this;
      this.$confirm('确定要删除该设备吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _shared_store.deleteStoreDevice)(_this6.storeId, deviceId).then(function () {
          _this6.getList();
          _this6.$message.success("操作成功");
        });
      });
    }
  }
};
exports.default = _default;