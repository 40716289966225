"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _mixin = _interopRequireDefault(require("@/utils/mixin"));
var _moment = _interopRequireDefault(require("moment"));
var _finance_reco = require("@/api/finance_reco");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var billCmpStart = 'start_cmp';
var billCmping = 'in_cmp';
var billCmpCompleted = 'completed_cmp';
var _default = {
  name: "OtaOrderResyncButton",
  mixins: [_mixin.default],
  props: {},
  watch: {},
  data: function data() {
    return {
      dialogVisible: false,
      billWeekRange: undefined,
      billExists: false,
      resyncForm: {
        bill_week: ""
      },
      billCmpStart: billCmpStart,
      billCmping: billCmping,
      billCmpCompleted: billCmpCompleted,
      billComparisonStatus: billCmpStart,
      billComparisonInfo: {
        error: 0
      },
      resyncFormRules: {
        bill_week: [{
          required: true,
          message: '请选择账单周期',
          trigger: 'blur'
        }]
      },
      resyncFormSubmitLoading: false
    };
  },
  mounted: function mounted() {},
  methods: {
    resyncHandler: function resyncHandler() {
      this.dialogVisible = true;
      this.resyncFormSubmitLoading = false;
      this.billComparisonStatus = billCmpStart;
    },
    onBillWeekChange: function onBillWeekChange(v) {
      var _this = this;
      if (v) {
        this.billWeekRange = this.getWeekRange((0, _moment.default)(v));
        // 校验是否已生成账单
        (0, _finance_reco.checkFinanceBillExists)(this.resyncForm).then(function (response) {
          _this.billExists = response.data.exists;
          // if (response.data.exists) {
          //   this.$message.error("当前账单周期已存在账单，请勿重复操作")
          // }
        });
      } else {
        this.billWeekRange = undefined;
        this.billExists = false;
      }
      this.billComparisonStatus = billCmpStart;
    },
    resyncFormCloseHanlder: function resyncFormCloseHanlder() {
      this.$refs['resyncForm'].resetFields();
      this.billWeekRange = undefined;
      this.billExists = false;
    },
    getBillComparisonStatus: function getBillComparisonStatus(queryData) {
      var _this2 = this;
      setTimeout(function () {
        (0, _finance_reco.getFinanceOrderReviewSummary)(queryData).then(function (response) {
          // 弹窗关闭，或账期变更，不处理
          if (!_this2.dialogVisible || _this2.resyncForm.bill_week != queryData.bill_week) {
            return;
          }
          if (response.data.building) {
            _this2.getBillComparisonStatus(queryData);
            return;
          }
          _this2.billComparisonStatus = billCmpCompleted;
          _this2.billComparisonInfo = response.data.comparison_info;
          _this2.resyncFormSubmitLoading = false;
          _this2.$message.success("更新订单完成");
          _this2.$emit('success');
        });
      }, 3000);
    },
    resyncFormSubmitHandler: function resyncFormSubmitHandler() {
      var _this3 = this;
      this.$refs['resyncForm'].validate(function (valid) {
        if (valid) {
          var weekRange = _this3.getWeekRange(_this3.resyncForm.bill_week);
          _this3.$confirm("\u5F53\u524D\u5BF9\u8D26\u5468\u671F\u3010".concat(weekRange[0], " \u81F3 ").concat(weekRange[1], "\u3011\uFF0C\u66F4\u65B0\u8BA2\u5355\u64CD\u4F5C\u4F1A\u5148\u79FB\u9664\u5DF2\u540C\u6B65\u7B2C\u4E09\u65B9\u8BA2\u5355\u540E\u91CD\u65B0\u540C\u6B65\uFF0C\u786E\u8BA4\u540C\u6B65\u7B2C\u4E09\u65B9\u8BA2\u5355\u5417?"), '提示', {
            title: '更新订单',
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(function () {
            _this3.resyncFormSubmitLoading = true;
            _this3.billComparisonStatus = billCmping;
            (0, _finance_reco.resyncOtaOrder)(_this3.resyncForm).then(function () {
              _this3.getBillComparisonStatus(_this3.resyncForm);
            }).catch(function () {
              _this3.resyncFormSubmitLoading = false;
              _this3.billComparisonStatus = billCmpStart;
            });
          }).catch(function () {});
        }
      });
    }
  }
};
exports.default = _default;