var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", { style: { color: _vm.getHighlightColor() } }, [
    _vm._v("\n  " + _vm._s(_vm.number) + _vm._s(_vm.suffix) + "\n"),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }