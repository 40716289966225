"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _member = require("@/api/member");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "MemberLockRecord",
  components: {},
  props: {
    userId: {
      type: [String, Number]
    }
  },
  data: function data() {
    return {
      list: [],
      pagination: {},
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 20
      }
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;
      (0, _member.lockRecord)(this.userId, {
        "page": this.pagination.current_page
      }).then(function (res) {
        _this.list = res.data.data;
        _this.pagination = res.data;
      });
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    }
  }
};
exports.default = _default;