var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container calendar-list-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: { placeholder: "订单号" },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.handleFilter($event)
              },
            },
            model: {
              value: _vm.listQuery.order_no,
              callback: function ($$v) {
                _vm.$set(_vm.listQuery, "order_no", $$v)
              },
              expression: "listQuery.order_no",
            },
          }),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.handleFilter },
            },
            [_vm._v("\n      查询\n    ")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            { staticClass: "filter-item", on: { click: _vm.resetForm } },
            [_vm._v("\n      重置\n    ")]
          ),
          _vm._v(" "),
          _c(
            "el-badge",
            {
              staticStyle: { "margin-left": "10px", "margin-right": "10px" },
              attrs: { "is-dot": _vm.hasFilter },
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  on: { click: _vm.filterFormHandler },
                },
                [_vm._v("\n        筛选\n      ")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            { staticClass: "filter-item", on: { click: _vm.download } },
            [_vm._v("\n      全部导出\n    ")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          key: "tableList",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            "element-loading-text": "给我一点时间",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "订单号", width: "240" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.order_no))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              "show-overflow-tooltip": true,
              label: "酒店房型",
              width: "240",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [_vm._v(_vm._s(scope.row.hotel_name))]),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(scope.row.room_name))]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "结算模式" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        " " + _vm._s(_vm.modeNameList[scope.row.hotel_mode])
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "是否续房" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.type == 1
                      ? _c("span", [
                          _vm._v("房间：" + _vm._s(scope.row.room_desc)),
                        ])
                      : _c("span", [_vm._v("否")]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "入/离时间", "max-width": "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm._f("dateFormat")(scope.row.start_date))
                      ),
                    ]),
                    _vm._v("/"),
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm._f("dateFormat")(scope.row.end_date)) +
                          " " +
                          _vm._s(scope.row.days) +
                          "晚"
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "实付金额" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.getRealAmount(scope.row, "amount")) + "元"
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "飞火佣金" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.getRealAmount(scope.row, "commission")) +
                          "元"
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "商家应收" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.getRealAmount(scope.row, "store_amount")) +
                          "元"
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "顾客", "max-width": "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.contacts))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "状态", "max-width": "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.statusMap[scope.row.status])),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "支付时间", "max-width": "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.pay_at))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              fixed: "right",
              align: "left",
              label: "操作",
              width: "180",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handleDetail(scope.row.id)
                          },
                        },
                      },
                      [_vm._v("\n          查看\n        ")]
                    ),
                    _vm._v(" "),
                    scope.row.status == 30
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleOrderChangeStatus(
                                  scope.row.id,
                                  40
                                )
                              },
                            },
                          },
                          [_vm._v("\n          已入住\n        ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status == 25
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleOrderChangeStatus(
                                  scope.row.id,
                                  30
                                )
                              },
                            },
                          },
                          [_vm._v("\n          确认\n        ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status == 25
                      ? _c(
                          "el-button",
                          {
                            staticStyle: { color: "#F56C6C" },
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleOrderChangeStatus(
                                  scope.row.id,
                                  27
                                )
                              },
                            },
                          },
                          [_vm._v("\n          拒绝\n        ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status == 30
                      ? _c(
                          "el-button",
                          {
                            staticStyle: { color: "#E6A23C" },
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleCancel(scope.row.id)
                              },
                            },
                          },
                          [_vm._v("\n          取消\n        ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status == 40
                      ? _c(
                          "el-button",
                          {
                            staticStyle: { color: "#E6A23C" },
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleRefund(scope.row.id)
                              },
                            },
                          },
                          [_vm._v("\n          退款\n        ")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "pagination-container text-right" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              total: _vm.pagination.total,
              "current-page": _vm.listQuery.page,
              "page-sizes": [10, 20, 50],
              "page-size": _vm.listQuery.limit,
              layout: "total, sizes, prev, pager, next, jumper",
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogDetailVisible, title: "订单详情" },
          on: {
            "update:visible": function ($event) {
              _vm.dialogDetailVisible = $event
            },
          },
        },
        [_c("order-detail", { attrs: { id: _vm.orderId } })],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogfilterVisible,
            title: "订单筛选",
            width: "680px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogfilterVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "filterForm",
              attrs: {
                model: _vm.filterForm,
                "label-suffix": ":",
                "label-width": "85px",
                "label-position": "left",
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "所属酒店", prop: "hotel_id" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "filter-item",
                              attrs: { filterable: "", placeholder: "请选择" },
                              model: {
                                value: _vm.filterForm.hotel_id,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filterForm, "hotel_id", $$v)
                                },
                                expression: "filterForm.hotel_id",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { value: "", label: "全部" },
                              }),
                              _vm._v(" "),
                              _vm._l(_vm.hotelList, function (item) {
                                return _c("el-option", {
                                  key: item.hotel_id,
                                  attrs: {
                                    label: item.name,
                                    value: item.hotel_id,
                                  },
                                })
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "所属区域", prop: "area_id" } },
                        [
                          _vm.areas.length > 0
                            ? _c(
                                "el-select",
                                {
                                  staticClass: "filter-item",
                                  attrs: {
                                    "default-first-option": "",
                                    placeholder: "请选择",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.filterForm.area_id,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.filterForm, "area_id", $$v)
                                    },
                                    expression: "filterForm.area_id",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { value: "", label: "全部" },
                                  }),
                                  _vm._v(" "),
                                  _vm._l(_vm.areas, function (item) {
                                    return _c("el-option", {
                                      key: item.role_id,
                                      attrs: {
                                        label: item.name,
                                        value: item.role_id,
                                      },
                                    })
                                  }),
                                ],
                                2
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "支付日期", prop: "pay_at" } },
                        [
                          _c("el-date-picker", {
                            staticClass: "filter-item",
                            attrs: {
                              type: "daterange",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "结束日期",
                              "value-format": "yyyyMMdd",
                              align: "right",
                            },
                            model: {
                              value: _vm.filterForm.pay_at,
                              callback: function ($$v) {
                                _vm.$set(_vm.filterForm, "pay_at", $$v)
                              },
                              expression: "filterForm.pay_at",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "入住时间", prop: "start_date" } },
                        [
                          _c("el-date-picker", {
                            staticClass: "filter-item",
                            attrs: {
                              type: "daterange",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "结束日期",
                              "value-format": "yyyyMMdd",
                              align: "right",
                            },
                            model: {
                              value: _vm.filterForm.start_date,
                              callback: function ($$v) {
                                _vm.$set(_vm.filterForm, "start_date", $$v)
                              },
                              expression: "filterForm.start_date",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "离店时间", prop: "end_date" } },
                        [
                          _c("el-date-picker", {
                            staticClass: "filter-item",
                            attrs: {
                              type: "daterange",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "结束日期",
                              "value-format": "yyyyMMdd",
                              align: "right",
                            },
                            model: {
                              value: _vm.filterForm.end_date,
                              callback: function ($$v) {
                                _vm.$set(_vm.filterForm, "end_date", $$v)
                              },
                              expression: "filterForm.end_date",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "订单状态", prop: "status" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "filter-item",
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.filterForm.status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filterForm, "status", $$v)
                                },
                                expression: "filterForm.status",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { value: "", label: "全部" },
                              }),
                              _vm._v(" "),
                              _vm._l(_vm.statusOptions, function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "酒店模式", prop: "mode" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "filter-item",
                              attrs: {
                                filterable: "",
                                clearable: "",
                                placeholder: "请选择",
                              },
                              model: {
                                value: _vm.filterForm.mode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filterForm, "mode", $$v)
                                },
                                expression: "filterForm.mode",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { value: "", label: "全部" },
                              }),
                              _vm._v(" "),
                              _vm._l(_vm.modeList, function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "入住人" } },
                        [
                          _c("el-input", {
                            staticClass: "filter-item",
                            staticStyle: { width: "200px" },
                            attrs: { placeholder: "不限" },
                            model: {
                              value: _vm.filterForm.contacts,
                              callback: function ($$v) {
                                _vm.$set(_vm.filterForm, "contacts", $$v)
                              },
                              expression: "filterForm.contacts",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c("el-button", { on: { click: _vm.closeFilter } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                { staticClass: "filter-item", on: { click: _vm.resetFilter } },
                [_vm._v("\n        重置\n      ")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.onFilterSubmit },
                },
                [_vm._v("\n        筛 选\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("order-refund-dialog", {
        attrs: {
          visible: _vm.refundOrderVisible,
          "order-id": _vm.refundOrderId,
        },
        on: {
          "update:visible": function ($event) {
            _vm.refundOrderVisible = $event
          },
          succss: _vm.getList,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }