"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.array.sort");
require("core-js/modules/es6.number.constructor");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: "FitterSelectMult",
  props: {
    title: {
      type: [Number, String],
      default: function _default() {
        return "";
      }
    },
    width: {
      type: Number,
      default: function _default() {
        return 170;
      }
    },
    height: {
      type: Number,
      default: function _default() {
        return 200;
      }
    },
    col: {
      type: Number,
      default: function _default() {
        return 1;
      }
    },
    options: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    defaultValue: {
      type: Object,
      default: function _default() {
        return {
          checkAll: false,
          checkedList: []
        };
      }
    }
  },
  watch: {
    options: function options(value) {
      this.checkboxOptions = value;
      this.init();
    }
  },
  data: function data() {
    return {
      chooseVisable: false,
      indeterminate: true,
      checkboxOptions: [],
      checkAll: false,
      checkedList: [],
      chooseIconClass: "",
      checkDefaultValue: {},
      initialState: {
        checkAll: false,
        checkedList: []
      }
    };
  },
  created: function created() {
    this.checkboxOptions = this.options;
    this.checkDefaultValue = this.defaultValue;
    this.init();
  },
  methods: {
    init: function init() {
      if (this.checkDefaultValue.checkAll) {
        this.checkAll = true;
        this.doCheckAll(true);
      } else if (this.checkDefaultValue.checkedList && this.checkDefaultValue.checkedList.length > 0) {
        this.checkedList = this.checkDefaultValue.checkedList;
      }
    },
    showChoosedialog: function showChoosedialog() {
      this.chooseVisable = true;
      this.chooseIconClass = "choose-icon-active";
      this.initialState = JSON.parse(JSON.stringify({
        checkAll: this.checkAll,
        checkedList: this.checkedList
      }));
    },
    onCheckAllChange: function onCheckAllChange(value) {
      this.doCheckAll(value);
    },
    doCheckAll: function doCheckAll(check) {
      Object.assign(this, {
        checkedList: check ? this.options.map(function (option) {
          return option.value;
        }) : [],
        indeterminate: false,
        checkAll: check
      });
      this.onCheckChange();
    },
    onChange: function onChange(checks) {
      this.indeterminate = !!this.checkedList.length && this.checkedList.length < this.options.length;
      this.checkAll = this.checkedList.length === this.options.length;
      this.onCheckChange();
    },
    onOverlayClick: function onOverlayClick() {
      this.chooseVisable = false;
      this.chooseIconClass = "choose-icon-unactive";
      /**
       * 触发事件 confirm
       * 满足以下条件
       * 1.弹窗关闭 or 确认
       * 2.选中值和初始值发生变化
       */
      if (this.isChange()) {
        this.$emit('confirm', JSON.parse(JSON.stringify({
          is_all: this.checkAll,
          checks: this.checkedList
        })));
      }
    },
    onCheckChange: function onCheckChange() {
      this.$emit('change', JSON.parse(JSON.stringify({
        is_all: this.checkAll,
        checks: this.checkedList
      })));
    },
    reload: function reload() {
      this.init();
    },
    reset: function reset(defaultValue) {
      if (defaultValue) {
        this.checkDefaultValue = defaultValue;
      }
      this.init();
    },
    isChange: function isChange() {
      // 数量变换
      if (this.checkedList.length != this.initialState.checkedList.length) {
        return true;
      }
      // 元素变化
      var change = false;
      var oldCheckedList = JSON.parse(JSON.stringify(this.initialState.checkedList));
      var newCheckedList = JSON.parse(JSON.stringify(this.checkedList));
      oldCheckedList.sort();
      newCheckedList.sort();
      newCheckedList.forEach(function (item, k) {
        if (item != oldCheckedList[k]) {
          change = true;
        }
      });
      return change;
    }
  }
};
exports.default = _default2;