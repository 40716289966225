"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _net_bar = require("@/api/net_bar");
var _echarts = _interopRequireDefault(require("echarts"));
var _resize = _interopRequireDefault(require("@/components/Charts/mixins/resize"));
var _moment = _interopRequireDefault(require("moment"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

require('echarts/theme/macarons'); // echarts theme
var _default = {
  name: "NetBarStoreStatistics",
  mixins: [_resize.default],
  components: {},
  props: {
    storeId: {
      type: [String, Number]
    }
  },
  data: function data() {
    return {
      loading: false,
      datePickerOptions: {
        disabledDate: function disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      chart: null,
      chartCategory: 'used_time',
      chartQuery: {
        date: [(0, _moment.default)().subtract(1, 'months').format("YYYY-MM-DD"), (0, _moment.default)().subtract(1, 'days').format("YYYY-MM-DD")],
        date_type: 'day'
      },
      chartData: {
        xData: [],
        yData: [],
        total: 0,
        avg: 0
      }
    };
  },
  mounted: function mounted() {
    this.getChartData();
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    dateChangeHandler: function dateChangeHandler() {
      this.getChartData();
    },
    dateTypeChangeHandler: function dateTypeChangeHandler() {
      this.getChartData();
    },
    categoryChangeHandler: function categoryChangeHandler() {
      this.getChartData();
    },
    getChartData: function getChartData() {
      if (this.chartCategory == 'used_time') {
        this.getUsedTimeChart();
      } else if (this.chartCategory == 'device_per_used_time') {
        this.getDevicePerUsedTimeChart();
      } else if (this.chartCategory == 'user_per_used_time') {
        this.getUserPerUsedTimeChart();
      }
    },
    getUsedTimeChart: function getUsedTimeChart() {
      var _this = this;
      this.loading = true;
      (0, _net_bar.fetchStoreStatsUsedTimeChart)(this.storeId, this.chartQuery).then(function (response) {
        _this.refreshChart(response.data);
        _this.loading = false;
      });
    },
    getDevicePerUsedTimeChart: function getDevicePerUsedTimeChart() {
      var _this2 = this;
      this.loading = true;
      (0, _net_bar.fetchStoreStatsDevicePerUsedTimeChart)(this.storeId, this.chartQuery).then(function (response) {
        _this2.refreshChart(response.data);
        _this2.loading = false;
      });
    },
    getUserPerUsedTimeChart: function getUserPerUsedTimeChart() {
      var _this3 = this;
      this.loading = true;
      (0, _net_bar.fetchStoreStatsUserPerUsedTimeChart)(this.storeId, this.chartQuery).then(function (response) {
        _this3.refreshChart(response.data);
        _this3.loading = false;
      });
    },
    refreshChart: function refreshChart(data) {
      this.chartData.xData = data.xData;
      this.chartData.yData = data.yData;
      this.chartData.total = data.total;
      this.chartData.avg = data.avg;
      this.initChart();
    },
    getSeriesName: function getSeriesName() {
      if (this.chartCategory == 'used_time') {
        return '上机总时长';
      } else if (this.chartCategory == 'device_per_used_time') {
        return '单机使用时长';
      } else if (this.chartCategory == 'user_per_used_time') {
        return '人均使用时长';
      }
    },
    initChart: function initChart() {
      if (!this.chart) {
        this.chart = _echarts.default.init(document.getElementById('used_time_chart'), 'macarons');
      }
      this.chart.setOption({
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross'
          },
          padding: [5, 10]
        },
        grid: {
          left: 20,
          right: 50,
          bottom: 20,
          top: 30,
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: this.chartData.xData,
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          name: "时长（小时）",
          axisTick: {
            show: false
          }
        },
        series: [{
          name: this.getSeriesName(),
          markLine: {
            data: [{
              name: '平均线',
              yAxis: this.chartData.avg
            }],
            precision: 1,
            lineStyle: {
              color: 'rgba(245, 108, 108, 1)'
            }
          },
          type: 'bar',
          barMaxWidth: 40,
          color: ['rgba(64, 158, 255, 1)'],
          data: this.chartData.yData,
          animationDuration: 1200,
          animationEasing: 'quadraticOut'
        }]
      });
    }
  }
};
exports.default = _default;