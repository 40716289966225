"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _net_bar = require("@/api/net_bar");
var _index = _interopRequireDefault(require("@/views/net_bar/device/index"));
var _index2 = _interopRequireDefault(require("@/views/net_bar/recharge/index"));
var _index3 = _interopRequireDefault(require("@/views/net_bar/device_price/index"));
var _index4 = _interopRequireDefault(require("@/views/net_bar/statistics/index"));
var _yunjifei = _interopRequireDefault(require("@/views/net_bar/third_member/yunjifei"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "NetBarStoreDetail",
  components: {
    NetBarStoreDevice: _index.default,
    NetBarStoreRecharge: _index2.default,
    NetBarStoreDevicePrice: _index3.default,
    NetBarStoreStatistics: _index4.default,
    YunjifeiMember: _yunjifei.default
  },
  data: function data() {
    return {
      activeName: "device",
      storeId: undefined,
      store: {
        finance: {}
      }
    };
  },
  created: function created() {
    this.storeId = this.$route.params.id;
    this.getStore();
  },
  methods: {
    getStore: function getStore() {
      var _this = this;
      (0, _net_bar.fetchStore)(this.storeId, {
        with_recharge_balance: 1
      }).then(function (result) {
        _this.store = result.data;
      });
    },
    editStoreHandler: function editStoreHandler() {
      this.$router.push({
        path: "/net_bar/store/edit/".concat(this.storeId, "?form=detail")
      });
    }
  }
};
exports.default = _default;