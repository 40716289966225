"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/web.dom.iterable");
var _common = require("@/api/common");
var _banner = require("@/api/banner");
var _hotel = require("@/api/hotel");
var _auth = require("@/utils/auth");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "Popup",
  data: function data() {
    return {
      uploadUrl: "",
      list: null,
      imageUrl: "",
      dialogFormVisible: false,
      popupForm: {
        image: "",
        desc: "",
        path: "",
        date: "",
        area_id: "",
        frequency: "",
        pages: ["1"],
        status: 1,
        sort: 0
      },
      popupFormRules: {
        area_id: [{
          required: true,
          message: '请选择板块',
          trigger: 'blur'
        }],
        date: [{
          required: true,
          message: '请选择展示日期',
          trigger: 'blur'
        }],
        image: [{
          required: true,
          message: '请上传弹窗图片',
          trigger: 'blur'
        }],
        frequency: [{
          required: true,
          message: '请选择弹出频率',
          trigger: 'blur'
        }],
        pages: [{
          required: true,
          message: '请选择弹窗页面',
          trigger: 'change'
        }]
      },
      areas: [],
      dialogTitle: "添加",
      header: {
        Authorization: "Bearer " + (0, _auth.getToken)()
      },
      options: [{
        value: 1,
        label: "每日一次"
      }, {
        value: 2,
        label: "只弹一次"
      }],
      options2: [{
        value: "1",
        label: "首页"
      }, {
        value: "2",
        label: "解锁成功页"
      }]
    };
  },
  created: function created() {
    var _this = this;
    this.uploadUrl = (0, _common.adminUpload)();
    this.getList();
    if ((0, _auth.getGrade)() < 1) {
      (0, _hotel.getArea)().then(function (response) {
        _this.areas = response.data;
      });
    }
  },
  methods: {
    doAdd: function doAdd() {
      this.dialogTitle = "添加";
      this.popupForm = {
        image: "",
        desc: "",
        path: "",
        date: "",
        area_id: "",
        sort: 0,
        frequency: 1,
        pages: ["1"]
      };
      this.dialogFormVisible = true;
    },
    getList: function getList() {
      var _this2 = this;
      this.listLoading = true;
      (0, _banner.PopupList)().then(function (res) {
        res.data.forEach(function (item) {
          item.pages = item.pages.split("|");
        });
        _this2.list = res.data;
      });
      this.listLoading = false;
    },
    handleImageSuccess: function handleImageSuccess(res) {
      if (res.code == 0) {
        this.popupForm.image = res.data.filepath;
        this.$refs['popupForm'].clearValidate('image'); // 移除错误提示
        this.$message.success("上传成功！");
      } else {
        this.$message.error("上传失败");
      }
    },
    createPopup: function createPopup() {
      var _this3 = this;
      this.$refs['popupForm'].validate(function (valid) {
        if (valid) {
          if (_this3.popupForm.id) {
            (0, _banner.updatePopup)(_this3.popupForm.id, _this3.popupForm).then(function (res) {
              if (res.code == 0) {
                _this3.$message.success("编辑成功！");
                _this3.getList();
                _this3.$refs['popupForm'].resetFields();
                _this3.dialogFormVisible = !_this3.dialogFormVisible;
              } else {
                _this3.$message.error(res.msg);
              }
              _this3.dialogTitle = "添加";
            });
          } else {
            (0, _banner.addPopup)(_this3.popupForm).then(function (res) {
              if (res.code == 0) {
                _this3.$message.success("添加成功！");
                _this3.getList();
                _this3.$refs['popupForm'].resetFields();
                _this3.dialogFormVisible = !_this3.dialogFormVisible;
              } else {
                _this3.$message.error(res.msg);
              }
            });
          }
        }
      });
    },
    handleShow: function handleShow(id) {
      var _this4 = this;
      (0, _banner.showPopup)(id).then(function (res) {
        if (res.data.code == 0) {
          _this4.$message.success(res.data.msg);
          _this4.getList();
        } else {
          _this4.$message.error(res.data.msg);
        }
      });
    },
    deletePopup: function deletePopup(id) {
      var _this5 = this;
      (0, _banner.deletePopup)(id).then(function (res) {
        if (res.code == 0) {
          _this5.$message.success("操作成功！");
          _this5.getList();
        } else {
          _this5.$message.error(res.msg);
        }
      });
    },
    updateAction: function updateAction(row) {
      this.popupForm = JSON.parse(JSON.stringify(row));
      this.dialogTitle = "编辑";
      this.dialogFormVisible = !this.dialogFormVisible;
    }
  }
};
exports.default = _default;