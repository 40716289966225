"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _common = require("@/api/common");
var _hotel_popup = require("@/api/hotel_popup");
var _auth = require("@/utils/auth");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "HotelPopup",
  data: function data() {
    return {
      uploadUrl: "",
      list: [],
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 20
      },
      pagination: "",
      imageUrl: "",
      dialogFormVisible: false,
      popupFormSubmitLoading: false,
      popupForm: {
        image: "",
        mp_image: "",
        desc: "",
        path: "",
        date: "",
        frequency: "",
        status: 1,
        type: 1,
        sort: 0
      },
      popupFormRules: {
        date: [{
          required: true,
          message: '请选择展示日期',
          trigger: 'blur'
        }],
        image: [{
          required: true,
          message: '请上传PC后台图片',
          trigger: 'blur'
        }],
        frequency: [{
          required: true,
          message: '请选择弹出频率',
          trigger: 'change'
        }],
        status: [{
          required: true,
          message: '请选择状态',
          trigger: 'change'
        }],
        type: [{
          required: true,
          message: '请选择通知状态',
          trigger: 'change'
        }]
      },
      dialogTitle: "添加弹窗",
      header: {
        Authorization: "Bearer " + (0, _auth.getToken)()
      },
      frequencyTypes: [{
        value: 1,
        label: "每日一次"
      }
      // { value: 2, label: "只弹一次"},
      ]
    };
  },
  created: function created() {
    this.uploadUrl = (0, _common.adminUpload)();
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      (0, _hotel_popup.getPopupList)(this.listQuery).then(function (response) {
        var data = response.data;
        _this.list = data.data;
        _this.pagination = data;
        _this.listQuery.page = data.current_page;
        _this.listLoading = false;
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    handleImageSuccess: function handleImageSuccess(res) {
      if (res.code == 0) {
        this.popupForm.image = res.data.filepath;
        this.$refs['popupForm'].clearValidate('image'); // 移除错误提示
        this.$message.success("上传成功！");
      } else {
        this.$message.error("上传失败");
      }
    },
    handleMpImageSuccess: function handleMpImageSuccess(res) {
      if (res.code == 0) {
        this.popupForm.mp_image = res.data.filepath;
        // this.$refs['popupForm'].clearValidate('mp_image') // 移除错误提示
        this.$message.success("上传成功！");
      } else {
        this.$message.error("上传失败");
      }
    },
    addHandler: function addHandler() {
      this.dialogTitle = "添加弹窗";
      this.popupForm = {
        image: "",
        mp_image: "",
        desc: "",
        path: "",
        date: "",
        status: 1,
        type: 1,
        sort: 0,
        frequency: 1
      };
      this.dialogFormVisible = true;
    },
    updateHandler: function updateHandler(row) {
      this.popupForm = JSON.parse(JSON.stringify(row));
      this.dialogTitle = "编辑弹窗";
      this.dialogFormVisible = true;
    },
    createPopup: function createPopup() {
      var _this2 = this;
      this.$refs['popupForm'].validate(function (valid) {
        if (valid) {
          _this2.popupFormSubmitLoading = true;
          if (_this2.popupForm.id) {
            (0, _hotel_popup.updatePopup)(_this2.popupForm.id, _this2.popupForm).then(function (res) {
              if (res.code == 0) {
                _this2.$message.success("编辑成功！");
                _this2.getList();
                _this2.$refs['popupForm'].resetFields();
                _this2.dialogFormVisible = !_this2.dialogFormVisible;
              } else {
                _this2.$message.error(res.msg);
              }
            }).finally(function () {
              _this2.popupFormSubmitLoading = false;
            });
          } else {
            (0, _hotel_popup.addPopup)(_this2.popupForm).then(function (res) {
              if (res.code == 0) {
                _this2.$message.success("添加成功！");
                _this2.getList();
                _this2.$refs['popupForm'].resetFields();
                _this2.dialogFormVisible = !_this2.dialogFormVisible;
              } else {
                _this2.$message.error(res.msg);
              }
            }).finally(function () {
              _this2.popupFormSubmitLoading = false;
            });
          }
        }
      });
    },
    updateStatusHandler: function updateStatusHandler(id, status) {
      var _this3 = this;
      (0, _hotel_popup.updatePopupStatus)(id, status).then(function (res) {
        if (res.code == 0) {
          _this3.$message.success("操作成功！");
          _this3.getList();
        } else {
          _this3.$message.error(res.msg);
        }
      });
    }
  }
};
exports.default = _default;