var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "form-dialog",
      attrs: {
        visible: _vm.dialogformVisible,
        title: _vm.dialogFormTitle,
        "close-on-click-modal": false,
        width: "650px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogformVisible = $event
        },
        close: _vm.formCloseHanlder,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { "label-suffix": ":", model: _vm.data, rules: _vm.rules },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "活动名称",
                "label-width": "85px",
                required: "",
                prop: "title",
              },
            },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入" },
                model: {
                  value: _vm.data.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.data, "title", $$v)
                  },
                  expression: "data.title",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: { label: "活动时间", "label-width": "85px", required: "" },
            },
            [
              _c(
                "el-col",
                { attrs: { span: 11 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "start_time" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          type: "date",
                          placeholder: "开始日期",
                          "picker-options": _vm.datePickerOptions,
                        },
                        model: {
                          value: _vm.data.start_time,
                          callback: function ($$v) {
                            _vm.$set(_vm.data, "start_time", $$v)
                          },
                          expression: "data.start_time",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-col", { staticClass: "line", attrs: { span: 2 } }, [
                _vm._v("至"),
              ]),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 11 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "end_time" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          type: "date",
                          placeholder: "结束日期",
                          "picker-options": _vm.datePickerOptions,
                        },
                        model: {
                          value: _vm.data.end_time,
                          callback: function ($$v) {
                            _vm.$set(_vm.data, "end_time", $$v)
                          },
                          expression: "data.end_time",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "优惠券",
                "label-width": "85px",
                required: "",
                prop: "coupon_id",
              },
            },
            [
              _c(
                "el-select",
                {
                  attrs: { filterable: "", placeholder: "请选择" },
                  model: {
                    value: _vm.data.coupon_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.data, "coupon_id", $$v)
                    },
                    expression: "data.coupon_id",
                  },
                },
                _vm._l(_vm.couponList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogformVisible = false
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.formSubmitLoading },
              on: { click: _vm.formSubmitHandler },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }