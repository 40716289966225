var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container calendar-list-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _vm.areas.length > 0
            ? _c(
                "el-select",
                {
                  staticClass: "filter-item",
                  attrs: {
                    "default-first-option": "",
                    placeholder: "请选择区域",
                    clearable: "",
                  },
                  model: {
                    value: _vm.listQuery.area_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery, "area_id", $$v)
                    },
                    expression: "listQuery.area_id",
                  },
                },
                _vm._l(_vm.areas, function (item) {
                  return _c("el-option", {
                    key: item.role_id,
                    attrs: { label: item.name, value: item.role_id },
                  })
                }),
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: { placeholder: "城市", clearable: "" },
            model: {
              value: _vm.listQuery.city,
              callback: function ($$v) {
                _vm.$set(_vm.listQuery, "city", $$v)
              },
              expression: "listQuery.city",
            },
          }),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              attrs: { filterable: "", placeholder: "酒店", clearable: "" },
              model: {
                value: _vm.listQuery.hotel_id,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "hotel_id", $$v)
                },
                expression: "listQuery.hotel_id",
              },
            },
            _vm._l(_vm.hotelList, function (item) {
              return _c("el-option", {
                key: item.hotel_id,
                attrs: { label: item.name, value: item.hotel_id },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.handleFilter },
            },
            [_vm._v("\n      搜索\n    ")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              staticStyle: { "margin-left": "10px" },
              attrs: { type: "primary" },
              on: { click: _vm.addBtn },
            },
            [_vm._v("\n      添加\n    ")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          key: "tableList",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            "element-loading-text": "给我一点时间",
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "ID", width: "65" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.id))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "酒店名称",
              "class-name": "overflow-on",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.hotelInfo.name) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "城市" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [_vm._v(_vm._s(scope.row.hotelInfo.city))]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "活动日期" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(scope.row.start_date) +
                          " ~ " +
                          _vm._s(scope.row.end_date)
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "优惠信息" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return _vm._l(scope.row.detail, function (item) {
                    return _c("span", { key: item.day }, [
                      _vm._v(
                        _vm._s(item.day) + "天享" + _vm._s(item.discount) + "折"
                      ),
                      _c("br"),
                    ])
                  })
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "更新人" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.editor))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.status == 1
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.editBtn(scope.row)
                              },
                            },
                          },
                          [_vm._v("\n          编辑\n        ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status == 1
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "danger" },
                            on: {
                              click: function ($event) {
                                return _vm.downShelf(scope.row)
                              },
                            },
                          },
                          [_vm._v("\n          下架\n        ")]
                        )
                      : _c("span", [_vm._v("已下架")]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "pagination-container text-right" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              total: _vm.pagination.total,
              "current-page": _vm.listQuery.page,
              "page-sizes": [20],
              "page-size": _vm.listQuery.limit,
              layout: "total, sizes, prev, pager, next, jumper",
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: _vm.dialogTitle, visible: _vm.dialogFormVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "temp",
              staticClass: "small-space",
              staticStyle: { width: "500px", "margin-left": "20px" },
              attrs: {
                model: _vm.temp,
                "label-position": "right",
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: { label: "选择酒店", prop: "hotel_id", required: "" },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        filterable: "",
                        disabled: _vm.dialogTitle == "编辑",
                        placeholder: "酒店",
                      },
                      on: { change: _vm.changeHotel },
                      model: {
                        value: _vm.temp.hotel_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.temp, "hotel_id", $$v)
                        },
                        expression: "temp.hotel_id",
                      },
                    },
                    _vm._l(_vm.hotelList, function (item) {
                      return _c("el-option", {
                        key: item.hotel_id,
                        attrs: { label: item.name, value: item.hotel_id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "选择房型", required: "" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        filterable: "",
                        multiple: "",
                        placeholder: "房型",
                        "data-index": "index",
                      },
                      model: {
                        value: _vm.temp.room_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.temp, "room_id", $$v)
                        },
                        expression: "temp.room_id",
                      },
                    },
                    _vm._l(_vm.roomList, function (item) {
                      return _c("el-option", {
                        key: item.room_id,
                        attrs: { label: item.name, value: item.room_id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "活动成本", required: "" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { "data-index": "index" },
                      model: {
                        value: _vm.temp.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.temp, "type", $$v)
                        },
                        expression: "temp.type",
                      },
                    },
                    _vm._l(_vm.typeList, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "活动日期", required: "", prop: "date" } },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "100%" },
                    attrs: { type: "daterange", "value-format": "yyyyMMdd" },
                    model: {
                      value: _vm.temp.date,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp, "date", $$v)
                      },
                      expression: "temp.date",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "连住", required: "" } },
                [
                  _vm._l(_vm.temp.detail, function (domain, index) {
                    return _c(
                      "div",
                      {
                        key: domain.key,
                        staticStyle: { "margin-bottom": "20px" },
                        attrs: { label: "连住" + index, prop: "detail[]" },
                      },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 10 } },
                          [
                            _c(
                              "el-input",
                              {
                                attrs: { placeholder: "" },
                                model: {
                                  value: domain.day,
                                  callback: function ($$v) {
                                    _vm.$set(domain, "day", $$v)
                                  },
                                  expression: "domain.day",
                                },
                              },
                              [
                                _c("template", { slot: "append" }, [
                                  _vm._v("天，享"),
                                ]),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 10 } },
                          [
                            _c(
                              "el-input",
                              {
                                attrs: { placeholder: "" },
                                model: {
                                  value: domain.discount,
                                  callback: function ($$v) {
                                    _vm.$set(domain, "discount", $$v)
                                  },
                                  expression: "domain.discount",
                                },
                              },
                              [
                                _c("template", { slot: "append" }, [
                                  _vm._v("折"),
                                ]),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 4 } },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "success" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.removeDomain(domain)
                                  },
                                },
                              },
                              [_vm._v("\n            删除\n          ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c("el-button", { on: { click: _vm.addDomain } }, [
                        _vm._v("\n            添加\n          "),
                      ]),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = false
                    },
                  },
                },
                [_vm._v("\n        取 消\n      ")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: _vm.btn },
                  on: {
                    click: function ($event) {
                      return _vm.addConnectedOffer()
                    },
                  },
                },
                [_vm._v("\n        确 定\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }