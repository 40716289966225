"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es6.number.constructor");
var _net_bar = require("@/api/net_bar");
var _order_migrate_detail = _interopRequireDefault(require("./order_migrate_detail"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: "NetBarOrderDetail",
  components: {
    NetBarOrderMigrateDetail: _order_migrate_detail.default
  },
  props: {
    orderId: {
      type: [String, Number]
    },
    visible: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    }
  },
  watch: {
    visible: function visible() {
      this.dialogVisible = this.visible;
    },
    orderId: function orderId() {
      if (this.orderId) {
        this.getDetail();
      }
    }
  },
  filters: {
    dateFormat: function dateFormat(value) {
      if (!value) return '';
      var date = value.toString();
      return date.slice(4, 6) + '月' + date.slice(6, 8) + '日';
    }
  },
  data: function data() {
    return {
      detailLoading: false,
      dialogVisible: false,
      orderDetail: {},
      orderMigrateVisible: false
    };
  },
  created: function created() {},
  methods: {
    detailCloseHanlder: function detailCloseHanlder() {
      this.dialogVisible = false;
      this.orderDetail = {};
      this.$emit('update:orderId', "");
      this.$emit('update:visible', false);
    },
    getDetail: function getDetail() {
      var _this = this;
      this.detailLoading = true;
      (0, _net_bar.fetchOrder)(this.orderId).then(function (response) {
        var data = response.data;
        _this.orderDetail = data;
        _this.detailLoading = false;
      });
    },
    migrateHandler: function migrateHandler() {
      this.orderMigrateVisible = true;
    },
    getUseTime: function getUseTime(order) {
      var startTime = new Date(order.started_at).getTime() / 1000;
      var useTime = 0;
      if (order.status == 40) {
        var endTime = new Date(order.ended_at).getTime() / 1000;
        useTime = endTime - startTime;
      } else if (order.status == 30) {
        var now = new Date().getTime() / 1000;
        useTime = now - startTime;
      }
      // to H时m分
      var format = '';
      var h = parseInt(useTime / 3600);
      if (h > 0) format += "".concat(h, "\u5C0F\u65F6");
      var m = parseInt(useTime % 3600 / 60);
      if (m > 0) format += "".concat(m, "\u5206\u949F");
      return format;
    },
    getAmountDetail: function getAmountDetail(order) {
      var str = "".concat(order.amount, "\u5143");
      if (order.status == 40 && order.charge_gift_amount) {
        str += "\uFF08\u542B\u8D60\u9001\u91D1\u989D".concat(order.charge_gift_amount, "\u5143\uFF09");
      } else if (order.status == 30 && order.settlement.gift_amount) {
        str += "\uFF08\u542B\u8D60\u9001\u91D1\u989D".concat(order.settlement.gift_amount, "\u5143\uFF09");
      }
      return str;
    }
  }
};
exports.default = _default2;