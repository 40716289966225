"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _data = require("@/api/data");
var _hotel = require("@/api/hotel");
var _echarts = _interopRequireDefault(require("echarts"));
var _auth = require("@/utils/auth");
var _helper = require("@/utils/helper");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

require('echarts/theme/macarons'); // echarts theme
// import resize from './mixins/resize'
var _default = {
  name: 'MiniappStatistics',
  data: function data() {
    return {
      typeList: [{
        key: 1,
        label: '城市',
        value: 3
      }, {
        key: 2,
        label: '酒店',
        value: 4
      }],
      start: true,
      areas: [],
      area_id: "",
      platformDate: [],
      platformData: [],
      platformOrderDate: [],
      platformOrderData: [],
      platformTransDate: [],
      platformTransData: [],
      cityDate: [],
      cityData: [],
      cityOrderDate: [],
      cityOrderData: [],
      cityTransDate: [],
      cityTransData: [],
      trendDate: [],
      trendData: [],
      trendOrderDate: [],
      trendOrderData: [],
      trendTransDate: [],
      trendTransData: [],
      dataRole: [],
      roleList: [],
      trendList: [],
      detailType: 3,
      trendId: "",
      trendType: 3,
      trendOrderList: [],
      detailOrderType: 3,
      trendOrderId: "",
      trendOrderType: 3,
      trendTransList: [],
      detailTransType: 3,
      trendTransId: "",
      trendTransType: 3,
      activeName: "order",
      hotelList: [],
      tips: "订单数据：指已消费的订单，即已离店订单"
    };
  },
  created: function created() {
    var _this = this;
    if ((0, _auth.getGrade)() < 1) {
      (0, _hotel.getArea)().then(function (response) {
        _this.areas = response.data;
      });
    }
    (0, _hotel.getHotelSimpleList)().then(function (response) {
      _this.hotelList = response.data;
    });
  },
  mounted: function mounted() {
    this.getPlatFormOrder();
    this.getCityOrder();
    this.getSelectList();
  },
  methods: {
    changeArea: function changeArea() {
      this.getPlatFormOrder();
      this.getPlatFormUV();
      this.getPlatFormTrans();
      this.getCityUV();
      this.getTrendUV();
      this.getCityTrans();
      this.getTrendTrans();
      this.getCityOrder();
      this.getTrendOrder();
    },
    handleClick: function handleClick(tab) {
      switch (tab.paneName) {
        case 'order':
          this.platformOrderData != [] && this.getPlatFormOrder();
          break;
        case 'uv':
          this.getPlatFormUV != [] && this.getPlatFormUV();
          this.getCityUV();
          this.getTrendUV();
          this.getSelectList();
          break;
        case 'trans':
          this.platformTransData != [] && this.getPlatFormTrans();
          this.getTrendTrans();
          this.getCityTrans();
          this.getSelectList();
          break;
      }
    },
    initChart: function initChart(eleId, data) {
      var myChart = _echarts.default.init(document.getElementById(eleId), 'macarons');
      // 绘制图表
      myChart.setOption({
        title: {
          text: data.title
        },
        tooltip: {
          trigger: "axis",
          show: true //显示提示框
        },

        toolbox: {
          //可视化的工具箱
          show: true,
          feature: {
            dataView: {
              //数据视图
              show: true
            },
            magicType: {
              //动态类型切换
              type: ['bar', 'line']
            }
          }
        },
        xAxis: {
          data: data.xData,
          axisLabel: {
            interval: data.interval,
            //坐标轴刻度标签的显示间隔(在类目轴中有效) 0:显示所有  1：隔一个显示一个 :3：隔三个显示一个...
            rotate: data.rotate //标签倾斜的角度，显示不全时可以通过旋转防止标签重叠（-90到90）
          }
        },

        yAxis: {
          name: data.yName,
          type: 'value'
        },
        series: [{
          name: data.name,
          type: data.type,
          data: data.yData
        }]
      });
    },
    getPlatFormUV: function getPlatFormUV() {
      var _this2 = this;
      (0, _data.getData)(0, {
        'date': this.platformDate,
        'area_id': this.area_id
      }).then(function (res) {
        _this2.platformData = res.data;
        _this2.platformDate = res.data.date;
        _this2.initChart('platform-chart', _this2.platformData);
      });
    },
    getCityUV: function getCityUV() {
      var _this3 = this;
      (0, _data.getData)(this.detailType, {
        'date': this.cityDate,
        'area_id': this.area_id
      }).then(function (res) {
        _this3.cityData = res.data;
        _this3.cityDate = res.data.date;
        _this3.initChart('city-chart', _this3.cityData);
      });
    },
    getCityOrder: function getCityOrder() {
      var _this4 = this;
      (0, _data.getData)(this.detailOrderType == 3 ? 7 : 8, {
        'date': this.cityOrderDate,
        'area_id': this.area_id
      }).then(function (res) {
        _this4.cityOrderData = res.data;
        _this4.cityOrderDate = res.data.date;
        _this4.initChart('city-order-chart', _this4.cityOrderData);
      });
    },
    getCityTrans: function getCityTrans() {
      var _this5 = this;
      (0, _data.getData)(this.detailTransType == 3 ? 9 : 10, {
        'date': this.cityTransDate,
        'area_id': this.area_id
      }).then(function (res) {
        _this5.cityTransData = res.data;
        _this5.cityTransDate = res.data.date;
        _this5.initChart('city-trans-chart', _this5.cityTransData);
      });
    },
    getTrendUV: function getTrendUV() {
      var _this6 = this;
      var iType = 5;
      var params = {
        'date': this.trendDate,
        'area_id': this.area_id
      };
      if (this.trendType == 4) {
        iType = 6;
        params.hotel_id = this.trendId;
      } else {
        params.city = this.trendId;
      }
      (0, _data.getData)(iType, params).then(function (res) {
        _this6.trendData = res.data;
        _this6.trendDate = res.data.date;
        _this6.initChart('trend-chart', _this6.trendData);
      });
    },
    getTrendOrder: function getTrendOrder() {
      var _this7 = this;
      var iType = 11;
      var params = {
        'date': this.trendOrderDate,
        'area_id': this.area_id
      };
      if (this.trendOrderType == 4) {
        iType = 12;
        params.hotel_id = this.trendOrderId;
      } else {
        params.city = this.trendOrderId;
      }
      (0, _data.getData)(iType, params).then(function (res) {
        _this7.trendOrderData = res.data;
        _this7.trendOrderDate = res.data.date;
        _this7.initChart('trend-order-chart', _this7.trendOrderData);
      });
    },
    getTrendTrans: function getTrendTrans() {
      var _this8 = this;
      var iType = 13;
      var params = {
        'date': this.trendTransDate,
        'area_id': this.area_id
      };
      if (this.trendTransType == 4) {
        iType = 14;
        params.hotel_id = this.trendTransId;
      } else {
        params.city = this.trendTransId;
      }
      (0, _data.getData)(iType, params).then(function (res) {
        _this8.trendTransData = res.data;
        _this8.trendTransDate = res.data.date;
        _this8.initChart('trend-trans-chart', _this8.trendTransData);
      });
    },
    getPlatFormOrder: function getPlatFormOrder() {
      var _this9 = this;
      (0, _data.getData)(1, {
        'date': this.platformOrderDate,
        'area_id': this.area_id
      }).then(function (res) {
        _this9.platformOrderData = res.data;
        _this9.platformOrderDate = res.data.date;
        _this9.initChart('platform-order-chart', _this9.platformOrderData);
      });
    },
    getPlatFormTrans: function getPlatFormTrans() {
      var _this10 = this;
      (0, _data.getData)(2, {
        'date': this.platformTransDate,
        'area_id': this.area_id
      }).then(function (res) {
        _this10.platformTransData = res.data;
        _this10.platformTransDate = res.data.date;
        _this10.initChart('platform-trans-chart', _this10.platformTransData);
      });
    },
    getSelectList: function getSelectList() {
      var _this11 = this;
      switch (this.activeName) {
        case 'order':
          (0, _data.getSelectedList)(this.trendOrderType, {
            'area_id': this.area_id
          }).then(function (res) {
            _this11.trendOrderId = '';
            _this11.trendOrderList = res.data.list;
            if (_this11.start == true) {
              _this11.trendOrderId = res.data.city;
              _this11.start = false;
              _this11.getTrendOrder();
            }
          });
          break;
        case 'trans':
          (0, _data.getSelectedList)(this.trendTransType, {
            'area_id': this.area_id
          }).then(function (res) {
            _this11.trendTransId = '';
            _this11.trendTransList = res.data.list;
          });
          break;
        default:
          (0, _data.getSelectedList)(this.trendType, {
            'area_id': this.area_id
          }).then(function (res) {
            _this11.trendId = '';
            _this11.trendList = res.data.list;
          });
      }
      // this.trendId = '';
    },
    doDownload: function doDownload(type) {
      var baseUrl = '/admin/statistics/download/' + type + '?api_token=' + (0, _auth.getToken)() + '&area_id=' + this.area_id + '&';
      var url = '';
      switch (type) {
        case 0:
          url = (0, _helper.parseUrl)({
            'type': type,
            'date': this.platformDate
          });
          window.open(baseUrl + url);
          break;
        case 1:
          url = (0, _helper.parseUrl)({
            'type': type,
            'date': this.platformOrderDate
          });
          window.open(baseUrl + url);
          break;
        case 2:
          url = (0, _helper.parseUrl)({
            'type': type,
            'date': this.platformTransDate
          });
          window.open(baseUrl + url);
          break;
        case 3:
          url = (0, _helper.parseUrl)({
            'type': this.detailType,
            'date': this.cityDate
          });
          window.open(baseUrl + url);
          break;
        case 7:
          url = this.detailOrderType == 3 ? (0, _helper.parseUrl)({
            'type': type,
            'date': this.cityOrderDate
          }) : (0, _helper.parseUrl)({
            'type': 8,
            'date': this.cityOrderDate
          });
          window.open(baseUrl + url);
          break;
        case 9:
          url = this.detailTransType == 3 ? (0, _helper.parseUrl)({
            'type': type,
            'date': this.cityTransDate
          }) : (0, _helper.parseUrl)({
            'type': 10,
            'date': this.cityTransDate
          });
          window.open(baseUrl + url);
          break;
        case 5:
          var iType = 5;
          var params = {
            'date': this.cityDate
          };
          if (this.trendType == 4) {
            iType = 6;
            params.hotel_id = this.trendId;
          } else {
            params.city = this.trendId;
          }
          params.type = iType;
          url = (0, _helper.parseUrl)(params);
          window.open(baseUrl + url);
          break;
        case 11:
          var iType2 = 11;
          var params2 = {
            'date': this.cityDate
          };
          if (this.trendOrderType == 4) {
            iType2 = 12;
            params2.hotel_id = this.trendId;
          } else {
            params2.city = this.trendId;
          }
          params2.type = iType2;
          url = (0, _helper.parseUrl)(params2);
          window.open(baseUrl + url);
          break;
        case 13:
          var iType3 = 13;
          var params3 = {
            'date': this.cityDate
          };
          if (this.trendTransType == 4) {
            iType3 = 14;
            params3.hotel_id = this.trendId;
          } else {
            params3.city = this.trendId;
          }
          params3.type = iType3;
          url = (0, _helper.parseUrl)(params3);
          window.open(baseUrl + url);
          break;
        default:
      }
    }
  }
};
exports.default = _default;