"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
require("core-js/modules/es6.number.constructor");
var _net_bar = require("@/api/net_bar");
var _price_calendar = _interopRequireDefault(require("@/views/net_bar/components/price_calendar.vue"));
var _form_pack = _interopRequireDefault(require("./form_pack.vue"));
var _form_all_night = _interopRequireDefault(require("./form_all_night.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: "NetBarDevicePriceForm",
  components: {
    PriceCalendar: _price_calendar.default,
    NetBarDevicePriceFormPack: _form_pack.default,
    NetBarDevicePriceFormAllNgiht: _form_all_night.default
  },
  props: {
    id: [Number, String],
    storeId: {
      type: [String, Number]
    },
    visible: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    }
  },
  watch: {
    id: function id() {},
    visible: function visible() {
      this.dialogVisible = this.visible;
      if (this.id && this.visible) {
        this.getDetail();
      }
    },
    'dataForm.prices.temp_price': function dataFormPricesTemp_price(val) {
      if (Object.keys(val).length == 168) {
        this.$refs['priceTemp'].clearValidate();
      }
    },
    'dataForm.prices.member_price': function dataFormPricesMember_price(val) {
      if (Object.keys(val).length == 168) {
        this.$refs['priceMember'].clearValidate();
      }
    }
  },
  data: function data() {
    var _this = this;
    var checkPriceSet = function checkPriceSet(rule, value, callback) {
      var priceType = rule.field.split(".")[1];
      if (Object.keys(_this.dataForm.prices[priceType]).length < 168) {
        callback(new Error("\u8BF7\u5B8C\u5584".concat(_this.priceType[priceType].label, "\u4EF7\u683C\u8BBE\u7F6E")));
        return;
      }
      callback();
    };
    return {
      dialogVisible: false,
      formLoading: false,
      submitLoading: false,
      priceCalendarType: "temp_price",
      priceType: {
        temp_price: {
          label: "临时卡"
        },
        member_price: {
          label: "会员卡"
        },
        pack_price: {
          label: "包时段"
        },
        all_night_price: {
          label: "通宵"
        }
      },
      dataForm: {
        id: undefined,
        title: undefined,
        prices: {
          temp_price: {},
          // 会员价
          member_price: {},
          // 临时价
          pack_price: [],
          // 包时段
          all_night_price: [] // 通宵
        }
      },

      dataFormRules: {
        title: [{
          required: true,
          message: '请输入配置名称',
          trigger: 'change'
        }],
        'prices.temp_price': [{
          validator: checkPriceSet,
          trigger: 'change'
        }],
        'prices.member_price': [{
          validator: checkPriceSet,
          trigger: 'change'
        }]
      }
    };
  },
  mounted: function mounted() {},
  methods: {
    getDetail: function getDetail() {
      var _this2 = this;
      this.formLoading = true;
      (0, _net_bar.fetchStoreDevicePrice)(this.storeId, this.id).then(function (result) {
        var data = result.data;
        _this2.dataForm = {
          id: data.id,
          title: data.title,
          prices: data.prices
        };
        _this2.formLoading = false;
      });
    },
    closeHanlder: function closeHanlder() {
      this.$refs['dataForm'].resetFields();
      this.restForm();
      this.$emit('update:id', "");
      this.$emit('update:visible', false);
      this.priceCalendarType = "temp_price";
    },
    restForm: function restForm() {
      this.dataForm = {
        id: undefined,
        title: undefined,
        prices: {
          temp_price: {},
          // 会员价
          member_price: {},
          // 临时价
          pack_price: [],
          // 包时段
          all_night_price: [] // 通宵
        }
      };
    },
    submitHandler: function submitHandler() {
      var _this3 = this;
      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          if (_this3.dataForm.id) {
            _this3.doUpadte();
          } else {
            _this3.doCreate();
          }
        } else {
          if (Object.keys(_this3.dataForm.prices.temp_price).length < 168) {
            _this3.priceCalendarType = "temp_price";
          } else if (Object.keys(_this3.dataForm.prices.member_price).length < 168) {
            _this3.priceCalendarType = "member_price";
          }
        }
      });
    },
    doCreate: function doCreate() {
      var _this4 = this;
      this.submitLoading = true;
      (0, _net_bar.createStoreDevicePrice)(this.storeId, this.dataForm).then(function () {
        _this4.$message.success("操作成功");
        _this4.$emit('success');
        _this4.dialogVisible = false;
      }).finally(function () {
        _this4.submitLoading = false;
      });
    },
    doUpadte: function doUpadte() {
      var _this5 = this;
      this.submitLoading = true;
      (0, _net_bar.updateStoreDevicePrice)(this.storeId, this.dataForm.id, this.dataForm).then(function () {
        _this5.$message.success("操作成功");
        _this5.$emit('success');
        _this5.dialogVisible = false;
      }).finally(function () {
        _this5.submitLoading = false;
      });
    }
  }
};
exports.default = _default2;