"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addAdmin = addAdmin;
exports.deleteAdmin = deleteAdmin;
exports.fetchAdmin = fetchAdmin;
exports.fetchAdminList = fetchAdminList;
exports.fetchAgentList = fetchAgentList;
exports.fetchHotelList = fetchHotelList;
exports.fetchNetBarList = fetchNetBarList;
exports.fetchSharedStoreList = fetchSharedStoreList;
exports.updateAdmin = updateAdmin;
var _request = _interopRequireDefault(require("@/utils/request"));
function fetchAdminList(query) {
  return (0, _request.default)({
    url: '/admin/list',
    method: 'get',
    params: query
  });
}
function fetchHotelList(query) {
  return (0, _request.default)({
    url: '/admin/hotelAccount',
    method: 'get',
    params: query
  });
}
function fetchAgentList(query) {
  return (0, _request.default)({
    url: '/admin/agentAccount',
    method: 'get',
    params: query
  });
}
function fetchSharedStoreList(query) {
  return (0, _request.default)({
    url: '/admin/sharedStoreAccount',
    method: 'get',
    params: query
  });
}
function fetchNetBarList(query) {
  return (0, _request.default)({
    url: '/admin/netBarAccount',
    method: 'get',
    params: query
  });
}
function fetchAdmin(id) {
  return (0, _request.default)({
    url: "/admin/info/".concat(id),
    method: 'get'
  });
}
function updateAdmin(id, data) {
  return (0, _request.default)({
    url: "/admin/".concat(id),
    method: 'put',
    data: data
  });
}
function deleteAdmin(query) {
  return (0, _request.default)({
    url: '/admin',
    method: 'delete',
    params: query
  });
}
function addAdmin(data) {
  return (0, _request.default)({
    url: '/admin',
    method: 'post',
    data: data
  });
}