var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container member-container" }, [
    _c("div", { staticClass: "user-detail" }, [
      _c("h3", [_vm._v("用户信息")]),
      _vm._v(" "),
      _c("div", [
        _c("img", { attrs: { src: _vm.info.avatar_url } }),
        _vm._v(" " + _vm._s(_vm.info.nickname) + "\n    "),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "user-info" },
        [
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "grid-content bg-purple" }, [
                  _vm._v(
                    "\n            地区：" +
                      _vm._s(_vm.info.province) +
                      "/" +
                      _vm._s(_vm.info.city) +
                      "\n          "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "grid-content bg-purple" }, [
                  _vm._v(
                    "\n            年龄：" +
                      _vm._s(_vm.info.age || "无") +
                      "\n          "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "grid-content bg-purple" }, [
                  _vm._v(
                    "\n            注册时间：" +
                      _vm._s(_vm.info.created_at) +
                      "\n          "
                  ),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "grid-content bg-purple-light" }, [
                  _vm._v(
                    "\n            注册渠道：" +
                      _vm._s(_vm.info.channel) +
                      "\n          "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "grid-content bg-purple" }, [
                  _vm._v(
                    "\n            联系电话：" +
                      _vm._s(_vm.info.mobile) +
                      "\n          "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "grid-content bg-purple" }, [
                  _vm._v(
                    "\n            性别：" +
                      _vm._s(_vm.info.gender || "无") +
                      "\n          "
                  ),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 8 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple-light" },
                  [
                    _vm._v("\n            用户标签：\n            "),
                    (_vm.info.tag & 1) == 1
                      ? _c(
                          "el-tag",
                          {
                            staticStyle: { "border-color": "#409EFF" },
                            attrs: { size: "small" },
                          },
                          [_vm._v("酒店")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    (_vm.info.tag & 2) == 2
                      ? _c(
                          "el-tag",
                          {
                            staticStyle: {
                              color: "#bf24ff",
                              "border-color": "#bf24ff",
                            },
                            attrs: { size: "small", color: "#efc9ff" },
                          },
                          [_vm._v("网咖")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "main" },
      [
        _c(
          "el-tabs",
          {
            on: { "tab-click": _vm.handleClick },
            model: {
              value: _vm.activeName,
              callback: function ($$v) {
                _vm.activeName = $$v
              },
              expression: "activeName",
            },
          },
          [
            _c(
              "el-tab-pane",
              { attrs: { label: "消费记录", name: "hotel_order" } },
              [_c("MemberHotelOrder", { attrs: { userId: _vm.user_id } })],
              1
            ),
            _vm._v(" "),
            _c(
              "el-tab-pane",
              { attrs: { label: "上机记录", name: "online", lazy: "" } },
              [_c("MemberLockRecord", { attrs: { userId: _vm.user_id } })],
              1
            ),
            _vm._v(" "),
            _c(
              "el-tab-pane",
              { attrs: { label: "优惠券", name: "coupon", lazy: "" } },
              [_c("MemberCoupon", { attrs: { userId: _vm.user_id } })],
              1
            ),
            _vm._v(" "),
            _c(
              "el-tab-pane",
              {
                attrs: {
                  label: "网咖消费记录",
                  name: "net_bar_order",
                  lazy: "",
                },
              },
              [_c("MemberNetBarOrder", { attrs: { userId: _vm.user_id } })],
              1
            ),
            _vm._v(" "),
            _c(
              "el-tab-pane",
              { attrs: { label: "网咖会员卡", name: "net_bar_stv", lazy: "" } },
              [_c("MemberNetBarStv", { attrs: { userId: _vm.user_id } })],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }