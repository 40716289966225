"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.to-string");
var _mixin = _interopRequireDefault(require("@/utils/mixin"));
var _moment = _interopRequireDefault(require("moment"));
var _AdjustBillForm = _interopRequireDefault(require("./AdjustBillForm"));
var _AdjustBillDetail = _interopRequireDefault(require("./AdjustBillDetail"));
var _bill_soft = require("@/api/bill_soft");
var _hotel = require("@/api/hotel");
var _agent = require("@/api/agent");
var _auth = require("@/utils/auth");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  mixins: [_mixin.default],
  components: {
    AdjustBillForm: _AdjustBillForm.default,
    AdjustBillDetail: _AdjustBillDetail.default
  },
  filters: {
    dateFormat: function dateFormat(value) {
      if (!value) return '';
      var date = value.toString();
      return date.slice(0, 4) + '-' + date.slice(4, 6) + '-' + date.slice(6, 8);
    }
  },
  data: function data() {
    return {
      alert_title: '',
      list: [],
      hotelList: [],
      agentList: [],
      multipleSelection: [],
      selected: [],
      select_num: 0,
      select_amount: 0,
      listQuery: {
        page: 1,
        limit: 10,
        bill_week: "",
        bill_date: [],
        date: "",
        hotel_id: "",
        agent_id: "",
        status: "",
        settlement_type: ""
      },
      pagination: "",
      statusOptions: [{
        value: 10,
        label: '商家待确认'
      },
      // { value: 11,label: '商家确认异常'},
      {
        value: 20,
        label: '待发起I8付款'
      }, {
        value: 21,
        label: 'I8审核中'
      }, {
        value: 22,
        label: 'I8被驳回'
      }, {
        value: 30,
        label: '待打款'
      }, {
        value: 40,
        label: '已打款'
      }],
      settlementTypes: [{
        value: '',
        label: '全部'
      }, {
        value: 'deposit_deduct_off',
        label: '非押金抵扣'
      }, {
        value: 'deposit_deduct_processing',
        label: '押金抵扣中'
      }, {
        value: 'deposit_deduct_completed',
        label: '押金抵扣完毕'
      }],
      statusNameMap: {
        10: '商家待确认',
        // 11: '商家确认异常',
        20: '待发起I8付款',
        21: 'I8审核中',
        22: 'I8被驳回',
        30: '待打款',
        40: '已打款'
      },
      adjustBillId: "",
      adjustBillFormVisible: false,
      adjustBillDetailVisible: false
    };
  },
  created: function created() {
    var _this = this;
    this.listQuery.hotel_id = this.$route.query.hotel_id || "";
    if (this.$route.query.bill_start_date) {
      this.listQuery.bill_date = this.getWeekRange((0, _moment.default)(this.$route.query.bill_start_date).format("YYYY-MM-DD"));
    } else {
      this.listQuery.bill_date = this.getWeekRange((0, _moment.default)().subtract(7, 'days').format("YYYY-MM-DD"));
    }
    // let prevWeekStart = this.getWeekRange(moment().subtract(7, 'days').format("YYYY-MM-DD"))[0]
    // this.listQuery.bill_week = moment(prevWeekStart).format("YYYYMMDD")

    this.getList();
    // 取消模式过滤 {mode: 6}
    (0, _hotel.getHotelSimpleList)().then(function (response) {
      _this.hotelList = response.data;
    });
    (0, _agent.getSimpleList)().then(function (response) {
      _this.agentList = response.data;
    });
  },
  methods: {
    getList: function getList() {
      var _this2 = this;
      this.listLoading = true;
      (0, _bill_soft.softWeekList)(this.listQuery).then(function (response) {
        var data = response.data;
        _this2.list = data.data;
        _this2.listLoading = false;
        _this2.pagination = data;
      });
    },
    download: function download() {
      window.open((0, _bill_soft.getSoftWeekBillDownloadUrl)(this.listQuery, (0, _auth.getToken)()));
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    resetForm: function resetForm() {
      this.listQuery.bill_week = "";
      this.listQuery.bill_date = this.getWeekRange((0, _moment.default)().subtract(7, 'days').format("YYYY-MM-DD"));
      this.listQuery.status = "";
      this.listQuery.hotel_id = "";
      this.listQuery.agent_id = "";
      this.listQuery.settlement_type = "";
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    onBillDateChange: function onBillDateChange(date) {
      var start = date[0];
      var end = date[1];
      var startBillDate = this.getWeekRange((0, _moment.default)(start).format("YYYY-MM-DD"))[0];
      var endBillDate = this.getWeekRange((0, _moment.default)(end).format("YYYY-MM-DD"))[1];
      this.listQuery.bill_date = [startBillDate, endBillDate];
    },
    toOrder: function toOrder(row) {
      this.$router.push("/bill/finance_order?hotel_id=".concat(row.hotel_id, "&bill_week=").concat(row.start_date, "&hotel_mode=").concat(row.hotel_mode));
    },
    adjustBillHandler: function adjustBillHandler(record) {
      this.adjustBillId = record.id;
      this.adjustBillFormVisible = true;
    },
    showAdjustDetail: function showAdjustDetail(record) {
      this.adjustBillId = record.id;
      this.adjustBillDetailVisible = true;
    },
    handleSelectionChange: function handleSelectionChange(val) {
      var self = this;
      self.multipleSelection = [];
      self.selected = val;
      self.select_num = 0;
      self.select_amount = 0;
      val.forEach(function (v) {
        self.select_num++;
        // self.select_amount += v.hotel_amount;
        self.select_amount += v.real_hotel_amount;
        self.multipleSelection.push(v.id);
      });
      self.alert_title = '已选择 ' + self.select_num + ' 笔对账单   总计：' + self.select_amount;
      console.warn(this.multipleSelection);
    },
    resetBlank: function resetBlank() {
      this.$refs.multipleTable.clearSelection();
    },
    transfer: function transfer() {
      var _this3 = this;
      if (this.multipleSelection.length < 1) {
        this.$message.error("请选择账单");
        return false;
      }
      var selectIds = this.multipleSelection.join(',');
      var params = JSON.parse(JSON.stringify(this.listQuery));
      params.id = selectIds;
      (0, _bill_soft.softTransferPreview)(params).then(function (response) {
        if (response.data.count > 0) {
          _this3.transferConfirm(response.data, params);
        } else {
          _this3.$message.error("只有待发起I8付款的账单才能发起付款");
        }
      });
    },
    transferAll: function transferAll() {
      var _this4 = this;
      (0, _bill_soft.softTransferPreview)(this.listQuery).then(function (response) {
        if (response.data.count > 0) {
          _this4.transferConfirm(response.data, _this4.listQuery);
        } else {
          _this4.$message.error("只有待发起I8付款的账单才能发起付款");
        }
      });
    },
    transferConfirm: function transferConfirm(bill, params) {
      var _this5 = this;
      this.$confirm("\u8D26\u5355\u6570\u91CF\uFF1A".concat(bill.count, "\uFF1B\u603B\u91D1\u989D\uFF1A").concat(bill.amount), '请您核对对账单数量和总金额', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _bill_soft.softHotelPaymentFlow)(params).then(function (response) {
          if (response.code === 0) {
            window.open(response.data);
          } else {
            _this5.$message.error(response.msg);
          }
        });
      }).catch(function () {});
    },
    exportBill: function exportBill() {
      if (this.multipleSelection.length < 1) {
        this.$message.error('请选择账单');
        return false;
      }
      var selectIds = this.multipleSelection.join(',');
      var params = JSON.parse(JSON.stringify(this.listQuery));
      params.id = selectIds;
      window.open((0, _bill_soft.getSoftWeekBillDownloadUrl)(params, (0, _auth.getToken)()));
    }
  }
};
exports.default = _default;