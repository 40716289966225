"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addConnectedOffer = addConnectedOffer;
exports.addNewDiscount = addNewDiscount;
exports.addSecKill = addSecKill;
exports.addSpecial = addSpecial;
exports.checkAreaSecKill = checkAreaSecKill;
exports.closePointLottery = closePointLottery;
exports.closeScan = closeScan;
exports.connectedOffers = connectedOffers;
exports.createPointLottery = createPointLottery;
exports.createScan = createScan;
exports.downConnectedOffer = downConnectedOffer;
exports.downNewDiscount = downNewDiscount;
exports.downSecKill = downSecKill;
exports.downSpecial = downSpecial;
exports.editNewDiscount = editNewDiscount;
exports.editSecKill = editSecKill;
exports.fetchList = fetchList;
exports.fetchPointLotteryList = fetchPointLotteryList;
exports.fetchPointLotteryLogList = fetchPointLotteryLogList;
exports.fetchScanList = fetchScanList;
exports.getHotel = getHotel;
exports.getNewDiscountList = getNewDiscountList;
exports.getPointLottery = getPointLottery;
exports.getScanQRCodeUrl = getScanQRCodeUrl;
exports.getSecKill = getSecKill;
exports.hotelList = hotelList;
exports.secKillInfo = secKillInfo;
exports.updateConnectedOffer = updateConnectedOffer;
exports.updateSpecial = updateSpecial;
exports.writeOffPointLotteryLog = writeOffPointLotteryLog;
var _request = _interopRequireDefault(require("@/utils/request"));
function getHotel(id, data) {
  return (0, _request.default)({
    url: "/hotel/".concat(id),
    method: 'get',
    params: data
  });
}

/***********特价码活动 ************/

function fetchList(data) {
  return (0, _request.default)({
    url: 'activity/specialOffer',
    method: 'get',
    params: data
  });
}
function addSpecial(data) {
  return (0, _request.default)({
    url: 'activity/addSpecial',
    method: 'post',
    data: data
  });
}
function updateSpecial(id, data) {
  return (0, _request.default)({
    url: "/activity/editSpecial/".concat(id),
    method: 'put',
    data: data
  });
}
function downSpecial(id) {
  return (0, _request.default)({
    url: "/activity/downSpecial/".concat(id),
    method: 'patch'
  });
}

/*********** 新店活动 ************/

function getNewDiscountList(data) {
  return (0, _request.default)({
    url: 'activity/newDiscount',
    method: 'get',
    params: data
  });
}
function addNewDiscount(data) {
  return (0, _request.default)({
    url: 'activity/addNewDiscount',
    method: 'post',
    data: data
  });
}
function editNewDiscount(id, data) {
  return (0, _request.default)({
    url: "/activity/editNewDiscount/".concat(id),
    method: 'put',
    data: data
  });
}
function downNewDiscount(id) {
  return (0, _request.default)({
    url: "/activity/downNewDiscount/".concat(id),
    method: 'patch'
  });
}

/*********** 连住活动 ************/

function hotelList(hotelId, data) {
  return (0, _request.default)({
    url: 'activity/connectedOffer/roomList/' + hotelId,
    method: 'get',
    params: data
  });
}
function connectedOffers(data) {
  return (0, _request.default)({
    url: 'activity/connectedOffers',
    method: 'get',
    params: data
  });
}
function addConnectedOffer(data) {
  return (0, _request.default)({
    url: 'activity/connectedOffer',
    method: 'post',
    data: data
  });
}
function updateConnectedOffer(id, data) {
  return (0, _request.default)({
    url: "/activity/connectedOffer/".concat(id),
    method: 'put',
    data: data
  });
}
function downConnectedOffer(id) {
  return (0, _request.default)({
    url: "/activity/connectedOffer/".concat(id),
    method: 'patch'
  });
}

/*********** 秒杀活动 ************/

function getSecKill(data) {
  return (0, _request.default)({
    url: 'activity/secKill',
    method: 'get',
    params: data
  });
}
function addSecKill(data) {
  return (0, _request.default)({
    url: 'activity/addSecKill',
    method: 'post',
    data: data
  });
}
function editSecKill(id, data) {
  return (0, _request.default)({
    url: "/activity/editSecKill/".concat(id),
    method: 'put',
    data: data
  });
}
function secKillInfo(id) {
  return (0, _request.default)({
    url: "/activity/secKill/".concat(id),
    method: 'get'
  });
}
function downSecKill(id) {
  return (0, _request.default)({
    url: "/activity/downSecKill/".concat(id),
    method: 'patch'
  });
}
function checkAreaSecKill(id, data) {
  return (0, _request.default)({
    url: "/activity/checkAreaSecKill/".concat(id),
    method: 'get',
    params: data
  });
}

/*********** 扫码红包活动 ************/

function fetchScanList(query) {
  return (0, _request.default)({
    url: '/activity/scan_codes',
    method: 'get',
    params: query
  });
}
function createScan(data) {
  return (0, _request.default)({
    url: 'activity/scan_codes',
    method: 'post',
    data: data
  });
}
function closeScan(id) {
  return (0, _request.default)({
    url: 'activity/scan_codes/' + id + '/closed',
    method: 'put'
  });
}
function getScanQRCodeUrl(id, token) {
  return process.env.VUE_APP_BASE_API + "/activity/scan_codes/".concat(id, "/qrcode?api_token=").concat(token);
}

/*********** 积分转盘活动 ************/

function fetchPointLotteryList(query) {
  return (0, _request.default)({
    url: '/activity/point_lotterys',
    method: 'get',
    params: query
  });
}
function getPointLottery(activityId) {
  return (0, _request.default)({
    url: "activity/point_lotterys/".concat(activityId),
    method: 'get'
  });
}
function createPointLottery(data) {
  return (0, _request.default)({
    url: 'activity/point_lotterys',
    method: 'post',
    data: data
  });
}
function closePointLottery(activityId) {
  return (0, _request.default)({
    url: "activity/point_lotterys/".concat(activityId, "/closed"),
    method: 'put'
  });
}
function fetchPointLotteryLogList(activityId, query) {
  return (0, _request.default)({
    url: "activity/point_lotterys/".concat(activityId, "/logs"),
    method: 'get',
    params: query
  });
}
function writeOffPointLotteryLog(activityId, logId) {
  return (0, _request.default)({
    url: "activity/point_lotterys/".concat(activityId, "/logs/").concat(logId, "/write_off"),
    method: 'put'
  });
}