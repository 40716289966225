var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "detail-container",
      attrs: {
        visible: _vm.detailVisible,
        "close-on-click-modal": false,
        title: "押金抵扣进度（待抵扣/总额）",
        width: "680px",
        top: "10vh",
      },
      on: { close: _vm.closeHandler },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
        },
        [
          _c("div", { staticClass: "detail-summary" }, [
            _vm._v(
              _vm._s(_vm.detail.to_deposit_amount) +
                " / " +
                _vm._s(_vm.detail.deposit_amount)
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.detail.withholding_deduct_amount,
                  expression: "detail.withholding_deduct_amount",
                },
              ],
              staticClass: "detail-tips",
            },
            [
              _vm._v(
                "含未审核确认金额：" +
                  _vm._s(_vm.detail.withholding_deduct_amount) +
                  " 元"
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "detail-table" },
            [
              _c(
                "el-table",
                {
                  attrs: {
                    "row-key": "id",
                    data: _vm.detail.deducts,
                    fit: "",
                    size: "small",
                    "header-row-class-name": "detail-table-header",
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { align: "center", label: "账期" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm
                                    .moment(scope.row.start_date_fmt)
                                    .format("yyyy年第WW周")
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "抵扣金额",
                      "class-name": "overflow-on",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(scope.row.deposit_deduct_amount) +
                                "\n          "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { align: "center", label: "状态" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [_vm._v(_vm._s(scope.row.status_name))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { align: "center", label: "操作", width: "120" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDetailRedirect(scope.row)
                                  },
                                },
                              },
                              [_vm._v("\n              查看\n            ")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.closeHandler } },
            [_vm._v("\n      确 定\n    ")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }