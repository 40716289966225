var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app-container calendar-list-container",
      staticStyle: { "background-color": "#f0f2f5" },
    },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _vm.areas.length > 0
            ? _c(
                "el-select",
                {
                  staticClass: "filter-item",
                  attrs: {
                    "default-first-option": "",
                    placeholder: "请选择区域",
                    clearable: "",
                  },
                  on: {
                    change: function ($event) {
                      return _vm.changeArea()
                    },
                  },
                  model: {
                    value: _vm.area_id,
                    callback: function ($$v) {
                      _vm.area_id = $$v
                    },
                    expression: "area_id",
                  },
                },
                _vm._l(_vm.areas, function (item) {
                  return _c("el-option", {
                    key: item.role_id,
                    attrs: { label: item.name, value: item.role_id },
                  })
                }),
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { id: "platform" } },
            [
              _c("el-alert", {
                attrs: {
                  closable: false,
                  title: "统计数据截止到昨天",
                  type: "success",
                },
              }),
              _vm._v(" "),
              _c(
                "el-tabs",
                {
                  attrs: { type: "card" },
                  on: { "tab-click": _vm.handleClick },
                  model: {
                    value: _vm.activeName,
                    callback: function ($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName",
                  },
                },
                [
                  _c(
                    "el-tab-pane",
                    { attrs: { name: "order", label: "订单数据" } },
                    [
                      _c(
                        "div",
                        {},
                        [
                          _c("el-date-picker", {
                            staticClass: "filter-item",
                            attrs: {
                              type: "daterange",
                              "start-placeholder": "",
                              "end-placeholder": "",
                              "value-format": "yyyyMMdd",
                              align: "right",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.getPlatFormOrder()
                              },
                            },
                            model: {
                              value: _vm.platformOrderDate,
                              callback: function ($$v) {
                                _vm.platformOrderDate = $$v
                              },
                              expression: "platformOrderDate",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              staticClass: "filter-item",
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.doDownload(1)
                                },
                              },
                            },
                            [_vm._v("\n              下载\n            ")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("el-alert", {
                        staticClass: "alert-title",
                        attrs: {
                          closable: false,
                          title: "订单数据：指已消费的订单，即已离店订单",
                          type: "info",
                        },
                      }),
                      _vm._v(" "),
                      _c("div", {
                        style: { width: "100%", height: "400px" },
                        attrs: { id: "platform-order-chart" },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "detail-data" }, [
                        _c(
                          "div",
                          {},
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "filter-item",
                                attrs: { "default-first-option": "" },
                                on: {
                                  change: function ($event) {
                                    return _vm.getCityOrder()
                                  },
                                },
                                model: {
                                  value: _vm.detailOrderType,
                                  callback: function ($$v) {
                                    _vm.detailOrderType = $$v
                                  },
                                  expression: "detailOrderType",
                                },
                              },
                              _vm._l(_vm.typeList, function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }),
                              1
                            ),
                            _vm._v(" "),
                            _c("el-date-picker", {
                              staticClass: "filter-item",
                              attrs: {
                                type: "daterange",
                                "start-placeholder": "",
                                "end-placeholder": "",
                                "value-format": "yyyyMMdd",
                                align: "right",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.getCityOrder()
                                },
                              },
                              model: {
                                value: _vm.cityOrderDate,
                                callback: function ($$v) {
                                  _vm.cityOrderDate = $$v
                                },
                                expression: "cityOrderDate",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                staticClass: "filter-item",
                                attrs: { type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.doDownload(7)
                                  },
                                },
                              },
                              [_vm._v("\n                下载\n              ")]
                            ),
                            _vm._v(" "),
                            _c("el-alert", {
                              staticClass: "alert-title",
                              attrs: {
                                closable: false,
                                title:
                                  "细分数据：所有城市或所有酒店的数据排行，默认只显示前12",
                                type: "info",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", {
                          style: { width: "100%", height: "400px" },
                          attrs: { id: "city-order-chart" },
                        }),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "detail-data" },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "filter-item",
                              attrs: { "default-first-option": "" },
                              on: {
                                change: function ($event) {
                                  return _vm.getSelectList()
                                },
                              },
                              model: {
                                value: _vm.trendOrderType,
                                callback: function ($$v) {
                                  _vm.trendOrderType = $$v
                                },
                                expression: "trendOrderType",
                              },
                            },
                            _vm._l(_vm.typeList, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-select",
                            {
                              staticClass: "filter-item",
                              attrs: { filterable: "" },
                              on: {
                                change: function ($event) {
                                  return _vm.getTrendOrder()
                                },
                              },
                              model: {
                                value: _vm.trendOrderId,
                                callback: function ($$v) {
                                  _vm.trendOrderId = $$v
                                },
                                expression: "trendOrderId",
                              },
                            },
                            _vm._l(_vm.trendOrderList, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                          _vm._v(" "),
                          _c("el-date-picker", {
                            staticClass: "filter-item",
                            attrs: {
                              type: "daterange",
                              "start-placeholder": "",
                              "end-placeholder": "",
                              "value-format": "yyyyMMdd",
                              align: "right",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.getTrendOrder()
                              },
                            },
                            model: {
                              value: _vm.trendOrderDate,
                              callback: function ($$v) {
                                _vm.trendOrderDate = $$v
                              },
                              expression: "trendOrderDate",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              staticClass: "filter-item",
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.doDownload(11)
                                },
                              },
                            },
                            [_vm._v("\n              下载\n            ")]
                          ),
                          _vm._v(" "),
                          _c("el-alert", {
                            staticClass: "alert-title",
                            attrs: {
                              closable: false,
                              title:
                                "细分趋势：某个城市或某家酒店的历史数据折线图",
                              type: "info",
                            },
                          }),
                          _vm._v(" "),
                          _c("div", {
                            style: { width: "100%", height: "400px" },
                            attrs: { id: "trend-order-chart" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "访问数据", name: "uv" } },
                    [
                      _c(
                        "div",
                        {},
                        [
                          _c("el-date-picker", {
                            staticClass: "filter-item",
                            attrs: {
                              type: "daterange",
                              "start-placeholder": "",
                              "end-placeholder": "",
                              "value-format": "yyyyMMdd",
                              align: "right",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.getPlatFormUV()
                              },
                            },
                            model: {
                              value: _vm.platformDate,
                              callback: function ($$v) {
                                _vm.platformDate = $$v
                              },
                              expression: "platformDate",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              staticClass: "filter-item",
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.doDownload(0)
                                },
                              },
                            },
                            [_vm._v("\n              下载\n            ")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("el-alert", {
                        staticClass: "alert-title",
                        attrs: {
                          closable: false,
                          title: "访问数据：一个用户一日内多次访问记一次",
                          type: "info",
                        },
                      }),
                      _vm._v(" "),
                      _c("div", {
                        style: { width: "100%", height: "400px" },
                        attrs: { id: "platform-chart" },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "detail-data" }, [
                        _c(
                          "div",
                          {},
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "filter-item",
                                attrs: { "default-first-option": "" },
                                on: {
                                  change: function ($event) {
                                    return _vm.getCityUV()
                                  },
                                },
                                model: {
                                  value: _vm.detailType,
                                  callback: function ($$v) {
                                    _vm.detailType = $$v
                                  },
                                  expression: "detailType",
                                },
                              },
                              _vm._l(_vm.typeList, function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }),
                              1
                            ),
                            _vm._v(" "),
                            _c("el-date-picker", {
                              staticClass: "filter-item",
                              attrs: {
                                type: "daterange",
                                "start-placeholder": "",
                                "end-placeholder": "",
                                "value-format": "yyyyMMdd",
                                align: "right",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.getCityUV()
                                },
                              },
                              model: {
                                value: _vm.cityDate,
                                callback: function ($$v) {
                                  _vm.cityDate = $$v
                                },
                                expression: "cityDate",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                staticClass: "filter-item",
                                attrs: { type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.doDownload(3)
                                  },
                                },
                              },
                              [_vm._v("\n                下载\n              ")]
                            ),
                            _vm._v(" "),
                            _c("el-alert", {
                              staticClass: "alert-title",
                              attrs: {
                                closable: false,
                                title:
                                  "细分数据：所有城市或所有酒店的数据排行，默认只显示前12",
                                type: "info",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", {
                          style: { width: "100%", height: "400px" },
                          attrs: { id: "city-chart" },
                        }),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "detail-data" },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "filter-item",
                              attrs: { "default-first-option": "" },
                              on: {
                                change: function ($event) {
                                  return _vm.getSelectList()
                                },
                              },
                              model: {
                                value: _vm.trendType,
                                callback: function ($$v) {
                                  _vm.trendType = $$v
                                },
                                expression: "trendType",
                              },
                            },
                            _vm._l(_vm.typeList, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-select",
                            {
                              staticClass: "filter-item",
                              attrs: { filterable: "" },
                              on: {
                                change: function ($event) {
                                  return _vm.getTrendUV()
                                },
                              },
                              model: {
                                value: _vm.trendId,
                                callback: function ($$v) {
                                  _vm.trendId = $$v
                                },
                                expression: "trendId",
                              },
                            },
                            _vm._l(_vm.trendList, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                          _vm._v(" "),
                          _c("el-date-picker", {
                            staticClass: "filter-item",
                            attrs: {
                              type: "daterange",
                              "value-format": "yyyyMMdd",
                              align: "right",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.getTrendUV()
                              },
                            },
                            model: {
                              value: _vm.trendDate,
                              callback: function ($$v) {
                                _vm.trendDate = $$v
                              },
                              expression: "trendDate",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              staticClass: "filter-item",
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.doDownload(5)
                                },
                              },
                            },
                            [_vm._v("\n              下载\n            ")]
                          ),
                          _vm._v(" "),
                          _c("el-alert", {
                            staticClass: "alert-title",
                            attrs: {
                              closable: false,
                              title:
                                "细分趋势：某个城市或某家酒店的历史数据折线图",
                              type: "info",
                            },
                          }),
                          _vm._v(" "),
                          _c("div", {
                            style: { width: "100%", height: "400px" },
                            attrs: { id: "trend-chart" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "转化率", name: "trans" } },
                    [
                      _c(
                        "div",
                        {},
                        [
                          _c("el-date-picker", {
                            staticClass: "filter-item",
                            attrs: {
                              type: "daterange",
                              "start-placeholder": "",
                              "end-placeholder": "",
                              "value-format": "yyyyMMdd",
                              align: "right",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.getPlatFormTrans()
                              },
                            },
                            model: {
                              value: _vm.platformTransDate,
                              callback: function ($$v) {
                                _vm.platformTransDate = $$v
                              },
                              expression: "platformTransDate",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              staticClass: "filter-item",
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.doDownload(2)
                                },
                              },
                            },
                            [_vm._v("\n              下载\n            ")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("el-alert", {
                        staticClass: "alert-title",
                        attrs: {
                          closable: false,
                          title: "转化率：订单量/访问量",
                          type: "info",
                        },
                      }),
                      _vm._v(" "),
                      _c("div", {
                        style: { width: "100%", height: "400px" },
                        attrs: { id: "platform-trans-chart" },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "detail-data" }, [
                        _c(
                          "div",
                          {},
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "filter-item",
                                attrs: { "default-first-option": "" },
                                on: {
                                  change: function ($event) {
                                    return _vm.getCityTrans()
                                  },
                                },
                                model: {
                                  value: _vm.detailTransType,
                                  callback: function ($$v) {
                                    _vm.detailTransType = $$v
                                  },
                                  expression: "detailTransType",
                                },
                              },
                              _vm._l(_vm.typeList, function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }),
                              1
                            ),
                            _vm._v(" "),
                            _c("el-date-picker", {
                              staticClass: "filter-item",
                              attrs: {
                                type: "daterange",
                                "value-format": "yyyyMMdd",
                                align: "right",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.getCityTrans()
                                },
                              },
                              model: {
                                value: _vm.cityTransDate,
                                callback: function ($$v) {
                                  _vm.cityTransDate = $$v
                                },
                                expression: "cityTransDate",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                staticClass: "filter-item",
                                attrs: { type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.doDownload(9)
                                  },
                                },
                              },
                              [_vm._v("\n                下载\n              ")]
                            ),
                            _vm._v(" "),
                            _c("el-alert", {
                              staticClass: "alert-title",
                              attrs: {
                                closable: false,
                                title:
                                  "细分数据：所有城市或所有酒店的数据排行，默认只显示前12",
                                type: "info",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", {
                          style: { width: "100%", height: "400px" },
                          attrs: { id: "city-trans-chart" },
                        }),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "detail-data" },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "filter-item",
                              attrs: { "default-first-option": "" },
                              on: {
                                change: function ($event) {
                                  return _vm.getSelectList()
                                },
                              },
                              model: {
                                value: _vm.trendTransType,
                                callback: function ($$v) {
                                  _vm.trendTransType = $$v
                                },
                                expression: "trendTransType",
                              },
                            },
                            _vm._l(_vm.typeList, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-select",
                            {
                              staticClass: "filter-item",
                              attrs: { filterable: "" },
                              on: {
                                change: function ($event) {
                                  return _vm.getTrendTrans()
                                },
                              },
                              model: {
                                value: _vm.trendTransId,
                                callback: function ($$v) {
                                  _vm.trendTransId = $$v
                                },
                                expression: "trendTransId",
                              },
                            },
                            _vm._l(_vm.trendTransList, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                          _vm._v(" "),
                          _c("el-date-picker", {
                            staticClass: "filter-item",
                            attrs: {
                              type: "daterange",
                              "start-placeholder": "",
                              "end-placeholder": "",
                              "value-format": "yyyyMMdd",
                              align: "right",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.getTrendTrans()
                              },
                            },
                            model: {
                              value: _vm.trendTransDate,
                              callback: function ($$v) {
                                _vm.trendTransDate = $$v
                              },
                              expression: "trendTransDate",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              staticClass: "filter-item",
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.doDownload(13)
                                },
                              },
                            },
                            [_vm._v("\n              下载\n            ")]
                          ),
                          _vm._v(" "),
                          _c("el-alert", {
                            staticClass: "alert-title",
                            attrs: {
                              closable: false,
                              title:
                                "细分趋势：某个城市或某家酒店的历史数据折线图",
                              type: "info",
                            },
                          }),
                          _vm._v(" "),
                          _c("div", {
                            style: { width: "100%", height: "400px" },
                            attrs: { id: "trend-trans-chart" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }