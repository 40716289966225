"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es6.number.constructor");
var _finance_reco = require("@/api/finance_reco");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: "AdjustBillForm",
  components: {},
  props: {
    id: [Number, String],
    visible: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    }
  },
  watch: {
    visible: function visible() {
      this.dialogVisible = this.visible;
    },
    id: function id() {
      if (this.id) {
        this.getBill();
      }
    }
  },
  data: function data() {
    return {
      dialogVisible: false,
      bill: {},
      formLoading: false,
      billFormSubmitLoading: false,
      billForm: {
        id: undefined,
        adjust_amount: undefined,
        adjust_reason: undefined
      },
      billFormRules: {
        adjust_amount: [{
          required: true,
          message: '请输入调整金额',
          trigger: 'change'
        }],
        adjust_reason: [{
          required: true,
          message: '请输入调整原因',
          trigger: 'change'
        }]
      }
    };
  },
  filters: {
    previceDateFormat: function previceDateFormat(value) {
      if (!value) return '';
      var date = value.toString();
      return "".concat(date.slice(0, 4), "-").concat(date.slice(4, 6), "-").concat(date.slice(6, 8));
    }
  },
  mounted: function mounted() {},
  methods: {
    getBill: function getBill() {
      var _this = this;
      this.formLoading = true;
      (0, _finance_reco.getFinanceBill)(this.id).then(function (result) {
        _this.bill = result.data;
        _this.billForm = {
          id: _this.bill.id,
          adjust_amount: _this.bill.adjust_amount || undefined,
          adjust_reason: _this.bill.adjust_reason || undefined
        };
        _this.formLoading = false;
      });
    },
    billFormCloseHanlder: function billFormCloseHanlder() {
      this.$refs['billForm'].resetFields();
      this.$emit('update:id', "");
      this.$emit('update:visible', false);
    },
    billFormSubmitHandler: function billFormSubmitHandler() {
      var _this2 = this;
      this.$refs['billForm'].validate(function (valid) {
        if (valid) {
          var form = JSON.parse(JSON.stringify(_this2.billForm));
          form.adjust_amount = parseFloat(form.adjust_amount);
          _this2.billFormSubmitLoading = true;
          (0, _finance_reco.adjustFinanceBill)(form.id, form).then(function () {
            _this2.$message.success("调整成功");
            _this2.$emit('success');
            _this2.dialogVisible = false;
          }).finally(function () {
            _this2.billFormSubmitLoading = false;
          });
        }
      });
    }
  }
};
exports.default = _default2;