"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _net_bar = require("@/api/net_bar");
var _add_form = _interopRequireDefault(require("./add_form.vue"));
var _edit_form = _interopRequireDefault(require("./edit_form.vue"));
var _mixin = _interopRequireDefault(require("@/utils/mixin"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "NetBarStoreDevice",
  mixins: [_mixin.default],
  components: {
    NetBarDeviceAddForm: _add_form.default,
    NetBarDeviceEditForm: _edit_form.default
  },
  props: {
    storeId: {
      type: [String, Number]
    }
  },
  data: function data() {
    return {
      list: [],
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 20,
        keyword: ""
      },
      pagination: "",
      deviceId: "",
      deviceAddFormVisible: false,
      deviceEditFormVisible: false,
      // 使用记录
      deviceOrdersDialogVisible: false,
      orderList: [],
      orderListLoading: false,
      orderPagination: {
        page: 1,
        limit: 10,
        total: 0
      }
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      (0, _net_bar.fetchStoreDeviceList)(this.storeId, this.listQuery).then(function (response) {
        var data = response.data;
        _this.list = data.data;
        _this.pagination = data;
        _this.listQuery.page = data.current_page;
        _this.listLoading = false;
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    addHandler: function addHandler() {
      this.deviceId = "";
      this.deviceAddFormVisible = true;
    },
    editHandler: function editHandler(record) {
      this.deviceId = record.id;
      this.deviceEditFormVisible = true;
    },
    deviceCloseHandler: function deviceCloseHandler(record) {
      var _this2 = this;
      this.$confirm('一旦下机，当前设备订单自动结束', '你确定要为当前设备操作下机吗？', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _net_bar.offlineStoreDevice)(_this2.storeId, record.id).then(function () {
          _this2.$message.success("操作成功");
          // 下线为异步操作，手动调整列表数据状态
          record.used = 0;
          record.used_uid = 0;
        });
      }).catch(function () {});
    },
    deviceStartSvcHandler: function deviceStartSvcHandler(deviceId) {
      var _this3 = this;
      (0, _net_bar.updateStoreDeviceStatus)(this.storeId, deviceId, 1).then(function () {
        _this3.getList();
        _this3.$message.success("操作成功");
      });
    },
    deviceStopSvcHandler: function deviceStopSvcHandler(deviceId) {
      var _this4 = this;
      this.$confirm('一旦下架，门店对应设备将无法开机。', '你确定要下架该设备吗？', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      }).then(function () {
        (0, _net_bar.updateStoreDeviceStatus)(_this4.storeId, deviceId, 0).then(function () {
          _this4.getList();
          _this4.$message.success("操作成功");
        });
      }).catch(function () {});
    },
    deleteHandler: function deleteHandler(deviceId) {
      var _this5 = this;
      this.$confirm('一旦删除，门店对应设备将无法开机。', '你确定要删除该设备吗？', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      }).then(function () {
        (0, _net_bar.deleteStoreDevice)(_this5.storeId, deviceId).then(function () {
          _this5.getList();
          _this5.$message.success("操作成功");
        });
      }).catch(function () {});
    },
    showUsageRecordHandler: function showUsageRecordHandler(deviceId) {
      this.getOrderList(deviceId);
      this.deviceOrdersDialogVisible = true;
    },
    getOrderList: function getOrderList(deviceId) {
      var _this6 = this;
      this.orderListLoading = true;
      (0, _net_bar.fetchOrderList)({
        nb_id: this.storeId,
        device_id: deviceId,
        page: this.orderPagination.page,
        limit: this.orderPagination.limit
      }).then(function (response) {
        var data = response.data;
        _this6.orderList = data.data;
        _this6.orderPagination.page = data.current_page;
        _this6.orderPagination.total = data.total;
        _this6.orderListLoading = false;
      });
    },
    handleOrderSizeChange: function handleOrderSizeChange(val) {
      this.orderPagination.limit = val;
      this.getOrderList();
    },
    handleOrderCurrentChange: function handleOrderCurrentChange(val) {
      this.orderPagination.page = val;
      this.getOrderList();
    },
    getUseTime: function getUseTime(order) {
      var startTime = new Date(order.started_at).getTime() / 1000;
      var useTime = 0;
      if (order.status == 40) {
        var endTime = new Date(order.ended_at).getTime() / 1000;
        useTime = endTime - startTime;
      } else if (order.status == 30) {
        var now = new Date().getTime() / 1000;
        useTime = now - startTime;
      }
      // to H时m分
      var format = '';
      var h = parseInt(useTime / 3600);
      if (h > 0) format += "".concat(h, "\u5C0F\u65F6");
      var m = parseInt(useTime % 3600 / 60);
      if (m > 0) format += "".concat(m, "\u5206\u949F");
      return format;
    },
    deviceAddEnabled: function deviceAddEnabled() {
      // 非财务
      return !this.isRoleBill();
    },
    deviceCloseEnabled: function deviceCloseEnabled(record) {
      // 使用中，且非财务
      if (record.used && !this.isRoleBill()) {
        return true;
      }
      return false;
    },
    deviceStatusEnabled: function deviceStatusEnabled() {
      // 管理员或财务
      return this.isRoleAdmin() || this.isRoleBill();
    }
  }
};
exports.default = _default;