var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container calendar-list-container work-order-tempate" },
    [
      _c("div", { staticClass: "filter-container filter-container-flex" }, [
        _c(
          "div",
          [
            _c("el-input", {
              staticClass: "filter-item",
              staticStyle: { width: "190px" },
              attrs: { placeholder: "工单内容" },
              model: {
                value: _vm.listQuery.title,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "title", $$v)
                },
                expression: "listQuery.title",
              },
            }),
            _vm._v(" "),
            _c(
              "el-select",
              {
                staticClass: "filter-item",
                attrs: { placeholder: "工单类型", clearable: "" },
                model: {
                  value: _vm.listQuery.type,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery, "type", $$v)
                  },
                  expression: "listQuery.type",
                },
              },
              [
                _c("el-option", {
                  attrs: { value: "", label: "全部工单类型" },
                }),
                _vm._v(" "),
                _vm._l(_vm.templateTypes, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "el-select",
              {
                staticClass: "filter-item",
                attrs: { placeholder: "工单级别", clearable: "" },
                model: {
                  value: _vm.listQuery.level,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery, "level", $$v)
                  },
                  expression: "listQuery.level",
                },
              },
              [
                _c("el-option", { attrs: { value: "", label: "全部级别" } }),
                _vm._v(" "),
                _vm._l(_vm.templateLevels, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "filter-item",
                attrs: { type: "primary" },
                on: { click: _vm.handleFilter },
              },
              [_vm._v("\n        搜索\n      ")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "filter-item",
                attrs: { type: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.addHandler()
                  },
                },
              },
              [_vm._v("\n        新建\n      ")]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.templates,
            "element-loading-text": "给我一点时间",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "编号", width: "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.id))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "工单内容" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.title))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "级别", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.level_name))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "类型", width: "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.type_name))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "循环设置", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.cycle_name))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "时效", width: "140" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.cycle == 2
                      ? _c(
                          "div",
                          { staticStyle: { "white-space": "break-spaces" } },
                          [_vm._v(_vm._s(scope.row.cycle_time))]
                        )
                      : _c("span", [_vm._v(_vm._s(scope.row.cycle_time))]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "创建人", width: "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.create_uname))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "反馈流程" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          scope.row.flows
                            .map(function (flow) {
                              return flow.name
                            })
                            .join("-")
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              fixed: "right",
              align: "center",
              label: "操作",
              width: "160",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.showHandler(scope.row)
                          },
                        },
                      },
                      [_vm._v("\n          查看\n        ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.editHandler(scope.row)
                          },
                        },
                      },
                      [_vm._v("\n          编辑\n        ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-popconfirm",
                      {
                        attrs: {
                          title:
                            "你确定要" +
                            (scope.row.status ? "禁用" : "恢复") +
                            "该模板吗？",
                        },
                        on: {
                          confirm: function ($event) {
                            return _vm.statusChangeHandler(
                              scope.row.id,
                              scope.row.status ? 0 : 1
                            )
                          },
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: {
                              color: "#F56C6C",
                              "margin-left": "10px",
                            },
                            attrs: { slot: "reference", type: "text" },
                            slot: "reference",
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(scope.row.status ? "禁用" : "恢复") +
                                "\n          "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "pagination-container text-right" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              total: _vm.pagination.total,
              "current-page": _vm.listQuery.page,
              "page-sizes": [10, 20, 50],
              "page-size": _vm.listQuery.limit,
              layout: "total, sizes, prev, pager, next, jumper",
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "template-form-dialog",
          attrs: {
            visible: _vm.dialogVisible,
            title: (_vm.templateForm.id ? "编辑" : "新建") + "工单模板",
            "close-on-click-modal": false,
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            close: _vm.templateFormCloseHanlder,
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.formLoading,
                  expression: "formLoading",
                },
              ],
              ref: "templateForm",
              attrs: {
                model: _vm.templateForm,
                rules: _vm.templateFormRules,
                disabled: _vm.templateFormShowDetail,
                "label-suffix": ":",
                "label-width": "105px",
                "label-position": "right",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "工单内容", prop: "title" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "400px" },
                    attrs: { placeholder: "请输入工单内容" },
                    model: {
                      value: _vm.templateForm.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.templateForm, "title", $$v)
                      },
                      expression: "templateForm.title",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "工单级别", prop: "level" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { filterable: "", placeholder: "请选择工单级别" },
                      model: {
                        value: _vm.templateForm.level,
                        callback: function ($$v) {
                          _vm.$set(_vm.templateForm, "level", $$v)
                        },
                        expression: "templateForm.level",
                      },
                    },
                    _vm._l(_vm.templateLevels, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "工单类型", prop: "type" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.templateForm.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.templateForm, "type", $$v)
                        },
                        expression: "templateForm.type",
                      },
                    },
                    _vm._l(_vm.templateTypes, function (item) {
                      return _c(
                        "el-radio",
                        { key: item.value, attrs: { label: item.value } },
                        [_vm._v(_vm._s(item.label))]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "循环设置", prop: "cycle" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { disabled: !!_vm.templateForm.id },
                      on: { change: _vm.onCycleChange },
                      model: {
                        value: _vm.templateForm.cycle,
                        callback: function ($$v) {
                          _vm.$set(_vm.templateForm, "cycle", $$v)
                        },
                        expression: "templateForm.cycle",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("每日")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 2 } }, [_vm._v("即时")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "工单时效", required: "" } },
                [
                  _vm.templateForm.cycle == 1
                    ? [
                        _c(
                          "el-col",
                          { attrs: { span: 11 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { prop: "cycle_time_start" } },
                              [
                                _c("el-time-picker", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    "value-format": "HH:mm:ss",
                                    placeholder: "选择开始时间",
                                  },
                                  on: { change: _vm.onCycleTimeStartChange },
                                  model: {
                                    value: _vm.templateForm.cycle_time_start,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.templateForm,
                                        "cycle_time_start",
                                        $$v
                                      )
                                    },
                                    expression: "templateForm.cycle_time_start",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { staticClass: "line", attrs: { span: 2 } },
                          [_vm._v("至")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 11 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { prop: "cycle_time_end" } },
                              [
                                _c("el-time-picker", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    "value-format": "HH:mm:ss",
                                    placeholder: "选择结束时间",
                                  },
                                  on: { change: _vm.onCycleTimeEndChange },
                                  model: {
                                    value: _vm.templateForm.cycle_time_end,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.templateForm,
                                        "cycle_time_end",
                                        $$v
                                      )
                                    },
                                    expression: "templateForm.cycle_time_end",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    : _vm.templateForm.cycle == 2
                    ? [
                        _c(
                          "el-col",
                          { attrs: { span: 11 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { prop: "cycle_time_start" } },
                              [
                                _c("el-date-picker", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    type: "datetime",
                                    "value-format": "yyyy-MM-dd HH:mm:ss",
                                    placeholder: "选择开始时间",
                                  },
                                  on: {
                                    change: _vm.onCycleDatetimeStartChange,
                                  },
                                  model: {
                                    value: _vm.templateForm.cycle_time_start,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.templateForm,
                                        "cycle_time_start",
                                        $$v
                                      )
                                    },
                                    expression: "templateForm.cycle_time_start",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { staticClass: "line", attrs: { span: 2 } },
                          [_vm._v("至")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 11 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { prop: "cycle_time_end" } },
                              [
                                _c("el-date-picker", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    type: "datetime",
                                    "value-format": "yyyy-MM-dd HH:mm:ss",
                                    placeholder: "选择结束时间",
                                  },
                                  on: { change: _vm.onCycleDatetimeEndChange },
                                  model: {
                                    value: _vm.templateForm.cycle_time_end,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.templateForm,
                                        "cycle_time_end",
                                        $$v
                                      )
                                    },
                                    expression: "templateForm.cycle_time_end",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "下发酒店", required: "" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 14 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "target_hotel_ids" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "250px" },
                              attrs: {
                                multiple: "",
                                filterable: "",
                                placeholder: "请选择下发酒店",
                              },
                              model: {
                                value: _vm.templateForm.target_hotel_ids,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.templateForm,
                                    "target_hotel_ids",
                                    $$v
                                  )
                                },
                                expression: "templateForm.target_hotel_ids",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "全部酒店", value: 0 },
                              }),
                              _vm._v(" "),
                              _vm._l(_vm.hotelList, function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: { label: item.name, value: item.id },
                                })
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "target_role" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                filterable: "",
                                placeholder: "请选择通知对象",
                              },
                              model: {
                                value: _vm.templateForm.target_role,
                                callback: function ($$v) {
                                  _vm.$set(_vm.templateForm, "target_role", $$v)
                                },
                                expression: "templateForm.target_role",
                              },
                            },
                            _vm._l(_vm.hotelEmployeeList, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "反馈流程", prop: "flows" } },
                [
                  _vm._l(_vm.templateForm.flows, function (flowItem, idx) {
                    return _c(
                      "div",
                      { staticClass: "flow-item" },
                      [
                        _c(
                          "el-row",
                          { attrs: { type: "flex", gutter: 5 } },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      filterable: "",
                                      placeholder: "请选择",
                                    },
                                    model: {
                                      value:
                                        _vm.templateForm.flows[idx].wo_role_id,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.templateForm.flows[idx],
                                          "wo_role_id",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "templateForm.flows[idx].wo_role_id",
                                    },
                                  },
                                  _vm._l(_vm.WORoleList, function (item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.name,
                                        value: item.id,
                                        disabled:
                                          _vm.templateForm.flows
                                            .map(function (flow) {
                                              return flow.wo_role_id
                                            })
                                            .indexOf(item.id) >= 0,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              { staticClass: "line", attrs: { span: 1 } },
                              [_vm._v("--")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              { staticClass: "line", attrs: { span: 4 } },
                              [_vm._v("处理时间")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              { attrs: { span: 6 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { prop: "share_rate" } },
                                  [
                                    _c("el-input", {
                                      model: {
                                        value:
                                          _vm.templateForm.flows[idx]
                                            .time_limit,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.templateForm.flows[idx],
                                            "time_limit",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "templateForm.flows[idx].time_limit",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              { staticClass: "line", attrs: { span: 2 } },
                              [_vm._v("分钟")]
                            ),
                            _vm._v(" "),
                            idx > 0
                              ? _c("el-col", { attrs: { span: 2 } }, [
                                  !_vm.templateFormShowDetail
                                    ? _c("i", {
                                        staticClass:
                                          "el-icon-remove-outline flow-remove-icon",
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeFlowItemHandler(
                                              idx
                                            )
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  _vm._v(" "),
                  !_vm.templateFormShowDetail
                    ? _c(
                        "div",
                        {
                          staticClass: "flow-add-btn",
                          on: { click: _vm.addFlowItemHandler },
                        },
                        [
                          _c("i", {
                            staticClass: "el-icon-plus flow-plus-icon",
                          }),
                          _vm._v("添加\n        "),
                        ]
                      )
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _vm.templateFormShowDetail
                ? [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            _vm.dialogVisible = false
                          },
                        },
                      },
                      [_vm._v("确定")]
                    ),
                  ]
                : [
                    _c(
                      "el-button",
                      {
                        on: {
                          click: function ($event) {
                            _vm.dialogVisible = false
                          },
                        },
                      },
                      [_vm._v("取 消")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          loading: _vm.templateFormSubmitLoading,
                        },
                        on: { click: _vm.templateFormSubmitHandler },
                      },
                      [_vm._v("确定")]
                    ),
                  ],
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }