"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.find");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.array.from");
//
//
//
//
//
//
//
//

// https://github.com/stephan281094/vue-drag-select/tree/master
// Takes an array and returns a copy of the array without duplicates
function uniqueArray(array) {
  var newArray = array.concat();
  for (var i = 0; i < newArray.length; ++i) {
    for (var j = i + 1; j < newArray.length; ++j) {
      if (newArray[i] === newArray[j]) {
        newArray.splice(j--, 1);
      }
    }
  }
  return newArray;
}
var _default = {
  name: 'vue-drag-select',
  props: {
    selectorClass: {
      type: String,
      required: true
    },
    color: {
      type: String,
      default: 'rgba(0, 162, 255, .4)'
    }
  },
  data: function data() {
    return {
      mouseDown: false,
      concat: false,
      startPoint: null,
      endPoint: null,
      selectedItems: []
    };
  },
  computed: {
    selectionBox: function selectionBox() {
      // Only set styling when necessary
      if (!this.mouseDown || !this.startPoint || !this.endPoint) return {};
      var clientRect = this.$el.getBoundingClientRect();
      var scroll = this.getScroll();

      // Calculate position and dimensions of the selection box
      var left = Math.min(this.startPoint.x, this.endPoint.x) - clientRect.left - scroll.x;
      var top = Math.min(this.startPoint.y, this.endPoint.y) - clientRect.top - scroll.y;
      var width = Math.abs(this.startPoint.x - this.endPoint.x);
      var height = Math.abs(this.startPoint.y - this.endPoint.y);

      // Return the styles to be applied
      return {
        left: left,
        top: top,
        width: width,
        height: height
      };
    },
    selectionBoxStyling: function selectionBoxStyling() {
      // Only set styling when necessary
      if (!this.mouseDown || !this.startPoint || !this.endPoint) {
        return {
          background: this.color
        };
      }
      var _this$selectionBox = this.selectionBox,
        left = _this$selectionBox.left,
        top = _this$selectionBox.top,
        width = _this$selectionBox.width,
        height = _this$selectionBox.height;

      // Return the styles to be applied
      return {
        background: this.color,
        left: "".concat(left, "px"),
        top: "".concat(top, "px"),
        width: "".concat(width, "px"),
        height: "".concat(height, "px")
      };
    }
  },
  watch: {
    selectedItems: function selectedItems(val) {
      this.$emit('change', val);
    }
  },
  methods: {
    getScroll: function getScroll() {
      // If we're on the server, default to 0,0
      if (typeof document === 'undefined') {
        return {
          x: 0,
          y: 0
        };
      }
      return {
        x: this.$el.scrollLeft || document.body.scrollLeft || document.documentElement.scrollLeft,
        y: this.$el.scrollTop || document.body.scrollTop || document.documentElement.scrollTop
      };
    },
    onMouseDown: function onMouseDown(event) {
      // Ignore right clicks
      if (event.button === 2) return;

      // Check if shift is down
      this.concat = event.shiftKey;

      // Register begin point
      this.mouseDown = true;
      this.startPoint = {
        x: event.pageX,
        y: event.pageY
      };

      // Start listening for mouse move and up events
      window.addEventListener('mousemove', this.onMouseMove);
      window.addEventListener('mouseup', this.onMouseUp);
    },
    onMouseMove: function onMouseMove(event) {
      var _this = this;
      // Update the end point position
      if (this.mouseDown) {
        this.endPoint = {
          x: event.pageX,
          y: event.pageY
        };
        var children = this.$children.length ? this.$children : this.$el.children;
        if (children) {
          var selected = Array.from(children).filter(function (item) {
            return _this.isItemSelected(item.$el || item);
          });

          // If shift was held during mousedown the new selection is added to the current. Otherwise the new selection
          // will be selected
          this.selectedItems = this.concat ? uniqueArray(this.selectedItems.concat(selected)) : selected;
        }
      }
    },
    onMouseUp: function onMouseUp(event) {
      // Clean up event listeners
      window.removeEventListener('mousemove', this.onMouseMove);
      window.removeEventListener('mouseup', this.onMouseUp);

      // Reset state
      this.mouseDown = false;
      this.concat = false;
      this.startPoint = null;
      this.endPoint = null;

      // 变化后回调
      this.$emit('confirm', this.selectedItems);
    },
    isItemSelected: function isItemSelected(el) {
      if (el.classList.contains(this.selectorClass)) {
        var boxA = this.selectionBox;
        var boxB = {
          top: el.offsetTop,
          left: el.offsetLeft,
          width: el.clientWidth,
          height: el.clientHeight
        };
        return !!(boxA.left <= boxB.left + boxB.width && boxA.left + boxA.width >= boxB.left && boxA.top <= boxB.top + boxB.height && boxA.top + boxA.height >= boxB.top);
      }
      return false;
    },
    reset: function reset() {
      this.selectedItems = [];
    }
  },
  mounted: function mounted() {
    var _this2 = this;
    this.$children.forEach(function (child) {
      child.$on('click', function (event) {
        var included = _this2.selectedItems.find(function (item) {
          return child.$el === item.$el;
        });
        if (included) {
          _this2.selectedItems = _this2.selectedItems.filter(function (item) {
            return child.$el !== item.$el;
          });
        } else {
          _this2.selectedItems.push(child);
        }
      });
    });
  },
  beforeDestroy: function beforeDestroy() {
    // Remove event listeners
    window.removeEventListener('mousemove', this.onMouseMove);
    window.removeEventListener('mouseup', this.onMouseUp);
    this.$children.forEach(function (child) {
      child.$off('click');
    });
  }
};
exports.default = _default;