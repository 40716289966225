"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.to-string");
var _finance_reco = require("@/api/finance_reco");
var _ThirdOrderForm = _interopRequireDefault(require("./ThirdOrderForm.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "FinanceFeeOrderTable",
  components: {
    ThirdOrderForm: _ThirdOrderForm.default
  },
  filters: {
    dateFormat: function dateFormat(value) {
      if (!value) return '';
      var date = value.toString();
      return date.slice(0, 4) + '-' + date.slice(4, 6) + '-' + date.slice(6, 8);
    }
  },
  props: {
    loading: [Boolean],
    data: [Array],
    summary: [Object],
    query: [Object],
    pagination: [Object],
    dateRange: [Array],
    allowAction: [Object]
  },
  data: function data() {
    return {
      orderFormVisible: false,
      orderId: ''
    };
  },
  created: function created() {},
  methods: {
    handleSizeChange: function handleSizeChange(val) {
      var query = this.query;
      query.limit = val;
      this.$emit('update:query', query);
      this.getList();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      var query = this.query;
      query.page = val;
      this.$emit('update:query', query);
      this.getList();
    },
    getList: function getList() {
      this.$emit("getData", this.query);
    },
    getSummaries: function getSummaries(param) {
      var sums = [];
      sums[0] = "金额合计";
      sums[6] = this.summary.amount + "元";
      sums[7] = this.summary.real_amount + "元";
      sums[8] = this.summary.commission + "元";
      sums[9] = this.summary.hotel_amount + "元";
      sums[10] = this.summary.hotel_real_amount + "元";
      sums[11] = this.summary.ota_service_fee + "元";
      sums[12] = this.summary.ota_service_refund_fee + "元";
      sums[13] = this.summary.hotel_agent_amount + "元";
      sums[14] = this.summary.hotel_agent_real_amount + "元";
      return sums;
    },
    editHandler: function editHandler(record) {
      this.orderId = record.id;
      this.orderFormVisible = true;
    },
    inDateRange: function inDateRange(record) {
      if (this.dateRange.length == 0) {
        return false;
      }
      var endDateStr = record.bill_end_date.toString();
      endDateStr = endDateStr.slice(0, 4) + '-' + endDateStr.slice(4, 6) + '-' + endDateStr.slice(6, 8);
      return endDateStr >= this.dateRange[0] && endDateStr <= this.dateRange[1];
    },
    deleteHandler: function deleteHandler(id) {
      var _this = this;
      this.$confirm('确认删除订单吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _finance_reco.deleteFinanceRecoOrder)(id).then(function () {
          _this.getList();
          _this.$message.success("操作成功");
        });
      });
    },
    getChannelName: function getChannelName(recode) {
      if (!recode.is_third) {
        return '小程序';
      }
      var channelName = '-';
      switch (recode.third_channel) {
        case 1:
          channelName = '美团同舟';
          break;
        case 2:
          channelName = '美团独立';
          break;
        case 4:
        case 5:
          channelName = '携程代理';
          break;
        case 6:
          channelName = '携程独立';
          break;
        case 7:
          channelName = '线下渠道';
          break;
        case 8:
          channelName = '抖音渠道';
          break;
      }
      return channelName;
    }
  }
};
exports.default = _default;