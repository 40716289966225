"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.to-string");
var _mixin = _interopRequireDefault(require("@/utils/mixin"));
var _moment = _interopRequireDefault(require("moment"));
var _bill = require("@/api/bill");
var _hotel = require("@/api/hotel");
var _auth = require("@/utils/auth");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  mixins: [_mixin.default],
  filters: {
    dateFormat: function dateFormat(value) {
      if (!value) return '';
      var date = value.toString();
      return date.slice(0, 4) + '-' + date.slice(4, 6) + '-' + date.slice(6, 8);
    }
  },
  data: function data() {
    return {
      areas: [],
      alert_title: '',
      list: [],
      modeList: [],
      dialogFormVisible: false,
      hotelList: [],
      multipleSelection: [],
      selected: [],
      select_num: 0,
      select_amount: 0,
      listQuery: {
        page: 1,
        limit: 10,
        bill_week: "",
        date: "",
        hotel_id: "",
        status: "",
        recharge_status: "",
        area_id: "",
        mode: ""
      },
      pagination: "",
      statusOptions: [
      // { value: 10,label: '商家待确认'},
      // { value: 11,label: '商家确认异常'},
      // { value: 20,label: '待发起I8付款'},
      // { value: 21,label: 'I8审核中'},
      // { value: 22,label: 'I8被驳回'},
      {
        value: 30,
        label: '待打款'
      }, {
        value: 40,
        label: '已打款'
      }],
      statusNameMap: {
        10: '商家待确认',
        // 11: '商家确认异常',
        20: '待发起I8付款',
        21: 'I8审核中',
        22: 'I8被驳回',
        30: '待打款',
        40: '已打款'
      },
      rechargeStatusOptions: [{
        value: 0,
        label: '待充值'
      }, {
        value: 1,
        label: '已充值'
      }],
      rechargeStatusNameMap: {
        0: '待充值',
        1: '已充值'
      }
    };
  },
  created: function created() {
    var _this = this;
    var prevWeekStart = this.getWeekRange((0, _moment.default)().subtract(7, 'days').format("YYYY-MM-DD"))[0];
    this.listQuery.bill_week = (0, _moment.default)(prevWeekStart).format("YYYYMMDD");
    this.getList();
    (0, _hotel.getHotelSimpleList)().then(function (response) {
      _this.hotelList = response.data;
    });
    if ((0, _auth.getGrade)() < 1) {
      (0, _hotel.getArea)().then(function (response) {
        _this.areas = response.data;
      });
    }
    (0, _hotel.getModeList)().then(function (res) {
      var allowMods = [1, 2, 4];
      _this.modeList = [];
      res.data.forEach(function (item) {
        if (allowMods.indexOf(item.value) >= 0) {
          _this.modeList.push(item);
        }
      });
    });
  },
  methods: {
    toOrder: function toOrder(row) {
      // this.$router.push(`/bill/finance_order?hotel_id=${row.hotel_id}&bill_week=${row.start_date}`);
      this.$router.push("/order/index?hotel_id=".concat(row.hotel_id, "&start_date=").concat(row.start_date, "&end_date=").concat(row.end_date));
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    resetForm: function resetForm() {
      this.listQuery.bill_week = "";
      this.listQuery.hotel_id = "";
      this.listQuery.status = "";
      this.listQuery.recharge_status = "";
      this.listQuery.area_id = "";
      this.listQuery.mode = "";
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    getList: function getList() {
      var _this2 = this;
      this.listLoading = true;
      (0, _bill.weekList)(this.listQuery).then(function (response) {
        var data = response.data;
        _this2.list = data.data;
        _this2.listLoading = false;
        _this2.pagination = data;
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      var self = this;
      self.multipleSelection = [];
      self.selected = val;
      self.select_num = 0;
      self.select_amount = 0;
      val.forEach(function (v) {
        self.select_num++;
        self.select_amount += v.hotel_amount;
        self.multipleSelection.push(v.id);
      });
      self.alert_title = '已选择 ' + self.select_num + ' 笔对账单   总计：' + self.select_amount;
      console.warn(this.multipleSelection);
    },
    transfer: function transfer() {
      var _this3 = this;
      var num = 0,
        amount = 0;
      if (this.multipleSelection.length < 1) {
        this.$message.error('请选择账单');
        return false;
      }
      this.selected.forEach(function (v) {
        if (v.status == 30) {
          num++;
          amount += v.hotel_amount;
        }
      });
      if (num < 1) {
        this.$message.error('请选择未转账的账单');
        return false;
      }
      this.$confirm("\u8D26\u5355\u6570\u91CF\uFF1A".concat(num, "\uFF1B\u603B\u91D1\u989D\uFF1A").concat(amount), '请您核对对账单数量和总金额', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _bill.transfer)({
          'id': _this3.multipleSelection
        }).then(function (response) {
          if (response.code === 0) {
            _this3.getList();
            _this3.$message.success(response.msg);
          } else {
            _this3.$message.error(response.msg);
          }
        });
      }).catch(function () {});
    },
    transferAll: function transferAll() {
      var _this4 = this;
      this.$confirm('确认筛选的账单全部转账', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _bill.transferAll)(_this4.listQuery).then(function (response) {
          if (response.code === 0) {
            _this4.getList();
            _this4.$message.success(response.msg);
          } else {
            _this4.$message.error(response.msg);
          }
        });
      }).catch(function () {});
    },
    recharge: function recharge() {
      var _this5 = this;
      if (this.multipleSelection.length < 1) {
        this.$message.error('请选择账单');
        return false;
      }
      this.$prompt('请输入备注信息', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function (value) {
        (0, _bill.recharge)({
          'id': _this5.multipleSelection,
          'remark': value.value
        }).then(function (response) {
          if (response.code === 0) {
            _this5.getList();
            _this5.$message.success(response.msg);
          } else {
            _this5.$message.error(response.msg);
          }
        });
      }).catch(function () {});
    },
    rechargeAll: function rechargeAll() {
      var _this6 = this;
      this.$prompt('请输入备注信息', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function (value) {
        _this6.listQuery.remark = value.value;
        (0, _bill.rechargeAll)(_this6.listQuery).then(function (response) {
          if (response.code === 0) {
            _this6.getList();
            _this6.$message.success(response.msg);
          } else {
            _this6.$message.error(response.msg);
          }
        });
      }).catch(function () {});
    },
    resetBlank: function resetBlank() {
      this.$refs.multipleTable.clearSelection();
    },
    download: function download() {
      window.open((0, _bill.getWeekBillDownloadUrl)(this.listQuery, (0, _auth.getToken)()));
    },
    exportBill: function exportBill() {
      if (this.multipleSelection.length < 1) {
        this.$message.error('请选择账单');
        return false;
      }
      var selectIds = this.multipleSelection.join(',');
      var params = JSON.parse(JSON.stringify(this.listQuery));
      params.id = selectIds;
      window.open((0, _bill.getWeekBillDownloadUrl)(params, (0, _auth.getToken)()));
    }
  }
};
exports.default = _default;