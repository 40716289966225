var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container calendar-list-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: { placeholder: "订单号" },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.handleFilter($event)
              },
            },
            model: {
              value: _vm.listQuery.order_no,
              callback: function ($$v) {
                _vm.$set(_vm.listQuery, "order_no", $$v)
              },
              expression: "listQuery.order_no",
            },
          }),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              attrs: { filterable: "", placeholder: "请选择" },
              model: {
                value: _vm.listQuery.hotel_id,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "hotel_id", $$v)
                },
                expression: "listQuery.hotel_id",
              },
            },
            [
              _c("el-option", { attrs: { value: "", label: "全部酒店" } }),
              _vm._v(" "),
              _vm._l(_vm.hotelList, function (item) {
                return _c("el-option", {
                  key: item.hotel_id,
                  attrs: { label: item.name, value: item.hotel_id },
                })
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "el-button-group",
            { staticClass: "filter-item" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleFilter } },
                [_vm._v("查询")]
              ),
              _vm._v(" "),
              _c("el-button", { on: { click: _vm.addHandlerByForm } }, [
                _vm._v("新增"),
              ]),
              _vm._v(" "),
              _c(
                "el-badge",
                { attrs: { "is-dot": _vm.hasFilter } },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-bottom": "1px" },
                      on: { click: _vm.filterFormHandler },
                    },
                    [_vm._v("\n          筛选\n        ")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-dropdown",
                {
                  staticStyle: { "border-left": "1px solid #dcdfe6" },
                  on: { command: _vm.handleCommand },
                },
                [
                  _c("el-button", { attrs: { icon: "el-icon-more" } }),
                  _vm._v(" "),
                  _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    [
                      _c(
                        "el-dropdown-item",
                        { attrs: { command: "importHandler" } },
                        [_vm._v("批量导入")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-dropdown-item",
                        { attrs: { command: "addHandlerByText" } },
                        [_vm._v("文本粘贴")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-dropdown-item",
                        { attrs: { command: "download" } },
                        [_vm._v("导出表格")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          key: "tableList",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            "element-loading-text": "给我一点时间",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "订单号", width: "180" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.order_no))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              "show-overflow-tooltip": true,
              label: "酒店房型",
              "min-width": "200",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [_vm._v(_vm._s(scope.row.hotel_name))]),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(scope.row.room_name))]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "入/离时间", width: "180" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm._f("dateFormat")(scope.row.start_date))
                      ),
                    ]),
                    _vm._v("/"),
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm._f("dateFormat")(scope.row.end_date)) +
                          " " +
                          _vm._s(scope.row.days) +
                          "晚"
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "来源渠道", width: "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.third_name))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "售卖方", width: "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.seller_name))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "实付金额", width: "90" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.getRealAmount(scope.row, "amount")) + "元"
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "飞火佣金", width: "90" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.getRealAmount(scope.row, "commission")) +
                          "元"
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "商家应收", width: "90" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.getRealAmount(scope.row, "store_amount")) +
                          "元"
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "顾客", width: "90" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.contacts))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "状态", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          scope.row.refunded_at
                            ? "已退款"
                            : _vm.statusMap[scope.row.status]
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "支付时间", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(scope.row.pay_at || scope.row.created_at)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              fixed: "right",
              align: "center",
              label: "操作",
              width: "180",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.order_type == "soft"
                      ? [
                          scope.row.status == 30
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleOtaOrderChangeStatus(
                                        scope.row,
                                        40
                                      )
                                    },
                                  },
                                },
                                [_vm._v("\n            已入住\n          ")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.canEdit(scope.row)
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.editHandlerByForm(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("\n            编辑\n          ")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.canDelete(scope.row)
                            ? _c(
                                "el-button",
                                {
                                  staticStyle: { color: "#F56C6C" },
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleDelete(scope.row.id)
                                    },
                                  },
                                },
                                [_vm._v("\n            删除\n          ")]
                              )
                            : _vm._e(),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.order_type == "yoka"
                      ? [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleDetail(scope.row.id)
                                },
                              },
                            },
                            [_vm._v("\n            查看\n          ")]
                          ),
                          _vm._v(" "),
                          scope.row.status == 30
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleOrderChangeStatus(
                                        scope.row.id,
                                        40
                                      )
                                    },
                                  },
                                },
                                [_vm._v("\n            已入住\n          ")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          scope.row.status == 25
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleOrderChangeStatus(
                                        scope.row.id,
                                        30
                                      )
                                    },
                                  },
                                },
                                [_vm._v("\n            确认\n          ")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          scope.row.status == 25
                            ? _c(
                                "el-button",
                                {
                                  staticStyle: { color: "#F56C6C" },
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleOrderChangeStatus(
                                        scope.row.id,
                                        27
                                      )
                                    },
                                  },
                                },
                                [_vm._v("\n            拒绝\n          ")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          scope.row.status == 30
                            ? _c(
                                "el-button",
                                {
                                  staticStyle: { color: "#E6A23C" },
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleCancel(scope.row.id)
                                    },
                                  },
                                },
                                [_vm._v("\n            取消\n          ")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          scope.row.status == 40
                            ? _c(
                                "el-button",
                                {
                                  staticStyle: { color: "#E6A23C" },
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleRefund(scope.row.id)
                                    },
                                  },
                                },
                                [_vm._v("\n            退款\n          ")]
                              )
                            : _vm._e(),
                        ]
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "pagination-container text-right" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              total: _vm.pagination.total,
              "current-page": _vm.listQuery.page,
              "page-sizes": [10, 20, 50],
              "page-size": _vm.listQuery.limit,
              layout: "total, sizes, prev, pager, next, jumper",
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogImportVisible,
            "close-on-click-modal": false,
            width: "65%",
            title: "导入订单",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogImportVisible = $event
            },
            close: _vm.importCloseHanlder,
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form",
                    {
                      staticClass: "demo-form-inline",
                      attrs: { inline: true, "label-suffix": ":" },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "来源渠道" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择" },
                              on: { change: _vm.importOtaChangeHandler },
                              model: {
                                value: _vm.importOta,
                                callback: function ($$v) {
                                  _vm.importOta = $$v
                                },
                                expression: "importOta",
                              },
                            },
                            _vm._l(_vm.otas, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.title, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "文件上传" } },
                        [
                          _c(
                            "el-upload",
                            {
                              staticStyle: { display: "inline-block" },
                              attrs: {
                                name: "file",
                                multiple: false,
                                "show-file-list": false,
                                headers: _vm.header,
                                action: _vm.uploadUrl,
                                "on-success": _vm.handleUploadSuccess,
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    slot: "trigger",
                                    size: "small",
                                    type: "primary",
                                  },
                                  slot: "trigger",
                                },
                                [_vm._v("添加")]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            { on: { click: _vm.downloadTemplate } },
                            [_vm._v("\n              下载模板\n            ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "soft-preview" },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.previewListLoading,
                          expression: "previewListLoading",
                        },
                      ],
                      staticStyle: { width: "100%" },
                      attrs: { data: _vm.previewList, border: "" },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "order_no",
                          label: "订单号",
                          width: "100",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { prop: "hotel_name", label: "酒店" },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { prop: "room_name", label: "房型" },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { prop: "amount", label: "金额", width: "80" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [
                                  _vm._v(_vm._s(scope.row.amount) + " 元 "),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "start_date",
                          label: "入住日期",
                          width: "100",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("previceDateFormat")(
                                        scope.row.start_date
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "end_date",
                          label: "离店日期",
                          width: "100",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("previceDateFormat")(
                                        scope.row.end_date
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "third_name",
                          label: "来源渠道",
                          width: "80",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "seller_name",
                          label: "售卖方",
                          width: "80",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "room_quantity",
                          label: "房间数",
                          width: "70",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { prop: "days", label: "间夜数", width: "70" },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "status",
                          label: "订单状态",
                          width: "80",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "import_at",
                          label: "同步时间",
                          width: "100",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          fixed: "right",
                          align: "center",
                          label: "操作",
                          width: "80",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "a",
                                  {
                                    staticStyle: { color: "#F56C6C" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handlePreviewDelete(
                                          scope.row.order_no
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                删除\n              "
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "pagination-container text-right" },
                    [
                      _c("el-pagination", {
                        attrs: {
                          background: "",
                          total: _vm.previewPagination.total,
                          "current-page": _vm.previewPagination.page,
                          "page-sizes": [10, 20, 50],
                          "page-size": _vm.previewPagination.limit,
                          layout: "total, sizes, prev, pager, next, jumper",
                        },
                        on: {
                          "size-change": _vm.handlePreviewSizeChange,
                          "current-change": _vm.handlePreviewCurrentChange,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticStyle: { "text-align": "center" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogImportVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.importDataHandler },
                },
                [_vm._v("确定导入")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogOrderByFormVisible,
            "close-on-click-modal": false,
            title: _vm.dialogOrderByFormTitle,
            width: "640px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogOrderByFormVisible = $event
            },
            close: _vm.orderFormCloseHanlder,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "orderForm",
              attrs: {
                model: _vm.orderForm,
                rules: _vm.orderFormRules,
                "label-suffix": ":",
                "label-width": "85px",
                "label-position": "right",
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "来源渠道",
                            prop: "third_id",
                            required: "",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: "请选择",
                                disabled: _vm.orderFormIsEdit(),
                              },
                              on: { change: _vm.orderFormThirdIdChangeHandler },
                              model: {
                                value: _vm.orderForm.third_id,
                                callback: function ($$v) {
                                  _vm.$set(_vm.orderForm, "third_id", $$v)
                                },
                                expression: "orderForm.third_id",
                              },
                            },
                            _vm._l(_vm.otas, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.title, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "售卖方",
                            prop: "seller_type",
                            required: "",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: "请选择",
                                disabled: _vm.orderForm.third_id == 5,
                              },
                              model: {
                                value: _vm.orderForm.seller_type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.orderForm, "seller_type", $$v)
                                },
                                expression: "orderForm.seller_type",
                              },
                            },
                            _vm._l(_vm.sellers, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.title, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "入住酒店",
                            prop: "hotel_id",
                            required: "",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                filterable: "",
                                placeholder: "请选择",
                                disabled: _vm.orderFormIsEdit(),
                              },
                              on: { change: _vm.orderFormHotelChangeHandler },
                              model: {
                                value: _vm.orderForm.hotel_id,
                                callback: function ($$v) {
                                  _vm.$set(_vm.orderForm, "hotel_id", $$v)
                                },
                                expression: "orderForm.hotel_id",
                              },
                            },
                            _vm._l(_vm.softHotelList, function (item) {
                              return _c("el-option", {
                                key: item.hotel_id,
                                attrs: {
                                  label: item.name,
                                  value: item.hotel_id,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "预订房型",
                            prop: "room_id",
                            required: "",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                filterable: "",
                                placeholder: "请选择",
                                disabled: _vm.orderFormIsEdit(),
                              },
                              model: {
                                value: _vm.orderForm.room_id,
                                callback: function ($$v) {
                                  _vm.$set(_vm.orderForm, "room_id", $$v)
                                },
                                expression: "orderForm.room_id",
                              },
                            },
                            _vm._l(_vm.orderFormRooms, function (item) {
                              return _c("el-option", {
                                key: item.room_id,
                                attrs: {
                                  label: item.name,
                                  value: item.room_id,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "入离日期",
                            prop: "dates",
                            required: "",
                          },
                        },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              clearable: false,
                              type: "daterange",
                              "range-separator": "-",
                              "start-placeholder": "入住日期",
                              "end-placeholder": "离店日期",
                              "picker-options": _vm.orderFormPickerOptions,
                            },
                            model: {
                              value: _vm.orderForm.dates,
                              callback: function ($$v) {
                                _vm.$set(_vm.orderForm, "dates", $$v)
                              },
                              expression: "orderForm.dates",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "订单编号",
                            prop: "order_no",
                            required: "",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入",
                              disabled: _vm.orderFormIsEdit(),
                            },
                            model: {
                              value: _vm.orderForm.order_no,
                              callback: function ($$v) {
                                _vm.$set(_vm.orderForm, "order_no", $$v)
                              },
                              expression: "orderForm.order_no",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "订单金额",
                            prop: "amount",
                            required: "",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入" },
                            model: {
                              value: _vm.orderForm.amount,
                              callback: function ($$v) {
                                _vm.$set(_vm.orderForm, "amount", $$v)
                              },
                              expression: "orderForm.amount",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "入住人",
                            prop: "contacts",
                            required: "",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入" },
                            model: {
                              value: _vm.orderForm.contacts,
                              callback: function ($$v) {
                                _vm.$set(_vm.orderForm, "contacts", $$v)
                              },
                              expression: "orderForm.contacts",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "手机号", prop: "mobile" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入",
                              disabled: _vm.orderFormIsEdit(),
                            },
                            model: {
                              value: _vm.orderForm.mobile,
                              callback: function ($$v) {
                                _vm.$set(_vm.orderForm, "mobile", $$v)
                              },
                              expression: "orderForm.mobile",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "房间数量",
                            prop: "room_quantity",
                            required: "",
                          },
                        },
                        [
                          _c("el-input-number", {
                            attrs: {
                              min: 1,
                              placeholder: "请输入",
                              disabled: _vm.orderFormIsEdit(),
                            },
                            model: {
                              value: _vm.orderForm.room_quantity,
                              callback: function ($$v) {
                                _vm.$set(_vm.orderForm, "room_quantity", $$v)
                              },
                              expression: "orderForm.room_quantity",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticStyle: { "text-align": "center" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogOrderByFormVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    loading: _vm.orderFormConfirmLoading,
                  },
                  on: { click: _vm.orderFormSubmitHandler },
                },
                [_vm._v("\n        确 定\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogAddByTextVisible,
            "close-on-click-modal": false,
            title: "添加订单",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogAddByTextVisible = $event
            },
            close: _vm.addTextCloseHanlder,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "addTextForm",
              attrs: {
                model: _vm.addTextForm,
                rules: _vm.addTextFormRules,
                "label-suffix": ":",
                "label-width": "85px",
                "label-position": "left",
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "来源渠道",
                            prop: "type",
                            required: "",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.addTextForm.type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.addTextForm, "type", $$v)
                                },
                                expression: "addTextForm.type",
                              },
                            },
                            _vm._l(_vm.otas, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.title, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "售卖方",
                            prop: "seller_type",
                            required: "",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.addTextForm.seller_type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.addTextForm, "seller_type", $$v)
                                },
                                expression: "addTextForm.seller_type",
                              },
                            },
                            _vm._l(_vm.sellers, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.title, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "订单信息", prop: "text", required: "" } },
                [
                  _c("el-input", {
                    staticStyle: { display: "inline-block" },
                    attrs: {
                      type: "textarea",
                      autosize: { minRows: 7, maxRows: 12 },
                      placeholder: "请输入订单信息",
                    },
                    model: {
                      value: _vm.addTextForm.text,
                      callback: function ($$v) {
                        _vm.$set(_vm.addTextForm, "text", $$v)
                      },
                      expression: "addTextForm.text",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "el-tooltip",
                    { attrs: { placement: "bottom" } },
                    [
                      _c(
                        "div",
                        { attrs: { slot: "content" }, slot: "content" },
                        [
                          _vm._v(
                            "\n            订单号： 4800000000000000000 (新订单/取消单) XXX酒店"
                          ),
                          _c("br"),
                          _vm._v("\n            入住人： 张三 等共1人"),
                          _c("br"),
                          _vm._v(
                            "\n            入离日期：2021-01-01~2021-01-02(共1晚)"
                          ),
                          _c("br"),
                          _vm._v("\n            预订房型：XXX房 共1 间"),
                          _c("br"),
                          _vm._v(
                            "\n            房费: 共计CNY188元\n          "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("el-button", { attrs: { type: "text" } }, [
                        _vm._v("订单信息示例"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticStyle: { "text-align": "center" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogAddByTextVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    loading: _vm.addTextConfirmLoading,
                  },
                  on: { click: _vm.addTextOrderHandler },
                },
                [_vm._v("\n        确 定\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogDetailVisible, title: "订单详情" },
          on: {
            "update:visible": function ($event) {
              _vm.dialogDetailVisible = $event
            },
          },
        },
        [_c("order-detail", { attrs: { id: _vm.orderId } })],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogfilterVisible,
            title: "订单筛选",
            width: "680px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogfilterVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "filterForm",
              attrs: {
                model: _vm.filterForm,
                "label-suffix": ":",
                "label-width": "85px",
                "label-position": "left",
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "所属区域", prop: "area_id" } },
                        [
                          _vm.areas.length > 0
                            ? _c(
                                "el-select",
                                {
                                  staticClass: "filter-item",
                                  attrs: {
                                    "default-first-option": "",
                                    placeholder: "请选择",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.filterForm.area_id,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.filterForm, "area_id", $$v)
                                    },
                                    expression: "filterForm.area_id",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { value: "", label: "全部" },
                                  }),
                                  _vm._v(" "),
                                  _vm._l(_vm.areas, function (item) {
                                    return _c("el-option", {
                                      key: item.role_id,
                                      attrs: {
                                        label: item.name,
                                        value: item.role_id,
                                      },
                                    })
                                  }),
                                ],
                                2
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "售卖方", prop: "seller_type" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "filter-item",
                              attrs: {
                                "default-first-option": "",
                                placeholder: "请选择",
                                clearable: "",
                              },
                              model: {
                                value: _vm.filterForm.seller_type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filterForm, "seller_type", $$v)
                                },
                                expression: "filterForm.seller_type",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { value: "", label: "全部" },
                              }),
                              _vm._v(" "),
                              _vm._l(_vm.sellers, function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: { label: item.title, value: item.id },
                                })
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "支付日期", prop: "pay_at" } },
                        [
                          _c("el-date-picker", {
                            staticClass: "filter-item",
                            attrs: {
                              type: "daterange",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "结束日期",
                              "value-format": "yyyyMMdd",
                              align: "right",
                            },
                            model: {
                              value: _vm.filterForm.pay_at,
                              callback: function ($$v) {
                                _vm.$set(_vm.filterForm, "pay_at", $$v)
                              },
                              expression: "filterForm.pay_at",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "入住时间", prop: "start_date" } },
                        [
                          _c("el-date-picker", {
                            staticClass: "filter-item",
                            attrs: {
                              type: "daterange",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "结束日期",
                              "value-format": "yyyyMMdd",
                              align: "right",
                            },
                            model: {
                              value: _vm.filterForm.start_date,
                              callback: function ($$v) {
                                _vm.$set(_vm.filterForm, "start_date", $$v)
                              },
                              expression: "filterForm.start_date",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "离店时间", prop: "end_date" } },
                        [
                          _c("el-date-picker", {
                            staticClass: "filter-item",
                            attrs: {
                              type: "daterange",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "结束日期",
                              "value-format": "yyyyMMdd",
                              align: "right",
                            },
                            model: {
                              value: _vm.filterForm.end_date,
                              callback: function ($$v) {
                                _vm.$set(_vm.filterForm, "end_date", $$v)
                              },
                              expression: "filterForm.end_date",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "订单状态", prop: "status" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "filter-item",
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.filterForm.status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filterForm, "status", $$v)
                                },
                                expression: "filterForm.status",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { value: "", label: "全部" },
                              }),
                              _vm._v(" "),
                              _vm._l(_vm.statusOptions, function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "代理商", prop: "agent_id" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "filter-item",
                              attrs: {
                                filterable: "",
                                clearable: "",
                                placeholder: "请选择",
                              },
                              model: {
                                value: _vm.filterForm.agent_id,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filterForm, "agent_id", $$v)
                                },
                                expression: "filterForm.agent_id",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { value: "", label: "全部" },
                              }),
                              _vm._v(" "),
                              _vm._l(_vm.agentList, function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: { label: item.name, value: item.id },
                                })
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "入住人" } },
                        [
                          _c("el-input", {
                            staticClass: "filter-item",
                            staticStyle: { width: "200px" },
                            attrs: { placeholder: "不限" },
                            model: {
                              value: _vm.filterForm.contacts,
                              callback: function ($$v) {
                                _vm.$set(_vm.filterForm, "contacts", $$v)
                              },
                              expression: "filterForm.contacts",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c("el-button", { on: { click: _vm.closeFilter } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                { staticClass: "filter-item", on: { click: _vm.resetFilter } },
                [_vm._v("\n        重置\n      ")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.onFilterSubmit },
                },
                [_vm._v("\n        筛 选\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("order-refund-dialog", {
        attrs: {
          visible: _vm.refundOrderVisible,
          "order-id": _vm.refundOrderId,
        },
        on: {
          "update:visible": function ($event) {
            _vm.refundOrderVisible = $event
          },
          succss: _vm.getList,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }