"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getSoftDayBillDownloadUrl = getSoftDayBillDownloadUrl;
exports.getSoftFinancialSettlementDownloadUrl = getSoftFinancialSettlementDownloadUrl;
exports.getSoftWeekBillDownloadUrl = getSoftWeekBillDownloadUrl;
exports.softDayList = softDayList;
exports.softFinancialSettlement = softFinancialSettlement;
exports.softHotelPaymentFlow = softHotelPaymentFlow;
exports.softTransfer = softTransfer;
exports.softTransferAll = softTransferAll;
exports.softTransferPreview = softTransferPreview;
exports.softWeekList = softWeekList;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
require("core-js/modules/es6.regexp.to-string");
var _request = _interopRequireDefault(require("@/utils/request"));
function softDayList(data) {
  return (0, _request.default)({
    url: 'softBills/day',
    method: 'get',
    params: data
  });
}
function getSoftDayBillDownloadUrl(day, token) {
  return process.env.VUE_APP_BASE_API + "/softBills/dayDownload?date=".concat(day, "&api_token=").concat(token);
}
function softWeekList(data) {
  return (0, _request.default)({
    url: "/softBills/week",
    method: 'get',
    params: data
  });
}
function softTransferPreview(data) {
  return (0, _request.default)({
    url: "/softBills/transferPreview",
    method: 'post',
    params: data
  });
}
function softHotelPaymentFlow(data) {
  return (0, _request.default)({
    url: "/softBills/hotelPaymentFlow",
    method: 'post',
    params: data
  });
}
function softTransfer(data) {
  return (0, _request.default)({
    url: "/softBills/transfer",
    method: 'post',
    params: data
  });
}
function softTransferAll(data) {
  return (0, _request.default)({
    url: "/softBills/transferAll",
    method: 'post',
    params: data
  });
}
function getSoftWeekBillDownloadUrl(params, token) {
  var isArray = function isArray(obj) {
    return Object.prototype.toString.call(obj) === '[object Array]';
  };
  var url = Object.keys(params).map(function (key) {
    if (isArray(params[key])) {
      var iUrl = '';
      params[key].forEach(function (item) {
        iUrl = iUrl + key + '[]=' + item + '&';
      });
      return iUrl.trim('&');
    } else {
      return encodeURIComponent(key) + "=" + encodeURIComponent(params[key]);
    }
  }).join("&");
  return process.env.VUE_APP_BASE_API + "/softBills/weekDownload?".concat(url, "&api_token=").concat(token);
}
function softFinancialSettlement(data) {
  return (0, _request.default)({
    url: 'softBills/financialSettlement',
    method: 'get',
    params: data
  });
}
function getSoftFinancialSettlementDownloadUrl(url, token) {
  return process.env.VUE_APP_BASE_API + "/softBills/financialSettlementDownload?".concat(url, "&api_token=").concat(token);
}