"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _point_shop = require("@/api/point_shop");
var _coupon = require("@/api/coupon");
var _hotel = require("@/api/hotel");
var _common = require("@/api/common");
var _auth = require("@/utils/auth");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "SharedgoodsForm",
  components: {},
  data: function data() {
    return {
      id: undefined,
      goodsLoading: false,
      coupons: [],
      categories: [],
      hotels: [],
      goodsForm: {
        type: 1,
        foreign_id: "",
        title: "",
        intro: "",
        categories: [],
        point: "",
        stock: "",
        price_line: "",
        thumbnail: "",
        images: [],
        detail_image: "",
        detail: "",
        is_recommend: 0,
        is_show: 1,
        pickup_hotel: 0,
        pickup_hotels: []
      },
      formRules: {
        'type': [{
          required: true,
          message: '请输入商品类别',
          trigger: 'blur'
        }],
        'foreign_id': [{
          required: true,
          message: '请选择优惠券',
          trigger: 'blur'
        }],
        'title': [{
          required: true,
          message: '请输入商品名称',
          trigger: 'blur'
        }],
        'point': [{
          required: true,
          message: '请输入消耗积分',
          trigger: 'blur'
        }, {
          type: 'number',
          min: 1,
          message: '消耗积分必须大于0',
          trigger: 'blur'
        }],
        'stock': [{
          required: true,
          message: '请输入商品库存',
          trigger: 'blur'
        }],
        'price_line': [{
          required: true,
          message: '请输入划线价',
          trigger: 'blur'
        }, {
          type: 'number',
          min: 1,
          message: '划线价必须大于0',
          trigger: 'blur'
        }],
        'thumbnail': [{
          required: true,
          message: '请上传缩略图',
          trigger: 'blur'
        }],
        'images': [{
          required: true,
          message: '请上传商品主图',
          trigger: 'blur'
        }],
        'is_recommend': [{
          required: true,
          message: '请选择设为推荐',
          trigger: 'blur'
        }],
        'is_show': [{
          required: true,
          message: '请选择状态',
          trigger: 'blur'
        }],
        'pickup_hotel': [{
          required: true,
          message: '请选择支持提货酒店',
          trigger: 'blur'
        }],
        'pickup_hotels': [{
          required: true,
          message: '请选择提货酒店',
          trigger: 'blur'
        }]
      },
      formSubmitLoading: false,
      uploadUrl: '',
      header: {
        Authorization: "Bearer " + (0, _auth.getToken)()
      },
      mainImageList: []
    };
  },
  created: function created() {
    this.uploadUrl = (0, _common.adminUpload)();
    this.id = this.$route.params.id;
    this.getCoupons();
    this.getCategories();
    this.getHotels();
    if (this.id) {
      this.getGoods();
    }
  },
  methods: {
    getCoupons: function getCoupons() {
      var _this = this;
      (0, _coupon.couponManualList)().then(function (response) {
        _this.coupons = response.data;
      });
    },
    typeChangeHandler: function typeChangeHandler(value) {
      this.goodsForm.foreign_id = "";
    },
    getCategories: function getCategories() {
      var _this2 = this;
      (0, _point_shop.fetchCatetorySimple)().then(function (response) {
        _this2.categories = response.data;
      });
    },
    getHotels: function getHotels() {
      var _this3 = this;
      (0, _hotel.getHotelSimpleList)().then(function (response) {
        _this3.hotels = response.data;
      });
    },
    getGoods: function getGoods() {
      var _this4 = this;
      this.goodsLoading = true;
      (0, _point_shop.fetchGoods)(this.id).then(function (response) {
        var goods = response.data;
        delete goods["id"];
        _this4.goodsForm = goods;
        goods["images"].forEach(function (image) {
          _this4.mainImageList.push({
            name: image.substring(image.lastIndexOf("/") + 1, image.length),
            url: image
          });
        });
      }).finally(function () {
        _this4.goodsLoading = false;
      });
    },
    handleThumbnailSuccess: function handleThumbnailSuccess(res) {
      var _this5 = this;
      this.handleImageUploadSuccess(res, function (result) {
        _this5.goodsForm.thumbnail = result.data.filepath;
        _this5.$refs['goodsThumbnail'].clearValidate();
      });
    },
    handleImagesRemove: function handleImagesRemove(file, fileList) {
      this.parseFileListToImages(fileList);
    },
    handleImagesSuccess: function handleImagesSuccess(res, file, fileList) {
      var _this6 = this;
      this.handleImageUploadSuccess(res, function (result) {
        _this6.parseFileListToImages(fileList);
        _this6.$refs['goodsImages'].clearValidate();
      });
    },
    parseFileListToImages: function parseFileListToImages(fileList) {
      var images = [];
      fileList.forEach(function (item) {
        var url = item.url;
        if (item.response != undefined) {
          url = item.response.data.filepath;
        }
        images.push(url);
      });
      this.goodsForm.images = images;
    },
    handleDetailImageSuccess: function handleDetailImageSuccess(res) {
      var _this7 = this;
      this.handleImageUploadSuccess(res, function (result) {
        _this7.goodsForm.detail_image = result.data.filepath;
        _this7.$refs['goodsDetailImage'].clearValidate();
      });
    },
    handleImageUploadSuccess: function handleImageUploadSuccess(res, callback) {
      if (res.code == 0) {
        this.$message.success("上传成功！");
        callback(res);
      } else {
        this.$message.error("上传失败");
      }
    },
    pickupHotelChangeHandler: function pickupHotelChangeHandler(vlaue) {
      if (vlaue == 0) {
        this.goodsForm.pickup_hotels = [];
      }
    },
    submitHandler: function submitHandler() {
      var _this8 = this;
      this.$refs['goodsForm'].validate(function (valid) {
        if (!valid) {
          return false;
        }
        if (_this8.id) {
          _this8.editHandler();
        } else {
          _this8.addHandler();
        }
      });
    },
    addHandler: function addHandler() {
      var _this9 = this;
      this.formSubmitLoading = true;
      (0, _point_shop.createGoods)(this.goodsForm).then(function () {
        _this9.$message.success("添加成功");
        _this9.formSubmitLoading = false;
        _this9.$router.push('/point_shop/goods');
      }).catch(function () {
        _this9.formSubmitLoading = false;
      });
    },
    editHandler: function editHandler() {
      var _this10 = this;
      this.formSubmitLoading = true;
      (0, _point_shop.updateGoods)(this.id, this.goodsForm).then(function () {
        _this10.$message.success("编辑成功");
        _this10.formSubmitLoading = false;
        _this10.$router.push('/point_shop/goods');
      }).catch(function () {
        _this10.formSubmitLoading = false;
      });
    },
    cancel: function cancel() {
      this.$router.push('/point_shop/goods');
    }
  }
};
exports.default = _default;