var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.goodsLoading,
          expression: "goodsLoading",
        },
      ],
      staticClass: "app-container point-goods-form-container",
    },
    [
      _c(
        "el-form",
        {
          ref: "goodsForm",
          attrs: {
            model: _vm.goodsForm,
            "label-position": "right",
            "label-width": "125px",
            "label-suffix": ":",
            rules: _vm.formRules,
          },
        },
        [
          _c(
            "div",
            { staticClass: "form-block" },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 20, md: 16, lg: 12, xl: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "商品类别",
                            required: "",
                            prop: "type",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择" },
                              on: { change: _vm.typeChangeHandler },
                              model: {
                                value: _vm.goodsForm.type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.goodsForm, "type", $$v)
                                },
                                expression: "goodsForm.type",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "实物", value: 1 },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "优惠券", value: 2 },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.goodsForm.type == 2
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "优惠券",
                                required: "",
                                prop: "foreign_id",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: "请选择",
                                    filterable: "",
                                  },
                                  model: {
                                    value: _vm.goodsForm.foreign_id,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.goodsForm, "foreign_id", $$v)
                                    },
                                    expression: "goodsForm.foreign_id",
                                  },
                                },
                                _vm._l(_vm.coupons, function (coupon) {
                                  return _c("el-option", {
                                    key: coupon.id,
                                    attrs: {
                                      label: coupon.name,
                                      value: coupon.id,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "商品名称",
                            required: "",
                            prop: "title",
                            maxlength: "20",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入商品的名称，限20汉字",
                            },
                            model: {
                              value: _vm.goodsForm.title,
                              callback: function ($$v) {
                                _vm.$set(_vm.goodsForm, "title", $$v)
                              },
                              expression: "goodsForm.title",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "副标题", prop: "intro" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入" },
                            model: {
                              value: _vm.goodsForm.intro,
                              callback: function ($$v) {
                                _vm.$set(_vm.goodsForm, "intro", $$v)
                              },
                              expression: "goodsForm.intro",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品分类", prop: "categories" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "380px" },
                              attrs: {
                                multiple: "",
                                filterable: "",
                                placeholder: "请选择",
                              },
                              model: {
                                value: _vm.goodsForm.categories,
                                callback: function ($$v) {
                                  _vm.$set(_vm.goodsForm, "categories", $$v)
                                },
                                expression: "goodsForm.categories",
                              },
                            },
                            _vm._l(_vm.categories, function (category) {
                              return _c("el-option", {
                                key: category.id,
                                attrs: {
                                  label: category.title,
                                  value: category.id,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "商品库存",
                            prop: "stock",
                            required: "",
                          },
                        },
                        [
                          _c("el-input-number", {
                            attrs: { min: 0, placeholder: "请输入" },
                            model: {
                              value: _vm.goodsForm.stock,
                              callback: function ($$v) {
                                _vm.$set(_vm.goodsForm, "stock", $$v)
                              },
                              expression: "goodsForm.stock",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "消耗积分",
                            prop: "point",
                            required: "",
                          },
                        },
                        [
                          _c("el-input-number", {
                            attrs: { min: 0, placeholder: "请输入" },
                            model: {
                              value: _vm.goodsForm.point,
                              callback: function ($$v) {
                                _vm.$set(_vm.goodsForm, "point", $$v)
                              },
                              expression: "goodsForm.point",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "划线价",
                            prop: "price_line",
                            required: "",
                          },
                        },
                        [
                          _c("el-input-number", {
                            attrs: { min: 0, placeholder: "请输入" },
                            model: {
                              value: _vm.goodsForm.price_line,
                              callback: function ($$v) {
                                _vm.$set(_vm.goodsForm, "price_line", $$v)
                              },
                              expression: "goodsForm.price_line",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          ref: "goodsThumbnail",
                          attrs: {
                            label: "缩略图",
                            required: "",
                            prop: "thumbnail",
                          },
                        },
                        [
                          _c(
                            "el-upload",
                            {
                              staticClass: "avatar-uploader",
                              attrs: {
                                action: _vm.uploadUrl,
                                headers: _vm.header,
                                "show-file-list": false,
                                multiple: false,
                                "on-success": _vm.handleThumbnailSuccess,
                              },
                            },
                            [
                              _vm.goodsForm.thumbnail
                                ? _c("img", {
                                    staticClass: "avatar",
                                    staticStyle: { "max-width": "500px" },
                                    attrs: { src: _vm.goodsForm.thumbnail },
                                  })
                                : _c("i", {
                                    staticClass:
                                      "el-icon-plus avatar-uploader-icon",
                                  }),
                            ]
                          ),
                          _vm._v(" "),
                          _c("span", { staticClass: "image-tips" }, [
                            _vm._v("建议尺寸：335*200"),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          ref: "goodsImages",
                          attrs: {
                            label: "商品主图",
                            required: "",
                            prop: "images",
                          },
                        },
                        [
                          _c(
                            "el-upload",
                            {
                              staticClass: "avatar-uploader",
                              attrs: {
                                action: _vm.uploadUrl,
                                headers: _vm.header,
                                "show-file-list": "",
                                "list-type": "picture-card",
                                "file-list": _vm.mainImageList,
                                "on-remove": _vm.handleImagesRemove,
                                "on-success": _vm.handleImagesSuccess,
                              },
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "el-icon-plus avatar-uploader-icon",
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c("span", { staticClass: "image-tips" }, [
                            _vm._v("建议尺寸：750*450"),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          ref: "goodsDetailImage",
                          attrs: { label: "详情图", prop: "detail_image" },
                        },
                        [
                          _c(
                            "el-upload",
                            {
                              staticClass: "avatar-uploader",
                              attrs: {
                                action: _vm.uploadUrl,
                                headers: _vm.header,
                                "show-file-list": false,
                                multiple: false,
                                "on-success": _vm.handleDetailImageSuccess,
                              },
                            },
                            [
                              _vm.goodsForm.detail_image
                                ? _c("img", {
                                    staticClass: "avatar",
                                    staticStyle: { "max-width": "500px" },
                                    attrs: { src: _vm.goodsForm.detail_image },
                                  })
                                : _c("i", {
                                    staticClass:
                                      "el-icon-plus avatar-uploader-icon",
                                  }),
                            ]
                          ),
                          _vm._v(" "),
                          _c("span", { staticClass: "image-tips" }, [
                            _vm._v("建议宽度：660，长度不限"),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "设为推荐",
                            required: "",
                            prop: "is_recommend",
                          },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.goodsForm.is_recommend,
                                callback: function ($$v) {
                                  _vm.$set(_vm.goodsForm, "is_recommend", $$v)
                                },
                                expression: "goodsForm.is_recommend",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 0 } }, [
                                _vm._v("否"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("是"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "状态",
                            required: "",
                            prop: "is_show",
                          },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.goodsForm.is_show,
                                callback: function ($$v) {
                                  _vm.$set(_vm.goodsForm, "is_show", $$v)
                                },
                                expression: "goodsForm.is_show",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("显示"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 0 } }, [
                                _vm._v("隐藏"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "支持提货酒店",
                            required: "",
                            prop: "pickup_hotel",
                          },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              on: { change: _vm.pickupHotelChangeHandler },
                              model: {
                                value: _vm.goodsForm.pickup_hotel,
                                callback: function ($$v) {
                                  _vm.$set(_vm.goodsForm, "pickup_hotel", $$v)
                                },
                                expression: "goodsForm.pickup_hotel",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 0 } }, [
                                _vm._v("全部"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("部分可提货"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 2 } }, [
                                _vm._v("部分不可提货"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.goodsForm.pickup_hotel
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "提货酒店",
                                required: "",
                                prop: "pickup_hotels",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "380px" },
                                  attrs: {
                                    multiple: "",
                                    filterable: "",
                                    placeholder: "请选择",
                                  },
                                  model: {
                                    value: _vm.goodsForm.pickup_hotels,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.goodsForm,
                                        "pickup_hotels",
                                        $$v
                                      )
                                    },
                                    expression: "goodsForm.pickup_hotels",
                                  },
                                },
                                _vm._l(_vm.hotels, function (hotel) {
                                  return _c("el-option", {
                                    key: hotel.hotel_id,
                                    attrs: {
                                      label: hotel.name,
                                      value: hotel.hotel_id,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "text-center ml" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.formSubmitLoading },
              on: { click: _vm.submitHandler },
            },
            [_vm._v("\n      提交\n    ")]
          ),
          _vm._v(" "),
          _c("el-button", { on: { click: _vm.cancel } }, [
            _vm._v("\n      取 消\n    "),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }