"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("@/api/net_bar");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "NetBarDevicePriceFormAllNgiht",
  components: {},
  props: {
    value: {
      type: Array
    }
  },
  data: function data() {
    return {
      prices: [],
      formDialogVisible: false,
      formLoading: false,
      submitLoading: false,
      updateIdx: undefined,
      dataForm: {
        id: undefined,
        eff_day_type: 1,
        start_time: "",
        end_time: "",
        pack_price: undefined
      },
      dataFormRules: {
        eff_day_type: [{
          required: true,
          message: '请选择应用日期',
          trigger: 'blur'
        }],
        start_time: [{
          required: true,
          message: '请选择生效起始时间',
          trigger: 'blur'
        }],
        end_time: [{
          required: true,
          message: '请选择生效结束时间',
          trigger: 'blur'
        }],
        pack_price: [{
          required: true,
          message: '请输入价格',
          trigger: 'blur'
        }, {
          type: "number",
          min: 0.01,
          max: 1000000,
          message: '通宵价格不能小于0',
          trigger: 'blur'
        }]
      }
    };
  },
  watch: {
    value: function value(newVal) {
      this.prices = JSON.parse(JSON.stringify(newVal));
    }
  },
  created: function created() {},
  methods: {
    addHandler: function addHandler() {
      this.updateIdx = undefined;
      this.dataForm.id = undefined;
      this.formDialogVisible = true;
    },
    editHandler: function editHandler($index) {
      var _this = this;
      this.updateIdx = $index;
      this.formDialogVisible = true;
      this.$nextTick(function () {
        _this.dataForm = JSON.parse(JSON.stringify(_this.prices[$index]));
      });
    },
    closeHanlder: function closeHanlder() {
      this.dataForm.id = undefined;
      this.$refs['dataForm'].resetFields();
    },
    submitHandler: function submitHandler() {
      var _this2 = this;
      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          if (_this2.updateIdx != undefined) {
            _this2.doUpadte();
          } else {
            _this2.doCreate();
          }
        }
      });
    },
    doCreate: function doCreate() {
      this.submitLoading = true;
      this.prices.push(this.dataForm);
      this.$emit("input", JSON.parse(JSON.stringify(this.prices)));
      this.submitLoading = false;
      this.formDialogVisible = false;
    },
    doUpadte: function doUpadte() {
      this.submitLoading = true;
      this.prices[this.updateIdx] = this.dataForm;
      this.$emit("input", JSON.parse(JSON.stringify(this.prices)));
      this.submitLoading = false;
      this.formDialogVisible = false;
    },
    removeHandler: function removeHandler(index) {
      var _this3 = this;
      this.$confirm('<span style="color: #F56C6C;">一旦删除，对应通宵设置将失效</span>', '确定要删除该时段吗？', {
        dangerouslyUseHTMLString: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this3.prices.splice(index, 1);
        _this3.$emit("input", JSON.parse(JSON.stringify(_this3.prices)));
      }).catch(function () {});
    }
  }
};
exports.default = _default;