"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _hotel = require("@/api/hotel");
var _room = require("@/api/room");
var _activity = require("@/api/activity");
var _auth = require("@/utils/auth");
var _coupon = require("@/api/coupon");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "activity_seckill",
  data: function data() {
    return {
      areas: [],
      list: [],
      header: {
        Authorization: "Bearer " + (0, _auth.getToken)()
      },
      btn: false,
      dialogFormVisible: false,
      listQuery: {
        page: 1,
        limit: 20,
        area_id: "",
        hotel_id: ""
      },
      temp: {
        banner: "http://zlfz-hotel.oss-cn-hangzhou.aliyuncs.com/activity/seckill2.png",
        streamer: "http://zlfz-hotel.oss-cn-hangzhou.aliyuncs.com/activity/streamer.png",
        detail: [{
          start_time: '',
          end_time: '',
          coupons: [{
            type: 1,
            coupon_id: '',
            price: '',
            origin_price: '',
            desc: '',
            stock_num: '',
            list: this.couponList
          }]
        }]
      },
      couponList: [],
      groupCouponList: [],
      pagination: "",
      dialogTitle: "添加",
      disabledText: false,
      hotelList: [],
      roomList: [],
      typeList: [{
        "label": "普通优惠券",
        "val": 1
      }, {
        "label": "组合优惠券",
        "val": 2
      }]
    };
  },
  created: function created() {
    var _this = this;
    this.getList();
    // var roles = getRoles();
    if ((0, _auth.getGrade)() < 1) {
      (0, _hotel.getArea)().then(function (response) {
        _this.areas = response.data;
      });
    }
    (0, _hotel.getHotelSimpleList)().then(function (response) {
      _this.hotelList = response.data;
    });
    (0, _coupon.groupSimpleList)().then(function (response) {
      _this.groupCouponList = response.data;
    });
    (0, _coupon.couponManualList)().then(function (response) {
      _this.couponList = response.data;
    });
  },
  methods: {
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleDetailRedirect: function handleDetailRedirect(id) {
      this.$router.push({
        path: "/hotel/detail/" + id
      });
    },
    handleRoomRedirect: function handleRoomRedirect(id) {
      this.$router.push({
        path: "/hotel/room/" + id
      });
    },
    handleFormRedirect: function handleFormRedirect(id, action) {
      if (action == "create") {
        this.$router.push({
          path: "/hotel/add/"
        });
      } else {
        this.$router.push({
          path: "/hotel/edit/" + id
        });
      }
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    changeRow: function changeRow(row) {
      if (row.type == 1) {
        row.list = this.couponList;
      } else {
        row.list = this.groupCouponList;
      }
    },
    getList: function getList() {
      var _this2 = this;
      this.listLoading = true;
      (0, _activity.getSecKill)(this.listQuery).then(function (response) {
        var data = response.data;
        _this2.list = data.data;
        _this2.pagination = data;
        _this2.listQuery.page = data.current_page;
        _this2.listLoading = false;
      });
    },
    copySeckill: function copySeckill(row) {
      var _this3 = this;
      this.dialogTitle = '添加';
      var that = this;
      (0, _activity.secKillInfo)(row.id).then(function (res) {
        res.data.detail.forEach(function (val) {
          val.coupons.forEach(function (v) {
            if (v.type == 1) {
              v.list = that.couponList;
            } else {
              v.list = that.groupCouponList;
            }
          });
        });
        console.log(res.data.detail);
        _this3.temp = res.data;
        _this3.dialogFormVisible = true;
      });
    },
    addBtn: function addBtn() {
      this.temp = {
        banner: "http://zlfz-hotel.oss-cn-hangzhou.aliyuncs.com/activity/seckill2.png",
        streamer: "http://zlfz-hotel.oss-cn-hangzhou.aliyuncs.com/activity/streamer.png",
        detail: [{
          start_time: '',
          end_time: '',
          coupons: [{
            type: 1,
            coupon_id: '',
            price: '',
            origin_price: '',
            desc: '',
            stock_num: '',
            list: this.couponList
          }]
        }]
      };
      this.dialogTitle = '添加', this.dialogFormVisible = true;
    },
    removeDomain: function removeDomain(item) {
      var index = this.temp.detail.indexOf(item);
      if (index !== -1) {
        this.temp.detail.splice(index, 1);
      }
    },
    addDomain: function addDomain() {
      this.temp.detail.push({
        start_time: '',
        end_time: '',
        coupons: [{
          coupon_id: '',
          price: '',
          origin_price: '',
          desc: '',
          stock_num: '',
          type: 1,
          list: this.couponList
        }]
      });
    },
    removeCoupon: function removeCoupon(item, detail) {
      var index = detail.indexOf(item);
      if (index !== -1) {
        detail.splice(index, 1);
      }
    },
    addCoupon: function addCoupon(item) {
      item.push({
        coupon_id: '',
        price: '',
        origin_price: '',
        desc: '',
        stock_num: '',
        type: 1,
        list: this.couponList
      });
    },
    doConfirm: function doConfirm() {
      var _this4 = this;
      var that = this;
      (0, _activity.checkAreaSecKill)(that.temp.area_id, {
        id: that.temp.id,
        date: that.temp.date
      }).then(function (res) {
        if (res.data.repetend) {
          _this4.$confirm('时间段内有其他活动，确认后将自动下架之前保存的秒杀活动，确认操作?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(function () {
            if (that.dialogTitle == '添加') {
              that.addSecKill();
            } else {
              that.doEdit();
            }
          }).catch(function () {
            return;
          });
        } else if (that.dialogTitle == '添加') {
          that.addSecKill();
        } else {
          that.doEdit();
        }
      });
    },
    addSecKill: function addSecKill() {
      var _this5 = this;
      (0, _activity.addSecKill)(this.temp).then(function (res) {
        if (res.code == 0) {
          _this5.getList();
          _this5.dialogFormVisible = false;
          _this5.$message.success(res.msg);
        } else {
          _this5.$message.error(res.msg);
        }
      });
    },
    doEdit: function doEdit() {
      var _this6 = this;
      (0, _activity.editSecKill)(this.temp.id, this.temp).then(function (res) {
        if (res.code == 0) {
          _this6.getList();
          _this6.dialogFormVisible = false;
          _this6.$message.success(res.msg);
        } else {
          _this6.$message.error(res.msg);
        }
      });
    },
    downShelf: function downShelf(row) {
      var _this7 = this;
      this.$confirm('确认下架操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _activity.downSecKill)(row.id).then(function (res) {
          if (res.code == 0) {
            _this7.getList();
            _this7.$message.success(res.msg);
          } else {
            _this7.$message.error(res.msg);
          }
        });
      }).catch(function () {
        return;
      });
    },
    handleStreamerSuccess: function handleStreamerSuccess(res) {
      if (res.code == 0) {
        this.temp.streamer = res.data.filepath;
        this.$message.success("上传成功！");
      } else {
        this.$message.error("上传失败");
      }
    },
    handleImageSuccess: function handleImageSuccess(res) {
      if (res.code == 0) {
        this.temp.banner = res.data.filepath;
        this.$message.success("上传成功！");
      } else {
        this.$message.error("上传失败");
      }
    },
    changeHotel: function changeHotel() {
      var _this8 = this;
      (0, _room.simpleList)(this.temp.hotel_id).then(function (res) {
        _this8.roomList = res.data;
      });
    },
    editBtn: function editBtn(row) {
      var _this9 = this;
      this.dialogTitle = '编辑';
      var that = this;
      (0, _activity.secKillInfo)(row.id).then(function (res) {
        res.data.detail.forEach(function (val) {
          val.coupons.forEach(function (v) {
            if (v.type == 1) {
              v.list = that.couponList;
            } else {
              v.list = that.groupCouponList;
            }
          });
        });
        console.log(res.data.detail);
        _this9.temp = res.data;
        _this9.dialogFormVisible = true;
      });
    }
  }
};
exports.default = _default;