"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BannerList = BannerList;
exports.PopupList = PopupList;
exports.addBanner = addBanner;
exports.addPopup = addPopup;
exports.deleteBanner = deleteBanner;
exports.deletePopup = deletePopup;
exports.showBanner = showBanner;
exports.showPopup = showPopup;
exports.updateBanner = updateBanner;
exports.updatePopup = updatePopup;
var _request = _interopRequireDefault(require("@/utils/request"));
function BannerList(query) {
  return (0, _request.default)({
    url: '/bannerList',
    method: 'get',
    params: query
  });
}
function addBanner(query) {
  var q = JSON.parse(JSON.stringify(query));
  q.start_time = new Date(q.start_time).getTime();
  q.over_time = new Date(q.over_time).getTime();
  return (0, _request.default)({
    url: '/banner',
    method: 'post',
    params: q
  });
}
function updateBanner(query) {
  var q = JSON.parse(JSON.stringify(query));
  q.start_time = new Date(q.start_time).getTime();
  q.over_time = new Date(q.over_time).getTime();
  return (0, _request.default)({
    url: '/banner',
    method: 'put',
    params: q
  });
}
function deleteBanner(query) {
  return (0, _request.default)({
    url: '/banner',
    method: 'delete',
    params: query
  });
}
function showBanner(query) {
  return (0, _request.default)({
    url: '/showBanner',
    method: 'put',
    params: query
  });
}
function PopupList(query) {
  return (0, _request.default)({
    url: '/popupList',
    method: 'get',
    params: query
  });
}
function addPopup(q) {
  return (0, _request.default)({
    url: '/popups',
    method: 'post',
    data: q
  });
}
function updatePopup(id, data) {
  return (0, _request.default)({
    url: "/popups/".concat(id),
    method: 'put',
    data: data
  });
}
function deletePopup(id) {
  return (0, _request.default)({
    url: "/popups/".concat(id),
    method: 'delete'
  });
}
function showPopup(id) {
  return (0, _request.default)({
    url: "/showPopup/".concat(id),
    method: 'put'
  });
}