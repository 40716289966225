var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container calendar-list-container" },
    [
      _c(
        "el-header",
        { staticClass: "store-card" },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 24 } }, [
                _c("div", { staticClass: "store-card-header" }, [
                  _c("div", { staticClass: "store-card-header-title" }, [
                    _vm._v(_vm._s(_vm.store.name)),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.editStoreHandler },
                        },
                        [_vm._v("编辑")]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "el-col",
                { staticClass: "store-card-info", attrs: { span: 18 } },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        {
                          staticStyle: { "max-width": "380px" },
                          attrs: { span: 7 },
                        },
                        [
                          _c("div", { staticClass: "info-item" }, [
                            _c("div", { staticClass: "item-label" }, [
                              _vm._v("累计营收："),
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    color: "#67C23A",
                                    "font-size": "16px",
                                  },
                                },
                                [_vm._v(_vm._s(_vm.store.total_revenue || 0))]
                              ),
                              _vm._v("元"),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "info-item" }, [
                            _c("div", { staticClass: "item-label" }, [
                              _vm._v("计费方式："),
                            ]),
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  _vm.store.info.charge_mode == 2
                                    ? "阶梯收费"
                                    : "固定价格"
                                )
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "info-item" }, [
                            _c("div", { staticClass: "item-label" }, [
                              _vm._v("账户名："),
                            ]),
                            _c("div", [
                              _vm._v(_vm._s(_vm.store.finance.account)),
                            ]),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        {
                          staticStyle: { "max-width": "380px" },
                          attrs: { span: 7 },
                        },
                        [
                          _c("div", { staticClass: "info-item" }, [
                            _c("div", { staticClass: "item-label" }, [
                              _vm._v("联系人："),
                            ]),
                            _c("div", [_vm._v(_vm._s(_vm.store.contacts))]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "info-item" }, [
                            _c("div", { staticClass: "item-label" }, [
                              _vm._v("门店地址："),
                            ]),
                            _c("div", [_vm._v(_vm._s(_vm.store.address))]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "info-item" }, [
                            _c("div", { staticClass: "item-label" }, [
                              _vm._v("开户行："),
                            ]),
                            _c("div", [_vm._v(_vm._s(_vm.store.finance.bank))]),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        {
                          staticStyle: { "max-width": "380px" },
                          attrs: { span: 10 },
                        },
                        [
                          _c("div", { staticClass: "info-item" }, [
                            _c("div", { staticClass: "item-label" }, [
                              _vm._v("联系方式："),
                            ]),
                            _c("div", [_vm._v(_vm._s(_vm.store.tel))]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "info-item" }, [
                            _c("div", { staticClass: "item-label" }, [
                              _vm._v("创建时间："),
                            ]),
                            _c("div", [_vm._v(_vm._s(_vm.store.created_at))]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "info-item" }, [
                            _c("div", { staticClass: "item-label" }, [
                              _vm._v("收款账号："),
                            ]),
                            _c("div", [
                              _vm._v(_vm._s(_vm.store.finance.bank_account)),
                            ]),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { staticClass: "store-card-status", attrs: { span: 6 } },
                [
                  _c("div", { staticClass: "status-title" }, [_vm._v("状态")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "status-value" },
                    [
                      _vm.store.status != undefined
                        ? [
                            _vm.store.status == 1
                              ? _c("span", [_vm._v("正常")])
                              : _c(
                                  "span",
                                  { staticStyle: { color: "#F56C6C" } },
                                  [_vm._v("锁定")]
                                ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-tabs",
        {
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "设备列表", name: "device" } },
            [
              _c(
                "div",
                {
                  staticClass: "filter-container",
                  staticStyle: { "text-align": "right", "margin-top": "15px" },
                },
                [
                  _c("el-input", {
                    staticClass: "filter-item",
                    staticStyle: { width: "200px" },
                    attrs: { placeholder: "设备编号" },
                    model: {
                      value: _vm.listQuery.code,
                      callback: function ($$v) {
                        _vm.$set(_vm.listQuery, "code", $$v)
                      },
                      expression: "listQuery.code",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticClass: "filter-item",
                      staticStyle: { "margin-left": "10px" },
                      attrs: { type: "primary" },
                      on: { click: _vm.handleFilter },
                    },
                    [_vm._v("\n          搜索\n        ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticClass: "filter-item",
                      staticStyle: { "margin-left": "10px" },
                      on: { click: _vm.addDeviceHandler },
                    },
                    [_vm._v("\n          添加\n        ")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  key: "tableList",
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.list,
                    "element-loading-text": "给我一点时间",
                    border: "",
                    fit: "",
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "设备编号",
                      "min-width": "100",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [_c("span", [_vm._v(_vm._s(scope.row.code))])]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "计费规则",
                      "class-name": "overflow-on",
                      width: "120",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.orders.length
                              ? _c("span", [
                                  scope.row.orders[0].charge_mode == 1
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            scope.row.orders[0].charge_price
                                          ) + "元/小时"
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  scope.row.orders[0].charge_mode == 2
                                    ? _c("span", [_vm._v("阶梯收费")])
                                    : _vm._e(),
                                ])
                              : _c("span", [
                                  _vm.store.info.charge_mode == 1
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.store.info.charge_price) +
                                            "元/小时"
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.store.info.charge_mode == 2
                                    ? _c("span", [_vm._v("阶梯收费")])
                                    : _vm._e(),
                                ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      align: "left",
                      label: "最近上机",
                      "class-name": "overflow-on",
                      "min-width": "300",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.orders.length
                              ? _c("span", [
                                  scope.row.used
                                    ? _c(
                                        "span",
                                        { staticStyle: { color: "#67C23A" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              scope.row.orders[0].started_at
                                            ) + " ~ 现在"
                                          ),
                                        ]
                                      )
                                    : _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            scope.row.orders[0].started_at
                                          ) +
                                            " ~ " +
                                            _vm._s(scope.row.orders[0].ended_at)
                                        ),
                                      ]),
                                ])
                              : _c("span", [_vm._v("--")]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "累计使用时长",
                      "class-name": "overflow-on",
                      "min-width": "120",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.orders.length
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.getUseTime(scope.row.orders[0]))
                                  ),
                                ])
                              : _c("span", [_vm._v("--")]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "金额",
                      "class-name": "overflow-on",
                      "min-width": "100",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.orders.length
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(scope.row.orders[0].amount) + "元"
                                  ),
                                ])
                              : _c("span", [_vm._v("--")]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "设备状态",
                      "min-width": "100",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.used == 1
                              ? _c("span", [
                                  _c("div", { staticClass: "dot-success" }),
                                  _vm._v("使用中"),
                                ])
                              : _c("span", [
                                  _c("div", { staticClass: "dot-danger" }),
                                  _vm._v("闲置中"),
                                ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      fixed: "right",
                      label: "操作",
                      width: "160",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.editDeviceHandler(scope.row)
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.showOrdersHandler(scope.row.id)
                                  },
                                },
                              },
                              [_vm._v("查看")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteDeviceHandler(scope.row.id)
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "pagination-container text-right" },
                [
                  _c("el-pagination", {
                    attrs: {
                      background: "",
                      total: _vm.pagination.total,
                      "current-page": _vm.listQuery.page,
                      "page-sizes": [10, 20, 50],
                      "page-size": _vm.listQuery.limit,
                      layout: "total, sizes, prev, pager, next, jumper",
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.handleCurrentChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.addDeviceFormDialogVisible,
            title: "添加设备",
            width: "580px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.addDeviceFormDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "addDeviceForm",
              attrs: {
                model: _vm.addDeviceForm,
                "label-suffix": ":",
                "label-width": "100px",
                "label-position": "right",
                inline: "",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "设备编号",
                    prop: "devices.0.code",
                    rules: {
                      required: true,
                      message: "设备编号不能为空",
                      trigger: "blur",
                    },
                  },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "270px", "margin-right": "15px" },
                    attrs: { placeholder: "请保证与无盘系统内设备ID一致" },
                    model: {
                      value: _vm.addDeviceForm.devices[0].code,
                      callback: function ($$v) {
                        _vm.$set(_vm.addDeviceForm.devices[0], "code", $$v)
                      },
                      expression: "addDeviceForm.devices[0].code",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.addDeviceItem()
                        },
                      },
                    },
                    [_vm._v("添加")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm._l(_vm.addDeviceForm.devices, function (device, index) {
                return [
                  index != 0
                    ? _c(
                        "el-form-item",
                        {
                          key: index,
                          attrs: {
                            label: "设备编号",
                            prop: "devices." + index + ".code",
                            rules: {
                              required: true,
                              message: "设备编号不能为空",
                              trigger: "blur",
                            },
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: {
                              width: "270px",
                              "margin-right": "15px",
                            },
                            attrs: {
                              placeholder: "请保证与无盘系统内设备ID一致",
                            },
                            model: {
                              value: _vm.addDeviceForm.devices[index].code,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.addDeviceForm.devices[index],
                                  "code",
                                  $$v
                                )
                              },
                              expression: "addDeviceForm.devices[index].code",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "warning" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.removeDeviceItem(index)
                                },
                              },
                            },
                            [_vm._v("移除")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.addDeviceFormDialogVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    loading: _vm.addDeviceFormSubmitLoading,
                  },
                  on: { click: _vm.addDeviceFormSubmitHandler },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.editDeviceFormDialogVisible,
            title: "编辑设备",
            width: "580px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.editDeviceFormDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "editDeviceForm",
              attrs: {
                model: _vm.editDeviceForm,
                "label-suffix": ":",
                "label-width": "100px",
                "label-position": "right",
                inline: "",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "设备编号",
                    prop: "code",
                    rules: {
                      required: true,
                      message: "设备编号不能为空",
                      trigger: "blur",
                    },
                  },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "270px", "margin-right": "15px" },
                    attrs: { placeholder: "请保证与无盘系统内设备ID一致" },
                    model: {
                      value: _vm.editDeviceForm.code,
                      callback: function ($$v) {
                        _vm.$set(_vm.editDeviceForm, "code", $$v)
                      },
                      expression: "editDeviceForm.code",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.editDeviceFormDialogVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    loading: _vm.editDeviceFormSubmitLoading,
                  },
                  on: { click: _vm.editDeviceFormSubmitHandler },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.deviceOrdersDialogVisible,
            title: "使用记录",
            width: "700px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.deviceOrdersDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-row",
            {},
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.orderListLoading,
                          expression: "orderListLoading",
                        },
                      ],
                      staticStyle: { width: "100%" },
                      attrs: { data: _vm.orderList, border: "" },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          align: "left",
                          label: "上机时间",
                          "class-name": "overflow-on",
                          "min-width": "300",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.status == 30
                                  ? _c(
                                      "span",
                                      { staticStyle: { color: "#67C23A" } },
                                      [
                                        _vm._v(
                                          _vm._s(scope.row.started_at) +
                                            " ~ 现在"
                                        ),
                                      ]
                                    )
                                  : _c("span", [
                                      _vm._v(
                                        _vm._s(scope.row.started_at) +
                                          " ~ " +
                                          _vm._s(scope.row.ended_at)
                                      ),
                                    ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "累计时长",
                          "class-name": "overflow-on",
                          "min-width": "120",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.getUseTime(scope.row))),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "金额",
                          "class-name": "overflow-on",
                          "min-width": "100",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [
                                  _vm._v(_vm._s(scope.row.amount) + "元"),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "pagination-container text-right" },
                    [
                      _c("el-pagination", {
                        attrs: {
                          background: "",
                          total: _vm.orderPagination.total,
                          "current-page": _vm.orderPagination.page,
                          "page-sizes": [10, 20, 50],
                          "page-size": _vm.orderPagination.limit,
                          layout: "total, sizes, prev, pager, next, jumper",
                        },
                        on: {
                          "size-change": _vm.handleOrderSizeChange,
                          "current-change": _vm.handleOrderCurrentChange,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.deviceOrdersDialogVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.deviceOrdersDialogVisible = false
                    },
                  },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }