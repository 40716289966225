"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: "ImagePreview",
  props: {
    title: {
      type: String,
      default: function _default() {
        return "预览";
      }
    },
    images: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    visible: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    }
  },
  watch: {
    images: function images(value) {
      this.previewImages = value;
    },
    visible: function visible() {
      this.dialogVisible = this.visible;
    }
  },
  data: function data() {
    return {
      dialogVisible: false
    };
  },
  created: function created() {
    this.previewImages = this.images;
  },
  methods: {
    closeHanlder: function closeHanlder() {
      this.$emit('update:visible', false);
    }
  }
};
exports.default = _default2;