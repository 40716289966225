"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createFinanceBillCost = createFinanceBillCost;
exports.createO2oCoupon = createO2oCoupon;
exports.createStore = createStore;
exports.createStoreDevice = createStoreDevice;
exports.createStoreDevicePrice = createStoreDevicePrice;
exports.createStoreRecharge = createStoreRecharge;
exports.deleteFinanceBillCost = deleteFinanceBillCost;
exports.deleteStoreDevice = deleteStoreDevice;
exports.fetchFinanceAbnormalOrderList = fetchFinanceAbnormalOrderList;
exports.fetchFinanceBill = fetchFinanceBill;
exports.fetchFinanceBillCost = fetchFinanceBillCost;
exports.fetchFinanceBillCostList = fetchFinanceBillCostList;
exports.fetchFinanceBillList = fetchFinanceBillList;
exports.fetchFinanceOrderList = fetchFinanceOrderList;
exports.fetchMemberList = fetchMemberList;
exports.fetchO2oCoupon = fetchO2oCoupon;
exports.fetchO2oCouponList = fetchO2oCouponList;
exports.fetchO2oCouponWriteOffList = fetchO2oCouponWriteOffList;
exports.fetchO2oList = fetchO2oList;
exports.fetchOrder = fetchOrder;
exports.fetchOrderList = fetchOrderList;
exports.fetchOrderMigrateList = fetchOrderMigrateList;
exports.fetchRechargeOrder = fetchRechargeOrder;
exports.fetchRechargeOrderList = fetchRechargeOrderList;
exports.fetchStore = fetchStore;
exports.fetchStoreDevice = fetchStoreDevice;
exports.fetchStoreDeviceList = fetchStoreDeviceList;
exports.fetchStoreDevicePrice = fetchStoreDevicePrice;
exports.fetchStoreDevicePriceList = fetchStoreDevicePriceList;
exports.fetchStoreDevicePriceLog = fetchStoreDevicePriceLog;
exports.fetchStoreDevicePriceLogList = fetchStoreDevicePriceLogList;
exports.fetchStoreDevicePricePackList = fetchStoreDevicePricePackList;
exports.fetchStoreDevicePriceSimpleList = fetchStoreDevicePriceSimpleList;
exports.fetchStoreList = fetchStoreList;
exports.fetchStoreRecharge = fetchStoreRecharge;
exports.fetchStoreRechargeList = fetchStoreRechargeList;
exports.fetchStoreStatsDevicePerUsedTimeChart = fetchStoreStatsDevicePerUsedTimeChart;
exports.fetchStoreStatsUsedTimeChart = fetchStoreStatsUsedTimeChart;
exports.fetchStoreStatsUserPerUsedTimeChart = fetchStoreStatsUserPerUsedTimeChart;
exports.financeBillStatusBatchFinanceConfirm = financeBillStatusBatchFinanceConfirm;
exports.financeBillStatusComplete = financeBillStatusComplete;
exports.financeBillStatusFinanceConfirm = financeBillStatusFinanceConfirm;
exports.financeImportThridOrders = financeImportThridOrders;
exports.financeOrderReview = financeOrderReview;
exports.getChargeModeList = getChargeModeList;
exports.getDeviceConfigurationList = getDeviceConfigurationList;
exports.getFinanceAbnormalOrderDownloadUrl = getFinanceAbnormalOrderDownloadUrl;
exports.getFinanceBillCostDownloadUrl = getFinanceBillCostDownloadUrl;
exports.getFinanceBillCostFeeTypeList = getFinanceBillCostFeeTypeList;
exports.getFinanceBillDownloadUrl = getFinanceBillDownloadUrl;
exports.getFinanceOrderDownloadUrl = getFinanceOrderDownloadUrl;
exports.getFinanceOrderReviewSummary = getFinanceOrderReviewSummary;
exports.getNetBarUploadUrl = getNetBarUploadUrl;
exports.getO2oCouponWriteOffDownloadUrl = getO2oCouponWriteOffDownloadUrl;
exports.getOrderDownloadUrl = getOrderDownloadUrl;
exports.getRechargeCardList = getRechargeCardList;
exports.getRechargeOrderDownloadUrl = getRechargeOrderDownloadUrl;
exports.getSimpleList = getSimpleList;
exports.offlineStoreDevice = offlineStoreDevice;
exports.saveFinanceBillExtra = saveFinanceBillExtra;
exports.updateFinanceBillCost = updateFinanceBillCost;
exports.updateO2oCoupon = updateO2oCoupon;
exports.updateO2oCouponStatus = updateO2oCouponStatus;
exports.updateStore = updateStore;
exports.updateStoreDevice = updateStoreDevice;
exports.updateStoreDevicePrice = updateStoreDevicePrice;
exports.updateStoreDevicePriceStatus = updateStoreDevicePriceStatus;
exports.updateStoreDeviceStatus = updateStoreDeviceStatus;
exports.updateStoreRecharge = updateStoreRecharge;
exports.updateStoreRechargeStatus = updateStoreRechargeStatus;
var _request = _interopRequireDefault(require("@/utils/request"));
var _auth = require("@/utils/auth");
var _helper = require("@/utils/helper");
/**************** 门店相关 ******************/

function getNetBarUploadUrl() {
  return process.env.VUE_APP_BASE_API + '/net_bar_upload';
}
function fetchStoreList(query) {
  return (0, _request.default)({
    url: '/net_bars',
    method: 'get',
    params: query
  });
}
function getSimpleList(query) {
  return (0, _request.default)({
    url: '/net_bar_simple',
    method: 'get',
    params: query
  });
}
function fetchStore(id, query) {
  query = query || {};
  return (0, _request.default)({
    url: 'net_bars/' + id,
    method: 'get',
    params: query
  });
}
function createStore(data) {
  return (0, _request.default)({
    url: 'net_bars',
    method: 'post',
    data: data
  });
}
function updateStore(id, data) {
  return (0, _request.default)({
    url: 'net_bars/' + id,
    method: 'put',
    data: data
  });
}

// export function deleteStore(id) {
//   return request({
//     url: '/net_bars/' + id,
//     method: 'delete'
//   })
// }

function getChargeModeList() {
  return (0, _request.default)({
    url: '/net_bar_charge_modes',
    method: 'get'
  });
}
function getRechargeCardList() {
  return (0, _request.default)({
    url: '/net_bar_recharge_cards',
    method: 'get'
  });
}
function getDeviceConfigurationList() {
  return (0, _request.default)({
    url: '/net_bar_device_configurations',
    method: 'get'
  });
}
function fetchO2oList() {
  return (0, _request.default)({
    url: '/net_bar_o2os',
    method: 'get'
  });
}

/**************** 门店充值配置相关 ******************/

function fetchStoreRechargeList(storeId, query) {
  return (0, _request.default)({
    url: "/net_bars/".concat(storeId, "/recharges"),
    method: 'get',
    params: query
  });
}
function fetchStoreRecharge(storeId, rechargeId) {
  return (0, _request.default)({
    url: "/net_bars/".concat(storeId, "/recharges/").concat(rechargeId),
    method: 'get'
  });
}
function createStoreRecharge(storeId, data) {
  return (0, _request.default)({
    url: "/net_bars/".concat(storeId, "/recharges"),
    method: 'post',
    data: data
  });
}
function updateStoreRecharge(storeId, rechargeId, data) {
  return (0, _request.default)({
    url: "/net_bars/".concat(storeId, "/recharges/").concat(rechargeId),
    method: 'put',
    data: data
  });
}
function updateStoreRechargeStatus(storeId, rechargeId, status) {
  return (0, _request.default)({
    url: "/net_bars/".concat(storeId, "/recharges/").concat(rechargeId, "/status"),
    method: 'put',
    data: {
      status: status
    }
  });
}

/**************** 门店价格配置相关 ******************/

function fetchStoreDevicePriceList(storeId, query) {
  return (0, _request.default)({
    url: "/net_bars/".concat(storeId, "/device_prices"),
    method: 'get',
    params: query
  });
}
function fetchStoreDevicePriceSimpleList(storeId, query) {
  return (0, _request.default)({
    url: "/net_bars/".concat(storeId, "/device_price_simple"),
    method: 'get',
    params: query
  });
}
function fetchStoreDevicePrice(storeId, devicePriceId) {
  return (0, _request.default)({
    url: "/net_bars/".concat(storeId, "/device_prices/").concat(devicePriceId),
    method: 'get'
  });
}
function createStoreDevicePrice(storeId, data) {
  return (0, _request.default)({
    url: "/net_bars/".concat(storeId, "/device_prices"),
    method: 'post',
    data: data
  });
}
function updateStoreDevicePrice(storeId, devicePriceId, data) {
  return (0, _request.default)({
    url: "/net_bars/".concat(storeId, "/device_prices/").concat(devicePriceId),
    method: 'put',
    data: data
  });
}
function updateStoreDevicePriceStatus(storeId, devicePriceId, status) {
  return (0, _request.default)({
    url: "/net_bars/".concat(storeId, "/device_prices/").concat(devicePriceId, "/status"),
    method: 'put',
    data: {
      status: status
    }
  });
}
function fetchStoreDevicePriceLogList(storeId, devicePriceId, query) {
  return (0, _request.default)({
    url: "/net_bars/".concat(storeId, "/device_prices/").concat(devicePriceId, "/logs"),
    method: 'get',
    params: query
  });
}
function fetchStoreDevicePriceLog(storeId, devicePriceId, logId) {
  return (0, _request.default)({
    url: "/net_bars/".concat(storeId, "/device_prices/").concat(devicePriceId, "/logs/").concat(logId),
    method: 'get'
  });
}
function fetchStoreDevicePricePackList(storeId, devicePriceId, query) {
  return (0, _request.default)({
    url: "/net_bars/".concat(storeId, "/device_prices/").concat(devicePriceId, "/packs"),
    method: 'get',
    params: query
  });
}

/**************** 门店设备相关 ******************/

function fetchStoreDeviceList(storeId, query) {
  return (0, _request.default)({
    url: "/net_bars/".concat(storeId, "/devices"),
    method: 'get',
    params: query
  });
}
function fetchStoreDevice(storeId, deviceId) {
  return (0, _request.default)({
    url: "/net_bars/".concat(storeId, "/devices/").concat(deviceId),
    method: 'get'
  });
}
function createStoreDevice(storeId, data) {
  return (0, _request.default)({
    url: "/net_bars/".concat(storeId, "/devices"),
    method: 'post',
    data: data
  });
}
function updateStoreDevice(storeId, deviceId, data) {
  return (0, _request.default)({
    url: "/net_bars/".concat(storeId, "/devices/").concat(deviceId),
    method: 'put',
    data: data
  });
}
function deleteStoreDevice(storeId, deviceId) {
  return (0, _request.default)({
    url: "/net_bars/".concat(storeId, "/devices/").concat(deviceId),
    method: 'delete'
  });
}
function offlineStoreDevice(storeId, deviceId) {
  return (0, _request.default)({
    url: "/net_bars/".concat(storeId, "/devices/").concat(deviceId, "/offline"),
    method: 'put'
  });
}
function updateStoreDeviceStatus(storeId, deviceId, status) {
  return (0, _request.default)({
    url: "/net_bars/".concat(storeId, "/devices/").concat(deviceId, "/status"),
    method: 'put',
    data: {
      status: status
    }
  });
}

/**************** 开机订单相关 ******************/

function fetchOrderList(query) {
  return (0, _request.default)({
    url: "/net_bar_orders",
    method: 'get',
    params: query
  });
}
function getOrderDownloadUrl(query) {
  query.api_token = (0, _auth.getToken)();
  var queryStr = (0, _helper.parseUrl)(query);
  return "".concat(process.env.VUE_APP_BASE_API, "/net_bar_order_download?").concat(queryStr);
}
function fetchOrder(orderId) {
  return (0, _request.default)({
    url: "/net_bar_orders/".concat(orderId),
    method: 'get'
  });
}
function fetchOrderMigrateList(orderId) {
  return (0, _request.default)({
    url: "/net_bar_orders/".concat(orderId, "/migrates"),
    method: 'get'
  });
}

/**************** 开机订单相关 ******************/

function fetchRechargeOrderList(query) {
  return (0, _request.default)({
    url: "/net_bar_recharge_orders",
    method: 'get',
    params: query
  });
}
function getRechargeOrderDownloadUrl(query) {
  query.api_token = (0, _auth.getToken)();
  var queryStr = (0, _helper.parseUrl)(query);
  return "".concat(process.env.VUE_APP_BASE_API, "/net_bar_recharge_order_download?").concat(queryStr);
}
function fetchRechargeOrder(orderId) {
  return (0, _request.default)({
    url: "/net_bar_recharge_orders/".concat(orderId),
    method: 'get'
  });
}

/**************** 经营统计数据相关 ******************/

function fetchStoreStatsUsedTimeChart(storeId, query) {
  return (0, _request.default)({
    url: "/net_bars/".concat(storeId, "/stats_chart/used_time"),
    method: 'get',
    params: query
  });
}
function fetchStoreStatsDevicePerUsedTimeChart(storeId, query) {
  return (0, _request.default)({
    url: "/net_bars/".concat(storeId, "/stats_chart/device_per_used_time"),
    method: 'get',
    params: query
  });
}
function fetchStoreStatsUserPerUsedTimeChart(storeId, query) {
  return (0, _request.default)({
    url: "/net_bars/".concat(storeId, "/stats_chart/user_per_used_time"),
    method: 'get',
    params: query
  });
}

/**************** 财务对账相关 ******************/

function fetchFinanceBillList(query) {
  return (0, _request.default)({
    url: "/net_bar_finance_bills",
    method: 'get',
    params: query
  });
}
function getFinanceBillDownloadUrl(query) {
  query.api_token = (0, _auth.getToken)();
  var queryStr = (0, _helper.parseUrl)(query);
  return "".concat(process.env.VUE_APP_BASE_API, "/net_bar_finance_bill_download?").concat(queryStr);
}
function fetchFinanceBill(billId) {
  return (0, _request.default)({
    url: "/net_bar_finance_bills/".concat(billId),
    method: 'get'
  });
}
function saveFinanceBillExtra(billId, data) {
  return (0, _request.default)({
    url: "/net_bar_finance_bills/".concat(billId, "/extras"),
    method: 'post',
    data: data
  });
}
function financeBillStatusFinanceConfirm(billId) {
  return (0, _request.default)({
    url: "/net_bar_finance_bills/".concat(billId, "/status_finance_confirm"),
    method: 'put'
  });
}
function financeBillStatusBatchFinanceConfirm(billIds) {
  return (0, _request.default)({
    url: "/net_bar_finance_bill_batch_finance_confirm",
    method: 'put',
    data: {
      id: billIds
    }
  });
}
function financeBillStatusComplete(billId) {
  return (0, _request.default)({
    url: "/net_bar_finance_bills/".concat(billId, "/status_complete"),
    method: 'put'
  });
}
function getFinanceBillCostFeeTypeList() {
  return (0, _request.default)({
    url: '/net_bar_finance_bill_cost_fee_types',
    method: 'get'
  });
}
function fetchFinanceBillCostList(billId, params) {
  return (0, _request.default)({
    url: "/net_bar_finance_bills/".concat(billId, "/costs"),
    method: 'get',
    params: params
  });
}
function fetchFinanceBillCost(billId, costId) {
  return (0, _request.default)({
    url: "/net_bar_finance_bills/".concat(billId, "/costs/").concat(costId),
    method: 'get'
  });
}
function createFinanceBillCost(billId, data) {
  return (0, _request.default)({
    url: "/net_bar_finance_bills/".concat(billId, "/costs"),
    method: 'post',
    data: data
  });
}
function updateFinanceBillCost(billId, costId, data) {
  return (0, _request.default)({
    url: "/net_bar_finance_bills/".concat(billId, "/costs/").concat(costId),
    method: 'put',
    data: data
  });
}
function deleteFinanceBillCost(billId, costId) {
  return (0, _request.default)({
    url: "/net_bar_finance_bills/".concat(billId, "/costs/").concat(costId),
    method: 'delete'
  });
}
function getFinanceBillCostDownloadUrl(billId, query) {
  query.api_token = (0, _auth.getToken)();
  var queryStr = (0, _helper.parseUrl)(query);
  return "".concat(process.env.VUE_APP_BASE_API, "/net_bar_finance_bills/").concat(billId, "/cost_download?").concat(queryStr);
}

/**************** 外部卡券管理相关 ******************/

function fetchO2oCouponList(query) {
  return (0, _request.default)({
    url: "/net_bar_o2o_coupons",
    method: 'get',
    params: query
  });
}
function fetchO2oCoupon(couponId) {
  return (0, _request.default)({
    url: "/net_bar_o2o_coupons/".concat(couponId),
    method: 'get'
  });
}
function createO2oCoupon(data) {
  return (0, _request.default)({
    url: "/net_bar_o2o_coupons",
    method: 'post',
    data: data
  });
}
function updateO2oCoupon(couponId, data) {
  return (0, _request.default)({
    url: "/net_bar_o2o_coupons/".concat(couponId),
    method: 'put',
    data: data
  });
}
function updateO2oCouponStatus(couponId, status) {
  return (0, _request.default)({
    url: "/net_bar_o2o_coupons/".concat(couponId, "/status"),
    method: 'put',
    data: {
      status: status
    }
  });
}
function fetchO2oCouponWriteOffList(query) {
  return (0, _request.default)({
    url: "/net_bar_o2o_coupon_write_offs",
    method: 'get',
    params: query
  });
}
function getO2oCouponWriteOffDownloadUrl(query) {
  query.api_token = (0, _auth.getToken)();
  var queryStr = (0, _helper.parseUrl)(query);
  return "".concat(process.env.VUE_APP_BASE_API, "/net_bar_o2o_coupon_write_off_download?").concat(queryStr);
}

/**************** 门店会员相关 ******************/

function fetchMemberList(storeId, query) {
  return (0, _request.default)({
    url: "/net_bars/".concat(storeId, "/members"),
    method: 'get',
    params: query
  });
}

/**************** 结算订单相关 ******************/

function fetchFinanceOrderList(query) {
  return (0, _request.default)({
    url: "/net_bar_finance_reco/orders",
    method: 'get',
    params: query
  });
}
function getFinanceOrderDownloadUrl(query) {
  query.api_token = (0, _auth.getToken)();
  var queryStr = (0, _helper.parseUrl)(query);
  return "".concat(process.env.VUE_APP_BASE_API, "/net_bar_finance_reco/order_download?").concat(queryStr);
}
function financeImportThridOrders(data) {
  return (0, _request.default)({
    url: '/net_bar_finance_reco/import',
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: data
  });
}
function getFinanceOrderReviewSummary() {
  return (0, _request.default)({
    url: '/net_bar_finance_reco/order_review_summary',
    method: 'get'
  });
}
function financeOrderReview() {
  return (0, _request.default)({
    url: "/net_bar_finance_reco/order_review",
    method: 'post'
  });
}
function fetchFinanceAbnormalOrderList(query) {
  return (0, _request.default)({
    url: "/net_bar_finance_reco/abnormal_orders",
    method: 'get',
    params: query
  });
}
function getFinanceAbnormalOrderDownloadUrl(query) {
  query.api_token = (0, _auth.getToken)();
  var queryStr = (0, _helper.parseUrl)(query);
  return "".concat(process.env.VUE_APP_BASE_API, "/net_bar_finance_reco/abnormal_order_download?").concat(queryStr);
}