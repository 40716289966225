"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _net_bar = require("@/api/net_bar");
var _update_recordDetail = _interopRequireDefault(require("./update_record detail.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'NetBarDevicePriceLog',
  components: {
    NetBarDevicePriceLogDetail: _update_recordDetail.default
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    id: [Number, String],
    storeId: {
      type: [String, Number]
    }
  },
  watch: {
    visible: function visible() {
      this.dialogVisible = this.visible;
      if (this.visible) {
        this.getList();
      }
    }
  },
  data: function data() {
    return {
      dialogVisible: false,
      list: [],
      listLoading: false,
      pagination: {
        page: 1,
        limit: 10,
        total: 0
      },
      logId: undefined,
      logVisible: false
    };
  },
  created: function created() {},
  methods: {
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      (0, _net_bar.fetchStoreDevicePriceLogList)(this.storeId, this.id, {
        page: this.pagination.page,
        limit: this.pagination.limit
      }).then(function (response) {
        var data = response.data;
        _this.list = data.data;
        _this.pagination.page = data.current_page;
        _this.pagination.total = data.total;
        _this.listLoading = false;
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      this.pagination.limit = val;
      this.getList();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.pagination.page = val;
      this.getList();
    },
    closeHandler: function closeHandler() {
      this.dialogVisible = false;
      this.list = [];
      this.$emit("update:visible", this.dialogVisible);
    },
    handleDetailRedirect: function handleDetailRedirect(record) {
      this.logId = record.id;
      this.logVisible = true;
    }
  }
};
exports.default = _default;