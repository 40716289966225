"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _work_order = require("@/api/work_order");
var _hotel = require("@/api/hotel");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "WorkOrderOrder",
  watch: {},
  data: function data() {
    var _this = this;
    var validateFeedbackDesc = function validateFeedbackDesc(rule, value, callback) {
      if (_this.processForm.process_type == "close" && !value) {
        return callback(new Error('请输入处理意见'));
      }
      callback();
    };
    return {
      tabName: "to_be_process",
      templateTypes: [],
      templateLevels: [],
      hotelList: [],
      feedbackStatus: [],
      // 列表及查询
      orders: [],
      listQuery: {
        page: 1,
        limit: 20,
        status_tag: "to_be_process",
        order_no: "",
        hotel_id: "",
        level: "",
        type: "",
        feedback_status: "",
        start_date: ""
      },
      pagination: {},
      listLoading: false,
      dialogVisible: false,
      orderDetail: {},
      detailLoading: false,
      orderActionProcess: false,
      processFormSubmitLoading: false,
      processForm: {
        id: "",
        process_type: "close",
        feedback_desc: "",
        report_desc: ""
      },
      processFormRules: {
        // process_type: [{ required: true, message: '请选择处理方式', trigger: 'change' }],
        feedback_desc: [{
          required: true,
          message: '请输入处理意见',
          trigger: 'blur'
        }
        // { validator: validateFeedbackDesc, trigger: 'blur' }
        ]
      }
    };
  },
  created: function created() {
    var _this2 = this;
    if (this.$route.query.order_no) {
      this.listQuery.order_no = this.$route.query.order_no;
    }
    this.getList();
    (0, _work_order.getTemplateTypeList)().then(function (response) {
      _this2.templateTypes = response.data;
    });
    (0, _work_order.getTemplateLevelList)().then(function (response) {
      _this2.templateLevels = response.data;
    });
    (0, _hotel.getHotelSimpleList)().then(function (response) {
      _this2.hotelList = response.data;
    });
    (0, _work_order.getFeedbackStatusList)().then(function (response) {
      _this2.feedbackStatus = response.data;
    });
  },
  methods: {
    typeHandler: function typeHandler() {
      if (this.tabName == this.listQuery.status_tag) {
        return;
      }
      this.listQuery.status_tag = this.tabName;
      if (this.listQuery.status_tag == "to_be_process") {
        this.listQuery.feedback_status = "";
      }
      this.getList();
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleReset: function handleReset() {
      this.listQuery.order_no = "";
      this.listQuery.hotel_id = "";
      this.listQuery.level = "";
      this.listQuery.type = "";
      this.listQuery.feedback_status = "";
      this.listQuery.start_date = "";
      this.handleFilter();
    },
    exportHandler: function exportHandler() {
      window.open((0, _work_order.getWorkOrderDownloadUrl)(this.listQuery));
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    getList: function getList() {
      var _this3 = this;
      this.listLoading = true;
      (0, _work_order.getWorkOrderList)(this.listQuery).then(function (response) {
        var data = response.data;
        _this3.pagination = {
          total: data.total,
          currentPage: data.current_page,
          pageSize: data.per_page
        };
        _this3.orders = data.data;
        _this3.listQuery.page = data.current_page;
        _this3.listLoading = false;
      });
    },
    getOrder: function getOrder(orderId) {
      var _this4 = this;
      this.detailLoading = true;
      (0, _work_order.getWorkOrder)(orderId).then(function (response) {
        var data = response.data;
        data.logs = data.logs.reverse().map(function (item) {
          item.log = item.log.replaceAll("@@", "");
          return item;
        });
        _this4.orderDetail = data;
        _this4.detailLoading = false;
      });
    },
    detailHandler: function detailHandler(record) {
      this.orderActionProcess = false;
      this.getOrder(record.id);
      this.processForm.id = record.id;
      this.dialogVisible = true;
    },
    processHandler: function processHandler(record) {
      this.orderActionProcess = true;
      this.getOrder(record.id);
      this.processForm.id = record.id;
      this.dialogVisible = true;
    },
    processFormCloseHanlder: function processFormCloseHanlder() {
      if (this.orderActionProcess) {
        this.$refs['processForm'].resetFields();
      }
    },
    onProcessTypeChange: function onProcessTypeChange(val) {
      this.$refs['processForm'].clearValidate();
      if (val == "close") {
        this.processForm.feedback_desc = this.processForm.report_desc;
      } else {
        this.processForm.report_desc = this.processForm.feedback_desc;
      }
    },
    processFormSubmitHandler: function processFormSubmitHandler() {
      var _this5 = this;
      this.$refs['processForm'].validate(function (valid) {
        if (valid) {
          _this5.processFormSubmitLoading = true;
          if (_this5.processForm.process_type == "close") {
            (0, _work_order.processWorkOrderClose)(_this5.processForm.id, {
              feedback_desc: _this5.processForm.feedback_desc
            }).then(function () {
              _this5.getList();
              _this5.$message.success("操作成功");
              _this5.dialogVisible = false;
            }).finally(function () {
              _this5.processFormSubmitLoading = false;
            });
          } else if (_this5.processForm.process_type == "report") {
            (0, _work_order.processWorkOrderReport)(_this5.processForm.id, {
              feedback_desc: _this5.processForm.report_desc
            }).then(function () {
              _this5.getList();
              _this5.$message.success("操作成功");
              _this5.dialogVisible = false;
            }).finally(function () {
              _this5.processFormSubmitLoading = false;
            });
          }
        }
      });
    },
    getOrderDetailTimeRange: function getOrderDetailTimeRange(order) {
      if (!order.id) {
        return '';
      }
      var startTime = order.start_time.substr(0, 5);
      var endTime = order.end_time.substr(0, 5);
      if (order.start_date != order.end_date) {
        return "".concat(order.start_date, " ").concat(startTime, " \u81F3 ").concat(order.end_date, " ").concat(endTime);
      }
      return "".concat(order.start_date, " ").concat(startTime, " - ").concat(endTime);
    }
  }
};
exports.default = _default;