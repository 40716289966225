var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container calendar-list-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              staticStyle: { "margin-left": "10px" },
              attrs: { type: "primary", icon: "edit" },
              on: {
                click: function ($event) {
                  return _vm.doAdd()
                },
              },
            },
            [_vm._v("添加")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          key: "tableList",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            "element-loading-text": "给我一点时间",
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "序号", width: "65" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.id))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _vm.areas.length > 0
            ? _c("el-table-column", {
                attrs: { align: "center", label: "所属板块" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.areas[scope.row.area_id - 2].name)
                            ),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2861689143
                ),
              })
            : _vm._e(),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "图片", width: "250" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("img", {
                      staticStyle: { width: "200px" },
                      attrs: { src: scope.row.thumbnail },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "排序" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.order))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "跳转类型" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          scope.row.redirect_type == 0
                            ? "不跳转"
                            : scope.row.redirect_type == 1
                            ? "跳转小程序"
                            : "跳转网页"
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "跳转地址" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.path))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "是否展示" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(scope.row.is_show == 1 ? "是" : "否")),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.updateAction(scope.row)
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "danger" },
                        on: {
                          click: function ($event) {
                            return _vm.deleteBanner(scope.row.id)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: _vm.dialogTitle, visible: _vm.dialogFormVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              staticClass: "small-space",
              staticStyle: { width: "500px", "margin-left": "50px" },
              attrs: {
                model: _vm.temp,
                "label-position": "left",
                "label-width": "100px",
              },
            },
            [
              _vm.areas.length > 0
                ? _c(
                    "el-form-item",
                    { attrs: { label: "板块" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "filter-item",
                          attrs: {
                            "default-first-option": "",
                            placeholder: "请选择区域",
                            clearable: "",
                          },
                          model: {
                            value: _vm.temp.area_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.temp, "area_id", $$v)
                            },
                            expression: "temp.area_id",
                          },
                        },
                        _vm._l(_vm.areas, function (item) {
                          return _c("el-option", {
                            key: item.role_id,
                            attrs: { label: item.name, value: item.role_id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "banner" } },
                [
                  _c(
                    "el-upload",
                    {
                      staticClass: "avatar-uploader",
                      attrs: {
                        action: "/admin/upload",
                        headers: _vm.header,
                        "show-file-list": false,
                        "on-success": _vm.handleImageSuccess,
                      },
                    },
                    [
                      _vm.temp.thumbnail
                        ? _c("img", {
                            staticClass: "avatar",
                            staticStyle: { "max-width": "100%" },
                            attrs: { src: _vm.temp.thumbnail },
                          })
                        : _c("i", {
                            staticClass: "el-icon-plus avatar-uploader-icon",
                          }),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "描述" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.temp.desc,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp, "desc", $$v)
                      },
                      expression: "temp.desc",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "跳转类型" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.temp.redirect_type,
                        callback: function ($$v) {
                          _vm.$set(_vm.temp, "redirect_type", $$v)
                        },
                        expression: "temp.redirect_type",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [
                        _vm._v("不跳转"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("小程序页面"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 2 } }, [_vm._v("网页")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.temp.redirect_type,
                      expression: "temp.redirect_type",
                    },
                  ],
                  attrs: { label: "链接路径" },
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.temp.path,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp, "path", $$v)
                      },
                      expression: "temp.path",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "排序" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.temp.order,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp, "order", $$v)
                      },
                      expression: "temp.order",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "是否展示", prop: "is_show" } },
                [
                  _c(
                    "el-switch",
                    {
                      attrs: { "active-value": 1, "inactive-value": 0 },
                      model: {
                        value: _vm.temp.is_show,
                        callback: function ($$v) {
                          _vm.$set(_vm.temp, "is_show", $$v)
                        },
                        expression: "temp.is_show",
                      },
                    },
                    [_vm._v("是")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.createBanner } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }