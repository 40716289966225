"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _mixin = _interopRequireDefault(require("@/utils/mixin"));
var _moment = _interopRequireDefault(require("moment"));
var _hotel = require("@/api/hotel");
var _finance_reco = require("@/api/finance_reco");
var _AppealBillForm = _interopRequireDefault(require("./AppealBillForm"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  mixins: [_mixin.default],
  components: {
    AppealBillForm: _AppealBillForm.default
  },
  data: function data() {
    return {
      list: [],
      hotelList: [],
      listQuery: {
        page: 1,
        limit: 20,
        bill_date: [],
        hotel_id: "",
        audit_status: ""
      },
      pagination: "",
      statusOptions: [{
        value: "",
        label: "全部状态"
      }, {
        value: 0,
        label: "待处理"
      }, {
        value: 1,
        label: "已处理"
      }],
      appealBillId: undefined,
      appealBillFormVisible: false
    };
  },
  created: function created() {
    var _this = this;
    this.getList();
    (0, _hotel.getHotelSimpleList)().then(function (response) {
      _this.hotelList = response.data;
    });
  },
  methods: {
    getList: function getList() {
      var _this2 = this;
      this.listLoading = true;
      (0, _finance_reco.getFinanceBillAppealList)(this.listQuery).then(function (response) {
        var data = response.data;
        _this2.list = data.data;
        _this2.listLoading = false;
        _this2.pagination = data;
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    resetForm: function resetForm() {
      this.listQuery.bill_date = [];
      this.listQuery.audit_status = "";
      this.listQuery.hotel_id = "";
    },
    onBillDateChange: function onBillDateChange(date) {
      if (date != null) {
        var start = date[0];
        var end = date[1];
        var startBillDate = this.getWeekRange((0, _moment.default)(start).format("YYYY-MM-DD"))[0];
        var endBillDate = this.getWeekRange((0, _moment.default)(end).format("YYYY-MM-DD"))[1];
        this.listQuery.bill_date = [startBillDate, endBillDate];
      } else {
        this.listQuery.bill_date = [];
      }
    },
    auditHandler: function auditHandler(record) {
      this.appealBillId = record.bill_id;
      this.appealBillFormVisible = true;
    },
    showHandler: function showHandler(record) {
      this.appealBillId = record.bill_id;
      this.appealBillFormVisible = true;
    },
    exportHandler: function exportHandler() {
      window.open((0, _finance_reco.getFinanceBillAppealDownloadUrl)(this.listQuery));
    }
  }
};
exports.default = _default;