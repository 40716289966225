"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _net_bar = require("@/api/net_bar");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import { getToken } from '@/utils/auth' 
var _default = {
  name: "NetBarStore",
  data: function data() {
    return {
      list: [],
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 20,
        id: "",
        name: "",
        area: "",
        // province:"",
        // city:"",
        // district:"",
        status: ""
      },
      pagination: "",
      showOptions: [{
        value: '',
        label: '全部'
      }, {
        value: 1,
        label: '正常'
      }, {
        value: 0,
        label: '锁定'
      }]
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      (0, _net_bar.fetchStoreList)(this.listQuery).then(function (response) {
        var data = response.data;
        _this.list = data.data;
        _this.pagination = data;
        _this.listQuery.page = data.current_page;
        _this.listLoading = false;
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    renderTipsHeader: function renderTipsHeader(h, _ref) {
      var column = _ref.column;
      var tips = '';
      switch (column.label) {
        case '设备数量':
          tips = '使用中数量/总数量';
          break;
        default:
      }
      return h('div', [h('span', column.label), h('el-tooltip', {
        props: {
          effect: 'dark',
          content: tips,
          placement: 'top'
        }
      }, [h('i', {
        class: 'el-icon-question',
        style: 'color:#409EFF;margin-left:5px;'
      })])]);
    },
    addStoreHandler: function addStoreHandler() {
      this.$router.push({
        path: "/net_bar/store/add"
      });
    },
    editStoreHandler: function editStoreHandler(id) {
      this.$router.push({
        path: "/net_bar/store/edit/" + id
      });
    },
    showStoreHandler: function showStoreHandler(id) {
      this.$router.push({
        path: "/net_bar/store/detail/" + id
      });
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    }
  }
};
exports.default = _default;