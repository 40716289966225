"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _vDistpicker = _interopRequireDefault(require("v-distpicker"));
var _net_bar = require("@/api/net_bar");
var _auth = require("@/utils/auth");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "NetBarStoreForm",
  components: {
    VDistpicker: _vDistpicker.default
  },
  data: function data() {
    return {
      id: undefined,
      o2oTypes: [],
      formPath: '',
      storeForm: {
        base: {
          status: 0,
          thumbnail: '',
          // 必须存在
          district: '',
          // 必须存在
          o2o_ids: {}
        },
        finance: {
          type: 1,
          bank_city: '' // 必须存在
        },

        settlement: {
          is_mgt_fee: 0,
          mgt_fee_ratio: 0,
          is_order_commission: 0,
          nb_share_ratio: 0
        }
      },
      formRules: {
        'base.name': [{
          required: true,
          message: '请输入门店名称',
          trigger: 'blur'
        }],
        'base.cd_id': [{
          required: true,
          message: '请输入计费系统ID',
          trigger: 'blur'
        }],
        'base.thumbnail': [{
          required: true,
          message: '请上传缩略图',
          trigger: 'change'
        }],
        'base.district': [{
          required: true,
          message: '请选择地区',
          trigger: 'change'
        }],
        'base.address': [{
          required: true,
          message: '请输入详细地址',
          trigger: 'blur'
        }],
        'base.lng': [{
          required: true,
          message: '请输入经度',
          trigger: 'blur'
        }],
        'base.lat': [{
          required: true,
          message: '请输入纬度',
          trigger: 'blur'
        }],
        'base.settlement_type': [{
          required: true,
          message: '请选择门店数据来源',
          trigger: 'change'
        }],
        'base.yjf_account': [{
          required: true,
          message: '请输入云计费门店账号',
          trigger: 'blur'
        }],
        'base.status': [{
          required: true,
          message: '请选择门店状态',
          trigger: 'blur'
        }],
        'finance.type': [{
          required: true,
          message: '请选择账户性质',
          trigger: 'blur'
        }],
        'finance.account': [{
          required: true,
          message: '请输入账户名称',
          trigger: 'blur'
        }],
        'finance.bank_account': [{
          required: true,
          message: '请输入银行账号',
          trigger: 'blur'
        }],
        'finance.bank': [{
          required: true,
          message: '请输入开户行',
          trigger: 'blur'
        }],
        'finance.bank_city': [{
          required: true,
          message: '请选择开户行所在省市',
          trigger: 'blur'
        }],
        'finance.idcard': [{
          required: true,
          message: '请输入收款人身份证号',
          trigger: 'change'
        }],
        'settlement.is_mgt_fee': [{
          required: true,
          message: '请选择飞火管理费',
          trigger: 'blur'
        }],
        'settlement.mgt_fee_ratio': [{
          required: true,
          message: '请输入管理费比例',
          trigger: 'change'
        }, {
          type: "number",
          min: 0,
          max: 100,
          message: '管理费比例不能小于0',
          trigger: 'blur'
        }],
        'settlement.is_order_commission': [{
          required: true,
          message: '请选择订单分佣',
          trigger: 'blur'
        }],
        'settlement.nb_share_ratio': [{
          required: true,
          message: '请输入商家分佣比例',
          trigger: 'change'
        }, {
          type: "number",
          min: 0,
          max: 100,
          message: '商家分佣比例不能小于0',
          trigger: 'blur'
        }]
      },
      formSubmitLoading: false,
      uploadUrl: '',
      header: {
        Authorization: "Bearer " + (0, _auth.getToken)()
      },
      settlementTypes: [{
        value: 0,
        label: "飞火系统"
      }, {
        value: 1,
        label: "云计费"
      }]
    };
  },
  created: function created() {
    var _this = this;
    this.uploadUrl = (0, _net_bar.getNetBarUploadUrl)();
    this.formPath = this.$route.query.form;
    this.id = this.$route.params.id;
    (0, _net_bar.fetchO2oList)().then(function (response) {
      _this.o2oTypes = response.data;
    });
    if (this.id) {
      this.getStore();
    }
  },
  methods: {
    handleImageSuccess: function handleImageSuccess(res) {
      if (res.code == 0) {
        this.$message.success("上传成功！");
        this.$refs['sotreBaseThumbnail'].clearValidate();
        this.storeForm.base.thumbnail = res.data.filepath;
      } else {
        this.$message.error("上传失败");
      }
    },
    onStoreDistSelected: function onStoreDistSelected(data) {
      this.storeForm.base.province = data.province.value;
      this.storeForm.base.city = data.city.value;
      this.storeForm.base.district = data.area.value;
      this.$refs['sotreBaseDistrict'].clearValidate();
    },
    onBankDistSelected: function onBankDistSelected(data) {
      this.storeForm.finance.bank_province = data.province.value;
      this.storeForm.finance.bank_city = data.city.value;
      this.$refs['sotreFinanceBankCity'].clearValidate();
    },
    submitHandler: function submitHandler() {
      var _this2 = this;
      this.$refs['storeForm'].validate(function (valid) {
        if (!valid) {
          return false;
        }
        if (_this2.id) {
          _this2.editHandler();
        } else {
          _this2.addHandler();
        }
      });
    },
    addHandler: function addHandler() {
      var _this3 = this;
      this.formSubmitLoading = true;
      (0, _net_bar.createStore)(this.storeForm).then(function () {
        _this3.$message.success("添加成功");
        _this3.formSubmitLoading = false;
        _this3.$router.push('/net_bar/store/index');
      }).catch(function () {
        _this3.formSubmitLoading = false;
      });
    },
    editHandler: function editHandler() {
      var _this4 = this;
      this.formSubmitLoading = true;
      (0, _net_bar.updateStore)(this.id, this.storeForm).then(function () {
        _this4.$message.success("编辑成功");
        _this4.formSubmitLoading = false;
        if (_this4.formPath) {
          _this4.$router.push("/net_bar/store/detail/".concat(id));
        } else {
          _this4.$router.push('/net_bar/store/index');
        }
      }).catch(function () {
        _this4.formSubmitLoading = false;
      });
    },
    getStore: function getStore() {
      var _this5 = this;
      (0, _net_bar.fetchStore)(this.id).then(function (res) {
        // 数据分类 base finance
        _this5.storeForm = {
          base: _this5.parseBaseData(res.data),
          finance: _this5.parseFinanceData(res.data),
          settlement: _this5.parseSettlementData(res.data)
        };
      });
    },
    parseBaseData: function parseBaseData(data) {
      return {
        name: data.name,
        cd_id: data.cd_id,
        thumbnail: data.thumbnail,
        contacts: data.contacts,
        tel: data.tel,
        province: data.province,
        city: data.city,
        district: data.district,
        address: data.address,
        lng: data.lng,
        lat: data.lat,
        settlement_type: data.settlement_type,
        yjf_account: data.yjf_account,
        status: data.status,
        o2o_ids: data.o2o_ids || {}
      };
    },
    parseFinanceData: function parseFinanceData(data) {
      if (data.finance) {
        return {
          type: data.finance.type,
          account: data.finance.account,
          bank_account: data.finance.bank_account,
          bank: data.finance.bank,
          bank_province: data.finance.bank_province,
          bank_city: data.finance.bank_city,
          idcard: data.finance.idcard
        };
      }
      return this.storeForm.finance;
    },
    parseSettlementData: function parseSettlementData(data) {
      if (data.settlement) {
        return {
          is_mgt_fee: data.settlement.is_mgt_fee,
          mgt_fee_ratio: data.settlement.mgt_fee_ratio,
          is_order_commission: data.settlement.is_order_commission,
          nb_share_ratio: data.settlement.nb_share_ratio
        };
      }
      return this.storeForm.settlement;
    },
    cancel: function cancel() {
      this.$router.back();
    }
  }
};
exports.default = _default;