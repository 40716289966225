"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.dayDownload = dayDownload;
exports.dayList = dayList;
exports.financialSettlement = financialSettlement;
exports.getDayBillDownloadUrl = getDayBillDownloadUrl;
exports.getFinancialSettlementDownloadUrl = getFinancialSettlementDownloadUrl;
exports.getWeekBillDownloadUrl = getWeekBillDownloadUrl;
exports.recharge = recharge;
exports.rechargeAll = rechargeAll;
exports.transfer = transfer;
exports.transferAll = transferAll;
exports.weekList = weekList;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
require("core-js/modules/es6.regexp.to-string");
var _request = _interopRequireDefault(require("@/utils/request"));
function dayList(data) {
  return (0, _request.default)({
    url: 'bill/day',
    method: 'get',
    params: data
  });
}
function getDayBillDownloadUrl(day, token) {
  return process.env.VUE_APP_BASE_API + "/bill/dayDownload?date=".concat(day, "&api_token=").concat(token);
}
function dayDownload(data) {
  return (0, _request.default)({
    url: "/bill/dayDownload",
    method: 'get',
    params: data
  });
}
function weekList(data) {
  return (0, _request.default)({
    url: "/bill/week",
    method: 'get',
    params: data
  });
}
function getWeekBillDownloadUrl(params, token) {
  var isArray = function isArray(obj) {
    return Object.prototype.toString.call(obj) === '[object Array]';
  };
  var url = Object.keys(params).map(function (key) {
    if (isArray(params[key])) {
      var iUrl = '';
      params[key].forEach(function (item) {
        iUrl = iUrl + key + '[]=' + item + '&';
      });
      return iUrl.trim('&');
    } else {
      return encodeURIComponent(key) + "=" + encodeURIComponent(params[key]);
    }
  }).join("&");
  return process.env.VUE_APP_BASE_API + "/bill/weekDownload?".concat(url, "&api_token=").concat(token);
}
function transfer(data) {
  return (0, _request.default)({
    url: "/bill/transfer",
    method: 'post',
    params: data
  });
}
function transferAll(data) {
  return (0, _request.default)({
    url: "/bill/transferAll",
    method: 'post',
    params: data
  });
}
function recharge(data) {
  return (0, _request.default)({
    url: "/bill/recharge",
    method: 'post',
    params: data
  });
}
function rechargeAll(data) {
  return (0, _request.default)({
    url: "/bill/rechargeAll",
    method: 'post',
    params: data
  });
}
function financialSettlement(data) {
  return (0, _request.default)({
    url: 'bill/financialSettlement',
    method: 'get',
    params: data
  });
}
function getFinancialSettlementDownloadUrl(url, token) {
  return process.env.VUE_APP_BASE_API + "/bill/financialSettlementDownload?".concat(url, "&api_token=").concat(token);
}