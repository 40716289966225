"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.detail = detail;
exports.fetchStvBalanceLogsList = fetchStvBalanceLogsList;
exports.fetchStvList = fetchStvList;
exports.getList = getList;
exports.getMerberDownloadUrl = getMerberDownloadUrl;
exports.getStatistics = getStatistics;
exports.lockRecord = lockRecord;
exports.memberCoupon = memberCoupon;
exports.sendCoupon = sendCoupon;
exports.writeOffCoupon = writeOffCoupon;
var _request = _interopRequireDefault(require("@/utils/request"));
var _auth = require("@/utils/auth");
var _helper = require("@/utils/helper");
function sendCoupon(userId, data) {
  return (0, _request.default)({
    url: "sendCoupon/".concat(userId),
    method: 'post',
    data: data
  });
}
function detail(userId) {
  return (0, _request.default)({
    url: "member/detail/".concat(userId),
    method: 'get'
  });
}
function memberCoupon(userId) {
  return (0, _request.default)({
    url: "memberCoupon/".concat(userId),
    method: 'get'
  });
}
function getList(query) {
  return (0, _request.default)({
    url: 'member',
    method: 'get',
    params: query
  });
}
function getMerberDownloadUrl(query) {
  query.api_token = (0, _auth.getToken)();
  var queryStr = (0, _helper.parseUrl)(query);
  return "".concat(process.env.VUE_APP_BASE_API, "/member/export?").concat(queryStr);
}
function getStatistics() {
  return (0, _request.default)({
    url: 'getStatistics',
    method: 'get'
  });
}
function lockRecord(userId, query) {
  return (0, _request.default)({
    url: "member/lockRecord/".concat(userId),
    method: 'get',
    params: query
  });
}
function writeOffCoupon(userId, rowIds) {
  return (0, _request.default)({
    url: "writeOffCoupon/".concat(userId),
    method: 'put',
    data: {
      ids: rowIds
    }
  });
}

/**************** 网咖相关 ******************/
function fetchStvList(userId, query) {
  return (0, _request.default)({
    url: "/member_net_bar_stvs/".concat(userId),
    method: 'get',
    params: query
  });
}
function fetchStvBalanceLogsList(userId, nbId, query) {
  return (0, _request.default)({
    url: "/member_net_bar_stvs/".concat(userId, "/balance_logs/").concat(nbId),
    method: 'get',
    params: query
  });
}