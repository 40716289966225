var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            title: "订单详情",
            width: "680px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            close: _vm.closeHanlder,
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "demo-table-expand",
              attrs: { "label-position": "left", inline: "" },
            },
            [
              _c("el-form-item", { attrs: { label: "订单编号:" } }, [
                _c("span", [_vm._v(_vm._s(_vm.detail.order_no))]),
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "状态:" } }, [
                _c("span", [_vm._v(_vm._s(_vm.statusMap[_vm.detail.status]))]),
              ]),
              _vm._v(" "),
              _vm.detail.status == 29
                ? [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { display: "block" },
                        attrs: { label: "退款金额:" },
                      },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.detail.partial_refund_info.amount) + "元"
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c("el-form-item", { attrs: { label: "退款时间:" } }, [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.detail.partial_refund_info.created_at)
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("el-form-item", { attrs: { label: "退款间夜:" } }, [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm._f("dateFormat")(
                              _vm.detail.partial_refund_info.start_date
                            )
                          ) +
                            "-" +
                            _vm._s(
                              _vm._f("dateFormat")(
                                _vm.detail.partial_refund_info.end_date
                              )
                            ) +
                            " " +
                            _vm._s(_vm.detail.partial_refund_info.days) +
                            "晚"
                        ),
                      ]),
                    ]),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _c("el-divider"),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "酒店:" } }, [
                _c("span", [_vm._v(_vm._s(_vm.detail.hotel_name))]),
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "房型:" } }, [
                _c("span", [_vm._v(_vm._s(_vm.detail.room_name))]),
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "入离日期:" } }, [
                _c("span", [
                  _vm._v(
                    _vm._s(_vm._f("dateFormat")(_vm.detail.start_date)) +
                      "-" +
                      _vm._s(_vm._f("dateFormat")(_vm.detail.end_date)) +
                      " " +
                      _vm._s(_vm.detail.days) +
                      "晚"
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "房间数:" } }, [
                _c("span", [_vm._v(_vm._s(_vm.detail.room_quantity) + "间")]),
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "入住人:" } }, [
                _c("span", [_vm._v(_vm._s(_vm.detail.contacts))]),
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "手机号:" } }, [
                _c("span", [_vm._v(_vm._s(_vm.detail.mobile))]),
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "取消规则:" } }, [
                _c("span", [
                  _vm._v(
                    _vm._s(_vm._f("dateFormat")(_vm.detail.start_date)) +
                      " 18:00前免费取消"
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "下单时间:" } }, [
                _c("span", [_vm._v(_vm._s(_vm.detail.created_at))]),
              ]),
              _vm._v(" "),
              _c("el-divider"),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "飞火价:" } }, [
                _c("span", [_vm._v(_vm._s(_vm.detail.origin_amount) + "元")]),
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "支付时间:" } }, [
                _c("span", [_vm._v(_vm._s(_vm.detail.pay_at))]),
              ]),
              _vm._v(" "),
              _vm.detail.coupon.length > 0
                ? _c("el-form-item", { attrs: { label: "平台补贴：" } }, [
                    _c(
                      "div",
                      [
                        _c(
                          "el-table",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              data: _vm.detail.coupon,
                              border: "",
                              size: "mini",
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                prop: "name",
                                label: "名称",
                                width: "180",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "amount",
                                label: "金额",
                                width: "180",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              scope.row.type < 0
                                                ? scope.row.amount
                                                : _vm.detail.coupon_amount
                                            )
                                          ),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1566581663
                              ),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.detail.shop_coupon.length > 0
                ? _c("el-form-item", { attrs: { label: "商家优惠：" } }, [
                    _c(
                      "div",
                      [
                        _c(
                          "el-table",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              data: _vm.detail.shop_coupon,
                              border: "",
                              size: "mini",
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                prop: "name",
                                label: "名称",
                                width: "180",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "el-table-column",
                              {
                                attrs: {
                                  prop: "amount",
                                  label: "金额",
                                  width: "180",
                                },
                              },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.detail.shop_coupon_amount)),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("el-form-item"),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "用户实付:" } }, [
                _c("span", [_vm._v(_vm._s(_vm.detail.amount) + "元")]),
              ]),
              _vm._v(" "),
              _c("el-form-item"),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "飞火佣金:" } }, [
                _c("span", [_vm._v(_vm._s(_vm.detail.commission) + "元")]),
              ]),
              _vm._v(" "),
              _c("el-form-item"),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "商家应收:" } }, [
                _c("span", [_vm._v(_vm._s(_vm.detail.store_amount) + "元")]),
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "用户ID:" } }, [
                _c("span", [_vm._v(_vm._s(_vm.detail.user_id))]),
              ]),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }