var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container calendar-list-container work-order-page" },
    [
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.typeHandler },
          model: {
            value: _vm.tabName,
            callback: function ($$v) {
              _vm.tabName = $$v
            },
            expression: "tabName",
          },
        },
        [
          _c("el-tab-pane", {
            attrs: { label: "待处理", name: "to_be_process" },
          }),
          _vm._v(" "),
          _c("el-tab-pane", {
            attrs: { label: "全部工单", name: "all", lazy: "" },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "filter-container filter-container-flex" }, [
        _c(
          "div",
          [
            _c("el-input", {
              staticClass: "filter-item",
              staticStyle: { width: "190px" },
              attrs: { placeholder: "工单ID" },
              model: {
                value: _vm.listQuery.order_no,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "order_no", $$v)
                },
                expression: "listQuery.order_no",
              },
            }),
            _vm._v(" "),
            _c(
              "el-select",
              {
                staticClass: "filter-item",
                attrs: { filterable: "", clearable: "", placeholder: "酒店" },
                model: {
                  value: _vm.listQuery.hotel_id,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery, "hotel_id", $$v)
                  },
                  expression: "listQuery.hotel_id",
                },
              },
              _vm._l(_vm.hotelList, function (item) {
                return _c("el-option", {
                  key: item.hotel_id,
                  attrs: { label: item.name, value: item.hotel_id },
                })
              }),
              1
            ),
            _vm._v(" "),
            _c(
              "el-select",
              {
                staticClass: "filter-item",
                staticStyle: { width: "140px" },
                attrs: { placeholder: "工单级别", clearable: "" },
                model: {
                  value: _vm.listQuery.level,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery, "level", $$v)
                  },
                  expression: "listQuery.level",
                },
              },
              [
                _c("el-option", { attrs: { value: "", label: "全部级别" } }),
                _vm._v(" "),
                _vm._l(_vm.templateLevels, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "el-select",
              {
                staticClass: "filter-item",
                staticStyle: { width: "140px" },
                attrs: { placeholder: "工单类型", clearable: "" },
                model: {
                  value: _vm.listQuery.type,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery, "type", $$v)
                  },
                  expression: "listQuery.type",
                },
              },
              [
                _c("el-option", {
                  attrs: { value: "", label: "全部工单类型" },
                }),
                _vm._v(" "),
                _vm._l(_vm.templateTypes, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
              ],
              2
            ),
            _vm._v(" "),
            _vm.listQuery.status_tag == "all"
              ? _c(
                  "el-select",
                  {
                    staticClass: "filter-item",
                    staticStyle: { width: "140px" },
                    attrs: { placeholder: "处理结果", clearable: "" },
                    model: {
                      value: _vm.listQuery.feedback_status,
                      callback: function ($$v) {
                        _vm.$set(_vm.listQuery, "feedback_status", $$v)
                      },
                      expression: "listQuery.feedback_status",
                    },
                  },
                  [
                    _c("el-option", {
                      attrs: { value: "", label: "全部处理结果" },
                    }),
                    _vm._v(" "),
                    _vm._l(_vm.feedbackStatus, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _c("el-date-picker", {
              staticClass: "filter-item",
              staticStyle: { width: "250px" },
              attrs: {
                type: "daterange",
                "start-placeholder": "下发时间",
                "end-placeholder": "下发时间",
                "value-format": "yyyy-MM-dd",
                align: "right",
              },
              model: {
                value: _vm.listQuery.start_date,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "start_date", $$v)
                },
                expression: "listQuery.start_date",
              },
            }),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "filter-item",
                attrs: { type: "primary" },
                on: { click: _vm.handleFilter },
              },
              [_vm._v("\n        搜索\n      ")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              { staticClass: "filter-item", on: { click: _vm.handleReset } },
              [_vm._v("\n        重置\n      ")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "filter-item",
                attrs: { type: "primary" },
                on: { click: _vm.exportHandler },
              },
              [_vm._v("\n        导出\n      ")]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.orders,
            "element-loading-text": "给我一点时间",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "工单ID", width: "175" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.order_no))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "工单内容" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.title))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "级别", width: "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.level_name))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "状态", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.process_status == 10
                      ? _c("span", [
                          _c("div", { staticClass: "dot-item dot-info" }),
                          _vm._v(_vm._s(scope.row.process_status_name)),
                        ])
                      : scope.row.process_status == 20
                      ? _c("span", [
                          _c("div", { staticClass: "dot-item dot-success" }),
                          _vm._v(_vm._s(scope.row.process_status_name)),
                        ])
                      : scope.row.process_status == 21
                      ? _c("span", [
                          _c("div", { staticClass: "dot-item dot-danger" }),
                          _vm._v(_vm._s(scope.row.process_status_name)),
                        ])
                      : scope.row.process_status == 22
                      ? _c("span", [
                          _c("div", { staticClass: "dot-item dot-warning" }),
                          _vm._v(_vm._s(scope.row.process_status_name)),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "处理结果", width: "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.feedback_status == 10
                      ? _c("span", { staticStyle: { color: "#faad14" } }, [
                          _vm._v(_vm._s(scope.row.feedback_status_name)),
                        ])
                      : _c("span", [
                          _vm._v(_vm._s(scope.row.feedback_status_name)),
                        ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "所属酒店", width: "180" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.hotel_name))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "负责人", width: "90" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.owner))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "下发时间", width: "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", [_vm._v(_vm._s(scope.row.start_date))]),
                    _vm._v(" "),
                    _c("div", [_vm._v(_vm._s(scope.row.start_time))]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "截止时间", width: "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", [_vm._v(_vm._s(scope.row.end_date))]),
                    _vm._v(" "),
                    _c("div", [_vm._v(_vm._s(scope.row.end_time))]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              fixed: "right",
              align: "center",
              label: "操作",
              width: "120",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.detailHandler(scope.row)
                          },
                        },
                      },
                      [_vm._v("\n          查看\n        ")]
                    ),
                    _vm._v(" "),
                    scope.row.processable
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.processHandler(scope.row)
                              },
                            },
                          },
                          [_vm._v("\n          处理\n        ")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "pagination-container text-right" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              total: _vm.pagination.total,
              "current-page": _vm.listQuery.page,
              "page-sizes": [10, 20, 50],
              "page-size": _vm.listQuery.limit,
              layout: "total, sizes, prev, pager, next, jumper",
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "work-order-dialog",
          attrs: {
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
            "show-close": false,
            width: "680px",
            top: "10vh",
          },
          on: { close: _vm.processFormCloseHanlder },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.detailLoading,
                  expression: "detailLoading",
                },
              ],
            },
            [
              _c("div", { staticClass: "item-container" }, [
                _c("div", { staticClass: "item-title" }, [_vm._v("工单详情")]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "item-content",
                    staticStyle: { "margin-left": "25px" },
                  },
                  [
                    _c(
                      "el-row",
                      {
                        staticClass: "work-order-detail",
                        attrs: { gutter: 20 },
                      },
                      [
                        _c("el-col", { attrs: { span: 12 } }, [
                          _vm._v("工单ID：" + _vm._s(_vm.orderDetail.order_no)),
                        ]),
                        _vm._v(" "),
                        _c("el-col", { attrs: { span: 12 } }, [
                          _vm._v("酒店：" + _vm._s(_vm.orderDetail.hotel_name)),
                        ]),
                        _vm._v(" "),
                        _c("el-col", { attrs: { span: 12 } }, [
                          _vm._v(
                            "时限：" +
                              _vm._s(
                                _vm.getOrderDetailTimeRange(_vm.orderDetail)
                              )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("el-col", { attrs: { span: 12 } }, [
                          _vm._v(
                            "处理时间：" +
                              _vm._s(_vm.orderDetail.process_time || "--")
                          ),
                        ]),
                        _vm._v(" "),
                        _c("el-col", { attrs: { span: 12 } }, [
                          _vm._v(
                            "级别：" + _vm._s(_vm.orderDetail.level_name) + "级"
                          ),
                        ]),
                        _vm._v(" "),
                        _c("el-col", { attrs: { span: 12 } }, [
                          _vm._v("负责人：" + _vm._s(_vm.orderDetail.owner)),
                        ]),
                        _vm._v(" "),
                        _c("el-col", { attrs: { span: 24 } }, [
                          _vm._v(
                            "状态：" +
                              _vm._s(_vm.orderDetail.process_status_name)
                          ),
                        ]),
                        _vm._v(" "),
                        _c("el-col", { attrs: { span: 24 } }, [
                          _vm._v("工单内容：" + _vm._s(_vm.orderDetail.title)),
                        ]),
                        _vm._v(" "),
                        _vm.orderDetail.process_status == 22
                          ? _c("el-col", { attrs: { span: 24 } }, [
                              _vm._v(
                                "问题描述：" +
                                  _vm._s(_vm.orderDetail.process_desc)
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "item-container" }, [
                _c("div", { staticClass: "item-title" }, [_vm._v("工单记录")]),
                _vm._v(" "),
                _c("div", { staticClass: "item-content" }, [
                  _c(
                    "div",
                    { staticClass: "detail-table" },
                    [
                      _c(
                        "el-table",
                        {
                          attrs: {
                            "row-key": "id",
                            data: _vm.orderDetail.logs,
                            border: "",
                            fit: "",
                            size: "small",
                            "header-row-class-name": "detail-table-header",
                            "highlight-current-row": "",
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "时间",
                              width: "160",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("span", [
                                      _vm._v(_vm._s(scope.row.created_at)),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              align: "left",
                              label: "处理记录",
                              "class-name": "overflow-on",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(scope.row.log) +
                                        "\n                "
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _vm.orderActionProcess
                ? _c(
                    "div",
                    { staticClass: "item-container" },
                    [
                      _c("div", { staticClass: "item-title" }, [
                        _vm._v("工单处理"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-form",
                        {
                          ref: "processForm",
                          attrs: {
                            model: _vm.processForm,
                            rules: _vm.processFormRules,
                            "label-suffix": ":",
                            "label-width": "90px",
                            "label-position": "right",
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "处理方式",
                                prop: "process_type",
                              },
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  on: { change: _vm.onProcessTypeChange },
                                  model: {
                                    value: _vm.processForm.process_type,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.processForm,
                                        "process_type",
                                        $$v
                                      )
                                    },
                                    expression: "processForm.process_type",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio",
                                    { attrs: { label: "close" } },
                                    [_vm._v("直接处理")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-radio",
                                    { attrs: { label: "report" } },
                                    [_vm._v("向上汇报")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.processForm.process_type == "close"
                            ? _c(
                                "el-form-item",
                                {
                                  ref: "processFormFeedbackDesc",
                                  attrs: {
                                    label: "处理意见",
                                    prop: "feedback_desc",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "500px" },
                                    attrs: {
                                      type: "textarea",
                                      rows: 2,
                                      placeholder: "请输入处理意见",
                                    },
                                    model: {
                                      value: _vm.processForm.feedback_desc,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.processForm,
                                          "feedback_desc",
                                          $$v
                                        )
                                      },
                                      expression: "processForm.feedback_desc",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.processForm.process_type == "report"
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "备注说明",
                                    prop: "report_desc",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "500px" },
                                    attrs: {
                                      type: "textarea",
                                      rows: 2,
                                      placeholder: "请输入备注说明",
                                    },
                                    model: {
                                      value: _vm.processForm.report_desc,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.processForm,
                                          "report_desc",
                                          $$v
                                        )
                                      },
                                      expression: "processForm.report_desc",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _vm.orderActionProcess
                ? [
                    _c(
                      "el-button",
                      {
                        on: {
                          click: function ($event) {
                            _vm.dialogVisible = false
                          },
                        },
                      },
                      [_vm._v("取 消")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          loading: _vm.processFormSubmitLoading,
                        },
                        on: { click: _vm.processFormSubmitHandler },
                      },
                      [_vm._v("确定")]
                    ),
                  ]
                : _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.dialogVisible = false
                        },
                      },
                    },
                    [_vm._v("确定")]
                  ),
            ],
            2
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }