"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.number.constructor");
var _net_bar = require("@/api/net_bar");
var _mixin = _interopRequireDefault(require("@/utils/mixin"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: "NetBarDeviceEditForm",
  mixins: [_mixin.default],
  components: {},
  props: {
    id: [Number, String],
    storeId: {
      type: [String, Number]
    },
    visible: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    }
  },
  watch: {
    visible: function visible() {
      var _this = this;
      this.dialogVisible = this.visible;
      if (this.visible) {
        (0, _net_bar.fetchStoreDevicePriceSimpleList)(this.storeId).then(function (response) {
          _this.devicePrices = response.data;
        });
        (0, _net_bar.getDeviceConfigurationList)().then(function (response) {
          _this.deviceConfigurations = response.data;
        });
      }
    },
    id: function id() {
      if (this.id) {
        this.getDetail();
      }
    }
  },
  data: function data() {
    return {
      devicePrices: [],
      deviceConfigurations: [],
      dialogVisible: false,
      formLoading: false,
      submitLoading: false,
      deviceForm: {
        id: undefined,
        name: undefined,
        code: undefined,
        graphic_card: undefined,
        display: undefined,
        cpu: undefined,
        device_price_id: undefined
      },
      deviceFormRules: {
        name: [{
          required: true,
          message: '请输入门店设备编号',
          trigger: 'change'
        }],
        code: [{
          required: true,
          message: '请输入设备编号',
          trigger: 'change'
        }],
        graphic_card: [{
          required: true,
          message: '请选择显卡',
          trigger: 'change'
        }],
        display: [{
          required: true,
          message: '请选择显示器',
          trigger: 'change'
        }],
        cpu: [{
          required: true,
          message: '请选择CPU',
          trigger: 'change'
        }],
        ram: [{
          required: true,
          message: '请选择内存',
          trigger: 'change'
        }],
        device_price_id: [{
          required: !this.priceEditDisabled(),
          message: '请选择计费规则',
          trigger: 'change'
        }]
      }
    };
  },
  mounted: function mounted() {},
  methods: {
    getDetail: function getDetail() {
      var _this2 = this;
      this.formLoading = true;
      (0, _net_bar.fetchStoreDevice)(this.storeId, this.id).then(function (result) {
        var device = result.data;
        _this2.deviceForm = {
          id: device.id,
          name: device.name,
          code: device.code,
          graphic_card: device.graphic_card,
          display: device.display,
          cpu: device.cpu,
          ram: device.ram,
          device_price_id: device.device_price_id || undefined
        };
        _this2.formLoading = false;
      });
    },
    closeHanlder: function closeHanlder() {
      this.deviceForm.id = undefined;
      this.$refs['deviceForm'].resetFields();
      this.$emit('update:id', "");
      this.$emit('update:visible', false);
    },
    submitHandler: function submitHandler() {
      var _this3 = this;
      this.$refs['deviceForm'].validate(function (valid) {
        if (valid) {
          _this3.doUpadte();
        }
      });
    },
    doUpadte: function doUpadte() {
      var _this4 = this;
      this.submitLoading = true;
      (0, _net_bar.updateStoreDevice)(this.storeId, this.deviceForm.id, this.deviceForm).then(function () {
        _this4.$message.success("操作成功");
        _this4.$emit('success');
        _this4.dialogVisible = false;
      }).finally(function () {
        _this4.submitLoading = false;
      });
    },
    basicEditDisabled: function basicEditDisabled() {
      return this.isRoleBill();
    },
    priceEditDisabled: function priceEditDisabled() {
      return !this.isRoleAdmin() && !this.isRoleBill();
    }
  }
};
exports.default = _default2;