"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _net_bar = require("@/api/net_bar");
var _form = _interopRequireDefault(require("./form.vue"));
var _update_record = _interopRequireDefault(require("./update_record.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "NetBarDevicePrice",
  components: {
    NetBarDevicePriceForm: _form.default,
    NetBarDevicePriceLog: _update_record.default
  },
  props: {
    storeId: {
      type: [String, Number]
    }
  },
  data: function data() {
    return {
      list: [],
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 20,
        title: ""
      },
      pagination: "",
      devicePriceId: "",
      devicePriceFormVisible: false,
      devicePriceUpdateRecordVisible: false
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      (0, _net_bar.fetchStoreDevicePriceList)(this.storeId, this.listQuery).then(function (response) {
        var data = response.data;
        _this.list = data.data;
        _this.pagination = data;
        _this.listQuery.page = data.current_page;
        _this.listLoading = false;
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    addHandler: function addHandler() {
      this.devicePriceId = "";
      this.devicePriceFormVisible = true;
    },
    editHandler: function editHandler(record) {
      this.devicePriceId = record.id;
      this.devicePriceFormVisible = true;
    },
    updateRecordHandler: function updateRecordHandler(record) {
      this.devicePriceId = record.id;
      this.devicePriceUpdateRecordVisible = true;
    },
    statusChangeHandler: function statusChangeHandler(record) {
      var _this2 = this;
      (0, _net_bar.updateStoreDevicePriceStatus)(this.storeId, record.id, record.status ? 0 : 1).then(function () {
        _this2.getList();
        _this2.$message.success("操作成功");
      });
    }
  }
};
exports.default = _default;