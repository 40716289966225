var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "order-dialog",
          attrs: {
            visible: _vm.dialogVisible,
            title: "订单详情",
            width: "700px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            close: _vm.detailCloseHanlder,
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.detailLoading,
                  expression: "detailLoading",
                },
              ],
              staticClass: "order-container",
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c("el-col", { attrs: { span: 12 } }, [
                    _vm._v("订单编号：" + _vm._s(_vm.orderDetail.order_no)),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _vm._v("订单状态：" + _vm._s(_vm.orderDetail.status_name)),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _vm._v("消费门店：" + _vm._s(_vm.orderDetail.nb_name)),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _vm._v(
                      "订单模式：" + _vm._s(_vm.orderDetail.charge_mode_name)
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _vm._v(
                      "门店设备编号：" +
                        _vm._s(_vm.orderDetail.device_name) +
                        "（" +
                        _vm._s(_vm.orderDetail.device_code) +
                        "）"
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _vm._v("用户手机：" + _vm._s(_vm.orderDetail.user_mobile)),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _vm._v("开机时间：" + _vm._s(_vm.orderDetail.started_at)),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _vm._v(
                      "下机时间：" + _vm._s(_vm.orderDetail.ended_at || "--")
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c("el-col", { attrs: { span: 12 } }, [
                    _vm._v(
                      "使用时长：" + _vm._s(_vm.getUseTime(_vm.orderDetail))
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _vm._v(
                      "订单金额：" +
                        _vm._s(_vm.getAmountDetail(_vm.orderDetail))
                    ),
                  ]),
                  _vm._v(" "),
                  [3, 4].indexOf(_vm.orderDetail.charge_mode) >= 0
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("span", [
                            _vm._v(
                              "是否换机：" +
                                _vm._s(_vm.orderDetail.is_migrate ? "是" : "否")
                            ),
                          ]),
                          _vm._v(" "),
                          _vm.orderDetail.is_migrate
                            ? _c(
                                "el-link",
                                {
                                  staticStyle: { "vertical-align": "baseline" },
                                  attrs: { type: "primary", underline: false },
                                  on: { click: _vm.migrateHandler },
                                },
                                [_vm._v("(换机记录)")]
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("NetBarOrderMigrateDetail", {
        attrs: { id: _vm.orderId, visible: _vm.orderMigrateVisible },
        on: {
          "update:id": function ($event) {
            _vm.orderId = $event
          },
          "update:visible": function ($event) {
            _vm.orderMigrateVisible = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }