"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.number.constructor");
var _hotel = require("@/api/hotel");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'HotelEmployeeDetail',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    hotelId: {
      type: [String, Number]
    }
  },
  watch: {
    visible: function visible() {
      this.detailVisible = this.visible;
      if (this.visible) {
        this.getDetail();
      }
    },
    hotelId: function hotelId() {}
  },
  computed: {
    employees: function employees() {
      var employees = [];
      this.employeeList.forEach(function (employee) {
        if (!employee.hasDelete) {
          employees.push(employee);
        }
      });
      return employees;
    }
  },
  data: function data() {
    return {
      detailVisible: false,
      loading: true,
      employeeList: [],
      hotelEmployeeRoles: [],
      currEmpId: ""
    };
  },
  created: function created() {
    var _this = this;
    (0, _hotel.gethotelEmployeeRoles)().then(function (response) {
      _this.hotelEmployeeRoles = response.data;
    });
  },
  methods: {
    getDetail: function getDetail() {
      var _this2 = this;
      this.loading = true;
      (0, _hotel.getHotelEmployeeList)(this.hotelId).then(function (res) {
        var employeeList = res.data;
        employeeList.forEach(function (employee) {
          var roleIds = employee.roles.map(function (role) {
            return role.id;
          });
          employee.baseRoleIds = JSON.parse(JSON.stringify(roleIds));
          employee.roleIds = JSON.parse(JSON.stringify(roleIds));
          employee.isEdit = false;
          employee.hasDelete = false;
        });
        _this2.employeeList = employeeList;
        _this2.loading = false;
      });
    },
    roleChangeHandler: function roleChangeHandler(record) {
      var _this3 = this;
      this.currEmpId = record.id;
      record.isEdit = true;
      this.$nextTick(function () {
        _this3.$refs["EmployeeRoleSelection_".concat(record.id)].focus();
      });
    },
    onEmployeeRoleVisibleChange: function onEmployeeRoleVisibleChange(flag) {
      var _this4 = this;
      if (!flag) {
        this.employeeList.forEach(function (employee) {
          if (employee.id == _this4.currEmpId) {
            var roles = [];
            _this4.hotelEmployeeRoles.forEach(function (employeeRole) {
              if (employee.roleIds.indexOf(employeeRole.id) >= 0) {
                roles.push(employeeRole);
              }
            });
            employee.roles = roles;
            employee.isEdit = false;
            // 是否发生变更
            if (employee.baseRoleIds.length != employee.roleIds.length) {
              employee.hasChange = 'update';
            } else {
              var inertsectRoleIds = [];
              employee.baseRoleIds.forEach(function (roleId) {
                if (employee.roleIds.indexOf(roleId) >= 0) {
                  inertsectRoleIds.push(roleId);
                }
              });
              if (employee.baseRoleIds.length != inertsectRoleIds.length) {
                employee.hasChange = 'update';
              } else {
                employee.hasChange = undefined;
              }
            }
          }
        });
        this.currEmpId = "";
      }
    },
    deleteHandler: function deleteHandler(record) {
      record.hasDelete = true;
      record.hasChange = 'delete';
      // deleteHotelEmployee(this.hotelId, id)
      //   .then(res => {
      //     this.$message.success("操作成功")
      //     this.getDetail()
      //   })
    },
    resetDetail: function resetDetail() {
      this.employeeList = [];
    },
    closeHandler: function closeHandler() {
      var _this5 = this;
      var changeList = [];
      for (var i = 0; i < this.employeeList.length; i++) {
        var employee = this.employeeList[i];
        if (employee.hasChange) {
          if (employee.hasChange == 'update' && !employee.roleIds.length) {
            this.$message.error("\u8BF7\u914D\u7F6E\u5458\u5DE5\u3010".concat(employee.remark || employee.nickname, "\u3011\u89D2\u8272\u4FE1\u606F"));
            return;
          }
          changeList.push({
            id: employee.id,
            role_ids: employee.roleIds,
            delete: employee.hasDelete ? 1 : 0
          });
        }
      }
      // 存在变更
      if (changeList.length) {
        this.loading = true;
        (0, _hotel.batchUpdateHotelEmployee)(this.hotelId, changeList).then(function (res) {
          _this5.$message.success("操作成功");
          _this5.doClose();
        }).finally(function () {
          _this5.loading = false;
        });
      } else {
        this.doClose();
      }
    },
    doClose: function doClose() {
      this.resetDetail();
      this.detailVisible = false;
      this.$emit("update:visible", this.detailVisible);
    }
  }
};
exports.default = _default;