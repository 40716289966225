var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "order-review-container" },
    [
      _c(
        "el-button",
        { staticClass: "filter-item", on: { click: _vm.reviewHandler } },
        [_vm._v("\n    订单复核\n  ")]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "order-review-dialog",
          attrs: {
            visible: _vm.dialogVisible,
            title: "订单复核",
            "close-on-click-modal": false,
            width: "480px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            close: _vm.reviewCloseHanlder,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "reviewForm",
              attrs: {
                model: _vm.reviewForm,
                "label-suffix": ":",
                "label-width": "120px",
                "label-position": "right",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "第三方订单复核" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", loading: _vm.reviewLoading },
                      on: { click: _vm.startReviewHander },
                    },
                    [
                      _vm.reviewCmpStatus == _vm.cmpStart
                        ? _c("span", [_vm._v("开始复核")])
                        : _vm.reviewCmpStatus == _vm.cmping
                        ? _c("span", [_vm._v("订单复核中")])
                        : _vm.reviewCmpStatus == _vm.cmpCompleted
                        ? _c("span", [_vm._v("再次复核订单")])
                        : _vm._e(),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "review-tips" }, [
                    _vm.reviewCmpStatus == _vm.cmping
                      ? _c("span", [_vm._v("订单复核需要一定时间，请耐心等待")])
                      : _vm.reviewCmpStatus == _vm.cmpCompleted
                      ? _c("span", [
                          _vm._v("复核完成！发现【异常订单】"),
                          _c("span", { staticClass: "error-num" }, [
                            _vm._v(_vm._s(_vm.reviewInfo.error || 0)),
                          ]),
                          _vm._v("单"),
                        ])
                      : _vm._e(),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _vm.reviewCmpStatus == _vm.cmpStart ||
              _vm.reviewCmpStatus == _vm.cmping
                ? [
                    _c(
                      "el-button",
                      {
                        on: {
                          click: function ($event) {
                            _vm.dialogVisible = false
                          },
                        },
                      },
                      [_vm._v("取 消")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      { attrs: { type: "primary", disabled: "" } },
                      [_vm._v("确认复核结果")]
                    ),
                  ]
                : _vm.reviewCmpStatus == _vm.cmpCompleted
                ? [
                    _vm.reviewInfo.error > 0
                      ? [
                          _c(
                            "el-button",
                            {
                              on: {
                                click: function ($event) {
                                  _vm.dialogVisible = false
                                },
                              },
                            },
                            [_vm._v("取 消")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.toAbnormalOrder },
                            },
                            [_vm._v("查看异常订单")]
                          ),
                        ]
                      : _c(
                          "el-button",
                          {
                            on: {
                              click: function ($event) {
                                _vm.dialogVisible = false
                              },
                            },
                          },
                          [_vm._v("复核完成，没有异常")]
                        ),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }