"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _point_shop = require("@/api/point_shop");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "PointShopGoods",
  data: function data() {
    return {
      list: [],
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 20,
        title: "",
        show: ""
      },
      pagination: "",
      showOptions: [{
        value: '',
        label: '全部'
      }, {
        value: 1,
        label: '显示'
      }, {
        value: 0,
        label: '隐藏'
      }]
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      (0, _point_shop.fetchGoodsList)(this.listQuery).then(function (response) {
        var data = response.data;
        _this.list = data.data;
        _this.pagination = data;
        _this.listQuery.page = data.current_page;
        _this.listLoading = false;
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleReset: function handleReset() {
      this.listQuery.title = "";
      this.listQuery.show = "";
      this.handleFilter();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    addGoodsHandler: function addGoodsHandler() {
      this.$router.push({
        path: "/point_shop/goods/add"
      });
    },
    editGoodsHandler: function editGoodsHandler(id) {
      this.$router.push({
        path: "/point_shop/goods/edit/" + id
      });
    },
    isShowChangeHandler: function isShowChangeHandler(id) {
      var _this2 = this;
      (0, _point_shop.updateGoodsShow)(id).then(function (response) {
        _this2.getList();
      });
    },
    parseToString: function parseToString(data, key) {
      var vals = [];
      data.forEach(function (item) {
        vals.push(item[key]);
      });
      return vals.join(",");
    }
  }
};
exports.default = _default;