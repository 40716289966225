"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _hotel_order = _interopRequireDefault(require("./components/hotel_order"));
var _lock_record = _interopRequireDefault(require("./components/lock_record"));
var _coupon = _interopRequireDefault(require("./components/coupon"));
var _net_bar_order = _interopRequireDefault(require("./components/net_bar_order"));
var _net_bar_stv = _interopRequireDefault(require("./components/net_bar_stv"));
var _member = require("@/api/member");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "MemberDetail",
  components: {
    MemberHotelOrder: _hotel_order.default,
    MemberLockRecord: _lock_record.default,
    MemberCoupon: _coupon.default,
    MemberNetBarOrder: _net_bar_order.default,
    MemberNetBarStv: _net_bar_stv.default
  },
  data: function data() {
    return {
      user_id: 0,
      info: {},
      activeName: 'hotel_order'
    };
  },
  created: function created() {
    var _this = this;
    this.user_id = this.$route.params.id;
    (0, _member.detail)(this.user_id).then(function (response) {
      _this.info = response.data;
    });
  },
  methods: {
    handleClick: function handleClick(tab, event) {}
  }
};
exports.default = _default;