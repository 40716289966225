var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container point-goods-container" },
    [
      _c("div", { staticClass: "filter-container filter-container-flex" }, [
        _c(
          "div",
          [
            _c("el-input", {
              staticClass: "filter-item",
              staticStyle: { width: "200px" },
              attrs: { placeholder: "商品名称" },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.handleFilter($event)
                },
              },
              model: {
                value: _vm.listQuery.title,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "title", $$v)
                },
                expression: "listQuery.title",
              },
            }),
            _vm._v(" "),
            _c(
              "el-select",
              {
                staticClass: "filter-item",
                attrs: {
                  "default-first-option": "",
                  placeholder: "状态",
                  clearable: "",
                },
                model: {
                  value: _vm.listQuery.show,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery, "show", $$v)
                  },
                  expression: "listQuery.show",
                },
              },
              _vm._l(_vm.showOptions, function (item) {
                return _c("el-option", {
                  key: item.value,
                  attrs: { label: item.label, value: item.value },
                })
              }),
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c(
              "el-button",
              {
                staticClass: "filter-item",
                attrs: { type: "primary" },
                on: { click: _vm.handleFilter },
              },
              [_vm._v("\n        搜索\n      ")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              { staticClass: "filter-item", on: { click: _vm.handleReset } },
              [_vm._v("\n        重置\n      ")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "filter-item",
                staticStyle: { "margin-left": "10px" },
                on: {
                  click: function ($event) {
                    return _vm.addGoodsHandler()
                  },
                },
              },
              [_vm._v("\n        新增\n      ")]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          key: "tableList",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            "element-loading-text": "给我一点时间",
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "商品编号",
              "class-name": "overflow-on",
              width: "120",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v("\n        " + _vm._s(scope.row.id) + "\n      "),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "商品名称",
              "class-name": "overflow-on",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v("\n        " + _vm._s(scope.row.title) + "\n      "),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "分类",
              "class-name": "overflow-on",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.parseToString(scope.row.categories, "title")
                        ) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "缩略图", width: "210" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("img", {
                      staticStyle: { width: "150px" },
                      attrs: { src: scope.row.thumbnail },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "类别",
              "class-name": "overflow-on",
              width: "120",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " + _vm._s(scope.row.type_name) + "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "排序",
              "class-name": "overflow-on",
              width: "120",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v("\n        " + _vm._s(scope.row.sort) + "\n      "),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "提货酒店",
              "class-name": "overflow-on",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.pickup_hotels_count) +
                        "家\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "是否推荐",
              "class-name": "overflow-on",
              width: "120",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(scope.row.is_recommend ? "是" : "否")),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "状态",
              "class-name": "overflow-on",
              width: "120",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(scope.row.is_show ? "显示" : "隐藏")),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              fixed: "right",
              label: "操作",
              width: "120",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.editGoodsHandler(scope.row.id)
                          },
                        },
                      },
                      [_vm._v("\n          编辑\n        ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-popconfirm",
                      {
                        attrs: {
                          title:
                            "是否" +
                            (scope.row.is_show ? "下架" : "上架") +
                            "该商品？",
                        },
                        on: {
                          confirm: function ($event) {
                            return _vm.isShowChangeHandler(scope.row.id)
                          },
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { slot: "reference", type: "text" },
                            slot: "reference",
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(scope.row.is_show ? "下架" : "上架") +
                                "\n          "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "pagination-container text-right" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              total: _vm.pagination.total,
              "current-page": _vm.listQuery.page,
              "page-sizes": [10, 20, 50],
              "page-size": _vm.listQuery.limit,
              layout: "total, sizes, prev, pager, next, jumper",
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }