"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _mixin = _interopRequireDefault(require("@/utils/mixin"));
var _net_bar = require("@/api/net_bar");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cmpStart = 'start_cmp';
var cmping = 'in_cmp';
var cmpCompleted = 'completed_cmp';
var _default = {
  name: "NetBarFinanceOrderReviewButton",
  mixins: [_mixin.default],
  props: {},
  watch: {},
  data: function data() {
    return {
      dialogVisible: false,
      reviewLoading: false,
      reviewInfo: {
        error: 0
      },
      cmpStart: cmpStart,
      cmping: cmping,
      cmpCompleted: cmpCompleted,
      reviewCmpStatus: cmpStart
    };
  },
  mounted: function mounted() {},
  methods: {
    reviewHandler: function reviewHandler() {
      this.dialogVisible = true;
      this.reviewCmpStatus = cmpStart;
    },
    startReviewHander: function startReviewHander() {
      var _this = this;
      this.reviewLoading = true;
      this.reviewCmpStatus = cmping;
      // 发起复核任务
      (0, _net_bar.financeOrderReview)().then(function () {
        _this.getReviewStatus();
      }).catch(function () {
        _this.reviewCmpStatus = cmpStart;
        _this.reviewLoading = false;
      });
    },
    getReviewStatus: function getReviewStatus() {
      var _this2 = this;
      // 复核状态查询
      setTimeout(function () {
        (0, _net_bar.getFinanceOrderReviewSummary)().then(function (response) {
          // 弹窗关闭，或账期变更，不处理
          if (!_this2.dialogVisible) {
            return;
          }
          if (response.data.building) {
            _this2.getReviewStatus();
            return;
          }
          _this2.reviewCmpStatus = cmpCompleted;
          _this2.reviewInfo = response.data.comparison_info;
          _this2.reviewLoading = false;
          _this2.$emit('success');
        });
      }, 3000);
    },
    toAbnormalOrder: function toAbnormalOrder() {
      this.$router.push("/net_bar/finance_order?tab_name=abnormal");
      this.dialogVisible = false;
    },
    reviewCloseHanlder: function reviewCloseHanlder() {}
  }
};
exports.default = _default;