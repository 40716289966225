var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container calendar-list-container" },
    [
      _c("div", { staticClass: "filter-container filter-container-flex" }, [
        _c(
          "div",
          [
            _c(
              "el-button",
              {
                staticClass: "filter-item",
                attrs: { type: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.addHandler()
                  },
                },
              },
              [_vm._v("\n      新建角色\n      ")]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.roles,
            "element-loading-text": "给我一点时间",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "编号" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.id))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "角色名" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.name))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "账号个数" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [_vm._v(_vm._s(scope.row.accounts_count || 0))]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "关联模板个数" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(scope.row.templates_count || 0)),
                    ]),
                    _vm._v(" "),
                    scope.row.templates_count
                      ? _c(
                          "el-link",
                          {
                            staticStyle: { "font-size": "12px" },
                            attrs: { type: "primary", underline: false },
                            on: {
                              click: function ($event) {
                                return _vm.toTemplatePage(scope.row)
                              },
                            },
                          },
                          [_vm._v("查看")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "添加时间", width: "180" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.created_at))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              fixed: "right",
              align: "center",
              label: "操作",
              width: "150",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    !scope.row.system
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.editHandler(scope.row)
                              },
                            },
                          },
                          [_vm._v("\n          编辑\n        ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "el-popconfirm",
                      {
                        attrs: {
                          title:
                            "你确定要" +
                            (scope.row.status ? "禁用" : "恢复") +
                            "该角色吗？",
                        },
                        on: {
                          confirm: function ($event) {
                            return _vm.statusChangeHandler(
                              scope.row.id,
                              scope.row.status ? 0 : 1
                            )
                          },
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: {
                              color: "#F56C6C",
                              "margin-left": "10px",
                            },
                            attrs: { slot: "reference", type: "text" },
                            slot: "reference",
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(scope.row.status ? "禁用" : "恢复") +
                                "\n          "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "pagination-container text-right" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              total: _vm.pagination.total,
              "current-page": _vm.listQuery.page,
              "page-sizes": [10, 20, 50],
              "page-size": _vm.listQuery.limit,
              layout: "total, sizes, prev, pager, next, jumper",
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogFormVisible,
            "close-on-click-modal": false,
            title: _vm.roleFormTitle,
            width: "580px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
            close: _vm.roleFormCloseHanlder,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "roleForm",
              attrs: {
                model: _vm.roleForm,
                rules: _vm.roleFormRules,
                "label-suffix": ":",
                "label-width": "85px",
                "label-position": "right",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "角色名", prop: "name" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "400px" },
                    attrs: { placeholder: "请输入角色名" },
                    model: {
                      value: _vm.roleForm.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.roleForm, "name", $$v)
                      },
                      expression: "roleForm.name",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticStyle: { "text-align": "center" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    loading: _vm.roleFormConfirmLoading,
                  },
                  on: { click: _vm.roleFormSubmitHandler },
                },
                [_vm._v("\n        确 定\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }