"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _mixin = _interopRequireDefault(require("@/utils/mixin"));
var _moment = _interopRequireDefault(require("moment"));
var _AdjustOrderTable = _interopRequireDefault(require("./AdjustOrderTable.vue"));
var _ThirdOrderImport = _interopRequireDefault(require("./ThirdOrderImport.vue"));
var _hotel = require("@/api/hotel");
var _finance_reco = require("@/api/finance_reco");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "FinanceMeituanAdjust",
  mixins: [_mixin.default],
  components: {
    AdjustOrderTable: _AdjustOrderTable.default,
    ThirdOrderImport: _ThirdOrderImport.default
  },
  data: function data() {
    return {
      thirdId: 1,
      hotelList: [],
      thirdChannel: [{
        id: 3,
        title: "美团（调整订单）"
      }],
      financeRange: [],
      allowAction: {
        allowImport: false,
        allowModify: false,
        allowDelete: false
      },
      // 列表及查询
      listQuery: {
        page: 1,
        limit: 20,
        bill_week: "",
        order_no: "",
        end_date: "",
        hotel_id: ""
      },
      pagination: {},
      listLoading: false,
      orders: [],
      summary: {},
      // 导入
      importVisible: false
    };
  },
  created: function created() {
    var _this = this;
    if (this.$route.query.hotel_id) {
      this.listQuery.hotel_id = parseInt(this.$route.query.hotel_id);
    }
    if (this.$route.query.hotel_mode) {
      this.listQuery.hotel_mode = parseInt(this.$route.query.hotel_mode);
    }
    if (this.$route.query.bill_week) {
      this.listQuery.bill_week = this.$route.query.bill_week;
    } else {
      var prevWeekStart = this.getWeekRange((0, _moment.default)().subtract(7, 'days').format("YYYY-MM-DD"))[0];
      this.listQuery.bill_week = (0, _moment.default)(prevWeekStart).format("YYYYMMDD");
    }
    (0, _hotel.getHotelSimpleList)().then(function (response) {
      _this.hotelList = response.data;
    });
    (0, _finance_reco.getFinanceRange)().then(function (response) {
      var data = response.data;
      _this.financeRange = [data.date.start, data.date.end];
      _this.allowAction.allowImport = data.allow_import;
      _this.allowAction.allowModify = data.allow_modify;
      _this.allowAction.allowDelete = data.allow_delete;
    });
    this.getList();
  },
  methods: {
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    getList: function getList() {
      var _this2 = this;
      this.listLoading = true;
      (0, _finance_reco.getMeiTtuanAdjustList)(this.listQuery).then(function (response) {
        var data = response.data;
        _this2.pagination = {
          total: data.total,
          currentPage: data.current_page,
          pageSize: data.per_page
        };
        _this2.orders = data.data;
        _this2.summary = data.summary;
        _this2.listQuery.page = data.current_page;
        _this2.listLoading = false;
      });
    },
    importHandler: function importHandler() {
      this.importVisible = true;
    },
    exportHandler: function exportHandler() {
      window.open((0, _finance_reco.getMeiTtuanAdjustDownloadUrl)(this.listQuery));
    },
    onEndDateChange: function onEndDateChange() {
      this.listQuery.bill_week = "";
    }
  }
};
exports.default = _default;