"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _net_bar = require("@/api/net_bar");
var _OrderReviewButton = _interopRequireDefault(require("./OrderReviewButton.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "NetBarFinanceAbnormalOrderNormal",
  components: {
    OrderReviewButton: _OrderReviewButton.default
  },
  data: function data() {
    return {
      storeList: [],
      list: [],
      summary: {},
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 20,
        order_no: "",
        card_id: "",
        nb_id: "",
        settlement_at: ""
      },
      pagination: ""
    };
  },
  created: function created() {
    var _this = this;
    (0, _net_bar.getSimpleList)().then(function (response) {
      _this.storeList = response.data;
    });
    this.initQuery();
    this.getList();
  },
  methods: {
    initQuery: function initQuery() {
      if (this.$route.query.nb_id) {
        this.listQuery.nb_id = Number(this.$route.query.nb_id);
      }
      if (this.$route.query.card_id) {
        this.listQuery.card_id = this.$route.query.card_id;
      }
      if (this.$route.query.order_no) {
        this.listQuery.order_no = this.$route.query.order_no;
      }
      if (this.$route.query.start_date) {
        this.listQuery.settlement_at = [this.$route.query.start_date, this.$route.query.end_date];
      }
    },
    getList: function getList() {
      var _this2 = this;
      this.listLoading = true;
      (0, _net_bar.fetchFinanceAbnormalOrderList)(this.listQuery).then(function (response) {
        var data = response.data;
        _this2.list = data.data;
        _this2.pagination = data;
        _this2.listQuery.page = data.current_page;
        _this2.listLoading = false;
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    handleReset: function handleReset() {
      this.listQuery.order_no = "";
      this.listQuery.card_id = "";
      this.listQuery.nb_id = "";
      this.listQuery.settlement_at = "";
      this.handleFilter();
    },
    exportHandler: function exportHandler() {
      window.open((0, _net_bar.getFinanceAbnormalOrderDownloadUrl)(this.listQuery));
    }
  }
};
exports.default = _default;