"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addPopup = addPopup;
exports.deletePopup = deletePopup;
exports.getPopup = getPopup;
exports.getPopupList = getPopupList;
exports.updatePopup = updatePopup;
exports.updatePopupStatus = updatePopupStatus;
var _request = _interopRequireDefault(require("@/utils/request"));
function getPopupList(query) {
  return (0, _request.default)({
    url: '/hotel_popups',
    method: 'get',
    params: query
  });
}
function getPopup(id) {
  return (0, _request.default)({
    url: "/hotel_popups/".concat(id),
    method: 'get'
  });
}
function addPopup(data) {
  return (0, _request.default)({
    url: '/hotel_popups',
    method: 'post',
    data: data
  });
}
function updatePopup(id, data) {
  return (0, _request.default)({
    url: "/hotel_popups/".concat(id),
    method: 'put',
    data: data
  });
}
function updatePopupStatus(id, status) {
  return (0, _request.default)({
    url: "/hotel_popups/".concat(id, "/status"),
    method: 'put',
    data: {
      status: status
    }
  });
}
function deletePopup(id) {
  return (0, _request.default)({
    url: "/hotel_popups/".concat(id),
    method: 'delete'
  });
}