"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _auth = require("@/utils/auth");
var _common = require("@/api/common");
var _finance_reco = require("@/api/finance_reco");
var _ButtonFile = _interopRequireDefault(require("@/components/ButtonFile"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: "FinanceOrderImport",
  components: {
    ButtonFile: _ButtonFile.default
  },
  props: {
    channel: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    thirdId: [Number, String],
    dateRange: [Array],
    visible: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    }
  },
  watch: {
    importFormFile: function importFormFile(val, oldVal) {
      this.importForm.file = val;
      this.$refs['uploadFile'].clearValidate();
    },
    visible: function visible() {
      this.dialogVisible = this.visible;
    },
    dateRange: function dateRange() {
      this.importForm.date_range = this.dateRange;
    },
    thirdId: function thirdId() {
      this.importForm.third_id = this.thirdId;
    },
    channel: function channel() {
      this.importForm.third_channel = this.channel[0].id || '';
    }
  },
  data: function data() {
    return {
      dialogVisible: false,
      header: {
        Authorization: "Bearer " + (0, _auth.getToken)()
      },
      uploadUrl: '',
      importForm: {
        third_id: "",
        third_channel: "",
        date_range: [],
        file: []
      },
      importFormFile: [],
      importFormRules: {
        third_channel: [{
          required: true,
          message: '请选择来源渠道',
          trigger: 'blur'
        }],
        date_range: [{
          required: true,
          message: '请选择对账周期',
          trigger: 'blur'
        }],
        file: [{
          required: true,
          message: '请选择文件',
          trigger: 'blur'
        }]
      },
      importFormConfirmLoading: false
    };
  },
  mounted: function mounted() {
    this.uploadUrl = (0, _common.adminUpload)();
    this.importForm.third_id = this.thirdId;
    this.importForm.third_channel = this.channel[0].id || '';
  },
  methods: {
    importCloseHanlder: function importCloseHanlder() {
      this.$refs['importForm'].resetFields();
      this.$emit('update:visible', false);
    },
    handleUploadSuccess: function handleUploadSuccess(res) {
      this.importForm.file = res.data.filepath;
      this.$refs['uploadFile'].clearValidate();
    },
    handleUploadRemove: function handleUploadRemove(file, fileList) {
      this.importForm.file = "";
    },
    downloadTemplate: function downloadTemplate() {
      window.open((0, _finance_reco.getImportTemplate)((0, _auth.getToken)(), this.importForm.third_channel));
    },
    importHandler: function importHandler() {
      var _this = this;
      this.$refs['importForm'].validate(function (valid) {
        if (valid) {
          var formData = new FormData();
          formData.append("file", _this.importForm.file[0]);
          formData.append("third_id", _this.importForm.third_id);
          formData.append("third_channel", _this.importForm.third_channel);
          formData.append("date_range", JSON.stringify(_this.importForm.date_range));
          _this.orderFormConfirmLoading = true;
          console.log(_this.importForm);
          (0, _finance_reco.importThridOrders)(formData).then(function (response) {
            _this.importCloseHanlder();
            _this.$emit('success');
          }).finally(function () {
            _this.orderFormConfirmLoading = false;
          });
        }
      });
    }
  }
};
exports.default = _default2;