"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.to-string");
var _mixin = _interopRequireDefault(require("@/utils/mixin"));
var _moment = _interopRequireDefault(require("moment"));
var _OtaOrderResyncButton = _interopRequireDefault(require("./OtaOrderResyncButton.vue"));
var _order = require("@/api/order");
var _hotel = require("@/api/hotel");
var _finance_reco = require("@/api/finance_reco");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "FinanceComparisonAbnormal",
  mixins: [_mixin.default],
  components: {
    OtaOrderResyncButton: _OtaOrderResyncButton.default
  },
  inject: ["reload"],
  filters: {
    dateFormat: function dateFormat(value) {
      if (!value) return '';
      var date = value.toString();
      return date.slice(0, 4) + '-' + date.slice(4, 6) + '-' + date.slice(6, 8);
    }
  },
  watch: {
    '$route.query.bill_week': {
      handler: function handler(billWeek) {
        if (billWeek) {
          this.listQuery.bill_week = this.$route.query.bill_week;
          this.getList();
        }
      }
    }
  },
  data: function data() {
    return {
      otas: [],
      hotelList: [],
      billExists: true,
      // 列表及查询
      listQuery: {
        page: 1,
        limit: 20,
        bill_week: "",
        order_no: "",
        third_id: "",
        start_date: "",
        end_date: "",
        hotel_id: ""
      },
      pagination: {},
      listLoading: false,
      orders: []
    };
  },
  created: function created() {
    var _this = this;
    if (this.$route.query.hotel_id) {
      this.listQuery.hotel_id = parseInt(this.$route.query.hotel_id);
    }
    if (this.$route.query.bill_week) {
      this.listQuery.bill_week = this.$route.query.bill_week;
    } else {
      var prevWeekStart = this.getWeekRange((0, _moment.default)().subtract(7, 'days').format("YYYY-MM-DD"))[0];
      this.listQuery.bill_week = (0, _moment.default)(prevWeekStart).format("YYYYMMDD");
    }
    (0, _hotel.getHotelSimpleList)().then(function (response) {
      _this.hotelList = response.data;
    });
    (0, _order.getOtaList)().then(function (response) {
      _this.otas = response.data;
    });
    this.getList();
  },
  methods: {
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    getList: function getList() {
      var _this2 = this;
      this.listLoading = true;
      (0, _finance_reco.getFinanceOrderReviews)(this.listQuery).then(function (response) {
        var data = response.data;
        _this2.pagination = {
          total: data.total,
          currentPage: data.current_page,
          pageSize: data.per_page
        };
        _this2.orders = data.data;
        _this2.listQuery.page = data.current_page;
        _this2.listLoading = false;
      });
    },
    exportHandler: function exportHandler() {
      window.open((0, _finance_reco.getFinanceOrderReviewDownloadUrl)(this.listQuery));
    },
    toBookOrder: function toBookOrder(record) {
      var path = '/order/index';
      switch (record.hotel_mode) {
        case 3:
          // 按比例分成模式
          path = '/order/permutation';
          break;
        case 5:
          // 置换模式
          path = '/order/permutation_old';
          break;
        case 6:
          // 软包房模式
          path = '/order/soft';
          break;
        case 7:
          // 直营店模式
          path = '/order/self';
          break;
      }
      this.$router.push("".concat(path, "?order_no=").concat(record.order_no));
    },
    toFinanceOrder: function toFinanceOrder(record) {
      this.$router.push("/bill/finance_order?tab_name=normal&hotel_id=".concat(record.hotel_id, "&bill_week=").concat(record.start_date, "&hotel_mode=").concat(record.hotel_mode, "&order_no=").concat(record.order_no));
      this.reload();
    }
  }
};
exports.default = _default;