"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getStatistics = getStatistics;
var _request = _interopRequireDefault(require("@/utils/request"));
function getStatistics(params) {
  return (0, _request.default)({
    url: '/statistics_hotel_sales',
    method: 'get',
    params: params
  });
}