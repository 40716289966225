"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _coupon = require("@/api/coupon");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "coupon_group",
  data: function data() {
    return {
      list: [],
      btn: false,
      dialogFormVisible: false,
      listQuery: {
        page: 1,
        limit: 20,
        title: ""
      },
      temp: {
        title: '',
        per_num: '',
        items: [{
          coupon_id: '',
          num: ''
        }]
      },
      couponList: [],
      pagination: "",
      dialogTitle: "添加",
      disabledText: false
    };
  },
  created: function created() {
    var _this = this;
    this.getList();
    (0, _coupon.couponManualList)().then(function (response) {
      _this.couponList = response.data;
    });
  },
  methods: {
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    getList: function getList() {
      var _this2 = this;
      this.listLoading = true;
      (0, _coupon.groupList)(this.listQuery).then(function (response) {
        var data = response.data;
        _this2.list = data.data;
        _this2.pagination = data;
        _this2.listQuery.page = data.current_page;
        _this2.listLoading = false;
      });
    },
    addBtn: function addBtn() {
      this.temp = {
        title: '',
        per_num: '',
        items: [{
          coupon_id: '',
          num: ''
        }]
      };
      this.dialogTitle = '添加', this.dialogFormVisible = true;
    },
    removeCoupon: function removeCoupon(item, detail) {
      var index = detail.indexOf(item);
      if (index !== -1) {
        detail.splice(index, 1);
      }
    },
    doUpdate: function doUpdate() {
      var that = this;
      for (var i = 0; i < that.temp.items.length; i++) {
        if (that.temp.items[i].num < 1) {
          that.$message.error('数量不能是小于1');
          return false;
        }
        if (that.temp.items[i].coupon_id < 1) {
          that.$message.error('请选择优惠券');
          return false;
        }
      }
      if (that.dialogTitle == '添加') {
        that.doAdd();
      } else {
        that.doEdit();
      }
    },
    addCoupon: function addCoupon() {
      this.temp.items.push({
        coupon_id: '',
        num: 1
      });
    },
    doAdd: function doAdd() {
      var _this3 = this;
      (0, _coupon.addGroupCoupon)(this.temp).then(function (res) {
        if (res.code == 0) {
          _this3.getList();
          _this3.dialogFormVisible = false;
          _this3.$message.success(res.msg);
        } else {
          _this3.$message.error(res.msg);
        }
      });
    },
    doEdit: function doEdit() {
      var _this4 = this;
      (0, _coupon.editGroupCoupon)(this.temp.id, this.temp).then(function (res) {
        if (res.code == 0) {
          _this4.getList();
          _this4.dialogFormVisible = false;
          _this4.$message.success(res.msg);
        } else {
          _this4.$message.error(res.msg);
        }
      });
    },
    editBtn: function editBtn(row) {
      this.dialogTitle = '编辑';
      this.temp = row;
      this.dialogFormVisible = true;
    }
  }
};
exports.default = _default;