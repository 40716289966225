"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _auth = require("@/utils/auth");
var _common = require("@/api/common");
var _coupon = require("@/api/coupon");
var _activity = require("@/api/activity");
var _moment = _interopRequireDefault(require("moment"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "ActivityForm",
  components: {},
  data: function data() {
    return {
      copyId: undefined,
      uploadUrl: '',
      header: {
        Authorization: "Bearer " + (0, _auth.getToken)()
      },
      prizeTypes: [{
        id: 0,
        title: "谢谢参与"
      }, {
        id: 1,
        title: "实物"
      }, {
        id: 2,
        title: "优惠券"
      }, {
        id: 3,
        title: "积分"
      }],
      prizeTypeMap: {
        0: "谢谢参与",
        1: "实物",
        2: "优惠券",
        3: "积分"
      },
      coupons: [],
      dialogPrizeFormVisible: false,
      prize: {
        title: "",
        type: "",
        stock: "",
        point: "",
        thumbnail: "",
        foreign_id: "",
        foreign_name: ""
      },
      prizeRules: {
        title: [{
          required: true,
          message: "请输入奖品名称",
          trigger: 'blur'
        }],
        type: [{
          required: true,
          message: "请选择奖品类型",
          trigger: 'blur'
        }],
        stock: [{
          required: true,
          message: "请输入库存",
          trigger: 'blur'
        }],
        point: [{
          required: true,
          message: "请输入积分",
          trigger: 'blur'
        }],
        thumbnail: [{
          required: true,
          message: "请上传奖品图",
          trigger: 'change'
        }],
        foreign_id: [{
          required: true,
          message: "请选择关联记录",
          trigger: 'blur'
        }]
      },
      data: {
        title: "",
        start_time: "",
        end_time: "",
        point: "",
        share_text: "",
        rules: "",
        share_image: "",
        prizes: [
          // {title: "奖品1",thumbnail: "http://zlfz-hotel.oss-cn-hangzhou.aliyuncs.com/production/404afea368fe4c7e6da5fb5d5846b0e0.png",stock: 100,type: 2,point: 0,foreign_id: 63,foreign_name: "xxxx"},
        ]
      },
      rules: {
        title: [{
          required: true,
          message: "请输入活动名称",
          trigger: 'change'
        }],
        start_time: [{
          type: 'date',
          required: true,
          message: "请选择开始日期",
          trigger: 'change'
        }],
        end_time: [{
          type: 'date',
          required: true,
          message: "请选择结束日期",
          trigger: 'change'
        }],
        point: [{
          required: true,
          message: "请输入消耗积分",
          trigger: 'change'
        }, {
          type: 'number',
          min: 1,
          message: '消耗积分必须大于0',
          trigger: 'change'
        }],
        rules: [{
          required: true,
          message: "请输入活动规则",
          trigger: 'change'
        }],
        prizes: [{
          required: true,
          message: "请选择配置奖品",
          trigger: 'change'
        }, {
          type: 'array',
          min: 1,
          message: '请选择配置奖品',
          trigger: 'change'
        }]
      },
      activityFormSubmitLoading: false,
      datePickerOptions: {
        disabledDate: function disabledDate(time) {
          return time.getTime() < Date.now() - 86400000;
        }
      }
    };
  },
  created: function created() {
    this.copyId = this.$route.query.copy;
    if (this.copyId) {
      this.getActivity();
    }
    this.uploadUrl = (0, _common.adminUpload)();
    this.getCoupons();
  },
  methods: {
    getActivity: function getActivity() {
      var _this = this;
      this.prizeLoading = true;
      (0, _activity.getPointLottery)(this.copyId).then(function (response) {
        var activity = response.data;
        var prizes = [];
        activity.prizes.forEach(function (item) {
          prizes.push({
            id: _this.getRandomId(),
            title: item.title,
            thumbnail: item.thumbnail,
            stock: item.stock,
            type: item.type,
            point: item.point,
            foreign_id: item.foreign_id,
            foreign_name: item.foreign_name
          });
        });
        _this.data = {
          title: activity.title,
          start_time: new Date(activity.start_time),
          end_time: new Date(activity.end_time),
          point: activity.point,
          share_text: activity.share_text,
          rules: activity.rules,
          share_image: activity.share_image,
          prizes: prizes
        };
      });
    },
    getCoupons: function getCoupons() {
      var _this2 = this;
      (0, _coupon.couponManualList)().then(function (response) {
        _this2.coupons = response.data;
      });
    },
    handleShareImageSuccess: function handleShareImageSuccess(res) {
      if (res.code == 0) {
        this.data.share_image = res.data.filepath;
        this.$refs['activityShareImage'].clearValidate();
        this.$message.success("上传成功！");
      } else {
        this.$message.error("上传失败");
      }
    },
    handlePrizeImageSuccess: function handlePrizeImageSuccess(res) {
      if (res.code == 0) {
        this.prize.thumbnail = res.data.filepath;
        this.$refs['prizeThumbnail'].clearValidate();
        this.$message.success("上传成功！");
      } else {
        this.$message.error("上传失败");
      }
    },
    onStartTimeChange: function onStartTimeChange(date) {
      // 强制格式化为 2022-01-01 xx:00:00
      var startTime = (0, _moment.default)(date).format("YYYY-MM-DD HH:00:00");
      this.data.start_time = new Date(startTime);
    },
    onEndTimeChange: function onEndTimeChange(date) {
      // 强制格式化为 2022-01-01 xx:59:59
      var endTime = (0, _moment.default)(date).format("YYYY-MM-DD HH:59:59");
      this.data.end_time = new Date(endTime);
    },
    addPrizeHandler: function addPrizeHandler() {
      if (this.data.prizes.length >= 8) {
        this.$message.error("奖品数量不能超过8个");
        return false;
      }
      this.dialogPrizeFormVisible = true;
    },
    editPrizeHandler: function editPrizeHandler(record) {
      this.prize = JSON.parse(JSON.stringify(record));
      this.dialogPrizeFormVisible = true;
    },
    deletePrizeHandler: function deletePrizeHandler(idx) {
      this.data.prizes.splice(idx, 1);
    },
    prizeTypeChangeHandler: function prizeTypeChangeHandler(v) {
      this.prize.point = "";
      this.prize.foreign_id = "";
      this.prize.foreign_name = "";
      if (v == 0) {
        this.prize.title = '谢谢参与';
        this.prize.thumbnail = 'http://zlfz-hotel.oss-cn-hangzhou.aliyuncs.com/activity/empty_prize.png'; // 待补充
      }
    },
    prizeForeignChangeHandler: function prizeForeignChangeHandler(foreignId) {
      var _this3 = this;
      if (this.prize.type == 2) {
        this.coupons.forEach(function (item) {
          if (item.id == foreignId) {
            _this3.prize.foreign_name = item.name;
          }
        });
      }
    },
    prizeFormCloseHanlder: function prizeFormCloseHanlder() {
      this.$refs['prizeForm'].resetFields();
    },
    prizeFormSubmitHandler: function prizeFormSubmitHandler() {
      var _this4 = this;
      this.$refs['prizeForm'].validate(function (valid) {
        if (!valid) {
          return false;
        }
        var prize = JSON.parse(JSON.stringify(_this4.prize));
        // 更新
        if (prize.id != undefined) {
          var idx = undefined;
          _this4.data.prizes.forEach(function (item, k) {
            if (item.id == prize.id) {
              idx = k;
            }
          });
          _this4.data.prizes.splice(idx, 1, prize);
        } else {
          prize.id = _this4.getRandomId();
          _this4.data.prizes.push(prize);
        }
        _this4.$refs['prizesTable'].clearValidate();
        _this4.dialogPrizeFormVisible = false;
      });
    },
    activityFormSubmitHandler: function activityFormSubmitHandler() {
      var _this5 = this;
      this.$refs['activityForm'].validate(function (valid) {
        if (!valid) {
          return false;
        }
        _this5.addHandler();
      });
    },
    activityFormCancel: function activityFormCancel() {
      this.$router.push('/activity/point_lottery');
    },
    addHandler: function addHandler() {
      var _this6 = this;
      this.activityFormSubmitLoading = true;
      var activityForm = JSON.parse(JSON.stringify(this.data));
      activityForm.start_time = (0, _moment.default)(activityForm.start_time).format("YYYY-MM-DD HH:00:00");
      activityForm.end_time = (0, _moment.default)(activityForm.end_time).format("YYYY-MM-DD HH:59:59");
      (0, _activity.createPointLottery)(activityForm).then(function () {
        _this6.$message.success("添加成功");
        _this6.activityFormSubmitLoading = false;
        _this6.activityFormCancel();
      }).catch(function () {
        _this6.activityFormSubmitLoading = false;
      });
    },
    getRandomId: function getRandomId() {
      return Math.random().toString(36).substring(2, 5);
    }
  }
};
exports.default = _default;