"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _mixin = _interopRequireDefault(require("@/utils/mixin"));
var _work_order = require("@/api/work_order");
var _hotel = require("@/api/hotel");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "WorkOrderConfigAccount",
  mixins: [_mixin.default],
  data: function data() {
    return {
      // 列表及查询
      accounts: [],
      listQuery: {
        page: 1,
        limit: 20,
        username: ""
      },
      pagination: {},
      listLoading: false,
      platformAccountList: [],
      WORoleList: [],
      hotelList: [],
      dialogVisible: false,
      formLoading: false,
      accountFormSubmitLoading: false,
      accountForm: {
        id: "",
        account_id: "",
        wo_role_id: [],
        mobile: "",
        hotel_id: []
      },
      accountFormRules: {
        account_id: [{
          required: true,
          message: '请选择账号',
          trigger: 'change'
        }],
        wo_role_id: [{
          required: true,
          message: '请选择角色',
          trigger: 'change'
        }],
        mobile: [{
          required: true,
          message: '请输入手机号码',
          trigger: 'change'
        }, {
          type: 'string',
          pattern: /^1[\d]{10}$/,
          message: '手机号码格式错误',
          trigger: 'blur'
        }],
        hotel_id: [{
          required: true,
          message: '请选择关联酒店',
          trigger: 'change'
        }]
      }
    };
  },
  created: function created() {
    var _this = this;
    this.getList();
    (0, _hotel.getHotelSimpleList)().then(function (response) {
      _this.hotelList = response.data;
    });
    (0, _work_order.getSimlpeRoleList)().then(function (response) {
      _this.WORoleList = response.data;
    });
    (0, _work_order.getUsablePlatformAccountsList)().then(function (response) {
      _this.platformAccountList = response.data;
    });
  },
  methods: {
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    getList: function getList() {
      var _this2 = this;
      this.listLoading = true;
      (0, _work_order.getAccountList)(this.listQuery).then(function (response) {
        var data = response.data;
        _this2.pagination = {
          total: data.total,
          currentPage: data.current_page,
          pageSize: data.per_page
        };
        _this2.accounts = data.data;
        _this2.listQuery.page = data.current_page;
        _this2.listLoading = false;
      });
    },
    addHandler: function addHandler() {
      this.dialogVisible = true;
    },
    editHandler: function editHandler(record) {
      var _this3 = this;
      this.formLoading = true;
      (0, _work_order.getAccount)(record.id).then(function (response) {
        _this3.accountForm = response.data;
        _this3.formLoading = false;
      });
      this.dialogVisible = true;
    },
    statusChangeHandler: function statusChangeHandler(id, status) {
      var _this4 = this;
      (0, _work_order.editWOAccountStatus)(id, status).then(function (res) {
        _this4.$message.success("操作成功");
        _this4.handleFilter();
      });
    },
    accountFormCloseHanlder: function accountFormCloseHanlder() {
      this.$refs['accountForm'].resetFields();
    },
    accountFormSubmitHandler: function accountFormSubmitHandler() {
      var _this5 = this;
      this.$refs['accountForm'].validate(function (valid) {
        if (valid) {
          var form = JSON.parse(JSON.stringify(_this5.accountForm));
          _this5.accountFormSubmitLoading = true;
          if (form.id) {
            (0, _work_order.editWOAccount)(form.id, form).then(function () {
              _this5.getList();
              _this5.$message.success("编辑成功");
              _this5.dialogVisible = false;
            }).finally(function () {
              _this5.accountFormSubmitLoading = false;
            });
          } else {
            (0, _work_order.addWOAccount)(form).then(function () {
              _this5.getList();
              _this5.$message.success("添加成功");
              _this5.dialogVisible = false;
            }).finally(function () {
              _this5.accountFormSubmitLoading = false;
            });
          }
        }
      });
    }
  }
};
exports.default = _default;