var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "form-dialog",
      attrs: {
        visible: _vm.dialogVisible,
        title: (_vm.id ? "编辑" : "新增") + "充值档位",
        "close-on-click-modal": false,
        width: "540px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.closeHanlder,
      },
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.formLoading,
              expression: "formLoading",
            },
          ],
          ref: "dataForm",
          attrs: {
            model: _vm.dataForm,
            rules: _vm.dataFormRules,
            "label-suffix": ":",
            "label-width": "85px",
            "label-position": "right",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "充值卡片", prop: "recharge_card" } },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { disabled: !!_vm.dataForm.id },
                  on: { change: _vm.onRechargeCardChange },
                  model: {
                    value: _vm.dataForm.recharge_card,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataForm, "recharge_card", $$v)
                    },
                    expression: "dataForm.recharge_card",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("会员卡")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 2 } }, [_vm._v("临时卡")]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "充值类型", prop: "recharge_type" } },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { disabled: !!_vm.dataForm.id },
                  on: { change: _vm.onRechargeTypeChange },
                  model: {
                    value: _vm.dataForm.recharge_type,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataForm, "recharge_type", $$v)
                    },
                    expression: "dataForm.recharge_type",
                  },
                },
                [
                  _vm.dataForm.recharge_card == 1
                    ? _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("固定档位"),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 2 } }, [_vm._v("自定义")]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.dataForm.recharge_type == 1
            ? [
                _c(
                  "el-form-item",
                  { attrs: { label: "充值金额", prop: "recharge_amount" } },
                  [
                    _c("el-input-number", {
                      staticStyle: { width: "220px" },
                      attrs: {
                        precision: 2,
                        step: 0.01,
                        min: 0.01,
                        max: 1000000,
                        controls: false,
                        placeholder: "请输入充值金额，需大于 0",
                      },
                      model: {
                        value: _vm.dataForm.recharge_amount,
                        callback: function ($$v) {
                          _vm.$set(_vm.dataForm, "recharge_amount", $$v)
                        },
                        expression: "dataForm.recharge_amount",
                      },
                    }),
                    _vm._v(" "),
                    _c("span", [_vm._v("元")]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "赠送金额", prop: "gift_amount" } },
                  [
                    _c("el-input-number", {
                      staticStyle: { width: "220px" },
                      attrs: {
                        precision: 2,
                        step: 0.01,
                        min: 0,
                        max: 1000000,
                        controls: false,
                        placeholder: "请输入赠送金额，可以为 0",
                      },
                      model: {
                        value: _vm.dataForm.gift_amount,
                        callback: function ($$v) {
                          _vm.$set(_vm.dataForm, "gift_amount", $$v)
                        },
                        expression: "dataForm.gift_amount",
                      },
                    }),
                    _vm._v(" "),
                    _c("span", [_vm._v("元")]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "是否推荐", prop: "is_recommend" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.dataForm.is_recommend,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataForm, "is_recommend", $$v)
                          },
                          expression: "dataForm.is_recommend",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: 1 } }, [_vm._v("是")]),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: 0 } }, [_vm._v("否")]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "有效期", prop: "eff_type" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        on: { change: _vm.onEffTypeChange },
                        model: {
                          value: _vm.dataForm.eff_type,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataForm, "eff_type", $$v)
                          },
                          expression: "dataForm.eff_type",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: 1 } }, [
                          _vm._v("长期有效"),
                        ]),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: 2 } }, [
                          _vm._v("限时生效"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.dataForm.eff_type == 2
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "生效时间", prop: "eff_date" } },
                      [
                        _c("el-date-picker", {
                          staticClass: "el-range-separator-w",
                          staticStyle: { width: "320px" },
                          attrs: {
                            clearable: false,
                            "picker-options": _vm.effDatePickerOptions,
                            type: "daterange",
                            format: "yyyy-MM-dd",
                            "value-format": "yyyy-MM-dd",
                            "range-separator": "至",
                            "start-placeholder": "开始时间",
                            "end-placeholder": "结束时间",
                          },
                          model: {
                            value: _vm.dataForm.eff_date,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "eff_date", $$v)
                            },
                            expression: "dataForm.eff_date",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            : _vm.dataForm.recharge_type == 2
            ? [
                _c(
                  "el-form-item",
                  { attrs: { label: "起充金额", prop: "recharge_amount" } },
                  [
                    _c("el-input-number", {
                      staticStyle: { width: "220px" },
                      attrs: {
                        precision: 2,
                        step: 0.01,
                        min: 0.01,
                        max: 1000000,
                        controls: false,
                        placeholder: "请输入起充金额，需大于 0",
                      },
                      model: {
                        value: _vm.dataForm.recharge_amount,
                        callback: function ($$v) {
                          _vm.$set(_vm.dataForm, "recharge_amount", $$v)
                        },
                        expression: "dataForm.recharge_amount",
                      },
                    }),
                    _vm._v(" "),
                    _c("span", [_vm._v("元")]),
                  ],
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitLoading },
              on: { click: _vm.submitHandler },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }