var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container calendar-list-container" },
    [
      _c("div", { staticClass: "filter-container filter-container-flex" }, [
        _c(
          "div",
          [
            _c("el-date-picker", {
              staticClass: "filter-item",
              staticStyle: { width: "210px" },
              attrs: {
                "picker-options": { firstDayOfWeek: 1 },
                clearable: false,
                type: "week",
                "value-format": "yyyyMMdd",
                format: "yyyy 年 第 WW 周",
                placeholder: "账单周期",
              },
              model: {
                value: _vm.listQuery.bill_week,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "bill_week", $$v)
                },
                expression: "listQuery.bill_week",
              },
            }),
            _vm._v(" "),
            _c("el-input", {
              staticClass: "filter-item",
              staticStyle: { width: "200px" },
              attrs: { placeholder: "请输入订单号" },
              model: {
                value: _vm.listQuery.order_no,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "order_no", $$v)
                },
                expression: "listQuery.order_no",
              },
            }),
            _vm._v(" "),
            _c(
              "el-select",
              {
                staticClass: "filter-item",
                staticStyle: { width: "180px" },
                attrs: { filterable: "", placeholder: "请选择渠道" },
                model: {
                  value: _vm.listQuery.third_id,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery, "third_id", $$v)
                  },
                  expression: "listQuery.third_id",
                },
              },
              [
                _c("el-option", { attrs: { value: "", label: "全部渠道" } }),
                _vm._v(" "),
                _vm._l(_vm.otas, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { value: item.id, label: item.title },
                  })
                }),
              ],
              2
            ),
            _vm._v(" "),
            _c("el-date-picker", {
              staticClass: "filter-item",
              staticStyle: { width: "255px" },
              attrs: {
                type: "daterange",
                "start-placeholder": "入住日期",
                "end-placeholder": "入住日期",
                "value-format": "yyyy-MM-dd",
                align: "right",
              },
              model: {
                value: _vm.listQuery.start_date,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "start_date", $$v)
                },
                expression: "listQuery.start_date",
              },
            }),
            _vm._v(" "),
            _c("el-date-picker", {
              staticClass: "filter-item",
              staticStyle: { width: "255px" },
              attrs: {
                type: "daterange",
                "start-placeholder": "离店日期",
                "end-placeholder": "离店日期",
                "value-format": "yyyyMMdd",
                align: "right",
              },
              model: {
                value: _vm.listQuery.end_date,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "end_date", $$v)
                },
                expression: "listQuery.end_date",
              },
            }),
            _vm._v(" "),
            _c(
              "el-select",
              {
                staticClass: "filter-item",
                attrs: { filterable: "", clearable: "", placeholder: "请选择" },
                model: {
                  value: _vm.listQuery.hotel_id,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery, "hotel_id", $$v)
                  },
                  expression: "listQuery.hotel_id",
                },
              },
              [
                _c("el-option", { attrs: { value: "", label: "全部酒店" } }),
                _vm._v(" "),
                _vm._l(_vm.hotelList, function (item) {
                  return _c("el-option", {
                    key: item.hotel_id,
                    attrs: { label: item.name, value: item.hotel_id },
                  })
                }),
              ],
              2
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticStyle: { width: "300px", "text-align": "right" } },
          [
            _c(
              "el-button",
              {
                staticClass: "filter-item",
                attrs: { type: "primary" },
                on: { click: _vm.handleFilter },
              },
              [_vm._v("\n        查询\n      ")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "filter-item",
                attrs: { type: "primary" },
                on: { click: _vm.exportHandler },
              },
              [_vm._v("\n        导出\n      ")]
            ),
            _vm._v(" "),
            _c("ota-order-resync-button", { on: { success: _vm.getList } }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.orders,
            "element-loading-text": "给我一点时间",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "订单号", width: "180" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.order_no))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              "show-overflow-tooltip": true,
              label: "酒店房型",
              "min-width": "180",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [_vm._v(_vm._s(scope.row.hotel_name))]),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(scope.row.room_name))]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "入/离时间", width: "130" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm._f("dateFormat")(scope.row.start_date))
                      ),
                    ]),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v("至"),
                    _c("br"),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(_vm._s(_vm._f("dateFormat")(scope.row.end_date))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "渠道", width: "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.third_name))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "售卖方", width: "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.seller_name))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "顾客", width: "90" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.contacts))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "实付金额", width: "90" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.amount) + "元")])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "商家应收", width: "90" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    [2, 3].indexOf(scope.row.abn_type) >= 0
                      ? _c("span", { staticStyle: { color: "#F56C6C" } }, [
                          _vm._v(_vm._s(scope.row.hotel_amount) + "元"),
                        ])
                      : _c("span", [
                          _vm._v(_vm._s(scope.row.hotel_amount) + "元"),
                        ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "原因", width: "130" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(scope.row.abn_type_name || "未知")),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              fixed: "right",
              align: "center",
              label: "操作",
              width: "120",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.abn_type == 1
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.toBookOrder(scope.row)
                              },
                            },
                          },
                          [_vm._v("\n          调整订单\n        ")]
                        )
                      : [2, 3, 4].indexOf(scope.row.abn_type) >= 0
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.toFinanceOrder(scope.row)
                              },
                            },
                          },
                          [_vm._v("\n          调整订单\n        ")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "pagination-container text-right" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              total: _vm.pagination.total,
              "current-page": _vm.listQuery.page,
              "page-sizes": [10, 20, 50],
              "page-size": _vm.listQuery.limit,
              layout: "total, sizes, prev, pager, next, jumper",
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }