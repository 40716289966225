"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.constantRoutes = exports.asyncRoutes = void 0;
exports.resetRouter = resetRouter;
var _interopRequireWildcard2 = _interopRequireDefault(require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _vue = _interopRequireDefault(require("vue"));
var _vueRouter = _interopRequireDefault(require("vue-router"));
var _layout = _interopRequireDefault(require("@/layout"));
var _blank = _interopRequireDefault(require("@/layout/blank"));
_vue.default.use(_vueRouter.default);

/* Layout */

/* Router Modules */
// import componentsRouter from './modules/components'
// import chartsRouter from './modules/charts'
// import tableRouter from './modules/table'
// import nestedRouter from './modules/nested'

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    noCache: true                if set true, the page will no be cached(default is false)
    affix: true                  if set true, the tag will affix in the tags-view
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
var constantRoutes = [{
  path: '/redirect',
  component: _layout.default,
  hidden: true,
  children: [{
    path: '/redirect/:path*',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/redirect/index'));
      });
    }
  }]
}, {
  path: '/login',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/login/index'));
    });
  },
  hidden: true
}, {
  path: '/auth-redirect',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/login/auth-redirect'));
    });
  },
  hidden: true
}, {
  path: '/404',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/error-page/404'));
    });
  },
  hidden: true
}, {
  path: '/401',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/error-page/401'));
    });
  },
  hidden: true
}, {
  path: '/',
  component: _layout.default,
  redirect: '/dashboard',
  children: [{
    path: 'dashboard',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/dashboard/index'));
      });
    },
    name: 'Dashboard',
    meta: {
      title: '首页',
      icon: 'el-icon-s-home',
      affix: true
    }
  }]
}];

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
exports.constantRoutes = constantRoutes;
var asyncRoutes = [{
  path: '/order',
  component: _layout.default,
  redirect: '/order/index',
  meta: {
    title: '订单管理',
    icon: 'el-icon-s-order',
    noCache: true,
    roles: ['admin', 'master', 'area', 'member', 'motion', 'bill']
  },
  children: [{
    path: 'index',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/order/index'));
      });
    },
    name: 'order',
    meta: {
      title: '预充值/授权/租赁订单',
      noCache: true,
      roles: ['admin', 'master', 'area', 'member', 'motion', 'bill']
    }
  }, {
    path: 'permutation',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/order/permutation'));
      });
    },
    name: 'order_permutation',
    meta: {
      title: '比例分成订单',
      noCache: true,
      roles: ['admin', 'master', 'area', 'member', 'motion', 'bill']
    }
  }, {
    path: 'permutation_old',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/order/permutationOld'));
      });
    },
    name: 'order_permutation_old',
    meta: {
      title: '置换订单',
      noCache: true,
      roles: ['admin', 'master', 'area', 'member', 'motion', 'bill']
    }
  }, {
    path: 'soft',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/order/soft'));
      });
    },
    name: 'order_soft',
    meta: {
      title: '软包订单',
      noCache: true,
      roles: ['admin', 'master', 'area', 'member', 'motion', 'bill']
    }
  }, {
    path: 'self',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/order/self'));
      });
    },
    name: 'order_self',
    meta: {
      title: '直营订单',
      noCache: true,
      roles: ['admin', 'master', 'area', 'member', 'motion', 'bill']
    }
  }, {
    path: 'alipay',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/order/alipay'));
      });
    },
    name: 'order_alipay',
    meta: {
      title: '支付宝订单',
      noCache: true,
      roles: ['admin', 'master', 'area', 'member', 'bill']
    }
  }, {
    path: 'order_seckill',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/order/seckill'));
      });
    },
    name: 'order_seckill',
    meta: {
      title: '秒杀订单',
      noCache: true,
      roles: ['admin', 'master', 'area', 'member', 'bill']
    }
  }, {
    path: 'sharedStore',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/order/sharedStore'));
      });
    },
    name: 'order_shared_store',
    meta: {
      title: '共享模式订单',
      noCache: true,
      roles: ['admin', 'master', 'area', 'member', 'bill']
    }
  }]
}, {
  path: '/room_stat',
  component: _layout.default,
  meta: {
    title: '房情表',
    icon: 'el-icon-s-management',
    roles: ['admin', 'master', 'area', 'member', 'motion']
  },
  children: [{
    path: '/room_stat/index',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/room_stat/index'));
      });
    },
    name: 'hotelStatIndex',
    meta: {
      title: '房情表',
      noCache: true,
      roles: ['admin', 'master', 'area', 'member', 'motion']
    }
  }]
}, {
  path: '/statistics',
  component: _layout.default,
  meta: {
    title: '数据统计',
    icon: 'el-icon-s-data',
    noCache: true,
    roles: ['admin', 'master', 'area', 'member', 'motion']
  },
  children: [{
    path: '/statistics/miniapp',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/data/miniappStatistics'));
      });
    },
    name: 'statistics_miniapp',
    meta: {
      title: '小程序统计',
      noCache: true,
      roles: ['admin', 'master', 'area', 'member']
    }
  }, {
    path: '/statistics/hotel_sale',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/data/hotelSaleStatistics'));
      });
    },
    name: 'statistics_hotel_sale',
    meta: {
      title: '售房统计',
      noCache: true,
      roles: ['admin', 'master', 'area', 'member', 'motion']
    }
  }]
}, {
  path: '/merchant',
  component: _layout.default,
  meta: {
    title: '商户管理',
    icon: 'el-icon-s-shop',
    roles: ['admin', 'master', 'area', 'member', 'motion', 'bill']
  },
  children: [{
    path: '/hotel/index',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/hotel/index'));
      });
    },
    name: 'HotelIndex',
    meta: {
      title: '酒店列表',
      noCache: true,
      roles: ['admin', 'master', 'area', 'member', 'motion', 'bill']
    }
  }, {
    path: '/hotel/add',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/hotel/form'));
      });
    },
    name: 'hotel/add',
    meta: {
      title: '添加酒店',
      noCache: true,
      roles: ['admin', 'master', 'area', 'member', 'motion']
    },
    hidden: true
  }, {
    path: '/hotel/room/:id',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/hotel/room'));
      });
    },
    name: 'hotel/room/id',
    meta: {
      title: '房间管理',
      icon: 'list',
      noCache: true,
      roles: ['admin', 'master', 'area', 'member', 'motion']
    },
    hidden: true
  }, {
    path: '/hotel/edit/:id',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/hotel/form'));
      });
    },
    name: 'hotel/edit',
    meta: {
      title: '编辑酒店',
      icon: 'list',
      noCache: true,
      roles: ['admin', 'master', 'area', 'member', 'motion', 'bill']
    },
    hidden: true
  }, {
    path: '/shared_store/index',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/shared_store/index'));
      });
    },
    name: 'SharedStore',
    meta: {
      title: '共享门店',
      noCache: true,
      roles: ['admin', 'master', 'area', 'member']
    }
  }, {
    path: '/shared_store/add',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/shared_store/form'));
      });
    },
    name: 'shared_store/add',
    meta: {
      title: '添加门店',
      noCache: true,
      roles: ['admin', 'master', 'area', 'member']
    },
    hidden: true
  }, {
    path: '/shared_store/edit/:id',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/shared_store/form'));
      });
    },
    name: 'shared_store/edit',
    meta: {
      title: '编辑门店',
      noCache: true,
      roles: ['admin', 'master', 'area', 'member']
    },
    hidden: true
  }, {
    path: '/shared_store/detail/:id',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/shared_store/detail'));
      });
    },
    name: 'shared_store/detail',
    meta: {
      title: '门店详情',
      noCache: true,
      roles: ['admin', 'master', 'area', 'member']
    },
    hidden: true
  }]
}, {
  path: '/net_bar',
  component: _layout.default,
  meta: {
    title: '网咖管理',
    icon: 'el-icon-s-shop',
    roles: ['admin', 'master', 'bill', 'net_bar_manager']
  },
  children: [{
    path: 'store/index',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/net_bar/store/index'));
      });
    },
    name: 'NetBarStore',
    meta: {
      title: '网咖门店',
      noCache: true,
      roles: ['admin', 'master', 'bill']
    }
  }, {
    path: 'store/add',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/net_bar/store/form'));
      });
    },
    name: 'NetBarStoreAdd',
    meta: {
      title: '添加门店',
      noCache: true,
      roles: ['admin', 'master', 'bill']
    },
    hidden: true
  }, {
    path: 'store/edit/:id',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/net_bar/store/form'));
      });
    },
    name: 'NetBarStoreEdit',
    meta: {
      title: '编辑门店',
      noCache: true,
      roles: ['admin', 'master', 'bill']
    },
    hidden: true
  }, {
    path: 'store/detail/:id',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/net_bar/store/detail'));
      });
    },
    name: 'NetBarStoreDetail',
    meta: {
      title: '门店详情',
      noCache: true,
      roles: ['admin', 'master', 'bill']
    },
    hidden: true
  }, {
    path: 'order',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/net_bar/third_order/index'));
      });
    },
    name: 'NetBarOrder',
    meta: {
      title: '开机订单',
      noCache: true,
      roles: ['admin', 'master', 'bill']
    }
  }, {
    path: 'recharge_order',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/net_bar/third_recharge_order/index'));
      });
    },
    name: 'NetBarRechargeOrder',
    meta: {
      title: '充值订单',
      noCache: true,
      roles: ['admin', 'master', 'bill']
    }
  }, {
    path: 'finance_order',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/net_bar/finance_order/index'));
      });
    },
    name: 'NetBarFinanceOrder',
    meta: {
      title: '结算订单',
      noCache: true,
      roles: ['admin', 'master', 'bill']
    }
  }, {
    path: 'finance_bill',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/net_bar/finance_bill/index'));
      });
    },
    name: 'NetBarFinanceBill',
    meta: {
      title: '财务对账',
      noCache: true,
      roles: ['admin', 'master', 'bill', 'net_bar_manager']
    }
  }, {
    path: 'finance_bill/cost/:id',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/net_bar/finance_bill/cost'));
      });
    },
    name: 'NetBarFinanceBillCost',
    meta: {
      title: '成本明细',
      noCache: true,
      roles: ['admin', 'master', 'bill', 'net_bar_manager']
    },
    hidden: true
  }
  // {
  //   path: 'o2o',
  //   component: LayoutBlank,
  //   meta: { title: '外部卡券管理', noCache: true, roles: ['admin', 'master', 'bill'] },
  //   children: [
  //     {
  //       path: 'coupon/index',
  //       component: () => import('@/views/net_bar/o2o_coupon/index'),
  //       name: 'NetBarO2oCoupon',
  //       meta: { title: '外部卡券管理', noCache: true, roles: ['admin', 'master', 'bill'] },
  //     },
  //     {
  //       path: 'coupon_write_off/index',
  //       component: () => import('@/views/net_bar/o2o_coupon_write_off/index'),
  //       name: 'NetBarO2oCouponWriteOff',
  //       meta: { title: '核销记录', noCache: true, roles: ['admin', 'master', 'bill'] },
  //     }
  //   ]
  // },
  ]
}, {
  path: '/work_order',
  component: _layout.default,
  meta: {
    title: '工单',
    icon: 'el-icon-s-claim',
    roles: ['admin', 'master', 'area']
  },
  children: [{
    path: 'order',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/work_order/order'));
      });
    },
    name: 'work_order_order',
    meta: {
      title: '工单列表',
      noCache: true,
      roles: ['admin', 'master', 'area']
    }
  }, {
    path: 'template',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/work_order/template'));
      });
    },
    name: 'work_order_template',
    meta: {
      title: '模板配置',
      noCache: true,
      roles: ['admin', 'master', 'area']
    }
  }, {
    path: 'config',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/work_order/config'));
      });
    },
    name: 'work_order_config',
    meta: {
      title: '账号管理',
      noCache: true,
      roles: ['admin', 'master', 'area']
    }
  }]
}, {
  path: '/coupon',
  component: _layout.default,
  redirect: '/coupon/index',
  meta: {
    title: '优惠券',
    icon: 'el-icon-s-ticket',
    roles: ['admin', 'master']
  },
  children: [{
    path: 'platform',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/coupon/platform'));
      });
    },
    name: 'PlatformCoupon',
    meta: {
      title: '平台优惠券',
      noCache: true,
      roles: ['admin', 'master']
    }
  }, {
    path: 'hotel',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/coupon/hotel'));
      });
    },
    name: 'HotelCoupon',
    meta: {
      title: '商家优惠券',
      noCache: true,
      roles: ['admin', 'master']
    }
  }, {
    path: 'group',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/coupon/group'));
      });
    },
    name: 'coupon_group',
    meta: {
      title: '组合优惠券',
      noCache: true,
      roles: ['admin', 'master']
    }
  }]
}, {
  path: '/point_shop',
  component: _layout.default,
  redirect: '/point_shop/type',
  meta: {
    title: '积分商城',
    icon: 'el-icon-present',
    roles: ['admin', 'master', 'area', 'member', 'motion', 'bill']
  },
  children: [{
    path: 'goods',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/point_shop/goods'));
      });
    },
    name: 'point_goods',
    meta: {
      title: '商品管理',
      noCache: true,
      roles: ['admin', 'master', 'area', 'member', 'motion', 'bill']
    }
  }, {
    path: '/point_shop/goods/add',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/point_shop/goods_form'));
      });
    },
    name: 'point_shop_goods_add',
    meta: {
      title: '添加商品',
      noCache: true,
      roles: ['admin', 'master', 'area', 'member', 'motion', 'bill']
    },
    hidden: true
  }, {
    path: '/point_shop/goods/edit/:id',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/point_shop/goods_form'));
      });
    },
    name: 'point_shop_goods_edit',
    meta: {
      title: '编辑商品',
      noCache: true,
      roles: ['admin', 'master', 'area', 'member', 'motion', 'bill']
    },
    hidden: true
  }, {
    path: 'category',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/point_shop/category'));
      });
    },
    name: 'point_good_category',
    meta: {
      title: '商品分类',
      noCache: true,
      roles: ['admin', 'master', 'area', 'member', 'motion', 'bill']
    }
  }, {
    path: 'order',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/point_shop/order'));
      });
    },
    name: 'point_good_order',
    meta: {
      title: '积分兑换订单',
      noCache: true,
      roles: ['admin', 'master', 'area', 'member', 'motion', 'bill']
    }
  }]
}, {
  path: '/banner',
  component: _layout.default,
  redirect: '/banner/index',
  meta: {
    title: '运营管理',
    icon: 'el-icon-picture',
    roles: ['admin', 'master', 'area', 'member']
  },
  children: [{
    path: 'index',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/banner/index'));
      });
    },
    name: 'banner',
    meta: {
      title: '首页banner',
      noCache: true,
      roles: ['admin', 'master', 'area', 'member']
    }
  }, {
    path: 'popup',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/banner/popup'));
      });
    },
    name: 'popup',
    meta: {
      title: '弹窗管理',
      noCache: true,
      roles: ['admin', 'master', 'area', 'member']
    }
  }, {
    path: 'hotel_popup',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/banner/hotel_popup'));
      });
    },
    name: 'hotel_popup',
    meta: {
      title: '商家后台通知',
      noCache: true,
      roles: ['admin', 'master', 'area', 'member']
    }
  }]
}, {
  path: '/bill',
  component: _layout.default,
  redirect: '/bill/day',
  meta: {
    title: '财务管理',
    icon: 'el-icon-s-finance',
    roles: ['admin', 'master', 'area', 'motion', 'bill']
  },
  children: [{
    path: 'week',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/bill/week/index'));
      });
    },
    name: 'BillWeek',
    meta: {
      title: '对账单',
      noCache: true,
      roles: ['admin', 'master', 'area', 'bill']
    }
  },
  // {
  //   path: 'day',
  //   component: () => import('@/views/bill/day/index'),
  //   name: 'BillDay',
  //   meta: { title: '每日营收', noCache: true, roles: ['admin', 'master', 'bill'] }
  // },
  // {
  //   path: 'settle',
  //   component: () => import('@/views/bill/settlement/index'),
  //   name: 'SettleMent',
  //   meta: { title: '财务结算', noCache: true, roles: ['admin', 'bill'] }
  // },
  {
    path: 'finance_meituan',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/bill/finance/meituan'));
      });
    },
    name: 'FinanceMeituan',
    meta: {
      title: '美团订单',
      noCache: true,
      roles: ['admin', 'master', 'area', 'motion', 'bill']
    }
  }, {
    path: 'finance_ctrip',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/bill/finance/ctrip'));
      });
    },
    name: 'FinanceCtrip',
    meta: {
      title: '携程订单',
      noCache: true,
      roles: ['admin', 'master', 'area', 'motion', 'bill']
    }
  }, {
    path: 'finance_offline',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/bill/finance/offline'));
      });
    },
    name: 'FinanceOffline',
    meta: {
      title: '线下渠道订单',
      noCache: true,
      roles: ['admin', 'master', 'area', 'motion', 'bill']
    }
  },
  // {
  //   path: 'finance_miniapp',
  //   component: () => import('@/views/bill/finance/miniapp'),
  //   name: 'FinanceMiniapp',
  //   meta: { title: '小程序订单', noCache: true, roles: ['admin', 'master', 'area', 'motion', 'bill'] }
  // },
  {
    path: 'finance_order',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/bill/finance/order'));
      });
    },
    name: 'FinanceOrder',
    meta: {
      title: '全渠道订单',
      noCache: true,
      roles: ['admin', 'master', 'area', 'motion', 'bill']
    }
  }, {
    path: 'appeal',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/bill/week/appeal'));
      });
    },
    name: 'BillAppeal',
    meta: {
      title: '商户账单申诉',
      noCache: true,
      roles: ['admin', 'master', 'area', 'bill']
    }
  }]
}, {
  path: '/member',
  component: _layout.default,
  redirect: '/member/index',
  meta: {
    title: '会员管理',
    icon: 'el-icon-user-solid',
    roles: ['admin', 'master', 'member']
  },
  children: [{
    path: 'index',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/member/index'));
      });
    },
    name: 'member/index',
    meta: {
      title: '会员列表',
      noCache: true,
      roles: ['admin', 'master', 'member']
    }
  }, {
    path: 'detail/:id',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/member/detail'));
      });
    },
    name: 'member/detail',
    meta: {
      title: '会员详情',
      noCache: true,
      roles: ['admin', 'master', 'member']
    }
  }]
}, {
  path: '/activity',
  component: _layout.default,
  redirect: '/activity/special',
  meta: {
    title: '活动管理',
    icon: 'el-icon-s-flag',
    noCache: true,
    roles: ['admin', 'master', 'area', 'member']
  },
  children: [{
    path: 'activity_special',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/activity/special'));
      });
    },
    name: 'special',
    meta: {
      title: '今夜特价',
      noCache: true,
      roles: ['admin', 'master', 'area', 'member']
    }
  }, {
    path: 'activity_new',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/activity/new'));
      });
    },
    name: 'activity_new',
    meta: {
      title: '新店特惠',
      noCache: true,
      roles: ['admin', 'master', 'area', 'member']
    }
  }, {
    path: 'connected_offer',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/activity/connectedOffer'));
      });
    },
    name: 'connected_offer',
    meta: {
      title: '连住优惠',
      noCache: true,
      roles: ['admin', 'master', 'area', 'member']
    }
  }, {
    path: 'seckill',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/activity/seckill'));
      });
    },
    name: 'seckill',
    meta: {
      title: '限时秒杀',
      noCache: true,
      roles: ['admin', 'master', 'area', 'member']
    }
  }, {
    path: 'scan_code',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/activity/scanCode/index'));
      });
    },
    name: 'ActivityScanCode',
    meta: {
      title: '扫码红包',
      noCache: true,
      roles: ['admin', 'master', 'area', 'member']
    }
  }, {
    path: 'point_lottery',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/activity/pointLottery/index'));
      });
    },
    name: 'PointLottery',
    meta: {
      title: '转盘活动',
      noCache: true,
      roles: ['admin', 'master', 'area', 'member']
    }
  }, {
    path: 'point_lottery_form',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/activity/pointLottery/form'));
      });
    },
    name: 'PointLotteryForm',
    meta: {
      title: '配置活动',
      noCache: true,
      roles: ['admin', 'master', 'area', 'member']
    },
    hidden: true
  }, {
    path: 'point_lottery/:id/log',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/activity/pointLottery/log'));
      });
    },
    name: 'PointLotteryLog',
    meta: {
      title: '活动详情',
      noCache: true,
      roles: ['admin', 'master', 'area', 'member']
    },
    hidden: true
  }
  // {
  //   path: '/hotel/edit/:id',
  //   component: () => import('@/views/hotel/form'),
  //   name: 'hotel/edit',
  //   meta: { title: '编辑酒店', icon: 'list', noCache: true, roles: ['admin', 'master', 'area', 'member', 'motion']  },
  //   hidden: true
  // },
  ]
}, {
  path: '/agent',
  component: _layout.default,
  redirect: '/agent/index',
  children: [{
    path: 'index',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/agent/index'));
      });
    },
    name: 'AgentIndex',
    meta: {
      title: '代理商管理',
      icon: 'el-icon-s-custom',
      noCache: true,
      roles: ['admin', 'master', 'area']
    }
  }, {
    path: ':id',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/agent/detail'));
      });
    },
    name: 'AgentDetail',
    meta: {
      title: '代理商详情',
      noCache: true,
      roles: ['admin', 'master', 'area']
    },
    hidden: true
  }]
}, {
  path: '/manager',
  component: _layout.default,
  redirect: '/manager/admin',
  meta: {
    title: '系统权限',
    icon: 'el-icon-cpu',
    noCache: true,
    roles: ['admin', 'master']
  },
  children: [{
    path: 'admin',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/manager/admin'));
      });
    },
    name: 'manager_admin',
    meta: {
      title: '平台账号',
      noCache: true,
      roles: ['admin']
    }
  }, {
    path: 'hotel',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/manager/hotel'));
      });
    },
    name: 'manager_hotel',
    meta: {
      title: '酒店账号',
      noCache: true,
      roles: ['admin', 'master']
    }
  }, {
    path: 'agent',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/manager/agent'));
      });
    },
    name: 'manager_agent',
    meta: {
      title: '代理商账号',
      noCache: true,
      roles: ['admin', 'master']
    }
  }, {
    path: 'shared_store',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/manager/sharedStore'));
      });
    },
    name: 'manager_shared_store',
    meta: {
      title: '共享门店账号',
      noCache: true,
      roles: ['admin', 'master']
    }
  }, {
    path: 'net_bar',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/manager/netBar'));
      });
    },
    name: 'manager_net_bar',
    meta: {
      title: '网咖账号',
      noCache: true,
      roles: ['admin', 'master']
    }
  }]
}];
exports.asyncRoutes = asyncRoutes;
var createRouter = function createRouter() {
  return new _vueRouter.default({
    // mode: 'history', // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};
var router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
var _default = router;
exports.default = _default;