"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _member = require("@/api/member");
var _coupon = require("@/api/coupon");
var _hotel = require("@/api/hotel");
var _vDistpicker = _interopRequireDefault(require("v-distpicker"));
var _auth = require("@/utils/auth");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "MemberIndex",
  components: {
    VDistpicker: _vDistpicker.default
  },
  data: function data() {
    return {
      btn: false,
      list: [],
      pagination: {},
      dialogFormVisible: false,
      searchVisible: false,
      dialogTitle: "添加优惠券",
      listQuery: {
        page: 1,
        limit: 20,
        id: "",
        created_at: "",
        last_consume_date: "",
        mobile: "",
        nickname: "",
        consume_count_min: "",
        consume_count_max: "",
        consume_amount_min: "",
        consume_amount_max: "",
        unlock_count_max: "",
        unlock_count_min: "",
        consume_date: "",
        gender: "",
        channel: "",
        mobile_select: "",
        province: "",
        city: "",
        fast_hotel: "",
        tag: []
      },
      channelList: [],
      couponList: [],
      hotelList: [],
      loading: false,
      couponId: "",
      userId: 0,
      statics: {
        new_count: 0,
        pay_count: 0,
        new_pay_count: 0,
        all_count: 0,
        new_count_per: 0,
        new_pay_count_per: 0
      },
      tags: [{
        value: 1,
        label: "酒店"
      }, {
        value: 2,
        label: "网咖"
      }]
    };
  },
  created: function created() {
    var _this = this;
    this.getList();
    (0, _member.getStatistics)().then(function (response) {
      _this.statics = response.data;
    });
  },
  methods: {
    getSimpleHotel: function getSimpleHotel(query) {
      var _this2 = this;
      if (query !== '') {
        this.loading = true;
        setTimeout(function () {
          _this2.loading = false;
          (0, _hotel.getSimpleHotel)({
            'name': query
          }).then(function (res) {
            _this2.hotelList = res.data;
          });
        }, 200);
      } else {
        this.options = [];
      }
    },
    onSelected: function onSelected(data) {
      console.log(data);
      this.listQuery.province = data.province.value;
      this.listQuery.city = data.city.value;
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleRedirect: function handleRedirect(id) {
      console.log(id);
      this.$router.push({
        path: "/member/detail/" + id
      });
    },
    resetForm: function resetForm() {
      this.listQuery = {
        page: this.listQuery.page,
        limit: 20,
        id: "",
        created_at: "",
        mobile: "",
        nickname: "",
        consume_count_min: "",
        consume_count_max: "",
        consume_amount_min: "",
        consume_amount_max: "",
        unlock_count_max: "",
        unlock_count_min: "",
        consume_date: "",
        last_consume_date: "",
        gender: "",
        channel: "",
        mobile_select: "",
        province: "",
        city: "",
        tag: []
      };
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    doSearchMore: function doSearchMore() {
      this.searchVisible = false;
      this.handleFilter();
    },
    getList: function getList() {
      var _this3 = this;
      this.listLoading = true;
      (0, _member.getList)(this.listQuery).then(function (response) {
        var data = response.data;
        _this3.list = data.data;
        _this3.pagination = data;
        _this3.listQuery.page = data.current_page;
        _this3.listLoading = false;
        _this3.channelList = data.channelList;
        _this3.hotelList = data.hotelList;
      });
      this.listLoading = false;
    },
    download: function download() {
      // var query = this.listQuery;
      // var isArray = function (obj) {
      //   return Object.prototype.toString.call(obj) === "[object Array]";
      // };
      // var url = Object.keys(this.listQuery)
      //   .map(function (key) {
      //     if (isArray(query[key])) {
      //       var iUrl = "";
      //       query[key].forEach((item) => {
      //         iUrl = iUrl + key + "[]=" + item + "&";
      //       });
      //       return iUrl.trim("&");
      //     } else {
      //       return (
      //         encodeURIComponent(key) + "=" + encodeURIComponent(query[key])
      //       );
      //     }
      //   })
      //   .join("&");
      //   console.log(url)
      // window.open("/admin/member/export?" + url + "&api_token=" + getToken());
      window.open((0, _member.getMerberDownloadUrl)(this.listQuery));
    },
    sendCouponDialog: function sendCouponDialog(row) {
      var _this4 = this;
      this.userId = row.id;
      (0, _coupon.couponManualList)().then(function (response) {
        _this4.couponList = response.data;
      });
      this.dialogFormVisible = true;
    },
    sendCoupon: function sendCoupon() {
      var _this5 = this;
      if (this.userId < 1 || this.couponId < 1) {
        this.$message.error("请选择优惠券");
        return false;
      }
      this.$confirm("优惠券会立即发放给该用户，不可撤回，确认操作?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        // console.log(id, { coupon_id: couponId })
        (0, _member.sendCoupon)(_this5.userId, {
          coupon_id: _this5.couponId
        }).then(function (res) {
          if (res.code == 0) {
            _this5.$message.success("发送成功！");
            _this5.dialogFormVisible = false;
          } else {
            _this5.$message.error(res.data.msg);
          }
        });
      }).catch(function () {
        // console.log(this.userId, { coupon_id: couponId })
        return;
      });
    },
    searchMore: function searchMore() {
      this.searchVisible = true;
    }
  }
};
exports.default = _default;