var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "calendar-list-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: { clearable: "", placeholder: "设备编号" },
            model: {
              value: _vm.listQuery.keyword,
              callback: function ($$v) {
                _vm.$set(_vm.listQuery, "keyword", $$v)
              },
              expression: "listQuery.keyword",
            },
          }),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              staticStyle: { "margin-left": "10px" },
              attrs: { type: "primary" },
              on: { click: _vm.handleFilter },
            },
            [_vm._v("\n      搜索\n    ")]
          ),
          _vm._v(" "),
          _vm.deviceAddEnabled()
            ? _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  staticStyle: { "margin-left": "10px" },
                  on: { click: _vm.addHandler },
                },
                [_vm._v("\n      添加设备\n    ")]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          key: "tableList",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            "element-loading-text": "给我一点时间",
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "name",
              align: "center",
              label: "门店设备编号",
              "min-width": "120",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "code", align: "center", label: "设备编号" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "cpu", align: "center", label: "CPU" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "graphic_card", align: "center", label: "显卡" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "ram", align: "center", label: "内存" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "display", align: "center", label: "显示器" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "计费规则",
              "class-name": "overflow-on",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.device_price_name || "--") +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "状态", "min-width": "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.status == 1
                      ? [
                          scope.row.used == 1
                            ? _c("span", [
                                _c("div", { staticClass: "dot-success" }),
                                _vm._v("使用中"),
                              ])
                            : _c("span", [
                                _c("div", { staticClass: "dot-info" }),
                                _vm._v("未使用"),
                              ]),
                        ]
                      : [
                          _c("span", [
                            _c("div", { staticClass: "dot-info" }),
                            _vm._v("未上架"),
                          ]),
                        ],
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              fixed: "right",
              label: "操作",
              width: "215",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.editHandler(scope.row)
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.showUsageRecordHandler(scope.row.id)
                          },
                        },
                      },
                      [_vm._v("查看记录")]
                    ),
                    _vm._v(" "),
                    _vm.deviceCloseEnabled(scope.row)
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.deviceCloseHandler(scope.row)
                              },
                            },
                          },
                          [_vm._v("下机")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.deviceStatusEnabled()
                      ? [
                          scope.row.status
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.deviceStopSvcHandler(
                                        scope.row.id
                                      )
                                    },
                                  },
                                },
                                [_vm._v("下架")]
                              )
                            : _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.deviceStartSvcHandler(
                                        scope.row.id
                                      )
                                    },
                                  },
                                },
                                [_vm._v("上架")]
                              ),
                        ]
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "pagination-container text-right" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              total: _vm.pagination.total,
              "current-page": _vm.listQuery.page,
              "page-sizes": [10, 20, 50],
              "page-size": _vm.listQuery.limit,
              layout: "total, sizes, prev, pager, next, jumper",
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.deviceOrdersDialogVisible,
            title: "使用记录",
            width: "780px",
            top: "10vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.deviceOrdersDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-row",
            {},
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.orderListLoading,
                          expression: "orderListLoading",
                        },
                      ],
                      staticStyle: { width: "100%" },
                      attrs: { data: _vm.orderList, border: "" },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          align: "left",
                          label: "上机时间",
                          "class-name": "overflow-on",
                          "min-width": "300",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.status == 30
                                  ? _c(
                                      "span",
                                      { staticStyle: { color: "#67C23A" } },
                                      [
                                        _vm._v(
                                          _vm._s(scope.row.started_at) +
                                            " ~ 现在"
                                        ),
                                      ]
                                    )
                                  : _c("span", [
                                      _vm._v(
                                        _vm._s(scope.row.started_at) +
                                          " ~ " +
                                          _vm._s(scope.row.ended_at)
                                      ),
                                    ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "order_no",
                          align: "center",
                          label: "关联订单",
                          "class-name": "overflow-on",
                          width: "175",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "累计时长",
                          "class-name": "overflow-on",
                          "min-width": "130",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.getUseTime(scope.row))),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "金额",
                          "class-name": "overflow-on",
                          "min-width": "110",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [
                                  _vm._v(_vm._s(scope.row.amount) + "元"),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "pagination-container text-right" },
                    [
                      _c("el-pagination", {
                        attrs: {
                          background: "",
                          total: _vm.orderPagination.total,
                          "current-page": _vm.orderPagination.page,
                          "page-sizes": [10, 20, 50],
                          "page-size": _vm.orderPagination.limit,
                          layout: "total, sizes, prev, pager, next, jumper",
                        },
                        on: {
                          "size-change": _vm.handleOrderSizeChange,
                          "current-change": _vm.handleOrderCurrentChange,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.deviceOrdersDialogVisible = false
                    },
                  },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("NetBarDeviceAddForm", {
        ref: "DeviceAddForm",
        attrs: { storeId: _vm.storeId, visible: _vm.deviceAddFormVisible },
        on: {
          "update:visible": function ($event) {
            _vm.deviceAddFormVisible = $event
          },
          success: _vm.getList,
        },
      }),
      _vm._v(" "),
      _c("NetBarDeviceEditForm", {
        ref: "DeviceEditForm",
        attrs: {
          storeId: _vm.storeId,
          id: _vm.deviceId,
          visible: _vm.deviceEditFormVisible,
        },
        on: {
          "update:id": function ($event) {
            _vm.deviceId = $event
          },
          "update:visible": function ($event) {
            _vm.deviceEditFormVisible = $event
          },
          success: _vm.getList,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }