"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _member = require("@/api/member");
var _net_bar_stv_balance_log = _interopRequireDefault(require("./net_bar_stv_balance_log"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "MemberNetBarStv",
  components: {
    NetBarStvBalanceLog: _net_bar_stv_balance_log.default
  },
  props: {
    userId: {
      type: [String, Number]
    }
  },
  data: function data() {
    return {
      statusList: [{
        value: 30,
        label: "使用中"
      }, {
        value: 40,
        label: "已完成"
      }],
      list: [],
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 20
      },
      pagination: "",
      storeId: "",
      balanceLogDetailVisible: false
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      (0, _member.fetchStvList)(this.userId, this.listQuery).then(function (response) {
        var data = response.data;
        _this.list = data.data;
        _this.pagination = data;
        _this.listQuery.page = data.current_page;
        _this.listLoading = false;
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    detailHandler: function detailHandler(record) {
      this.storeId = record.nb_id;
      this.balanceLogDetailVisible = true;
    }
  }
};
exports.default = _default;