var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container member-container" },
    [
      _c(
        "div",
        { staticClass: "user-sum" },
        [
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 6 } }, [
                _c("div", { staticClass: "grid-content bg-purple" }, [
                  _vm._v("累计用户：" + _vm._s(_vm.statics.all_count)),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 6 } }, [
                _c("div", { staticClass: "grid-content bg-purple" }, [
                  _vm._v(
                    "\n          30日新增：" + _vm._s(_vm.statics.new_count)
                  ),
                  _c("br"),
                  _vm._v(" "),
                  _vm.statics.new_count_per >= 0
                    ? _c("span", { staticClass: "percent" }, [
                        _vm._v("环比"),
                        _c("span", { staticClass: "percent-red" }, [
                          _vm._v("+" + _vm._s(_vm.statics.new_count_per) + "%"),
                        ]),
                      ])
                    : _c("span", { staticClass: "percent" }, [
                        _vm._v("环比"),
                        _c("span", { staticClass: "percent-green" }, [
                          _vm._v("-" + _vm._s(_vm.statics.new_count_per) + "%"),
                        ]),
                      ]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 6 } }, [
                _c("div", { staticClass: "grid-content bg-purple" }, [
                  _vm._v("支付人数：" + _vm._s(_vm.statics.pay_count)),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 6 } }, [
                _c("div", { staticClass: "grid-content bg-purple-light" }, [
                  _vm._v("30日新增：" + _vm._s(_vm.statics.new_pay_count)),
                  _c("br"),
                  _vm._v(" "),
                  _vm.statics.new_pay_count_per >= 0
                    ? _c("span", { staticClass: "percent" }, [
                        _vm._v("环比"),
                        _c("span", { staticClass: "percent-red" }, [
                          _vm._v(
                            "+" + _vm._s(_vm.statics.new_pay_count_per) + "%"
                          ),
                        ]),
                      ])
                    : _c("span", { staticClass: "percent" }, [
                        _vm._v("环比"),
                        _c("span", { staticClass: "percent-green" }, [
                          _vm._v(
                            "-" + _vm._s(_vm.statics.new_pay_count_per) + "%"
                          ),
                        ]),
                      ]),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "main" },
        [
          _c(
            "div",
            { staticClass: "filter-container" },
            [
              _c("el-input", {
                staticClass: "filter-item",
                staticStyle: { width: "100px" },
                attrs: { placeholder: "ID" },
                model: {
                  value: _vm.listQuery.id,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery, "id", $$v)
                  },
                  expression: "listQuery.id",
                },
              }),
              _vm._v(" "),
              _c("el-input", {
                staticClass: "filter-item",
                staticStyle: { width: "150px" },
                attrs: { placeholder: "昵称" },
                model: {
                  value: _vm.listQuery.nickname,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery, "nickname", $$v)
                  },
                  expression: "listQuery.nickname",
                },
              }),
              _vm._v(" "),
              _c("el-input", {
                staticClass: "filter-item",
                staticStyle: { width: "150px" },
                attrs: { placeholder: "手机号" },
                model: {
                  value: _vm.listQuery.mobile,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery, "mobile", $$v)
                  },
                  expression: "listQuery.mobile",
                },
              }),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  attrs: { type: "primary", icon: "el-icon-search" },
                  on: { click: _vm.handleFilter },
                },
                [_vm._v("查询")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { staticClass: "filter-item", on: { click: _vm.resetForm } },
                [_vm._v("重置")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { staticClass: "filter-item", on: { click: _vm.searchMore } },
                [_vm._v("筛选")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { staticClass: "filter-item", on: { click: _vm.download } },
                [_vm._v("导出")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              key: "id",
              attrs: {
                data: _vm.list,
                "element-loading-text": "给我一点时间",
                border: "",
                fit: "",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { align: "center", label: "ID" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.id))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { align: "center", label: "昵称" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.nickname))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { align: "center", label: "用户标签" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        (scope.row.tag & 1) == 1
                          ? _c(
                              "el-tag",
                              {
                                staticStyle: { "border-color": "#409EFF" },
                                attrs: { size: "mini" },
                              },
                              [_vm._v("酒店")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        (scope.row.tag & 2) == 2
                          ? _c(
                              "el-tag",
                              {
                                staticStyle: {
                                  color: "#bf24ff",
                                  "border-color": "#bf24ff",
                                },
                                attrs: { size: "mini", color: "#efc9ff" },
                              },
                              [_vm._v("网咖")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { align: "center", label: "手机号" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              scope.row.mobile ? scope.row.mobile.slice(2) : ""
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { align: "center", label: "注册时间" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.created_at))]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { align: "center", label: "最近消费时间" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.pay_at))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { align: "center", label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleRedirect(scope.row.id)
                              },
                            },
                          },
                          [_vm._v("查看")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.sendCouponDialog(scope.row)
                              },
                            },
                          },
                          [_vm._v("手动发券")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "pagination-container text-right" },
            [
              _c("el-pagination", {
                attrs: {
                  background: "",
                  total: _vm.pagination.total,
                  "current-page": _vm.listQuery.page,
                  "page-sizes": [20],
                  "page-size": _vm.listQuery.limit,
                  layout: "total, sizes, prev, pager, next, jumper",
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.searchVisible,
            title: "用户筛选",
            top: "10vh",
            width: "780px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.searchVisible = $event
            },
          },
        },
        [
          _c(
            "el-divider",
            {
              staticClass: "divider-title",
              attrs: { "content-position": "left" },
            },
            [_vm._v("基本信息：")]
          ),
          _vm._v(" "),
          _c(
            "el-form",
            { attrs: { "label-position": "right", "label-width": "100px" } },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "注册时间" } },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "280px" },
                            attrs: {
                              type: "daterange",
                              "range-separator": "至",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "结束日期",
                              "value-format": "yyyy-MM-dd",
                            },
                            model: {
                              value: _vm.listQuery.created_at,
                              callback: function ($$v) {
                                _vm.$set(_vm.listQuery, "created_at", $$v)
                              },
                              expression: "listQuery.created_at",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "会员性别" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.listQuery.gender,
                                callback: function ($$v) {
                                  _vm.$set(_vm.listQuery, "gender", $$v)
                                },
                                expression: "listQuery.gender",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "全部", value: "" },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "男", value: "1" },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "女", value: "2" },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "未知", value: "0" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "地区范围" } },
                        [
                          _c("v-distpicker", {
                            attrs: {
                              province: _vm.listQuery.province,
                              city: _vm.listQuery.city,
                              "hide-area": "",
                            },
                            on: { selected: _vm.onSelected },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "来源渠道" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.listQuery.channel,
                                callback: function ($$v) {
                                  _vm.$set(_vm.listQuery, "channel", $$v)
                                },
                                expression: "listQuery.channel",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "全部", value: "" },
                              }),
                              _vm._v(" "),
                              _vm._l(_vm.channelList, function (item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: { label: item, value: index },
                                })
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "手机号" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.listQuery.mobile_select,
                                callback: function ($$v) {
                                  _vm.$set(_vm.listQuery, "mobile_select", $$v)
                                },
                                expression: "listQuery.mobile_select",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "不限", value: "" },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "有", value: "1" },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "无", value: "2" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "转化酒店" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "filter-item",
                              attrs: {
                                filterable: "",
                                remote: "",
                                placeholder: "请输入展示区域",
                                "remote-method": _vm.getSimpleHotel,
                                loading: _vm.loading,
                              },
                              model: {
                                value: _vm.listQuery.fast_hotel,
                                callback: function ($$v) {
                                  _vm.$set(_vm.listQuery, "fast_hotel", $$v)
                                },
                                expression: "listQuery.fast_hotel",
                              },
                            },
                            _vm._l(_vm.hotelList, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item, value: index },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "用户标签" } },
                        [
                          _c(
                            "el-checkbox-group",
                            {
                              model: {
                                value: _vm.listQuery.tag,
                                callback: function ($$v) {
                                  _vm.$set(_vm.listQuery, "tag", $$v)
                                },
                                expression: "listQuery.tag",
                              },
                            },
                            _vm._l(_vm.tags, function (tag) {
                              return _c(
                                "el-checkbox",
                                { attrs: { label: tag.value } },
                                [_vm._v(_vm._s(tag.label))]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-divider",
            {
              staticClass: "divider-title",
              attrs: { "content-position": "left" },
            },
            [_vm._v("消费信息：")]
          ),
          _vm._v(" "),
          _c(
            "el-form",
            {
              attrs: {
                "label-position": "right",
                "label-width": "100px",
                inline: true,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "上次消费距今" } },
                [
                  _c(
                    "el-input",
                    {
                      attrs: { "show-word-limit": "" },
                      model: {
                        value: _vm.listQuery.consume_date,
                        callback: function ($$v) {
                          _vm.$set(_vm.listQuery, "consume_date", $$v)
                        },
                        expression: "listQuery.consume_date",
                      },
                    },
                    [_c("template", { slot: "append" }, [_vm._v("天")])],
                    2
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "最近消费日期" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "daterange",
                      "range-separator": "至",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                      "value-format": "yyyyMMdd",
                    },
                    model: {
                      value: _vm.listQuery.last_consume_date,
                      callback: function ($$v) {
                        _vm.$set(_vm.listQuery, "last_consume_date", $$v)
                      },
                      expression: "listQuery.last_consume_date",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "消费笔数" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 11 } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.listQuery.consume_count_min,
                          callback: function ($$v) {
                            _vm.$set(_vm.listQuery, "consume_count_min", $$v)
                          },
                          expression: "listQuery.consume_count_min",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { staticClass: "text-center", attrs: { span: 2 } },
                    [_vm._v("-")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 11 } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.listQuery.consume_count_max,
                          callback: function ($$v) {
                            _vm.$set(_vm.listQuery, "consume_count_max", $$v)
                          },
                          expression: "listQuery.consume_count_max",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "消费金额" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 11 } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.listQuery.consume_amount_min,
                          callback: function ($$v) {
                            _vm.$set(_vm.listQuery, "consume_amount_min", $$v)
                          },
                          expression: "listQuery.consume_amount_min",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { staticClass: "text-center", attrs: { span: 2 } },
                    [_vm._v("-")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 11 } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.listQuery.consume_amount_max,
                          callback: function ($$v) {
                            _vm.$set(_vm.listQuery, "consume_amount_max", $$v)
                          },
                          expression: "listQuery.consume_amount_max",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "上机次数" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 11 } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.listQuery.unlock_count_min,
                          callback: function ($$v) {
                            _vm.$set(_vm.listQuery, "unlock_count_min", $$v)
                          },
                          expression: "listQuery.unlock_count_min",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { staticClass: "text-center", attrs: { span: 2 } },
                    [_vm._v("-")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 11 } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.listQuery.unlock_count_max,
                          callback: function ($$v) {
                            _vm.$set(_vm.listQuery, "unlock_count_max", $$v)
                          },
                          expression: "listQuery.unlock_count_max",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.searchVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: _vm.btn },
                  on: {
                    click: function ($event) {
                      return _vm.doSearchMore()
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: _vm.dialogTitle, visible: _vm.dialogFormVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            [
              _c(
                "el-form-item",
                { attrs: { label: "券名称" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { filterable: "" },
                      model: {
                        value: _vm.couponId,
                        callback: function ($$v) {
                          _vm.couponId = $$v
                        },
                        expression: "couponId",
                      },
                    },
                    _vm._l(_vm.couponList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: _vm.btn },
                  on: {
                    click: function ($event) {
                      return _vm.sendCoupon()
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }