var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container calendar-list-container activity-form-page" },
    [
      _c(
        "el-form",
        {
          ref: "activityForm",
          attrs: { "label-suffix": ":", model: _vm.data, rules: _vm.rules },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "活动名称",
                        "label-width": "85px",
                        prop: "title",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入" },
                        model: {
                          value: _vm.data.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.data, "title", $$v)
                          },
                          expression: "data.title",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "活动时间",
                        "label-width": "85px",
                        required: "",
                      },
                    },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 11 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "start_time" } },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  type: "datetime",
                                  format: "yyyy-MM-dd HH",
                                  "default-time": "00:00:00",
                                  placeholder: "开始日期",
                                  "picker-options": _vm.datePickerOptions,
                                },
                                model: {
                                  value: _vm.data.start_time,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data, "start_time", $$v)
                                  },
                                  expression: "data.start_time",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { staticClass: "line", attrs: { span: 2 } },
                        [_vm._v("至")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 11 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "end_time" } },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  type: "datetime",
                                  format: "yyyy-MM-dd HH",
                                  "default-time": "23:59:59",
                                  placeholder: "结束日期",
                                  "picker-options": _vm.datePickerOptions,
                                },
                                model: {
                                  value: _vm.data.end_time,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data, "end_time", $$v)
                                  },
                                  expression: "data.end_time",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "消耗积分",
                        "label-width": "85px",
                        prop: "point",
                      },
                    },
                    [
                      _c("el-input-number", {
                        attrs: { min: 0, placeholder: "请输入" },
                        model: {
                          value: _vm.data.point,
                          callback: function ($$v) {
                            _vm.$set(_vm.data, "point", $$v)
                          },
                          expression: "data.point",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "分享文案",
                        "label-width": "85px",
                        prop: "share_text",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入分享到微信的群的文案" },
                        model: {
                          value: _vm.data.share_text,
                          callback: function ($$v) {
                            _vm.$set(_vm.data, "share_text", $$v)
                          },
                          expression: "data.share_text",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "活动规则",
                        "label-width": "85px",
                        prop: "rules",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入活动规则文案，将展示在小程序端",
                          type: "textarea",
                          autosize: { minRows: 4, maxRows: 6 },
                        },
                        model: {
                          value: _vm.data.rules,
                          callback: function ($$v) {
                            _vm.$set(_vm.data, "rules", $$v)
                          },
                          expression: "data.rules",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      ref: "activityShareImage",
                      attrs: {
                        label: "分享图",
                        "label-width": "85px",
                        prop: "share_image",
                      },
                    },
                    [
                      _c(
                        "el-upload",
                        {
                          attrs: {
                            action: _vm.uploadUrl,
                            headers: _vm.header,
                            "show-file-list": false,
                            "on-success": _vm.handleShareImageSuccess,
                          },
                        },
                        [
                          _vm.data.share_image
                            ? _c("img", {
                                staticStyle: { "max-width": "100px" },
                                attrs: { src: _vm.data.share_image },
                              })
                            : _c("i", {
                                staticClass: "el-icon-plus image-uploader-icon",
                              }),
                        ]
                      ),
                      _vm._v(" "),
                      _c("span", { staticClass: "image-tips" }, [
                        _vm._v("建议尺寸：500*400"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-divider",
                    {
                      staticStyle: { "margin-top": "0" },
                      attrs: { "content-position": "left" },
                    },
                    [_vm._v("奖品列表")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { staticStyle: { "text-align": "right" }, attrs: { span: 24 } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "text", icon: "el-icon-plus" },
                      on: { click: _vm.addPrizeHandler },
                    },
                    [_vm._v("添加奖品")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { ref: "prizesTable", attrs: { prop: "prizes" } },
                    [
                      _c(
                        "el-table",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            data: _vm.data.prizes,
                            size: "small",
                            border: "",
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: { prop: "title", label: "奖品名称" },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "thumbnail",
                              label: "奖品图",
                              width: "120",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: { "text-align": "center" },
                                      },
                                      [
                                        _c("img", {
                                          staticStyle: { width: "50px" },
                                          attrs: { src: scope.row.thumbnail },
                                        }),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "stock",
                              label: "库存",
                              width: "120",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "type",
                              label: "奖品类型",
                              width: "120",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.prizeTypeMap[scope.row.type] ||
                                            "未知"
                                        ) +
                                        "\n              "
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { prop: "foreign_name", label: "奖品信息" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    scope.row.type == 2
                                      ? _c("span", [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(scope.row.foreign_name) +
                                              "\n                "
                                          ),
                                        ])
                                      : scope.row.type == 3
                                      ? _c("span", [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(scope.row.point) +
                                              "积分\n                "
                                          ),
                                        ])
                                      : _c("span", [_vm._v("--")]),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              fixed: "right",
                              align: "center",
                              label: "操作",
                              width: "180",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-link",
                                      {
                                        staticClass: "link-right",
                                        attrs: {
                                          type: "primary",
                                          underline: false,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.editPrizeHandler(
                                              scope.row
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                  编辑\n                "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-popconfirm",
                                      {
                                        attrs: {
                                          title: "你确定要删除该奖品吗？",
                                          icon: "el-icon-info",
                                          "icon-color": "red",
                                        },
                                        on: {
                                          confirm: function ($event) {
                                            return _vm.deletePrizeHandler(
                                              scope.$index
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "el-link",
                                          {
                                            attrs: {
                                              slot: "reference",
                                              underline: false,
                                              type: "danger",
                                            },
                                            slot: "reference",
                                          },
                                          [_vm._v("删除")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { staticStyle: { "margin-top": "35px" }, attrs: { span: 24 } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        loading: _vm.activityFormSubmitLoading,
                      },
                      on: { click: _vm.activityFormSubmitHandler },
                    },
                    [_vm._v("\n          确认\n        ")]
                  ),
                  _vm._v(" "),
                  _c("el-button", { on: { click: _vm.activityFormCancel } }, [
                    _vm._v("\n          取 消\n        "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogPrizeFormVisible,
            title: "配置活动",
            "close-on-click-modal": false,
            width: "750px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogPrizeFormVisible = $event
            },
            close: _vm.prizeFormCloseHanlder,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "prizeForm",
              attrs: {
                "label-suffix": ":",
                model: _vm.prize,
                rules: _vm.prizeRules,
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "奖品名称",
                            "label-width": "85px",
                            prop: "title",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入" },
                            model: {
                              value: _vm.prize.title,
                              callback: function ($$v) {
                                _vm.$set(_vm.prize, "title", $$v)
                              },
                              expression: "prize.title",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "奖品类型",
                            "label-width": "85px",
                            prop: "type",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择" },
                              on: { change: _vm.prizeTypeChangeHandler },
                              model: {
                                value: _vm.prize.type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.prize, "type", $$v)
                                },
                                expression: "prize.type",
                              },
                            },
                            _vm._l(_vm.prizeTypes, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.title, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "库存",
                            "label-width": "85px",
                            prop: "stock",
                          },
                        },
                        [
                          _c("el-input-number", {
                            attrs: { min: 0, placeholder: "请输入" },
                            model: {
                              value: _vm.prize.stock,
                              callback: function ($$v) {
                                _vm.$set(_vm.prize, "stock", $$v)
                              },
                              expression: "prize.stock",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      [
                        _vm.prize.type == 2
                          ? _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "优惠券",
                                  "label-width": "85px",
                                  prop: "foreign_id",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      placeholder: "请选择",
                                      filterable: "",
                                    },
                                    on: {
                                      change: _vm.prizeForeignChangeHandler,
                                    },
                                    model: {
                                      value: _vm.prize.foreign_id,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.prize, "foreign_id", $$v)
                                      },
                                      expression: "prize.foreign_id",
                                    },
                                  },
                                  _vm._l(_vm.coupons, function (coupon) {
                                    return _c("el-option", {
                                      key: coupon.id,
                                      attrs: {
                                        label: coupon.name,
                                        value: coupon.id,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm.prize.type == 3
                          ? _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "积分",
                                  "label-width": "85px",
                                  prop: "stock",
                                },
                              },
                              [
                                _c("el-input-number", {
                                  attrs: { min: 0, placeholder: "请输入" },
                                  model: {
                                    value: _vm.prize.point,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.prize, "point", $$v)
                                    },
                                    expression: "prize.point",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          ref: "prizeThumbnail",
                          attrs: {
                            label: "奖品图",
                            "label-width": "85px",
                            prop: "thumbnail",
                          },
                        },
                        [
                          _c(
                            "el-upload",
                            {
                              staticClass: "avatar-uploader",
                              attrs: {
                                action: _vm.uploadUrl,
                                headers: _vm.header,
                                "show-file-list": false,
                                "on-success": _vm.handlePrizeImageSuccess,
                              },
                            },
                            [
                              _vm.prize.thumbnail
                                ? _c("img", {
                                    staticClass: "avatar",
                                    staticStyle: { "max-width": "100px" },
                                    attrs: { src: _vm.prize.thumbnail },
                                  })
                                : _c("i", {
                                    staticClass:
                                      "el-icon-plus image-uploader-icon",
                                  }),
                            ]
                          ),
                          _vm._v(" "),
                          _c("span", { staticClass: "image-tips" }, [
                            _vm._v("建议尺寸：200*200"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogPrizeFormVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.prizeFormSubmitHandler },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }