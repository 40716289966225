"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es6.number.constructor");
var _hotel = require("@/api/hotel");
var _finance_reco = require("@/api/finance_reco");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: "ThirOrderForm",
  components: {},
  props: {
    id: [Number, String],
    visible: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    },
    allowModify: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    },
    dateRange: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  watch: {
    visible: function visible() {
      this.dialogVisible = this.visible;
    },
    id: function id() {
      if (this.id) {
        this.getOrder();
      }
    }
  },
  data: function data() {
    return {
      dialogVisible: false,
      formLoading: false,
      hotelList: [],
      adjustUndertakers: [{
        id: 1,
        title: "商家"
      }, {
        id: 2,
        title: "平台"
      }],
      orderFormSubmitLoading: false,
      orderForm: {
        id: "",
        order_no: "",
        hotel_id: "",
        amount: "",
        reason: "",
        adjust_undertaker: undefined,
        end_date: ""
      },
      orderFormRules: {
        order_no: [{
          required: true,
          message: '请输入订单编号',
          trigger: 'blur'
        }],
        hotel_id: [{
          required: true,
          message: '请选择酒店名称',
          trigger: 'blur'
        }],
        amount: [{
          required: true,
          message: '请输入结算金额',
          trigger: 'blur'
        }],
        reason: [{
          required: true,
          message: '请输入原因',
          trigger: 'blur'
        }],
        adjust_undertaker: [{
          required: true,
          message: '请选择承担方',
          trigger: 'blur'
        }]
      },
      orderFormRooms: [],
      orderFormHotelRooms: [],
      inFinanceRange: false
    };
  },
  filters: {
    previceDateFormat: function previceDateFormat(value) {
      if (!value) return '';
      var date = value.toString();
      return "".concat(date.slice(0, 4), "-").concat(date.slice(4, 6), "-").concat(date.slice(6, 8));
    }
  },
  mounted: function mounted() {
    var _this = this;
    (0, _hotel.getHotelSimpleList)().then(function (response) {
      _this.hotelList = response.data;
    });
  },
  methods: {
    getOrder: function getOrder() {
      var _this2 = this;
      this.formLoading = true;
      (0, _finance_reco.getFinanceRecoOrder)(this.id).then(function (result) {
        var orderForm = result.data;
        _this2.formLoading = false;
        _this2.orderForm = {
          id: orderForm.id,
          order_no: orderForm.order_no,
          hotel_id: orderForm.hotel_id,
          amount: orderForm.amount,
          reason: orderForm.reason,
          end_date: orderForm.end_date,
          adjust_undertaker: orderForm.adjust_undertaker || undefined
        };
        _this2.inFinanceRange = _this2.inDateRange(orderForm.bill_end_date);
        _this2.formLoading = false;
      });
    },
    orderFormCloseHanlder: function orderFormCloseHanlder() {
      this.$refs['orderForm'].resetFields();
      this.$emit('update:id', "");
      this.$emit('update:visible', false);
    },
    orderFormSubmitHandler: function orderFormSubmitHandler() {
      var _this3 = this;
      this.$refs['orderForm'].validate(function (valid) {
        if (valid) {
          var form = JSON.parse(JSON.stringify(_this3.orderForm));
          form.amount = parseFloat(form.amount);
          _this3.editHandler(form);
        }
      });
    },
    editHandler: function editHandler(form) {
      var _this4 = this;
      this.orderFormSubmitLoading = true;
      (0, _finance_reco.updateMeiTtuanAdjustOrder)(form.id, form).then(function () {
        _this4.$message.success("编辑成功");
        _this4.$emit('success');
        _this4.dialogVisible = false;
      }).finally(function () {
        _this4.orderFormSubmitLoading = false;
      });
    },
    orderFormIsEdit: function orderFormIsEdit() {
      return !this.allowModify || !this.inFinanceRange;
    },
    inDateRange: function inDateRange(billEndDate) {
      if (this.dateRange.length == 0) {
        return false;
      }
      var endDateStr = billEndDate.toString();
      endDateStr = endDateStr.slice(0, 4) + '-' + endDateStr.slice(4, 6) + '-' + endDateStr.slice(6, 8);
      return endDateStr >= this.dateRange[0] && endDateStr <= this.dateRange[1];
    }
  }
};
exports.default = _default2;