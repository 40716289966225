"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _hotel = require("@/api/hotel");
var _moment = _interopRequireDefault(require("moment"));
var _statistics_hotel_sale = require("@/api/statistics_hotel_sale");
var _HighlightNumber = _interopRequireDefault(require("./components/HighlightNumber.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "HotelSaleStatistics",
  components: {
    HighlightNumber: _HighlightNumber.default
  },
  data: function data() {
    return {
      modeList: [],
      hotelList: [],
      list: [],
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 20,
        date: "",
        hotel_id: "",
        hotel_mode: ""
      },
      pagination: ""
    };
  },
  created: function created() {
    var _this = this;
    var yesterday = (0, _moment.default)().subtract(1, 'days').format("YYYYMMDD");
    this.listQuery.date = [yesterday, yesterday];
    (0, _hotel.getHotelSimpleList)().then(function (response) {
      _this.hotelList = response.data;
    });
    (0, _hotel.getModeList)().then(function (response) {
      _this.modeList = response.data;
    });
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this2 = this;
      this.listLoading = true;
      (0, _statistics_hotel_sale.getStatistics)(this.listQuery).then(function (response) {
        var data = response.data;
        _this2.list = data.data;
        _this2.pagination = data;
        _this2.listQuery.page = data.current_page;
        _this2.listLoading = false;
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    resetForm: function resetForm() {
      var yesterday = (0, _moment.default)().subtract(1, 'days').format("YYYYMMDD");
      this.listQuery.date = [yesterday, yesterday];
      this.listQuery.hotel_id = "";
      this.listQuery.hotel_mode = "";
    },
    toDetail: function toDetail(record) {
      var path = '/order/index';
      switch (record.hotel_mode) {
        case 3:
          // 按比例分成模式
          path = '/order/permutation';
          break;
        case 5:
          // 置换模式
          path = '/order/permutation_old';
          break;
        case 6:
          // 软包房模式
          path = '/order/soft';
          break;
        case 7:
          // 直营店模式
          path = '/order/self';
          break;
      }
      this.$router.push("".concat(path, "?hotel_id=").concat(record.hotel_id, "&pay_start_date=").concat(this.listQuery.date[0], "&pay_end_date=").concat(this.listQuery.date[1]));
    }
  }
};
exports.default = _default;