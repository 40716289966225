"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _net_bar = require("@/api/net_bar");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "NetBarThirdOrder",
  data: function data() {
    return {
      storeList: [],
      chargeModeList: [],
      list: [],
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 20,
        is_third: 1,
        card_id: "",
        nb_id: "",
        charge_mode: "",
        ended_at: ""
      },
      pagination: "",
      orderId: ""
    };
  },
  created: function created() {
    var _this = this;
    (0, _net_bar.getSimpleList)().then(function (response) {
      _this.storeList = response.data;
    });
    (0, _net_bar.getChargeModeList)().then(function (response) {
      _this.chargeModeList = response.data;
    });
    this.initQuery();
    this.getList();
  },
  methods: {
    initQuery: function initQuery() {
      if (this.$route.query.nb_id) {
        this.listQuery.nb_id = Number(this.$route.query.nb_id);
      }
      if (this.$route.query.card_id) {
        this.listQuery.card_id = this.$route.query.card_id;
      }
      if (this.$route.query.start_date) {
        this.listQuery.ended_at = [this.$route.query.start_date, this.$route.query.end_date];
      }
    },
    getList: function getList() {
      var _this2 = this;
      this.listLoading = true;
      (0, _net_bar.fetchOrderList)(this.listQuery).then(function (response) {
        var data = response.data;
        _this2.list = data.data;
        _this2.pagination = data;
        _this2.listQuery.page = data.current_page;
        _this2.listLoading = false;
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    getUseTime: function getUseTime(order) {
      var startTime = new Date(order.started_at).getTime() / 1000;
      var useTime = 0;
      if (order.status == 40) {
        var endTime = new Date(order.ended_at).getTime() / 1000;
        useTime = endTime - startTime;
      } else if (order.status == 30) {
        var now = new Date().getTime() / 1000;
        useTime = now - startTime;
      }
      // to H时m分
      var format = '';
      var h = parseInt(useTime / 3600);
      if (h > 0) format += "".concat(h, "\u5C0F\u65F6");
      var m = parseInt(useTime % 3600 / 60);
      if (m > 0) format += "".concat(m, "\u5206\u949F");
      return format || '0分钟';
    },
    handleReset: function handleReset() {
      this.listQuery.card_id = "";
      this.listQuery.nb_id = "";
      this.listQuery.charge_mode = "";
      this.listQuery.ended_at = "";
      this.handleFilter();
    },
    exportHandler: function exportHandler() {
      window.open((0, _net_bar.getOrderDownloadUrl)(this.listQuery));
    }
  }
};
exports.default = _default;