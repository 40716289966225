"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("@/api/net_bar");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "NetBarDevicePriceFormAllNightLog",
  components: {},
  props: {
    value: {
      type: Array
    }
  },
  data: function data() {
    return {
      prices: []
    };
  },
  watch: {
    value: function value(newVal) {
      this.prices = JSON.parse(JSON.stringify(newVal));
    }
  },
  created: function created() {},
  methods: {}
};
exports.default = _default;