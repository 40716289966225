var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
            title: "改价记录",
            width: "780px",
            top: "10vh",
          },
          on: { close: _vm.closeHandler },
        },
        [
          _c(
            "el-row",
            {},
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.listLoading,
                          expression: "listLoading",
                        },
                      ],
                      staticStyle: { width: "100%" },
                      attrs: { data: _vm.list, border: "" },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "device_price_title",
                          align: "center",
                          label: "配置名称",
                          "class-name": "overflow-on",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "created_at",
                          align: "center",
                          label: "操作时间",
                          "class-name": "overflow-on",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "create_uname",
                          align: "center",
                          label: "操作人",
                          "class-name": "overflow-on",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { align: "center", label: "查看", width: "120" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini", type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleDetailRedirect(
                                          scope.row
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                查看详情\n              "
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "pagination-container text-right" },
                    [
                      _c("el-pagination", {
                        attrs: {
                          background: "",
                          total: _vm.pagination.total,
                          "current-page": _vm.pagination.page,
                          "page-sizes": [10, 20, 50],
                          "page-size": _vm.pagination.limit,
                          layout: "total, sizes, prev, pager, next, jumper",
                        },
                        on: {
                          "size-change": _vm.handleSizeChange,
                          "current-change": _vm.handleCurrentChange,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.closeHandler } },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("NetBarDevicePriceLogDetail", {
        attrs: {
          storeId: _vm.storeId,
          devicePriceId: _vm.id,
          id: _vm.logId,
          visible: _vm.logVisible,
        },
        on: {
          "update:id": function ($event) {
            _vm.logId = $event
          },
          "update:visible": function ($event) {
            _vm.logVisible = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }