"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _activity = require("@/api/activity");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "PointShopOrder",
  data: function data() {
    return {
      activityId: '',
      activity: {
        prizes: []
      },
      prizeLoading: false,
      prizeTypes: [{
        id: 0,
        title: "谢谢参与"
      }, {
        id: 1,
        title: "实物"
      }, {
        id: 2,
        title: "优惠券"
      }, {
        id: 3,
        title: "积分"
      }],
      prizeTypeMap: {
        0: "谢谢参与",
        1: "实物",
        2: "优惠券",
        3: "积分"
      },
      list: [],
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 20,
        prize_type: "",
        created_at: []
      },
      pagination: "",
      statusOptions: [{
        value: '',
        label: '全部'
      }, {
        value: 0,
        label: '待发放'
      }, {
        value: 1,
        label: '已发放'
      }],
      statusName: {
        0: '待发放',
        1: '已发放'
      }
    };
  },
  created: function created() {
    this.activityId = this.$route.params.id;
    this.getActivity();
    this.getList();
  },
  methods: {
    getActivity: function getActivity() {
      var _this = this;
      this.prizeLoading = true;
      (0, _activity.getPointLottery)(this.activityId).then(function (response) {
        _this.activity = response.data;
        _this.prizeLoading = false;
      });
    },
    getList: function getList() {
      var _this2 = this;
      this.listLoading = true;
      (0, _activity.fetchPointLotteryLogList)(this.activityId, this.listQuery).then(function (response) {
        var data = response.data;
        _this2.list = data.data;
        _this2.pagination = data;
        _this2.listQuery.page = data.current_page;
        _this2.listLoading = false;
      });
    },
    onPrizeTypeChange: function onPrizeTypeChange() {
      this.handleFilter();
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleReset: function handleReset() {
      this.listQuery.prize_type = "";
      this.listQuery.created_at = [];
      this.handleFilter();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    writeOffHandler: function writeOffHandler(record) {
      var _this3 = this;
      var logId = record.id;
      this.$confirm('<span style="color:#fe6c6f;">确认发放后不可撤销</span>，你还要继续吗？', '温馨提示', {
        dangerouslyUseHTMLString: true,
        confirmButtonText: '继续',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _activity.writeOffPointLotteryLog)(_this3.activityId, logId).then(function () {
          var later = 0;
          // 非实体发放，后台是异步处理，延迟刷新
          if (record.prize_type != 1) {
            later = 1500;
          }
          setTimeout(function () {
            _this3.getList();
            _this3.$message.success("操作成功");
          }, later);
        });
      }).catch(function () {
        return;
      });
    }
  }
};
exports.default = _default;