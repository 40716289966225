"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _point_shop = require("@/api/point_shop");
var _hotel = require("@/api/hotel");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "PointShopOrder",
  data: function data() {
    return {
      list: [],
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 20,
        order_no: "",
        pickup_hotel_id: "",
        status: "",
        created_at: []
      },
      pagination: "",
      statusOptions: [{
        value: '',
        label: '全部'
      }, {
        value: 0,
        label: '待提货'
      }, {
        value: 1,
        label: '已提货'
      }, {
        value: 2,
        label: '已取消'
      }],
      statusName: {
        0: '待提货',
        1: '已提货',
        2: '已取消'
      },
      hotels: []
    };
  },
  created: function created() {
    this.getHotels();
    this.getList();
  },
  methods: {
    getHotels: function getHotels() {
      var _this = this;
      (0, _hotel.getHotelSimpleList)().then(function (response) {
        _this.hotels = response.data;
      });
    },
    getList: function getList() {
      var _this2 = this;
      this.listLoading = true;
      (0, _point_shop.fetchOrderList)(this.listQuery).then(function (response) {
        var data = response.data;
        _this2.list = data.data;
        _this2.pagination = data;
        _this2.listQuery.page = data.current_page;
        _this2.listLoading = false;
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleReset: function handleReset() {
      this.listQuery.order_no = "";
      this.listQuery.status = "";
      this.listQuery.pickup_hotel_id = "";
      this.listQuery.created_at = "";
      this.handleFilter();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    confirmOrderHandler: function confirmOrderHandler(orderId) {
      var _this3 = this;
      this.$confirm('<span style="color:#fe6c6f;">确认提货后不可撤销</span>，你还要继续吗？', '温馨提示', {
        dangerouslyUseHTMLString: true,
        confirmButtonText: '继续',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _point_shop.confirmOrder)(orderId).then(function () {
          _this3.getList();
          _this3.$message.success("操作成功");
        });
      }).catch(function () {
        return;
      });
    },
    cancelOrderHandler: function cancelOrderHandler(orderId) {
      var _this4 = this;
      this.$confirm('<span style="color:#fe6c6f;">取消后积分将回退至用户账户</span>，你还要继续吗？', '温馨提示', {
        dangerouslyUseHTMLString: true,
        confirmButtonText: '继续',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _point_shop.cancelOrder)(orderId).then(function () {
          _this4.getList();
          _this4.$message.success("操作成功");
        });
      }).catch(function () {
        return;
      });
    }
  }
};
exports.default = _default;