var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "detail-container",
      attrs: {
        visible: _vm.detailVisible,
        "close-on-click-modal": false,
        title: "工单角色管理",
        width: "720px",
        top: "10vh",
      },
      on: { close: _vm.doClose },
    },
    [
      _c(
        "div",
        { staticClass: "detail-table" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: {
                "row-key": "id",
                data: _vm.employees,
                fit: "",
                size: "small",
                "header-row-class-name": "detail-table-header",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { align: "center", label: "员工名称", width: "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(scope.row.remark || scope.row.nickname)
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "openid",
                  "class-name": "overflow-on",
                  width: "220",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(scope.row.gzh_openid) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { align: "center", label: "角色" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        !scope.row.isEdit
                          ? _c("div", [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    scope.row.roles
                                      .map(function (role) {
                                        return role.name
                                      })
                                      .join("，")
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c("i", {
                                staticClass: "el-icon-edit role-edit",
                                on: {
                                  click: function ($event) {
                                    return _vm.roleChangeHandler(scope.row)
                                  },
                                },
                              }),
                            ])
                          : _c(
                              "div",
                              [
                                _c(
                                  "el-select",
                                  {
                                    ref:
                                      "EmployeeRoleSelection_" + scope.row.id,
                                    attrs: {
                                      filterable: "",
                                      multiple: "",
                                      placeholder: "请选择角色",
                                    },
                                    on: {
                                      "visible-change":
                                        _vm.onEmployeeRoleVisibleChange,
                                    },
                                    model: {
                                      value: scope.row.roleIds,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "roleIds", $$v)
                                      },
                                      expression: "scope.row.roleIds",
                                    },
                                  },
                                  _vm._l(
                                    _vm.hotelEmployeeRoles,
                                    function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.name,
                                          value: item.id,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { align: "center", label: "操作", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-popconfirm",
                          {
                            attrs: { title: "你确定要删除该角色吗？" },
                            on: {
                              confirm: function ($event) {
                                return _vm.deleteHandler(scope.row)
                              },
                            },
                          },
                          [
                            _c(
                              "el-link",
                              {
                                attrs: { slot: "reference", type: "danger" },
                                slot: "reference",
                              },
                              [_vm._v("删除")]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.closeHandler } },
            [_vm._v("\n      确 定\n    ")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }