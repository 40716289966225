"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _net_bar = require("@/api/net_bar");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'NetBarOrderMigrateDetail',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    id: [Number, String]
  },
  watch: {
    visible: function visible() {
      this.dialogVisible = this.visible;
      if (this.visible) {
        this.getList();
      }
    }
  },
  data: function data() {
    return {
      dialogVisible: false,
      list: [],
      listLoading: false,
      pagination: {
        page: 1,
        limit: 10,
        total: 0
      },
      logId: undefined,
      logVisible: false
    };
  },
  created: function created() {},
  methods: {
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      (0, _net_bar.fetchOrderMigrateList)(this.id).then(function (response) {
        _this.list = response.data;
        _this.listLoading = false;
      });
    },
    closeHandler: function closeHandler() {
      this.dialogVisible = false;
      this.list = [];
      this.$emit("update:visible", this.dialogVisible);
    }
  }
};
exports.default = _default;