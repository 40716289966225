var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container calendar-list-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "180px" },
              attrs: { filterable: "", clearable: "", placeholder: "酒店" },
              model: {
                value: _vm.listQuery.hotel_id,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "hotel_id", $$v)
                },
                expression: "listQuery.hotel_id",
              },
            },
            _vm._l(_vm.hotelList, function (item) {
              return _c("el-option", {
                key: item.hotel_id,
                attrs: { label: item.name, value: item.hotel_id },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c("el-date-picker", {
            staticClass: "filter-item filter-item-daterange",
            staticStyle: { width: "300px" },
            attrs: {
              type: "daterange",
              clearable: "",
              "range-separator": "至",
              "start-placeholder": "账单日期",
              "end-placeholder": "账单日期",
              "value-format": "yyyy-MM-dd",
            },
            on: { change: _vm.onBillDateChange },
            model: {
              value: _vm.listQuery.bill_date,
              callback: function ($$v) {
                _vm.$set(_vm.listQuery, "bill_date", $$v)
              },
              expression: "listQuery.bill_date",
            },
          }),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "160px" },
              attrs: { clearable: "", placeholder: "状态" },
              model: {
                value: _vm.listQuery.audit_status,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "audit_status", $$v)
                },
                expression: "listQuery.audit_status",
              },
            },
            _vm._l(_vm.statusOptions, function (item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.label, value: item.value },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              staticStyle: { "margin-left": "10px" },
              attrs: { type: "primary" },
              on: { click: _vm.handleFilter },
            },
            [_vm._v("\n      查询\n    ")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary" },
              on: { click: _vm.exportHandler },
            },
            [_vm._v("\n      导出\n    ")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          key: "tableList",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            "element-loading-text": "给我一点时间",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "申诉ID",
              "class-name": "overflow-on",
              prop: "id",
              width: "80",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "申诉酒店",
              "class-name": "overflow-on",
              prop: "hotel_id",
              "min-width": "145",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.hotel_name) +
                        "（" +
                        _vm._s(scope.row.hotel_id) +
                        "）\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "申诉账期",
              "class-name": "overflow-on",
              prop: "bill_range",
              width: "160",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.start_date) +
                        " - " +
                        _vm._s(scope.row.end_date) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "申诉类型",
              "class-name": "overflow-on",
              prop: "type_name",
              "min-width": "120",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "描述",
              "class-name": "overflow-on",
              prop: "description",
              "min-width": "145",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "申诉金额",
              "class-name": "overflow-on",
              prop: "amount",
              "min-width": "100",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          scope.row.amount != null ? scope.row.amount : "--"
                        ) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "处理金额",
              "class-name": "overflow-on",
              prop: "audit_amount",
              "min-width": "100",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          scope.row.audit_amount != null
                            ? scope.row.audit_amount
                            : "--"
                        ) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "联系方式",
              "class-name": "overflow-on",
              prop: "contact_tel",
              "min-width": "120",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "申诉时间",
              "class-name": "overflow-on",
              prop: "created_at",
              "min-width": "160",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "当前状态",
              "class-name": "overflow-on",
              prop: "audit_status",
              "min-width": "100",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.audit_status
                      ? _c("span", [_vm._v("已处理")])
                      : _c("span", { staticClass: "text-danger" }, [
                          _vm._v("待处理"),
                        ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              fixed: "right",
              label: "操作",
              width: "120",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.audit_status == 0
                      ? _c(
                          "el-link",
                          {
                            staticClass: "link-right",
                            attrs: { type: "primary", underline: false },
                            on: {
                              click: function ($event) {
                                return _vm.auditHandler(scope.row)
                              },
                            },
                          },
                          [_vm._v("\n          处理\n        ")]
                        )
                      : _c(
                          "el-link",
                          {
                            staticClass: "link-right",
                            attrs: { type: "primary", underline: false },
                            on: {
                              click: function ($event) {
                                return _vm.showHandler(scope.row)
                              },
                            },
                          },
                          [_vm._v("\n          查看\n        ")]
                        ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "pagination-container text-right" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              total: _vm.pagination.total,
              "current-page": _vm.listQuery.page,
              "page-size": _vm.listQuery.limit,
              layout: "total, sizes, prev, pager, next, jumper",
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("appeal-bill-form", {
        attrs: { billId: _vm.appealBillId, visible: _vm.appealBillFormVisible },
        on: {
          "update:billId": function ($event) {
            _vm.appealBillId = $event
          },
          "update:bill-id": function ($event) {
            _vm.appealBillId = $event
          },
          "update:visible": function ($event) {
            _vm.appealBillFormVisible = $event
          },
          success: _vm.getList,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }