"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _net_bar = require("@/api/net_bar");
var _price_calendar_log = _interopRequireDefault(require("@/views/net_bar/components/price_calendar_log.vue"));
var _form_pack_log = _interopRequireDefault(require("./form_pack_log.vue"));
var _form_all_night_log = _interopRequireDefault(require("./form_all_night_log.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: "NetBarDevicePriceLogDetail",
  components: {
    PriceCalendarLog: _price_calendar_log.default,
    NetBarDevicePriceFormPackLog: _form_pack_log.default,
    NetBarDevicePriceFormAllNightLog: _form_all_night_log.default
  },
  props: {
    id: [Number, String],
    storeId: {
      type: [String, Number]
    },
    devicePriceId: {
      type: [String, Number]
    },
    visible: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    }
  },
  watch: {
    id: function id() {},
    visible: function visible() {
      this.dialogVisible = this.visible;
      if (this.id && this.visible) {
        this.getDetail();
      }
    }
  },
  data: function data() {
    return {
      dialogVisible: false,
      loading: false,
      priceCalendarType: "temp_price",
      priceType: {
        temp_price: {
          label: "临时卡"
        },
        member_price: {
          label: "会员卡"
        },
        pack_price: {
          label: "包时段"
        },
        all_night_price: {
          label: "通宵"
        }
      },
      log: {
        temp_price: {},
        // 会员价
        member_price: {},
        // 临时价
        pack_price: [],
        // 包时段
        all_night_price: [] // 通宵
      }
    };
  },
  mounted: function mounted() {},
  methods: {
    getDetail: function getDetail() {
      var _this = this;
      this.loading = true;
      (0, _net_bar.fetchStoreDevicePriceLog)(this.storeId, this.devicePriceId, this.id).then(function (result) {
        var data = result.data;
        _this.log.temp_price = data.detail.temp_price || {};
        _this.log.member_price = data.detail.member_price || {};
        _this.log.pack_price = data.detail.pack_price || [];
        _this.log.all_night_price = data.detail.all_night_price || [];
        _this.loading = false;
      });
    },
    closeHanlder: function closeHanlder() {
      this.$emit('update:id', "");
      this.$emit('update:visible', false);
      this.priceCalendarType = "temp_price";
      this.log = {
        temp_price: {},
        member_price: {},
        pack_price: [],
        // 包时段
        all_night_price: [] // 通宵
      };
    }
  }
};
exports.default = _default2;