var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container calendar-list-container" },
    [
      _c(
        "div",
        {
          staticClass: "filter-container",
          staticStyle: { "text-align": "right" },
        },
        [
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              staticStyle: { "margin-left": "10px" },
              attrs: { type: "primary", disabled: _vm.rooms.length <= 0 },
              on: { click: _vm.setBatchPriceHandler },
            },
            [_vm._v("\n      批量改价\n    ")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              staticStyle: { "margin-left": "10px" },
              attrs: { type: "primary" },
              on: { click: _vm.addAction },
            },
            [_vm._v("\n      添加\n    ")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          key: "id",
          ref: "table",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.rooms,
            "element-loading-text": "给我一点时间",
            border: "",
            fit: "",
            "highlight-current-row": "",
            "row-key": "id",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "ID",
              "class-name": "overflow-on",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v("\n        " + _vm._s(scope.row.id) + "\n      "),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "名称",
              "class-name": "overflow-on",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v("\n        " + _vm._s(scope.row.name) + "\n      "),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "图片", width: "210" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("img", {
                      staticStyle: { width: "150px" },
                      attrs: { src: scope.row.imageList[0] },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "今日价格", "max-width": "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.today_price))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "最新参考价" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          scope.row.meituan ? scope.row.meituan : "未匹配到房型"
                        )
                      ),
                      scope.row.meituan
                        ? _c("span", { staticClass: "meituan-time" }, [
                            _c("br"),
                            _vm._v(_vm._s(scope.row.meituan_time)),
                          ])
                        : _vm._e(),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "今日房态",
              "render-header": _vm.renderTipsHeader,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.stock >= 0
                      ? _c("span", [
                          _vm._v(
                            _vm._s(scope.row.stock - scope.row.sale_num) +
                              "/" +
                              _vm._s(scope.row.sale_num)
                          ),
                        ])
                      : _c("span", [
                          _vm._v("未设置/" + _vm._s(scope.row.sale_num)),
                        ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "状态" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.close
                      ? _c("span", [_vm._v("已关房")])
                      : _c("span", [_vm._v("售卖中")]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "left",
              fixed: "right",
              label: "操作",
              width: "200",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.updateAction(scope.row)
                          },
                        },
                      },
                      [_vm._v("\n          编辑\n        ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticStyle: { color: "#F56C6C" },
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.deleteRoom(scope.row.id)
                          },
                        },
                      },
                      [_vm._v("\n          删除\n        ")]
                    ),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handleCheck(scope.row, "price")
                          },
                        },
                      },
                      [_vm._v("\n          价格设置\n        ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handleCheck(scope.row, "stock")
                          },
                        },
                      },
                      [_vm._v("\n          库存管理\n        ")]
                    ),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.showPriceRecord(scope.row.id)
                          },
                        },
                      },
                      [_vm._v("\n          改价记录\n        ")]
                    ),
                    _vm._v(" "),
                    scope.row.info != null && scope.row.info.room_no.length > 0
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.qrCode(scope.row.id)
                              },
                            },
                          },
                          [_vm._v("\n          续房码\n        ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handleCheck(scope.row, "room_stat")
                          },
                        },
                      },
                      [_vm._v("\n          房态管理\n        ")]
                    ),
                    _vm._v(" "),
                    scope.row.close
                      ? _c(
                          "el-button",
                          {
                            staticStyle: { color: "#67C23A" },
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.openRoom(scope.row.id)
                              },
                            },
                          },
                          [_vm._v("\n          恢复\n        ")]
                        )
                      : _c(
                          "el-button",
                          {
                            staticStyle: { color: "#67C23A" },
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.close(scope.row.id)
                              },
                            },
                          },
                          [_vm._v("\n          关房\n        ")]
                        ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "改价记录",
            visible: _vm.recordVisible,
            width: "750px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.recordVisible = $event
            },
          },
        },
        [
          _c(
            "el-alert",
            { attrs: { type: "info", "show-icon": "", closable: false } },
            [
              _c(
                "span",
                [
                  _vm._v("默认展示最近 "),
                  _c("span", { staticStyle: { "font-size": "14px" } }, [
                    _vm._v("30"),
                  ]),
                  _vm._v(" 天内的改价记录，如需查看所有记录，请点击 "),
                  _c(
                    "el-link",
                    {
                      staticStyle: { "font-size": "12px" },
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.getPriceAllRecord()
                        },
                      },
                    },
                    [_vm._v("查看更多")]
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.priceRecordLoading,
                  expression: "priceRecordLoading",
                },
              ],
              attrs: { data: _vm.recordList, height: "360" },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "username",
                  align: "center",
                  label: "修改人",
                  width: "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "time",
                  align: "center",
                  label: "修改时间",
                  "min-width": "160",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "address",
                  align: "center",
                  label: "修改后",
                  "min-width": "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.is_part == 1
                          ? _c("span", [
                              _vm._v("周中：" + _vm._s(scope.row.normal_price)),
                              _c("br"),
                              _vm._v("周末：" + _vm._s(scope.row.week_price)),
                            ])
                          : _c("span", [
                              _vm._v("通用：" + _vm._s(scope.row.normal_price)),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "address",
                  align: "center",
                  label: "变更时段",
                  "min-width": "180",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              scope.row.start_date + "~" + scope.row.end_date
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "room-form-dialog",
          attrs: {
            title: _vm.dialogTitle,
            visible: _vm.dialogFormVisible,
            top: "10vh",
            width: "650px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
            close: _vm.onRoomFormClosed,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "roomForm",
              staticClass: "small-space",
              attrs: {
                model: _vm.roomForm,
                rules: _vm.formRules,
                "label-position": "right",
                "label-width": "125px",
                "label-suffix": ":",
              },
            },
            [
              _c(
                "el-divider",
                {
                  staticClass: "divider-title",
                  attrs: { "content-position": "left" },
                },
                [_vm._v("\n        基本信息\n      ")]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "类型", required: "", prop: "type" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        "allow-create": "",
                        filterable: "",
                        "default-first-option": "",
                        placeholder: "类型",
                      },
                      model: {
                        value: _vm.roomForm.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.roomForm, "type", $$v)
                        },
                        expression: "roomForm.type",
                      },
                    },
                    _vm._l(_vm.typeList, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "名称", required: "", prop: "name" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.roomForm.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.roomForm, "name", $$v)
                      },
                      expression: "roomForm.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "图片", prop: "images" } },
                [
                  _c(
                    "el-upload",
                    {
                      attrs: {
                        action: _vm.uploadUrl,
                        "list-type": "picture-card",
                        headers: _vm.header,
                        "on-remove": _vm.handleRemove,
                        "file-list": _vm.roomForm.images,
                        "on-success": _vm.handleImageSuccess,
                      },
                    },
                    [_c("i", { staticClass: "el-icon-plus" })]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticStyle: { "margin-bottom": "12px" },
                  attrs: { label: "默认预订价", required: "", prop: "price" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "price-names" },
                    [
                      _c("div", { staticClass: "price-label" }, [
                        _vm._v("周中（周日-周四）"),
                      ]),
                      _vm._v(" "),
                      _c("el-input", {
                        attrs: { clall: "price-input" },
                        model: {
                          value: _vm.roomForm.price,
                          callback: function ($$v) {
                            _vm.$set(_vm.roomForm, "price", $$v)
                          },
                          expression: "roomForm.price",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.roomForm.price_change_delay &&
                  _vm.roomForm.price_change_delay.price
                    ? _c("div", { staticClass: "price-change-tips" }, [
                        _vm._v(
                          "\n          最新价：" +
                            _vm._s(_vm.roomForm.price_change_delay.price) +
                            "，生效时间：" +
                            _vm._s(_vm.roomForm.price_change_delay.eff_date) +
                            "\n        "
                        ),
                      ])
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { required: "", prop: "weekend_price" } },
                [
                  _c(
                    "div",
                    { staticClass: "price-names" },
                    [
                      _c("div", { staticClass: "price-label" }, [
                        _vm._v("周末（周五-周六）"),
                      ]),
                      _vm._v(" "),
                      _c("el-input", {
                        attrs: { clall: "price-input" },
                        model: {
                          value: _vm.roomForm.weekend_price,
                          callback: function ($$v) {
                            _vm.$set(_vm.roomForm, "weekend_price", $$v)
                          },
                          expression: "roomForm.weekend_price",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.roomForm.price_change_delay &&
                  _vm.roomForm.price_change_delay.weekend_price
                    ? _c("div", { staticClass: "price-change-tips" }, [
                        _vm._v(
                          "\n          最新价：" +
                            _vm._s(
                              _vm.roomForm.price_change_delay.weekend_price
                            ) +
                            "，生效时间：" +
                            _vm._s(_vm.roomForm.price_change_delay.eff_date) +
                            "\n        "
                        ),
                      ])
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticStyle: { "margin-bottom": "12px" },
                  attrs: {
                    label: "默认底价",
                    required: _vm.isSoftMode(),
                    prop: "base_price",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "price-names" },
                    [
                      _c("div", { staticClass: "price-label" }, [
                        _vm._v("周中（周日-周四）"),
                      ]),
                      _vm._v(" "),
                      _c("el-input", {
                        attrs: { clall: "price-input" },
                        model: {
                          value: _vm.roomForm.base_price,
                          callback: function ($$v) {
                            _vm.$set(_vm.roomForm, "base_price", $$v)
                          },
                          expression: "roomForm.base_price",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.roomForm.price_change_delay &&
                  _vm.roomForm.price_change_delay.base_price
                    ? _c("div", { staticClass: "price-change-tips" }, [
                        _vm._v(
                          "\n          最新价：" +
                            _vm._s(_vm.roomForm.price_change_delay.base_price) +
                            "，生效时间：" +
                            _vm._s(_vm.roomForm.price_change_delay.eff_date) +
                            "\n        "
                        ),
                      ])
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _c("el-form-item", { attrs: { prop: "weekend_base_price" } }, [
                _c(
                  "div",
                  { staticClass: "price-names" },
                  [
                    _c("div", { staticClass: "price-label" }, [
                      _vm._v("周末（周五-周六）"),
                    ]),
                    _vm._v(" "),
                    _c("el-input", {
                      attrs: { clall: "price-input" },
                      model: {
                        value: _vm.roomForm.weekend_base_price,
                        callback: function ($$v) {
                          _vm.$set(_vm.roomForm, "weekend_base_price", $$v)
                        },
                        expression: "roomForm.weekend_base_price",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.roomForm.price_change_delay &&
                _vm.roomForm.price_change_delay.weekend_base_price
                  ? _c("div", { staticClass: "price-change-tips" }, [
                      _vm._v(
                        "\n          最新价：" +
                          _vm._s(
                            _vm.roomForm.price_change_delay.weekend_base_price
                          ) +
                          "，生效时间：" +
                          _vm._s(_vm.roomForm.price_change_delay.eff_date) +
                          "\n        "
                      ),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _vm.enableShareRatio()
                ? [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "商家分润比例",
                          prop: "is_share_ratio",
                        },
                      },
                      [
                        _c("el-switch", {
                          attrs: {
                            "active-text": "开启",
                            "inactive-text": "关闭",
                          },
                          model: {
                            value: _vm.roomForm.is_share_ratio,
                            callback: function ($$v) {
                              _vm.$set(_vm.roomForm, "is_share_ratio", $$v)
                            },
                            expression: "roomForm.is_share_ratio",
                          },
                        }),
                        _vm._v(" "),
                        _vm.roomForm.is_share_ratio
                          ? _c("el-slider", {
                              attrs: { "show-input": "" },
                              model: {
                                value: _vm.roomForm.share_ratio,
                                callback: function ($$v) {
                                  _vm.$set(_vm.roomForm, "share_ratio", $$v)
                                },
                                expression: "roomForm.share_ratio",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "是否有窗", prop: "window" } },
                [
                  _c("el-switch", {
                    attrs: { "active-text": "有", "inactive-text": "无" },
                    model: {
                      value: _vm.roomForm.window,
                      callback: function ($$v) {
                        _vm.$set(_vm.roomForm, "window", $$v)
                      },
                      expression: "roomForm.window",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "面积", required: "", prop: "area" } },
                [
                  _c(
                    "el-input",
                    {
                      model: {
                        value: _vm.roomForm.area,
                        callback: function ($$v) {
                          _vm.$set(_vm.roomForm, "area", $$v)
                        },
                        expression: "roomForm.area",
                      },
                    },
                    [_c("template", { slot: "append" }, [_vm._v("m²")])],
                    2
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "机位数", prop: "seating_amount" } },
                [
                  _c("el-input-number", {
                    attrs: { min: 1, max: 5 },
                    model: {
                      value: _vm.roomForm.seating_amount,
                      callback: function ($$v) {
                        _vm.$set(_vm.roomForm, "seating_amount", $$v)
                      },
                      expression: "roomForm.seating_amount",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-divider",
                {
                  staticClass: "divider-title",
                  attrs: { "content-position": "left" },
                },
                [_vm._v("\n        配置信息\n      ")]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "显卡",
                    required: "",
                    prop: "info.graphic_card",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        "allow-create": "",
                        filterable: "",
                        "default-first-option": "",
                        placeholder: "显卡",
                      },
                      model: {
                        value: _vm.roomForm.info.graphic_card,
                        callback: function ($$v) {
                          _vm.$set(_vm.roomForm.info, "graphic_card", $$v)
                        },
                        expression: "roomForm.info.graphic_card",
                      },
                    },
                    _vm._l(_vm.CardList, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "显示器",
                    required: "",
                    prop: "info.display",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        filterable: "",
                        "allow-create": "",
                        "default-first-option": "",
                      },
                      model: {
                        value: _vm.roomForm.info.display,
                        callback: function ($$v) {
                          _vm.$set(_vm.roomForm.info, "display", $$v)
                        },
                        expression: "roomForm.info.display",
                      },
                    },
                    _vm._l(_vm.displayList, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "内存", required: "", prop: "info.ram" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        "allow-create": "",
                        filterable: "",
                        "default-first-option": "",
                      },
                      model: {
                        value: _vm.roomForm.info.ram,
                        callback: function ($$v) {
                          _vm.$set(_vm.roomForm.info, "ram", $$v)
                        },
                        expression: "roomForm.info.ram",
                      },
                    },
                    _vm._l(_vm.ramList, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "房间号", prop: "info.room_no" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "多个房间中间用@分隔，如305@406" },
                    model: {
                      value: _vm.roomForm.info.room_no,
                      callback: function ($$v) {
                        _vm.$set(_vm.roomForm.info, "room_no", $$v)
                      },
                      expression: "roomForm.info.room_no",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "默认库存", prop: "stock" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "" },
                    model: {
                      value: _vm.roomForm.stock,
                      callback: function ($$v) {
                        _vm.$set(_vm.roomForm, "stock", $$v)
                      },
                      expression: "roomForm.stock",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "统计库存", prop: "stat_stock" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "统计库存只用于数据统计" },
                    model: {
                      value: _vm.roomForm.stat_stock,
                      callback: function ($$v) {
                        _vm.$set(_vm.roomForm, "stat_stock", $$v)
                      },
                      expression: "roomForm.stat_stock",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "第三方平台名称", prop: "ota_names" } },
                [
                  _vm._l(_vm.otas, function (item) {
                    return [
                      _c(
                        "div",
                        { key: item.id, staticClass: "ota-names" },
                        [
                          _c("div", { staticClass: "ota-label" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(item.title) +
                                "：\n            "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("el-input", {
                            attrs: {
                              clall: "ota-input",
                              placeholder:
                                "多个名称中间用逗号分隔，如：电竞房,单机房",
                            },
                            model: {
                              value: _vm.roomForm.ota_names[item.id],
                              callback: function ($$v) {
                                _vm.$set(_vm.roomForm.ota_names, item.id, $$v)
                              },
                              expression: "roomForm.ota_names[item.id]",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  }),
                ],
                2
              ),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = false
                    },
                  },
                },
                [_vm._v("\n        取 消\n      ")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    loading: _vm.onRoomFormConfirmLoading,
                  },
                  on: { click: _vm.createRoom },
                },
                [_vm._v("\n        确 定\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "设置库存",
            visible: _vm.dialogStockVisible,
            width: "650px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogStockVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              staticClass: "small-space",
              staticStyle: { width: "500px", "margin-left": "20px" },
              attrs: {
                model: _vm.stockForm,
                "label-position": "left",
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "名称" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "300px" },
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.stockForm.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.stockForm, "name", $$v)
                      },
                      expression: "stockForm.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "时间段" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "daterange",
                      "range-separator": "~",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                      "value-format": "yyyy-MM-dd",
                      "picker-options": _vm.datePickerOptions,
                    },
                    model: {
                      value: _vm.stockForm.date,
                      callback: function ($$v) {
                        _vm.$set(_vm.stockForm, "date", $$v)
                      },
                      expression: "stockForm.date",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "库存" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "300px" },
                    model: {
                      value: _vm.stockForm.stock,
                      callback: function ($$v) {
                        _vm.$set(_vm.stockForm, "stock", $$v)
                      },
                      expression: "stockForm.stock",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogStockVisible = false
                    },
                  },
                },
                [_vm._v("\n        取 消\n      ")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.putStock } },
                [_vm._v("\n        确 定\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "设置房态",
            visible: _vm.dialogRoomStatVisible,
            width: "650px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogRoomStatVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "roomStatForm",
              staticClass: "small-space",
              staticStyle: { width: "500px", "margin-left": "20px" },
              attrs: {
                model: _vm.roomStatForm,
                rules: _vm.roomStatFormRules,
                "label-position": "left",
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "名称" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "300px" },
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.roomStatForm.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.roomStatForm, "name", $$v)
                      },
                      expression: "roomStatForm.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "时间段", prop: "date" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "daterange",
                      "range-separator": "~",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                      "value-format": "yyyy-MM-dd",
                      "picker-options": _vm.datePickerOptions,
                    },
                    model: {
                      value: _vm.roomStatForm.date,
                      callback: function ($$v) {
                        _vm.$set(_vm.roomStatForm, "date", $$v)
                      },
                      expression: "roomStatForm.date",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "房态", prop: "open" } },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-value": 1,
                      "inactive-value": 0,
                      "active-text": "开",
                      "inactive-text": "关",
                    },
                    model: {
                      value: _vm.roomStatForm.open,
                      callback: function ($$v) {
                        _vm.$set(_vm.roomStatForm, "open", $$v)
                      },
                      expression: "roomStatForm.open",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogRoomStatVisible = false
                    },
                  },
                },
                [_vm._v("\n        取 消\n      ")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.putRoomStat } },
                [_vm._v("\n        确 定\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "设置价格",
            visible: _vm.dialogPriceVisible,
            width: "700px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogPriceVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "priceForm",
              staticClass: "small-space",
              staticStyle: { width: "650px", "margin-left": "50px" },
              attrs: {
                model: _vm.priceForm,
                rules: _vm.priceRules,
                "label-position": "left",
                "label-width": "100px",
                "label-suffix": ":",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "名称" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "300px" },
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.priceForm.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.priceForm, "name", $$v)
                      },
                      expression: "priceForm.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "调价时段", prop: "date" } },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "300px" },
                    attrs: {
                      type: "daterange",
                      "range-separator": "~",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                      "value-format": "yyyy-MM-dd",
                      clearable: false,
                      "picker-options": _vm.datePickerOptions,
                    },
                    model: {
                      value: _vm.priceForm.date,
                      callback: function ($$v) {
                        _vm.$set(_vm.priceForm, "date", $$v)
                      },
                      expression: "priceForm.date",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "分时计价", prop: "is_part" } },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 0 },
                      model: {
                        value: _vm.priceForm.is_part,
                        callback: function ($$v) {
                          _vm.$set(_vm.priceForm, "is_part", $$v)
                        },
                        expression: "priceForm.is_part",
                      },
                    },
                    [_vm._v("\n          不分平时周末\n        ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 1 },
                      model: {
                        value: _vm.priceForm.is_part,
                        callback: function ($$v) {
                          _vm.$set(_vm.priceForm, "is_part", $$v)
                        },
                        expression: "priceForm.is_part",
                      },
                    },
                    [
                      _vm._v(
                        "\n          区分周末（周末指周五，周六）\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "预订价", prop: "normal_price" } },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 9 } },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: {
                                placeholder:
                                  "今日售价" + (_vm.getRoomTodayPrice() || "?"),
                              },
                              model: {
                                value: _vm.priceForm.normal_price,
                                callback: function ($$v) {
                                  _vm.$set(_vm.priceForm, "normal_price", $$v)
                                },
                                expression: "priceForm.normal_price",
                              },
                            },
                            [
                              _c("template", { slot: "prepend" }, [
                                _vm._v("平时价"),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.priceForm.is_part == 1
                        ? [
                            _c(
                              "el-col",
                              {
                                staticStyle: { "margin-left": "30px" },
                                attrs: { span: 9 },
                              },
                              [
                                _c(
                                  "el-input",
                                  {
                                    model: {
                                      value: _vm.priceForm.week_price,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.priceForm,
                                          "week_price",
                                          $$v
                                        )
                                      },
                                      expression: "priceForm.week_price",
                                    },
                                  },
                                  [
                                    _c("template", { slot: "prepend" }, [
                                      _vm._v("周末价"),
                                    ]),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.isSoftMode()
                ? _c(
                    "el-form-item",
                    { attrs: { label: "底价", prop: "base_normal_price" } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 9 } },
                            [
                              _c(
                                "el-input",
                                {
                                  attrs: { placeholder: "不填不修改" },
                                  model: {
                                    value: _vm.priceForm.base_normal_price,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.priceForm,
                                        "base_normal_price",
                                        $$v
                                      )
                                    },
                                    expression: "priceForm.base_normal_price",
                                  },
                                },
                                [
                                  _c("template", { slot: "prepend" }, [
                                    _vm._v("平时价"),
                                  ]),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.priceForm.is_part == 1
                            ? [
                                _c(
                                  "el-col",
                                  {
                                    staticStyle: { "margin-left": "30px" },
                                    attrs: { span: 9 },
                                  },
                                  [
                                    _c(
                                      "el-input",
                                      {
                                        attrs: { placeholder: "不填不修改" },
                                        model: {
                                          value: _vm.priceForm.base_week_price,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.priceForm,
                                              "base_week_price",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "priceForm.base_week_price",
                                        },
                                      },
                                      [
                                        _c("template", { slot: "prepend" }, [
                                          _vm._v("周末价"),
                                        ]),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.priceForm.is_part == 1
                ? _c("p", { staticStyle: { "margin-left": "100px" } }, [
                    _vm._v("注：周末指入住日为周五及周六"),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogPriceVisible = false
                    },
                  },
                },
                [_vm._v("\n        取 消\n      ")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.putPrice } },
                [_vm._v("\n        确 定\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "批量改价",
            visible: _vm.dialogBatchPriceVisible,
            width: "700px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogBatchPriceVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "batchPriceForm",
              staticClass: "small-space",
              staticStyle: { width: "650px", "margin-left": "30px" },
              attrs: {
                model: _vm.batchPriceForm,
                rules: _vm.batchPriceRules,
                "label-position": "left",
                "label-width": "100px",
                "label-suffix": ":",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "调价时段", prop: "date" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "daterange",
                      "range-separator": "~",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                      "value-format": "yyyy-MM-dd",
                      "picker-options": _vm.datePickerOptions,
                    },
                    model: {
                      value: _vm.batchPriceForm.date,
                      callback: function ($$v) {
                        _vm.$set(_vm.batchPriceForm, "date", $$v)
                      },
                      expression: "batchPriceForm.date",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "分时计价", prop: "is_part", required: "" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.batchPriceForm.is_part,
                        callback: function ($$v) {
                          _vm.$set(_vm.batchPriceForm, "is_part", $$v)
                        },
                        expression: "batchPriceForm.is_part",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [
                        _vm._v("不分平时周末"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("区分周末（周末指周五，周六）"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  staticStyle: { width: "600px" },
                  attrs: { data: _vm.rooms, border: "" },
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "name", label: "房型名称", align: "center" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "total_price",
                      label: "今日价格",
                      align: "center",
                      width: "80",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.getRoomTodayPrice(scope.row.id)) +
                                "\n          "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "normal_price",
                      label: "平时价（周日~周四）",
                      align: "center",
                      width: "195",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("el-input", {
                              attrs: {
                                size: "small",
                                placeholder: "输入价格，不填则不修改",
                              },
                              model: {
                                value:
                                  _vm.batchPriceForm.rooms[scope.row.id]
                                    .normal_price,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.batchPriceForm.rooms[scope.row.id],
                                    "normal_price",
                                    $$v
                                  )
                                },
                                expression:
                                  "batchPriceForm.rooms[scope.row.id].normal_price",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "week_price",
                      label: "周末价（周五~周六）",
                      align: "center",
                      width: "195",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm.batchPriceForm.is_part
                              ? _c("el-input", {
                                  attrs: {
                                    size: "small",
                                    placeholder: "输入价格，不填则不修改",
                                  },
                                  model: {
                                    value:
                                      _vm.batchPriceForm.rooms[scope.row.id]
                                        .week_price,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.batchPriceForm.rooms[scope.row.id],
                                        "week_price",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "batchPriceForm.rooms[scope.row.id].week_price",
                                  },
                                })
                              : _c(
                                  "span",
                                  { staticStyle: { color: "#909399" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.batchPriceForm.rooms[scope.row.id]
                                          .normal_price || "不区分"
                                      )
                                    ),
                                  ]
                                ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogBatchPriceVisible = false
                    },
                  },
                },
                [_vm._v("\n        取 消\n      ")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.batchPutPrice },
                },
                [_vm._v("\n        确 定\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "dialog-calendar",
          attrs: {
            title: _vm.calendarTitle,
            visible: _vm.dialogCalendarVisible,
            width: "1000px",
            "destroy-on-close": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogCalendarVisible = $event
            },
          },
        },
        [
          _c("el-calendar", {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.calendarLoad,
                expression: "calendarLoad",
              },
            ],
            attrs: { "element-loading-text": "给我一点时间" },
            scopedSlots: _vm._u([
              {
                key: "dateCell",
                fn: function (ref) {
                  var date = ref.date
                  var data = ref.data
                  return [
                    _c(
                      "div",
                      {
                        class:
                          _vm.nowDay <= data.day.replace(/-/g, "")
                            ? ""
                            : "calendar-prev",
                      },
                      [
                        _vm.calendarType == "price"
                          ? _c(
                              "div",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.setPrice(data, date)
                                  },
                                },
                              },
                              [
                                _c("div", { staticClass: "calendar-day" }, [
                                  _vm._v(_vm._s(data.day.slice(-2))),
                                ]),
                                _vm._v(" "),
                                data.type == "current-month" &&
                                _vm.refreshPrice(data)
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "calendar-price",
                                        class: {
                                          "calendar-price-l2": _vm.isSoftMode(),
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "preorder-price" },
                                          [
                                            _vm._v(
                                              "预订价：" +
                                                _vm._s(
                                                  _vm.priceList[
                                                    data.day.slice(-2) - 1
                                                  ]
                                                )
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _vm.isSoftMode()
                                          ? _c(
                                              "div",
                                              { staticClass: "base-price" },
                                              [
                                                _vm._v(
                                                  "底价：" +
                                                    _vm._s(
                                                      _vm.basePriceList[
                                                        data.day.slice(-2) - 1
                                                      ]
                                                    )
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            )
                          : _vm.calendarType == "stock" ||
                            _vm.calendarType == "room_stat"
                          ? _c(
                              "div",
                              {
                                on: {
                                  click: function ($event) {
                                    _vm.calendarType == "stock"
                                      ? _vm.setStock(data, date)
                                      : _vm.setRoomStat(data, date)
                                  },
                                },
                              },
                              [
                                _c("div", { staticClass: "calendar-day" }, [
                                  _vm._v(_vm._s(data.day.slice(-2))),
                                ]),
                                _vm._v(" "),
                                data.type == "current-month" &&
                                _vm.refreshStock(data)
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "calendar-stock",
                                        on: {
                                          click: function ($event) {
                                            _vm.calendarType == "stock"
                                              ? _vm.setStock(data, date)
                                              : _vm.setRoomStat(data, date)
                                          },
                                        },
                                      },
                                      [
                                        _c("div", {
                                          staticStyle: {
                                            "text-align": "center",
                                          },
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.stockList[
                                                data.day.slice(-2) - 1
                                              ]
                                            ),
                                          },
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            )
                          : _vm._e(),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogCalendarVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _vm.calendarType == "price"
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.closeCalendarToUpdateRoom },
                    },
                    [_vm._v("修改默认价")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.dialogCalendarVisible = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogRoomPriceChangeVisible, width: "400px" },
          on: {
            "update:visible": function ($event) {
              _vm.dialogRoomPriceChangeVisible = $event
            },
            close: _vm.onRoomPriceChangeClosed,
          },
        },
        [
          _c("div", { attrs: { slot: "title" }, slot: "title" }, [
            _c("i", {
              staticClass: "el-icon-info",
              staticStyle: {
                "font-size": "20px",
                color: "#ff9900",
                "margin-right": "5px",
              },
            }),
            _vm._v("请确定价格修改的生效时间\n    "),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "padding-left": "25px", "font-size": "16px" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.roomPriceChangeEffectType,
                    callback: function ($$v) {
                      _vm.roomPriceChangeEffectType = $$v
                    },
                    expression: "roomPriceChangeEffectType",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: "now" } }, [
                    _vm._v("即刻生效"),
                  ]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: "next_day" } }, [
                    _vm._v("次日0点生效"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogRoomPriceChangeVisible = false
                    },
                  },
                },
                [_vm._v("\n        取 消\n      ")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.onRoomPriceChangeConfirm },
                },
                [_vm._v("\n        确 定\n      ")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }