"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
//
//
//
//
//
//
var _default2 = {
  name: "HighlightNumber",
  props: {
    number: [Number, String],
    color: {
      type: String,
      default: function _default() {
        return '#409EFF';
      }
    },
    suffix: {
      type: String,
      default: function _default() {
        return '';
      }
    }
  },
  methods: {
    getHighlightColor: function getHighlightColor() {
      var number = parseInt(this.number);
      if (number > 0) {
        return this.color;
      } else {
        return 'inherit';
      }
    }
  }
};
exports.default = _default2;