"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.adminUpload = adminUpload;
// import request from '@/utils/request'

function adminUpload() {
  return process.env.VUE_APP_BASE_API + '/upload';
}