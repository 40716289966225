var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container calendar-list-container" },
    [
      _c("div", { staticClass: "filter-container filter-container-flex" }, [
        _c(
          "div",
          [
            _c(
              "el-select",
              {
                staticClass: "filter-item",
                attrs: {
                  filterable: "",
                  clearable: "",
                  placeholder: "网咖门店",
                },
                model: {
                  value: _vm.listQuery.nb_id,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery, "nb_id", $$v)
                  },
                  expression: "listQuery.nb_id",
                },
              },
              _vm._l(_vm.storeList, function (item) {
                return _c("el-option", {
                  key: item.id,
                  attrs: { label: item.name, value: item.id },
                })
              }),
              1
            ),
            _vm._v(" "),
            _c(
              "el-select",
              {
                staticClass: "filter-item",
                staticStyle: { width: "160px" },
                attrs: { clearable: "", placeholder: "状态" },
                model: {
                  value: _vm.listQuery.status,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery, "status", $$v)
                  },
                  expression: "listQuery.status",
                },
              },
              [
                _c("el-option", {
                  attrs: { label: "全部账单状态", value: "" },
                }),
                _vm._v(" "),
                _vm._l(_vm.statusOptions, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
              ],
              2
            ),
            _vm._v(" "),
            _c("el-date-picker", {
              staticClass: "filter-item el-range-separator-w",
              attrs: {
                type: "daterange",
                "range-separator": "至",
                "start-placeholder": "账单开始日期",
                "end-placeholder": "账单结束日期",
                "value-format": "yyyy-MM-dd",
                align: "right",
              },
              on: { change: _vm.onBillDateChange },
              model: {
                value: _vm.listQuery.start_date,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "start_date", $$v)
                },
                expression: "listQuery.start_date",
              },
            }),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "filter-item",
                attrs: { type: "primary" },
                on: { click: _vm.handleFilter },
              },
              [_vm._v("\n        搜索\n      ")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              { staticClass: "filter-item", on: { click: _vm.handleReset } },
              [_vm._v("\n        重置\n      ")]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          key: "tableList",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            "element-loading-text": "给我一点时间",
            fit: "",
            "highlight-current-row": "",
          },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "45" } }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "对账周期",
              "class-name": "overflow-on",
              width: "150",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm._f("dateFormat")(scope.row.start_date))
                      ),
                    ]),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v("至"),
                    _c("br"),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(_vm._s(_vm._f("dateFormat")(scope.row.end_date))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "网咖门店",
              "class-name": "overflow-on",
              prop: "nb_name",
            },
          }),
          _vm._v(" "),
          _c(
            "el-table-column",
            {
              attrs: {
                align: "center",
                "class-name": "overflow-on",
                prop: "settlement_amount",
                width: "120",
              },
            },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _c("span", [_vm._v("门店实收")]),
                  _vm._v(" "),
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: { effect: "dark", placement: "top" },
                    },
                    [
                      _c(
                        "div",
                        { attrs: { slot: "content" }, slot: "content" },
                        [
                          _c("div", [
                            _vm._v(
                              "账期内【临时卡订单的实收金额】和【会员卡充值的支付金额】之和，"
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", [_vm._v("扣除渠道手续费后的金额")]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("i", {
                        staticClass: "el-icon-question color-success",
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", [_vm._v("（已扣手续费）")]),
                ],
                1
              ),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "el-table-column",
            {
              attrs: {
                align: "center",
                "class-name": "overflow-on",
                prop: "order_mgt_fee",
                width: "115",
              },
            },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _c("span", [_vm._v("飞火管理费")]),
                  _vm._v(" "),
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: { effect: "dark", placement: "top" },
                    },
                    [
                      _c(
                        "div",
                        { attrs: { slot: "content" }, slot: "content" },
                        [
                          _c("div", [
                            _vm._v("根据订单实付金额，按照预设比例进行扣除"),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("i", {
                        staticClass: "el-icon-question color-success",
                      }),
                    ]
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "抖音卡券",
              "class-name": "overflow-on",
              width: "100",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.extras.o2o_douyin
                      ? [
                          _c("span", [
                            _vm._v(
                              _vm._s(scope.row.extras.o2o_douyin.extra_amount)
                            ),
                          ]),
                        ]
                      : _c("span", [_vm._v("--")]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "美团卡券",
              "class-name": "overflow-on",
              width: "100",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.extras.o2o_meituan
                      ? [
                          _c("span", [
                            _vm._v(
                              _vm._s(scope.row.extras.o2o_meituan.extra_amount)
                            ),
                          ]),
                        ]
                      : _c("span", [_vm._v("--")]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "水吧收入",
              "class-name": "overflow-on",
              width: "105",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.extras.water_bar
                      ? [
                          _c("span", [
                            _vm._v(
                              _vm._s(scope.row.extras.water_bar.extra_amount)
                            ),
                          ]),
                          _vm._v(" "),
                          scope.row.extras.water_bar.extra_desc
                            ? _c("span", [
                                _vm._v(
                                  "(" +
                                    _vm._s(
                                      scope.row.extras.water_bar.extra_desc
                                    ) +
                                    "杯)"
                                ),
                              ])
                            : _vm._e(),
                        ]
                      : _c("span", [_vm._v("--")]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "洗浴间收入",
              "class-name": "overflow-on",
              width: "105",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.extras.shower
                      ? [
                          _c("span", [
                            _vm._v(
                              _vm._s(scope.row.extras.shower.extra_amount)
                            ),
                          ]),
                          _vm._v(" "),
                          scope.row.extras.shower.extra_desc
                            ? _c("span", [
                                _vm._v(
                                  "(" +
                                    _vm._s(scope.row.extras.shower.extra_desc) +
                                    "次)"
                                ),
                              ])
                            : _vm._e(),
                        ]
                      : _c("span", [_vm._v("--")]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c(
            "el-table-column",
            {
              attrs: {
                align: "center",
                "class-name": "overflow-on",
                width: "105",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.extras.income_adjust
                        ? [
                            _c(
                              "div",
                              {
                                attrs: {
                                  title:
                                    scope.row.extras.income_adjust.extra_desc,
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    scope.row.extras.income_adjust.extra_amount
                                  )
                                ),
                              ]
                            ),
                          ]
                        : _c("span", [_vm._v("--")]),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _c("span", [_vm._v("收入调整")]),
                  _vm._v(" "),
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: { effect: "dark", placement: "top" },
                    },
                    [
                      _c(
                        "div",
                        { attrs: { slot: "content" }, slot: "content" },
                        [
                          _c("div", [
                            _vm._v(
                              "叠加至账单金额中，用于收入调整，鼠标悬停可查看备注"
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("i", {
                        staticClass: "el-icon-question color-success",
                      }),
                    ]
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "el-table-column",
            {
              attrs: {
                align: "center",
                "class-name": "overflow-on",
                width: "125",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.extras.cost
                        ? [
                            _c("div", [
                              _vm._v(
                                _vm._s(scope.row.extras.cost.extra_amount)
                              ),
                            ]),
                            _vm._v(" "),
                            scope.row.status == 10
                              ? _c("div", [_vm._v("（待确认）")])
                              : _vm._e(),
                          ]
                        : _c("span", [_vm._v("--")]),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _c("span", [_vm._v("门店经营成本")]),
                  _vm._v(" "),
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: { effect: "dark", placement: "top" },
                    },
                    [
                      _c(
                        "div",
                        { attrs: { slot: "content" }, slot: "content" },
                        [
                          _c("div", [
                            _vm._v(
                              "由业务填写、财务核算后，计算的门店成本总额"
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("i", {
                        staticClass: "el-icon-question color-success",
                      }),
                    ]
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "el-table-column",
            {
              attrs: {
                align: "center",
                "class-name": "overflow-on",
                width: "125",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.extras.adjust
                        ? [
                            _c(
                              "div",
                              {
                                attrs: {
                                  title: scope.row.extras.adjust.extra_desc,
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(scope.row.extras.adjust.extra_amount)
                                ),
                              ]
                            ),
                          ]
                        : _c("span", [_vm._v("--")]),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _c("span", [_vm._v("经营费用调整")]),
                  _vm._v(" "),
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: { effect: "dark", placement: "top" },
                    },
                    [
                      _c(
                        "div",
                        { attrs: { slot: "content" }, slot: "content" },
                        [
                          _c("div", [
                            _vm._v(
                              "叠加至账单金额中，用于经营费用调整，鼠标悬停可查看备注"
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("i", {
                        staticClass: "el-icon-question color-success",
                      }),
                    ]
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "el-table-column",
            {
              attrs: {
                align: "center",
                "class-name": "overflow-on",
                width: "180",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("div", [_vm._v(_vm._s(scope.row.loss_amount))]),
                      _vm._v(" "),
                      scope.row.loss_deduct_amount
                        ? _c("div", [
                            _vm._v(
                              "(当期已抵扣" +
                                _vm._s(scope.row.loss_deduct_amount) +
                                "元)"
                            ),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _c("span", [_vm._v("累计亏损")]),
                  _vm._v(" "),
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: { effect: "dark", placement: "top" },
                    },
                    [
                      _c(
                        "div",
                        { attrs: { slot: "content" }, slot: "content" },
                        [
                          _c("div", [
                            _vm._v(
                              "显示当期账单商家应收金额冲抵历史累计亏损金额后的结果。"
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _vm._v(
                              "若门店利润为正数，即抵扣累计亏损；若为负数，则滚入累计亏损"
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("i", {
                        staticClass: "el-icon-question color-success",
                      }),
                    ]
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "el-table-column",
            {
              attrs: {
                align: "center",
                "class-name": "overflow-on",
                prop: "nb_share_amount",
                width: "105",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.status == 10
                        ? _c("span", [_vm._v("成本核算中")])
                        : _c("span", [
                            _vm._v(_vm._s(scope.row.nb_share_amount)),
                          ]),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _c("span", [_vm._v("商家分润")]),
                  _vm._v(" "),
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: { effect: "dark", placement: "top" },
                    },
                    [
                      _c(
                        "div",
                        { attrs: { slot: "content" }, slot: "content" },
                        [
                          _c("div", [
                            _vm._v(
                              "商家分润=（当期门店实收（扣除渠道手续费）- 飞火管理费 + 抖音卡券 + 美团卡券 + 水吧收入 + 淋浴间收入 + 收入调整金额 - 门店经营成本 + 经营费用调整金额 - 累计亏损）*分润比例；"
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _vm._v(
                              "若为正数，以最终数值作为当期商家分润；若为负数，则直接显示为0，并将相关金额自动冲抵/滚入累计亏损"
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("i", {
                        staticClass: "el-icon-question color-success",
                      }),
                    ]
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "状态",
              prop: "status_name",
              width: "105",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              fixed: "right",
              label: "操作",
              width: "180",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "text-align": "left",
                          "margin-left": "15px",
                        },
                      },
                      [
                        _vm.enableBillExtraEntry(scope.row)
                          ? _c(
                              "el-link",
                              {
                                staticClass: "link-right",
                                attrs: { type: "primary", underline: false },
                                on: {
                                  click: function ($event) {
                                    return _vm.extraFormHandler(scope.row)
                                  },
                                },
                              },
                              [_vm._v("\n            账单补充\n          ")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.enableFinanceConfirm(scope.row)
                          ? _c(
                              "el-link",
                              {
                                staticClass: "link-right",
                                attrs: { type: "primary", underline: false },
                                on: {
                                  click: function ($event) {
                                    return _vm.financeConfirmHandler(scope.row)
                                  },
                                },
                              },
                              [_vm._v("\n            成本审核\n          ")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "el-link",
                          {
                            staticClass: "link-right",
                            attrs: { type: "primary", underline: false },
                            on: {
                              click: function ($event) {
                                return _vm.toCostPage(scope.row)
                              },
                            },
                          },
                          [_vm._v("\n            成本明细\n          ")]
                        ),
                        _vm._v(" "),
                        !_vm.isRoleNetBarManager()
                          ? [
                              scope.row.nb_settlement_type == 1
                                ? _c(
                                    "el-link",
                                    {
                                      staticClass: "link-right",
                                      attrs: {
                                        type: "primary",
                                        underline: false,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.showRedirectHandler(
                                            scope.row
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              查看订单\n            "
                                      ),
                                    ]
                                  )
                                : _c(
                                    "el-link",
                                    {
                                      staticClass: "link-right",
                                      attrs: {
                                        type: "primary",
                                        underline: false,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.toOrderPage(scope.row)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              查看订单\n            "
                                      ),
                                    ]
                                  ),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.enablePaymentComplete(scope.row)
                          ? _c(
                              "el-link",
                              {
                                staticClass: "link-right",
                                attrs: { type: "primary", underline: false },
                                on: {
                                  click: function ($event) {
                                    return _vm.paymentCompleteHandler(scope.row)
                                  },
                                },
                              },
                              [_vm._v("\n            打款完成\n          ")]
                            )
                          : _vm._e(),
                      ],
                      2
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "pagination-container pagination-stat" }, [
        _c(
          "div",
          { staticClass: "stat" },
          [
            _vm.enableBatchFinanceConfirm()
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "small" },
                    on: { click: _vm.financeBatchConfirmHandler },
                  },
                  [_vm._v("\n        账单批量审核\n      ")]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "el-button",
              { attrs: { size: "small" }, on: { click: _vm.exportHandler } },
              [_vm._v("\n        全部导出\n      ")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c("el-pagination", {
              attrs: {
                background: "",
                total: _vm.pagination.total,
                "current-page": _vm.listQuery.page,
                "page-sizes": [10, 20, 50],
                "page-size": _vm.listQuery.limit,
                layout: "sizes, prev, pager, next, jumper",
              },
              on: {
                "size-change": _vm.handleSizeChange,
                "current-change": _vm.handleCurrentChange,
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("NetBarFinanceBillExtraForm", {
        ref: "FinanceBillExtraForm",
        attrs: {
          billId: _vm.extraBillId,
          visible: _vm.financeBillExtraFormVisible,
        },
        on: {
          "update:visible": function ($event) {
            _vm.financeBillExtraFormVisible = $event
          },
          success: _vm.getList,
        },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "redirect-dialog",
          attrs: {
            visible: _vm.redirectDialogVisible,
            title: "查询订单",
            width: "420px",
          },
          on: { close: _vm.redirectCloseHanlder },
        },
        [
          _c(
            "el-form",
            {
              ref: "redirectForm",
              attrs: {
                model: _vm.redirectForm,
                "label-suffix": ":",
                "label-width": "100px",
                "label-position": "right",
                size: "mini",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "订单类型" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.redirectForm.order_type,
                        callback: function ($$v) {
                          _vm.$set(_vm.redirectForm, "order_type", $$v)
                        },
                        expression: "redirectForm.order_type",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "boot" } }, [
                        _vm._v("开机"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "recharge" } }, [
                        _vm._v("充值"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.redirectDialogVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.redirectHandler },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }