"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _moment = _interopRequireDefault(require("moment"));
var _auth = require("@/utils/auth");
var mixin = {
  components: {},
  data: function data() {
    return {};
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    isProduction: function isProduction() {
      return process.env.NODE_ENV == 'production';
    },
    // 获取周起始日期
    getWeekRange: function getWeekRange(day) {
      day = day || (0, _moment.default)().format("YYYY-MM-DD");
      if (typeof day == "string") {
        day = (0, _moment.default)(day);
      } else if (!day._isAMomentObject) {
        day = (0, _moment.default)(day);
      }
      var weekOfDay = day.format('E');
      var monday = day.subtract(weekOfDay - 1, 'days').format("YYYY-MM-DD");
      var sunday = day.add(6, 'days').format("YYYY-MM-DD");
      return [monday, sunday];
    },
    hasRole: function hasRole(role) {
      return (0, _auth.getRoles)().indexOf(role) >= 0;
    },
    /******** 角色判断 ********/
    // 管理员
    isRoleAdmin: function isRoleAdmin() {
      return this.hasRole("admin");
    },
    // 财务
    isRoleBill: function isRoleBill() {
      return this.hasRole("bill");
    },
    // 网咖店长
    isRoleNetBarManager: function isRoleNetBarManager() {
      return this.hasRole("net_bar_manager");
    }
  },
  beforeDestroy: function beforeDestroy() {},
  computed: {}
};
var _default = mixin;
exports.default = _default;