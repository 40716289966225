"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _vDistpicker = _interopRequireDefault(require("v-distpicker"));
var _shared_store = require("@/api/shared_store");
var _common = require("@/api/common");
var _auth = require("@/utils/auth");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "SharedStoreForm",
  components: {
    VDistpicker: _vDistpicker.default
  },
  data: function data() {
    return {
      id: undefined,
      formPath: '',
      storeForm: {
        base: {
          status: 1,
          thumbnail: '',
          // 必须存在
          district: '',
          // 必须存在
          graphic_card: 'GTX1660',
          display: '34寸显示器',
          cpu: '4核处理器'
        },
        charge: {
          charge_mode: 1
        },
        finance: {
          type: 1,
          bank_city: '' // 必须存在
        }
      },

      formRules: {
        'base.name': [{
          required: true,
          message: '请输入门店名称',
          trigger: 'blur'
        }],
        'base.cd_id': [{
          required: true,
          message: '请输入计费系统ID',
          trigger: 'blur'
        }],
        'base.thumbnail': [{
          required: true,
          message: '请上传缩略图',
          trigger: 'change'
        }],
        'base.district': [{
          required: true,
          message: '请选择地区',
          trigger: 'change'
        }],
        'base.address': [{
          required: true,
          message: '请输入详细地址',
          trigger: 'blur'
        }],
        'base.lng': [{
          required: true,
          message: '请输入经度',
          trigger: 'blur'
        }],
        'base.lat': [{
          required: true,
          message: '请输入纬度',
          trigger: 'blur'
        }],
        'base.status': [{
          required: true,
          message: '请选择门店状态',
          trigger: 'blur'
        }],
        'base.graphic_card': [{
          required: true,
          message: '请选择显卡',
          trigger: 'change'
        }],
        'base.display': [{
          required: true,
          message: '请选择显示器',
          trigger: 'change'
        }],
        'base.cpu': [{
          required: true,
          message: '请选择CPU',
          trigger: 'change'
        }],
        'charge.charge_mode': [{
          required: true,
          message: '请选择计费方式',
          trigger: 'blur'
        }],
        'charge.charge_price': [{
          required: true,
          message: '请输入单价',
          trigger: 'blur'
        }],
        'finance.type': [{
          required: true,
          message: '请选择账户性质',
          trigger: 'blur'
        }],
        'finance.account': [{
          required: true,
          message: '请输入账户名称',
          trigger: 'blur'
        }],
        'finance.bank_account': [{
          required: true,
          message: '请输入银行账号',
          trigger: 'blur'
        }],
        'finance.bank': [{
          required: true,
          message: '请输入开户行',
          trigger: 'blur'
        }],
        'finance.bank_city': [{
          required: true,
          message: '请选择开户行所在省市',
          trigger: 'blur'
        }],
        'finance.idcard': [{
          required: true,
          message: '请输入收款人身份证号',
          trigger: 'change'
        }]
      },
      formSubmitLoading: false,
      uploadUrl: '',
      header: {
        Authorization: "Bearer " + (0, _auth.getToken)()
      }
    };
  },
  created: function created() {
    this.uploadUrl = (0, _common.adminUpload)();
    this.formPath = this.$route.query.form;
    this.id = this.$route.params.id;
    if (this.id) {
      this.getStore();
    }
  },
  methods: {
    handleImageSuccess: function handleImageSuccess(res) {
      if (res.code == 0) {
        this.$message.success("上传成功！");
        this.$refs['sotreBaseThumbnail'].clearValidate();
        this.storeForm.base.thumbnail = res.data.filepath;
      } else {
        this.$message.error("上传失败");
      }
    },
    onStoreDistSelected: function onStoreDistSelected(data) {
      this.storeForm.base.province = data.province.value;
      this.storeForm.base.city = data.city.value;
      this.storeForm.base.district = data.area.value;
      this.$refs['sotreBaseDistrict'].clearValidate();
    },
    onBankDistSelected: function onBankDistSelected(data) {
      this.storeForm.finance.bank_province = data.province.value;
      this.storeForm.finance.bank_city = data.city.value;
      this.$refs['sotreFinanceBankCity'].clearValidate();
    },
    submitHandler: function submitHandler() {
      var _this = this;
      this.$refs['storeForm'].validate(function (valid) {
        if (!valid) {
          return false;
        }
        if (_this.id) {
          _this.editHandler();
        } else {
          _this.addHandler();
        }
      });
    },
    addHandler: function addHandler() {
      var _this2 = this;
      this.formSubmitLoading = true;
      (0, _shared_store.createStore)(this.storeForm).then(function () {
        _this2.$message.success("添加成功");
        _this2.formSubmitLoading = false;
        _this2.$router.push('/shared_store/index');
      }).catch(function () {
        _this2.formSubmitLoading = false;
      });
    },
    editHandler: function editHandler() {
      var _this3 = this;
      this.formSubmitLoading = true;
      (0, _shared_store.updateStore)(this.id, this.storeForm).then(function () {
        _this3.$message.success("编辑成功");
        _this3.formSubmitLoading = false;
        if (_this3.formPath) {
          _this3.$router.push('/shared_store/detail/' + _this3.id);
        } else {
          _this3.$router.push('/shared_store/index');
        }
      }).catch(function () {
        _this3.formSubmitLoading = false;
      });
    },
    getStore: function getStore() {
      var _this4 = this;
      (0, _shared_store.fetchStore)(this.id).then(function (res) {
        // 数据分类 base charge finance
        _this4.storeForm = {
          base: _this4.parseBaseData(res.data),
          charge: _this4.parseChargeData(res.data),
          finance: _this4.parseFinanceData(res.data)
        };
      });
    },
    parseBaseData: function parseBaseData(data) {
      return {
        name: data.name,
        cd_id: data.info.cd_id,
        thumbnail: data.thumbnail,
        contacts: data.contacts,
        tel: data.tel,
        province: data.province,
        city: data.city,
        district: data.district,
        address: data.address,
        lng: data.lng,
        lat: data.lat,
        status: data.status,
        graphic_card: data.info.graphic_card,
        display: data.info.display,
        cpu: data.info.cpu
      };
    },
    parseChargeData: function parseChargeData(data) {
      return {
        charge_mode: data.info.charge_mode,
        charge_price: data.info.charge_price
      };
    },
    parseFinanceData: function parseFinanceData(data) {
      return {
        type: data.finance.type,
        account: data.finance.account,
        bank_account: data.finance.bank_account,
        bank: data.finance.bank,
        bank_province: data.finance.bank_province,
        bank_city: data.finance.bank_city,
        idcard: data.finance.idcard
      };
    },
    cancel: function cancel() {
      this.$router.push('/shared_store/index');
    }
  }
};
exports.default = _default;