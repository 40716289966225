"use strict";

var _interopRequireDefault = require("D:/00_GIT/hotel/hotel-front-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createAgent = createAgent;
exports.deleteAgent = deleteAgent;
exports.fetchAgent = fetchAgent;
exports.fetchHotelList = fetchHotelList;
exports.fetchList = fetchList;
exports.getSimpleList = getSimpleList;
exports.updateAgent = updateAgent;
var _request = _interopRequireDefault(require("@/utils/request"));
function fetchList(query) {
  return (0, _request.default)({
    url: '/agents',
    method: 'get',
    params: query
  });
}
function fetchAgent(id) {
  return (0, _request.default)({
    url: 'agents/' + id,
    method: 'get'
  });
}
function createAgent(data) {
  return (0, _request.default)({
    url: 'agents',
    method: 'post',
    data: data
  });
}
function updateAgent(id, data) {
  return (0, _request.default)({
    url: 'agents/' + id,
    method: 'put',
    data: data
  });
}
function deleteAgent(id) {
  return (0, _request.default)({
    url: '/agents/' + id,
    method: 'delete'
  });
}
function getSimpleList(query) {
  return (0, _request.default)({
    url: '/agent_simple',
    method: 'get',
    params: query
  });
}
function fetchHotelList(id, query) {
  return (0, _request.default)({
    url: '/agents/' + id + '/hotels',
    method: 'get',
    params: query
  });
}